import { ReactElement } from "react";

import { format, getYear } from "date-fns";
import { ko } from "date-fns/locale";
import DatePicker from "react-datepicker";

import HFlex from "../layouts/HFlex";
import Text from "../layouts/Text";
import Image from "../layouts/Image";
import Flex from "../layouts/Flex";

import "./YearMonthPicker.scss";

const START_DATE = new Date(1546300800 * 1000); // 2019년 1월 1일

function YearMonthPicker({
  value,
  setValue,
  customInput,
}: {
  value: Date;
  setValue: (value: Date) => void;
  customInput?: ReactElement;
}) {
  return (
    <Flex className="year-month-picker">
      <DatePicker
        startDate={START_DATE}
        selected={value}
        onChange={setValue}
        dateFormat="yyyy"
        showMonthYearPicker
        yearItemNumber={12}
        minDate={START_DATE}
        locale={ko}
        customInput={
          customInput ?? (
            <HFlex style={{ display: "inline-flex" }} a-c>
              <Text t-14-500-s8 clickable>
                {format(value, "yyyy년 M월")}
              </Text>
              <Image src={"/icons/arrow_drop_down.png"} size={20} clickable />
            </HFlex>
          )
        }
        renderCustomHeader={({
          date,
          decreaseYear,
          increaseYear,
          prevYearButtonDisabled,
        }) => (
          <HFlex p-8-rl c-c j-b>
            <Image
              onClick={() => {
                if (!prevYearButtonDisabled) decreaseYear();
              }}
              src={
                prevYearButtonDisabled
                  ? "/icons/icon_calendar_prev_disabled.svg"
                  : "/icons/icon_calendar_prev.svg"
              }
              size={24}
              clickable
            />
            <Text t-16-600 c-gr5={getYear(value) === getYear(date)}>
              {getYear(date)}
            </Text>
            <Image
              onClick={increaseYear}
              src={"/icons/icon_calendar_next.svg"}
              size={24}
              clickable
            />
          </HFlex>
        )}
      />
    </Flex>
  );
}

export default YearMonthPicker;
