import ButtonV2 from "../../layouts/ButtonV2";
import HFlex from "../../layouts/HFlex";
import Search from "../../layouts/Search";
import VFlex from "../../layouts/VFlex";
import Dropdown from "../../layouts/Dropdown";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import api from "../../common/api";
import { usePartner } from "../../providers/partner";
import Menu from "../../layouts/Menu";
import {
  Filters,
  Sorts,
  ReservationStatusFilter as ReservationStatusFilterType,
} from "../../providers/types";
import ReservationStatusFilter from "./ReservationStatusFilter";
import ReservationDateFilter from "./ReservationDateFilter";
import FilterChip from "../../layouts/FilterChip";
import { isNil } from "lodash";
import SortFilter from "../Customers/SortFilter";

export enum ReservationListType {
  basic = "G_RESERVATION_LIST",
  deleted = "G_DELETED_RESERVATION_LIST",
  hidden = "G_HIDDEN_RESERVATION_LIST",
}
export enum ReservationListFilterCategory {
  status = "RESV_STATUS",
  date = "RESV_DATE",
}

interface ReservationListFilterProps {
  type: ReservationListType;
  keyword: string;
  setKeyword: (k: string) => void;
  statusList: ReservationStatusFilterType[];
  setStatusList: (s: ReservationStatusFilterType[]) => void;
  sortsFilters: Sorts[];
  setSortsFilters: Dispatch<SetStateAction<Sorts[]>>;
  setStatusFiltersId: (id: number) => void;
  startDateFilter: Date | undefined;
  setStartDateFilter: Dispatch<SetStateAction<Date | undefined>>;
  endDateFilter: Date | undefined;
  setEndDateFilter: Dispatch<SetStateAction<Date | undefined>>;
}

const ReservationListFilter = ({
  type,
  keyword,
  setKeyword,
  statusList,
  setStatusList,
  setStatusFiltersId,
  sortsFilters,
  setSortsFilters,
  startDateFilter,
  setEndDateFilter,
  endDateFilter,
  setStartDateFilter,
}: ReservationListFilterProps) => {
  const { partner } = usePartner();

  const [filtersOptions, setFiltersOptions] = useState<Filters<any>[]>([]);
  const [sortsOptions, setSortsOptions] = useState<Sorts[]>([]);

  const [statusFilterOptions, setStatusFilterOptions] = useState<
    ReservationStatusFilterType[]
  >([]);
  const [filterStack, setFilterStack] = useState<string[]>([]);
  const [shownSort, setShownSort] = useState(false);
  const [shownFilters, setShownFilters] = useState(false);

  const initFilterOptions = async () => {
    const { filters, sorts } = await api.get<{
      filters: Filters<ReservationStatusFilterType>[];
      sorts: Sorts[];
    }>(`/rest/group/${partner.id}/filter?type=${type}`);

    setFiltersOptions(filters);
    setStatusFiltersId(
      filters.find((f) => f?.category === ReservationListFilterCategory.status)
        ?.id!
    );
    setStatusFilterOptions(
      filters.find((f) => f?.category === ReservationListFilterCategory.status)
        ?.data ?? []
    );

    setSortsOptions(sorts);
  };

  useEffect(() => {
    initFilterOptions();
    if (statusList.length) {
      setShownFilters(true);
      setFilterStack((s) => [...s, ReservationListFilterCategory.status]);
    }
    if (startDateFilter && endDateFilter) {
      setShownFilters(true);
      setFilterStack((s) => [...s, ReservationListFilterCategory.date]);
    }
    if (sortsFilters.length) {
      setShownSort(true);
    }
  }, []);

  useEffect(() => {
    if (sortsFilters.length <= 0) setShownSort(false);
  }, [sortsFilters]);

  return (
    <VFlex
      p-16-tb
      p-24-rl
      g-16={filterStack.length > 0 || sortsFilters.length > 0}
    >
      <HFlex g-16>
        <Search
          size="S"
          type="outline"
          placeholder="고객명, 예약자명, 전화번호를 입력하세요"
          value={keyword}
          onChangeValue={setKeyword}
        />
        <HFlex g-8>
          {filterStack.length === 0 ? (
            <Dropdown
              trigger={
                <ButtonV2
                  size="XS"
                  type="grey_tint"
                  onClick={() => {}}
                  icon="/icons/icon_filter.svg"
                  iconSize={18}
                >
                  필터
                </ButtonV2>
              }
              renderOptions={({ close }) => (
                <Menu width={180}>
                  {filtersOptions.map((opt) => (
                    <Menu.Item
                      handleClick={() => {
                        setFilterStack((s) => [...s, opt.category]);
                        close();

                        if (sortsFilters.length) setShownSort(true);
                        setShownFilters(true);
                      }}
                    >
                      {opt.title}
                    </Menu.Item>
                  ))}
                </Menu>
              )}
              position="right"
            />
          ) : (
            <ButtonV2
              size="XS"
              type="color_outlined"
              onClick={() => {
                if (sortsFilters.length) setShownSort((s) => !s);
                setShownFilters((s) => !s);
              }}
              icon="/icons/active_filter_list.svg"
              iconSize={18}
            >
              필터 {filterStack.length}
            </ButtonV2>
          )}
          {sortsFilters.length === 0 ? (
            <Dropdown
              trigger={
                <ButtonV2
                  size="XS"
                  type="grey_tint"
                  onClick={() => {}}
                  icon="/icons/icon_sort.svg"
                  iconSize={18}
                >
                  정렬
                </ButtonV2>
              }
              renderOptions={({ close }) => (
                <Menu width={180}>
                  {sortsOptions.map((opt) => (
                    <Menu.Item
                      handleClick={() => {
                        setShownSort(true);
                        setSortsFilters((s) => [
                          ...s,
                          sortsOptions.find(
                            (o) => o.category === opt.category
                          )!,
                        ]);
                        close();

                        if (filterStack.length) setShownFilters(true);
                      }}
                    >
                      {opt.title}
                    </Menu.Item>
                  ))}
                </Menu>
              )}
              position="right"
            />
          ) : (
            <ButtonV2
              size="XS"
              type="color_outlined"
              onClick={() => {
                if (filterStack.length) setShownFilters((s) => !s);
                setShownSort((s) => !s);
              }}
              icon="/icons/active_sort.svg"
              iconSize={18}
            >
              정렬 {sortsFilters.length}
            </ButtonV2>
          )}
        </HFlex>
      </HFlex>
      <HFlex g-6>
        {shownSort && (
          <SortFilter
            options={sortsOptions}
            sortFilters={sortsFilters}
            setSortFilters={setSortsFilters}
          />
        )}
        {shownFilters &&
          filterStack.map((filter) =>
            filter === ReservationListFilterCategory.status ? (
              <ReservationStatusFilter
                options={statusFilterOptions}
                statusList={statusList}
                setStatusList={setStatusList}
                removeFilter={() => {
                  setStatusList([]);
                  setFilterStack((s) =>
                    s.filter((f) => f !== ReservationListFilterCategory.status)
                  );
                }}
              />
            ) : (
              <ReservationDateFilter
                startDateFilter={startDateFilter}
                endDateFilter={endDateFilter}
                setStartDateFilter={setStartDateFilter}
                setEndDateFilter={setEndDateFilter}
                removeFilter={() => {
                  setStartDateFilter(undefined);
                  setEndDateFilter(undefined);
                  setFilterStack((s) =>
                    s.filter((f) => f !== ReservationListFilterCategory.date)
                  );
                }}
              />
            )
          )}
        {filterStack.length > 0 &&
          filterStack.length < 2 &&
          (shownFilters || shownSort) && (
            <Dropdown
              position="left"
              trigger={
                <FilterChip prefixIcon="/icons/add.svg" prefixIconSize={18}>
                  필터 추가
                </FilterChip>
              }
              renderOptions={() => (
                <Menu width={180}>
                  {isNil(
                    filterStack.find(
                      (f) => f === ReservationListFilterCategory.status
                    )
                  ) && (
                    <Menu.Item
                      handleClick={() => {
                        setFilterStack((s) => [
                          ...s,
                          ReservationListFilterCategory.status,
                        ]);
                      }}
                    >
                      예약 상태
                    </Menu.Item>
                  )}
                  {isNil(
                    filterStack.find(
                      (f) => f === ReservationListFilterCategory.date
                    )
                  ) && (
                    <Menu.Item
                      handleClick={() => {
                        setFilterStack((s) => [
                          ...s,
                          ReservationListFilterCategory.date,
                        ]);
                      }}
                    >
                      예약 날짜
                    </Menu.Item>
                  )}
                </Menu>
              )}
            />
          )}
      </HFlex>
    </VFlex>
  );
};

export default ReservationListFilter;
