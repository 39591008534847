import FileUpload from "../../../../components/FileUpload";
import HFlex from "../../../../layouts/HFlex";
import VFlex from "../../../../layouts/VFlex";
import Image from "../../../../layouts/Image";
import Text from "../../../../layouts/Text";
import { useEffect, useState } from "react";
import Flex from "../../../../layouts/Flex";
import ButtonV2 from "../../../../layouts/ButtonV2";
import FilterChip from "../../../../layouts/FilterChip";
import TextInput from "../../../../layouts/TextInput";
import useCustomerFormStore from "../../../../providers/customerForm";
import Dropdown from "../../../../components/Dropdown";
import { Species } from "../../../../providers/types";
import api from "../../../../common/api";
import {
  BIRTH_TYPE_OPTIONS,
  NEUTRALIZED,
  PET_BIRTHDAY_MAX_DATE,
  PET_BIRTHDAY_MIN_DATE,
  SPECIES_OPTIONS,
  sex,
} from "../../../../common/constants";
import { isNil } from "lodash";
import TextArea from "../../../../layouts/TextareaV2";
import SubfixInput from "../../../../layouts/SubfixInput";
import DatePicker from "../../../../components/DatePicker";
import format from "date-fns/format";
import Notification from "../../../../layouts/Notification";
import RadioGroup from "../../../../layouts/RadioGroup";
import {
  estimateBirthYearMonth,
  validateWeight,
} from "../../../../common/utils/customers";

const PetInfoTab = () => {
  const { setData, ...rest } = useCustomerFormStore();

  const [breedList, setBreedList] = useState<Species[]>([]);
  const [birthType, setBirthType] = useState<"BIRTHDAY" | "AGE" | undefined>(
    rest.birthday
      ? "BIRTHDAY"
      : !isNil(rest.age) || !isNil(rest.age)
      ? "AGE"
      : undefined
  );

  const fetchSpeciesList = async (spciesId: number) => {
    const speciesList = await api.get(`/rest/species/${spciesId}/species-list`);
    setBreedList(speciesList);
  };

  useEffect(() => {
    if (rest.species) fetchSpeciesList(rest.species?.id);
  }, [rest.species]);

  return (
    <VFlex a-c f-1 ovf-h>
      <VFlex style={{ width: "100%", padding: "32px 20%" }} f-1 ovf-a>
        <HFlex m-32-b>
          <label htmlFor="customer-form_profile">
            <Flex rel>
              <Image
                size={80}
                bdr-24
                src={
                  rest.profileImgFile
                    ? URL.createObjectURL(rest.profileImgFile)
                    : rest.profileImgUrl ?? `/icons/default_profile.svg`
                }
                clickable
              />
              <Image
                src="/icons/icon_add_img.svg"
                size={25}
                abs
                bc-w
                style={{
                  bottom: 3,
                  right: -3,
                  padding: 3,
                  borderRadius: "16px",
                }}
              />
            </Flex>
          </label>
          <FileUpload
            id="customer-form_profile"
            onLoad={(e) => {
              setData({
                profileImgFile: e,
              });
            }}
          />
        </HFlex>

        <VFlex g-24>
          <TextInput
            caption="반려동물명"
            placeholder="반려동물 이름"
            required
            value={rest.name}
            onChangeValue={(e) => {
              setData({
                name: e,
              });
            }}
          />

          <RadioGroup
            caption="반려동물 종류"
            required
            value={
              rest.species
                ? { label: rest.species.name, value: rest.species.id }
                : undefined
            }
            onChange={(species) => {
              if (species?.value === rest.species?.id) return;

              setData({
                species: species
                  ? { id: species.value, name: species.label }
                  : species,
                breed: undefined,
              });
            }}
            options={SPECIES_OPTIONS}
            renderOption={(t, handleChange) => (
              <Flex f-1>
                <FilterChip
                  type={rest.species?.id === t.value ? "selected" : undefined}
                  onClick={() => handleChange(t)}
                  size="L"
                >
                  {t.label}
                </FilterChip>
              </Flex>
            )}
          />

          {rest.species && (
            <Dropdown
              caption={rest.species.id === 4 ? "견종" : "묘종"}
              placeholder={(rest.species.id === 4 ? "견종" : "묘종") + " 선택"}
              value={rest.breed?.id}
              options={breedList?.map((breed) => ({
                value: breed.id,
                obj: breed,
                label: breed.name,
              }))}
              onChange={(value) => {
                setData({
                  breed: value?.obj,
                });
              }}
              isSearchable={true}
              required={true}
              controlBgColor="fff"
            />
          )}

          <RadioGroup
            caption="성별"
            value={
              !isNil(rest.sex)
                ? { label: sex[rest.sex], value: rest.sex }
                : undefined
            }
            onChange={(sex) => {
              setData({
                sex: sex?.value,
              });
            }}
            options={sex.map((s, i) => ({ label: s, value: i }))}
            renderOption={(t, handleChange) => (
              <Flex f-1>
                <FilterChip
                  type={rest.sex === t.value ? "selected" : undefined}
                  onClick={() => handleChange(t)}
                  size="L"
                >
                  {t.label}
                </FilterChip>
              </Flex>
            )}
          />
          <RadioGroup
            caption="중성화"
            value={
              !isNil(rest.neutralized)
                ? {
                    label: NEUTRALIZED[rest.neutralized],
                    value: rest.neutralized,
                  }
                : undefined
            }
            onChange={(nt) => {
              setData({
                neutralized: nt?.value,
              });
            }}
            options={NEUTRALIZED.map((n, i) => ({ label: n, value: i }))}
            renderOption={(t, handleChange) => (
              <Flex f-1>
                <FilterChip
                  type={rest.neutralized === t.value ? "selected" : undefined}
                  onClick={() => handleChange(t)}
                  size="L"
                >
                  {t.label}
                </FilterChip>
              </Flex>
            )}
          />

          <VFlex g-16>
            <RadioGroup
              caption="나이"
              options={BIRTH_TYPE_OPTIONS.map((opt) => opt.value)}
              renderOption={(opt, handleChange) => (
                <HFlex f-1 a-c g-8>
                  <Image
                    src={
                      birthType === opt
                        ? "/icons/checkbox_circle_outline_checked.svg"
                        : "/icons/checkbox_circle_outline_unchecked.svg"
                    }
                    size={24}
                    onClick={() => {
                      handleChange(opt);
                      if (opt === BIRTH_TYPE_OPTIONS[0].value)
                        setData({
                          age: undefined,
                          months: undefined,
                        });
                      else if (opt === BIRTH_TYPE_OPTIONS[1].value)
                        setData({
                          birthday: undefined,
                        });
                    }}
                    clickable
                  />
                  <Text t-14-500-s8 onClick={() => handleChange(opt)} clickable>
                    {
                      BIRTH_TYPE_OPTIONS.find((option) => opt === option.value)
                        ?.label
                    }
                  </Text>
                </HFlex>
              )}
              value={birthType}
              onChange={(v) => {
                setBirthType(v);
              }}
              allowUnselect={false}
            />
            {birthType === BIRTH_TYPE_OPTIONS[0].value && (
              <DatePicker
                date={rest.birthday}
                minDate={PET_BIRTHDAY_MIN_DATE}
                maxDate={PET_BIRTHDAY_MAX_DATE}
                setDate={(dt) => {
                  setData({
                    birthday: dt,
                  });
                }}
                trigger={
                  <HFlex p-24-rl p-16-tb bdr-16 bd-t5 bc-w clickable j-b>
                    {rest.birthday ? (
                      <Text t-16-500-s8>{`${format(
                        rest.birthday,
                        "yyyy년 MM월 dd일"
                      )}`}</Text>
                    ) : (
                      <Text t-16-500-s2>생년월일 선택</Text>
                    )}

                    <Image
                      src="/icons/navigation/arrow_drop_down_s3.svg"
                      size={22}
                    />
                  </HFlex>
                }
              />
            )}
            {birthType === BIRTH_TYPE_OPTIONS[1].value && (
              <VFlex g-8>
                <HFlex g-8>
                  <Flex f-1>
                    <Dropdown
                      placeholder={"년"}
                      value={rest.age}
                      options={[...Array(61).keys()].map((y) => ({
                        value: y,
                        obj: y,
                        label: `${y}년`,
                      }))}
                      onChange={(value) => {
                        setData({
                          age: value?.value,
                        });

                        if (isNil(rest.months)) {
                          setData({
                            months: 0,
                          });
                        }
                      }}
                      isSearchable={true}
                      controlBgColor="fff"
                    />
                  </Flex>

                  <Flex f-1>
                    <Dropdown
                      placeholder={"개월"}
                      value={rest.months}
                      options={[...Array(12).keys()].map((y) => ({
                        value: y,
                        obj: y,
                        label:
                          (isNil(rest.age) || rest.age === 0) && y === 0
                            ? "1개월 미만"
                            : `${y}개월`,
                      }))}
                      onChange={(value) => {
                        setData({
                          months: value?.obj,
                        });

                        if (isNil(rest.age)) {
                          setData({
                            age: 0,
                          });
                        }
                      }}
                      isSearchable={true}
                      controlBgColor="fff"
                    />
                  </Flex>
                </HFlex>
                <Notification type="container" color="blue">
                  <HFlex g-12>
                    <Image src="/icons/icon_info_s6.svg" size={22} />
                    <VFlex g-4>
                      {(!isNil(rest.age) || !isNil(rest.months)) && (
                        <Notification.Title>
                          추정 생년월일:{" "}
                          {estimateBirthYearMonth(
                            rest.age ?? 0,
                            rest.months ?? 0
                          )}
                        </Notification.Title>
                      )}
                      <Notification.Description>
                        추정 생년월일을 기준으로 나이가 업데이트 됩니다.
                      </Notification.Description>
                    </VFlex>
                  </HFlex>
                </Notification>
              </VFlex>
            )}
          </VFlex>

          <SubfixInput
            caption="몸무게"
            placeholder="예: 7.6"
            value={rest.weight}
            validate={validateWeight}
            inputClassName="weight_input"
            onChangeValue={(e) => {
              setData({
                weight: e,
              });
            }}
            onBlur={() => {
              if (rest.weight?.endsWith("."))
                setData({ weight: rest.weight.replace(".", "") });
            }}
            renderSubfix={() => <Text t-16-500-s8>kg</Text>}
            error={
              !isNil(rest.weight) &&
              (Number(rest.weight) > 200 || Number(rest.weight) <= 0)
            }
            errorText={
              Number(rest.weight) > 200
                ? "몸무게는 최대 200kg까지 입력할 수 있습니다."
                : "0kg 이하의 값을 입력할 수 없습니다."
            }
          />

          <TextArea
            caption="알레르기 정보"
            placeholder="예: 캥거루 고기, 가금류"
            value={rest.allergy}
            onChangeValue={(v) => setData({ allergy: v })}
            maxLength={800}
          />
          <TextArea
            caption="반려동물 메모"
            placeholder="특이사항을 메모해주세요"
            value={rest.note}
            onChangeValue={(v) => setData({ note: v })}
            maxLength={800}
          />
        </VFlex>
      </VFlex>
    </VFlex>
  );
};

export default PetInfoTab;
