import { ChangeEvent, ReactElement, useCallback } from "react";

function FilesUpload({
  id,
  onLoad,
  multiple,
}: {
  id: string;
  onLoad: (files: File[]) => void;
  multiple?: boolean;
}): ReactElement {
  const photoUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const files = e.target.files;

      if (files) {
        const selectedFiles: File[] = Array.from(files);
        onLoad(selectedFiles);
      }
    },
    [onLoad]
  );
  return (
    <input
      id={id}
      type="file"
      accept="image/*"
      onChange={photoUpload}
      onClick={(e: any) => {
        (e.target as HTMLInputElement).value = "";
      }}
      multiple={multiple}
    />
  );
}

export default FilesUpload;
