import { InputHTMLAttributes, useRef, useState } from "react";
import Absolute from "./Absolute";
import HFlex from "./HFlex";
import VFlex from "./VFlex";
import Text from "./Text";
import SvgIcon, { SvgIconType } from "../svg";

function tryParseLocalize(str: string) {
  if (/^[0-9]+$/.test(str)) {
    try {
      let value = parseFloat(str);
      if (value > 100) value = 100;
      else if (value < 0) value = 0;
      return value.toLocaleString();
    } catch {}
  }
  return str;
}

const PercentageInput = ({
  caption,
  required,
  disabled,
  error,
  value,
  onChangeValue,
  ...props
}: InputHTMLAttributes<HTMLInputElement> & {
  caption?: string;
  isNumber?: boolean;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  value?: string;
  onChangeValue?: (value: string | undefined) => void;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [focused, setFocused] = useState<boolean>(false);
  const [cancelMouseDown, setCancelMouseDown] = useState<boolean>(false);

  return (
    <VFlex
      g-8
      unclickable={disabled}
      clickable={!disabled}
      style={{ cursor: "text" }}
    >
      {caption && (
        <HFlex g-8>
          <Text t-14-s8>{caption}</Text>
          {required && <Text t-14-700-rd1>*</Text>}
        </HFlex>
      )}
      <VFlex
        bc-t1
        bd-t3={!error && !focused}
        bd-gr5={!error && focused}
        bd-err1={error}
        rel
        bdr-16
        height={56}
        onClick={() => {
          setFocused(true);
          inputRef.current?.focus();
        }}
      >
        {!value && (
          <HFlex f-1 p-24-rl a-c>
            <Text
              t-16-s1={!!caption || !(focused || !!value)}
              m-22-b={!caption && (focused || !!value)}
              t-12-s1={!caption && (focused || !!value)}
              transition
            >
              {"0~100% 숫자만 입력"}
            </Text>
          </HFlex>
        )}

        <Absolute>
          <HFlex
            a-c
            j-b
            p-24-rl
            style={{ opacity: focused || caption || !!value ? 1 : 0 }}
            height={"100%"}
          >
            <HFlex className="percentage_input" f-1>
              <input
                ref={inputRef}
                style={{
                  width: 9 * (value?.length ?? 0) || 100,
                  backgroundColor: "transparent",
                  borderWidth: 0,
                  padding: 0,
                  height: 22,
                  color: disabled ? "#979E9A" : "#252726",
                  marginTop: !caption ? 16 : 0,
                  fontSize: !caption ? 14 : 16,
                }}
                value={tryParseLocalize(value ?? "")}
                onChange={(e) => {
                  if (
                    e.target.value.length &&
                    !/^[0-9]+$/.test(e.target.value)
                  ) {
                    e.preventDefault();
                    return;
                  }
                  onChangeValue?.(
                    tryParseLocalize(e.target.value) || undefined
                  );
                }}
                {...props}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
              />
              {value && (
                <label style={{ flex: 1 }} className="percentage_input-label" />
              )}
            </HFlex>

            {!!value && (focused || cancelMouseDown) && (
              <HFlex
                c-c
                onClick={(e) => {
                  onChangeValue?.("");
                  e.stopPropagation();
                  setCancelMouseDown(false);
                }}
                onMouseDown={() => setCancelMouseDown(true)}
              >
                <SvgIcon icon={SvgIconType.InputCancel} size={24} />
              </HFlex>
            )}
          </HFlex>
        </Absolute>
      </VFlex>
    </VFlex>
  );
};

export default PercentageInput;
