import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import { format } from "date-fns";

import TextInput from "../../layouts/TextInput";
import DatePicker from "../../components/DatePicker";
import FileUpload from "../../components/FileUpload";
import { SignupFooter } from "./Signup";
import Dropdown from "../../components/Dropdown";
import LoadingView from "../../layouts/LoadingView";
import VFlex from "../../layouts/VFlex";
import HFlex from "../../layouts/HFlex";
import Text from "../../layouts/Text";
import Button from "../../layouts/Button";
import Image from "../../layouts/Image";
import Notification from "../../layouts/Notification";

import api from "../../common/api";
import { useNavigate } from "react-router-dom";
import { usePartner } from "../../providers/partner";
import { useAmplitudeTrack } from "../../common/useAmplitudeTrack";
import useIsMobile from "../../common/useIsMobile";

import { formatPhoneNumber } from "../../common/format";

function SignupCompany() {
  const { trackSetBizLicense } = useAmplitudeTrack();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { partner, fetchPartner } = usePartner();
  useEffect(() => {
    if (isMobile) {
      window.location.href = "https://pawdlypartners.page.link/c6wA";
      return;
    }
    fetchPartner();
  }, [fetchPartner]);
  const [title, setTitle] = useState<string>();
  const [brn, setBrn] = useState<string>();
  const [commencementDate, setCommencementDate] = useState<Date>();
  const [primaryContact, setPrimaryContact] = useState<string>();
  const [address, setAddress] = useState<string>();
  const [additionalAddress, setAdditionalAddress] = useState<string>();
  const [zoneCode, setZoneCode] = useState<string>();
  const [file, setFile] = useState<File>();
  const ready = useMemo(
    () =>
      !!partner &&
      !!title &&
      !!brn &&
      brn.length === 10 &&
      !!commencementDate &&
      !!primaryContact &&
      !!address &&
      !!file,
    [partner, title, brn, commencementDate, primaryContact, address, file]
  );
  const [loading, setLoading] = useState<boolean>();
  const openAddress = useCallback(() => {
    // @ts-ignore
    new daum.Postcode({
      oncomplete: function (data: any) {
        setAddress(data.address);
        setZoneCode(data.zonecode);
      },
    }).open();
  }, []);
  return (
    <LoadingView f-1 a-c bc-w ovf-a loading={loading}>
      <VFlex a-st g-24 p-24-rl width={428} style={{ marginTop: 150 }}>
        <VFlex a-st g-24 style={{ marginBottom: 100 }}>
          <VFlex a-c g-8>
            <HFlex a-c>
              <Text t-32-600-s9>
                <Text t-32-600-gr5>사업자 정보를 </Text>인증해주세요
              </Text>
            </HFlex>
            <Text t-16-500-s3>사업자 등록증에 명시된 정보를 입력해주세요.</Text>

            <HFlex a-st as-st m-24-trl m-16-b>
              <Notification color="blue" type="container">
                <HFlex g-12>
                  <Image src="/icons/icon_info_s6.svg" size={22} />
                  <VFlex g-4>
                    <Notification.Title>
                      도움이 필요하신가요?
                    </Notification.Title>
                    <Notification.Link url="https://pawdly.notion.site/ec4e86b5b2eb4ef9819335385894dda5?pvs=4">
                      <Text>사업자 인증 방법 확인하기</Text>
                    </Notification.Link>
                  </VFlex>
                </HFlex>
              </Notification>
            </HFlex>

            <VFlex a-st f-1 as-st m-24-rl g-16>
              <TextInput
                caption="대표자명"
                disabled={true}
                value={partner?.fullname}
              />
              <TextInput
                caption="업체명"
                placeholder="업체명 입력"
                value={title}
                onChangeValue={(value) => setTitle(value)}
              />
              <TextInput
                caption="사업자 등록번호"
                placeholder="사업자 번호 11자리 숫자만 입력"
                value={brn}
                onChangeValue={(value) => setBrn(value?.slice(0, 10))}
              />
              <VFlex g-8>
                <HFlex g-8>
                  <Text t-14-500-s8>개업년월일</Text>
                  <Text t-14-500-r1>*</Text>
                </HFlex>
                <DatePicker
                  date={commencementDate}
                  setDate={setCommencementDate}
                  trigger={
                    <HFlex
                      p-16-tb
                      p-16-r
                      p-24-l
                      bdr-16
                      bd-t3
                      bc-w
                      clickable
                      j-b
                    >
                      {commencementDate ? (
                        <Text>{`${format(
                          commencementDate,
                          "yyyy년 MM월 dd일"
                        )}`}</Text>
                      ) : (
                        <Text t-16-500-s1>날짜를 선택하세요</Text>
                      )}

                      <Image
                        src="/icons/navigation/arrow_drop_down_s3.svg"
                        size={22}
                      />
                    </HFlex>
                  }
                />
              </VFlex>
              <TextInput
                caption="업체 전화번호"
                placeholder="010-0000-0000"
                value={primaryContact}
                inputMode="numeric"
                onKeyDown={(e) => {
                  if (
                    !(
                      e.key === "Tab" ||
                      e.key === "Backspace" ||
                      /[0-9]/.test(e.key)
                    )
                  ) {
                    e.preventDefault();
                  }
                }}
                onChangeValue={(value) => {
                  const result = value?.replace(/[^0-9]/g, "") || "";
                  setPrimaryContact((phone) =>
                    formatPhoneNumber(phone, result)
                  );
                }}
              />
              <Dropdown
                controlBgColor="#fff"
                placeholder="주소를 선택하세요"
                isDisabled={true}
                value={address ? 0 : undefined}
                options={[{ value: 0, label: address ?? "" }]}
                caption={"업체 주소"}
                onClick={() => {
                  openAddress();
                }}
                required={true}
              />
              <TextInput
                value={additionalAddress}
                onChangeValue={(value) => setAdditionalAddress(value)}
                placeholder="상세 주소 입력"
              />
              <HFlex g-8>
                <Text t-14-400-s9>사업자등록증</Text>
              </HFlex>
              <HFlex f-1 m-40-b>
                <label
                  htmlFor="business-license-upload"
                  style={{ width: "100%" }}
                >
                  {file ? (
                    <HFlex c-c g-8 bdr-16 height={56} bd-t3>
                      <Image size={20} src={`/icons/checkbox_checked.png`} />
                      <Text t-16-500-gr5>{file.name}</Text>
                    </HFlex>
                  ) : (
                    <HFlex c-c g-8 bdr-16 height={56} bd-t3>
                      <Image size={20} src={`/icons/icon_camera_2.png`} />
                      <Text t-16-500-gr5>사업자등록증 올리기</Text>
                    </HFlex>
                  )}
                  <FileUpload
                    id="business-license-upload"
                    onLoad={(file: File) => setFile(file)}
                  />
                </label>
              </HFlex>
              <Button
                type="cta-lg"
                enabled={ready}
                onClick={async () => {
                  if (
                    !partner ||
                    !title ||
                    !brn ||
                    !commencementDate ||
                    !primaryContact ||
                    !address ||
                    !file
                  ) {
                    return;
                  }
                  const formData = new FormData();
                  formData.append("businessOwner", partner?.fullname);
                  formData.append("title", title);
                  formData.append("brn", brn);
                  formData.append(
                    "commencementDate",
                    Math.floor(commencementDate.getTime() / 1000).toString()
                  );
                  formData.append("primaryContact", primaryContact);
                  formData.append(
                    "address",
                    additionalAddress
                      ? `${zoneCode} ${address}, ${additionalAddress}`
                      : `${zoneCode} ${address}`
                  );
                  formData.append("file", file);
                  setLoading(true);
                  try {
                    const groupId = await api.post(
                      `/rest/group/business/registration`,
                      formData,
                      {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      }
                    );
                    if (groupId) {
                      navigate(`/signup/companydetails`, {
                        state: { groupId },
                      });
                    } else {
                      toast.error("입력 정보가 올바르지 않습니다.");
                    }
                  } catch (e) {
                    toast.error("입력 정보가 올바르지 않습니다.");
                  } finally {
                    setLoading(false);
                  }

                  trackSetBizLicense();
                }}
                caption="다음"
              />
            </VFlex>
          </VFlex>
        </VFlex>
      </VFlex>
      <VFlex f-1 />
      <SignupFooter />
    </LoadingView>
  );
}

export default SignupCompany;
