import { useEffect } from "react";

import VFlex from "../../layouts/VFlex";
import Image from "../../layouts/Image";
import Text from "../../layouts/Text";
import HFlex from "../../layouts/HFlex";

import { useAmplitudeTrack } from "../useAmplitudeTrack";

const RedirectAppDownload = () => {
  const { trackLandDownloadPPA, trackClickDownloadPPA } = useAmplitudeTrack();

  useEffect(() => {
    trackLandDownloadPPA();
  }, []);

  return (
    <VFlex f-1 c-c>
      <VFlex
        a-c
        style={{
          width: "100%",
          maxWidth: "375px",
        }}
      >
        <Image src="PPA_logo.png" size={100} bdr-20 />

        <VFlex style={{ marginTop: "20px", marginBottom: "60px" }}>
          <Text t-16-500-s8 m-8-b style={{ textAlign: "center" }}>
            포들리 파트너스
          </Text>
          <Text t-12-500-s4 style={{ textAlign: "center" }}>
            새로운 반려동물 고객관리의 시작
          </Text>
        </VFlex>

        <a
          href="https://pawdly-partners.onelink.me/QH3C/artqxglk"
          style={{ width: "100%" }}
        >
          <HFlex
            p-12
            bc-gr5
            c-c
            bdr-16
            clickable
            onClick={() => trackClickDownloadPPA()}
          >
            <Text t-16-600-tw>앱 다운로드</Text>
          </HFlex>
        </a>
      </VFlex>
    </VFlex>
  );
};

export default RedirectAppDownload;
