import { useMemo, useState } from "react";
import HFlex from "../../../layouts/HFlex";
import VFlex from "../../../layouts/VFlex";
import Text from "../../../layouts/Text";
import Image from "../../../layouts/Image";
import TextInput from "../../../layouts/TextInput";
import Button from "../../../layouts/Button";
import api from "../../../common/api";
import { toast } from "react-hot-toast";
import { usePartner } from "../../../providers/partner";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";

function AddMileage({
  popPopup,
  petId,
  fetchHistory,
}: {
  popPopup: () => void;
  petId: number;
  fetchHistory: () => void;
}) {
  const { trackSavePoint } = useAmplitudeTrack();
  const { partner } = usePartner();
  const [amount, setAmount] = useState<string | undefined>();
  const [note, setNote] = useState<string | undefined>();
  const isSavable = useMemo(() => {
    return !!(amount && parseInt(amount!.replaceAll(/,/g, "")) > 0);
  }, [amount]);

  const addMileage = async () => {
    if (parseInt(amount!.replaceAll(/,/g, "")) >= 100000000) {
      toast.error("최대 입력 가능 금액은 99,999,999원입니다.");
    } else {
      await api.post("/rest/mileage", {
        petId,
        groupId: partner.id,
        amount: parseInt(amount!.replaceAll(/,/g, "")),
        note,
      });
      toast.success("적립금이 추가되었어요");
      popPopup();
    }
  };
  return (
    <VFlex p-32>
      <HFlex j-b m-24-b>
        <Text t-22-600 c-s8>
          적립금 추가
        </Text>
        <Image
          size={24}
          src={`/icons/popup_close.png`}
          clickable
          onClick={() => {
            popPopup();
          }}
        />
      </HFlex>
      <TextInput
        caption="추가 금액"
        placeholder="0원"
        value={amount}
        isNumber={true}
        onChangeValue={(value) => setAmount(value)}
      />

      <>
        <Text t-16-500-s8 m-32-t m-16-b>
          적립금 추가 이유
        </Text>
        <Text t-14-500-s4 m-8-b>
          적립금 추가 이유를 입력해주세요 (최대 500자)
        </Text>
        <TextInput
          placeholder="예) 적립금으로 환불금 대체"
          value={note}
          maxLength={500}
          onChangeValue={(value) => setNote(value)}
        />
      </>

      <Button
        m-32-t
        type="cta-lg"
        enabled={isSavable}
        onClick={async () => {
          await addMileage();
          await fetchHistory();
          trackSavePoint();
        }}
        caption="저장"
      />
    </VFlex>
  );
}

export default AddMileage;
