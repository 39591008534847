import { ReactElement, useCallback, useEffect, useState } from "react";

import Toggle from "../../../components/Toggle";
import api from "../../../common/api";
import VFlex from "../../../layouts/VFlex";
import Text from "../../../layouts/Text";
import HFlex from "../../../layouts/HFlex";
import Image from "../../../layouts/Image";

import { usePartner } from "../../../providers/partner";
import { SettingsTitle } from ".";
import Select from "react-select";
import _ from "lodash";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";

interface InquiryDetailSettingsInterface {
  content: string | undefined;
  defaultValue: string | undefined;
  id: number | undefined;
  // selectOptions?: SelectOption[];
  title: string | undefined;
  type: string | undefined;
  typeId: number | undefined;
  value: string | undefined;
  valueType: string | undefined;
}
interface InquirySettingsInterface {
  childs: InquiryDetailSettingsInterface[] | undefined;
  defaultValue: string | undefined;
  id: number | undefined;
  title: string | undefined;
  type: string | undefined;
  value: string | undefined;
  valueType: string | undefined;
  content: string | undefined;
}

// interface SelectOption {
//   label: string;
//   value: string;
//   sortOrder: number;
// }
const inquiryPeriodItems = [
  { label: "14일", value: 14 },
  { label: "30일", value: 30 },
  { label: "60일", value: 60 },
  { label: "90일", value: 90 },
];
const inquiryIntervalItems = [
  { label: "30분", value: 30 },
  { label: "60분", value: 60 },
  { label: "90분", value: 90 },
  { label: "120분", value: 120 },
];

const PeriodSelect = ({
  defaultIndex,
  items,
  onChange,
}: {
  defaultIndex: number;
  // items: SelectOption[];
  // onChange: (value: string) => void;
  items: { value: number; label: string }[];
  onChange: (index: number) => void;
}) => {
  return (
    <HFlex m-12-t a-c>
      <Select
        defaultValue={items[defaultIndex]}
        hideSelectedOptions={false}
        closeMenuOnSelect={true}
        isMulti={false}
        styles={{
          control: () => ({
            display: "flex",
            backgroundColor: "#F1F3F2",
            alignItems: "center",
            WebkitUserSelect: "none",
            msUserSelect: "none",
            userSelect: "none",
            padding: "4px 4px 4px 12px",
            borderRadius: "8px",
          }),
          valueContainer: () => ({
            flex: 1,
            display: "flex",
          }),
          menu: () => ({
            marginTop: "4px",
            position: "absolute",
            backgroundColor: "white",
            width: "100%",
            maxHeight: "264px",
            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.2)",
            borderRadius: 8,
          }),
          menuPortal: (provided) => ({
            ...provided,
            width: 104,
            zIndex: 100000000000,
          }),
          singleValue: () => ({
            color: "#383B3A",
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "22px",
            cursor: "pointer",
          }),
        }}
        components={{
          IndicatorSeparator: () => null,
          Option: (props: any) => {
            const { innerProps, innerRef } = props;
            return (
              <article
                ref={innerRef}
                {...innerProps}
                style={{
                  marginTop: 2,
                  marginBottom: 2,
                  width: "100%",
                  height: "38px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  backgroundColor: props.isFocused ? "#F8F9F8" : "white",
                  WebkitUserSelect: "none",
                  msUserSelect: "none",
                  userSelect: "none",
                  cursor: "pointer",
                }}
              >
                <HFlex a-c j-b p-8-rl width={"100%"}>
                  <Text t-14-500 c-s8 f-1>
                    {/* {props.label} */}
                    {props.data.label}
                  </Text>
                  {/* {props.value.toString() ===
                    items[defaultIndex]?.value.toString() && ( */}
                  {props.data.value === items[defaultIndex].value && (
                    <Image src="/icons/check_small.png" size={22} />
                  )}
                </HFlex>
              </article>
            );
          },
          DropdownIndicator: () => (
            <Image
              size={24}
              src={`/icons/arrow_drop_down.png`}
              style={{ cursor: "pointer" }}
            />
          ),
        }}
        onChange={(item) => {
          onChange(item?.value!);
        }}
        options={items}
        menuPortalTarget={document.querySelector("body")}
        isSearchable={false}
      />
    </HFlex>
  );
};

function InquirySetting({
  setSelectedSubpage,
}: {
  setSelectedSubpage: (component?: ReactElement) => void;
}) {
  const { trackClickInquiryResMore, trackClickInquirySettings } =
    useAmplitudeTrack();
  const { partner } = usePartner();

  const [inquirySettings, setInquirySettings] =
    useState<InquirySettingsInterface>();
  const [settings, setSettings] = useState<InquiryDetailSettingsInterface[]>(
    []
  );

  const getInquirySettings = useCallback(async () => {
    const response = await api.get(
      `/rest/group/${partner.id}/details?type=INQ_RESV`
    );
    setInquirySettings({
      childs: response[0].childs,
      content: response[0].content,
      defaultValue: response[0].defaultValue,
      id: response[0].id,
      title: response[0].title,
      type: response[0].type,
      value: response[0].value,
      valueType: response[0].valueType,
    });
    setSettings(
      response[0].childs.filter(
        (s: InquiryDetailSettingsInterface) => s.type !== "0" && s.type !== "3"
      )
    );
  }, [partner.id]);

  useEffect(() => {
    getInquirySettings();
  }, [getInquirySettings]);

  return (
    <VFlex f-1 as-st>
      <SettingsTitle
        title="예약문의 설정"
        setSelectedSubpage={setSelectedSubpage}
        border={true}
      />
      <VFlex p-32>
        <HFlex a-s>
          <VFlex g-8>
            <Text t-18-700>예약문의</Text>
            <Text t-14-400-s4>
              예약 문의 기능을 사용하면, 고객이 예약일시를 직접 선택해 문의로
              남길 수 있어요.
            </Text>

            <Text
              t-16-700
              c-ct3
              onClick={() => {
                window.open(
                  "https://www.notion.so/pawdly/9e1f2ebf89bf430ab0bb5493ffb241c9",
                  "_blank"
                );
                trackClickInquiryResMore(partner.title);
              }}
            >
              예약 문의 더 알아보기
            </Text>
          </VFlex>
          <Toggle
            value={inquirySettings?.value === "TRUE" ? true : false}
            onChange={async (value) => {
              await api.put(
                `/rest/group/${partner.id}/details/${
                  inquirySettings?.id
                }?value=${value ? "TRUE" : "FALSE"}`
              );
              getInquirySettings();
            }}
          />
        </HFlex>
        <VFlex
          bdr-8
          m-24-t
          bd-t3
          style={inquirySettings?.value === "FALSE" ? { opacity: 0.5 } : {}}
        >
          {settings?.map((setting, i) => (
            <VFlex key={i} p-16-tb p-24-l bd-b-t3={settings.length - 1 !== i}>
              <HFlex>
                <VFlex g-4>
                  <Text t-14-500-bl3>{setting.title}</Text>
                  <Text t-12 c-s6>
                    {setting.content}
                  </Text>
                </VFlex>

                {/* {setting.typeId !== 5 && setting.typeId !== 4 && ( */}
                {setting.typeId !== 5 && (
                  <Toggle
                    value={setting.value !== "FALSE" && true}
                    onChange={async (value) => {
                      if (inquirySettings?.value === "FALSE") return;

                      await api.put(
                        `/rest/group/${partner.id}/details/${
                          setting.id
                        }?value=${
                          value ? (setting.id === 4 ? 14 : "TRUE") : "FALSE"
                        }`
                      );
                      await getInquirySettings();
                      trackClickInquirySettings(
                        setting.id === 3
                          ? "당일 예약 문의"
                          : setting.id === 6
                          ? "가능한 빨리"
                          : "아무시간",
                        value,
                        partner.title
                      );
                    }}
                  />
                )}
              </HFlex>
              {/* {setting.selectOptions && ( */}
              {setting.typeId === 4 && setting.value !== "FALSE" && (
                <PeriodSelect
                  defaultIndex={inquiryPeriodItems.findIndex(
                    (i) => i.value === Number(setting.value)
                  )}
                  items={inquiryPeriodItems}
                  onChange={async (value) => {
                    await api.put(
                      `/rest/group/${partner.id}/details/${setting.id}?value=${value}`
                    );
                    await getInquirySettings();
                    trackClickInquirySettings(
                      "예약 문의 가능 기간",
                      value.toString(),
                      partner.title
                    );
                  }}
                />
              )}
              {setting.typeId === 5 && (
                <PeriodSelect
                  // defaultIndex={setting.selectOptions.findIndex(
                  //   (i) => i.value.toString() === setting.value?.toString()
                  defaultIndex={inquiryIntervalItems.findIndex(
                    (i) => i.value === Number(setting.value)
                  )}
                  // items={setting.selectOptions}
                  items={inquiryIntervalItems}
                  onChange={async (value) => {
                    await api.put(
                      `/rest/group/${partner.id}/details/${setting.id}?value=${value}`
                    );
                    await getInquirySettings();
                    trackClickInquirySettings(
                      "예약 시간 간격",
                      value.toString(),
                      partner.title
                    );
                  }}
                />
              )}
            </VFlex>
          ))}
        </VFlex>
      </VFlex>
    </VFlex>
  );
}

export default InquirySetting;
