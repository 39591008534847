import { addYears } from "date-fns";
import { CustomerListSortType } from "../providers/types";

export const mondayFirstDays = ["월", "화", "수", "목", "금", "토", "일"];
export const days = ["일", "월", "화", "수", "목", "금", "토"];
export const sex = ["남아", "여아"];
export const NEUTRALIZED = ["했어요", "안했어요", "모르겠어요"];
export const NEUTRALIZED_VALUES = ["NEUTERED", "NOT_NEUTERED", "UNKNOWN"];

export const dayOfWeek = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
];

export const ordinals = ["첫번째 주", "두번째 주", "세번째 주", "네번째 주"];

export const shortOrdinals = [
  "첫째",
  "둘째",
  "셋째",
  "넷째",
  "다섯째",
  "여섯째",
];

export const letterGroup = [
  "A-Z",
  "ㄱ",
  "ㄴ",
  "ㄷ",
  "ㄹ",
  "ㅁ",
  "ㅂ",
  "ㅅ",
  "ㅇ",
  "ㅈ",
  "ㅊ",
  "ㅋ",
  "ㅌ",
  "ㅍ",
  "ㅎ",
];

export const scheduleStatusColor = [
  "#27BD63",
  "#444845",
  "#27BD63",
  "#27BD63",
  "#27BD63",
  "#FF4F17",
  "#FF4F17",
  "#FF4F17",
  "#FF4F17",
  "#FF4F17",
  "#27BD63",
];

export const scheduleStatusString = [
  "예약문의",
  "문의종료",
  "예약진행중",
  "예약확정",
  "이용완료",
  "예약취소",
  "예약취소",
  "노쇼",
  "삭제",
  "삭제",
  "방문확정",
];

export const staffColors: Record<
  string,
  { mainColor: string; bgColor: string }
> = {
  0: {
    mainColor: "#F23326",
    bgColor: "#FFCBC8",
  },
  1: {
    mainColor: "#DB2E7C",
    bgColor: "#FFCAF3",
  },
  2: {
    mainColor: "#F17D17",
    bgColor: "#FFD0A7",
  },
  3: {
    mainColor: "#E0BC00",
    bgColor: "#FFF7A7",
  },
  4: {
    mainColor: "#1CCE18",
    bgColor: "#CEFFCD",
  },
  5: {
    mainColor: "#0085CF",
    bgColor: "#BDE8FF",
  },
  6: {
    mainColor: "#2A3AEA",
    bgColor: "#D2D0FF",
  },
};

// export const staffColors: Record<string, { mainColor: string; bgColor: string }> = {
//   0: {
//     mainColor: '#FF857D',
//     bgColor: '#FFD8D8',
//   },
//   1: {
//     mainColor: '#FF79A9',
//     bgColor: '#FFE0F3',
//   },
//   2: {
//     mainColor: '#FFA360',
//     bgColor: '#FFE8D3',
//   },
//   3: {
//     mainColor: '#FFCC48',
//     bgColor: '#FFF2B4',
//   },
//   4: {
//     mainColor: '#4EC78D',
//     bgColor: '#C3F9DF',
//   },
//   5: {
//     mainColor: '#61A5FF',
//     bgColor: '#CFE3FF',
//   },
//   6: {
//     mainColor: '#CB87FF',
//     bgColor: '#EFDBFF',
//   },
// };

export const CUSTOMER_LIST_SORT_TYPE: {
  label: string;
  value: CustomerListSortType;
}[] = [
  { label: "최근 방문순", value: "LAST_VISITED" },
  { label: "최신 등록순", value: "CREATED" },
];

export const SORT_ORDER: {
  [key: string]: string;
} = {
  ASC: "오름차순",
  DESC: "내림차순",
};

export const CUSTOMER_SORT_CATEGORY: {
  [key: string]: string;
} = {
  PET_VISITED: "방문순",
  PET_CREATED: "등록순",
};

export const SPECIES_OPTIONS = [
  { label: "강아지", value: 4 },
  { label: "고양이", value: 5 },
];
export const BIRTH_TYPE_OPTIONS = [
  { label: "생년월일을 알고 있어요", value: "BIRTHDAY" },
  { label: "나이만 알고 있어요", value: "AGE" },
] as const;

export const PET_BIRTHDAY_MIN_DATE = new Date("1960-01-01");
export const PET_BIRTHDAY_MAX_DATE = new Date();

export const RESERVATION_MIN_DATE = new Date("2000-01-01");
export const RESERVATION_MAX_DATE = addYears(new Date(), 5);
export const MAIN_SERVICE_TYPE_ID = 198;
