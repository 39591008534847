import { useState } from "react";

import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Flex from "../../../layouts/Flex";
import Text from "../../../layouts/Text";
import Image from "../../../layouts/Image";
import Textarea from "../../../layouts/TextareaV2";

import { Pet } from "../../../providers/types";

function EditCustomerDeletedReason({
  popPopup,
  deletedReason,
  editDeletedReason,
}: {
  popPopup: () => void;
  deletedReason: string;
  editDeletedReason: (reason: string) => void;
}) {
  const [newDeletedReason, setNewDeletedReason] = useState(deletedReason || "");

  return (
    <VFlex width={530} height={"95vh"} p-24>
      <HFlex p-24-b>
        <Text t-24-600-s8>고객 삭제 사유 작성</Text>
        <Flex f-1 />
        <Image
          src="/icons/close.svg"
          size={24}
          clickable
          onClick={() => popPopup()}
        />
      </HFlex>

      <Textarea
        placeholder="예) 주소 이전으로 인해 샵 변경"
        value={newDeletedReason}
        onChangeValue={(v) => {
          if (v?.length || 0 <= 800) setNewDeletedReason(v as string);
        }}
        height={160}
        maxLength={800}
      />

      <Flex f-1 />

      <Flex
        c-c
        bc-g1
        bdr-16
        p-16-tb
        t-18-600-tw
        onClick={() => {
          editDeletedReason(newDeletedReason);
          popPopup();
        }}
        clickable
      >
        <Text>저장</Text>
      </Flex>
    </VFlex>
  );
}

export default EditCustomerDeletedReason;
