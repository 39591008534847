import { PropsWithChildren } from "react";

import HFlex from "./HFlex";
import Image from "./Image";
import Text from "./Text";
import VFlex from "./VFlex";

interface SanckbarProps extends PropsWithChildren {
  width?: number | string;
  height?: number | string;
  text: string;
  action?: string;
  close?: boolean;
  actionOnClick?: () => void;
  onClick?: () => void;
}

function Snackbar({
  width,
  height,
  text,
  action,
  close,
  actionOnClick,
  onClick,
}: SanckbarProps) {
  return (
    <HFlex
      a-c
      j-b
      g-8
      p-16-tb
      p-24-rl
      width={width ? width : 380}
      height={height ? height : 64}
      bc-s8
      bdr-24
    >
      <VFlex
        t-16-500-w
        l-m
        style={{
          maxWidth: 204,
        }}
      >
        {text}
      </VFlex>
      <HFlex a-c j-b g-8>
        {action && (
          <Text
            t-16-600-gr5
            p-12-tb
            p-24-rl
            bdr-16
            snack-hover
            clickable
            onClick={actionOnClick}
          >
            {action}
          </Text>
        )}

        {close && (
          <Image
            size={24}
            src={`/icons/navigation/close_t8.svg`}
            clickable
            onClick={onClick}
          />
        )}
      </HFlex>
    </HFlex>
  );
}

export default Snackbar;
