import { ReactElement, useCallback, useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import HFlex from "../layouts/HFlex";
import Text from "../layouts/Text";
import VFlex from "../layouts/VFlex";
import Image from "../layouts/Image";

export type AutocompleteItem = {
  value: any;
  label: string;
  icon?: string;
  status?: boolean;
};

function AddressAutocomplete({
  value,
  isDisabled,
  caption,
  required,
  placeholder,
  onChange,
  onClick,
  onInputChange,
}: {
  value?: any;
  isDisabled?: boolean;
  caption?: string;
  required?: boolean;
  placeholder?: string;
  onChange?: (value: AutocompleteItem | undefined) => void;
  onClick?: () => void;
  onInputChange?: (value: string) => Promise<AutocompleteItem[]>;
}): ReactElement {
  const [innerValue, setInnerValue] = useState<AutocompleteItem | undefined>(
    value
  );
  const loadOptions = useCallback(
    (inputValue: string, callback: (options: AutocompleteItem[]) => void) => {
      onInputChange?.(inputValue)?.then((options) => callback(options));
    },
    [onInputChange]
  );
  // when value/options is changed from outside, update innerValue
  useEffect(() => {
    setInnerValue(value);
  }, [value, setInnerValue]);
  return (
    <VFlex g-8 onClick={(e) => onClick?.()}>
      {caption && (
        <HFlex g-8>
          <Text t-14-s8>{caption}</Text>
          {required && <Text t-14-700-rd1>*</Text>}
        </HFlex>
      )}
      <AsyncSelect
        cacheOptions
        placeholder={placeholder}
        loadOptions={loadOptions}
        //key={innerValue?.value.toString()}
        isDisabled={isDisabled}
        loadingMessage={() => "로딩중..."}
        noOptionsMessage={() => "검색 결과가 없습니다."}
        styles={{
          placeholder: (style) => ({
            ...style,
            color: "#EBEDEC",
            fontSize: "16px",
            lineHeight: "24px",
          }),
          option: (_provided, state) => ({
            width: "100%",
            height: "56px",
            color: "#191C1A",
            display: "flex",
            alignItems: "center",
            fontWeight: 500,
            backgroundColor: state.isFocused ? "#F8F9F8" : "white",
            fontSize: "16px",
            lineHeight: "24px",
            paddingLeft: "24px",
            WebkitUserSelect: "none",
            msUserSelect: "none",
            userSelect: "none",
          }),
          control: () => ({
            display: "flex",
            height: "56px",
            backgroundColor: "#F8F9F8",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "#EAF2EC",
            borderRadius: "16px",
            alignItems: "center",
            WebkitUserSelect: "none",
            msUserSelect: "none",
            userSelect: "none",
          }),
          indicatorSeparator: () => ({}),
          valueContainer: () => ({
            flex: 1,
            display: "grid",
            marginTop: "16px",
            marginBottom: "16px",
            marginLeft: "24px",
          }),
          singleValue: (style) => {
            return {
              ...style,
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "24px",
            };
          },
          menu: () => ({
            marginTop: "8px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "#EAF2EC",
            borderRadius: "8px",
            position: "absolute",
            backgroundColor: "white",
            top: "100%",
            width: "100%",
            maxHeight: "190px",
            overflow: "scroll",
          }),
          menuPortal: (provided) => ({ ...provided, zIndex: 100000000000 }),
          menuList: (provided) => ({
            ...provided,
            maxHeight: "190px",
          }),
          dropdownIndicator: () => ({
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#1D201F",
            width: "24px",
            height: "24px",
            marginRight: "24px",
            overflow: "scroll",
          }),
        }}
        formatOptionLabel={(data) => (
          <HFlex a-c g-16>
            {!!data.icon && <Image size={24} src={`/${data.icon}.png`} />}
            <HFlex a-c g-8>
              {data.label}
              {!!data.status && <VFlex width={8} height={8} bc-gr5 bdr-8 />}
            </HFlex>
          </HFlex>
        )}
        components={{
          DropdownIndicator: () => (
            <VFlex c-c width={56} height={56}>
              <Image size={24} src={`/icons/chevron_down.png`} />
            </VFlex>
          ),
        }}
        value={innerValue}
        onChange={(item) => {
          setInnerValue(item ?? undefined);
          onChange?.(item ?? undefined);
        }}
        defaultOptions={innerValue ? [innerValue] : []}
        menuPortalTarget={document.querySelector("body")}
        isSearchable={true}
      />
    </VFlex>
  );
}

export default AddressAutocomplete;
