import _ from "lodash";
import { useMemo } from "react";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import api from "../common/api";
import { usePartner } from "./partner";
import { Partner, Questionnare, QuestionnareTemplateItem } from "./types";

const questionnairesStore = create(
  immer<{
    questionnaires: Questionnare[];
    fetchQuestionnaires: (partner: Partner) => () => void;
    fetchQuestionnaireTemplate: (
      partner: Partner
    ) => (templateId: number) => Promise<QuestionnareTemplateItem[]>;
  }>((set) => ({
    questionnaires: [],
    fetchQuestionnaires: (partner: Partner) => async () => {
      const questionnaires = await api.get(
        `/rest/questionnaires?groupId=${partner.id}`
      );
      set((state) => ({
        ...state,
        questionnaires,
      }));
    },
    fetchQuestionnaireTemplate:
      (partner: Partner) => async (templateId: number) => {
        const questionnaires = await api.get<QuestionnareTemplateItem[]>(
          `/ext/questionnaire/template/${templateId}?groupId=${partner.id}`
        );

        return questionnaires;
      },
  }))
);

export const useQuestionnaires = () => {
  const { partner } = usePartner();
  const { fetchQuestionnaires, fetchQuestionnaireTemplate, ...rest } =
    questionnairesStore();
  return {
    fetchQuestionnaires: useMemo(
      () => fetchQuestionnaires(partner),
      [fetchQuestionnaires, partner]
    ),
    fetchQuestionnaireTemplate: useMemo(
      () => fetchQuestionnaireTemplate(partner),
      [fetchQuestionnaireTemplate, partner]
    ),
    ...rest,
  };
};
