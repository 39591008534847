import { ReactElement, useEffect, useState } from "react";
import "./DatePickerV2.scss";
import ReactDatePicker from "react-datepicker";
import { setMonth, setYear } from "date-fns";
import { ko } from "date-fns/locale";
import VFlex from "../layouts/VFlex";
import HFlex from "../layouts/HFlex";
import Text from "../layouts/Text";
import Image from "../layouts/Image";
import Dropdown from "../layouts/Dropdown";
import Flex from "../layouts/Flex";

const VIEW_TYPE = ["YEAR", "MONTH", "DAY"] as const;

function DatePicker({
  trigger,
  date,
  setDate,
  minDate,
  maxDate,
}: {
  trigger: React.ReactNode;
  date?: Date;
  setDate: (dt: Date) => void;
  minDate?: Date;
  maxDate?: Date;
}): ReactElement {
  const [viewType, setViewType] = useState<(typeof VIEW_TYPE)[number]>(
    VIEW_TYPE[2]
  );
  const getNewDateTime = (dateTime: Date, newDateTime: Date) => {
    if (viewType === VIEW_TYPE[0]) {
      return setYear(dateTime, newDateTime.getFullYear());
    } else if (viewType === VIEW_TYPE[1]) {
      return setMonth(dateTime, newDateTime.getMonth());
    }
    return newDateTime;
  };
  return (
    <Dropdown
      position="left"
      trigger={trigger}
      renderOptions={(close) => (
        <VFlex classes={["date-time-picker"]}>
          <ReactDatePicker
            minDate={minDate}
            maxDate={maxDate}
            selected={date ?? new Date()}
            open
            onChange={(d, e) => {
              const newDate = getNewDateTime(date ?? new Date(), d!);

              setDate(newDate);

              if (
                VIEW_TYPE.findIndex((t) => t === viewType) + 1 !==
                VIEW_TYPE.length
              ) {
                setViewType(
                  VIEW_TYPE[VIEW_TYPE.findIndex((t) => t === viewType) + 1]
                );
              }

              e?.stopPropagation();
            }}
            openToDate={date}
            shouldCloseOnSelect={false}
            renderCustomHeader={({ decreaseMonth, increaseMonth, date }) => (
              <HFlex p-12-t p-20-b bd-b-t2 m-8-b>
                <HFlex
                  c-c
                  m-8-r
                  clickable
                  onClick={(e) => {
                    setViewType(VIEW_TYPE[0]);
                    e.stopPropagation();
                  }}
                >
                  <Text t-16-600-s8 m-12-l>
                    {date.getFullYear()}년
                  </Text>
                  <Image src="/icons/chevron_down.png" size={24} />
                </HFlex>
                <HFlex
                  c-c
                  clickable
                  onClick={(e) => {
                    setViewType(VIEW_TYPE[1]);
                    e.stopPropagation();
                  }}
                >
                  <Text t-16-600-s8>{date.getMonth() + 1}월</Text>
                  <Image src="/icons/chevron_down.png" size={24} />
                </HFlex>
                <Flex f-1 />
                {viewType === VIEW_TYPE[2] && (
                  <>
                    <Flex width={48} c-c>
                      <Image
                        src="/icons/icon_chevron_left_s6.svg"
                        size={24}
                        clickable
                        onClick={() => {
                          decreaseMonth();
                        }}
                      />
                    </Flex>
                    <Flex width={48} c-c>
                      <Image
                        src="/icons/icon_chevron_right_s6.svg"
                        size={24}
                        clickable
                        onClick={() => {
                          increaseMonth();
                        }}
                      />
                    </Flex>
                  </>
                )}
              </HFlex>
            )}
            renderDayContents={(dayOfMonth, date) => {
              return (
                <VFlex f-1>
                  <Flex classes={["day-container"]}>{dayOfMonth}</Flex>
                </VFlex>
              );
            }}
            showYearPicker={viewType === VIEW_TYPE[0]}
            showMonthYearPicker={viewType === VIEW_TYPE[1]}
            yearItemNumber={80}
            locale={ko}
          />
        </VFlex>
      )}
    />
  );
}

export default DatePicker;
