import { ReactElement, useEffect, useState } from "react";
import HFlex from "../layouts/HFlex";
import Text from "../layouts/Text";
import VFlex from "../layouts/VFlex";

function RadioButton({
  value,
  caption,
  required,
  options,
  onChange,
  isSmallOption,
  isConnected,
  isRound,
  isFlex,
  multiSelect,
  height,
}: {
  value?: string;
  caption?: string;
  required?: boolean;
  options: string[];
  onChange?: (value: string[] | string | undefined) => void;
  isSmallOption?: boolean;
  isConnected?: boolean;
  isRound?: boolean;
  isFlex?: boolean;
  multiSelect?: boolean;
  height?: number;
}): ReactElement {
  const [values, setValues] = useState<string[]>(value ? [value] : []);
  // when value/options is changed from outside, update innerValue
  useEffect(() => {
    setValues(value ? [value] : []);
  }, [value, setValues]);
  return (
    <VFlex g-8>
      {caption && (
        <HFlex g-8>
          <Text t-14-s8>{caption}</Text>
          {required && <Text t-14-700-rd1>*</Text>}
        </HFlex>
      )}
      <HFlex a-st height={height ?? 56} bdr-16 g-8={!isConnected}>
        {options.map((option, i) => (
          <HFlex key={i} f-1={isFlex}>
            <VFlex
              style={{
                ...(isSmallOption ? { width: 46, height: 46 } : {}),
                ...(isConnected && i === 0
                  ? {
                      borderTopLeftRadius: 16,
                      borderBottomLeftRadius: 16,
                    }
                  : {}),
                ...(isConnected && i === options.length - 1
                  ? {
                      borderTopRightRadius: 16,
                      borderBottomRightRadius: 16,
                    }
                  : {}),
              }}
              as-st={isSmallOption}
              f-1={!isSmallOption}
              clickable
              c-c
              bc-w={isFlex && values.includes(option)}
              bc-t4={isFlex && values.includes(option)}
              c-gr5={isFlex && values.includes(option)}
              c-s6={isFlex && !values.includes(option)}
              bd-gr5={!isFlex && values.includes(option)}
              bd-t7
              bdr-32={!isConnected && isRound}
              bdr-16={!isConnected && !isRound}
              p-16-rl={isConnected}
              key={i}
              onClick={() => {
                const newValues = values.includes(option)
                  ? multiSelect
                    ? values.filter((v) => v !== option)
                    : []
                  : multiSelect
                  ? [...values, option]
                  : [option];
                setValues(newValues);
                onChange?.(newValues);
              }}
            >
              <Text
                t-14-s9={isFlex}
                t-14-700-gr5={!isFlex && values.includes(option)}
                t-14-700-s4={!isFlex && !values.includes(option)}
              >
                {option}
              </Text>
            </VFlex>
          </HFlex>
        ))}
      </HFlex>
    </VFlex>
  );
}

export default RadioButton;
