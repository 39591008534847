import { ReactElement, useEffect, useMemo, useState } from "react";
import Select, { GroupBase, OptionsOrGroups } from "react-select";
import _ from "lodash";
import VFlex from "../layouts/VFlex";
import HFlex from "../layouts/HFlex";
import Image from "../layouts/Image";
import Text from "../layouts/Text";

export type DropdownItem = {
  value: any;
  label: string;
  obj?: any;
  icon?: string;
  status?: boolean;
};

function DropdownMini({
  value,
  values,
  isDisabled,
  caption,
  required,
  placeholder,
  placeholderColor,
  multi,
  isSearchable,
  onChange,
  onChangeValues,
  options,
}: {
  value?: any;
  values?: any[];
  isDisabled?: boolean;
  caption?: string;
  required?: boolean;
  placeholder?: string;
  placeholderColor?: string;
  multi?: boolean;
  isSearchable?: boolean;
  onChange?: (value: DropdownItem | undefined) => void;
  onChangeValues?: (values: DropdownItem[] | undefined) => void;
  options: OptionsOrGroups<DropdownItem, GroupBase<DropdownItem>>;
}): ReactElement {
  const flattenedOptions = useMemo(
    () =>
      _.flatten(
        options.map((option) =>
          (option as GroupBase<DropdownItem>).options
            ? (option as GroupBase<DropdownItem>).options
            : [option as DropdownItem]
        )
      ),
    [options]
  );
  const [innerValue, setInnerValue] = useState<DropdownItem | undefined>(
    flattenedOptions.find((option) => option.value === value)
  );
  const [innerValues, setInnerValues] = useState<DropdownItem[] | undefined>(
    values
      ? flattenedOptions.filter((option) => values.includes(option.value))
      : []
  );
  // when value/options is changed from outside, update innerValue
  useEffect(() => {
    setInnerValue(flattenedOptions.find((option) => option.value === value));
    setInnerValues(
      values
        ? flattenedOptions.filter((option) => values.includes(option.value))
        : []
    );
  }, [value, values, flattenedOptions, setInnerValue, setInnerValues]);
  return (
    <VFlex g-8 clickable={value === 3}>
      {caption && (
        <HFlex g-8>
          <Text t-14-s8>{caption}</Text>
          {required && <Text t-14-700-rd1>*</Text>}
        </HFlex>
      )}
      {multi ? (
        <Select
          key={JSON.stringify(innerValues)}
          isDisabled={isDisabled}
          isMulti={true}
          isClearable={false}
          noOptionsMessage={() => "검색 결과가 없습니다."}
          placeholder={placeholder ?? ""}
          styles={{
            option: (_provided, state) => ({
              width: "100%",
              height: "46px",
              color: "#383B3A",
              display: "flex",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "22px",
              paddingLeft: "16px",
              alignItems: "center",
              backgroundColor: state.isFocused ? "#F8F9F8" : "white",
              WebkitUserSelect: "none",
              msUserSelect: "none",
              userSelect: "none",
              cursor: "pointer",
            }),
            control: () => ({
              display: "flex",
              justifyContent: "space-between",
              width: 328,
              minHeight: "30px",
            }),
            valueContainer: () => ({
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 6,
              padding: 0,
              fontSize: "14px",
              lineHeight: "22px",
            }),
            menu: () => ({
              left: 0,
              top: 4,
              position: "absolute",
              backgroundColor: "#fff",
              width: 328,
              maxWidth: "328px",
              border: "1px solid #E1E3DF",
              boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.2)",
              borderRadius: 12,
              overflow: "hidden",
            }),
            menuPortal: (provided) => ({ ...provided, zIndex: 100000000000 }),
            menuList: (provided) => ({
              ...provided,
              maxHeight: "190px",
            }),
            dropdownIndicator: () => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#1D201F",
              width: "24px",
              height: "24px",
              marginRight: "24px",
              overflow: "scroll",
            }),
            multiValue: (style) => {
              return {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "115px",
                minWidth: "115px",
                height: 34,
                fontSize: "14px",
                border: "1px solid #DEE2E0",
                borderRadius: "12px",
              };
            },
          }}
          formatOptionLabel={(data) => (
            <HFlex a-c g-16>
              {!!data.icon && <Image size={24} src={`/${data.icon}.png`} />}
              <HFlex
                a-c
                g-8
                l-1
                t-14
                style={{
                  display: "block",
                }}
              >
                {data.label}
                {!!data.status && <VFlex width={8} height={8} bc-gr5 bdr-8 />}
              </HFlex>
            </HFlex>
          )}
          components={{
            DropdownIndicator: () => (
              <Image
                size={24}
                src={`/icons/navigation/arrow_drop_down_s3.svg`}
                style={{ cursor: "pointer" }}
              />
            ),
            MultiValueRemove: ({ innerProps: { className, ...rest } }) => {
              return (
                <div {...rest}>
                  <VFlex c-c>
                    <Image size={18} src={`/icons/navigation/close.svg`} />
                  </VFlex>
                </div>
              );
            },
            IndicatorSeparator: () => null,
          }}
          value={innerValues}
          onChange={(item) => {
            setInnerValues([...item]);
            onChangeValues?.([...item]);
          }}
          options={options}
          menuPortalTarget={document.querySelector("body")}
          isSearchable={isSearchable}
        />
      ) : (
        <Select
          key={innerValue?.value.toString()}
          isDisabled={isDisabled}
          isMulti={false}
          noOptionsMessage={() => "검색 결과가 없습니다."}
          placeholder={placeholder ?? ""}
          styles={{
            control: () => ({
              padding: "6px 4px 6px 12px",
              display: "flex",
              alignItems: "center",
              WebkitUserSelect: "none",
              msUserSelect: "none",
              userSelect: "none",
              border: "1px solid #DEE2E0",
              borderRadius: 12,
              background:
                value === 4 || value === 6 || value === 7 ? "#F8F9F8" : "#fff",
            }),
            valueContainer: () => ({
              flex: 1,
              display: "flex",
              gap: 4,
              alignItems: "center",
            }),
            menu: () => ({
              left: 0,
              top: 0,
              marginTop: "8px",
              position: "absolute",
              backgroundColor: "#fff",
              width: "104px",
              maxHeight: "264px",
              border: "1px solid #E1E3DF",
              boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.2)",
              borderRadius: 8,
            }),
            menuPortal: (provided) => ({
              ...provided,
              width: 100,
              zIndex: 100000000000,
            }),
            singleValue: () => {
              return {
                //이용완료 4, 예약취소 6, 노쇼 7
                color:
                  value === 4 || value === 6 || value === 7
                    ? "#BFC6C2"
                    : "#383B3A",
                fontWeight: 500,
                fontSize: "14px",
                lineHeight: "22px",
                cursor: "pointer",
              };
            },
          }}
          components={{
            IndicatorSeparator: () => null,
            Option: (props: any) => {
              const { innerProps, innerRef } = props;
              return (
                <article
                  ref={innerRef}
                  {...innerProps}
                  style={{
                    marginTop: 2,
                    marginBottom: 2,
                    width: "100%",
                    height: "38px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor: props.isFocused ? "#F8F9F8" : "white",
                    WebkitUserSelect: "none",
                    msUserSelect: "none",
                    userSelect: "none",
                    cursor: "pointer",
                  }}
                >
                  <HFlex a-c p-24-rl width={"100%"}>
                    <Text t-14-500 c-s8 f-1>
                      {props.data.label}
                    </Text>
                  </HFlex>
                </article>
              );
            },
            DropdownIndicator: () => (
              <Image
                size={24}
                src={`/icons/navigation/arrow_drop_down_${
                  value === 4 || value === 6 || value === 7 ? "s1" : "s3"
                }.svg`}
                style={{ cursor: "pointer" }}
              />
            ),
          }}
          value={innerValue}
          onChange={(item) => {
            setInnerValue(item ?? undefined);
            onChange?.(item ?? undefined);
          }}
          options={options}
          menuPortalTarget={document.querySelector("body")}
          isSearchable={isSearchable}
        />
      )}
    </VFlex>
  );
}

export default DropdownMini;
