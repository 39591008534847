import _ from "lodash";
import { useMemo } from "react";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import api from "../common/api";
import { usePartner } from "./partner";
import { Partner, ProductGroup } from "./types";

const productGroupsStore = create(
  immer<{
    productGroups: ProductGroup[];
    fetchProductGroups: (partner: Partner) => () => void;
  }>((set) => ({
    productGroups: [],
    fetchProductGroups: (partner: Partner) => async () => {
      const productGroups = await api.get(
        `/rest/group/${partner.id}/product/intangibles`
      );
      set((state) => ({
        ...state,
        productGroups: _(productGroups)
          .groupBy((productGroup) =>
            JSON.stringify({
              menuType: productGroup.menuType.name,
              serviceType: productGroup.serviceType.name,
            })
          )
          .entries()
          .map(([key, value]) => [JSON.parse(key), value])
          .map(([{ menuType, serviceType }, productList]) => ({
            menuType,
            serviceType,
            productList,
          }))
          .value(),
      }));
    },
  }))
);

export const useProductGroups = () => {
  const { partner } = usePartner();
  const { fetchProductGroups, ...rest } = productGroupsStore();
  return {
    fetchProductGroups: useMemo(
      () => fetchProductGroups(partner),
      [fetchProductGroups, partner]
    ),
    ...rest,
  };
};
