import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import api, { initializeAxios } from "../../common/api";
import { formatPhoneNumber } from "../../common/format";
import useIsMobile from "../../common/useIsMobile";
import { useAmplitudeTrack } from "../../common/useAmplitudeTrack";

import LoadingView from "../../layouts/LoadingView";
import TextInput from "../../layouts/TextInput";
import VFlex from "../../layouts/VFlex";
import HFlex from "../../layouts/HFlex";
import Text from "../../layouts/Text";
import Button from "../../layouts/Button";

import SvgIcon, { SvgIconType } from "../../svg";

declare global {
  var IMP: any;
}

function Login() {
  const { trackClickLogin, setIdentifyCellnumber, trackClickSingup } =
    useAmplitudeTrack();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [phone, setPhone] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>(false);
  const ready = useMemo(
    () => phone?.length === 13 && !!password && password.length >= 8,
    [phone, password]
  );

  const onLogin = useCallback(async () => {
    if (isMobile) {
      window.location.href = "https://pawdlypartners.page.link/c6wA";
      return;
    }

    setLoading(true);
    const token = await api.post(
      `/rest/user/sign-in`,
      {},
      {
        params: {
          username: phone,
          password,
        },
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "",
        },
      }
    );
    if (token) {
      localStorage.setItem("token", token.toString());
      initializeAxios(token);

      window.location.href = "/main";
    } else {
      localStorage.removeItem("token");
      setError(true);
      setLoading(false);
    }
    trackClickLogin(phone ? phone : "");
    setIdentifyCellnumber(phone ? phone : "");
  }, [phone, password, isMobile]);

  return (
    <LoadingView bc-w f-1 c-c loading={loading}>
      <VFlex g-16 p-24-rl width={428}>
        <HFlex as-c m-24-b>
          <SvgIcon icon={SvgIconType.LogoText} width={137.14} height={24} />
        </HFlex>
        <VFlex g-8>
          <TextInput
            placeholder={"휴대폰 번호"}
            value={phone}
            inputMode="numeric"
            onKeyDown={(e) => {
              if (
                !(
                  e.key === "Tab" ||
                  e.key === "Backspace" ||
                  /[0-9]/.test(e.key)
                )
              ) {
                e.preventDefault();
              }
            }}
            onChangeValue={(value) => {
              const result = value?.replace(/[^0-9]/g, "") || "";
              setPhone((phone) => formatPhoneNumber(phone, result));
            }}
          />
          <TextInput
            pwd
            error={error}
            placeholder={"비밀번호"}
            value={password}
            onChangeValue={(value) => setPassword(value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && ready) {
                onLogin();
              }
            }}
          />
          {error && (
            <HFlex a-s g-8>
              <SvgIcon icon={SvgIconType.Error} size={24} />
              <Text t-14-err1 l-m>
                휴대폰 번호 또는 비밀번호를 잘못 입력했어요.{"\n"}입력하신
                내용을 다시 확인해주세요.
              </Text>
            </HFlex>
          )}
        </VFlex>
        <Button
          p-16-tb
          as-st
          enabled={ready}
          type={"cta-lg"}
          onClick={onLogin}
          caption={"로그인 하기"}
        />
        <HFlex m-24-t>
          <HFlex
            f-1
            c-c
            clickable
            onClick={() => {
              if (isMobile) {
                window.location.href = "https://pawdlypartners.page.link/c6wA";
                return;
              }
              trackClickSingup();
              navigate("/signup");
            }}
          >
            <Text t-16-700-s4>회원가입</Text>
          </HFlex>
          <HFlex f-1 c-c clickable onClick={() => navigate("/problem")}>
            <Text t-16-700-gr5>아이디/비밀번호 변경</Text>
          </HFlex>
        </HFlex>
      </VFlex>
    </LoadingView>
  );
}

export default Login;
