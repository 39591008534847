import { Fragment, useMemo } from "react";
import ButtonV2 from "../../../../layouts/ButtonV2";
import Divider from "../../../../layouts/Divider";
import Flex from "../../../../layouts/Flex";
import HFlex from "../../../../layouts/HFlex";
import Text from "../../../../layouts/Text";
import VFlex from "../../../../layouts/VFlex";
import useCustomerFormStore, {
  CustomerInfo,
} from "../../../../providers/customerForm";
import GuardianInfo from "../GuardianInfo";
import api from "../../../../common/api";
import { usePartner } from "../../../../providers/partner";
import cloneDeep from "lodash/cloneDeep";
import { validatePhoneNumber } from "../../../../common/utils/customers";
import { formatPhoneNumber } from "../../../../common/format";
import ConfirmPopup from "../../../../popup/ConfirmPopup";
import { PopupKind, PopupAlign } from "../../../../popup/Popup";
import { pushPopup } from "../../../../providers/popups";
import { Guardian } from "../../../../providers/types";

const GUARDIAN_NUMBER_LIMIT = 10;

const GuardianInfoTab = () => {
  const { setData, guardians, addNewGuardian, deleteGuardian } =
    useCustomerFormStore();
  const { partner } = usePartner();

  const checkRegistedGuardian = (cellnumber: string, index: number) => {
    return !!guardians
      .filter((_, i) => i !== index)
      .find((g) => g.cellnumber === cellnumber);
  };

  const getRegisteredGuardianData = async (
    cellnumber: string,
    index: number
  ) => {
    const response = await api.get<Guardian>(
      `/rest/group/${partner.id}/guardian?cellnumber=${cellnumber}`
    );

    if (response?.id) {
      (document.activeElement as HTMLInputElement)?.blur();
      pushPopup({
        element: ConfirmPopup,
        kind: PopupKind.Popup,
        align: PopupAlign.TopCenter,
        dimClick: "none",
        props: {
          icon: "/icons/icon_noti_info.png",
          title: "이미 등록된 보호자입니다. 정보를 불러올까요?",
          onConfirm: () => {
            const newGuardians = cloneDeep(guardians);
            newGuardians[index].id = response.id;
            newGuardians[index].name = response.name;
            newGuardians[index].note = response.note;
            newGuardians[index].cellnumber = cellnumber;

            setData({
              guardians: newGuardians,
            });
          },
          onCancel: () => {
            const newGuardians = cloneDeep(guardians);
            newGuardians[index].id = undefined;
            newGuardians[index].cellnumber = undefined;

            setData({
              guardians: newGuardians,
            });
          },
          confirmButtonType: "confirm",
          confirmButtonLabel: "불러오기",
          Contents: () => (
            <VFlex bc-t1 bd-t2 bdr-16 p-16 m-8-t g-4>
              {response.name && <Text t-14-500-s7>이름: {response.name}</Text>}
              <Text t-14-500-s7>전화번호: {response.cellnumber}</Text>
              {response.note && <Text t-14-500-s7>메모: {response.note}</Text>}
            </VFlex>
          ),
        },
      });
    } else {
      return;
    }
  };

  return (
    <VFlex a-c f-1 ovf-h>
      <VFlex style={{ width: "100%", padding: "32px 20%" }} f-1 ovf-a>
        <VFlex>
          {[...guardians].map((g, i) => (
            <Fragment key={g.id || i}>
              {i !== 0 && <Divider height={1} m-24-b />}
              <GuardianInfo
                key={i}
                index={i}
                guardian={g}
                existCoparent={guardians.length > 1}
                handleChangeName={(v) => {
                  const newGuardians = cloneDeep(guardians);
                  newGuardians[i].name = v;

                  setData({
                    guardians: newGuardians,
                  });
                }}
                handleChangeCellNumber={(v) => {
                  if (v) {
                    const newGuardians = cloneDeep(guardians);

                    newGuardians[i].cellnumber =
                      formatPhoneNumber(newGuardians[i].cellnumber, v) ?? "";
                    newGuardians[i].id = undefined;

                    setData({
                      guardians: newGuardians,
                    });
                  } else {
                    const newGuardians = cloneDeep(guardians);

                    newGuardians[i].cellnumber = "";
                    newGuardians[i].id = undefined;

                    setData({
                      guardians: newGuardians,
                    });
                  }

                  if (v && validatePhoneNumber(v)) {
                    if (checkRegistedGuardian(v, i)) {
                      (document.activeElement as HTMLInputElement)?.blur();
                      pushPopup({
                        element: ConfirmPopup,
                        kind: PopupKind.Popup,
                        align: PopupAlign.TopCenter,
                        dimClick: "none",
                        props: {
                          title: "동일한 보호자를 중복으로 등록할 수 없습니다",
                          cancelButtonDisplay: "none",
                          onConfirm: () => {
                            const newGuardians = cloneDeep(guardians);

                            newGuardians[i].id = undefined;
                            newGuardians[i].cellnumber = undefined;

                            setData({
                              guardians: newGuardians,
                            });
                          },
                        },
                      });
                    } else {
                      getRegisteredGuardianData(v, i);
                    }
                  }
                }}
                handleChangeMemo={(v) => {
                  const newGuardians = cloneDeep(guardians);
                  newGuardians[i].note = v;

                  setData({
                    guardians: newGuardians,
                  });
                }}
                setPrimaryGuardian={() => {
                  const newGuardians = cloneDeep(guardians).map((g) => ({
                    ...g,
                    primaryGuardian: false,
                  }));

                  newGuardians[i].primaryGuardian = true;

                  setData({
                    guardians: newGuardians,
                  });
                }}
                deleteGuardian={() => deleteGuardian(i)}
              />
            </Fragment>
          ))}
        </VFlex>

        {guardians.length < GUARDIAN_NUMBER_LIMIT && (
          <Flex>
            <ButtonV2
              type="grey_tint"
              onClick={addNewGuardian}
              icon="/icons/icon_add_s8.svg"
              iconSize={18}
            >
              보호자 추가
            </ButtonV2>
          </Flex>
        )}
      </VFlex>
    </VFlex>
  );
};

export default GuardianInfoTab;
