import { useCallback, useContext, useEffect, useState } from "react";
import { ko } from "date-fns/locale";
import { format } from "date-fns";
import { NoticeDetailInterface } from "../../../providers/types";
import { pushPopup, usePopups } from "../../../providers/popups";
import api from "../../../common/api";

import Image from "../../../layouts/Image";
import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Text from "../../../layouts/Text";
import Flex from "../../../layouts/Flex";
import { Swiper, SwiperSlide } from "swiper/react";
import Absolute from "../../../layouts/Absolute";
import { PopupKind } from "../../../popup/Popup";
import PhotosSwiperPopup from "../../../components/PhotosSwiperPopup";

interface NoticeDetailPopupInterface {
  reservationNumber: string;
  created?: number;
}

function NoticeDetail({
  reservationNumber,
  created,
}: NoticeDetailPopupInterface) {
  const { popPopup } = usePopups();
  const [detail, setDetail] = useState<NoticeDetailInterface>();

  const loadSchedule = useCallback(async () => {
    const noticeDetail = await api.get(
      `/ext/reservation/notice?key=${reservationNumber}`
    );
    setDetail(noticeDetail);
  }, [reservationNumber]);

  useEffect(() => {
    loadSchedule();
  }, [loadSchedule, reservationNumber, created]);

  const [slideIndex, setSlideIndex] = useState(0);

  return (
    <VFlex f-1 p-24-tb m-8-rl>
      <HFlex p-24-rl>
        <Flex f-1 />
        <Image
          src={`/icons/popup_close.png`}
          alt="popup_close"
          size={24}
          onClick={() => {
            popPopup();
          }}
        />
      </HFlex>
      <VFlex m-32-rl m-24-b m-16-t>
        <Text t-20-700 style={{ color: "#383B3A" }}>
          <Text c-gr5>{detail?.pet.name}</Text>의 알림장
        </Text>
        <Text t-16-500-s4>
          {format(
            new Date((detail?.created ?? 0) * 1000),
            "yyyy년 M월 dd일 (EEEEE)",
            {
              locale: ko,
            }
          )}
        </Text>
      </VFlex>
      {detail?.attachments && (
        <Flex m-32-l>
          <Swiper
            style={{
              position: "relative",
              width: "100%",
              height: "160px",
              overflow: "hidden",
            }}
            spaceBetween={8}
            slidesPerView={2.5}
            scrollbar={{ draggable: true }}
            onActiveIndexChange={(swiper: any) =>
              setSlideIndex(swiper.activeIndex)
            }
            onClick={() => {
              pushPopup({
                kind: PopupKind.Swiper,
                element: PhotosSwiperPopup,
                key: "notice_photos",
                props: {
                  date: new Date((detail?.created ?? 0) * 1000),
                  petName: detail.pet.name,
                  attachments: detail.attachments,
                },
              });
            }}
          >
            {detail?.attachments.map((attachment, i) => (
              <SwiperSlide key={i} style={{ display: "inline-block" }}>
                <Image
                  width={214}
                  height={160}
                  src={attachment.path}
                  style={{ objectFit: "cover" }}
                  clickable
                />
              </SwiperSlide>
            ))}
            <Absolute
              left={16}
              bottom={16}
              top={"auto"}
              right={"auto"}
              style={{ zIndex: 100000 }}
            >
              <HFlex p-12-rl bdr-16 style={{ background: "rgba(0,0,0,0.5)" }}>
                <Text t-14-w>
                  {slideIndex + 1}/{detail?.attachments.length}
                </Text>
              </HFlex>
            </Absolute>
          </Swiper>
        </Flex>
      )}
      <VFlex g-24 m-32-rl m-32-t ovf-a>
        {detail?.notice && (
          <HFlex g-16>
            <Text width={120} t-14-500-s6>
              전달사항
            </Text>
            <Flex f-1 p-16 bc-t1 bd-t2 bdr-8 t-14 c-s7>
              {detail?.notice}
            </Flex>
          </HFlex>
        )}
        {(detail?.beautyManner || detail?.beautyMannerNotice) && (
          <HFlex g-16>
            <Text width={120} t-14-500-s6>
              미용 매너
            </Text>
            <Flex f-1 t-14-500-s7>
              {detail?.beautyManner || detail.beautyMannerNotice}
            </Flex>
          </HFlex>
        )}
        {detail?.notes &&
          detail?.notes.map((note, i) => {
            if (note.value.type === "관심필요") {
              return (
                <HFlex key={i} g-16>
                  <Text width={120} t-14-500-s6>
                    {note.type.value}
                  </Text>
                  <VFlex f-1 p-16 g-4 bc-t1 bd-t2 bdr-8 t-14-500-s7>
                    <Text c-sf>관찰이 필요해요</Text>
                    {note.note && note.note}
                  </VFlex>
                </HFlex>
              );
            }
            return (
              <HFlex key={i} g-16>
                <Text width={120} t-14-500-s6>
                  {note.type.value}
                </Text>
                <VFlex f-1 t-14-500-s7>
                  {note.value.type}
                </VFlex>
              </HFlex>
            );
          })}
      </VFlex>
    </VFlex>
  );
}

export default NoticeDetail;
