import { PropsWithChildren } from "react";

import "./ButtonV2.scss";
import HFlex from "./HFlex";
import Image from "./Image";
import { isNil } from "lodash";

interface ButtonProps extends PropsWithChildren {
  size?: "XS" | "S" | "M" | "L";
  type:
    | "filled"
    | "color_outlined"
    | "grey_tint"
    | "grey_outlined"
    | "warning"
    | "payment"
    | "text";
  badgeNumber?: number;
  icon?: string;
  iconSize?: number;
  onClick: () => void;
  enabled?: boolean;
}

function ButtonV2({
  type,
  size = "XS",
  icon,
  iconSize,
  onClick,
  children,
  enabled,
}: ButtonProps) {
  return (
    <button
      className={`${type} ${size} c-c`}
      onClick={onClick}
      style={
        !isNil(enabled) && !enabled
          ? { cursor: "default", opacity: 0.5 }
          : {
              cursor: "pointer",
              opacity: 1,
            }
      }
    >
      <HFlex g-4 a-c>
        {icon && <Image src={icon} size={iconSize} />}
        <span>{children}</span>
        {/* TODO Badge */}
      </HFlex>
    </button>
  );
}

export default ButtonV2;
