import { createContext, useCallback, useEffect, useState } from "react";
import { DetailsType, Schedule, Staff } from "../../../providers/types";

import TextInput from "../../../layouts/TextInput";
import Dropdown from "../../../components/Dropdown";
import Checkbox from "../../../components/Checkbox";
import Text from "../../../layouts/Text";
import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Image from "../../../layouts/Image";
import Button from "../../../layouts/Button";
import TextArea from "../../../layouts/TextArea";
import {
  differenceInMinutes,
  format,
  isSameDay,
  parseISO,
  setDate,
  setHours,
  setMinutes,
  setMonth,
  setYear,
  startOfDay,
} from "date-fns";
import {
  minutesToTimeString,
  timeStringToMinutes,
} from "../../../common/format";
import { toast } from "react-hot-toast";
import _ from "lodash";
import api from "../../../common/api";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";

import { usePartner } from "../../../providers/partner";
import { useSchedules } from "../../../providers/schedules";
import { PopupAlign, PopupKind } from "../../../popup/Popup";
import ConfirmPopup from "../../../popup/ConfirmPopup";
import { pushPopup } from "../../../providers/popups";
import { useStaffs } from "../../../providers/staffs";
import { useCount } from "../../../providers/count";
import { getNearestNextMinInter30 } from "../../../common/utils/reservation";
import DateTimePickerContainer from "../../../components/DateTimePicker";
import { ko } from "date-fns/locale";

type AddPersonalScheduleContextInterface = {};

export const AddPersonalScheduleContext = createContext<
  AddPersonalScheduleContextInterface | undefined
>(undefined);

function AddPersonalSchedule({
  popPopup,
  personalSchedule,
  initialStaffs,
  ...props
}: {
  popPopup: () => void;
  personalSchedule?: Schedule;
  initialStaffs?: Staff[];
  startDateTime?: Date;
  endDateTime?: Date;
}) {
  const { trackClickSavePersonalSchedule } = useAmplitudeTrack();
  const { partner } = usePartner();
  const { fetchSchedules } = useSchedules();
  const { fetchCount } = useCount();
  const { staffs } = useStaffs();
  const [scheduleName, setScheduleName] = useState<string | undefined>(
    undefined
  );
  const [startDateTime, setStartDateTime] = useState(
    props.startDateTime || getNearestNextMinInter30(new Date())
  );
  const [endDateTime, setEndDateTime] = useState(
    props.endDateTime || getNearestNextMinInter30(startDateTime)
  );

  const timeGap = endDateTime.getTime() - startDateTime.getTime();

  const [startDateTimeChanged, setStartDateTimeChanged] = useState(false);
  const [endDateTimeChanged, setEndDateTimeChanged] = useState(false);
  const [dateTimeError, setDateTimeError] = useState(false);
  const handleChangeStartDate = (sdt: Date) => {
    if (
      (!endDateTimeChanged && !startDateTimeChanged) ||
      (!endDateTimeChanged && startDateTimeChanged)
    ) {
      setEndDateTime((end) => {
        end = setYear(end, sdt.getFullYear());
        end = setMonth(end, sdt.getMonth());
        end = setDate(end, sdt.getDate());

        return end;
      });
    } else {
      // 에러 케이스추가
      setEndDateTime(new Date(sdt.getTime() + timeGap));
    }

    setStartDateTime(sdt);
  };

  useEffect(() => {
    if (startDateTime.getTime() > endDateTime.getTime()) {
      setDateTimeError(true);
    } else {
      setDateTimeError(false);
    }
  }, [startDateTime, endDateTime]);
  const [selectedStaffs, setSelectedStaffs] = useState<Staff[] | undefined>(
    initialStaffs || []
  );
  const [isAlldaySchedule, setIsAlldaySchedule] = useState(Boolean);
  const [memo, setMemo] = useState<string | undefined>(undefined);

  const [isStaff, setIsStaff] = useState<DetailsType>();
  const fetchDetils = async () => {
    const res = await api.get(
      `/rest/group/${partner.id}/details?type=CALENDAR_S`
    );
    setIsStaff(res[0]);
  };
  useEffect(() => {
    fetchDetils();
  }, []);

  const isChanged = useCallback(() => {
    if (personalSchedule) {
      return (
        JSON.stringify(selectedStaffs) !==
          JSON.stringify(personalSchedule.staffs) ||
        startDateTime.getTime() !== personalSchedule.startDateTime.getTime() ||
        endDateTime.getTime() !== personalSchedule.endDateTime.getTime() ||
        memo !== personalSchedule.memo
      );
    } else {
      return (
        !_.isNil(startDateTime) || !_.isNil(endDateTime) || memo || scheduleName
      );
    }
  }, [
    startDateTime,
    endDateTime,
    selectedStaffs,
    memo,
    scheduleName,
    personalSchedule,
  ]);
  const isCompleted = useCallback(() => {
    return (
      startDateTime &&
      (isAlldaySchedule ||
        (!_.isNil(startDateTime) && !_.isNil(endDateTime))) &&
      scheduleName &&
      selectedStaffs &&
      (isStaff?.value === "FALSE" &&
      !!(selectedStaffs?.length === 0 || selectedStaffs === undefined)
        ? false
        : true)
    );
  }, [
    startDateTime,
    endDateTime,
    isAlldaySchedule,
    scheduleName,
    selectedStaffs,
  ]);

  const handleConfirm = useCallback(async () => {
    let requiredTime = isAlldaySchedule
      ? {
          startTime: "00:00",
          endTime: "23:59",
        }
      : {
          startTime: format(startDateTime, "HH:mm"),
          endTime: format(endDateTime, "HH:mm"),
        };

    const params = {
      title: scheduleName,
      date: Math.floor(startDateTime.setHours(0, 0) / 1000),
      memo: memo ?? "",
      staffs: selectedStaffs?.map((staff) => staff.id),
      type: "EVENT",
      ...requiredTime,
    };

    if (isStaff?.value === "FALSE" && selectedStaffs?.length === 0) {
      return toast.error("담당자를 선택해주세요.");
    } else {
      if (personalSchedule?.reservationId) {
        await api.put(
          `/rest/group/${partner.id}/staff/schedule/${personalSchedule?.reservationId}?date=${params.date}&startTime=${requiredTime.startTime}&endTime=${requiredTime.endTime}&title=${scheduleName}&memo=${params.memo}&staffs=${params.staffs}&type=${params.type}`
        );

        toast.success("일정이 수정되었습니다.");
        popPopup();
        fetchSchedules(startDateTime);
      } else {
        await api.post(
          `/rest/group/${partner.id}/staff/schedule?date=${params.date}&startTime=${requiredTime.startTime}&endTime=${requiredTime.endTime}&title=${scheduleName}&memo=${params.memo}&staffs=${params.staffs}&type=${params.type}`
        );

        toast.success("일정이 등록되었습니다.");
        await fetchCount({
          date: Math.floor(startDateTime.setHours(0, 0) / 1000),
        });
        popPopup();
        fetchSchedules(startDateTime);
        trackClickSavePersonalSchedule();
      }
    }
  }, [
    startDateTime,
    endDateTime,
    memo,
    selectedStaffs,
    scheduleName,

    isAlldaySchedule,
    personalSchedule,
    fetchSchedules,
    partner,
    popPopup,
  ]);

  const deletePersonalSchedule = useCallback(() => {
    const deletRequest = async () =>
      await api.delete(
        `/rest/group/${partner.id}/staff/schedule/${personalSchedule?.reservationId}`
      );
    pushPopup({
      kind: PopupKind.Popup,
      align: PopupAlign.TopCenter,
      element: ConfirmPopup,
      props: {
        title: "해당 일정을 삭제하시겠습니까?",
        onConfirm: async () => {
          await deletRequest();
          await fetchSchedules(startDateTime);
          await fetchCount({
            date: startDateTime!.getTime() / 1000,
          });
          popPopup();
        },
      },
    });
  }, [partner, personalSchedule, fetchSchedules, popPopup]);

  useEffect(() => {
    if (personalSchedule) {
      setStartDateTime(personalSchedule.startDateTime);
      setEndDateTime(personalSchedule.endDateTime);
      setMemo(personalSchedule.memo);
      setScheduleName(personalSchedule.title);
      setSelectedStaffs(personalSchedule.staffs);
    }
  }, [personalSchedule]);

  return (
    <VFlex f-1 a-st p-24-t m-8-rl>
      <HFlex p-24-rl>
        <VFlex f-1 />
        <Image
          clickable
          size={24}
          src={`/icons/popup_close.png`}
          onClick={() => popPopup()}
        />
      </HFlex>
      <Text m-24-b p-24-rl t-20-700-s9>
        {personalSchedule?.reservationId ? "기타 일정 수정" : "기타 일정 등록"}
      </Text>
      <VFlex f-1 ovf-a g-16 p-24-trl>
        <TextInput
          required={true}
          caption={"일정 이름"}
          placeholder={"일정 이름"}
          value={scheduleName}
          onChangeValue={(value) => {
            setScheduleName(value);
          }}
          maxLength={20}
        />
        {!isAlldaySchedule && (
          <VFlex g-4>
            <HFlex>
              <Text t-14-500-s6 m-4-r>
                예약 일시
              </Text>
              <Text t-14-500-r1>*</Text>
            </HFlex>
            <VFlex g-8>
              <DateTimePickerContainer
                isRange
                showReservationCount
                startDateTime={startDateTime}
                setStartDateTime={(dt) => {
                  handleChangeStartDate(dt);
                  setStartDateTimeChanged(true);
                }}
                endDateTime={endDateTime}
                setEndDateTime={(dt) => {
                  setEndDateTime(dt);
                  setEndDateTimeChanged(true);
                }}
                renderTrigger={() => (
                  <HFlex
                    p-16-tb
                    p-24-l
                    p-16-r
                    bc-t1
                    bd-t3={!dateTimeError}
                    bd-r1={dateTimeError}
                    bdr-16
                    j-b
                  >
                    <Text t-16-500-s8 f-1>{`${format(
                      startDateTime,
                      "yyyy년 M월 d일 (E) a hh:mm",
                      { locale: ko }
                    )} ~ ${format(
                      endDateTime,
                      isSameDay(startDateTime, endDateTime)
                        ? "a hh:mm"
                        : "yyyy년 M월 d일 (E) a hh:mm",
                      {
                        locale: ko,
                      }
                    )}`}</Text>
                    <Image
                      src="/icons/navigation/arrow_drop_down_s3.svg"
                      size={22}
                      clickable
                    />
                  </HFlex>
                )}
              />
            </VFlex>
            {dateTimeError && (
              <Text t-14-500-r1>일정 종료는 시작 시간 이후여야 합니다.</Text>
            )}
          </VFlex>
        )}

        <Checkbox rightLabel="종일" onChange={setIsAlldaySchedule} />
        <Dropdown
          multi={true}
          values={selectedStaffs?.map((staff) => staff.id.toString())}
          options={staffs.map((staff) => ({
            value: staff.id.toString(),
            obj: staff,
            label: `${staff.name} ${staff.jobTitle}`,
          }))}
          caption={"담당자"}
          placeholder={
            isStaff?.value === "FALSE" ? "담당자를 선택하세요" : "미지정"
          }
          onChangeValues={(values) => {
            setSelectedStaffs(values?.map((value) => value.obj));
          }}
          required={true}
        />
        <TextArea
          caption="메모"
          value={memo}
          onChange={(e) => setMemo(e.target.value)}
          placeholder="메모 (0/500)"
        />
      </VFlex>

      <HFlex g-8 m-24-t p-32-rl height={86} a-c>
        <VFlex f-1 />
        {personalSchedule?.type && (
          <Button
            type="warning"
            caption="삭제"
            onClick={deletePersonalSchedule}
          />
        )}
        <Button
          type="cancel"
          caption="취소"
          onClick={() => {
            isChanged()
              ? pushPopup({
                  kind: PopupKind.Popup,
                  align: PopupAlign.TopCenter,
                  element: ConfirmPopup,
                  props: {
                    title: "변경사항을 저장하지 않고 닫을까요?",
                    onConfirm: () => {
                      popPopup();
                    },
                  },
                })
              : popPopup();
          }}
        />
        <Button
          type="confirm"
          caption="저장"
          enabled={!!(personalSchedule ? isChanged() : isCompleted())}
          onClick={() => {
            if (!(personalSchedule ? isChanged() : isCompleted())) {
              return;
            }

            handleConfirm();
          }}
        />
      </HFlex>
    </VFlex>
  );
}

export default AddPersonalSchedule;
