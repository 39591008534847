import { useEffect, useState } from "react";

import VFlex from "../../../../layouts/VFlex";
import Text from "../../../../layouts/Text";
import ColumnResize from "react-table-column-resizer";
import "../../../../layouts/ResizeTable.scss";

import api from "../../../../common/api";
import { GroupNotification } from "../../../../providers/types";
import { usePartner } from "../../../../providers/partner";
import { format } from "date-fns";
import { ko } from "date-fns/locale";

const fetchNotitalkHistory = async (params: {
  groupId: number;
  reservationId: number;
}) => {
  const res = await api.get<GroupNotification[]>(
    `/rest/group/${params.groupId}/notification/history?reservationId=${params.reservationId}&type=RESV`
  );
  return res;
};

const NotitalkHistory = ({ reservationId }: { reservationId: number }) => {
  const { partner } = usePartner();
  const [notitalkHistory, setNotitalkHistory] = useState<
    GroupNotification[] | undefined
  >(undefined);

  useEffect(() => {
    fetchNotitalkHistory({
      groupId: partner.id,
      reservationId: reservationId,
    }).then(setNotitalkHistory);
  }, [partner.id, reservationId]);

  return notitalkHistory ? (
    <VFlex p-24 f-1 rel ovf-a>
      <VFlex bc-w p-12-t p-24-b bdr-16 style={{ overflowX: "auto" }}>
        <VFlex>
          <table style={{ padding: "0 24px" }}>
            <thead style={{ height: "46px" }}>
              <tr>
                <th>
                  <Text l-1>발송 날짜</Text>
                </th>
                <ColumnResize
                  id={2}
                  resizeEnd={(width) => {}}
                  resizeStart={() => {}}
                  className="columnResizer"
                  defaultWidth={100}
                  minWidth={44}
                  maxWidth={null}
                  disabled={false}
                />
                <th>
                  <Text l-1>발송 시간</Text>
                </th>
                <ColumnResize
                  id={3}
                  resizeEnd={(width) => {}}
                  resizeStart={() => {}}
                  className="columnResizer"
                  defaultWidth={100}
                  minWidth={44}
                  maxWidth={null}
                  disabled={false}
                />
                <th>
                  <Text l-1>발송 채널</Text>
                </th>
                <ColumnResize
                  id={4}
                  resizeEnd={(width) => {}}
                  resizeStart={() => {}}
                  className="columnResizer"
                  defaultWidth={100}
                  minWidth={44}
                  maxWidth={null}
                  disabled={false}
                />
                <th>
                  <Text l-1>발송 내용</Text>
                </th>
                <ColumnResize
                  id={5}
                  resizeEnd={(width) => {}}
                  resizeStart={() => {}}
                  className="columnResizer"
                  defaultWidth={127}
                  minWidth={44}
                  maxWidth={null}
                  disabled={false}
                />
                <th>
                  <Text l-1>발송 상태</Text>
                </th>
                <ColumnResize
                  id={5}
                  resizeEnd={(width) => {}}
                  resizeStart={() => {}}
                  className="columnResizer"
                  defaultWidth={127}
                  minWidth={44}
                  maxWidth={null}
                  disabled={false}
                />
              </tr>
            </thead>
            <tbody>
              {notitalkHistory.map((history, i) => {
                return (
                  <tr key={i} onClick={() => {}}>
                    <td>
                      <Text>
                        {format(
                          new Date(history.sendDate * 1000),
                          "yyyy.MM.dd (EEEEE)",
                          {
                            locale: ko,
                          }
                        )}
                      </Text>
                    </td>
                    <td className="column_resizer_body" />
                    <td>
                      {format(new Date(history.sendDate * 1000), "a hh:mm", {
                        locale: ko,
                      })}
                    </td>
                    <td className="column_resizer_body" />
                    <td>
                      <Text l-1>{history.label}</Text>
                    </td>
                    <td className="column_resizer_body" />
                    <td>
                      <Text l-1>{history.title}</Text>
                    </td>
                    <td className="column_resizer_body" />
                    <td>
                      <Text t-14-700>
                        {history.alimtalkSucceed || history.smsSucceed
                          ? "발송 성공"
                          : history.scheduled
                          ? "발송 예정"
                          : "발송 실패"}
                      </Text>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </VFlex>
      </VFlex>
    </VFlex>
  ) : (
    <></>
  );
};

export default NotitalkHistory;
