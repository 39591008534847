import { PropsWithChildren } from "react";
import VFlex from "./VFlex";
import HFlex from "./HFlex";

interface DropdownProps extends PropsWithChildren {
  width: number;
}

const Dropdown = ({ children, width }: DropdownProps) => {
  return (
    <VFlex width={width} bc-tw sdw-2 p-8 bdr-12>
      {children}
    </VFlex>
  );
};

export default Dropdown;

interface ItemProps extends PropsWithChildren {}

const DropdownItem = ({ children }: ItemProps) => {
  return (
    <HFlex bdr-8 p-8 clickable classes={["hover"]}>
      {children}
    </HFlex>
  );
};

Dropdown.Item = DropdownItem;
