import { useMemo, useRef } from "react";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import api from "../common/api";
import { usePartner } from "./partner";
import { Partner, Pet } from "./types";

const petsStore = create(
  immer<{
    pets: Pet[];
    totalPetsCount: number;
    fetchPets: (
      partner: Partner,
      size: number
    ) => ({
      page,
      search,
      sort,
    }: {
      page: number;
      search?: string;
      sort?: "ID" | "CREATED" | "MODIFIED" | "PET_NAME";
    }) => Promise<Pet[]>;
  }>((set) => ({
    pets: [],
    totalPetsCount: 0,
    fetchPets:
      (partner: Partner, size: number) =>
      async ({
        page,
        search,
        sort,
      }: {
        page: number;
        search?: string;
        sort?: "ID" | "CREATED" | "MODIFIED" | "PET_NAME";
      }) => {
        const { pets, totalCount } = await api.get(
          `/rest/v2/pet/search?groupId=${partner.id}&page=${
            page * 10
          }&size=${size}&sort=${sort}${search ? `&search=${search}` : ""}`
        );
        set((state) => ({
          ...state,
          pets,
          totalPetsCount: totalCount,
        }));
        return pets;
      },
  }))
);

export const usePets = () => {
  const PETS_DEFAULT_SIZE = 10;
  const { partner } = usePartner();
  const { fetchPets, ...rest } = petsStore();
  const fetchPetsRef = useRef(fetchPets);
  return {
    fetchPets: useMemo(
      () => fetchPets(partner, PETS_DEFAULT_SIZE),
      [fetchPets, partner]
    ),
    ...rest,
  };
};
