import { useState } from "react";

import HFlex from "../layouts/HFlex";
import VFlex from "../layouts/VFlex";
import Image from "../layouts/Image";
import Text from "../layouts/Text";

import { Guardian } from "../providers/types";

const GuardianDropdown = ({ guardianList }: { guardianList: Guardian[] }) => {
  return (
    <VFlex bc-w bdr-12 p-8 sdw-6>
      {guardianList.map((guardian) => (
        <GuardianDropdownItem guardian={guardian} key={guardian.id} />
      ))}
    </VFlex>
  );
};

const GuardianDropdownItem = ({ guardian }: { guardian: Guardian }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <HFlex
      a-c
      t-14-500-s6
      p-8-rl
      p-4-tb
      bdr-8
      bc-w={!isHovered}
      bc-t2={isHovered}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      clickable
    >
      <Image src="/icons/icon_phone.svg" size={16} m-4-r />
      <Text>{guardian.cellnumber}</Text>
      {guardian?.name && (
        <>
          <Image src="/icons/icon_ellipse.svg" size={3} m-6-rl />
          <Text t-14-500-s6>{guardian.name}</Text>
        </>
      )}
    </HFlex>
  );
};

export default GuardianDropdown;
