import {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";

import api from "../../../common/api";
import useDebounce from "../../../common/useDebounce";
import { reg } from "../../../common/format";
import { numberWithCommas } from "../../../common/utils";

import {
  Pet,
  Reservation,
  Guardian,
  PetInfo,
  Tag,
  Sorts,
  SpeciesFilter as SpeciesFilterType,
  TagFilter as TagFilterType,
  Filter,
} from "../../../providers/types";
import { pushPopup } from "../../../providers/popups";
import { usePartner } from "../../../providers/partner";
import { CustomersProvider } from "../../../providers/customers";

import SearchInput from "../../../components/SearchInput";
import PetProfile from "../../../components/PetProfile";
import ServiceSummary from "../../../components/Customers/ServiceSummary";
import TagDropdown from "../../../components/TagDropdown";
import TagFilter from "../../../components/Customers/TagFilter";
import SpeciesFilter from "../../../components/Customers/SpeciesFilter";
import SortFilter from "../../../components/Customers/SortFilter";
import AddCustomer from "../Customers/AddCustomer";
import ConfirmPopup from "../../../popup/ConfirmPopup";
import { PopupAlign, PopupKind } from "../../../popup/Popup";
import {
  ReseravtionHistoryOption,
  ReservationHistoryItem,
} from "./ReservationDetail/ServiceHistory";
import { InquiryData } from "./NewReservtaion";

import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Image from "../../../layouts/Image";
import Text from "../../../layouts/Text";
import Divider from "../../../layouts/Divider";
import Button from "../../../layouts/Button";
import Absolute from "../../../layouts/Absolute";
import FilterChip from "../../../layouts/FilterChip";
import Flex from "../../../layouts/Flex";
import DropdownLayout from "../../../layouts/DropdownV2";
import Dropdown from "../../../layouts/Dropdown";

import _ from "lodash";
import { format } from "date-fns";
import { ko } from "date-fns/locale";

function AddScheduleCustomerV2({
  popPopup,
  editingReservation,
  selectedCustomer,
  setSelectedCustomer,
  selectedGuardian,
  setSelectedGuardian,
  loadSchedule,
  fetchCurrentPage,
  inquiryData,
  newPetMemo,
  setNewPetMemo,
  savePetMemo,
  reservationId,
}: {
  popPopup: () => void;
  editingReservation?: Reservation;
  selectedCustomer?: Pet;
  setSelectedCustomer: Dispatch<SetStateAction<Pet | undefined>>;
  selectedGuardian?: Guardian;
  setSelectedGuardian: Dispatch<SetStateAction<Guardian | undefined>>;
  loadSchedule: () => void;
  fetchCurrentPage: () => void;
  inquiryData?: InquiryData;
  newPetMemo: string | undefined;
  setNewPetMemo: (memo: string | undefined) => void;
  savePetMemo: () => void;
  reservationId?: number;
}) {
  const { partner } = usePartner();
  const [loading, setLoading] = useState(false);
  const [hasNext, setHasNext] = useState(true);
  const [hasNexts, setHasNexts] = useState(true);
  const [lastElement, setLastElement] = useState<HTMLDivElement | null>(null);

  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState<string>();
  const [pets, setPets] = useState<Pet[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const debouncedSearchText = useDebounce({ value: searchText, delay: 500 });

  const [speciesFilterId, setSpeciesFilterId] = useState<number | null>(null);
  const [speciesFilters, setSpeciesFilters] = useState<SpeciesFilterType[]>([]);
  const [tagFilterId, setTagFilterId] = useState<number | null>(null);
  const [tagFilters, setTagFilters] = useState<TagFilterType[]>([]);
  const [sortFilters, setSortFilters] = useState<Sorts[]>([]);

  const [tagFilterOptions, setTagFilterOptions] = useState<TagFilterType[]>([]);
  const [sortOptions, setSortOptions] = useState<Sorts[]>([]);
  const [speciesFilterOptions, setSpeciesFilterOptions] = useState<
    SpeciesFilterType[]
  >([]);

  const [filterStack, setFilterStack] = useState<string[]>([]);
  const [shownAddFilterChip, setShownAddFilterChip] = useState(false);

  const [shownFilterDropdown, setShownFilterDropdown] = useState(false);
  const [shownSortDropdown, setShownSortDropdown] = useState(false);
  const [shownSpeciesFilter, setShownSpeciesFilter] = useState(false);
  const [shownTagFilter, setShownTagFilter] = useState(false);

  const [selectedYear, setSelectedYear] = useState<number>();
  const [totalHistoryCount, setTotalHistoryCount] = useState<number>(0);
  const [reservationHistory, setReservationHistory] = useState<
    ReservationHistoryItem[]
  >([]);
  const [selectOptions, setSelectOptions] = useState<
    ReseravtionHistoryOption[]
  >([]);
  const [shownYearDropdown, setShownYearDropdown] = useState(false);
  const [pageNum, setPageNum] = useState(0);

  useEffect(() => {
    if (
      selectedCustomer &&
      selectedCustomer.guardianList.length > 1 &&
      !selectedGuardian
    ) {
      pushPopup({
        kind: PopupKind.Popup,
        align: PopupAlign.TopCenter,
        element: ConfirmPopup,
        props: {
          title: `${selectedCustomer.name} (${selectedCustomer.species})에게 여러명의 보호자가 있습니다.\n어떤 보호자로 예약할까요?`,
          guardians: selectedCustomer.guardianList,
          onConfirm: async (guardian: any) => {
            setSelectedGuardian(guardian);
          },
        },
        dimClick: "none",
      });
    }
  }, [
    selectedCustomer,
    selectedGuardian,
    setSelectedCustomer,
    setSelectedGuardian,
  ]);

  const [petInfo, setPetInfo] = useState<PetInfo>();
  const [tags, setTags] = useState<Tag[] | undefined>();

  const fetchPetInfo = async () => {
    if (selectedCustomer) {
      const { petInfo, tags } = await api.get<{
        petInfo: PetInfo;
        tags: Tag[];
      }>(`/rest/v2/pet/${selectedCustomer.id}/detail?groupId=${partner.id}`);

      setPetInfo(petInfo);
      setNewPetMemo(petInfo.note);
      setTags(tags);
    }
  };

  useEffect(() => {
    fetchPetInfo();
  }, [selectedCustomer, partner]);

  const fetchReservationHistory = async () => {
    if (!hasNexts) return;

    if (selectedCustomer) {
      try {
        setLoading(true);
        const { data, totalCount, selectOptions } = await api.get<{
          totalCount: number;
          data: ReservationHistoryItem[];
          selectOptions: ReseravtionHistoryOption[];
        }>(
          `/rest/v3/reservation/pet/${selectedCustomer.id}/history?groupId=${
            partner.id
          }&type=RESV&size=5&page=${pageNum * 5}${
            selectedYear ? `&startDate=${selectedYear}` : ""
          }${reservationId ? `&reservationId=${reservationId}` : ""}`
        );
        setReservationHistory((prev) =>
          pageNum === 0 ? data : [...prev, ...data]
        );
        setTotalHistoryCount(totalCount);
        setSelectOptions(selectOptions);
        setHasNexts(pageNum === 0 || data.length !== 0);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchReservationHistory();
  }, [selectedCustomer, pageNum]);

  useEffect(() => {
    setPageNum(0);
    setHasNexts(true);
    setReservationHistory([]);
  }, [selectedCustomer, selectedYear]);

  const setFilterOptions = async () => {
    if (reservationId) return;

    try {
      setLoading(true);
      const { filters, sorts } = await api.get<Filter>(
        `/rest/group/${partner.id}/filter?type=G_PET_LIST`,
        {
          params: {
            deleted: false,
          },
        }
      );

      setSpeciesFilterId(filters.find((f) => f.category === "SPECIES")?.id!);
      setSpeciesFilterOptions(
        (filters.find((f) => f.category === "SPECIES")?.data ||
          []) as SpeciesFilterType[]
      );
      setTagFilterId(filters.find((f) => f.category === "CUSTOMER_TAG")?.id!);
      setTagFilterOptions(
        (filters.find((f) => f.category === "CUSTOMER_TAG")?.data ||
          []) as TagFilterType[]
      );
      setSortOptions(sorts);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setFilterOptions();
  }, [reservationId]);

  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        const className = first?.target.getAttribute("class");
        if (className === "list") {
          setPage((no) => no + 1);
        } else {
          setPageNum((no) => no + 1);
        }
      }
    })
  );

  const fetchPets = async ({ page, size }: { page: number; size: number }) => {
    const response: {
      data: Pet[];
      totalCount: number;
    } = await api.post(
      `/rest/group/${partner.id}/pets?page=${page * size}&size=${size}${
        debouncedSearchText ? `&search=${debouncedSearchText}` : ""
      }`,
      {
        filters: [
          {
            id: speciesFilterId,
            category: "SPECIES",
            data: speciesFilters.map((s) => ({ id: s.id })),
          },
          {
            id: tagFilterId,
            category: "CUSTOMER_TAG",
            data: tagFilters.map((s) => ({ id: s.id })),
          },
        ],
        sorts: sortFilters,
      },
      {
        params: {
          deleted: false,
        },
      }
    );
    return response;
  };

  const fetchPetList = async (customerId?: number) => {
    if (!hasNext || reservationId) return;

    try {
      setLoading(true);
      const { data, totalCount } = await fetchPets({
        page,
        size: 10,
      });

      if (customerId) {
        setSelectedCustomer(data.find((pet) => pet.id === customerId));
      }
      setPets((prev) => (page === 0 ? data : [...prev, ...data]));
      setHasNext(page === 0 || data.length !== 0);
      setTotalCount(totalCount);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPetList();
  }, [page]);

  useEffect(() => {
    setHasNext(true);
    setPets([]);
    if (page === 0) {
      fetchPetList();
    } else {
      setPage(0);
    }
  }, [
    JSON.stringify(tagFilters),
    JSON.stringify(sortFilters),
    JSON.stringify(speciesFilters),
    debouncedSearchText,
  ]);

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  if (selectedCustomer) {
    return petInfo ? (
      <VFlex rel>
        <PetProfile
          petInfo={petInfo}
          tags={tags}
          guardianList={selectedCustomer.guardianList}
          petProfileImg={selectedCustomer.attachment}
          newPetMemo={newPetMemo}
          setNewPetMemo={setNewPetMemo}
          savePetMemo={savePetMemo}
        />
        {!(editingReservation || inquiryData) ? (
          <Absolute
            style={{
              width: 24,
              height: 24,
              top: 24,
              right: 24,
              left: "auto",
              zIndex: 1,
            }}
          >
            <Image
              size={24}
              src={`/icons/popup_close.png`}
              clickable
              onClick={() => {
                setPetInfo(undefined);
                setSelectedCustomer(undefined);
                setSelectedGuardian(undefined);
                setReservationHistory([]);
                setTotalHistoryCount(0);
                setSelectedYear(0);
                setSelectOptions([]);
                setShownYearDropdown(false);
              }}
            />
          </Absolute>
        ) : null}

        <VFlex>
          <Text p-16-tb t-16-600-s8 p-24-rl>
            예약 목록
          </Text>
          <Divider height={1} m-24-rl />

          <HFlex a-c j-b p-16-t p-24-rl rel>
            <FilterChip
              onClick={() => {
                setShownYearDropdown(true);
              }}
              suffixIcon="/icons/navigation/arrow_drop_down.svg"
              suffixIconSize={18}
            >
              {selectedYear
                ? format(selectedYear * 1000, "yyyy년", { locale: ko })
                : "전체"}
            </FilterChip>
            {shownYearDropdown && (
              <Absolute
                top={55}
                right={"auto"}
                bottom={"auto"}
                left={24}
                style={{ zIndex: 2 }}
              >
                <DropdownLayout width={180}>
                  {selectOptions.map((opt, i) => {
                    return (
                      <DropdownLayout.Item key={i}>
                        <Text
                          f-1
                          t-14-500-s8
                          onClick={() => {
                            setShownYearDropdown(false);
                            setSelectedYear(opt.value);
                          }}
                        >
                          {opt.label}
                        </Text>
                        {opt.value === selectedYear && (
                          <Image src="/icons/action/check_g1.svg" size={20} />
                        )}
                      </DropdownLayout.Item>
                    );
                  })}
                </DropdownLayout>
              </Absolute>
            )}
            {shownYearDropdown && (
              <Absolute
                onClick={(e: MouseEvent) => {
                  setShownYearDropdown(false);
                  e.stopPropagation();
                }}
                style={{ zIndex: 1 }}
              />
            )}
            <Text t-14-600-s8>총 {totalHistoryCount}건</Text>
          </HFlex>

          <VFlex f-1 ovf-a rel>
            <VFlex g-12 p-24={reservationHistory.length === 0}>
              {reservationHistory.length > 0 ? (
                reservationHistory.map((history, i) => (
                  <VFlex key={i}>
                    <ServiceSummary
                      petId={petInfo.id!}
                      serviceSummary={history}
                      loadSchedule={fetchReservationHistory}
                      noneBorderRadius={true}
                    />
                    {i !== reservationHistory.length - 1 && (
                      <Divider bc-t2 height={12} />
                    )}
                    {!loading && hasNexts && (
                      <VFlex className="history" ref={setLastElement} />
                    )}
                  </VFlex>
                ))
              ) : (
                <VFlex height={200} bc-tw c-c bdr-16 p-24 bd-t4>
                  <Image src="/icons/icon_info_s3.svg" size={24} m-8-b />
                  <Text t-14-500-s4>예약 이력이 없습니다.</Text>
                </VFlex>
              )}
            </VFlex>
          </VFlex>
        </VFlex>
      </VFlex>
    ) : (
      <></>
    );
  } else {
    return (
      <CustomersProvider>
        <VFlex f-1 ovf-h rel>
          <VFlex p-16-tb p-24-rl g-16>
            <VFlex bdr-16 p-16-rl bd-t3 ovf-h>
              <SearchInput
                height={48}
                placeholder="고객명, 보호자명, 전화번호를 입력하세요"
                value={searchText}
                onChangeValue={(value) => {
                  setSearchText(reg(value));
                }}
              />
            </VFlex>

            <VFlex ovf-a>
              <HFlex g-8>
                {filterStack.length === 0 && (
                  <>
                    <FilterChip
                      prefixIconSize={18}
                      prefixIcon={`/icons/icon_filter.svg`}
                      onClick={() => setShownFilterDropdown(true)}
                    >
                      필터
                    </FilterChip>
                    {shownFilterDropdown && (
                      <Absolute
                        top={"auto"}
                        right={"auto"}
                        bottom={"auto"}
                        left={"auto"}
                        style={{ zIndex: 2, paddingTop: "40px" }}
                      >
                        <DropdownLayout width={180}>
                          <DropdownLayout.Item>
                            <Text
                              f-1
                              t-14-500-s8
                              onClick={() => {
                                setShownSpeciesFilter(true);
                                setShownFilterDropdown(false);
                                setFilterStack((stack) => [
                                  ...stack,
                                  "SPECIES",
                                ]);
                              }}
                            >
                              품종
                            </Text>
                          </DropdownLayout.Item>
                          <DropdownLayout.Item>
                            <Text
                              f-1
                              t-14-500-s8
                              onClick={() => {
                                setShownTagFilter(true);
                                setShownFilterDropdown(false);
                                setFilterStack((stack) => [
                                  ...stack,
                                  "CUSTOMER_TAG",
                                ]);
                              }}
                            >
                              고객 태그
                            </Text>
                          </DropdownLayout.Item>
                        </DropdownLayout>
                      </Absolute>
                    )}
                    {shownFilterDropdown && (
                      <Absolute
                        onClick={() => setShownFilterDropdown(false)}
                        style={{ zIndex: 1 }}
                      />
                    )}
                  </>
                )}
                {sortFilters.length === 0 && (
                  <FilterChip
                    prefixIconSize={18}
                    prefixIcon={`/icons/icon_sort.svg`}
                    onClick={() => {
                      setShownSortDropdown(true);
                    }}
                  >
                    정렬
                  </FilterChip>
                )}
                {shownSortDropdown && (
                  <Absolute
                    top={"auto"}
                    right={"auto"}
                    bottom={"auto"}
                    left={"auto"}
                    style={{ zIndex: 2, marginTop: "40px" }}
                  >
                    <DropdownLayout width={180}>
                      {
                        <DropdownLayout.Item>
                          <Text
                            f-1
                            t-14-500-s8
                            onClick={() => {
                              setShownSortDropdown(false);
                              setSortFilters((s) => [
                                ...s,
                                sortOptions.find(
                                  (o) => o.category === "PET_VISITED"
                                )!,
                              ]);
                            }}
                          >
                            방문순
                          </Text>
                        </DropdownLayout.Item>
                      }
                      <DropdownLayout.Item>
                        <Text
                          f-1
                          t-14-500-s8
                          onClick={() => {
                            setShownSortDropdown(false);
                            setSortFilters((s) => [
                              ...s,
                              sortOptions.find(
                                (o) => o.category === "PET_CREATED"
                              )!,
                            ]);
                          }}
                        >
                          등록순
                        </Text>
                      </DropdownLayout.Item>
                    </DropdownLayout>
                  </Absolute>
                )}
                {shownSortDropdown && (
                  <Absolute
                    style={{ zIndex: 1 }}
                    onClick={() => setShownSortDropdown(false)}
                  />
                )}
                {sortFilters.length > 0 && (
                  <SortFilter
                    options={sortOptions}
                    sortFilters={sortFilters}
                    setSortFilters={(s) => {
                      setSortFilters(s);
                    }}
                  />
                )}
                {filterStack.map((filter) =>
                  filter === "SPECIES"
                    ? shownSpeciesFilter && (
                        <SpeciesFilter
                          options={speciesFilterOptions}
                          speciesList={speciesFilters}
                          setSpeciesFilter={(s) => {
                            setSpeciesFilters(s);
                          }}
                          removeFilter={() => {
                            setSpeciesFilters([]);
                            setFilterStack((stack) =>
                              stack.filter((s) => s !== "SPECIES")
                            );
                            setShownSpeciesFilter(false);
                          }}
                        />
                      )
                    : shownTagFilter && (
                        <TagFilter
                          options={tagFilterOptions}
                          tagList={tagFilters}
                          setTagList={(t) => {
                            setTagFilters(t);
                          }}
                          removeFilter={() => {
                            setTagFilters([]);
                            setFilterStack((stack) =>
                              stack.filter((s) => s !== "CUSTOMER_TAG")
                            );
                            setShownTagFilter(false);
                          }}
                        />
                      )
                )}
                {(shownSpeciesFilter || shownTagFilter) &&
                  filterStack.length < 2 && (
                    <>
                      <FilterChip
                        prefixIcon="/icons/add.svg"
                        prefixIconSize={18}
                        onClick={() => setShownAddFilterChip((s) => !s)}
                      >
                        필터 추가
                      </FilterChip>
                      {shownAddFilterChip && (
                        <Absolute
                          top={"auto"}
                          right={24}
                          bottom={"auto"}
                          left={"auto"}
                          style={{ zIndex: 2, paddingTop: "40px" }}
                        >
                          <DropdownLayout width={180}>
                            {!filterStack.find((s) => s === "SPECIES") && (
                              <DropdownLayout.Item>
                                <Text
                                  t-14-500-s8
                                  f-1
                                  onClick={() => {
                                    setFilterStack((stack) => [
                                      ...stack,
                                      "SPECIES",
                                    ]);
                                    setShownSpeciesFilter(true);
                                    setShownAddFilterChip(false);
                                  }}
                                >
                                  품종
                                </Text>
                              </DropdownLayout.Item>
                            )}
                            {!filterStack.find((s) => s === "CUSTOMER_TAG") && (
                              <DropdownLayout.Item>
                                <Text
                                  t-14-500-s8
                                  f-1
                                  onClick={() => {
                                    setFilterStack((stack) => [
                                      ...stack,
                                      "CUSTOMER_TAG",
                                    ]);
                                    setShownTagFilter(true);
                                    setShownAddFilterChip(false);
                                  }}
                                >
                                  고객 태그
                                </Text>
                              </DropdownLayout.Item>
                            )}
                          </DropdownLayout>
                        </Absolute>
                      )}
                      {shownAddFilterChip && (
                        <Absolute
                          style={{ zIndex: 1 }}
                          onClick={() => setShownAddFilterChip(false)}
                        />
                      )}
                    </>
                  )}
              </HFlex>
            </VFlex>
          </VFlex>

          <VFlex ovf-a height={"100%"}>
            <Text p-8-tb p-24-rl t-18-600-s8>
              전체 ({numberWithCommas(totalCount)})
            </Text>
            {pets.length > 0 ? (
              <VFlex f-1 ovf-a p-24-rl p-24-b>
                {pets.map((customer) => {
                  return (
                    <ListItem
                      key={customer.id}
                      customer={customer}
                      setSelectedCustomer={setSelectedCustomer}
                      loading={loading}
                      hasNext={hasNext}
                      setLastElement={setLastElement}
                    />
                  );
                })}
              </VFlex>
            ) : (
              <VFlex
                a-c
                j-c
                bd-t3
                bdr-16
                m-12-t
                m-24-rl
                g-8
                style={{
                  padding: "100px 0",
                }}
              >
                <Image size={24} src={`/icons/icon_noti.svg`} />
                <Text t-16-s2>등록된 고객이 없어요</Text>
              </VFlex>
            )}
          </VFlex>

          <Button
            bdr-16
            width={56}
            height={56}
            p-0
            style={{
              position: "absolute",
              right: 24,
              bottom: 24,
              zIndex: 1,
              background: "#383B3A",
              boxShadow:
                "0px 3px 4px 0px rgba(0, 0, 0, 0.15), 0px 8px 12px 4px rgba(0, 0, 0, 0.20)",
            }}
            onClick={() =>
              pushPopup({
                kind: PopupKind.Popup,
                element: AddCustomer,
                dimClick: "none",
                props: {
                  fetchCurrentPage: (customerId?: number) => {
                    fetchPetList(customerId);
                  },
                },
              })
            }
          >
            <Image size={24} src={`/icons/content/add_w.svg`} />
          </Button>
        </VFlex>
      </CustomersProvider>
    );
  }
}

const ListItem = ({
  customer,
  setSelectedCustomer,
  loading,
  hasNext,
  setLastElement,
}: {
  customer: Pet;
  setSelectedCustomer: Dispatch<SetStateAction<Pet | undefined>>;
  loading: boolean;
  hasNext: boolean;
  setLastElement: any;
}) => {
  const [tagListOpened, setTagListOpened] = useState(false);

  return (
    <VFlex p-16-tb>
      <HFlex
        a-c
        g-8
        clickable
        onClick={() => {
          setSelectedCustomer(customer);
        }}
      >
        <Image
          width={44}
          height={44}
          bdr-12
          src={customer.attachment?.path ?? `/icons/default_profile.png`}
        />
        <VFlex f-1>
          <HFlex a-c g-4>
            <Text t-16-600-s8 l-1>
              {customer.name} ({customer.species})
            </Text>

            {customer.tags && customer.tags?.length > 0 && (
              <HFlex rel g-4 a-c>
                <Flex
                  p-6-rl
                  p-2-tb
                  bdr-6
                  t-12
                  style={{
                    color: customer.tags[0].textColor,
                    backgroundColor: customer.tags[0].backgroundColor,
                  }}
                >
                  {customer.tags[0].title}
                </Flex>

                {customer.tags.length > 1 && (
                  <Dropdown
                    position="left"
                    triggerAction="hover"
                    trigger={
                      <Text t-14-500-s4>+{customer.tags.length - 1}</Text>
                    }
                    renderOptions={() => (
                      <TagDropdown tagList={customer.tags!.slice(1)} />
                    )}
                  />
                )}
              </HFlex>
            )}
          </HFlex>

          <Text f-1 t-14-s4 l-1>
            {customer.guardianList
              .map((guardian) => {
                return `${guardian.name ?? "-"} (${guardian.cellnumber})`;
              })
              .join(", ")}
          </Text>
        </VFlex>
        {!loading && hasNext && <VFlex className="list" ref={setLastElement} />}
      </HFlex>
    </VFlex>
  );
};

export default AddScheduleCustomerV2;
