import { useCallback, useState } from "react";
import { toast } from "react-hot-toast";
import api from "../../../common/api";
import Spinner from "../../../common/Spinner/Spinner";
import Button from "../../../layouts/Button";
import HFlex from "../../../layouts/HFlex";
import Text from "../../../layouts/Text";
import TextArea from "../../../layouts/TextareaV2";
import VFlex from "../../../layouts/VFlex";
import ConfirmPopup from "../../../popup/ConfirmPopup";
import { PopupAlign, PopupKind } from "../../../popup/Popup";
import { usePartner } from "../../../providers/partner";
import { pushPopup } from "../../../providers/popups";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";

function NotesAfterService({ popPopup }: { popPopup: () => void }) {
  const { trackSaveNotesAfterService } = useAmplitudeTrack();
  const { partner, fetchPartner } = usePartner();
  const [text, setText] = useState<string>(partner.warning);
  const [sending, setSending] = useState<boolean>(false);
  const isChanged = useCallback(() => {
    return text !== partner.warning;
  }, [text, partner]);

  return (
    <VFlex f-1 a-st p-24-t rel ovf-h>
      <Text t-20-700-s9 m-32-rl>
        서비스 후 주의사항
      </Text>
      {sending ? (
        <VFlex f-1 c-c>
          <Spinner />
        </VFlex>
      ) : (
        <VFlex f-1 g-8>
          <Text t-14-s4 m-24-t m-32-rl>
            서비스 후 주의사항 입력
          </Text>
          <VFlex m-32-rl>
            <TextArea
              value={text ?? ""}
              onChangeValue={(v) => {
                if (v?.length || 0 <= 800) setText(v as string);
              }}
              placeholder={"서비스 후 주의사항을 작성해주세요."}
              height={360}
              maxLength={800}
            />
          </VFlex>
          <Text
            t-14-700-s9
            m-16-t
            m-32-rl
            style={{ color: "#3EBA86", textDecorationLine: "underline" }}
            clickable
            onClick={() =>
              window.open(
                "https://pawdly.notion.site/096b0514d5a94d5190cd409f91736650",
                "_blank"
              )
            }
          >
            자세히 알아보기
          </Text>
          <VFlex f-1 />
        </VFlex>
      )}
      <HFlex g-8 m-24-t p-32-rl height={86} bd-t-t3 a-c>
        <VFlex f-1 />
        <Button
          type="cancel"
          onClick={() => {
            isChanged()
              ? pushPopup({
                  kind: PopupKind.Popup,
                  align: PopupAlign.TopCenter,
                  element: ConfirmPopup,
                  props: {
                    title: "변경사항을 저장하지 않고 닫을까요?",
                    onConfirm: () => {
                      popPopup();
                    },
                  },
                })
              : popPopup();
          }}
          caption="취소"
        />
        <Button
          type="confirm"
          enabled={isChanged()}
          onClick={async () => {
            if (!isChanged()) {
              return;
            }
            setSending(true);
            await api.put(`/rest/group/${partner.id}/warning-message`, {
              message: text,
            });
            await fetchPartner();
            toast.success("저장되었습니다.");
            setSending(false);
            trackSaveNotesAfterService(partner.title);
          }}
          caption="저장"
        />
      </HFlex>
    </VFlex>
  );
}

export default NotesAfterService;
