import {
  Attachment,
  Guardian,
  PetInfo,
  Tag as TagType,
} from "../providers/types";
import { useTags } from "../providers/tags";

import GuardianDropdown from "./GurdianDropdown";
import Tag from "./Tag";

import HFlex from "../layouts/HFlex";
import VFlex from "../layouts/VFlex";
import Image from "../layouts/Image";
import Text from "../layouts/Text";
import Divider from "../layouts/Divider";
import Textarea from "../layouts/TextareaV2";
import Dropdown from "../layouts/Dropdown";

const PetProfile = ({
  petProfileImg,
  tags,
  petInfo,
  guardianList,
  newPetMemo,
  setNewPetMemo,
  savePetMemo,
}: {
  petProfileImg?: Attachment;
  tags?: TagType[];
  petInfo: PetInfo;
  guardianList: Guardian[];
  newPetMemo?: string;
  setNewPetMemo?: (n: string) => void;
  savePetMemo?: () => void;
}) => {
  const { name, species } = petInfo;
  const { colorList } = useTags();
  const primaryGuardianIndex =
    guardianList.findIndex((g) => g.primaryGuardian) > 0
      ? guardianList.findIndex((g) => g.primaryGuardian)
      : 0;

  return (
    <VFlex p-24>
      <VFlex a-c g-24>
        <Image
          src={petProfileImg?.path || "/icons/default_profile.svg"}
          size={110}
          bdr-24
        />
        <VFlex a-c g-16>
          <VFlex a-c g-4>
            <Text
              t-18-600-s8
              l-m
              style={{
                textAlign: "center",
              }}
            >
              {name} ({species.name})
            </Text>
            <VFlex g-4>
              <HFlex g-6 a-c>
                {guardianList[primaryGuardianIndex]?.name && (
                  <Text t-14-500-s6>
                    {guardianList[primaryGuardianIndex]?.name}
                  </Text>
                )}
                <Text t-14-500-s6>
                  ({guardianList[primaryGuardianIndex]?.cellnumber})
                </Text>

                {guardianList.length > 1 && (
                  <Dropdown
                    position="right"
                    trigger={
                      <Text t-14-500-s4 clickable>
                        +{guardianList.length - 1}
                      </Text>
                    }
                    renderOptions={() => (
                      <GuardianDropdown
                        guardianList={guardianList.filter(
                          (_, index) => index !== primaryGuardianIndex
                        )}
                      />
                    )}
                    triggerAction="hover"
                  />
                )}
              </HFlex>
            </VFlex>
          </VFlex>
          {tags && (
            <HFlex g-6 j-c style={{ flexWrap: "wrap" }}>
              {tags.map((tag, index) => (
                <Tag
                  key={index}
                  tag={{
                    ...tag,
                    // backgroundColor: colorList.find(
                    //   (c) => c.textColor === tag.textColor
                    // )?.bgColor!,
                    backgroundColor: tag.backgroundColor,
                    textColor: tag.textColor,
                  }}
                  isActived
                />
              ))}
            </HFlex>
          )}
        </VFlex>
      </VFlex>

      <Divider height={1} m-24-t />

      <VFlex g-8 m-24-t>
        <HFlex j-b>
          <Text t-16-600-s8>반려동물 메모</Text>
          {savePetMemo && newPetMemo !== petInfo.note && (
            <Text t-16-500-g1 clickable onClick={savePetMemo}>
              저장
            </Text>
          )}
        </HFlex>
        <Textarea
          className="petInfoSection_petMemo"
          value={newPetMemo ?? petInfo.note}
          onChangeValue={(v) => {
            setNewPetMemo?.(v as string);
          }}
          placeholder={"메모를 입력하세요"}
          height={104}
          disabled={!savePetMemo}
          maxLength={800}
        />
      </VFlex>
    </VFlex>
  );
};

export default PetProfile;
