import React, { ReactElement, useEffect, useMemo, useState } from "react";
import TabBar, { TabItemType } from "../../../components/TabBar";
import GroupNotificationList from "./GroupNotificationList";
import GroupNotificationSettings from "./GroupNotificationSettings";
import { SettingsTitle } from ".";
import VFlex from "../../../layouts/VFlex";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";

function GroupNotifications({
  setSelectedSubpage,
  initialTabIndex,
}: {
  setSelectedSubpage: (component?: ReactElement) => void;
  initialTabIndex: number;
}) {
  const { trackLandNotificationTalk } = useAmplitudeTrack();

  useEffect(() => {
    trackLandNotificationTalk();
  }, []);

  const tabs = useMemo(
    () => [
      {
        key: "1",
        name: "알림톡 내역",
        component: GroupNotificationList,
      },
      {
        key: "2",
        name: "알림톡 관리",
        component: GroupNotificationSettings,
      },
    ],
    []
  );
  const [selectedTab, setSelectedTab] = useState<TabItemType>(
    tabs[initialTabIndex]
  );
  return (
    <VFlex a-st>
      <SettingsTitle
        title="알림톡"
        setSelectedSubpage={setSelectedSubpage}
        border={false}
      />
      <VFlex p-32-rl height={42} sized bd-b-t3={true}>
        <TabBar
          value={selectedTab}
          items={tabs}
          onChange={(item) => setSelectedTab(item)}
        />
      </VFlex>
      {selectedTab && React.createElement(selectedTab.component)}
    </VFlex>
  );
}

export default GroupNotifications;
