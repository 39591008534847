import { useContext } from "react";
import VFlex from "../layouts/VFlex";
import { TabItemType, TabViewContext } from "./TabBar";

function TabItem({ item, width }: { item: TabItemType; width: number }) {
  const { selectedItem, setSelectedItem, onChange } =
    useContext(TabViewContext)!;
  const isSelected = selectedItem?.key === item.key;
  return (
    <VFlex
      width={width}
      a-c
      j-s
      t-14-600
      c-s8={isSelected}
      c-s4={!isSelected}
      clickable
      onClick={() => {
        setSelectedItem(item);
        onChange?.(item);
      }}
    >
      {item.name}
    </VFlex>
  );
}

export default TabItem;
