import Dropdown from "../../layouts/Dropdown";
import FilterChip from "../../layouts/FilterChip";
import Text from "../../layouts/Text";
import { ReservationStatusFilter as ReservationStatusFilterType } from "../../providers/types";
import { useMemo, useState } from "react";
import DropdownWithSearch from "../DropdownWithSearch";

interface ReservationStatusFilterProps {
  options: ReservationStatusFilterType[];
  statusList: ReservationStatusFilterType[];
  setStatusList: (s: ReservationStatusFilterType[]) => void;
  removeFilter: () => void;
}

const ReservationStatusFilter = ({
  options,
  statusList,
  setStatusList,
  removeFilter,
}: ReservationStatusFilterProps) => {
  const [keyword, setKeyword] = useState("");
  const filteredOptions = useMemo(() => {
    return options.filter((opt) => opt.name.includes(keyword));
  }, [keyword, options]);

  return (
    <Dropdown
      trigger={
        statusList.length > 0 ? (
          <FilterChip
            size="S"
            type="selected"
            onClick={() => {}}
            suffixIcon="/icons/active_arrow_drop_down.svg"
            suffixIconSize={18}
          >
            {`예약 상태 : ${statusList.map((s) => s.name).join(", ")}`}
          </FilterChip>
        ) : (
          <FilterChip
            size="S"
            suffixIcon="/icons/navigation/arrow_drop_down.svg"
            suffixIconSize={18}
          >
            예약 상태
          </FilterChip>
        )
      }
      renderOptions={({ close }) => (
        <DropdownWithSearch
          keyword={keyword}
          setKeyword={setKeyword}
          resetSelect={() => setStatusList([])}
          removeFilter={() => {
            removeFilter();
            close();
          }}
        >
          {(keyword ? filteredOptions : options).map((i) => (
            <DropdownWithSearch.ListItem
              key={i.id}
              selected={!!statusList.find((s) => s.id === i.id)}
              handleClick={() => {
                let newStatusList = [...statusList];

                if (statusList.find((s) => s.id === i.id)) {
                  newStatusList = newStatusList.filter((s) => s.id !== i.id);
                } else {
                  newStatusList.push(i);
                }

                setStatusList(newStatusList);
              }}
            >
              <Text t-14-500-s8>{i.name}</Text>
            </DropdownWithSearch.ListItem>
          ))}
          {keyword && filteredOptions.length <= 0 && (
            <DropdownWithSearch.EmptySearchResult />
          )}
        </DropdownWithSearch>
      )}
      position="left"
    />
  );
};

export default ReservationStatusFilter;
