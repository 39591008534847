import Bluebird from "bluebird";
import _ from "lodash";
import { useMemo } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import api, { initializeAxios } from "../common/api";
import { Partner } from "./types";

const partnerStore = create(
  immer<{
    partner?: Partner;
    logout: (navigate: NavigateFunction) => () => void;
    fetchPartner: (navigate: NavigateFunction) => () => void;
  }>((set) => ({
    partner: undefined,
    logout: (navigate) => async () => {
      localStorage.removeItem("token");
      initializeAxios(localStorage.getItem("token"));
      navigate(`/`);
    },
    fetchPartner: (navigate) => async () => {
      if (!localStorage.getItem("token")) {
        navigate(`/`);
        return;
      }
      const [user, partners] = await Bluebird.all([
        api.get("/rest/user/information"),
        api.get("/rest/user/groups"),
      ]);
      if (_.isEmpty(partners)) {
        set((state) => ({
          ...state,
          partner: user,
        }));
        if (window.location.pathname !== `/signup/company`) {
          navigate(`/signup/company`);
        }
        return;
      }
      const partner = partners[0];
      const [
        partnerDetail,
        partnerProfile,
        { message: warning },
        { message: notice },
      ] = await Bluebird.all([
        api.get(`/rest/group/${partner.id}`),
        api.get(`/rest/group/${partner.id}/profile`),
        api.get(`/rest/group/${partner.id}/warning-message`),
        api.get(`/rest/group/${partner.id}/notify`),
      ]);
      if (!partnerDetail.businessRegistration) {
        set((state) => ({
          ...state,
          partner: user,
        }));
        if (window.location.pathname !== `/signup/company`) {
          navigate(`/signup/company`);
        }
        return;
      }
      if (
        (!partnerDetail.categories || !partnerDetail.licenses) &&
        window.location.pathname !== `/signup/companydetails`
      ) {
        set((state) => ({
          ...state,
          partner: user,
        }));
        navigate(`/signup/companydetails`);
        return;
      }
      set((state) => ({
        ...state,
        partner: {
          ...user,
          ...partnerProfile,
          ...partnerDetail,
          warning,
          notice,
          fullyLoggedIn: true,
        },
      }));
    },
  }))
);

export const usePartner = () => {
  const navigate = useNavigate();
  const { partner, fetchPartner, logout, ...rest } = partnerStore();
  return {
    partner: partner!,
    logout: useMemo(() => logout(navigate), [logout, navigate]),
    fetchPartner: useMemo(
      () => fetchPartner(navigate),
      [fetchPartner, navigate]
    ),
    ...rest,
  };
};
