import HFlex from "../../layouts/HFlex";
import VFlex from "../../layouts/VFlex";
import Text from "../../layouts/Text";
import Image from "../../layouts/Image";
import { useEffect, useState } from "react";
import Flex from "../../layouts/Flex";
import Button from "../../layouts/Button";
import Dropdown from "../Dropdown";
import { Species } from "../../providers/types";
import api from "../../common/api";
import RadioGroup from "../../layouts/RadioGroup";
import { SPECIES_OPTIONS } from "../../common/constants";
import FilterChip from "../../layouts/FilterChip";

const EditPetSpecies = ({
  popPopup,
  typeId,
  value,
  fetch,
}: {
  popPopup: () => void;
  value: Species;
  typeId: number;
  fetch: (v: string | null) => void;
}) => {
  const [species, setSpecies] = useState<Species | undefined>(() => {
    const s = SPECIES_OPTIONS.find((opt) => opt.value === typeId);

    return s
      ? {
          id: s.value,
          name: s.label,
        }
      : undefined;
  });
  const [breed, setBreed] = useState<Species | undefined>(value);
  const [breedList, setBreedList] = useState<Species[]>([]);

  const fetchBreedList = async () => {
    const breedList = await api.get(
      `/rest/species/${species?.id}/species-list`
    );
    setBreedList(breedList);
  };

  useEffect(() => {
    fetchBreedList();
  }, [species]);

  return (
    <VFlex p-24>
      <HFlex j-b a-c m-24-b>
        <Text t-24-600-s8>고객 정보 수정</Text>
        <Image
          src="/icons/icon_close_s6.svg"
          size={24}
          clickable
          onClick={popPopup}
        />
      </HFlex>
      <VFlex g-24>
        <RadioGroup
          caption="반려동물 종류"
          required
          value={species}
          onChange={(s) => {
            if (s && s.id === species?.id) return;
            setSpecies({
              id: SPECIES_OPTIONS.find((opt) => opt.value === s?.id)!.value,
              name: SPECIES_OPTIONS.find((opt) => opt.value === s?.id)!.label,
            });
            setBreed(undefined);
          }}
          options={SPECIES_OPTIONS.map((opt) => ({
            id: opt.value,
            name: opt.label,
          }))}
          renderOption={(t, handleChange) => (
            <Flex f-1>
              <FilterChip
                type={species?.id === t.id ? "selected" : undefined}
                onClick={() => handleChange(t)}
                size="L"
              >
                {t.name}
              </FilterChip>
            </Flex>
          )}
        />

        {species && (
          <Dropdown
            caption={(species.id === 4 ? "견종" : "묘종") + " 선택"}
            placeholder={(species.id === 4 ? "견종" : "묘종") + " 선택"}
            value={breed?.id}
            options={breedList?.map((breed) => ({
              value: breed.id,
              obj: breed,
              label: breed.name,
            }))}
            onChange={(value) => {
              setBreed(value?.obj);
            }}
            isSearchable={true}
            required={true}
            controlBgColor="fff"
          />
        )}
      </VFlex>

      <Flex f-1 />
      <Button
        m-24-t
        bdr-12
        height={56}
        enabled={!!breed && breed.id !== value.id}
        onClick={() => {
          fetch(breed!.id.toString());
          popPopup();
        }}
      >
        저장
      </Button>
    </VFlex>
  );
};

export default EditPetSpecies;
