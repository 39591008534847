import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import api, { initializeAxios } from "../../common/api";
import { useAmplitudeTrack } from "../../common/useAmplitudeTrack";
import useIsMobile from "../../common/useIsMobile";

import { SignupFooter } from "./Signup";

import TextInput from "../../layouts/TextInput";
import LoadingView from "../../layouts/LoadingView";
import VFlex from "../../layouts/VFlex";
import HFlex from "../../layouts/HFlex";
import Text from "../../layouts/Text";
import Image from "../../layouts/Image";
import Divider from "../../layouts/Divider";
import Button from "../../layouts/Button";

function SignupPassword() {
  const { trackSetPassword, trackSavePassword } = useAmplitudeTrack();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { state } = useLocation();
  const { name, phone, impUid } = (state ?? {}) as {
    name: string;
    phone: string;
    impUid: string;
  };
  useEffect(() => {
    if (isMobile) {
      window.location.href = "https://pawdlypartners.page.link/c6wA";
      return;
    }
    if (!state) {
      navigate("/signup");
    }
  }, [state, navigate]);
  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [passwordLengthCheck, setPasswordLengthCheck] = useState<boolean>();
  const [passwordCharacterCheck, setPasswordCharacterCheck] =
    useState<boolean>();
  const [passwordDubbleCheck, setPasswordDubbleCheck] = useState<boolean>();
  const [showAgreements, setShowAgreements] = useState<boolean>(false);
  const [ready, setReady] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>();

  const [allAgreed, setAllAgreed] = useState(false);
  const [agreementStates, setAgreementStates] = useState({
    pawdlyPrivacyPolicy: false,
    partnersPrivacyPolicy: false,
    thirdPartyConsent: false,
  });

  useEffect(() => {
    const allChecked = Object.values(agreementStates).every(
      (value) => value === true
    );
    setAllAgreed(allChecked);
  }, [agreementStates]);

  const toggleAllAgreements = () => {
    const newAllAgreed = !allAgreed;
    setAllAgreed(newAllAgreed);
    setAgreementStates({
      pawdlyPrivacyPolicy: newAllAgreed,
      partnersPrivacyPolicy: newAllAgreed,
      thirdPartyConsent: newAllAgreed,
    });
  };

  const toggleAgreement = (key: keyof typeof agreementStates) => {
    setAgreementStates({
      ...agreementStates,
      [key]: !agreementStates[key],
    });
  };

  useEffect(() => {
    if (password && password.length >= 8) {
      setPasswordLengthCheck(true);
    } else {
      setPasswordLengthCheck(false);
    }

    if (
      password &&
      /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/.test(password)
    ) {
      setPasswordCharacterCheck(true);
    } else {
      setPasswordCharacterCheck(false);
    }

    if (password && confirmPassword && password === confirmPassword) {
      setPasswordDubbleCheck(true);
      setReady(true);
    } else {
      setPasswordDubbleCheck(false);
      setReady(false);
    }
  }, [
    password,
    confirmPassword,
    setReady,
    setPasswordCharacterCheck,
    setPasswordLengthCheck,
    setPasswordDubbleCheck,
  ]);

  return (
    <LoadingView f-1 a-c bc-w ovf-a loading={loading}>
      <VFlex a-st g-24 p-24-rl width={428} style={{ marginTop: 150 }}>
        <VFlex a-st g-24 style={{ marginBottom: 100 }}>
          <VFlex a-c g-12>
            <Text t-32-600-s9>
              <Text t-32-600-gr5>{name}</Text>님, 반갑습니다!
            </Text>
            <Text t-16-500-s4 l-m>
              <Text t-16-600-s6>{phone}</Text> 계정의 비밀번호를 설정해주세요.
            </Text>
          </VFlex>
          <VFlex as-st g-6 p-16 bdr-16 bc-t1 m-24-rl>
            <HFlex a-c>
              <Image
                size={24}
                src={
                  passwordLengthCheck
                    ? `/icons/signup_check_checked.png`
                    : `/icons/signup_check_unchecked.png`
                }
              />
              <Text t-14-600-s4 m-8-l>
                8글자 이상 입력
              </Text>
            </HFlex>
            <HFlex a-c>
              <Image
                size={24}
                src={
                  passwordCharacterCheck
                    ? `/icons/signup_check_checked.png`
                    : `/icons/signup_check_unchecked.png`
                }
              />
              <Text t-14-600-s4 m-8-l>
                영문+숫자+특수문자 조합으로 입력
              </Text>
            </HFlex>
            <HFlex a-c>
              <Image
                size={24}
                src={
                  passwordDubbleCheck
                    ? `/icons/signup_check_checked.png`
                    : `/icons/signup_check_unchecked.png`
                }
              />
              <Text t-14-600-s4 m-8-l>
                동일한 비밀번호 입력
              </Text>
            </HFlex>
          </VFlex>
          <VFlex as-st g-8 m-24-rl>
            <TextInput
              pwd
              placeholder={"비밀번호 입력"}
              value={password}
              onChangeValue={(value) => setPassword(value)}
            />
            <TextInput
              pwd
              placeholder={"비밀번호 한번 더 입력"}
              value={confirmPassword}
              onChangeValue={(value) => setConfirmPassword(value)}
            />
          </VFlex>

          {showAgreements && (
            <VFlex a-st g-16 m-24-rl>
              <HFlex a-c as-st clickable onClick={toggleAllAgreements}>
                <Image
                  size={24}
                  m-16-r
                  src={
                    allAgreed
                      ? `/icons/signup_agreement_checked.png`
                      : `/icons/signup_agreement_unchecked.png`
                  }
                />
                <Text t-18-600-s8>약관 전체 동의하기</Text>
              </HFlex>
              <Divider height={1} as-st p-24-rl />
              <VFlex as-st g-10 m-32-b>
                <HFlex a-c g-8>
                  <Image
                    size={24}
                    src={
                      agreementStates.pawdlyPrivacyPolicy
                        ? `/icons/signup_agreement_checked.png`
                        : `/icons/signup_agreement_unchecked.png`
                    }
                    clickable
                    onClick={() => toggleAgreement("pawdlyPrivacyPolicy")}
                  />
                  <HFlex
                    a-c
                    f-1
                    clickable
                    onClick={() =>
                      window.open(
                        "https://www.notion.so/pawdly/bc276f674e084f6fadc672bf5aaecb4b?pvs=4",
                        "_blank"
                      )
                    }
                  >
                    <Text f-1 t-14-400-s7>
                      <Text t-14-600-s8>[필수]</Text> 포들리 이용약관 동의
                    </Text>
                    <Image size={24} src={`/icons/chevron_right.png`} />
                  </HFlex>
                </HFlex>
                <HFlex a-c g-8>
                  <Image
                    size={24}
                    src={
                      agreementStates.partnersPrivacyPolicy
                        ? `/icons/signup_agreement_checked.png`
                        : `/icons/signup_agreement_unchecked.png`
                    }
                    clickable
                    onClick={() => toggleAgreement("partnersPrivacyPolicy")}
                  />
                  <HFlex
                    a-c
                    f-1
                    clickable
                    onClick={() =>
                      window.open(
                        "https://www.notion.so/pawdly/a09c510b50284dea964ef838d2640854?pvs=4",
                        "_blank"
                      )
                    }
                  >
                    <Text f-1 t-14-400-s7>
                      <Text t-14-600-s8>[필수]</Text> 포들리 파트너스 이용약관
                      동의
                    </Text>
                    <Image size={24} src={`/icons/chevron_right.png`} />
                  </HFlex>
                </HFlex>
                <HFlex a-c g-8>
                  <Image
                    size={24}
                    src={
                      agreementStates.thirdPartyConsent
                        ? `/icons/signup_agreement_checked.png`
                        : `/icons/signup_agreement_unchecked.png`
                    }
                    clickable
                    onClick={() => toggleAgreement("thirdPartyConsent")}
                  />
                  <HFlex
                    a-c
                    f-1
                    clickable
                    onClick={() =>
                      window.open(
                        "https://www.notion.so/pawdly/7c8a8101a73b43ecaca3ae8976ba7b83?pvs=4",
                        "_blank"
                      )
                    }
                  >
                    <Text f-1 t-14-400-s7>
                      <Text t-14-600-s8>[필수]</Text> 포들리 개인정보수집 및
                      이용동의
                    </Text>
                    <Image size={24} src={`/icons/chevron_right.png`} />
                  </HFlex>
                </HFlex>
              </VFlex>
            </VFlex>
          )}

          <VFlex m-20-rl>
            <Button
              type="cta-lg"
              enabled={
                ready &&
                passwordLengthCheck &&
                passwordCharacterCheck &&
                passwordDubbleCheck &&
                (!showAgreements || allAgreed)
              }
              onClick={async () => {
                if (showAgreements) {
                  trackSavePassword(); // 완료
                } else {
                  trackSetPassword(); // 다음
                }

                if (
                  showAgreements &&
                  ready &&
                  password &&
                  passwordLengthCheck &&
                  passwordCharacterCheck &&
                  passwordDubbleCheck &&
                  allAgreed
                ) {
                  setLoading(true);
                  await api.post(
                    `/rest/user/sign-up?name=${encodeURIComponent(
                      name
                    )}&cellNumber=${encodeURIComponent(
                      phone
                    )}&password=${encodeURIComponent(
                      password
                    )}&accountType=PARTNER&impUid=${impUid}`,
                    {},
                    { headers: { Authorization: "" } }
                  );
                  const token = await api.post(
                    `/rest/user/sign-in?username=${phone}&password=${password}`,
                    {},
                    {
                      headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        Authorization: "",
                      },
                    }
                  );
                  if (token) {
                    localStorage.setItem("token", token.toString());
                    initializeAxios(localStorage.getItem("token"));
                  }
                  navigate("/signup/company");
                } else if (
                  !showAgreements &&
                  ready &&
                  passwordLengthCheck &&
                  passwordCharacterCheck &&
                  passwordDubbleCheck
                ) {
                  setShowAgreements(true);
                }
              }}
              caption={showAgreements ? "완료" : "다음"}
            />
          </VFlex>
        </VFlex>
      </VFlex>
      <VFlex f-1 />
      <SignupFooter />
    </LoadingView>
  );
}

export default SignupPassword;
