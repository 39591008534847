import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import api from "../common/api";
import useDebounce from "../common/useDebounce";
import { usePartner } from "./partner";
import { Reservations, ReservationStatusFilter, Sorts } from "./types";
import { ReservationListFilterCategory } from "../components/Reservation/ReservationListFilter";

const ITEMS_PER_PAGE = 10;

enum HiddenReservationListSortCategory {
  created = "HIDDEN_RESV_CREATED",
  date = "RESV_DATE",
}
interface HiddenReservationsContextProps {
  totalCount: number;
  reservations: Reservations[];
  searchText: string;
  setSearchText: (t: string) => void;
  page: number;
  setPage: (p: number) => void;
  statusFilter: ReservationStatusFilter[];
  setStatusFilters: Dispatch<SetStateAction<ReservationStatusFilter[]>>;
  statusFilterId: number | null;
  setStatusFiltersId: (id: number) => void;
  sortFilters: Sorts[];
  setSortFilters: Dispatch<SetStateAction<Sorts[]>>;
  startDateFilter: Date | undefined;
  setStartDateFilter: Dispatch<SetStateAction<Date | undefined>>;
  endDateFilter: Date | undefined;
  setEndDateFilter: Dispatch<SetStateAction<Date | undefined>>;
  fetchCurrentPage: () => void;
  isLoading: boolean;
}

const HiddenReservationsContext = createContext<
  HiddenReservationsContextProps | undefined
>(undefined);

export const HiddenReservationsProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { partner } = usePartner();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState<string>("");
  const [totalCount, setTotalCount] = useState(0);
  const debouncedSearchText = useDebounce({ value: searchText, delay: 500 });

  const [reservations, setReservations] = useState<Reservations[]>([]);

  const [statusFilter, setStatusFilters] = useState<ReservationStatusFilter[]>(
    []
  );
  const [statusFilterId, setStatusFiltersId] = useState<number | null>(null);

  const [sortFilters, setSortFilters] = useState<Sorts[]>([]);
  const [startDateFilter, setStartDateFilter] = useState<Date | undefined>();
  const [endDateFilter, setEndDateFilter] = useState<Date | undefined>();

  const fetchHiddenReservations = async ({
    page,
    size,
  }: {
    page: number;
    size: number;
  }) => {
    const filters = [];

    if (startDateFilter && endDateFilter) {
      filters.push({
        date: [
          {
            type: "PERIOD",
            startDate: startDateFilter.getTime() / 1000,
            endDate: endDateFilter.getTime() / 1000,
          },
        ],
        category: ReservationListFilterCategory.date,
      });
    }

    if (statusFilter.length > 0) {
      filters.push({
        id: statusFilterId,
        category: ReservationListFilterCategory.status,
        data: statusFilter.map((s) => ({ id: s.id })),
      });
    }
    const response: {
      data: Reservations[];
      totalCount: number;
    } = await api.post(
      `/rest/v2/reservations?type=HIDDEN&groupId=${partner.id}&page=${
        page * size
      }&size=${size}${
        debouncedSearchText ? `&search=${debouncedSearchText}` : ""
      }`,
      {
        filters,
        sorts: sortFilters.map((s) => ({
          category: s.category,
          sortOrder: s.sortOrder,
        })),
      },
      {
        params: {
          deleted: false,
        },
      }
    );
    return response;
  };

  const fetchCurrentPage = async () => {
    setIsLoading(true);
    const { data, totalCount } = await fetchHiddenReservations({
      page,
      size: ITEMS_PER_PAGE,
    });

    setReservations(data);
    setTotalCount(totalCount);
    setIsLoading(false);
  };

  useEffect(() => {
    setPage(0);
  }, [searchText]);

  useEffect(() => {
    fetchCurrentPage();
  }, [
    page,
    debouncedSearchText,
    statusFilter,
    sortFilters,
    startDateFilter,
    endDateFilter,
  ]);

  const contextValue: HiddenReservationsContextProps = {
    totalCount,
    reservations,
    searchText,
    setSearchText,
    page,
    setPage,
    statusFilter,
    setStatusFilters,
    statusFilterId,
    setStatusFiltersId,
    sortFilters,
    setSortFilters,
    startDateFilter,
    setStartDateFilter,
    endDateFilter,
    setEndDateFilter,
    fetchCurrentPage,
    isLoading,
  };

  return (
    <HiddenReservationsContext.Provider value={contextValue}>
      {children}
    </HiddenReservationsContext.Provider>
  );
};

export default function useHiddenReservationsContext() {
  const context = useContext(HiddenReservationsContext);

  if (!context) {
    throw new Error(
      "useHiddenReservationsContext must be used within HiddenReservationsProvider"
    );
  }

  return context;
}
