// base
import React, { useCallback, useEffect, useMemo, useState } from "react";

import api from "../../../common/api";
import Spinner from "../../../common/Spinner/Spinner";
import { scheduleStatusString } from "../../../common/constants";

// components
import TabBar, { TabItemType } from "../../../components/TabBar";
import DropdownMini from "../../../components/DropdownMini";
import { PopupAlign, PopupKind } from "../../../popup/Popup";
import ConfirmPopup from "../../../popup/ConfirmPopup";
import CancelSchedulePopup from "../../../popup/ConfirmPopup/CancelSchedulePopup";
import ReservationDetail from "./ReservationDetail";
import CustomerDetail from "../Customers/CustomerDetail";
import Tag from "../../../components/Tag";

// providers
import {
  DetailsType,
  Note,
  PastSchedule,
  Pet,
  Reservation,
  Schedule,
  ScheduleStatus,
  Staff,
  Tag as TagType,
} from "../../../providers/types";
import { usePartner } from "../../../providers/partner";
import { useStaffs } from "../../../providers/staffs";
import { pushPopup } from "../../../providers/popups";
import { useSchedules } from "../../../providers/schedules";
import { useCount } from "../../../providers/count";

// layouts
import Divider from "../../../layouts/Divider";
import HFlex from "../../../layouts/HFlex";
import Image from "../../../layouts/Image";
import VFlex from "../../../layouts/VFlex";
import Text from "../../../layouts/Text";
import Button from "../../../layouts/Button";

// lib
import { format, parseISO, startOfDay } from "date-fns";
import { ko } from "date-fns/locale";
import Select from "react-select";
import { toast } from "react-hot-toast";
import {
  ReseravtionHistoryOption,
  ReservationHistoryItem,
} from "./ReservationDetail/ServiceHistory";

const MemoDropDown = ({
  items,
  onChange,
}: {
  items: string[];
  onChange: (index: number) => void;
}) => {
  const options = items.map((i, index) => ({ value: index, label: i }));

  return (
    <HFlex a-c j-b>
      <Select
        defaultValue={options[0]}
        hideSelectedOptions={false}
        closeMenuOnSelect={true}
        styles={{
          control: () => ({
            padding: "0px 4px 0px 12px",
            display: "flex",
            alignItems: "center",
            // width: 102,
            height: 36,
            WebkitUserSelect: "none",
            msUserSelect: "none",
            userSelect: "none",
            border: "1px solid #DEE2E0",
            borderRadius: 12,
          }),
          valueContainer: () => ({
            flex: 1,
            display: "flex",
            alignItems: "center",
          }),
          menu: () => ({
            position: "absolute",
            top: 8,
            left: 0,
            backgroundColor: "#fff",
            width: "120px",
            maxHeight: "264px",
            border: "1px solid #E1E3DF",
            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.2)",
            borderRadius: 8,
          }),
          menuPortal: (provided) => ({
            ...provided,
            width: 100,
            zIndex: 100000000000,
          }),
          singleValue: () => {
            return {
              color: "#383B3A",
              fontSize: "14px",
              lineHeight: "22px",
              cursor: "pointer",
            };
          },
        }}
        components={{
          IndicatorSeparator: () => null,
          Option: (props: any) => {
            const { innerProps, innerRef } = props;
            return (
              <article
                ref={innerRef}
                {...innerProps}
                style={{
                  marginTop: 2,
                  marginBottom: 2,
                  width: "100%",
                  height: "38px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  backgroundColor: props.isFocused ? "#F8F9F8" : "white",
                  WebkitUserSelect: "none",
                  msUserSelect: "none",
                  userSelect: "none",
                  cursor: "pointer",
                }}
              >
                <HFlex a-c p-24-rl width={"100%"}>
                  <Text t-14-500 c-s8 f-1>
                    {props.data.label}
                  </Text>
                </HFlex>
              </article>
            );
          },
          DropdownIndicator: () => (
            <Image
              size={24}
              src={`/icons/navigation/arrow_drop_down_s3.svg`}
              style={{ cursor: "pointer" }}
            />
          ),
        }}
        onChange={(item) => {
          onChange(item?.value!);
        }}
        options={options}
        menuPortalTarget={document.querySelector("body")}
        isSearchable={false}
      />
    </HFlex>
  );
};

const MemoBlockBox = ({
  setNoteType,
  setConditionTitle,
  setStyleTitle,
  noteType,
  conditionTypes,
  conditionTitle,
  styleTypes,
  styleTitle,
  notes,
  memo,
}: {
  setNoteType: (value: number) => void;
  setConditionTitle: (value: string) => void;
  setStyleTitle: (value: string) => void;
  noteType: number;
  conditionTypes: string[];
  conditionTitle: string;
  styleTypes: string[];
  styleTitle: string;
  notes: Note[];
  memo: Note[];
}) => {
  return (
    <>
      <HFlex f-1 a-c p-24-l sized ovf-h m-16-b>
        <MemoDropDown
          items={["예약 메모", "컨디션 메모", "스타일 메모"]}
          onChange={(value) => {
            setNoteType(value);
            setConditionTitle("컨디션");
            setStyleTitle("얼굴");
          }}
        />
        <Divider
          m-10
          width={1}
          height={24}
          style={{
            opacity: noteType === 0 ? "0" : "1",
          }}
        />
        {noteType === 0 ? (
          <></>
        ) : noteType === 1 ? (
          <HFlex a-c ovf-s-d clickable>
            <HFlex a-c g-4>
              {conditionTypes.map((type, i) => {
                return (
                  <VFlex
                    key={i}
                    bc-t2={conditionTitle !== type}
                    bc-s8={conditionTitle === type}
                    t-14-s8={conditionTitle !== type}
                    t-14-w={conditionTitle === type}
                    bdr-12
                    clickable
                    p-8-tb
                    p-16-rl
                    style={{
                      marginRight: i === conditionTypes.length - 1 ? 10 : 0,
                    }}
                    onClick={() => {
                      setConditionTitle(type);
                    }}
                  >
                    {type}
                  </VFlex>
                );
              })}
            </HFlex>
          </HFlex>
        ) : (
          <HFlex a-c ovf-s-d clickable>
            <HFlex a-c g-4>
              {styleTypes.map((type, i) => {
                return (
                  <VFlex
                    key={i}
                    bc-t2={styleTitle !== type}
                    bc-s8={styleTitle === type}
                    t-14-s8={styleTitle !== type}
                    t-14-w={styleTitle === type}
                    bdr-12
                    clickable
                    p-8-tb
                    p-16-rl
                    style={{
                      marginRight: i === styleTypes.length - 1 ? 10 : 0,
                    }}
                    onClick={() => {
                      setStyleTitle(type);
                    }}
                  >
                    {type}
                  </VFlex>
                );
              })}
            </HFlex>
          </HFlex>
        )}
      </HFlex>
      <VFlex
        bd-t5
        bdr-16
        p-16
        m-24-rl
        m-32-b
        t-14-s8
        height={100}
        j-b
        g-8
        sized
        ovf-s
      >
        {noteType === 0 ? (
          // 예약 메모
          notes.filter((v) => v.type.value === "REQUEST").length <= 0 ? (
            <VFlex t-14-s2>미입력</VFlex>
          ) : (
            notes
              .filter((v) => v.type.value === "REQUEST")
              .map((v, i) => {
                return (
                  <>
                    {v.note ? (
                      <VFlex key={i} l-m>
                        {v.note}
                      </VFlex>
                    ) : (
                      <VFlex t-14-s2>미입력</VFlex>
                    )}
                    {v.attachments && v.attachments.length > 0 && (
                      <HFlex g-4 a-c>
                        <Image
                          size={16}
                          src={`/icons/schedule_popup/img.svg`}
                        />
                        <VFlex t-14-s2>{v.attachments.length}</VFlex>
                      </HFlex>
                    )}
                  </>
                );
              })
          )
        ) : memo.length <= 0 ? (
          <VFlex t-14-s2>미입력</VFlex>
        ) : (
          <VFlex>
            {noteType === 1
              ? memo
                  .filter((v) => v.type.value.includes("C_"))
                  .map((v, i) => {
                    return (
                      // 컨디션 메모
                      <VFlex key={i}>
                        <VFlex>
                          {v.value?.type === "관심필요" ? (
                            <VFlex
                              style={{
                                color: "#0092E4",
                              }}
                            >
                              관찰이 필요해요
                            </VFlex>
                          ) : v.value?.type ? (
                            v.value?.type
                          ) : (
                            <VFlex t-14-s2>미입력</VFlex>
                          )}
                        </VFlex>
                        {v.note && v.note !== "undefined" && (
                          <VFlex m-4-t l-m>
                            {v.note}
                          </VFlex>
                        )}
                      </VFlex>
                    );
                  })
              : memo
                  .filter((v) => v.type.value.includes("S_"))
                  .map((v, i) => {
                    return (
                      // 스타일 메모
                      v.note && v.note !== "undefined" ? (
                        <VFlex l-m>{v.note}</VFlex>
                      ) : (
                        <VFlex t-14-s2>미입력</VFlex>
                      )
                    );
                  })}
          </VFlex>
        )}
      </VFlex>
    </>
  );
};

const ServiceBlock = ({ schedule }: { schedule: Schedule }) => {
  const [noteType, setNoteType] = useState(0);
  const [conditionTitle, setConditionTitle] = useState("컨디션");
  const [styleTitle, setStyleTitle] = useState("얼굴");
  const [notes, setNotes] = useState<Note[]>([]);
  const [memo, setMemo] = useState<Note[]>([]);

  const conditionTypes = [
    "컨디션",
    "발패드",
    "귀",
    "눈",
    "피부",
    "사마귀/딱지",
  ];
  const styleTypes = ["얼굴", "귀", "몸", "다리", "꼬리", "기타"];

  const fetchNotes = async () => {
    const data = await api.get(
      `/rest/v2/reservation/${schedule.reservationId}/note`
    );
    setNotes(data);
  };

  useEffect(() => {
    fetchNotes();
  }, []);

  useEffect(() => {
    if (notes) {
      noteType === 0
        ? setMemo(notes)
        : noteType === 1
        ? setMemo(notes.filter((v) => v.type.type === conditionTitle))
        : setMemo(notes.filter((v) => v.type.type === styleTitle));
    }
  }, [noteType, conditionTitle, styleTitle]);

  return (
    <VFlex>
      <HFlex p-16-tb p-24-rl a-c g-8>
        <Image size={20} src={`/icons/action/scissors_s6.svg`} />
        <VFlex
          t-14-s8
          style={{
            display: "block",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {schedule.productList?.join(", ") ?? []}
        </VFlex>
      </HFlex>
      <MemoBlockBox
        setNoteType={setNoteType}
        setConditionTitle={setConditionTitle}
        setStyleTitle={setStyleTitle}
        noteType={noteType}
        conditionTypes={conditionTypes}
        conditionTitle={conditionTitle}
        styleTypes={styleTypes}
        styleTitle={styleTitle}
        notes={notes}
        memo={memo}
      />
    </VFlex>
  );
};

const PastServiceBlock = ({ schedule }: { schedule: Schedule }) => {
  const { partner } = usePartner();
  const [pastSchedules, setPastSchedules] = useState<{
    totalCount: number;
    data: ReservationHistoryItem[];
    selectOptions: ReseravtionHistoryOption[];
  }>();

  const [noteType, setNoteType] = useState(0);
  const [conditionTitle, setConditionTitle] = useState("컨디션");
  const [styleTitle, setStyleTitle] = useState("얼굴");
  const [notes, setNotes] = useState<Note[]>([]);
  const [memo, setMemo] = useState<Note[]>([]);

  const conditionTypes = [
    "컨디션",
    "발패드",
    "귀",
    "눈",
    "피부",
    "사마귀/딱지",
  ];
  const styleTypes = ["얼굴", "귀", "몸", "다리", "꼬리", "기타"];

  useEffect(() => {
    (async () => {
      const reservation = await api.get<Reservation>(
        `/rest/reservation/${schedule.reservationId}?groupId=${partner.id}`
      );

      if (reservation) {
        const pastService = await api.get(
          `/rest/v3/reservation/pet/${reservation.petId}/history?groupId=${partner.id}&type=RESV&reservationId=${schedule.reservationId}`
        );

        setPastSchedules(pastService);
      }
    })();
  }, [noteType, partner, schedule]);

  useEffect(() => {
    (async () => {
      if (pastSchedules) {
        const data = await api.get(
          `/rest/v2/reservation/${pastSchedules?.data[0].id}/note`
        );
        setNotes(data);
      }
    })();
  }, [pastSchedules]);

  useEffect(() => {
    if (notes) {
      noteType === 0
        ? setMemo(notes)
        : noteType === 1
        ? setMemo(notes.filter((v) => v.type.type === conditionTitle))
        : setMemo(notes.filter((v) => v.type.type === styleTitle));
    }
  }, [noteType, conditionTitle, styleTitle]);

  return pastSchedules && pastSchedules?.data.length <= 0 ? (
    <VFlex m-16-t m-24-rl m-32-b bc-t1 bdr-16 p-16 t-14-s8 height={100} j-c>
      <VFlex t-14-s2 a-c>
        지난 서비스 내역이 없습니다
      </VFlex>
    </VFlex>
  ) : (
    <VFlex>
      <>
        <HFlex p-16-tb p-24-rl a-c j-b>
          <HFlex g-8 a-c>
            <Image size={20} src={`/icons/action/scissors_s6.svg`} />
            <VFlex
              t-14-s8
              style={{
                display: "block",
                width: 260,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {pastSchedules?.data[0].productName}
            </VFlex>
          </HFlex>
          <VFlex t-12-s2>
            {pastSchedules &&
              format(
                pastSchedules?.data[0].reservationDate * 1000,
                "M월 d일 (E)",
                {
                  locale: ko,
                }
              )}
          </VFlex>
        </HFlex>
      </>
      <MemoBlockBox
        setNoteType={setNoteType}
        setConditionTitle={setConditionTitle}
        setStyleTitle={setStyleTitle}
        noteType={noteType}
        conditionTypes={conditionTypes}
        conditionTitle={conditionTitle}
        styleTypes={styleTypes}
        styleTitle={styleTitle}
        notes={notes}
        memo={memo}
      />
    </VFlex>
  );
};

const CustomerMemoBlock = ({ schedule }: { schedule: Schedule }) => {
  const { partner } = usePartner();
  const [memo, setMemo] = useState("");

  useEffect(() => {
    (async () => {
      const reservation = await api.get<Reservation>(
        `/rest/reservation/${schedule.reservationId}?groupId=${partner.id}`
      );

      if (reservation) {
        setMemo(reservation.pet.petInfo.note ?? "");
      }
    })();
  }, []);

  return (
    <VFlex bd-t5 bdr-16 p-16 m-16-t m-24-rl m-32-b t-14-s8 height={100}>
      {memo ? <VFlex t-14-s8>{memo}</VFlex> : <VFlex t-14-s2>미입력</VFlex>}
    </VFlex>
  );
};

function ScheduleMini({
  popPopup,
  reservationId,
  schedule,
  initialStaffs,
  selectedPet,
  ...props
}: {
  popPopup: () => void;
  reservationId?: number;
  schedule: Schedule;
  initialStaffs?: Staff[];
  selectedPet?: Pet;
  startDateTime?: Date;
  endDateTime?: Date;
}) {
  const tabs = useMemo(
    () => [
      {
        key: "1",
        name: "이번 서비스",
        component: () => <ServiceBlock schedule={schedule} />,
      },
      {
        key: "2",
        name: "지난 서비스",
        component: () => <PastServiceBlock schedule={schedule} />,
      },
      {
        key: "3",
        name: "반려동물 메모",
        component: () => <CustomerMemoBlock schedule={schedule} />,
      },
    ],
    [schedule]
  );

  const { partner } = usePartner();
  const { staffs } = useStaffs();
  const { fetchSchedules } = useSchedules();
  const { fetchCount } = useCount();

  const [selectedStaffs, setSelectedStaffs] = useState<Staff[] | undefined>(
    initialStaffs
  );
  const [date, setDate] = useState<Date | undefined>(
    props.startDateTime ? startOfDay(props.startDateTime) : undefined
  );
  const [selectedTab, setSelectedTab] = useState<TabItemType>(tabs[0]);
  const [selectedCustomer, setSelectedCustomer] = useState<Pet | undefined>(
    selectedPet
  );
  const [status, setStatus] = useState<ScheduleStatus | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [editingSchedule, setEditingSchedule] = useState<Reservation>();

  const [tags, setTags] = useState<TagType[] | undefined>();

  const loadSchedule = useCallback(async () => {
    const reservation = await api.get<Reservation>(
      `/rest/reservation/${reservationId}?groupId=${partner.id}`
    );

    if (reservation) {
      setTags(reservation.pet.tags);
      setSelectedStaffs(reservation.staffs);
      setDate(parseISO(reservation.date));
      setSelectedCustomer({
        id: reservation.petId,
        name: reservation.pet.petInfo.name,
        age: reservation.pet.petInfo.age,
        sex: reservation.pet.petInfo.sex,
        weight: reservation.pet.petInfo.weight,
        species: reservation.pet.petInfo.species.name,
        note: reservation.pet.petInfo.note,
        attachment: reservation.pet.attachment,
        guardianList: [reservation.guardian],
      });
      setStatus(reservation.status);
      setEditingSchedule({ ...reservation });
    }
  }, [partner, reservationId]);

  useEffect(() => {
    if (reservationId) {
      loadSchedule();
    }
  }, [loadSchedule, reservationId]);

  const [isStaff, setIsStaff] = useState<DetailsType>();
  const fetchDetils = async () => {
    const res = await api.get(
      `/rest/group/${partner.id}/details?type=CALENDAR_S`
    );
    setIsStaff(res[0]);
  };
  useEffect(() => {
    fetchDetils();
  }, []);

  const popSchedulePopup = useCallback(
    async (toastText: string) => {
      await fetchSchedules(date);
      if (reservationId) {
        await loadSchedule();
      }
      toast.success(toastText);
      popPopup();
    },
    [fetchSchedules, loadSchedule, popPopup, reservationId, date]
  );

  const isSavable = useMemo(() => {
    return (
      !editingSchedule?.status ||
      ![
        ScheduleStatus.USER_CANCEL,
        ScheduleStatus.PARTNER_CANCEL,
        ScheduleStatus.NO_SHOW,
        ScheduleStatus.COMPLETE,
      ].includes(editingSchedule.status)
    );
  }, [editingSchedule]);

  const isDisable = useMemo(() => {
    return (
      !editingSchedule?.status ||
      ![
        ScheduleStatus.USER_CANCEL,
        ScheduleStatus.PARTNER_CANCEL,
        ScheduleStatus.NO_SHOW,
      ].includes(editingSchedule.status)
    );
  }, [editingSchedule]);

  const isChanged = useCallback(() => {
    if (editingSchedule) {
      return (
        JSON.stringify(selectedStaffs) !==
          JSON.stringify(editingSchedule.staffs) ||
        status !== editingSchedule.status
      );
    } else {
      return !!date || !!selectedCustomer;
    }
  }, [date, status, selectedStaffs]);

  const isStatusCompleted = useCallback(() => {
    if (editingSchedule) {
      return (
        editingSchedule.status !== ScheduleStatus.COMPLETE &&
        status === ScheduleStatus.COMPLETE
      );
    }
  }, [status, editingSchedule]);
  const isStatusNoShow = useCallback(() => {
    if (editingSchedule) {
      return (
        editingSchedule.status !== ScheduleStatus.NO_SHOW &&
        status === ScheduleStatus.NO_SHOW
      );
    }
  }, [status, editingSchedule]);
  const isStatusCancelled = useCallback(() => {
    if (editingSchedule) {
      return (
        editingSchedule.status !== ScheduleStatus.PARTNER_CANCEL &&
        status === ScheduleStatus.PARTNER_CANCEL
      );
    }
  }, [status, editingSchedule]);
  const isStaffChanged = useCallback(() => {
    if (editingSchedule) {
      return (
        JSON.stringify(selectedStaffs) !==
        JSON.stringify(editingSchedule.staffs)
      );
    }
  }, [editingSchedule, selectedStaffs]);

  const isStatusConfirmVisited = useCallback(() => {
    if (editingSchedule) {
      return (
        editingSchedule.status !== ScheduleStatus.CONFIRM_VISIT &&
        status === ScheduleStatus.CONFIRM_VISIT
      );
    }
  }, [status, editingSchedule]);

  const isInquiriesSchedule = useMemo(() => {
    return (
      schedule.incomingPlatform === "PUA" || schedule.incomingPlatform === "PUW"
    );
  }, [schedule]);

  const [isFullHovered, setIsFullHovered] = useState(false);
  const [isHideHovered, setIsHideHovered] = useState(false);

  return loading ? (
    <Spinner />
  ) : (
    <VFlex
      abs
      width={400}
      bc-w
      bdr-16
      style={{
        boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.2)",
      }}
    >
      <VFlex p-24-trl p-16-b g-16>
        <HFlex j-b a-c g-16>
          <HFlex
            a-c
            clickable
            onClick={() => {
              pushPopup({
                kind: PopupKind.Popup,
                element: CustomerDetail,
                props: {
                  petId: selectedCustomer?.id,
                },
              });
              popPopup();
            }}
          >
            <VFlex t-18-600-s8>
              {schedule.petName} ({schedule.species})
            </VFlex>
            <Image size={24} src={`/icons/chevron_right.png`} />
          </HFlex>
          <HFlex rel g-8>
            <VFlex
              p-4
              bd-t5
              bdr-8
              clickable
              onMouseEnter={(e) => {
                setIsFullHovered(true);
                e.stopPropagation();
              }}
              onMouseLeave={(e) => {
                setIsFullHovered(false);
                e.stopPropagation();
              }}
              onClick={() => {
                pushPopup({
                  key: "addSchedule",
                  kind: PopupKind.Popup,
                  element: ReservationDetail,
                  props: {
                    reservationId: schedule.reservationId,
                    petId: selectedCustomer?.id,
                    schedule: schedule,
                    editingReservation: editingSchedule,
                  },
                  dimClick: "none",
                });
                popPopup();
              }}
            >
              <Image size={20} src={`/icons/action/open_in_full_s6.svg`} />
              {isFullHovered && (
                <VFlex
                  p-2-tb
                  p-6-rl
                  bc-s8
                  bdr-4
                  t-12-w
                  style={{
                    position: "absolute",
                    top: 34,
                    left: "-14px",
                    boxShadow:
                      "0px 4px 8px 1px rgba(0, 0, 0, 0.20), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)",
                    zIndex: 1,
                  }}
                >
                  전체 보기
                </VFlex>
              )}
            </VFlex>
            {!isSavable && (
              <VFlex
                p-4
                bd-t5
                bdr-8
                clickable
                onMouseEnter={(e) => {
                  setIsHideHovered(true);
                  e.stopPropagation();
                }}
                onMouseLeave={(e) => {
                  setIsHideHovered(false);
                  e.stopPropagation();
                }}
                onClick={() => {
                  pushPopup({
                    kind: PopupKind.Popup,
                    align: PopupAlign.TopCenter,
                    element: ConfirmPopup,
                    props: {
                      title:
                        "예약을 숨길까요?\n숨긴 후에도 숨긴 예약 목록에서 확인 할 수 있습니다.",
                      icon: "/icons/icon_success.png",
                      confirmButtonLabel: "숨기기",
                      confirmButtonType: "confirm",
                      onConfirm: async () => {
                        setLoading(true);
                        popPopup();
                        await api.put(
                          `/rest/reservation/${reservationId}/delete?type=HIDDEN`
                        );
                        popSchedulePopup("예약을 숨겼습니다.");
                        await fetchCount({
                          date: date!.getTime() / 1000,
                        });
                      },
                    },
                  });
                }}
              >
                <Image
                  size={20}
                  src={`/icons/toggle/visibility_off_outline_s6.svg`}
                />
                {isHideHovered && (
                  <VFlex
                    p-2-tb
                    p-6-rl
                    bc-s8
                    bdr-4
                    t-12-w
                    style={{
                      position: "absolute",
                      top: 34,
                      left: 22,
                      boxShadow:
                        "0px 4px 8px 1px rgba(0, 0, 0, 0.20), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)",
                      zIndex: 1,
                    }}
                  >
                    예약 숨기기
                  </VFlex>
                )}
              </VFlex>
            )}
          </HFlex>
        </HFlex>
        <HFlex
          g-4
          style={{
            flexWrap: "wrap",
          }}
        >
          {isInquiriesSchedule && (
            <VFlex
              a-c
              j-c
              bdr-8
              style={{
                padding: "4px 5px 5px 4px",
                background: "#E5F0FF",
              }}
            >
              <Image size={17} src={`/icons/send.svg`} />
            </VFlex>
          )}
          {tags &&
            tags.map((tag, index) => (
              <Tag
                key={index}
                tag={{
                  ...tag,
                  backgroundColor: tag.backgroundColor,
                  textColor: tag.textColor,
                }}
                isActived
              />
            ))}
          <VFlex
            bc-t2
            bdr-8
            p-2-tb
            p-8-rl
            a-c
            j-c
            t-14
            bd-t2
            style={{
              color: "#848A87",
            }}
          >
            {`${selectedCustomer?.guardianList[0]?.name}•`}
            {selectedCustomer?.guardianList[0]?.cellnumber}
          </VFlex>
        </HFlex>
      </VFlex>
      <Divider height={1} />
      <VFlex p-16-t p-24-rl p-32-b g-16>
        <HFlex a-c j-b>
          <HFlex g-8 a-c>
            <VFlex>
              <DropdownMini
                isSearchable={false}
                isDisabled={!isSavable}
                value={status}
                options={[
                  ScheduleStatus.CONFIRM,
                  ScheduleStatus.COMPLETE,
                  ScheduleStatus.NO_SHOW,
                  ScheduleStatus.PARTNER_CANCEL,
                  ScheduleStatus.CONFIRM_VISIT,
                ].map((value) => ({
                  value,
                  label: scheduleStatusString[value],
                }))}
                onChange={(value) => {
                  setStatus(value?.value);
                }}
                required={true}
              />
            </VFlex>
            {/* TODO : 알림톡 내역 */}
            {/* <VFlex p-4 bd-t3 bdr-8 clickable onClick={() => {}}>
              <Image size={24} src={`/icons/schedule_popup/chat.svg`} />
            </VFlex> */}
          </HFlex>
          {/* TODO : 문의 시간 */}
          {/* <VFlex t-12-s2>1일전</VFlex> */}
        </HFlex>
        <VFlex>
          <HFlex
            g-8
            a-c
            style={{
              opacity: !isDisable ? "0.5" : "1",
            }}
          >
            <Image size={20} src={`/icons/action/event_available_s6.svg`} />
            <VFlex t-14-s8>
              {format(schedule.startDateTime, "M월 d일 (E) a h:mm ~ ", {
                locale: ko,
              })}
              {format(schedule.endDateTime, "a h:mm", { locale: ko })}
            </VFlex>
          </HFlex>
          {/* TODO : 반복 예약 */}
          {/* <VFlex m-24-l t-14-s4>
              매주 수요일
            </VFlex> */}
        </VFlex>
        <HFlex
          g-8
          // a-c
          style={{
            opacity: !isDisable ? "0.5" : "1",
          }}
        >
          <Image
            style={{ marginTop: 7 }}
            size={20}
            src={`/icons/action/face_s6.svg`}
          />
          <VFlex t-14-s8>
            <DropdownMini
              multi={true}
              values={selectedStaffs?.map((staff) => staff.id.toString())}
              options={staffs.map((staff) => ({
                value: staff.id.toString(),
                obj: staff,
                label: `${staff.name} ${staff.jobTitle}`,
              }))}
              placeholder={
                isStaff?.value === "FALSE" ? "담당자를 선택하세요" : "미지정"
              }
              onChangeValues={(values) => {
                setSelectedStaffs(values?.map((value) => value.obj));
              }}
              required={true}
            />
          </VFlex>
        </HFlex>
        {/* TODO : 예약금 */}
        {/* <HFlex g-8 a-c>
            <Image size={16} src={`/icons/schedule_popup/payment.svg`} />
            <VFlex
              t-14-s8
              style={{
                textDecoration: 'underline',
              }}
            >
              예약금 20,000원
            </VFlex>
          </HFlex> */}
      </VFlex>
      <VFlex p-24-rl height={35} sized bd-b-t3>
        <TabBar
          value={selectedTab}
          items={tabs}
          onChange={(item) => setSelectedTab(item)}
        />
      </VFlex>
      {selectedTab && React.createElement(selectedTab.component)}
      <Button
        type="confirm"
        caption="변경사항 저장"
        height={46}
        style={{
          display: editingSchedule && isChanged() ? "block" : "none",
          margin: "0 24px 24px",
          background: "none",
          color: "#27BD63",
          border: "1px solid #27BD63",
          borderRadius: 16,
          fontSize: "14px",
          fontWeight: "600",
          textAlign: "center",
          lineHeight: "46px",
        }}
        enabled={editingSchedule && isChanged()}
        onClick={async () => {
          if (isStatusNoShow()) {
            const startDate = new Date(schedule.startDateTime);
            if (startDate.getTime() > new Date().getTime()) {
              pushPopup({
                kind: PopupKind.Popup,
                align: PopupAlign.TopCenter,
                element: ConfirmPopup,
                props: {
                  title: "노쇼 처리는 예약시간 이후부터 가능합니다.",
                  icon: "/icons/icon_good_warning.png",
                  cancelButtonDisplay: "none",
                  confirmButtonLabel: "확인",
                  confirmButtonType: "confirm",
                  onConfirm: () => {
                    // popPopup();
                  },
                },
              });
            } else {
              pushPopup({
                kind: PopupKind.Popup,
                align: PopupAlign.TopCenter,
                element: ConfirmPopup,
                props: {
                  title: `고객이 연락 없이 오지 않았다면, 노쇼 처리가 가능합니다.\n노쇼 처리시, 고객에게 메세지가 전송됩니다.`,
                  icon: "/icons/icon_good_warning.png",
                  confirmButtonLabel: "노쇼 처리하고 메세지 보내기",
                  confirmButtonType: "confirm",
                  onConfirm: async () => {
                    setLoading(true);
                    popPopup();
                    await api.put(`/rest/reservation/${reservationId}/no-show`);
                    popSchedulePopup("노쇼 처리되었습니다.");
                  },
                },
              });
            }
          } else if (isStatusCompleted()) {
            const startDate = new Date(schedule.startDateTime);
            const endDate = new Date(startDate);
            endDate.setMinutes(endDate.getMinutes() + 30);
            if (endDate.getTime() > new Date().getTime()) {
              pushPopup({
                kind: PopupKind.Popup,
                align: PopupAlign.TopCenter,
                element: ConfirmPopup,
                props: {
                  title: "이용완료 처리는 예약시간 30분 이후부터 가능합니다.",
                  icon: "/icons/icon_good_warning.png",
                  cancelButtonDisplay: "none",
                  confirmButtonLabel: "확인",
                  confirmButtonType: "confirm",
                  onConfirm: () => {
                    // popPopup();
                  },
                },
              });
            } else {
              pushPopup({
                kind: PopupKind.Popup,
                align: PopupAlign.TopCenter,
                element: ConfirmPopup,
                props: {
                  title: `이용 완료 처리를 하면\n고객에게 서비스 후 주의사항 메세지가 보내집니다.`,
                  icon: "/icons/icon_good_warning.png",
                  confirmButtonLabel: "이용 완료 변경하기",
                  confirmButtonType: "confirm",
                  onConfirm: async () => {
                    setLoading(true);
                    popPopup();
                    await api.put(
                      `/rest/reservation/${reservationId}/complete?groupId=${partner?.id}`
                    );
                    popSchedulePopup("이용완료 처리되었습니다.");
                  },
                },
              });
            }
          } else if (isStaffChanged()) {
            if (isStaff?.value === "FALSE" && selectedStaffs?.length === 0) {
              return toast.error("담당자를 선택해주세요.");
            } else {
              await api.put(
                `/rest/reservation/${reservationId}/chargers?${selectedStaffs
                  ?.map((staff) => `chargers=${staff.id}`)
                  .join("&")}`
              );
              popSchedulePopup("담당자가 변경되었습니다.");
            }
          } else if (isStatusConfirmVisited()) {
            pushPopup({
              kind: PopupKind.Popup,
              align: PopupAlign.TopCenter,
              element: ConfirmPopup,
              props: {
                title: `방문확정은 보호자의 방문 여부를 알려주는 상태로,\n보호자만 변경할 수 있습니다.`,
                icon: "/icons/icon_good_warning.png",
                cancelButtonDisplay: "none",
                confirmButtonLabel: "확인",
                confirmButtonType: "confirm",
                onConfirm: () => {
                  popPopup();
                },
              },
            });
          } else if (isStatusCancelled()) {
            pushPopup({
              kind: PopupKind.Popup,
              align: PopupAlign.TopCenter,
              element: CancelSchedulePopup,
              props: {
                title:
                  "예약을 취소하면 고객에게 취소에 대한 메세지가 보내집니다.",
                confirmButtonLabel: "예약 취소하고 메세지 보내기",
                confirmButtonStyle: "warning",
                onConfirm: async (reason: any) => {
                  setLoading(true);
                  popPopup();
                  await api.put(
                    `/rest/reservation/${reservationId}/cancel?accountType=2`,
                    {
                      cancelNote: reason,
                      id: reservationId,
                      requestNote: "",
                      type: "",
                    }
                  );
                  popSchedulePopup("예약이 취소되었습니다.");
                },
              },
            });
          }
        }}
      />
    </VFlex>
  );
}

export default ScheduleMini;
