// base
import { useEffect, useState } from "react";

// common
import api from "../../../common/api";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";

// components
import Dropdown from "../../../components/Dropdown";
import Toggle from "../../../components/Toggle";
import ConfirmPopup from "../../../popup/ConfirmPopup";
import { PopupAlign, PopupKind } from "../../../popup/Popup";

// providers
import { usePartner } from "../../../providers/partner";
import { pushPopup } from "../../../providers/popups";
import { DetailsType } from "../../../providers/types";

// layouts
import Image from "../../../layouts/Image";
import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Text from "../../../layouts/Text";
import Button from "../../../layouts/Button";

// lib
import _ from "lodash";
import { toast } from "react-hot-toast";

function SettingCalendar({ popPopup }: { popPopup: () => void }) {
  const { trackClickCalendarTimeSetting, trackClickUnassignedFeatures } =
    useAmplitudeTrack();

  const { partner } = usePartner();
  const [isStaff, setIsStaff] = useState<DetailsType>();
  const [isTimeSetting, setIsTimeSetting] = useState<DetailsType>();
  // const [isSchedule, setIsSchedule] = useState<DetailsType>();
  // const [isOtherCalendar, setIsOterCalendar] = useState<DetailsType>();
  // const [scheduleValue, setScheduleValue] = useState<string | undefined>();

  const fetchDetails = async () => {
    const res = await api.get(
      `/rest/group/${partner.id}/details?type=CALENDAR_S`
    );

    setIsStaff(res[0]);
    setIsTimeSetting(res[1]);
    // setIsSchedule(res[2]);
    // setIsOterCalendar(res[3]);
    // setScheduleValue(res[2].value);
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  return (
    <VFlex a-st width={530}>
      <VFlex>
        <HFlex m-24-brl m-32-t>
          <Text f-1 t-20-700-s9>
            캘린더 설정
          </Text>
          <Image
            size={24}
            src={`/icons/popup_close.png`}
            clickable
            onClick={() => {
              popPopup();
            }}
          />
        </HFlex>
      </VFlex>

      <VFlex f-1 ovf-a>
        <VFlex f-1>
          <VFlex height={74}>
            <Toggle
              value={isStaff?.value === "TRUE" ? true : false}
              label={isStaff?.title}
              subTitle={isStaff?.content}
              onChange={async (value) => {
                await api
                  .put(
                    `/rest/group/${partner.id}/details/${isStaff?.id}?value=${
                      value ? "TRUE" : "FALSE"
                    }`
                  )
                  .then((res) => {
                    if (!res) {
                      pushPopup({
                        kind: PopupKind.Popup,
                        align: PopupAlign.TopCenter,
                        element: ConfirmPopup,
                        props: {
                          title: `등록된 예약이 있어 OFF 할 수 없습니다\n미지정 예약을 수정한 후 다시 시도해주세요.`,
                          cancelButtonDisplay: "none",
                          onConfirm: () => {},
                        },
                      });
                    } else {
                      toast.success(
                        `담당자 미지정 기능이 ${
                          isStaff?.value === "TRUE" ? "OFF" : "ON"
                        } 되었습니다.`
                      );
                      fetchDetails();
                    }
                  });
                trackClickUnassignedFeatures();
              }}
            />
          </VFlex>

          <VFlex p-24-rl p-32-b>
            <Dropdown
              isDisabled
              value={isTimeSetting?.value}
              options={[{ id: 1, name: "30분" }].map((v) => ({
                value: v.id?.toString(),
                obj: v,
                label: v.name,
              }))}
              placeholder={`${isTimeSetting?.value}분`}
              caption={isTimeSetting?.title}
              description={isTimeSetting?.content}
              placeholderColor={"#252A2F"}
              onChange={(value) => {}}
              onClick={() => {
                trackClickCalendarTimeSetting(partner.title);
                toast.success("준비중인 기능입니다.");
              }}
            />
          </VFlex>

          {/* TODO */}
          {/* <VFlex p-24-rl style={{ paddingTop: 15 }}>
            <Dropdown
              value={scheduleValue}
              options={[
                { id: 1, name: "10분" },
                { id: 2, name: "20분" },
                { id: 3, name: "30분" },
                { id: 4, name: "40분" },
                { id: 5, name: "50분" },
                { id: 6, name: "60분" },
              ].map((v) => ({
                value: v.id?.toString(),
                obj: v,
                label: v.name,
              }))}
              placeholder={scheduleValue + "분"}
              caption={isSchedule?.title}
              description={isSchedule?.content}
              placeholderColor={"#383B3A"}
              onChange={(value) => {
                setScheduleValue(value?.label.split("분")[0]);
              }}
            />
          </VFlex> */}

          {/* <VFlex height={74}>
            <Toggle
              value={isOtherCalendar?.value === "TRUE" ? true : false}
              label={isOtherCalendar?.title}
              subTitle={isOtherCalendar?.content}
              onChange={async (value) => {
                // TODO
                // await api.put(
                //   `/rest/group/${partner.id}/details/${
                //     isOtherCalendar.id
                //   }?value=${value ? "TRUE" : "FALSE"}`
                // );
                // await fetchDetils();
              }}
            />
          </VFlex> */}
        </VFlex>

        {/* <VFlex p-32>
          <Button
            t-14-700-w
            type="confirm-lg"
            style={{
              width: "100%",
            }}
            onClick={async () => {
              // TODO : 일정 설정 저장 후, 드래그 높이값 조절
              // await api
              //   .put(
              //     `/rest/group/${partner.id}/details/${isSchedule?.id}?value=${scheduleValue}`
              //   )
              //   .then(() => {
              //     toast.success("저장되었습니다");
              //     popPopup();
              //   })
              //   .catch(() => {
              //     toast.error("다시 시도해주세요");
              //   });

              popPopup();
            }}
          >
            저장
          </Button>
        </VFlex> */}
      </VFlex>
    </VFlex>
  );
}

export default SettingCalendar;
