import { useState } from "react";
import TextInput from "../../../layouts/TextInput";
import Image from "../../../layouts/Image";
import HFlex from "../../../layouts/HFlex";
import Text from "../../../layouts/Text";
import VFlex from "../../../layouts/VFlex";
import ConfirmPopup from "../../../popup/ConfirmPopup";
import { PopupAlign, PopupKind } from "../../../popup/Popup";
import { pushPopup } from "../../../providers/popups";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";
import Flex from "../../../layouts/Flex";
import Absolute from "../../../layouts/Absolute";
import { GuardianForm } from "../../../providers/customerForm";
import TextArea from "../../../layouts/TextareaV2";

function GuardianInfo({
  index,
  guardian,
  existCoparent,
  handleChangeCellNumber,
  handleChangeMemo,
  handleChangeName,
  setPrimaryGuardian,
  deleteGuardian,
}: {
  index: number;
  guardian: GuardianForm;
  existCoparent?: boolean;
  handleChangeName: (name: string | undefined) => void;
  handleChangeCellNumber: (cellNum: string | undefined) => void;
  handleChangeMemo: (memo: string | undefined) => void;
  setPrimaryGuardian: () => void;
  deleteGuardian: () => void;
}) {
  const { trackClickDeleteGuardian, trackDeleteGuardian } = useAmplitudeTrack();
  const [isShowDropdown, setIsShowDropdown] = useState(false);

  return (
    <VFlex a-st m-32-b>
      <VFlex g-16>
        {
          <HFlex a-c>
            <Text t-16-600-s8 m-8-r>
              보호자 {index + 1}
            </Text>
            {guardian.primaryGuardian && (
              <Flex p-2-tb p-8-rl bd-t4 bdr-8>
                <Text t-14-500-s6>대표 보호자</Text>
              </Flex>
            )}
            <Flex f-1 />
            {existCoparent && (
              <>
                <VFlex rel>
                  <Image
                    src="/icons/icon_more_horiz_s6.svg"
                    size={24}
                    clickable
                    onClick={() => {
                      setIsShowDropdown(true);
                    }}
                  />
                  {isShowDropdown && (
                    <Absolute style={{ inset: "28px 0 auto auto", zIndex: 3 }}>
                      <VFlex width={180} bdr-12 sdw-6 bc-w p-8>
                        {!guardian.primaryGuardian && (
                          <Text
                            t-14-500-s8
                            p-8
                            bdr-8
                            clickable
                            onClick={() => {
                              pushPopup({
                                element: ConfirmPopup,
                                kind: PopupKind.Popup,
                                align: PopupAlign.TopCenter,
                                props: {
                                  title: `보호자 ${
                                    index + 1
                                  }를 대표 보호자로 설정할까요?`,
                                  icon: "/icons/icon_noti_info.png",
                                  confirmButtonType: "confirm",
                                  confirmButtonLabel: "확인",
                                  onConfirm: () => {
                                    setPrimaryGuardian();
                                  },
                                },
                              });
                              setIsShowDropdown(false);
                            }}
                          >
                            대표 보호자로 설정
                          </Text>
                        )}
                        <Text
                          t-14-500-err2
                          p-8
                          bdr-8
                          clickable
                          onClick={() => {
                            pushPopup({
                              element: ConfirmPopup,
                              kind: PopupKind.Popup,
                              align: PopupAlign.TopCenter,
                              props: {
                                title: `보호자 ${index + 1}를 삭제할까요?`,
                                confirmButtonType: "delete",
                                confirmButtonLabel: "삭제",
                                onConfirm: () => {
                                  deleteGuardian();
                                },
                              },
                            });
                            setIsShowDropdown(false);
                          }}
                        >
                          보호자 {index + 1} 삭제
                        </Text>
                      </VFlex>
                    </Absolute>
                  )}
                </VFlex>

                {isShowDropdown && (
                  <Absolute
                    style={{ zIndex: 2 }}
                    onClick={() => setIsShowDropdown(false)}
                  />
                )}
              </>
            )}
          </HFlex>
        }
        <HFlex a-c g-16>
          <VFlex f-1>
            <TextInput
              caption={"보호자명"}
              placeholder={"보호자 이름"}
              value={guardian.name}
              onChangeValue={(value) => handleChangeName(value)}
            />
          </VFlex>
          <VFlex f-1>
            <TextInput
              required={true}
              caption={"휴대폰 번호"}
              placeholder={"휴대폰 번호"}
              value={guardian.cellnumber}
              inputMode="numeric"
              onKeyDown={(e) => {
                if (!(e.key === "Backspace" || /[0-9]/.test(e.key))) {
                  e.preventDefault();
                }
              }}
              onChangeValue={(value) => handleChangeCellNumber(value)}
            />
          </VFlex>
        </HFlex>
        <TextArea
          caption={"보호자 메모"}
          placeholder={"메모를 입력해주세요"}
          value={guardian.note}
          onChangeValue={(value) => handleChangeMemo(value)}
        />
      </VFlex>
    </VFlex>
  );
}

export default GuardianInfo;
