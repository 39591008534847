import React from "react";

import { Guardian } from "../../providers/types";

import VFlex from "../../layouts/VFlex";
import HFlex from "../../layouts/HFlex";
import Text from "../../layouts/Text";
import Image from "../../layouts/Image";
import Notification from "../../layouts/Notification";
import ButtonV2 from "../../layouts/ButtonV2";

import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import Flex from "../../layouts/Flex";
import { PopupKind } from "../../popup/Popup";
import { pushPopup } from "../../providers/popups";
import AddCustomer from "../../scenes/Main/Customers/AddCustomer";

const GuardianList = ({
  guardianList,
  isDeletedCustomer,
  petId,
  fetchGuardianList,
}: {
  guardianList: Guardian[];
  isDeletedCustomer: boolean;
  petId: number;
  fetchGuardianList: () => void;
}) => {
  return (
    <>
      {/* {isDeletedCustomer ? (
        <>
          <VFlex>
            <Notification type={"full"} color="err">
              <HFlex a-c>
                <Image src="/icons/action/info_w.svg" size={22} m-12-r />
                <HFlex g-8>
                  <Notification.Title color>
                    삭제된 고객입니다.
                  </Notification.Title>
                </HFlex>
              </HFlex>
            </Notification>
          </VFlex>
          <VFlex a-c j-c m-24 bc-w bdr-16 g-8 height={300}>
            <Image size={24} src={`/icons/icon_noti.svg`} />
            <Text t-16-s2 m-8-b>
              삭제된 정보를 보려면 plus plan으로 업그레이드하세요.
            </Text>
            <ButtonV2 type="filled" onClick={() => {}}>
              업그레이드
            </ButtonV2>
          </VFlex>
        </>
      ) : ( */}
      <>
        {isDeletedCustomer && (
          <VFlex>
            <Notification type={"full"} color="err">
              <HFlex a-c p-8-rl>
                <Image src="/icons/action/info_w.svg" size={22} m-12-r />
                <HFlex g-8>
                  <Notification.Title color>
                    삭제된 고객입니다.
                  </Notification.Title>
                </HFlex>
              </HFlex>
            </Notification>
          </VFlex>
        )}
        <HFlex height={56} a-c bc-w p-24-rl sized>
          <Flex f-1 />
          <HFlex
            g-4
            a-c
            clickable={!isDeletedCustomer}
            onClick={() => {
              if (!isDeletedCustomer)
                pushPopup({
                  kind: PopupKind.Popup,
                  element: AddCustomer,
                  width: 1024,
                  props: {
                    fetchCurrentPage: fetchGuardianList,
                    tab: 1,
                    petId,
                  },
                  dimClick: "none",
                });
            }}
          >
            <Text t-14-500-s4>보호자 정보 수정</Text>
            <Image src="/icons/icon_chevron_right_s3.svg" size={24} />
          </HFlex>
        </HFlex>
        <VFlex p-24 ovf-a g-12>
          {guardianList.map((guardian) => {
            return (
              <VFlex g-16 bc-w p-24 bdr-16 sized key={guardian.id}>
                <VFlex g-16>
                  <HFlex j-b a-c bd-b-t2 p-16-b g-16>
                    <HFlex g-8 a-c>
                      <Text t-16-s8>{guardian.name}</Text>
                      {guardian.primaryGuardian && (
                        <VFlex t-14-s6 p-2-tb p-8-rl bd-t4 bdr-8>
                          대표 보호자
                        </VFlex>
                      )}
                    </HFlex>
                    <CopyToClipboard
                      text={guardian.cellnumber}
                      onCopy={() => {
                        toast.success("전화번호가 복사되었습니다.");
                      }}
                    >
                      <HFlex g-16 a-c clickable>
                        <Text t-14-s6>{guardian.cellnumber}</Text>
                        <Image size={22} src={`/icons/content/copy_s6.svg`} />
                      </HFlex>
                    </CopyToClipboard>
                  </HFlex>

                  {guardian.note ? (
                    <Text t-14-s6 l-m>
                      {guardian.note}
                    </Text>
                  ) : (
                    <Text t-14-s2>작성된 내용이 없습니다.</Text>
                  )}
                </VFlex>
              </VFlex>
            );
          })}
        </VFlex>
      </>
      {/* )} */}
    </>
  );
};

export default GuardianList;
