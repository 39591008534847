import Absolute from "../layouts/Absolute";
import HFlex from "../layouts/HFlex";
import Image from "../layouts/Image";
import VFlex from "../layouts/VFlex";
import Text from "../layouts/Text";
import { PopupKind } from "../popup/Popup";
import { pushPopup } from "../providers/popups";
import { Attachment } from "../providers/types";
import PhotosSwiperPopup from "./PhotosSwiperPopup";

interface PhotoPreviewProps {
  photos: Attachment[];
}

const PhotoPreview = ({ photos }: PhotoPreviewProps) => {
  const pushPhotosSwiperPopup = (index: number) => {
    pushPopup({
      element: PhotosSwiperPopup,
      kind: PopupKind.Swiper,
      props: {
        attachments: photos,
        index,
      },
      dimClick: "none",
    });
  };

  return (
    // <HFlex g-8 rel>
    <HFlex
      g-8
      style={{
        flexWrap: "wrap",
      }}
    >
      {photos.map((photo, index) => (
        <Image
          src={photo.path}
          size={56}
          key={photo.id}
          bdr-16
          clickable
          onClick={() => pushPhotosSwiperPopup(index)}
        />
      ))}
      {/* {photos.length >= 10 ? (
        <>
          {photos.slice(0, 8).map((photo, index) => (
            <Image
              src={photo.path}
              size={56}
              key={photo.id}
              bdr-16
              clickable
              onClick={() => pushPhotosSwiperPopup(index)}
            />
          ))}
          <VFlex onClick={() => pushPhotosSwiperPopup(0)}>
            <Image
              src={photos[8].path}
              size={56}
              key={photos[8].id}
              bdr-16
              clickable
            />
            <Absolute
              style={{
                width: 56,
                height: 56,
                background: "rgba(0,0,0,40%)",
              }}
              top={"auto"}
              bottom={"auto"}
              left={"auto"}
              right={"auto"}
              bdr-16
              c-c
              clickable
            >
              <Text t-20-600-tw>+{photos.length - 8}</Text>
            </Absolute>
          </VFlex>
        </>
      ) : (
        photos.map((photo, index) => (
          <Image
            src={photo.path}
            size={56}
            key={photo.id}
            bdr-16
            clickable
            onClick={() => pushPhotosSwiperPopup(index)}
          />
        ))
      )} */}
    </HFlex>
  );
};

export default PhotoPreview;
