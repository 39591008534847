import { ReactElement, useEffect, useMemo, useState } from "react";
import NotesAfterService from "./NotesAfterService";
import ReservationInfo from "./ReservationInfo";
import VFlex from "../../../layouts/VFlex";
import Image from "../../../layouts/Image";
import HFlex from "../../../layouts/HFlex";
import Text from "../../../layouts/Text";
import TabBar, { TabItemType } from "../../../components/TabBar";
import React from "react";
import MileageSettings from "./MileageSettings";
import { usePartner } from "../../../providers/partner";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";
import { pushPopup } from "../../../providers/popups";
import { PopupAlign, PopupKind } from "../../../popup/Popup";
import ConfirmPopup from "../../../popup/ConfirmPopup";

function Policies({
  popPopup,
}: {
  setSelectedSubpage: (component?: ReactElement) => void;
  popPopup: () => void;
}) {
  const { partner } = usePartner();
  const { trackLandPolicyManage, trackClickPolicyTab } = useAmplitudeTrack();

  useEffect(() => {
    trackLandPolicyManage();
  }, []);

  const tabs = useMemo(
    () => [
      {
        key: "1",
        name: "서비스 후 주의사항",
        component: () => <NotesAfterService popPopup={popPopup} />,
      },
      {
        key: "2",
        name: "예약 시 안내사항",
        component: () => <ReservationInfo popPopup={popPopup} />,
      },
      {
        key: "3",
        name: "적립금 관리",
        component: () => <MileageSettings popPopup={popPopup} />,
      },
    ],
    []
  );
  const [selectedTab, setSelectedTab] = useState<TabItemType>(tabs[0]);

  return (
    <VFlex f-1 a-st p-24-t>
      <HFlex m-32-rl>
        <VFlex f-1 />
        <Image
          size={24}
          src={`/icons/popup_close.png`}
          clickable
          onClick={() => {
            pushPopup({
              kind: PopupKind.Popup,
              align: PopupAlign.TopCenter,
              element: ConfirmPopup,
              props: {
                title: "변경사항을 저장하지 않고 닫을까요?",
                onConfirm: () => {
                  popPopup();
                },
              },
            });
          }}
        />
      </HFlex>
      <Text t-20-700-gr10 m-32-rl m-24-b>
        정책 관리
      </Text>
      <HFlex height={42} m-32-rl>
        <TabBar
          value={selectedTab}
          items={tabs}
          onChange={(item) => {
            setSelectedTab(item);
            trackClickPolicyTab(item.name, partner.title);
          }}
        />
      </HFlex>

      <VFlex f-1 ovf-a>
        {React.createElement(selectedTab.component)}
      </VFlex>
    </VFlex>
  );
}

export default Policies;
