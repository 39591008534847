import {
  InputHTMLAttributes,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from "react";
import Absolute from "./Absolute";
import HFlex from "./HFlex";
import VFlex from "./VFlex";
import Text from "./Text";
import SvgIcon, { SvgIconType } from "../svg";
import { isNil } from "lodash";

const SubfixInput = ({
  caption,
  required,
  disabled,
  error,
  errorText,
  value,
  inputClassName,
  onChangeValue,
  renderSubfix,
  validate,
  ...props
}: InputHTMLAttributes<HTMLInputElement> & {
  caption?: string;
  isNumber?: boolean;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  errorText?: string;
  value?: string;
  inputClassName?: string;
  onChangeValue?: (value: string | undefined) => void;
  renderSubfix: () => ReactElement;
  validate?: (v: string) => boolean;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState<string | undefined>(value);
  const [focused, setFocused] = useState<boolean>(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (
      (validate && validate(newValue)) ||
      newValue === "" ||
      isNil(validate)
    ) {
      setInputValue(newValue);
      onChangeValue?.(newValue);
    }
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <VFlex
      g-8
      unclickable={disabled}
      clickable={!disabled}
      style={{ cursor: "text" }}
    >
      {caption && (
        <HFlex g-8>
          <Text t-14-s8>{caption}</Text>
          {required && <Text t-14-700-rd1>*</Text>}
        </HFlex>
      )}
      <VFlex
        bc-w
        bd-t5={!error && !focused}
        bd-gr5={!error && focused}
        bd-err1={error}
        rel
        bdr-16
        height={56}
        onClick={() => {
          setFocused(true);
          inputRef.current?.focus();
        }}
      >
        <Absolute>
          <HFlex
            a-c
            j-b
            p-24-rl
            g-16
            style={{ opacity: focused || caption || !!value ? 1 : 0 }}
            height={"100%"}
          >
            <input
              ref={inputRef}
              style={{
                flex: 1,
                backgroundColor: "transparent",
                borderWidth: 0,
                padding: 0,
                height: 22,
                color: disabled ? "#979E9A" : "#252726",
                marginTop: !caption ? 16 : 0,
                fontSize: !caption ? 14 : 16,
              }}
              value={inputValue || ""}
              onChange={handleInputChange}
              {...props}
              onFocus={() => setFocused(true)}
              onBlur={(e) => {
                setFocused(false);
                props.onBlur?.(e);
              }}
            />

            {renderSubfix()}
          </HFlex>
        </Absolute>
      </VFlex>
      {error && <Text t-12-r1>{errorText}</Text>}
    </VFlex>
  );
};

export default SubfixInput;
