import { useMemo } from "react";
import Toggle from "../../../components/Toggle";
import api from "../../../common/api";
import VFlex from "../../../layouts/VFlex";
import Text from "../../../layouts/Text";
import Divider from "../../../layouts/Divider";
import { usePartner } from "../../../providers/partner";
import { useNotifications } from "../../../providers/notifications";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";

function GroupNotificationSettings() {
  const { trackClickNotificationTalkSettings } = useAmplitudeTrack();
  const { partner } = usePartner();
  const { groupNotificationSettings, fetchGroupNotificationSettings } =
    useNotifications();
  const settings = useMemo(
    () => groupNotificationSettings.filter((i) => i.type === "ALIMTALK"),
    [groupNotificationSettings]
  );

  return (
    <VFlex f-1 as-st p-24-t p-32-rl g-8>
      <Text t-18-700>알림톡 발송 설정</Text>
      <Text t-14-400-s4>
        발송 설정에 따라 예약 상태별 고객에게 자동으로 알림톡 발송을 할 수
        있습니다.
      </Text>
      <VFlex bdr-8 m-24-t bd-t3 style={{ maxWidth: 800 }}>
        {settings.map((setting, i) => (
          <VFlex key={i} height={74}>
            <Toggle
              value={setting.enabled}
              label={setting.notifTemplateType.title}
              subTitle={setting.notifTemplateType.description}
              onChange={async (value) => {
                await api.put(
                  `/rest/notification/config/${setting.notifTemplateType.id}?groupId=${partner.id}&status=${value}`
                );
                fetchGroupNotificationSettings();
                trackClickNotificationTalkSettings(
                  setting.notifTemplateType.title,
                  value,
                  partner.title
                );
              }}
            />
            {i < settings.length - 1 && <Divider height={0.1} />}
          </VFlex>
        ))}
      </VFlex>
    </VFlex>
  );
}

export default GroupNotificationSettings;
