import * as amplitude from "@amplitude/analytics-browser";
import { AmplitudeEvent } from "./useAmplitudeEvents";

import { GuardianForm } from "../providers/customerForm";
import { Service } from "../providers/types";

export const useAmplitudeTrack = () => {
  const identifyObj = new amplitude.Identify();
  const setIdentifyObj = (key: string, value: any) => {
    identifyObj.set(key, value);
    amplitude.identify(identifyObj);
  };

  const trackEvent = (event: AmplitudeEvent, property?: {}) => {
    amplitude.track(event, property);
  };

  const events = {
    setIdentifyCellnumber: (cellnumber: string) =>
      setIdentifyObj("cellnumber", cellnumber),
    setIdentifyGropId: (groupId: number) => setIdentifyObj("groupId", groupId),
    setIdentifyTitle: (title: string) => setIdentifyObj("title", title),
    // land
    trackLandMain: () => trackEvent("land_main"),
    trackLandNotification: () => trackEvent("land_notification"),
    trackLandInquiryList: () => trackEvent("land_inquiry_list"),
    trackLandInquiryReservation: () => trackEvent("land_inquiry_reservation"),
    trackLandReservationDetail: (type?: string) =>
      trackEvent("land_reservation_detail", { type }),
    trackLandSales: (title: string) => trackEvent("land_sales", { title }),
    trackLandCustomer: () => trackEvent("land_customer"),
    trackLandServiceMenu: () => trackEvent("land_service_menu"),
    trackLandSettings: () => trackEvent("land_settings"),
    trackLandBizInfo: () => trackEvent("land_biz_info"),
    trackLandPolicyManage: () => trackEvent("land_policy_manage"),
    trackLandNotificationSetting: () => trackEvent("land_notification_setting"),
    trackLandBizHours: () => trackEvent("land_biz_hours"),
    trackLandHolidays: () => trackEvent("land_holidays"),
    trackLandStaffs: () => trackEvent("land_staffs"),
    trackLandNotificationTalk: () => trackEvent("land_notification_talk"),
    trackLandServiceNote: () => trackEvent("land_service_note_list"),
    trackLandInquirySettings: () => trackEvent("land_inquiry_settings"),
    trackLandSafequestionaire: () => trackEvent("land_safequestionaire"),
    trackLandHiddenRes: () => trackEvent("land_hidden_res"),
    trackLandDownloadPPA: () => trackEvent("land_download_ppa"),
    trackLandDeletedResList: () => trackEvent("land_deleted_res_list"),
    trackLandDeletedCustomerList: () =>
      trackEvent("land_deleted_customer_list"),

    // click
    trackClickNewReservation: () => trackEvent("click_new_reservation"),
    trackClickAddReservationSchedule: () =>
      trackEvent("click_add_reservation_schedule"),
    trackClickAddPersonalSchedule: () =>
      trackEvent("click_add_personal_schedule"),
    trackClickSaveReservationSchedule: (property: {
      date: string;
      startTime: string;
      endTime: string;
      petId: number;
      productIdList?: number[];
      products?: Service[];
      userIdList: number[] | undefined;
      guardianId: number;
      note?: string;
    }) => trackEvent("click_save_reservation_schedule", property),
    trackEditReservationSchedule: (property: {
      reservationId: number;
      date: string;
      startTime: string;
      endTime: string;
      petId: number;
      productIdList?: number[];
      products?: Service[];
      userIdList: number[] | undefined;
      guardianId: number;
      note?: string;
    }) => trackEvent("edit_reservation_schedule", property),
    trackClickSavePersonalSchedule: () =>
      trackEvent("click_save_personal_schedule"),
    trackClickInquiryList: () => trackEvent("click_inquiry_list"),
    trackClickInquiryDetail: (location?: string) =>
      trackEvent("click_inquiry_detail", { location }),
    trackClickSaveInquiryReseravtion: (property: {
      inquiryId: number;
      date: string;
      startTime: string;
      endTime: string;
      petId: number;
      productIdList: number[];
      userIdList: number[] | undefined;
      guardianId: number;
      note?: string;
    }) => trackEvent("click_save_inquiry_reservation", property),
    trackClickAddServiceNote: () => trackEvent("click_add_service_note"),
    trackClickSaveServiceNote: () => trackEvent("click_save_service_note"),
    trackClickSalesCalendarType: (type: string) =>
      trackEvent("click_sales_calendar_type", { type }),
    trackClickCalendarTimeSetting: (title: string) =>
      trackEvent("click_calendar_time_setting", { title }),
    trackClickSidebarBanner: (title: string, url: string) =>
      trackEvent("click_sidebar_banner", { title, url }),
    trackClickUpgradePlusPlan: () => trackEvent("click_upgrade_plus_plan"),
    trackDeleteCustomers: (petIds: number[]) =>
      trackEvent("delete_customers", { petIds }),
    trackRestoreCustomers: (petIds: number[]) =>
      trackEvent("restore_customers", { petIds }),
    // 설정
    trackClickSettingsMenu: (menu: string, title: string) =>
      trackEvent("click_settings_menu", { menu, title }),
    trackClickBizProfile: (title: string) =>
      trackEvent("click_biz_profile", { title }),
    trackSaveBizInfo: (title: string) => trackEvent("save_biz_info", { title }),
    trackClickPolicyTab: (tab: string, title: string) =>
      trackEvent("click_policy_tab", { tab, title }),
    trackSaveNotesAfterService: (title: string) =>
      trackEvent("save_notes_after_service", { title }),
    trackSaveResInfo: (title: string) => trackEvent("save_res_info", { title }),
    trackClickMileageSettings: (value: boolean, title: string) =>
      trackEvent("click_mileage_settings", { value, title }),
    trackClickMileageDeduction: (value: boolean, title: string) =>
      trackEvent("click_mileage_deduction", { value, title }),
    trackSaveMileageSettings: (title: string) =>
      trackEvent("save_mileage_settings", { title }),
    trackSaveBizHours: (title: string) =>
      trackEvent("save_biz_hours", { title }),
    trackClickHolidaysTab: (tab: string, title: string) =>
      trackEvent("click_holidays_tab", { tab, title }),
    trackAddRegularHolidays: (title: string) =>
      trackEvent("add_regular_holidays", { title }),
    trackSaveRegularHolidays: (title: string) =>
      trackEvent("save_regular_holidays", { title }),
    trackDeleteRegularHolidays: (title: string) =>
      trackEvent("delete_regular_holidays", { title }),
    trackAddTemporaryHolidays: (title: string) =>
      trackEvent("add_temporary_holidays", { title }),
    trackSaveTemporaryHolidays: (title: string) =>
      trackEvent("save_temporary_holidays", { title }),
    trackDeleteTemporaryHolidays: (title: string) =>
      trackEvent("delete_temporary_holidays", { title }),
    trackAddStaff: (title: string) => trackEvent("add_staff", { title }),
    trackSaveStaff: (title: string) => trackEvent("save_staff", { title }),
    trackSaveNewStaff: (title: string) =>
      trackEvent("save_new_staff", { title }),
    trackClickDeleteStaff: (title: string) =>
      trackEvent("click_delete_staff", { title }),
    trackDeleteStaff: (title: string) => trackEvent("delete_staff", { title }),
    trackClickNotificationTalkStatus: (type: string, title: string) =>
      trackEvent("click_notification_talk_type", { type, title }),
    trackClickNotificationTalkSettings: (
      type: string,
      value: boolean,
      title: string
    ) => trackEvent("click_notification_talk_settings", { type, value, title }),
    trackClickInquiryResMore: (title: string) =>
      trackEvent("click_inquiry_res_more", { title }),
    trackClickInquirySettings: (
      type: string,
      value: boolean | string,
      title: string
    ) => trackEvent("click_inquiry_settings", { type, value, title }),
    trackClickSafequestionaireMore: (title: string) =>
      trackEvent("click_safequestionaire_more", { title }),
    trackClickSafequestionaire: (title: string) =>
      trackEvent("click_safequestionaire", { title }),
    trackClickHiddenResSearch: (title: string) =>
      trackEvent("click_hidden_res_search", { title }),
    trackClickRevertHiddenRes: (title: string) =>
      trackEvent("click_revert_hidden_res", { title }),
    trackRevertHiddenRes: (title: string) =>
      trackEvent("revert_hidden_res", { title }),
    // 서비스 메뉴
    trackClickServiceMenuTab: (tab: string) =>
      trackEvent("click_service_menu_tab", { tab }),
    trackClickServiceMenuSearch: () => trackEvent("click_service_menu_search"),
    trackAddServiceMenu: (type: string) =>
      trackEvent("add_service_menu", { type }),
    trackSaveNewServiceMenu: () => trackEvent("save_new_service_menu"),
    trackSaveServiceMenu: () => trackEvent("save_service_menu"),
    trackClickDeleteServiceMenu: (type?: string) =>
      trackEvent("click_delete_service_menu", { type }),
    trackDeleteServiceMenu: (type?: string) =>
      trackEvent("delete_service_menu", { type }),
    // 고객
    trackClickSearchCustomer: () => trackEvent("click_search_customer"),
    trackAddNewCustomer: () => trackEvent("add_new_customer"),
    trackSaveNewCustomer: (property: {
      profileImgFile?: File;
      name: string;
      species: string;
      sex?: number;
      neutralized?: number;
      birthday?: Date;
      age?: number;
      months?: number;
      weight?: string;
      allergy?: string;
      note?: string;
      guardians: GuardianForm[];
    }) => trackEvent("save_new_customer", property),
    trackSaveCustomer: (property: {
      profileImgFile?: File;
      name: string;
      species: string;
      sex?: number;
      neutralized?: number;
      birthday?: Date;
      age?: number;
      months?: number;
      weight?: string;
      allergy?: string;
      note?: string;
      guardians: GuardianForm[];
    }) => trackEvent("save_customer", property),
    trackClickCustomerDetail: (id: number, type?: string) =>
      trackEvent("click_customer_detail", { id, type }),
    trackClickCustomerDetailTab: (tab: string) =>
      trackEvent("click_customer_detail_tab", { tab }),
    trackAddGuardian: () => trackEvent("add_guardian"),
    trackClickDeleteGuardian: () => trackEvent("click_delete_guardian"),
    trackDeleteGuardian: () => trackEvent("delete_guardian"),
    trackAddPoint: () => trackEvent("add_point"),
    trackSavePoint: () => trackEvent("save_point"),
    trackClickMakeARes: () => trackEvent("click_make_a_res"),
    trackClickDeleteCustomer: () => trackEvent("click_delete_customer"),
    trackDeleteCustomer: (petId: number) =>
      trackEvent("delete_customer", { petId }),
    // 예약
    trackClickCalendarDisplay: (type: string) =>
      trackEvent("click_calendar_display", { type }),
    trackClickToday: () => trackEvent("click_today"),
    trackClickResSearch: () => trackEvent("click_res_search"),
    trackClickPanel: (value: string) => trackEvent("click_panel", { value }),
    trackClickPanelAppointmentsState: (state: string, value: boolean) =>
      trackEvent("click_panel_appointments_state", { state, value }),
    trackClickPanelAppointmentsStateSetting: () =>
      trackEvent("click_panel_appointments_state_setting"),
    trackClickPanelAppointmentsVisible: (value: boolean) =>
      trackEvent("click_panel_appointments_visible", { value }),
    trackClickPanelPersonalScheduleState: (state: string, value: boolean) =>
      trackEvent("click_panel_personal_schedule_state", { state, value }),
    trackClickPanelPersonalScheduleVisible: (value: boolean) =>
      trackEvent("click_panel_personal_schedule_visible", { value }),
    trackClickPanelStaffsVisible: (value: boolean) =>
      trackEvent("click_panel_staffs_visible", { value }),
    trackClickPanelCalendarSettings: () =>
      trackEvent("click_panel_calendar_settings"),
    trackClickUnassignedFeatures: () => trackEvent("click_unassigned_features"),
    // 로그인 회원가입
    trackClickLogin: (phone: string) => trackEvent("click_login", { phone }),
    trackClickLogout: (title: string) => trackEvent("click_logout", { title }),
    trackClickSingup: () => trackEvent("click_singup"),
    trackClickIdentityAuthentication: (phone: string) =>
      trackEvent("click_identity_authentication", { phone }),
    trackClickLoginProblem: () => trackEvent("click_login_problem"),
    trackClickForgotPassword: () => trackEvent("click_forgot_password"),
    trackClickChangeCellnumber: () => trackEvent("click_change_cellnumber"),
    trackSetPassword: () => trackEvent("set_password"),
    trackSavePassword: () => trackEvent("save_password"),
    trackSetBizLicense: () => trackEvent("set_biz_license"),
    trackSetBizSector: () => trackEvent("set_biz_sector"),
    // 예약 일정 상세, 예약 메모
    trackClickResMemoTab: () => trackEvent("click_res_memo_tab"),
    trackEditResDetailResMemo: () => trackEvent("edit_res_detail_res_memo"),
    trackClickResDetailStyleMemo: () =>
      trackEvent("click_res_detail_style_memo"),
    trackClickResDetailConditionMemo: () =>
      trackEvent("click_res_detail_condition_memo"),
    trackClickResDetailResMemo: () => trackEvent("click_res_detail_res_memo"),
    trackClickResDetailResMemoImg: () =>
      trackEvent("click_res_detail_res_memo_img"),
    trackClickDownloadPPA: () => trackEvent("click_download_ppa"),
    trackRestoreRes: (type: string) => trackEvent("restore_res", { type }),
    trackRestoreCustomer: (petId: number) =>
      trackEvent("restore_customer", { petId }),
    trackClickTagDetail: (type?: string) =>
      trackEvent("click_tag_detail", { type }),
    trackClickTagSetting: (type?: string) =>
      trackEvent("click_tag_setting", { type }),
    trackSaveTag: () => trackEvent("save_tag"),
  };

  return events;
};
