import { Children, PropsWithChildren } from "react";

import HFlex from "./HFlex";
import VFlex from "./VFlex";
import Text from "./Text";

import "./Notification.scss";

interface NotificationProps extends PropsWithChildren {
  type: "container" | "full";
  color: "red" | "blue" | "err";
  // TODO line option
}

const Notification = ({ color, type, children }: NotificationProps) => {
  return (
    <VFlex classes={["notification", color, type]} f-1>
      {children}
    </VFlex>
  );
};

const Title = ({ color, children }: PropsWithChildren<{ color?: boolean }>) => {
  return (
    <Text t-14-600-s8={!color} t-14-600-w={color}>
      {children}
    </Text>
  );
};
const Description = ({ children }: PropsWithChildren) => {
  return <Text t-14-500-s7>{children}</Text>;
};
const LinkButton = ({ children, url }: PropsWithChildren<{ url: string }>) => {
  return (
    <Text
      classes={["link"]}
      t-14-500
      clickable
      onClick={() => {
        window.open(url);
      }}
    >
      {children}
    </Text>
  );
};

Notification.Title = Title;
Notification.Description = Description;
Notification.Link = LinkButton;
export default Notification;
