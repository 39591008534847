import { useCallback, useState } from "react";
import { PopupAnchor } from "../../../App";
import AddTemporaryHolidays from "./AddTemporaryHolidays";
import { differenceInDays } from "date-fns";
import api from "../../../common/api";
import Spinner from "../../../common/Spinner/Spinner";
import { days } from "../../../common/constants";
import { PopupKind } from "../../../popup/Popup";
import Image from "../../../layouts/Image";
import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Button from "../../../layouts/Button";
import { pushPopup } from "../../../providers/popups";
import Text from "../../../layouts/Text";
import { ContextMenu } from "../../../popup/ContextMenu";
import { usePartner } from "../../../providers/partner";
import { useBusinessHours } from "../../../providers/businessHours";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";

function TemporaryHolidays() {
  const { trackAddTemporaryHolidays, trackDeleteTemporaryHolidays } =
    useAmplitudeTrack();

  const { partner } = usePartner();
  const { temporaryHolidays, fetchTemporaryHolidays } = useBusinessHours();
  const [loading, setLoading] = useState<boolean>(false);
  const deleteTemporaryHoliday = useCallback(
    async (holidayId: number) => {
      await api.delete(`/rest/group/${partner.id}/temporary-holiday`, {
        data: [holidayId],
      });
      setLoading(true);
      await fetchTemporaryHolidays();
      setLoading(false);
    },
    [partner, fetchTemporaryHolidays]
  );
  return (
    <VFlex p-32 as-st g-16 rel>
      <VFlex bdr-16 bd-t3 style={{ minHeight: 330 }}>
        <HFlex a-c bc-t1 p-16-l p-8-r bd-b-t3 height={56}>
          <Text f-1 t-16-600-s9>
            업체 임시 휴무일
          </Text>
          <Button
            type="primary"
            onClick={() => {
              pushPopup({
                kind: PopupKind.Drawer,
                element: AddTemporaryHolidays,
              });
              trackAddTemporaryHolidays(partner.title);
            }}
          >
            <HFlex g-8 a-c>
              <Image size={18} src={`/icons/icon_add.png`} />
              <Text t-12-800-w>임시 휴무일 추가</Text>
            </HFlex>
          </Button>
        </HFlex>
        {loading ? (
          <HFlex c-c>
            <Spinner />
          </HFlex>
        ) : temporaryHolidays.length === 0 ? (
          <VFlex f-1 c-c g-8>
            <Image size={20} src={`/icons/icon_info_gray.png`} />
            <Text t-16-s4>설정된 임시 휴무일이 없습니다.</Text>
          </VFlex>
        ) : (
          <VFlex f-1 p-8 ovf-a g-8>
            {temporaryHolidays.map((holiday, i) => (
              <HFlex
                key={i}
                height={54}
                bd-b-t3={i !== temporaryHolidays.length - 1}
                a-c
                p-24-rl
                g-16
                classes={["table-row"]}
              >
                <Text t-14-700-s6>임시 휴무일 {i + 1}</Text>
                <Text t-14-500-s4>|</Text>
                <Text f-1 t-12-500-s4>
                  {`${holiday.startDate.getFullYear()}. ${(
                    "0" +
                    (holiday.startDate.getMonth() + 1)
                  ).slice(-2)}. ${("0" + holiday.startDate.getDate()).slice(
                    -2
                  )} (${days[holiday.startDate.getDay()]})${
                    holiday.startDate.getFullYear() ===
                      holiday.endDate.getFullYear() &&
                    holiday.startDate.getMonth() ===
                      holiday.endDate.getMonth() &&
                    holiday.startDate.getDate() === holiday.endDate.getDate()
                      ? ""
                      : ` ~ ${
                          holiday.startDate.getFullYear() !==
                          holiday.endDate.getFullYear()
                            ? `${holiday.endDate.getFullYear()}. `
                            : ""
                        }${("0" + (holiday.endDate.getMonth() + 1)).slice(
                          -2
                        )}. ${("0" + holiday.endDate.getDate()).slice(-2)} (${
                          days[holiday.endDate.getDay()]
                        })`
                  }`}
                </Text>
                <VFlex bc-bl2 bdr-8 p-16-rl c-c height={24}>
                  <Text t-12-500-w>
                    {differenceInDays(holiday.endDate, holiday.startDate) + 1}일
                  </Text>
                </VFlex>
                <PopupAnchor
                  popup={{
                    kind: PopupKind.Popup,
                    element: ContextMenu,
                    width: 212,
                    props: {
                      menus: [
                        {
                          icon: `/icons/icon_delete.png`,
                          isErrorText: true,
                          title: "임시휴무 취소하기",
                          confirmButtonTitle: "정말 취소할까요?",
                          confirmButtonLabel: "임시휴무 취소하기",
                          confirmButtonType: "warning",
                          onConfirm: () => {
                            deleteTemporaryHoliday(holiday.id);
                            trackDeleteTemporaryHolidays(partner.title);
                          },
                        },
                      ],
                    },
                    options: {
                      innerClassName: "Popup-Cancel",
                      backdropColor: "transparent",
                    },
                    offsetX: 180,
                    offsetY: -8,
                  }}
                >
                  <Image size={24} src={`/icons/icon_more.png`} />
                </PopupAnchor>
              </HFlex>
            ))}
          </VFlex>
        )}
      </VFlex>
    </VFlex>
  );
}

export default TemporaryHolidays;
