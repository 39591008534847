export type BusinessHour = {
  dayOfWeek: string;
  start: string;
  end: string;
};

export enum RepeatType {
  ONCE,
  EVERY_WEEK,
  FIRST_WEEK_OF_MONTH,
  SECOND_WEEK_OF_MONTH,
  THIRD_WEEK_OF_MONTH,
  FOURTH_WEEK_OF_MONTH,
  FIFTH_WEEK_OF_MONTH,
  SIXTH_WEEK_OF_MONTH,
}

export type PublicHoliday = {};

export type BusinessHoliday = {
  id: number;
  repeatType: RepeatType;
  dayOfWeek: string;
};

export type TemporaryHoliday = {
  id: number;
  name: string;
  startDate: Date;
  endDate: Date;
};

export type Partner = {
  id: number;
  fullname: string;
  name: string;
  address: Address;
  attachment: Attachment;
  title: string;
  contact: string;
  store: Store;
  notice: string;
  warning: string;
  deposit: Deposit;
  fullyLoggedIn: boolean;
};

export type Address = {
  id: number;
  latitude: number;
  longitude: number;
  address: string;
};

export type Attachment = {
  id: number;
  path: string;
  size: string;
  childs?: [
    {
      id: number;
      path: string;
      size: string;
    }
  ];
};

export type Store = {
  id: number;
  path: string;
  size: string;
};

export type Deposit = {
  deposit: number;
  refund: number;
  used: boolean;
};

export type Staff = {
  id: number;
  userId: number;
  groupId: number;
  name: string;
  jobTitle: string;
  color: string;
  workingCellNumber: string;
  attachment?: Attachment;
  filterActived: boolean;
};

export type Product = {
  id: number;
  type: string;
  thumbnail: Attachment;
  attachments: Attachment[];
  groupId: number;
  description: string;
  menuType: {
    id: number;
    termId: number;
    name: string;
    taxonomy: string;
    parent: number;
  };
  serviceType: {
    id: number;
    termId: number;
    name: string;
    taxonomy: string;
    parent: number;
  };
  price: number;
  stockQuantity: number; // 재고 수량 추가
  stockManaged: boolean; // 재고 관리 여부
  duration: number;
  deposit: number;
  cancellationFee: number;
  autoConfirm: boolean;
  name: string;
};

export interface Service {
  product: Product;
  quantity: number;
}

export type ProductGroup = {
  menuType: string;
  serviceType: string;
  productList: Product[];
};

export interface Service {
  product: Product;
  quantity: number;
}

export type Taxonomy = {
  id: number;
  termId: number;
  name: string;
  taxonomy: string;
  parent: number;
};

export type Guardian = {
  id: number;
  name: string;
  nickname?: string;
  cellnumber: string;
  note?: string;
  noteUpdatedDate?: string;
  primaryGuardian?: boolean;
};

export interface ServiceSummaryItem {
  id: number;
  status: number;
  date: string;
  reservationDate: number;
  styleNotes: Note[];
  conditionNotes: Note[];
  productName: string;
  otherNotes: Note[];
}

export interface Tag {
  id?: number;
  title: string;
  icon?: string;
  disabledTitle?: string; // 제목에서 이모지 제거
  description?: string;
  displayColor?: string;
  textColor: string;
  backgroundColor: string;
  enabled?: boolean;
  edited?: boolean; // 수정 가능 여부
  added?: boolean; // 고객 추가 가능 여부
  type?: number;
}

export interface TagColor {
  textColor: string;
  bgColor: string;
  displayColor: string;
}

export type Pet = {
  id: number;
  name: string;
  age?: number;
  sex?: number;
  weight?: number;
  species: string;
  guardianList: Guardian[];
  tags?: Tag[];
  todayRegistered?: boolean;
  lastVisited?: number;
  note?: string;
  attachment?: Attachment;
  deleted?: number;
  deletedUserId?: number;
  deletedUserNickname?: string;
  deletedReason?: string;
  birthday?: string;
  created?: string;
  neturalized?: boolean;
};

export type Species = {
  id: number;
  name: string;
};

export type PetResponse = {
  deleted?: boolean;
  guardians: Guardian[];
  petInfo: PetInfo;
  tags: Tag[];
  attachment?: Attachment;
};

export type PetInfo = {
  age?: number;
  months?: number;
  name: string;
  neuteredStatus?: string | boolean;
  note?: string;
  sex?: number;
  species: Species & { speciesTypeId: number; speciesType: string };
  weight?: number;
  id?: number;
  allergy?: string;
  birthday?: string;
  attachment?: Attachment;
};

export type Reservation = {
  date: string;
  deposit: number;
  depositExpiredDate: string;
  depositExpiredTime: string;
  endTime: string;
  groupId: number;
  guardian: Guardian;
  id: number;
  pet: PetResponse;
  petId: number;
  productList: Product[];
  products: Service[];
  reservationNumber: string;
  staffs: Staff[];
  startTime: string;
  status: ScheduleStatus;
  hidden: boolean;
  memo: string;
  attachments: Attachment[];
  requestMemo?: string;
  inquiredAttachments?: Attachment[];
  unCompletedReservationOnboardings: [
    {
      type: string;
    }
  ];
  partnerDeleted: boolean;
  questionnaireId?: number;
};

export type Reservations = {
  id: number;
  reservationNumber: string;
  status: number;
  reservationDate: number;
  duration: string;
  reservationPeriod: string;
  pet: {
    id: number;
    name: string;
    species: {
      id: number;
      name: string;
      speciesTypeId: number;
      speciesType: string;
    };
    attachment: {
      id: number;
      path: string;
      size: string;
      childs: [
        {
          id: number;
          path: string;
          size: string;
        }
      ];
    };
  };
  reserver: {
    id: number;
    name: string;
    nickname: string;
    cellnumber: string;
  };
  products: [
    {
      id: number;
      price: number;
      quantity: number;
      name: string;
    }
  ];
  productName: string;
  chargerName: string;
  histories?: [
    {
      changedData: boolean;
      created: number;
      nickname: string;
      previousData: boolean;
      type: string;
      userId: number;
    }
  ];
};

export type Schedule = {
  id?: number;
  reservationPeriod?: string;
  reservationDate?: number;
  histories?: [
    {
      changedData: boolean;
      created: number;
      nickname: string;
      previousData: boolean;
      type: string;
      userId: number;
    }
  ];
  startDateTime: Date;
  endDateTime: Date;
  reservationId: number;
  status: number;
  petName: string;
  petId?: number;
  species: string;
  productList: string[];
  chargerIdList: number[];
  staffs: Staff[];
  note: string;
  backgroundColor: string;
  nickname?: string;
  cellNumber?: string;
  requestNote?: string;
  background?: string;
  mainColor?: string;
  type?: string;
  title?: string;
  memo?: string;
  incomingPlatform?: string;
};

export type PastSchedule = {
  date: string;
  id: number;
  productName: string;
  status: number;
  amount: number;
  note: string;
  reservationDate: number;
};

export enum ScheduleStatus {
  INQUIRY = 0,
  INQUIRY_CLOSE = 1,
  IN_PROGRESS = 2,
  CONFIRM = 3,
  COMPLETE = 4,
  USER_CANCEL = 5,
  PARTNER_CANCEL = 6,
  NO_SHOW = 7,
  USER_DELETE = 8,
  PARTNER_DELETE = 9,
  CONFIRM_VISIT = 10,
}

export type Review = {
  content: string;
  decoration: {
    attachment: string;
    color: {
      id: number;
      bottomBgColor: string;
      topBgColor: string;
    };
  };
  pet: {
    name: string;
  };
  tags: [
    {
      content: string;
      id: number;
    }
  ];
};

export type Notification = {
  content: string;
  iconType: string;
  id: number;
  reservationId: number;
  inquiredReservationId: number;
  label: string;
  pushData: { id: string; type: string };
  read: boolean;
  sendDate: number;
  reviewId?: number;
};

export type GroupNotification = {
  alimtalkSucceed: boolean;
  cellNumber: string;
  id: number;
  label: string;
  petName: string;
  reservationId: number;
  scheduled: boolean;
  sendDate: number;
  smsSucceed: boolean;
  species: Species;
  title: string;
};

export type GroupNotificationSetting = {
  enabled: boolean;
  groupId: number;
  id: number;
  type: string;
  notifTemplateType: {
    description: string;
    enabled: boolean;
    id: number;
    title: string;
    typeCode: number;
  };
};

export enum CalendarType {
  DAILY = "일",
  WEEKLY = "주",
  MONTHLY = "월",
  SCHEDULE = "스케줄",
  STAFF = "담당자",
}

export type Plan = {
  id: number;
  icon: string;
  title: string;
  type: number;
  status: number;
  enabled: boolean;
  hidden: boolean;
  sortOrder: number;
  childs: [
    {
      id: number;
      title: string;
      description?: string;
      type: number;
      status: number;
      parent: number;
      enabled: boolean;
      hidden: boolean;
      sortOrder: number;
      plans: [
        {
          id: number;
          title: string;
          value: string;
          valueType: string;
        }
      ];
    }
  ];
};

export type Preference = {
  sidebarCollapsed: boolean;
  setSidebarCollapsed: (collapsed: boolean) => void;
};

export type Panel = {
  panelCollapsed: boolean;
  setPanelCollapsed: (collapsed: boolean) => void;
};

export type ViewType = {
  viewType: string;
  setViewType: (type: string) => void;
};

export type Note = {
  type: {
    type: string;
    value: string;
  };
  value?: {
    type: string;
    value: string;
  };
  note?: string;
  lastModified: number;
  imageCount?: number;
  attachments?: Attachment[];
};

export type InquiryType = {
  id: number;
  inquiredDateTime: number;
  attachments?: Attachment[];
  pet: {
    attachments: Attachment[];
    guardians: Guardian[];
    id: number;
    name: string;
    neutralized: boolean;
    sex: number;
    species: Species;
  };
  products: [
    {
      id: number;
      termId: number;
      name: string;
      taxonomy: string;
    }
  ];
  requestNote: string;
  reservationDate: number;
  status: number;
  time: string[];
};

export type InquiryListType = {
  id: number;
  reservationDate: number;
  inquiredDateTime: number;
  requestNote: string;
  status: number;
  time: [string];
  products: [
    {
      id: number;
      termId: number;
      name: string;
      taxonomy: string;
    }
  ];
  pet: {
    petInfo: {
      id: number;
      name: string;
      species: {
        id: number;
        name: string;
      };
      sex: number;
      attachment: {
        id: number;
        path: string;
        size: string;
      };
    };
    deleted: boolean;
    guardians: [
      {
        id: number;
        name: string;
        cellnumber: string;
        note: string;
        noteUpdatedDate: string;
      }
    ];
  };
  guardian: Guardian;
};

export type InquiryDetailType = {
  id: number;
  inquiredDateTime: number;
  reservationDate: number;
  reservationNumber: string;
  requestNote: string;
  status: number;
  time: string[];
  products: [
    {
      id: number;
      termId: number;
      name: string;
      taxonomy: string;
    }
  ];
  pet: {
    petInfo: {
      id: number;
      name: string;
      species: {
        id: number;
        name: string;
        speciesTypeId: number;
        speciesType: string;
      };
      sex: number;
      neuteredStatus: string;
      weight: number;
      age: number;
      birthday: string;
      note?: string;
      attachment: Attachment;
      neutralized?: boolean;
    };
    guardians: [
      {
        id: number;
        name: string;
        nickname: string;
        cellnumber: string;
        note?: string;
        primaryGuardian: boolean;
      }
    ];
    tags: [
      {
        id?: number;
        title: string;
        disabledTitle?: string;
        description?: string;
        textColor: string;
        backgroundColor: string;
        type?: number;
        sortOrder?: number;
        criterionLabel?: string;
        criteria?: [
          {
            id: number;
            title: string;
            dataType: string;
            label: string;
            value: number;
          }
        ];
        edited?: boolean;
      }
    ];
  };
  guardian: {
    id: number;
    name: string;
    nickname: string;
    fullname: string;
    cellnumber: string;
    primaryGuardian: boolean;
  };
  groomingQuestionnaire?: QuestionHistoryDetailInterface[];
  attachments: Attachment[];
  unCompletedOnboardings: [
    {
      type: string;
    }
  ];
};

export interface QquestionnaireResultsInterface {
  queueId: number;
  contentId: number;
  templateId: number;
  questionId: number;
  note: string;
  title: string;
  created: number;
}

export interface QuestionHistoryDetailInterface {
  id: number;
  title: string;
  sortOrder: number;
  questionnaireResults: QquestionnaireResultsInterface[];
  required: boolean;
}

export type InquiryNotificationHistory = {
  id: number;
  reservationId: number;
  inquiredReservationId: number;
  sendDate: number;
  type: string;
  label: string;
  petName: string;
  guardianName: string;
  cellNumber: string;
  title: string;
  species: Species;
  smsSucceed: boolean;
  scheduled: boolean;
  alimtalkSucceed: boolean;
};

export interface QuestionHistoryInterface {
  id: number;
  groupId: number;
  objectId: number;
  status: string;
  registeredDate: number;
  actualSentDate: number;
  pet: PetInfo;
}

export interface FilteredNotificationsInterface {
  alimtalkSucceed: boolean;
  smsSucceed: boolean;
  scheduled: boolean;
  cellNumber: string;
  content: string;
  created: number;
  label: string;
  id: number;
  // TODO
  pet: PetInfo;
  reservationId: number;
  reservationNumber: string;
}

export interface NoticeInterface {
  attachments: Attachment[];
  beautyManner: string;
  created: number;
  grouipTitle: string;
  id: number;
  lastModified: number;
  notes: ConditionMemoInterface[];
  notice: string;
  pet: Pet;
  productName: string;
  reservationId: number;
  status: string;
  writtenReview: boolean;
}

export interface ConditionMemoInterface {
  note: string;
  type: {
    type: string;
    value: string;
  };
  value: {
    type: string;
    value: string;
  };
}

export interface MemoInterface {
  type?: string;
  condition?: string;
  note?: string;
}

interface TypeAndValue {
  type: string;
  value: string;
}

interface Notes {
  color: TypeAndValue;
  type: TypeAndValue;
  value: TypeAndValue;
  note: string;
}

export interface NoticeDetailInterface {
  attachments: Attachment[];
  beautyManner?: string;
  beautyMannerNotice?: string;
  created: number;
  id: number;
  lastModified: number;
  notes: Notes[];
  notice: string;
  pet: PetInfo;
  productName: string;
  reservationId: number;
  status: string;
}

export interface NoticeDetailPopupInterface {
  reservationNumber: string;
  created: number;
}

export type Memo<Type> = {
  attachments?: Attachment[];
  type: Type;
  value?: 0 | 1 | 2 | 3 | 4;
  displayOrder: number;
  note?: string;
};

export enum MemoType {
  REQUEST = "예약 요청",
  C_COND = "컨디션",
  C_PAW = "발패드",
  C_EAR = "귀",
  C_EYE = "눈",
  C_SKIN = "피부",
  C_BREAKOUT = "사마귀/딱지",
  S_FACE = "얼굴",
  S_EAR = "귀",
  S_BODY = "몸",
  S_LEG = "다리",
  S_TAIL = "꼬리",
  S_ETC = "기타",
}

export enum NoticeMemoType {
  N_B_MANNER = "미용 매너",
  C_COND = "컨디션",
  C_PAW = "발패드",
  C_EAR = "귀",
  C_EYE = "눈",
  C_SKIN = "피부",
  C_BREAKOUT = "사마귀/딱지",
  N_B_NOTICE = "전달사항",
}

export const noticeMemoTypeContent: Record<
  NoticeMemoType,
  Memo<NoticeMemoType>
> = {
  [NoticeMemoType.N_B_MANNER]: {
    type: NoticeMemoType.N_B_MANNER,
    displayOrder: 0,
  },
  [NoticeMemoType.C_COND]: {
    type: NoticeMemoType.C_COND,
    displayOrder: 1,
  },
  [NoticeMemoType.C_PAW]: {
    type: NoticeMemoType.C_PAW,
    displayOrder: 2,
  },
  [NoticeMemoType.C_EAR]: {
    type: NoticeMemoType.C_EAR,
    displayOrder: 3,
  },
  [NoticeMemoType.C_EYE]: {
    type: NoticeMemoType.C_EYE,
    displayOrder: 4,
  },
  [NoticeMemoType.C_SKIN]: {
    type: NoticeMemoType.C_SKIN,
    displayOrder: 5,
  },
  [NoticeMemoType.C_BREAKOUT]: {
    type: NoticeMemoType.C_BREAKOUT,
    displayOrder: 6,
  },
  [NoticeMemoType.N_B_NOTICE]: {
    type: NoticeMemoType.N_B_NOTICE,
    displayOrder: 7,
  },
};

export const MEMO_STATUS_VALUE = [
  "좋아요",
  "보통",
  "관찰이 필요해요",
  "없어요",
  "있어요",
  "힘들어 했어요",
];
export const MEMO_STATUS_VALUES = [
  "GOOD",
  "SOSO",
  "BAD",
  "NONE",
  "EXIST",
  "TIRED",
];

export interface Photo {
  id: number;
  path: string;
  size: string;
}

export interface Questionnare {
  activedTime: number;
  content: string;
  description: string;
  groupEnabled: boolean | null;
  id: number;
  sortOrder: number;
  title: string;
}

export interface QuestionnareTemplateItem {
  id: number;
  title: string;
  sortOrder: number;
  questionnaireQuestions: {
    id: number;
    type: string;
    sortOrder: number;
    title: string;
    nextQuestions: number;
    textArea: boolean;
  }[];
  required: boolean;
}

export interface ReservationStatus {
  title: string;
  value: string;
  showed: boolean;
  actived: boolean;
}

export interface Deleteds {
  attachment?: { path: string };
  deletedDate?: number;
  deletedStaffNickname?: string;
  petName: string;
  productName: string;
  reservationDate: string;
  reservationId: number;
  reservationTime: string;
  species: string;
}

export interface DetailsType {
  content: string;
  defaultValue: string;
  id: number;
  title: string;
  type: string;
  value: string;
  valueType: string;
}

export interface Count {
  date: number;
  reservationCount: number;
}

export interface Statistics {
  totalAmount: number;
  paymentMethod: [
    {
      type: string;
      value: string;
      color: string;
    }
  ];
  revenue: [
    {
      date: number;
      amount: number;
    }
  ];
  chargersRevenue: [
    {
      id: number;
      name: string;
      count: number;
      amount: number;
    }
  ];
  petsRevenue: [
    {
      id: number;
      name: string;
      amount: number;
    }
  ];
  createdDate: number;
}

export interface Histories {
  id: number;
  name: string;
  date: number;
  productName: string;
  productPrice: number;
  status: number;
  registeredRevenue: boolean;
}

export type CustomerListSortType =
  | "ID"
  | "CREATED"
  | "MODIFIED"
  | "PET_NAME"
  | "LAST_VISITED";

export interface ITag {
  id: number;
  title: string;
  disabledTitle: string;
  description: string;
  textColor: string;
  backgroundColor: string;
  type: number;
  enabled: boolean;
  sortOrder: number;
  userCount: number;
  criterionLabel: string;
  criteria: [
    {
      id: number;
      variableId: number;
      title: string;
      dataType: string;
      label: string;
      value: number;
    }
  ];
  edited: false;
}

export interface IColor {
  label: string;
  textColor: string;
  bgColor: string;
  displayColor: string;
}

export interface Tags {
  totalCount: number;
  data: ITag[];
  colors: IColor[];
}

export interface TagsDetail {
  id: number;
  title: string;
  description: string;
  textColor: string;
  backgroundColor: string;
  sortOrder: number;
  userCount: number;
  criteria: [
    {
      id: number;
      variableId: number;
      title: string;
      dataType?: string;
      label: string;
      startValue?: number;
      endValue?: number;
      value?: number;
      variables?: [
        {
          id: number;
          label: string;
          dataType: string;
        }
      ];
    }
  ];
  customerTags: [
    {
      id: number;
      title: string;
      disabledTitle: string;
      description: string;
      textColor: string;
      backgroundColor: string;
      type: number;
      enabled: boolean;
      sortOrder: number;
      criterionLabel: string;
      criteria: [
        {
          id: number;
          variableId: number;
          title: string;
          dataType: string;
          label: string;
          value?: number;
          startValue?: number;
          endValue?: number;
          variables?: [
            {
              id: number;
              label: string;
              dataType: string;
            }
          ];
        }
      ];
      edited: boolean;
    }
  ];
  edited: boolean;
}

export interface Filters<F> {
  id: number;
  title: string;
  type: string;
  category: string;
  format: string;
  data: F[];
}

export interface Sorts {
  title: string;
  category: string;
  activced: boolean;
  sortOrder: string;
}

export interface Filter {
  filters: Filters<SpeciesFilter | TagFilter>[];
  sorts: Sorts[];
}

export interface SpeciesFilter {
  id: number;
  name: string;
}

export interface TagFilter {
  id: number;
  name: string;
  color: {
    textColor: string;
    bgColor: string;
  };
}

export interface ReservationStatusFilter {
  id: number;
  name: string;
  checked: boolean;
}

export interface DepositDetails {
  id: number;
  title: string;
  rate: number;
  amount: number;
  noticeMessage: string;
  expiredTime: number;
  autoApply: boolean;
  type: string;
  category: string;
  enabled: boolean;
  refunds: [
    {
      period: number;
      cancelDate: string;
      rate: number;
      refundRate: string;
    }
  ];
  tags: [
    {
      title: string;
      textColor: string;
      backgroundColor: string;
    }
  ];
}
export interface DepositTemplateListItem {
  id: number;
  title: string;
  rate: number;
  amount: number;
  noticeMessage?: string;
  expiredTime: number;
  autoApply: boolean;
  // type: "DEFAULT_POLICY";
  // category: "BEAUTY";
  enabled: boolean;
  tags: Tag[];
}

export type LOCALSTORAGE_KEY =
  | "DEPOSIT-TEMPLATE_LIST-CHECKED_USER_GUIDE"
  | "CUSTOMER-CREATE_NEW_CUSTOMER-FILL_REQUIRED_INFO";

export interface ReservationSummary {
  lastVisited: number | null;
  firstVisited: number | null;
}
