import _, { isNil } from "lodash";
import { ReactElement, useEffect, useState } from "react";

import HFlex from "../../layouts/HFlex";
import VFlex from "../../layouts/VFlex";
import Text from "../../layouts/Text";
import Image from "../../layouts/Image";

import toast from "react-hot-toast";
import api from "../../common/api";
import { usePartner } from "../../providers/partner";
import Divider from "../../layouts/Divider";
import Absolute from "../../layouts/Absolute";
import {
  calculateBirthday,
  formatAgeAndMonths,
  formatPetBirthDay,
  getSex,
} from "../../common/utils/customers";
import { pushPopup } from "../../providers/popups";
import { PopupKind } from "../../popup/Popup";
import Button from "../../layouts/ButtonV2";
import Flex from "../../layouts/Flex";
import { format } from "date-fns";
import EditPetName from "./EditPetName";
import EditPetSpecies from "./EditPetSpecies";
import EditPetWeight from "./EditPetWeight";
import EditPetSex from "./EditPetSex";
import EditPetNeutralized from "./EditPetNeutralized";
import { NEUTRALIZED, NEUTRALIZED_VALUES } from "../../common/constants";
import EditPetBirth from "./EditPetBirth";
import EditPetAllergy from "./EditPetAllergy";
import Notification from "../../layouts/Notification";
import { PetInfo, ReservationSummary } from "../../providers/types";

const DefaultCell = () => {
  return (
    <Text f-1 t-14-500-s2>
      -
    </Text>
  );
};

const CustomerInfo = ({
  petId,
  petInfo,
  reservationSummary,
  isDeletedCustomer,
  updateCustomerDetail,
}: {
  petId: number;
  petInfo: PetInfo | null;
  reservationSummary: ReservationSummary | null;
  isDeletedCustomer: boolean;
  updateCustomerDetail: () => void;
}) => {
  const { partner } = usePartner();

  const fetchPutPetInfo = async (type: string, value: string | null) => {
    try {
      await api.put(
        `/rest/v2/pet/${petId}/single?groupId=${
          partner.id
        }&type=${type}&value=${(value && encodeURIComponent(value)) || null}`
      );
      await updateCustomerDetail();
      toast.success("고객 정보가 변경되었습니다");
    } catch (e) {
      toast.error("고객 정보 변경에 실패했습니다");
    }
  };
  return petInfo && reservationSummary ? (
    <>
      {isDeletedCustomer && (
        <VFlex>
          <Notification type={"full"} color="err">
            <HFlex a-c p-8-rl>
              <Image src="/icons/action/info_w.svg" size={22} m-12-r />
              <HFlex g-8>
                <Notification.Title color>
                  삭제된 고객입니다.
                </Notification.Title>
              </HFlex>
            </HFlex>
          </Notification>
        </VFlex>
      )}
      <VFlex p-24 ovf-a>
        <VFlex bc-tw p-16-tb p-24-rl bdr-16 sized>
          <HFlex p-16-tb>
            <Text width={110} m-32-r t-14-500-s4>
              반려동물명
            </Text>
            <HFlex
              f-1
              a-s
              clickable={!isDeletedCustomer}
              onClick={() => {
                if (!isDeletedCustomer)
                  pushPopup({
                    kind: PopupKind.Popup,
                    element: EditPetName,
                    width: 530,
                    props: {
                      value: petInfo.name,
                      fetch: (v: string | null) => fetchPutPetInfo("NAME", v),
                    },
                  });
              }}
            >
              {petInfo.name ? (
                <Text f-1 t-14-500-s8>
                  {petInfo.name}
                </Text>
              ) : (
                <DefaultCell />
              )}
              <Image src="/icons/chevron_right.svg" size={24} m-16-l />
            </HFlex>
          </HFlex>
          <Divider height={1} />
          <HFlex a-c p-16-tb>
            <Text width={110} m-32-r t-14-500-s4>
              {petInfo.species.speciesTypeId === 4 || petInfo.species.id === 4
                ? "견종"
                : "묘종"}
            </Text>
            <HFlex
              f-1
              clickable={!isDeletedCustomer}
              onClick={() => {
                if (!isDeletedCustomer)
                  pushPopup({
                    kind: PopupKind.Popup,
                    element: EditPetSpecies,
                    width: 530,
                    props: {
                      value: petInfo.species,
                      typeId:
                        petInfo.species.speciesTypeId || petInfo.species.id,
                      fetch: (v: string | null) =>
                        fetchPutPetInfo("SPECIES", v),
                    },
                  });
              }}
            >
              <Text f-1 t-14-500-s8>
                {petInfo.species.name}
              </Text>
              <Image
                src="/icons/chevron_right.svg"
                size={24}
                m-16-l
                clickable={!isDeletedCustomer}
              />
            </HFlex>
          </HFlex>
          <Divider height={1} />
          <HFlex a-c p-16-tb>
            <Text width={110} m-32-r t-14-500-s4>
              성별
            </Text>
            <HFlex
              f-1
              j-e
              clickable={!isDeletedCustomer}
              onClick={() => {
                if (!isDeletedCustomer)
                  pushPopup({
                    kind: PopupKind.Popup,
                    element: EditPetSex,
                    width: 530,
                    props: {
                      value: petInfo.sex,
                      fetch: (v: string | null) => fetchPutPetInfo("SEX", v),
                    },
                  });
              }}
            >
              {isNil(petInfo.sex) ? (
                <DefaultCell />
              ) : (
                <Text f-1 t-14-500-s8 clickable={!isDeletedCustomer}>
                  {getSex(petInfo.sex)}
                </Text>
              )}
              <Image
                src="/icons/chevron_right.svg"
                size={24}
                m-16-l
                clickable={!isDeletedCustomer}
              />
            </HFlex>
          </HFlex>
          <Divider height={1} />
          <HFlex a-c p-16-tb>
            <Text width={110} m-32-r t-14-500-s4>
              중성화
            </Text>
            <HFlex
              f-1
              j-e
              clickable={!isDeletedCustomer}
              onClick={() => {
                if (!isDeletedCustomer)
                  pushPopup({
                    kind: PopupKind.Popup,
                    element: EditPetNeutralized,
                    width: 530,
                    props: {
                      value: petInfo.neuteredStatus,
                      fetch: (v: string | null) =>
                        fetchPutPetInfo("NEUTRALIZED", v),
                    },
                  });
              }}
            >
              {petInfo.neuteredStatus ? (
                <Text f-1 t-14-500-s8 clickable={!isDeletedCustomer}>
                  {
                    NEUTRALIZED[
                      NEUTRALIZED_VALUES.findIndex(
                        (v) => petInfo.neuteredStatus === v
                      )
                    ]
                  }
                </Text>
              ) : (
                <DefaultCell />
              )}
              <Image
                src="/icons/chevron_right.svg"
                size={24}
                m-16-l
                clickable={!isDeletedCustomer}
              />
            </HFlex>
          </HFlex>

          <Divider height={1} />
          <HFlex p-16-tb>
            <Text width={110} m-32-r t-14-500-s4>
              생일 (나이)
            </Text>
            <HFlex
              f-1
              j-b
              a-s
              clickable={!isDeletedCustomer}
              onClick={() => {
                if (!isDeletedCustomer)
                  pushPopup({
                    kind: PopupKind.Popup,
                    element: EditPetBirth,
                    width: 530,
                    props: {
                      value: {
                        birthday: petInfo.birthday,
                        age: petInfo.age,
                        months: petInfo.months,
                      },
                      fetch: (v: {
                        birthday?: string;
                        age?: number;
                        months?: number;
                      }) => {
                        if (v.birthday) {
                          fetchPutPetInfo("BIRTHDAY", v.birthday);
                        } else if (!isNil(v.age) && !isNil(v.months)) {
                          fetchPutPetInfo("AGE_MONTH", `${v.age}_${v.months}`);
                        }
                      },
                    },
                  });
              }}
            >
              {isNil(petInfo.birthday) ? (
                <DefaultCell />
              ) : (
                <VFlex f-1 clickable={!isDeletedCustomer} g-4>
                  <Text t-14-500-s8>
                    {formatPetBirthDay(petInfo.birthday)}{" "}
                    {formatAgeAndMonths(petInfo.age, petInfo.months) !== "" &&
                      `(${formatAgeAndMonths(petInfo.age, petInfo.months)})`}
                  </Text>
                  <Text t-14-500-s8>{calculateBirthday(petInfo.birthday)}</Text>
                </VFlex>
              )}
              <Image
                src="/icons/chevron_right.svg"
                size={24}
                m-16-l
                clickable={!isDeletedCustomer}
              />
            </HFlex>
          </HFlex>

          <Divider height={1} />
          <HFlex a-c p-16-tb>
            <Text width={110} m-32-r t-14-500-s4>
              몸무게
            </Text>
            <HFlex
              f-1
              a-c
              j-e
              clickable={!isDeletedCustomer}
              onClick={() => {
                if (!isDeletedCustomer)
                  pushPopup({
                    kind: PopupKind.Popup,
                    element: EditPetWeight,
                    width: 530,
                    props: {
                      value: petInfo.weight,
                      fetch: (v: string | null) => fetchPutPetInfo("WEIGHT", v),
                    },
                  });
              }}
            >
              {isNil(petInfo.weight) ? (
                <DefaultCell />
              ) : (
                <Text f-1 t-14-500-s8>
                  {petInfo.weight}kg
                </Text>
              )}
              <Image
                src="/icons/chevron_right.svg"
                size={24}
                m-16-l
                clickable={!isDeletedCustomer}
              />
            </HFlex>
          </HFlex>

          <Divider height={1} />
          <HFlex a-s p-16-tb>
            <Text width={110} m-32-r t-14-500-s4>
              알레르기 정보
            </Text>

            <HFlex
              f-1
              j-e
              clickable={!isDeletedCustomer}
              onClick={() => {
                if (!isDeletedCustomer)
                  pushPopup({
                    kind: PopupKind.Popup,
                    element: EditPetAllergy,
                    width: 530,
                    props: {
                      value: petInfo.allergy,
                      fetch: (v: string | null) =>
                        fetchPutPetInfo("ALLERGY", v),
                    },
                  });
              }}
            >
              {isNil(petInfo.allergy) ? (
                <DefaultCell />
              ) : (
                <Text
                  f-1
                  t-14-500-s8
                  clickable={!isDeletedCustomer}
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {petInfo.allergy}
                </Text>
              )}
              <Image
                src="/icons/chevron_right.svg"
                size={24}
                m-16-l
                clickable={!isDeletedCustomer}
              />
            </HFlex>
          </HFlex>
          <Divider height={1} />
          <HFlex a-c p-16-tb>
            <Text width={110} m-32-r t-14-500-s4>
              최근 방문 날짜
            </Text>
            {isNil(reservationSummary.lastVisited) ? (
              <DefaultCell />
            ) : (
              <Text f-1 t-14-500-s8>
                {format(reservationSummary.lastVisited * 1000, "yyyy.MM.dd")}
              </Text>
            )}
          </HFlex>
          <Divider height={1} />
          <HFlex a-c p-16-tb>
            <Text width={110} m-32-r t-14-500-s4>
              최초 방문 날짜
            </Text>
            {isNil(reservationSummary.firstVisited) ? (
              <DefaultCell />
            ) : (
              <Text f-1 t-14-500-s8>
                {format(reservationSummary.firstVisited * 1000, "yyyy.MM.dd")}
              </Text>
            )}
          </HFlex>
        </VFlex>
      </VFlex>
    </>
  ) : (
    // TODO
    <Absolute>{/* <LoadingView loading={true} /> */}</Absolute>
  );
};

export default CustomerInfo;

const EditInfoPopup = ({ renderForm }: { renderForm: () => ReactElement }) => {
  return (
    <VFlex height={"85vh"} p-24 rel>
      <HFlex j-b p-24-b>
        <Text t-24-600-s8>정보 수정</Text>
        <Image src="/icons/icon_close.png" size={24} clickable />
      </HFlex>

      {renderForm()}

      <Flex f-1 />

      <Button type="filled" onClick={() => null}>
        저장
      </Button>
    </VFlex>
  );
};
