import { Dispatch, SetStateAction, useMemo } from "react";
import _ from "lodash";
import hangul from "hangul-js";

import api from "../../../common/api";
import { reg } from "../../../common/format";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";
import { MAIN_SERVICE_TYPE_ID } from "../../../common/constants";

import { Service } from "../../../providers/types";
import { pushPopup } from "../../../providers/popups";
import { useProductGroups } from "../../../providers/productGroups";
import { useTaxonomies } from "../../../providers/taxonomies";
import { PopupAlign, PopupKind } from "../../../popup/Popup";
import { ContextMenu } from "../../../popup/ContextMenu";

import Image from "../../../layouts/Image";
import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Text from "../../../layouts/Text";
import Flex from "../../../layouts/Flex";

import { PopupAnchor } from "../../../App";
import SearchInput from "../../../components/SearchInput";
import AddMenu from "./AddMenu";

function MenuList({
  groupFilter,
  selectionMode,
  selectedProducts,
  setSelectedProducts,
  searchText,
  setSearchText,
}: {
  groupFilter?: string;
  selectionMode?: boolean;
  selectedProducts?: Service[];
  setSelectedProducts?: Dispatch<SetStateAction<Service[]>>;
  searchText: string | undefined;
  setSearchText: Dispatch<SetStateAction<string | undefined>>;
}) {
  const {
    trackClickServiceMenuSearch,
    trackAddServiceMenu,
    trackDeleteServiceMenu,
  } = useAmplitudeTrack();
  const { productGroups, fetchProductGroups } = useProductGroups();
  const { taxonomies } = useTaxonomies();
  const menus = useMemo(
    () =>
      _.compact(
        _.flatten(
          taxonomies["INTANGIBLE_BEAUTY_MENU"]?.map((menuType) =>
            taxonomies[
              `INTANGIBLE_BEAUTY_SERVICE?objectId=${menuType.id}`
            ]?.map((subMenuType) => ({
              menuType: menuType.name,
              serviceType: subMenuType.name,
            }))
          )
        )
          .filter((group) => !groupFilter || group.menuType === groupFilter)
          .map(({ menuType, serviceType }) => {
            const group = productGroups.find(
              (group) =>
                group.menuType === menuType && group.serviceType === serviceType
            );
            if (group) {
              const productList = group.productList.filter(
                (product) =>
                  hangul.search(
                    `[${group.serviceType}] ${product.name}`,
                    searchText ?? ""
                  ) >= 0
              );
              if (!searchText || productList.length > 0) {
                return {
                  menuType,
                  serviceType,
                  productList,
                };
              }
            } else if (!searchText) {
              if (!selectionMode) {
                return {
                  menuType,
                  serviceType,
                  productList: [],
                };
              }
            }
            return undefined;
          })
      ),
    [searchText, groupFilter, productGroups, taxonomies, selectionMode]
  );

  const increaseQuantity = (service: Service) => {
    if (
      selectedProducts &&
      selectedProducts.find((p) => p.product.id === service.product.id)
    ) {
      setSelectedProducts?.((products) => {
        const newSelectedProduct = [...products];
        const index = newSelectedProduct.findIndex(
          (p) => p.product.id === service.product.id
        );

        newSelectedProduct[index] = {
          product: service.product,
          quantity: newSelectedProduct[index].quantity + 1,
        };

        return newSelectedProduct;
      });
    } else {
      setSelectedProducts?.((p) => [
        ...p,
        { product: service.product, quantity: 1 },
      ]);
    }
  };

  const decreaseQuantity = (service: Service) => {
    if (
      selectedProducts &&
      selectedProducts.find((p) => p.product.id === service.product.id)!
        .quantity > 1
    ) {
      setSelectedProducts?.((products) => {
        const newSelectedProduct = [...products];
        const index = newSelectedProduct.findIndex(
          (p) => p.product.id === service.product.id
        );

        newSelectedProduct[index] = {
          product: service.product,
          quantity: newSelectedProduct[index].quantity - 1,
        };

        return newSelectedProduct;
      });
    }
  };

  const deleteService = (service: Service) => {
    setSelectedProducts?.((products) =>
      products.filter((p) => p.product.id !== service.product.id)
    );
  };

  return (
    <VFlex ovf-h>
      <VFlex p-32-rl bd-b-t3>
        <SearchInput
          height={56}
          placeholder="메뉴 이름을 검색해보세요"
          value={searchText}
          onChangeValue={(value) => {
            setSearchText(reg(value));
          }}
          onClick={() => {
            trackClickServiceMenuSearch();
          }}
        />
      </VFlex>
      <VFlex f-1 g-16 ovf-a p-32-rl style={{ paddingBottom: 100 }}>
        {menus.length === 0 ? (
          <HFlex height={330} bdr-16 bd-t3 c-c m-16-tb>
            <VFlex a-c g-8>
              <Image size={24} src={`/icons/icon_info_gray.png`} />
              <Text t-16-500-s4>검색 결과가 없습니다.</Text>
            </VFlex>
          </HFlex>
        ) : (
          <VFlex>
            {menus.map((group, i) => (
              <VFlex key={i} m-16-t bdr-16 bd-t3>
                <HFlex a-c bc-t0 height={72} p-24-l p-16-r bd-b-t3>
                  <VFlex f-1 t-16-600-s9>
                    {`[${group.menuType}] ${group.serviceType}`}
                  </VFlex>
                  {!selectionMode && (
                    <Image
                      size={34}
                      src={`/icons/icon_menu_add.png`}
                      clickable
                      onClick={() => {
                        trackAddServiceMenu(
                          `[${group.menuType}] ${group.serviceType}`
                        );

                        pushPopup({
                          kind: PopupKind.Drawer,
                          element: AddMenu,
                          props: {
                            selectedMenuType: group.menuType,
                            selectedServiceType: group.serviceType,
                          },
                        });
                      }}
                    />
                  )}
                </HFlex>
                <VFlex p-8>
                  {group.productList.map((product, j) => (
                    <HFlex
                      a-c
                      bdr-16
                      p-24-rl
                      g-16
                      height={56}
                      bc-w
                      hover
                      clickable
                      key={j}
                      onClick={() => {
                        if (selectionMode) {
                          setSelectedProducts?.((selectedProducts) => {
                            if (
                              selectedProducts.find(
                                (p) => p.product.id === product.id
                              )
                            ) {
                              return [
                                ...selectedProducts.filter(
                                  (p) => p.product.id !== product.id
                                ),
                              ];
                            }

                            if (
                              product.menuType.id === MAIN_SERVICE_TYPE_ID &&
                              selectedProducts.find(
                                (p) =>
                                  p.product.menuType.id === MAIN_SERVICE_TYPE_ID
                              )
                            ) {
                              selectedProducts = selectedProducts.filter(
                                (p) =>
                                  p.product.menuType.id !== MAIN_SERVICE_TYPE_ID
                              );
                            }

                            return [
                              ...selectedProducts,
                              {
                                product,
                                quantity: 1,
                              },
                            ];
                          });
                        } else {
                          pushPopup({
                            kind: PopupKind.Drawer,
                            element: AddMenu,
                            props: { editingProduct: product },
                          });
                        }
                      }}
                    >
                      <Text
                        t-14-600-s4
                        l-1
                      >{`[${group.serviceType}] ${product.name}`}</Text>

                      <Text t-12-500-s4>|</Text>
                      <Text t-14-700-s9>
                        {product.price.toLocaleString()}원
                      </Text>
                      <Text t-12-500-s4>|</Text>
                      <Text t-12-400-s4 f-1>
                        {product.duration >= 60
                          ? `${Math.floor(product.duration / 60)}시간 ${
                              product.duration % 60 === 0
                                ? ""
                                : (product.duration % 60) + "분"
                            }`
                          : `${product.duration % 60}분`}
                      </Text>

                      {selectionMode ? (
                        selectedProducts
                          ?.map((service) => service.product.id)
                          ?.includes(product.id) &&
                        (product.menuType.id !== 198 ? (
                          <>
                            <HFlex
                              width={110}
                              p-4-tb
                              p-8-rl
                              bd-t5
                              bc-w
                              style={{
                                borderRadius: 99,
                              }}
                            >
                              <Flex
                                f-1
                                c-c
                                clickable
                                onClick={(e) => {
                                  e.stopPropagation();

                                  let res = selectedProducts.find(
                                    (s) => s.product.id === product.id
                                  );

                                  if (
                                    selectedProducts.find(
                                      (s) => s.product.id === product.id
                                    )?.quantity === 1
                                  ) {
                                    res !== undefined && deleteService(res);
                                  } else {
                                    res !== undefined && decreaseQuantity(res);
                                  }
                                }}
                              >
                                <Image
                                  src={`/icons/${
                                    selectedProducts.find(
                                      (s) => s.product.id === product.id
                                    )?.quantity === 1
                                      ? "content/delete"
                                      : "action/remove"
                                  }_s6.svg`}
                                  size={20}
                                />
                              </Flex>
                              <Flex f-1 c-c t-16-500-s8>
                                {
                                  selectedProducts.find(
                                    (selected) =>
                                      selected.product.id === product.id
                                  )?.quantity
                                }
                              </Flex>
                              <Flex
                                f-1
                                c-c
                                clickable
                                onClick={(e) => {
                                  e.stopPropagation();

                                  let res = selectedProducts.find(
                                    (s) => s.product.id === product.id
                                  );

                                  res !== undefined && increaseQuantity(res);
                                }}
                              >
                                <Image
                                  src={`/icons/content/add_s6.svg`}
                                  size={20}
                                />
                              </Flex>
                            </HFlex>
                            <Image size={24} src={`/icons/icon_success.png`} />
                          </>
                        ) : (
                          <Image size={24} src={`/icons/icon_success.png`} />
                        ))
                      ) : (
                        <PopupAnchor
                          popup={{
                            kind: PopupKind.Popup,
                            align: PopupAlign.TopCenter,
                            element: ContextMenu,
                            props: {
                              menus: [
                                {
                                  icon: `/icons/icon_delete.png`,
                                  isErrorText: true,
                                  title: "메뉴 삭제하기",
                                  confirmButtonTitle: "정말 삭제할까요?",
                                  confirmButtonLabel: "메뉴 삭제하기",
                                  confirmButtonType: "warning",
                                  onConfirm: async () => {
                                    await api.delete(
                                      `/rest/product/${product.id}`
                                    );
                                    fetchProductGroups();
                                    trackDeleteServiceMenu();
                                  },
                                },
                              ],
                            },
                            options: {
                              backdropColor: "transparent",
                            },
                            offsetX: -40,
                            offsetY: -16,
                          }}
                        >
                          <Image size={24} src={`/icons/icon_more.png`} />
                        </PopupAnchor>
                      )}
                    </HFlex>
                  ))}
                </VFlex>
              </VFlex>
            ))}
          </VFlex>
        )}
      </VFlex>
    </VFlex>
  );
}

export default MenuList;
