import { format, setMonth, setYear } from "date-fns";
import { ko } from "date-fns/locale";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import Divider from "../layouts/Divider";
import Dropdown from "../layouts/Dropdown";
import Flex from "../layouts/Flex";
import HFlex from "../layouts/HFlex";
import VFlex from "../layouts/VFlex";
import Text from "../layouts/Text";
import Image from "../layouts/Image";
import { isNil } from "lodash";

import "./DatePickerV2.scss";

const FOCUSED_DATE_TIME = ["START", "END"] as const;
const VIEW_TYPE = ["YEAR", "MONTH", "DAY"] as const;

interface DatePickerProps {
  trigger: React.ReactNode;
  startDate?: Date;
  setStartDate: (dt: Date) => void;
  endDate?: Date;
  setEndDate: (dt: Date) => void;
  initDate?: () => void;
  remove?: () => void;
}

const DatePicker = ({
  trigger,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  initDate,
  remove,
}: DatePickerProps) => {
  const [focused, setFocused] = useState<(typeof FOCUSED_DATE_TIME)[number]>(
    FOCUSED_DATE_TIME[0]
  );
  const [viewType, setViewType] = useState<(typeof VIEW_TYPE)[number]>(
    VIEW_TYPE[2]
  );
  const getNewDateTime = (dateTime: Date, newDateTime: Date) => {
    if (viewType === VIEW_TYPE[0]) {
      return setYear(dateTime, newDateTime.getFullYear());
    } else if (viewType === VIEW_TYPE[1]) {
      return setMonth(dateTime, newDateTime.getMonth());
    }
    return newDateTime;
  };

  return (
    <Dropdown
      position="left"
      trigger={trigger}
      renderOptions={({ close }) => (
        <VFlex classes={["date-time-picker"]}>
          {(initDate || remove) && (
            <HFlex j-b m-10-b>
              {initDate && (
                <HFlex
                  onClick={() => {
                    initDate();
                    setFocused(FOCUSED_DATE_TIME[0]);
                    setViewType(VIEW_TYPE[2]);
                  }}
                  g-4
                >
                  <Image src="/icons/refresh.svg" size={20} clickable />
                  <Text t-14-500-s6 clickable>
                    날짜 선택 초기화
                  </Text>
                </HFlex>
              )}
              {remove && (
                <Image
                  src="/icons/delete.svg"
                  size={20}
                  clickable
                  onClick={() => {
                    remove();
                    close();
                  }}
                />
              )}
            </HFlex>
          )}

          <HFlex height={38} bdr-8>
            <HFlex
              onClick={() => {
                setViewType(VIEW_TYPE[2]);
                setFocused(FOCUSED_DATE_TIME[0]);
              }}
              clickable
              f-1
              p-12-rl
              a-c
              bd-t5={focused !== FOCUSED_DATE_TIME[0]}
              bd-g1={focused === FOCUSED_DATE_TIME[0]}
              style={{
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px",
                borderRight: "none",
                background:
                  focused === FOCUSED_DATE_TIME[0]
                    ? "rgba(39, 189, 99, 0.04)"
                    : "#F8F9F8",
              }}
            >
              <Text t-14-500-s8>
                {format(startDate ?? new Date(), "yyyy. M. d")}
              </Text>
            </HFlex>
            <Divider width={1} bc-g1 />
            <HFlex
              onClick={() => {
                setViewType(VIEW_TYPE[2]);
                setFocused(FOCUSED_DATE_TIME[1]);
              }}
              clickable
              f-1
              p-12-rl
              a-c
              bd-t5={focused !== FOCUSED_DATE_TIME[1]}
              bd-g1={focused === FOCUSED_DATE_TIME[1]}
              style={{
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
                borderLeft: "none",
                background:
                  focused === FOCUSED_DATE_TIME[1]
                    ? "rgba(39, 189, 99, 0.04)"
                    : "#F8F9F8",
              }}
            >
              <Text t-14-500-s8>
                {format(endDate ?? new Date(), "yyyy. M. d")}
              </Text>
            </HFlex>
          </HFlex>

          <ReactDatePicker
            selected={
              (focused === FOCUSED_DATE_TIME[0] ? startDate : endDate) ??
              new Date()
            }
            open
            onChange={(date, e) => {
              if (focused === FOCUSED_DATE_TIME[0]) {
                const newDate = getNewDateTime(startDate ?? new Date(), date!);

                setStartDate(newDate);
                if (isNil(endDate) || endDate.getTime() < newDate.getTime())
                  setEndDate(newDate);
              } else {
                const newDate = getNewDateTime(endDate!, date!);

                setEndDate(newDate);
                if (isNil(startDate) || newDate.getTime() < startDate.getTime())
                  setStartDate(newDate);
              }

              if (
                focused === FOCUSED_DATE_TIME[0] &&
                viewType === VIEW_TYPE[2]
              ) {
                setFocused(FOCUSED_DATE_TIME[1]);
              } else if (
                VIEW_TYPE.findIndex((t) => t === viewType) + 1 !==
                VIEW_TYPE.length
              ) {
                setViewType(
                  VIEW_TYPE[VIEW_TYPE.findIndex((t) => t === viewType) + 1]
                );
              }

              e?.stopPropagation();
            }}
            openToDate={focused === FOCUSED_DATE_TIME[0] ? startDate : endDate}
            shouldCloseOnSelect={false}
            renderCustomHeader={({ decreaseMonth, increaseMonth, date }) => (
              <HFlex p-12-t p-20-b bd-b-t2 m-8-b>
                <HFlex
                  c-c
                  m-8-r
                  clickable
                  onClick={(e) => {
                    setViewType(VIEW_TYPE[0]);
                    e.stopPropagation();
                  }}
                >
                  <Text t-16-600-s8 m-12-l>
                    {date.getFullYear()}년
                  </Text>
                  <Image src="/icons/chevron_down.png" size={24} />
                </HFlex>
                <HFlex
                  c-c
                  clickable
                  onClick={(e) => {
                    setViewType(VIEW_TYPE[1]);
                    e.stopPropagation();
                  }}
                >
                  <Text t-16-600-s8>{date.getMonth() + 1}월</Text>
                  <Image src="/icons/chevron_down.png" size={24} />
                </HFlex>
                <Flex f-1 />
                {viewType === VIEW_TYPE[2] && (
                  <>
                    <Flex width={48} c-c>
                      <Image
                        src="/icons/icon_chevron_left_s6.svg"
                        size={24}
                        clickable
                        onClick={() => {
                          decreaseMonth();
                        }}
                      />
                    </Flex>
                    <Flex width={48} c-c>
                      <Image
                        src="/icons/icon_chevron_right_s6.svg"
                        size={24}
                        clickable
                        onClick={() => {
                          increaseMonth();
                        }}
                      />
                    </Flex>
                  </>
                )}
              </HFlex>
            )}
            renderDayContents={(dayOfMonth, date) => {
              return (
                <VFlex f-1>
                  <Flex classes={["day-container"]}>{dayOfMonth}</Flex>
                </VFlex>
              );
            }}
            showYearPicker={viewType === VIEW_TYPE[0]}
            showMonthYearPicker={viewType === VIEW_TYPE[1]}
            locale={ko}
          />
        </VFlex>
      )}
    />
  );
};

export default DatePicker;
