import { useState } from "react";
import { isNil } from "lodash";

import HFlex from "../../layouts/HFlex";
import VFlex from "../../layouts/VFlex";
import Text from "../../layouts/Text";
import Image from "../../layouts/Image";
import Flex from "../../layouts/Flex";
import Button from "../../layouts/Button";
import RadioGroup from "../../layouts/RadioGroup";
import FilterChip from "../../layouts/FilterChip";

const SEX_OPTIONS = ["남아", "여아"];

const EditPetSex = ({
  popPopup,
  value,
  fetch,
}: {
  popPopup: () => void;
  value: number | undefined;
  fetch: (v: string | null) => void;
}) => {
  const [sex, setSex] = useState(value);

  return (
    <VFlex p-24>
      <HFlex j-b a-c m-24-b>
        <Text t-24-600-s8>고객 정보 수정</Text>
        <Image
          src="/icons/icon_close_s6.svg"
          size={24}
          clickable
          onClick={popPopup}
        />
      </HFlex>
      <RadioGroup
        caption="성별"
        value={
          !isNil(sex) ? { label: SEX_OPTIONS[sex], value: sex } : undefined
        }
        onChange={(sex) => {
          setSex(sex?.value!);
        }}
        options={SEX_OPTIONS.map((s, i) => ({ label: s, value: i }))}
        renderOption={(t, handleChange) => (
          <Flex f-1>
            <FilterChip
              type={sex === t.value ? "selected" : undefined}
              onClick={() => handleChange(t)}
              size="L"
            >
              {SEX_OPTIONS[t.value]}
            </FilterChip>
          </Flex>
        )}
      />

      <Button
        m-24-t
        bdr-12
        height={56}
        enabled={sex !== value}
        onClick={() => {
          fetch(isNil(sex) ? null : sex.toString());
          popPopup();
        }}
      >
        저장
      </Button>
    </VFlex>
  );
};

export default EditPetSex;
