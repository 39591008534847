import { ReactElement, useEffect, useMemo, useState } from "react";

import Toggle from "../../../components/Toggle";
import api from "../../../common/api";
import VFlex from "../../../layouts/VFlex";
import Text from "../../../layouts/Text";
import HFlex from "../../../layouts/HFlex";
import Image from "../../../layouts/Image";
import Divider from "../../../layouts/Divider";

import { usePartner } from "../../../providers/partner";
import { SettingsTitle } from ".";
import _ from "lodash";
import TabBar, { TabItemType } from "../../../components/TabBar";
import React from "react";
import Button from "../../../layouts/Button";
import { useQuestionnaires } from "../../../providers/questionnaires";
import { pushPopup } from "../../../providers/popups";
import { PopupKind } from "../../../popup/Popup";
import AddQuestionnaireForm from "./AddQuestionnaireForm";
import Flex from "../../../layouts/Flex";
import { format } from "date-fns";
import { ko } from "date-fns/locale";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";

function QuestionnaireSetting({
  setSelectedSubpage,
  initialTabIndex,
}: {
  setSelectedSubpage: (component?: ReactElement) => void;
  initialTabIndex: number;
}) {
  const { trackLandSafequestionaire } = useAmplitudeTrack();

  useEffect(() => {
    trackLandSafequestionaire();
  }, []);

  const tabs = useMemo(
    () => [
      {
        key: "1",
        name: "사용중인 문진표",
        component: QuestionnairesList,
      },
    ],
    []
  );
  const [selectedTab, setSelectedTab] = useState<TabItemType>(
    tabs[initialTabIndex]
  );
  const { fetchQuestionnaireTemplate, questionnaires } = useQuestionnaires();
  const isUsing = useMemo(() => {
    return (async () => {
      const isUsing = await api.get<boolean>(
        `/rest/user/onboarding?type=QUESTIONNAIRE`
      );
      return isUsing;
    })();
  }, [questionnaires]);

  useEffect(() => {
    return () => {
      setSelectedSubpage(undefined);
    };
  }, [setSelectedSubpage]);

  return (
    <VFlex a-st>
      <HFlex a-c j-b>
        <SettingsTitle
          title="안전 문진표"
          setSelectedSubpage={setSelectedSubpage}
          border={false}
        />
        {!isUsing && (
          <Button
            m-32-r
            g-4
            c-c
            type="add"
            onClick={() =>
              (async () => {
                const questionnaireTemplate = await fetchQuestionnaireTemplate(
                  1
                );

                pushPopup({
                  kind: PopupKind.Drawer,
                  element: AddQuestionnaireForm,
                  props: {
                    //  template data
                    questionnaireTemplate,
                    templateTitle: "미용서비스 안전 문진표",
                  },
                  width: 600,
                });
              })()
            }
          >
            <Image src="/icons/icon_add.png" size={18} />
            <Text>문진표 추가하기</Text>
          </Button>
        )}
      </HFlex>
      <VFlex p-32-rl height={42} sized bd-b-t3={true}>
        <TabBar
          value={selectedTab}
          items={tabs}
          onChange={(item) => setSelectedTab(item)}
        />
      </VFlex>
      {selectedTab && React.createElement(selectedTab.component)}
    </VFlex>
  );
}

function QuestionnairesList() {
  const { trackClickSafequestionaireMore, trackClickSafequestionaire } =
    useAmplitudeTrack();
  const { questionnaires, fetchQuestionnaires, fetchQuestionnaireTemplate } =
    useQuestionnaires();
  const { partner } = usePartner();

  return (
    <VFlex p-32>
      <HFlex a-s m-32-b>
        <VFlex g-8>
          <Text t-18-700>안전 문진표</Text>
          <Text t-14-400-s4>
            안전문진표 기능을 사용하면, 반려동물의 건강상태를 미리 받아볼 수
            있어요.
          </Text>
          {/* TODO Url */}
          <Text
            t-16-700
            c-ct3
            onClick={() => {
              window.open("https://www.pawdlypartners.com/safety/", "_blank");
              trackClickSafequestionaireMore(partner.title);
            }}
          >
            안전 문진표 더 알아보기
          </Text>
        </VFlex>
        <Toggle
          value={questionnaires[0].groupEnabled ?? false}
          onChange={async (value) => {
            const valueSetting = value ? "TRUE" : "FALSE";
            await api.post(
              `/rest/questionnaire/${questionnaires[0].id}?status=${valueSetting}&groupId=${partner.id}`
            );
            await fetchQuestionnaires();
          }}
        />
      </HFlex>
      {questionnaires.length === 0 ? (
        <HFlex c-c height={260} bdr-16 bd-t3 m-16-tb>
          <VFlex a-c g-8>
            <Image size={24} src={`/icons/icon_info_gray.png`} />
            <Text t-16-500-s4>사용중인 안전 문진표가 없습니다.</Text>
          </VFlex>
        </HFlex>
      ) : (
        <VFlex
          bdr-16
          style={{
            border: "1px solid #EBEBEB",
            filter: questionnaires[0].groupEnabled ? "" : "grayscale(1)",
          }}
        >
          {questionnaires.map((i, index) => (
            <VFlex key={i.id}>
              <HFlex
                p-16-tb
                p-24-rl
                clickable
                onClick={async () => {
                  trackClickSafequestionaire(partner.title);
                  const questionnaireTemplate =
                    await fetchQuestionnaireTemplate(1);

                  pushPopup({
                    kind: PopupKind.Drawer,
                    element: AddQuestionnaireForm,
                    props: {
                      // TODO template data
                      templateId: 1,
                      questionnaireTemplate,
                      templateTitle: "미용서비스 안전 문진표",
                      isAdded: true,
                      isUsing: questionnaires[0].groupEnabled,
                    },
                    width: 600,
                  });
                }}
              >
                <VFlex g-4>
                  <Text t-14-500-bl3>{i.title}</Text>
                  {i?.activedTime && (
                    <Text t-12 c-s6>
                      <Text t-12 c-s6>{`${format(
                        new Date(i.activedTime * 1000),
                        "yyyy.MM.dd",
                        {
                          locale: ko,
                        }
                      )} 생성됨`}</Text>
                    </Text>
                  )}
                </VFlex>
                <Flex f-1 />
                {i.groupEnabled ? (
                  <HFlex a-c g-4>
                    <Image src={"/icons/checkbox_checked.png"} size={20} />
                    <Text t-14-500-gr5>사용중</Text>
                  </HFlex>
                ) : (
                  <HFlex a-c g-4>
                    <Image src={"/icons/checkbox_unchecked.png"} size={20} />
                    <Text t-14-500-s2>사용 안함</Text>
                  </HFlex>
                )}
              </HFlex>
              {questionnaires.length > 1 &&
                index !== questionnaires.length - 1 && <Divider height={1} />}
            </VFlex>
          ))}
        </VFlex>
      )}
    </VFlex>
  );
}

export default QuestionnaireSetting;
