import { ReactElement, useEffect } from "react";

import { numberWithCommas } from "../../../common/utils";

import { pushPopup } from "../../../providers/popups";
import useDeletedReservationsContext from "../../../providers/deletedReservations";

import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Text from "../../../layouts/Text";
import Image from "../../../layouts/Image";
import ResizeTable from "../../../layouts/ResizeTable";
import Button from "../../../layouts/ButtonV2";
import Notification from "../../../layouts/Notification";
import LoadingView from "../../../layouts/LoadingView";

import ReservationListFilter, {
  ReservationListType,
} from "../../../components/Reservation/ReservationListFilter";
import Pagination from "../../../components/Pagination";
import { PopupKind } from "../../../popup/Popup";
import NewReservation from "../Calendar/NewReservtaion";
import ReservationDetail from "../Calendar/ReservationDetail";

import _ from "lodash";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";

const ITEMS_PER_PAGE = 10;

function DeletedReservationList({
  setSelectedSubpage,
}: {
  setSelectedSubpage: (component?: ReactElement) => void;
}) {
  const {
    totalCount,
    reservations,
    page,
    setPage,
    searchText,
    setSearchText,
    statusFilter,
    setStatusFilters,
    setStatusFiltersId,
    sortFilters,
    setSortFilters,
    startDateFilter,
    setEndDateFilter,
    endDateFilter,
    setStartDateFilter,
    fetchCurrentPage,
    isLoading,
  } = useDeletedReservationsContext();

  const { trackLandDeletedResList } = useAmplitudeTrack();
  useEffect(() => {
    trackLandDeletedResList();
  }, []);

  return (
    <VFlex ovf-h height={"100%"}>
      <HFlex p-16-tb p-24-rl j-b bd-b-t5>
        <HFlex g-8 a-c>
          <Image
            size={24}
            clickable
            src={`/icons/icon_back.png`}
            onClick={() => setSelectedSubpage(undefined)}
          />
          <Text t-22-600-s8>삭제된 예약</Text>
        </HFlex>
        <Button
          type="filled"
          icon={"/icons/icon_add.png"}
          iconSize={18}
          onClick={() => {
            pushPopup({
              kind: PopupKind.Popup,
              element: NewReservation,
              dimClick: "none",
            });
          }}
        >
          새로운 일정 등록
        </Button>
      </HFlex>

      {/* <VFlex>
        <Notification type={"full"} color="blue">
          <HFlex a-c>
            <Image src="/icons/action/info_s6.svg" size={22} m-12-r />
            <HFlex g-8>
              <Notification.Title>
                삭제된 예약은 삭제 일시로 부터 <Text t-14-600-b1>30일 </Text>
                후에 자동 영구 삭제됩니다.
              </Notification.Title>
            </HFlex>
          </HFlex>
        </Notification>
      </VFlex> */}

      <ReservationListFilter
        type={ReservationListType.deleted}
        keyword={searchText}
        setKeyword={setSearchText}
        statusList={statusFilter}
        setStatusList={setStatusFilters}
        setStatusFiltersId={setStatusFiltersId}
        sortsFilters={sortFilters}
        setSortsFilters={setSortFilters}
        startDateFilter={startDateFilter}
        setStartDateFilter={setStartDateFilter}
        endDateFilter={endDateFilter}
        setEndDateFilter={setEndDateFilter}
      />

      <HFlex p-24-rl j-b a-c>
        <Text t-16-600-s8 p-8-tb>
          전체 ({numberWithCommas(totalCount)})
        </Text>
        {/* <Image src="/icons/icon_add_circle_outline_s6.svg" size={20} p-10 /> */}
      </HFlex>

      <LoadingView loading={isLoading} f-1>
        {reservations.length === 0 ? (
          <VFlex
            a-c
            j-c
            bd-t3
            bdr-16
            g-8
            style={{
              padding: "100px 0",
              margin: "0 24px",
            }}
          >
            <Image size={24} src={`/icons/icon_noti.svg`} />
            <Text t-16-s2>내역이 존재하지 않습니다.</Text>
          </VFlex>
        ) : (
          <VFlex f-1 a-st ovf-a p-24-rl>
            <ResizeTable
              data={reservations}
              onClick={(data) => {
                pushPopup({
                  key: "resDetail",
                  kind: PopupKind.Popup,
                  element: ReservationDetail,
                  props: {
                    reservationId: data.id,
                    petId: data.pet.id,
                    schedule: data,
                    fetchCurrentPage,
                  },
                  dimClick: "none",
                });
              }}
            />
          </VFlex>
        )}
      </LoadingView>
      {reservations.length !== 0 && (
        <VFlex p-16-tb>
          <Pagination
            page={page}
            pageCount={totalCount / ITEMS_PER_PAGE}
            onPageChange={(n) => setPage(n)}
          />
        </VFlex>
      )}
    </VFlex>
  );
}

export default DeletedReservationList;
