import { useNavigate } from "react-router-dom";
import HFlex from "../../layouts/HFlex";
import Image from "../../layouts/Image";
import Text from "../../layouts/Text";
import VFlex from "../../layouts/VFlex";

function LoginCustomerService() {
  const navigate = useNavigate();
  return (
    <VFlex as-st f-1 c-c bc-w>
      <VFlex c-c a-st g-24 p-24-rl width={428}>
        <VFlex g-8 m-16-b a-c>
          <Text t-32-700>
            <Text c-gr5>포들리 고객센터</Text>로 연락주세요.
          </Text>
          <Text t-16-s2>
            포들리 고객센터 번호는 <Text c-b>010-7343-7802</Text> 입니다.
          </Text>
        </VFlex>
        <VFlex g-8>
          <HFlex height={78} bdr-24 g-16 as-st bc-t1 p-24-rl a-c clickable>
            <Image src={`/icons/icon_call_customer_service.png`} size={24} />
            <VFlex>
              <Text t-18-600-s5>고객센터 전화하기</Text>
            </VFlex>
          </HFlex>
        </VFlex>
        <HFlex m-16-t as-c clickable onClick={() => navigate("/problem")}>
          <Text t-16-700-gr5>이전으로 돌아가기</Text>
        </HFlex>
      </VFlex>
    </VFlex>
  );
}

export default LoginCustomerService;
