import _ from "lodash";
import { useMemo } from "react";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import api from "../common/api";
import { staffColors } from "../common/constants";
import { isValidDate } from "../common/format";
import { usePartner } from "./partner";
import { Partner, Schedule } from "./types";

const filteredSchedulesStore = create(
  immer<{
    partner?: Partner;
    filteredSchedules?: Schedule[];
    fetchFilteredSchedules: (
      partner: Partner,
      size: number
    ) => ({ search, page }: { search: string; page: number }) => void;
  }>((set, get) => ({
    partner: undefined,
    filteredSchedules: undefined,
    fetchFilteredSchedules:
      (partner: Partner, size: number) =>
      async ({ search, page }: { search: string; page: number }) => {
        if (page <= 0) {
          set((state) => ({
            ...state,
            filteredSchedules: undefined,
          }));
        }

        const searchedSchedule = await api.get(
          `/rest/reservation/search?groupId=${partner.id}&hiddenType=FALSE&search=${search}&page=${page}&size=${size}`
        );

        const filteredSchedules = _.flatten(
          searchedSchedule.map((schedules: any) => schedules.schedules)
        )
          .map<Schedule>((schedule: any) => {
            return {
              reservationId: schedule.reservationId,
              status: schedule.status,
              petName: schedule.petName,
              petId: schedule.petId,
              species: schedule.species,
              productList: schedule.productList ?? [],
              chargerIdList: schedule.chargerIdList,
              staffs: schedule.staffs ?? [],
              note: schedule.note,
              startDateTime: new Date(
                schedule.reservationDate.replace(/-/g, "/") +
                  " " +
                  ("0" + schedule.reservationTime.split("~")[0]).slice(-5)
              ),
              endDateTime: new Date(
                schedule.reservationDate.replace(/-/g, "/") +
                  " " +
                  ("0" + schedule.reservationTime.split("~")[1]).slice(-5)
              ),
              backgroundColor:
                schedule?.staffs?.length <= 1
                  ? staffColors[schedule.staffs[0]?.color]?.bgColor ?? "gray"
                  : "",
              requestNote: schedule.requestNote,
              nickname: schedule.nickname,
              cellNumber: schedule.cellNumber,
              title: schedule.title,
              type: schedule.type,
              memo: schedule.memo,
            };
          })
          .filter(
            (schedule) =>
              isValidDate(schedule.startDateTime) &&
              isValidDate(schedule.endDateTime)
          );

        set((state) => ({
          ...state,
          filteredSchedules:
            page <= 0
              ? filteredSchedules
              : _.uniqBy(
                  [...(get().filteredSchedules ?? []), ...filteredSchedules],
                  "reservationId"
                ),
        }));
      },
  }))
);

export const useFilteredSchedules = () => {
  const PAGE_SIZE_DEFAULT = 50;
  const { partner } = usePartner();
  const { fetchFilteredSchedules, ...rest } = filteredSchedulesStore();
  return {
    fetchFilteredSchedules: useMemo(
      () => fetchFilteredSchedules(partner, PAGE_SIZE_DEFAULT),
      [fetchFilteredSchedules, partner]
    ),
    ...rest,
  };
};
