import { ReactElement, useEffect, useMemo } from "react";
import Toggle from "../../../components/Toggle";
import api from "../../../common/api";
import { SettingsTitle } from ".";
import VFlex from "../../../layouts/VFlex";
import Text from "../../../layouts/Text";
import Divider from "../../../layouts/Divider";
import { usePartner } from "../../../providers/partner";
import { useNotifications } from "../../../providers/notifications";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";

function NotificationSettings({
  setSelectedSubpage,
}: {
  setSelectedSubpage: (component?: ReactElement) => void;
}) {
  const { trackLandNotificationSetting } = useAmplitudeTrack();
  const { partner } = usePartner();
  const { groupNotificationSettings, fetchGroupNotificationSettings } =
    useNotifications();
  const settings = useMemo(
    () => groupNotificationSettings.filter((i) => i.type === "PUSH"),
    [groupNotificationSettings]
  );

  useEffect(() => {
    trackLandNotificationSetting();
  }, []);

  return (
    <VFlex f-1 a-st>
      <SettingsTitle
        title="알림 설정"
        setSelectedSubpage={setSelectedSubpage}
      />
      <VFlex f-1 as-st p-24-t p-32-rl g-8>
        <Text t-18-700>푸시 알림 설정</Text>
        <Text t-14-400-s4>알림 설정에 따라 관련 알림을 받을 수 있습니다.</Text>
        <VFlex bdr-8 m-24-t bd-t3 style={{ maxWidth: 800 }}>
          {settings.map((setting, i) => (
            <VFlex key={i} height={74}>
              <Toggle
                value={setting.enabled}
                label={setting.notifTemplateType.title}
                subTitle={setting.notifTemplateType.description}
                onChange={async (value) => {
                  await api.put(
                    `/rest/notification/config/${setting.notifTemplateType.id}?groupId=${partner.id}&status=${value}`
                  );
                  fetchGroupNotificationSettings();
                }}
              />
              {i < settings.length - 1 && <Divider height={0.1} />}
            </VFlex>
          ))}
        </VFlex>
      </VFlex>
    </VFlex>
  );
}

export default NotificationSettings;
