import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Flex from "../../../layouts/Flex";
import Image from "../../../layouts/Image";
import Text from "../../../layouts/Text";
import Button from "../../../layouts/Button";
import { usePartner } from "../../../providers/partner";
import { pushPopup } from "../../../providers/popups";
import { PopupKind } from "../../../popup/Popup";
import { useState } from "react";

const OnboardingPopup = ({
  popPopup,
  children,
}: {
  popPopup: () => void;
  children: React.ReactElement;
}) => {
  return (
    <VFlex width={480} p-24-t p-32-rl g-16 style={{ paddingBottom: "48px" }}>
      <HFlex>
        <Flex className="f-1" />
        <Image
          size={24}
          clickable
          src={`/icons/popup_close.png`}
          alt="popup_close"
          onClick={() => {
            popPopup();
          }}
        />
      </HFlex>
      {children}
    </VFlex>
  );
};

export const NoticeOnboardingPopup = ({
  popPopup,
}: {
  popPopup: () => void;
}) => {
  return (
    <OnboardingPopup popPopup={popPopup}>
      <>
        <Text
          t-24-600-bl4
          style={{ lineHeight: "32px", whiteSpace: "pre-line" }}
        >
          {`안전 문진표로 사고를 방지하고\n안전한 서비스를 제공해보세요`}
        </Text>

        <VFlex>
          <HFlex g-16 p-16-tb p-24-rl a-c>
            <Image src="/icons/onboarding_record.png" size={40} />
            <VFlex>
              <Text t-16-500-s6>동의서까지 한번에 받아보세요</Text>
              <Text
                t-14-500-s2
                style={{
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                }}
              >
                문진표와 함께 보호자에게 동의서까지 함께 받을 수 있어 불쾌감을
                줄여줍니다
              </Text>
            </VFlex>
          </HFlex>
          <HFlex g-16 p-16-tb p-24-rl a-c>
            <Image src="/icons/onboarding_dog.png" size={40} />
            <VFlex>
              <Text t-16-500-s6>반려동물의 건강을 파악하세요</Text>
              <Text
                t-14-500-s2
                style={{
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                }}
              >
                문진표 내용으로 반려동물의 건강을 미리 파악할 수 있습니다
              </Text>
            </VFlex>
          </HFlex>
          <HFlex g-16 p-16-tb p-24-rl a-c>
            <Image src="/icons/onboarding_heart.png" size={40} />
            <VFlex>
              <Text t-16-500-s6>보호자와 신뢰를 쌓아보세요</Text>
              <Text
                t-14-500-s2
                style={{
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                }}
              >
                문진표를 바탕으로 안전한 서비스를 제공하여 보호자와 신뢰를 쌓을
                수 있어요
              </Text>
            </VFlex>
          </HFlex>
        </VFlex>

        <Flex width="100%">
          <Button
            bc-gr5
            bdr-24
            style={{ width: "100%", height: "64px" }}
            onClick={() => {
              popPopup();
            }}
          >
            <Text t-16-500-w>안전문진표 사용하기</Text>
          </Button>
        </Flex>
      </>
    </OnboardingPopup>
  );
};

export const QuestionnaireOnboardingPopup = ({
  popPopup,
  linkToQuestionnaireSetting,
}: {
  popPopup: () => void;
  linkToQuestionnaireSetting: () => void;
}) => {
  return (
    <OnboardingPopup popPopup={popPopup}>
      <>
        <Text
          t-24-600-bl4
          style={{ lineHeight: "32px", whiteSpace: "pre-line" }}
        >
          {`안전 문진표로 사고를 방지하고\n안전한 서비스를 제공해보세요`}
        </Text>

        <VFlex>
          <HFlex g-16 p-16-tb p-24-rl a-c>
            <Image src="/icons/onboarding_record.png" size={40} />
            <VFlex>
              <Text t-16-500-s6>동의서까지 한번에 받아보세요</Text>
              <Text
                t-14-500-s2
                style={{
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                }}
              >
                문진표와 함께 보호자에게 동의서까지 함께 받을 수 있어 불쾌감을
                줄여줍니다
              </Text>
            </VFlex>
          </HFlex>
          <HFlex g-16 p-16-tb p-24-rl a-c>
            <Image src="/icons/onboarding_dog.png" size={40} />
            <VFlex>
              <Text t-16-500-s6>반려동물의 건강을 파악하세요</Text>
              <Text
                t-14-500-s2
                style={{
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                }}
              >
                문진표 내용으로 반려동물의 건강을 미리 파악할 수 있습니다
              </Text>
            </VFlex>
          </HFlex>
          <HFlex g-16 p-16-tb p-24-rl a-c>
            <Image src="/icons/onboarding_heart.png" size={40} />
            <VFlex>
              <Text t-16-500-s6>보호자와 신뢰를 쌓아보세요</Text>
              <Text
                t-14-500-s2
                style={{
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                }}
              >
                문진표를 바탕으로 안전한 서비스를 제공하여 보호자와 신뢰를 쌓을
                수 있어요
              </Text>
            </VFlex>
          </HFlex>
        </VFlex>

        <Flex width="100%">
          <Button
            bc-gr5
            bdr-24
            style={{ width: "100%", height: "64px" }}
            onClick={() => {
              popPopup();
              pushPopup({
                kind: PopupKind.Popup,
                element: QuestionnaireAgreementPopup,
                props: {
                  linkToQuestionnaireSetting,
                },
              });
            }}
          >
            <Text t-16-500-w>안전문진표 사용하기</Text>
          </Button>
        </Flex>
      </>
    </OnboardingPopup>
  );
};

const QuestionnaireAgreementPopup = ({
  popPopup,
  linkToQuestionnaireSetting,
}: {
  popPopup: () => void;
  linkToQuestionnaireSetting: () => void;
}) => {
  const { partner } = usePartner();
  const [agreement, setAgreement] = useState<boolean[]>([false, false, false]);

  return (
    <OnboardingPopup popPopup={popPopup}>
      <>
        <Text
          t-24-600-bl4
          style={{ lineHeight: "32px", whiteSpace: "pre-line" }}
        >
          {`안전 문진표를 사용하기 위해서\n${partner.title}의 동의가 필요해요`}
        </Text>
        <HFlex
          bc-t1
          p-24
          bdr-24
          a-c
          j-b
          clickable
          onClick={() => {
            pushPopup({
              element: QuestionnaireAgreementReasonsPopup,
              kind: PopupKind.Popup,
            });
          }}
        >
          <HFlex a-c>
            <Image src={"/icons/onboarding_question.png"} size={24} m-16-r />
            <Text t-18-600-s8>안전문진표에 대해 궁금한가요?</Text>
          </HFlex>
          <Image src={"/icons/arrow_forward.png"} size={16} />
        </HFlex>

        <VFlex p-16-tb p-24-rl g-32>
          <Text t-18-600-s7>자세한 동의 내용을 확인해보세요</Text>
          <VFlex g-16>
            <HFlex g-8 a-c>
              <Image
                src={
                  agreement[0]
                    ? "/icons/icon_check.png"
                    : "/icons/signup_agreement_unchecked.png"
                }
                size={24}
                clickable
                onClick={() => {
                  setAgreement((agreement) =>
                    agreement.map((a, index) => (index === 0 ? !a : a))
                  );
                }}
              />
              <HFlex
                f-1
                g-8
                onClick={() =>
                  window.open(
                    "https://pawdly.oopy.io/bc276f67-4e08-4f6f-adc6-72bf5aaecb4b",
                    "_blank"
                  )
                }
                clickable
              >
                <Text t-16-400-s7 f-1>
                  [필수] 포들리 이용약관 동의
                </Text>
                <Image size={24} src={"/icons/arrow_drop_down.png"} />
              </HFlex>
            </HFlex>
            <HFlex g-8 a-c>
              <Image
                src={
                  agreement[1]
                    ? "/icons/icon_check.png"
                    : "/icons/signup_agreement_unchecked.png"
                }
                size={24}
                onClick={() => {
                  setAgreement((agreement) =>
                    agreement.map((a, index) => (index === 1 ? !a : a))
                  );
                }}
                clickable
              />
              <HFlex
                f-1
                g-8
                onClick={() =>
                  window.open(
                    "https://pawdly.oopy.io/18f26a53-a72f-4abd-b4da-7129fa931091",
                    "_blank"
                  )
                }
                clickable
              >
                <Text t-16-400-s7 f-1>
                  [필수] 포들리파트너스 이용약관 동의
                </Text>
                <Image size={24} src={"/icons/arrow_drop_down.png"} />
              </HFlex>
            </HFlex>
            <HFlex g-8 a-c>
              <Image
                src={
                  agreement[2]
                    ? "/icons/icon_check.png"
                    : "/icons/signup_agreement_unchecked.png"
                }
                size={24}
                onClick={() => {
                  setAgreement((agreement) =>
                    agreement.map((a, index) => (index === 2 ? !a : a))
                  );
                }}
                clickable
              />
              <HFlex
                f-1
                g-8
                onClick={() =>
                  window.open(
                    "https://pawdly.oopy.io/7c8a8101-a73b-43ec-aca3-ae8976ba7b83",
                    "_blank"
                  )
                }
                clickable
              >
                <Text t-16-400-s7 f-1>
                  [필수] 포들리 개인정보수집 및 이용동의
                </Text>
                <Image size={24} src={"/icons/arrow_drop_down.png"} />
              </HFlex>
            </HFlex>
          </VFlex>
        </VFlex>

        <Flex width="100%">
          <Button
            bc-gr5
            bdr-24
            style={{ width: "100%", height: "64px" }}
            enabled={agreement[0] && agreement[1] && agreement[2]}
            onClick={() => {
              popPopup();
              linkToQuestionnaireSetting();
            }}
          >
            <Text t-16-500-w>동의하고 문진표 사용하기</Text>
          </Button>
        </Flex>
      </>
    </OnboardingPopup>
  );
};

const QuestionnaireAgreementReasonsPopup = ({
  popPopup,
}: {
  popPopup: () => void;
}) => {
  return (
    <OnboardingPopup popPopup={popPopup}>
      <>
        <Text
          t-24-600-bl4
          style={{ lineHeight: "32px", whiteSpace: "pre-line" }}
        >
          {`안전 문진표 동의가 필요한\n이유들을 알려드릴게요`}
        </Text>
        <VFlex>
          <HFlex g-16 p-16-tb p-24-rl a-c>
            <Image src="/icons/onboarding_guard.png" size={40} />
            <VFlex>
              <Text t-16-500-s6>보호자에게 안전한 서비스를 제공해주세요</Text>
              <Text
                t-14-500-s2
                style={{
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                }}
              >
                동의서를 통해서 보호자에게 안전한 서비스를 제공한다는것을
                약속해주세요.
              </Text>
            </VFlex>
          </HFlex>
          <HFlex g-16 p-16-tb p-24-rl a-c>
            <Image src="/icons/onboarding_notice.png" size={40} />
            <VFlex>
              <Text t-16-500-s6>맞춤형 서비스를 제공해주세요</Text>
              <Text
                t-14-500-s2
                style={{
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                }}
              >
                반려동물의 각각 상태를 알고 맞춤형 서비스를 제공한다는 것을
                보호자에게 알려주세요.
              </Text>
            </VFlex>
          </HFlex>
          <HFlex g-16 p-16-tb p-24-rl a-c>
            <Image src="/icons/onboarding_promise.png" size={40} />
            <VFlex>
              <Text t-16-500-s6>보호자와의 약속</Text>
              <Text
                t-14-500-s2
                style={{
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-word",
                }}
              >
                보호자만 동의하는 일방적인 동의가 아닌, 안전한 서비스를 위해
                노력할것을 양쪽 모두 약속해주세요
              </Text>
            </VFlex>
          </HFlex>
        </VFlex>
      </>
    </OnboardingPopup>
  );
};
