import { isNil } from "lodash";
import React from "react";
import HFlex from "../layouts/HFlex";
import Text from "../layouts/Text";
import Image from "../layouts/Image";

import { Tag as TagType } from "../providers/types";

const Tag = ({
  tag,
  clickable,
  isActived,
  onClick,
}: {
  tag: TagType;
  clickable?: boolean;
  isActived?: boolean;
  onClick?: () => void;
}) => {
  return (
    <HFlex
      a-c
      g-4
      p-2-tb
      p-8-rl
      bdr-8
      sized
      style={
        !isNil(isActived) && isActived
          ? {
              background: tag.backgroundColor ?? "#F1F3F2",
              color: tag.textColor,
              border: `1px solid ${tag.backgroundColor ?? "#F1F3F2"}`,
            }
          : {
              background: "#F8F9F8",
              color: "#848A87",
              border: "1px solid #F1F3F2",
              boxSizing: "border-box",
            }
      }
      clickable={clickable}
      onClick={onClick}
    >
      {tag?.icon && <Image src={`/icons/${tag.icon}.svg`} size={16} />}
      <Text t-14>
        {!isNil(isActived) && isActived ? tag.title : tag.disabledTitle}
      </Text>
    </HFlex>
  );
};

export default Tag;
