import { useEffect, useState } from "react";

import api from "../../../../common/api";
import { getLastestDateTime } from "../../../../common/utils";
import {
  MEMO_STATUS_VALUE,
  Memo,
  MemoType,
  Photo,
  Schedule,
} from "../../../../providers/types";
import { pushPopup } from "../../../../providers/popups";

import { PopupKind } from "../../../../popup/Popup";
import EditReservationMemo from "./EditReseravtionMemo";
import PhotoPreview from "../../../../components/PhotoPreview";

import FilterChip from "../../../../layouts/FilterChip";
import Flex from "../../../../layouts/Flex";
import HFlex from "../../../../layouts/HFlex";
import Image from "../../../../layouts/Image";
import Text from "../../../../layouts/Text";
import VFlex from "../../../../layouts/VFlex";

import { format, isValid } from "date-fns";
import ko from "date-fns/locale/ko";

const ReservationMemo = ({
  reservationId,
  fetchCurrentPage,
  isDeletedReservation,
}: {
  reservationId: number;
  fetchCurrentPage?: () => void;
  isDeletedReservation: boolean;
}) => {
  const MEMO_OPTIONS = ["스타일 메모", "컨디션 메모", "예약 메모", "첨부파일"];
  const [selectedMemoTab, setSelectedMemoTab] = useState<string>(
    MEMO_OPTIONS[0]
  );

  const [memos, setMemos] = useState<Memo<keyof typeof MemoType>[]>([]);
  const [photos, setPhotos] = useState<Photo[]>([]);
  const [editedDate, setEditedDate] = useState<Date | undefined>();

  const loadParticalMemo = async () => {
    if (reservationId) {
      const memoResponse = await api.get(
        `/rest/v2/reservation/${reservationId}/note`
      );

      const editedDate = new Date(
        getLastestDateTime(
          memoResponse.map((m: { lastModified: number }) => m.lastModified)
        ) * 1000
      );

      setEditedDate(isValid(editedDate) ? editedDate : undefined);

      const memoList = Object.keys(MemoType).map((type, index) => {
        const data = memoResponse.find((r: any) => r.type.value === type);

        return {
          type: data?.type?.value ?? type,
          note: data?.note,
          value: data?.value?.value,
          displayOrder: index,
          attachments: data?.attachments,
        };
      });

      setMemos(memoList);
      if (memoList[0].attachments) {
        setPhotos(memoList[0].attachments ?? []);
      }
    }
  };

  useEffect(() => {
    loadParticalMemo();
  }, []);

  return (
    <VFlex p-24 g-12>
      <VFlex p-24 bc-w bdr-16 g-8>
        <HFlex g-12 a-c j-b>
          <Text t-20-600-s8>예약 메모</Text>
          <Text t-16-gr5>우리 업체만 볼 수 있는 메모입니다.</Text>
        </HFlex>
        {editedDate && (
          <Text t-16-400-s4>
            {`마지막 수정 날짜: 
            ${format(new Date(editedDate), "yyyy년 M월 d일 (E)", {
              locale: ko,
            })}`}
          </Text>
        )}
      </VFlex>

      <VFlex p-24 bc-w bdr-16 g-24>
        <HFlex g-12 j-b a-c>
          <HFlex height={34} g-6>
            <FilterChip
              type={
                selectedMemoTab === MEMO_OPTIONS[0] ? "selected" : undefined
              }
              onClick={() => {
                setSelectedMemoTab(MEMO_OPTIONS[0]);
              }}
            >
              스타일 메모
            </FilterChip>
            <FilterChip
              type={
                selectedMemoTab === MEMO_OPTIONS[1] ? "selected" : undefined
              }
              onClick={() => {
                setSelectedMemoTab(MEMO_OPTIONS[1]);
              }}
            >
              컨디션 메모
            </FilterChip>
            <FilterChip
              type={
                selectedMemoTab === MEMO_OPTIONS[2] ? "selected" : undefined
              }
              onClick={() => {
                setSelectedMemoTab(MEMO_OPTIONS[2]);
              }}
            >
              예약 메모
            </FilterChip>
            <Flex
              p-8
              bc-t1={photos.length === 0}
              bd-t5={selectedMemoTab !== MEMO_OPTIONS[3]}
              bd-g1={selectedMemoTab === MEMO_OPTIONS[3]}
              bdr-12
              c-c
              style={
                selectedMemoTab === MEMO_OPTIONS[3]
                  ? { backgroundColor: "rgba(39, 189, 99, 0.04)" }
                  : {}
              }
              clickable={photos.length > 0}
              onClick={() => {
                if (photos.length > 0) {
                  setSelectedMemoTab(MEMO_OPTIONS[3]);
                }
              }}
            >
              <Image
                src={
                  photos.length > 0
                    ? selectedMemoTab === MEMO_OPTIONS[3]
                      ? "/icons/icon_image_g1.svg"
                      : "/icons/icon_image_s3.svg"
                    : "/icons/icon_image_t1.svg"
                }
                size={20}
              />
            </Flex>
          </HFlex>
          {isDeletedReservation ? null : (
            <Image
              src="/icons/content/edit_s3.svg"
              size={24}
              clickable
              onClick={() => {
                pushPopup({
                  kind: PopupKind.Popup,
                  element: EditReservationMemo,
                  width: 530,
                  props: {
                    tabIndex: selectedMemoTab,
                    reservationId,
                    loadSchedule: () => {
                      loadParticalMemo();
                      if (fetchCurrentPage) fetchCurrentPage();
                    },
                  },

                  dimClick: "none",
                });
              }}
            />
          )}
        </HFlex>

        <VFlex>
          {selectedMemoTab === MEMO_OPTIONS[0] && <StyleMemo memos={memos} />}
          {selectedMemoTab === MEMO_OPTIONS[1] && (
            <ConditionMemo memos={memos} />
          )}
          {selectedMemoTab === MEMO_OPTIONS[2] && (
            <EtcMemo memos={memos} photos={photos} />
          )}
          {selectedMemoTab === MEMO_OPTIONS[3] && (
            <PhotoMemo memos={memos} photos={photos} />
          )}
        </VFlex>
      </VFlex>
    </VFlex>
  );
};

export default ReservationMemo;

function StyleMemo({ memos }: { memos: Memo<keyof typeof MemoType>[] }) {
  if (!memos.length) return <></>;

  return (
    <VFlex>
      {memos.slice(7).map((memo, i) => (
        <HFlex
          a-s
          g-16
          p-16-tb
          key={memo.type}
          bd-b-t3={i !== memos.slice(7).length - 1}
        >
          <Text width={100} t-16-500-s4 sized>
            {MemoType[memo.type]}
          </Text>
          {memo.note ? (
            <Text t-16-500-s8 l-m>
              {memo.note}
            </Text>
          ) : (
            <Text f-1 t-16-500-s2>
              미입력
            </Text>
          )}
        </HFlex>
      ))}
    </VFlex>
  );
}

function ConditionMemo({ memos }: { memos: Memo<keyof typeof MemoType>[] }) {
  if (!memos.length) return <></>;

  return (
    <VFlex>
      {memos.slice(1, 7).map((memo, i) => (
        <HFlex
          a-s
          g-16
          p-16-tb
          key={memo.type}
          bd-b-t3={i !== memos.slice(1, 7).length - 1}
        >
          <Text width={100} t-16-500-s4 sized>
            {MemoType[memo.type]}
          </Text>
          {memo.value ? (
            memo.value == 2 ? (
              <VFlex f-1 bc-t1 bdr-8 bd-t2 p-12 g-4>
                <Text t-16-500-sf>관찰이 필요해요</Text>
                {memo.note && (
                  <Text t-16-500-s7 l-m>
                    {memo.note}
                  </Text>
                )}
              </VFlex>
            ) : (
              <Text t-16-500-s7>{MEMO_STATUS_VALUE[memo.value]}</Text>
            )
          ) : (
            <Text f-1 t-16-500-s2>
              미입력
            </Text>
          )}
        </HFlex>
      ))}
    </VFlex>
  );
}

function EtcMemo({
  memos,
  photos,
}: {
  memos: Memo<keyof typeof MemoType>[];
  photos: Photo[];
}) {
  if (!memos.length) return <></>;

  return (
    <VFlex>
      <HFlex a-s g-16 p-16-tb bd-b-t3>
        <Text width={100} t-16-500-s4 sized>
          메모
        </Text>
        {memos[0]?.note ? (
          <Text t-16-500-s7 l-m>
            {memos[0].note}
          </Text>
        ) : (
          <Text f-1 t-16-500-s2>
            입력된 메모가 없습니다
          </Text>
        )}
      </HFlex>

      <HFlex a-s g-16 p-16-tb ovf-h>
        <Text width={100} t-16-500-s4 sized>
          사진
        </Text>

        {memos[0].attachments && memos[0].attachments.length ? (
          <VFlex ovf-s>
            <PhotoPreview photos={photos} />
          </VFlex>
        ) : (
          <Text f-1 t-16-500-s2>
            등록된 사진이 없어요
          </Text>
        )}
      </HFlex>
    </VFlex>
  );
}

function PhotoMemo({
  memos,
  photos,
}: {
  memos: Memo<keyof typeof MemoType>[];
  photos: Photo[];
}) {
  return memos[0].attachments && memos[0].attachments.length ? (
    <PhotoPreview photos={photos} />
  ) : (
    <Text f-1 t-16-500-s2>
      등록된 사진이 없어요
    </Text>
  );
}
