import Button from "../layouts/Button";
import HFlex from "../layouts/HFlex";
import Image from "../layouts/Image";
import { ButtonType } from "../layouts/index_generated";
import Text from "../layouts/Text";
import VFlex from "../layouts/VFlex";
import { Guardian } from "../providers/types";

function ConfirmPopup({
  popPopup,
  title,
  icon,
  guardians,
  cancelButtonLabel,
  cancelButtonDisplay,
  confirmButtonLabel,
  confirmButtonType,
  onConfirm,
  onCancel,
  Contents,
  dimClick,
}: {
  popPopup: () => void;
  title: string;
  onConfirm: (item?: Guardian) => void;
  onCancel?: () => void;
  icon?: string;
  guardians?: Guardian[];
  cancelButtonLabel?: string;
  cancelButtonDisplay?: string;
  confirmButtonType?: ButtonType;
  confirmButtonLabel?: string;
  Contents?: () => JSX.Element;
  dimClick?: string;
}) {
  return (
    <VFlex a-st p-24 g-8>
      <HFlex>
        <Image size={24} src={icon ?? `/icons/icon_warning.png`} />
      </HFlex>
      <Text t-18-500-s10 l-m>
        {title}
      </Text>
      {Contents && <Contents />}
      {guardians ? (
        <VFlex>
          {guardians.map((item, i) => (
            <HFlex
              key={i}
              a-c
              height={56}
              clickable
              onClick={() => {
                if (dimClick === "none") return;

                popPopup?.();
                onConfirm(item);
              }}
            >
              <VFlex f-1>
                <Text t-14-700-s10>{item.name}</Text>
                <Text t-14-700-s4>{item.cellnumber}</Text>
              </VFlex>
              <Image size={24} src={`/icons/checkbox_unchecked.png`} />
            </HFlex>
          ))}
        </VFlex>
      ) : (
        <HFlex g-8 m-24-t>
          <VFlex f-1 />
          <Button
            type="cancel"
            onClick={() => {
              popPopup?.();
              onCancel?.();
            }}
            caption={cancelButtonLabel ?? "취소"}
            style={{
              display: cancelButtonDisplay,
            }}
          />
          <Button
            type={confirmButtonType ?? "warning"}
            onClick={() => {
              popPopup?.();
              onConfirm();
            }}
            caption={confirmButtonLabel ?? "확인"}
          />
        </HFlex>
      )}
    </VFlex>
  );
}

export default ConfirmPopup;
