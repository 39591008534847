import _, { cloneDeep, isNil } from "lodash";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { Species, PetInfo, Guardian } from "./types";
import { NEUTRALIZED_VALUES } from "../common/constants";
import { parseDateString } from "../common/utils/customers";

export interface PetInfoForm {
  age?: number;
  months?: number;
  name?: string;
  neutralized?: number;
  note?: string;
  sex?: number;
  species?: Species;
  breed?: Species;
  weight?: string;
  id?: number;
  allergy?: string;
  birthday?: Date;
  profileImgUrl?: string;
  profileImgFile?: File;
}

export interface GuardianForm {
  id?: number;
  name?: string;
  cellnumber?: string;
  note?: string;
  primaryGuardian?: boolean;
}

export type CustomerInfo = PetInfoForm & GuardiansInfoForm;

interface GuardiansInfoForm {
  guardians: GuardianForm[];
}

export const defaultPetInfo = {
  age: undefined,
  months: undefined,
  name: undefined,
  neutralized: undefined,
  note: undefined,
  sex: undefined,
  species: undefined,
  breed: undefined,
  weight: undefined,
  id: undefined,
  allergy: undefined,
  birthday: undefined,
  profileImgFile: undefined,
  profileImgUrl: undefined,
};

export const defaultGuardiansInfo = [
  {
    id: undefined,
    name: undefined,
    cellnumber: undefined,
    note: undefined,
    primaryGuardian: true,
  },
];

const useCustomerFormStore = create(
  immer<
    CustomerInfo & {
      initInfo: (info?: PetInfo & { guardians: Guardian[] }) => void;
      setData: (data: Partial<CustomerInfo>) => void;
      addNewGuardian: () => void;
      deleteGuardian: (index: number) => void;
    }
  >((set, get) => ({
    ...defaultPetInfo,
    guardians: defaultGuardiansInfo,
    // func
    initInfo: (info) => {
      if (info) {
        const species = {
          id: info.species.speciesTypeId ?? info.species.id,
          name: info.species.speciesType ?? info.species.name,
        };
        const breed = { id: info.species.id, name: info.species.name };
        const neutralized = NEUTRALIZED_VALUES.findIndex(
          (v) => v === info.neuteredStatus
        );
        const birthday =
          info.birthday &&
          (info.birthday?.substring(4, 6) === "00" ||
            info.birthday?.substring(6, 8) === "00")
            ? undefined
            : info.birthday
            ? parseDateString(info.birthday)
            : undefined;
        const age = isNil(birthday) ? info.age : undefined;
        const months = isNil(birthday) ? info.months : undefined;

        set(() => ({
          ...info,
          profileImgUrl: info.attachment?.path,
          species,
          breed,
          neutralized,
          birthday,
          age,
          months,
        }));
      } else {
        set(() => ({
          ...defaultPetInfo,
          guardians: defaultGuardiansInfo,
        }));
      }
    },
    setData: (data) =>
      set((state) => ({
        ...state,
        ...data,
      })),
    addNewGuardian: () => {
      set((state) => ({
        ...state,
        guardians: [
          ...state.guardians,
          {
            id: undefined,
            name: undefined,
            cellnumber: undefined,
            note: undefined,
            primaryGuardian: false,
          },
        ],
      }));
    },
    deleteGuardian: (index: number) => {
      set((state) => {
        let newGuardians = cloneDeep(state.guardians);
        newGuardians = [
          ...newGuardians.slice(0, index),
          ...newGuardians.slice(index + 1),
        ];
        return {
          ...state,
          guardians: newGuardians,
        };
      });
    },
  }))
);

export default useCustomerFormStore;
