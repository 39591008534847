import { useNavigate } from "react-router-dom";
import HFlex from "../../layouts/HFlex";
import Image from "../../layouts/Image";
import Text from "../../layouts/Text";
import VFlex from "../../layouts/VFlex";
import { useAmplitudeTrack } from "../../common/useAmplitudeTrack";

function LoginProblem() {
  const { trackClickForgotPassword, trackClickChangeCellnumber } =
    useAmplitudeTrack();
  const navigate = useNavigate();
  return (
    <VFlex as-st f-1 c-c bc-w>
      <VFlex c-c a-st g-24 p-24-rl width={428}>
        <VFlex g-8 m-16-b a-c>
          <Text t-32-700>
            <Text c-gr5>로그인에 문제</Text>가 있나요?
          </Text>
          <Text t-16-s2>회원님의 문제를 알려주시면 도와드릴게요</Text>
        </VFlex>
        <VFlex g-8>
          <HFlex
            height={78}
            bdr-24
            g-16
            as-st
            bc-t1
            p-24-rl
            a-c
            clickable
            onClick={() => {
              navigate("/forgotpassword");
              trackClickForgotPassword();
            }}
          >
            <Image src={`/icons/icon_change_password.png`} size={24} />
            <VFlex>
              <Text t-18-600-s5>비밀번호를 잊어버렸어요.</Text>
              <Text t-14-500-s2>본인 인증을 통해 비밀번호를 재설정합니다.</Text>
            </VFlex>
          </HFlex>
          <HFlex
            height={78}
            bdr-24
            g-16
            as-st
            bc-t1
            p-24-rl
            a-c
            clickable
            onClick={() => {
              navigate("/customerservice");
              trackClickChangeCellnumber();
            }}
          >
            <Image src={`/icons/icon_customer_service.png`} size={24} />
            <VFlex>
              <Text t-18-600-s5>휴대폰 번호가 바뀌었어요.</Text>
              <Text t-14-500-s2>고객센터로 연락합니다.</Text>
            </VFlex>
          </HFlex>
        </VFlex>
      </VFlex>
    </VFlex>
  );
}

export default LoginProblem;
