import _ from "lodash";
import { ImgHTMLAttributes } from "react";
import {
  BorderRadiusProps,
  DimensionProps,
  MiscellaneousProps,
  propsToClasses,
  restOfProps,
} from "./index_generated";
import "./layout.scss";

type ImageProps = {
  classes?: string[];
  width?: number | string;
  height?: number | string;
  size?: number | string;
  round?: boolean;
  src?: string;
  alt?: string;
} & ImgHTMLAttributes<HTMLImageElement> &
  DimensionProps &
  BorderRadiusProps &
  MiscellaneousProps;

const Image = ({
  classes = [],
  children,
  round,
  width,
  height,
  size,
  src,
  alt,
  style,
  ...props
}: ImageProps) => {
  width = width || size;
  height = height || size;
  return (
    <img
      className={_.compact([...propsToClasses(props), ...classes]).join(" ")}
      style={{
        ...(width
          ? _.isString(width)
            ? { width }
            : { width: `${width}px` }
          : {}),
        ...(height
          ? _.isString(height)
            ? { height }
            : { height: `${height}px` }
          : {}),
        ...style,
      }}
      src={src}
      alt={alt}
      {...restOfProps(props)}
    />
  );
};

export default Image;
