import _ from "lodash";
import { TextareaHTMLAttributes, useEffect, useRef, useState } from "react";
import Absolute from "./Absolute";
import HFlex from "./HFlex";
import {
  ColorProps,
  FontProps,
  LineProps,
  propsToClasses,
  restOfProps,
} from "./index_generated";
import "./layout.scss";
import Text from "./Text";
import VFlex from "./VFlex";
import Flex from "./Flex";
import Image from "./Image";

type TextAreaProps = {
  classes?: string[];
  placeholder?: string;
  required?: boolean;
  caption?: string;
  disabled?: boolean;
  height?: number;
  value?: string;
  onChangeValue?: (value: string | undefined) => void;
  maxLength?: number;
  fontsize?: number | string;
} & TextareaHTMLAttributes<HTMLTextAreaElement> &
  FontProps &
  ColorProps &
  FontProps &
  LineProps;

const TextArea = ({
  classes = [],
  placeholder,
  caption,
  required,
  value,
  disabled,
  height,
  fontsize,
  onChangeValue,
  maxLength,
  ...props
}: TextAreaProps) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [focused, setFocused] = useState<boolean>(false);
  const [textLength, setTextLength] = useState(value?.length);

  useEffect(() => {
    setTextLength(value?.length);
  }, [value]);

  return (
    <VFlex g-8 rel unclickable={disabled} clickable={!disabled}>
      {caption && (
        <HFlex g-8>
          <Text t-14-s8>{caption}</Text>
          {required && <Text t-14-700-rd1>*</Text>}
        </HFlex>
      )}
      <VFlex
        bc-tw
        rel
        bdr-16
        bd-t5={!focused}
        bd-gr5={focused}
        height={height}
        style={{
          minHeight: 56,
          maxHeight: 300,
          resize: "vertical",
        }}
        onClick={() => {
          setFocused(true);
          inputRef.current?.focus();
        }}
      >
        <Absolute left={16} top={13} unclickable>
          {(!!caption ? !value : !value && !!placeholder) && (
            <Text
              transition
              style={{
                color: "#AAB2AE",
                fontSize: fontsize ? fontsize : 16,
              }}
            >
              {placeholder}
            </Text>
          )}
        </Absolute>
        <textarea
          ref={inputRef}
          style={{
            padding: 16,
            height: "100%",
            fontWeight: 500,
            fontSize: fontsize ? fontsize : 16,
            color: "#383B3A",
          }}
          className={_.compact([...propsToClasses(props), ...classes]).join(
            " "
          )}
          value={value}
          autoFocus={false}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onChange={(e) => {
            if (maxLength && e.target.value.length > maxLength) {
              return;
            }
            onChangeValue?.(e.target.value);
          }}
          disabled={disabled}
          {...restOfProps(props)}
        />
        <Image
          style={{
            position: "absolute",
            right: 5,
            bottom: 5,
            zIndex: 1,
          }}
          size={10}
          src={`/icons/resize.svg`}
        />
      </VFlex>
      {maxLength && (
        <HFlex m-4-t>
          <Flex f-1 />
          <Text t-14-500-s4>{`${textLength ?? 0} / ${maxLength}`}</Text>
        </HFlex>
      )}
    </VFlex>
  );
};

export default TextArea;
