import { useEffect, useState } from "react";

import api from "../../../../common/api";
import Spinner from "../../../../common/Spinner/Spinner";

import {
  MEMO_STATUS_VALUE,
  MEMO_STATUS_VALUES,
  Memo,
  MemoType,
  Photo,
} from "../../../../providers/types";
import { pushPopup } from "../../../../providers/popups";

import FilesUpload from "../../../../components/FilesUpload";
import GroupRadioButton from "../../../../components/GroupRadioButton";
import PhotosSwiperPopup from "../../../../components/PhotosSwiperPopup";
import { PopupKind } from "../../../../popup/Popup";

import Absolute from "../../../../layouts/Absolute";
import Button from "../../../../layouts/Button";
import FilterChip from "../../../../layouts/FilterChip";
import Flex from "../../../../layouts/Flex";
import HFlex from "../../../../layouts/HFlex";
import Image from "../../../../layouts/Image";
import Text from "../../../../layouts/Text";
import TextInput from "../../../../layouts/TextInput";
import TextArea from "../../../../layouts/TextareaV2";
import VFlex from "../../../../layouts/VFlex";

import { isNil } from "lodash";
import toast from "react-hot-toast";

const EditReservationMemo = ({
  popPopup,
  tabIndex,
  reservationId,
  loadSchedule,
  fetchCurrentPage,
}: {
  popPopup: () => void;
  tabIndex: string;
  reservationId?: number;
  loadSchedule: () => void;
  fetchCurrentPage?: () => void;
}) => {
  const MEMO_OPTIONS = ["스타일 메모", "컨디션 메모", "예약 메모", "첨부파일"];
  const [selectedMemoTab, setSelectedMemoTab] = useState<string>(
    MEMO_OPTIONS[0]
  );
  const [memos, setMemos] = useState<Memo<keyof typeof MemoType>[]>([]);
  const [photos, setPhotos] = useState<Photo[]>([]);
  const [prevPhotos, setPrevPhotos] = useState<Photo[]>([]);
  const [deletedPhotoId, setDeletedPhotoId] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    tabIndex === "첨부파일"
      ? setSelectedMemoTab(MEMO_OPTIONS[2])
      : setSelectedMemoTab(tabIndex);
  }, [tabIndex]);

  useEffect(() => {
    const fetchMemos = async () => {
      let memoList = [];

      if (reservationId) {
        const memoResponse = await api.get(
          `/rest/v2/reservation/${reservationId}/note`
        );

        memoList = Object.keys(MemoType).map((type, index) => {
          const data = memoResponse.find((r: any) => r.type.value === type);

          return {
            type: data?.type?.value ?? type,
            note: data?.note,
            value: data?.value?.value,
            displayOrder: index,
            attachments: data?.attachments,
          };
        });
      } else {
        memoList = Object.keys(MemoType).map((type, index) => ({
          type: type as keyof typeof MemoType,
          note: undefined,
          value: undefined,
          displayOrder: index,
          attachments: [],
        }));
      }

      setMemos(memoList);

      const requestIndex = memoList.findIndex((m) => m.type === "REQUEST");
      if (memoList[requestIndex]?.attachments) {
        setPhotos(memoList[requestIndex].attachments ?? []);
        setPrevPhotos(memoList[requestIndex].attachments ?? []);
      }
    };

    fetchMemos();
  }, [reservationId]);

  const [files, setFiles] = useState<File[]>([]);

  const removeFile = (idx: number) => {
    const newFiles =
      files &&
      files.filter((file, i) => {
        return idx !== i;
      });
    setFiles(newFiles);
  };

  useEffect(() => {
    const result = prevPhotos.filter((value) => !photos.includes(value));
    setDeletedPhotoId(result.map((r) => r.id));
  }, [photos]);

  return (
    <VFlex
      height={"85vh"}
      style={{
        maxWidth: 530,
      }}
    >
      <HFlex j-b a-c p-24>
        <Text t-24-600-s8>예약 메모</Text>
        <HFlex g-24 a-c>
          <Text t-16-s6>우리 업체 공유용</Text>
          <Image
            src="/icons/icon_close_s6.svg"
            size={24}
            clickable
            onClick={() => {
              popPopup();
            }}
          />
        </HFlex>
      </HFlex>

      <VFlex g-24 ovf-a>
        <HFlex g-12 j-b a-c p-24-rl>
          <HFlex height={34} g-6>
            <FilterChip
              type={
                selectedMemoTab === MEMO_OPTIONS[0] ? "selected" : undefined
              }
              onClick={() => {
                setSelectedMemoTab(MEMO_OPTIONS[0]);
              }}
            >
              스타일 메모
            </FilterChip>
            <FilterChip
              type={
                selectedMemoTab === MEMO_OPTIONS[1] ? "selected" : undefined
              }
              onClick={() => {
                setSelectedMemoTab(MEMO_OPTIONS[1]);
              }}
            >
              컨디션 메모
            </FilterChip>
            <FilterChip
              type={
                selectedMemoTab === MEMO_OPTIONS[2] ? "selected" : undefined
              }
              onClick={() => {
                setSelectedMemoTab(MEMO_OPTIONS[2]);
              }}
            >
              예약 메모
            </FilterChip>
          </HFlex>
        </HFlex>
        <VFlex
          j-b
          p-24-brl={
            selectedMemoTab === MEMO_OPTIONS[0] ||
            selectedMemoTab === MEMO_OPTIONS[1]
          }
          p-24-bl={selectedMemoTab === MEMO_OPTIONS[2]}
        >
          {selectedMemoTab === MEMO_OPTIONS[0] && (
            <StyleMemoSection memos={memos} setMemos={setMemos} />
          )}
          {selectedMemoTab === MEMO_OPTIONS[1] && (
            <ConditionMemoSection memos={memos} setMemos={setMemos} />
          )}
          {selectedMemoTab === MEMO_OPTIONS[2] && (
            <ResMemoSection
              loading={loading}
              memos={memos}
              setMemos={setMemos}
              photos={photos}
              setPhotos={setPhotos}
              files={files ?? []}
              setFiles={setFiles}
              removeFile={removeFile}
            />
          )}
        </VFlex>
      </VFlex>
      <Flex f-1 />
      <VFlex p-16-t p-24-rl p-32-b>
        <Button
          bdr-12
          height={56}
          onClick={async () => {
            const formData = new FormData();
            let memoCnt = 0;
            memos.forEach((memo, index) => {
              if ((!isNil(memo.value) && !isNaN(memo.value)) || memo.note) {
                formData.append(`notes[${memoCnt}].type`, memo.type);
                if (!isNil(memo.value) && !isNaN(memo.value)) {
                  formData.append(
                    `notes[${memoCnt}].condition`,
                    MEMO_STATUS_VALUES[memo.value]
                  );
                }

                if (
                  memo.note &&
                  ((!isNil(memo.value) &&
                    !isNaN(memo.value) &&
                    memo.value == 2) ||
                    !memo.type.startsWith("C_"))
                ) {
                  formData.append(`notes[${memoCnt}].note`, memo.note);
                }
                memoCnt++;
              }
            });

            const requestIndex = !memos.find((v) => v.type === "REQUEST")?.note
              ? memoCnt
              : memos.findIndex((v) => v.type === "REQUEST");

            formData.append(`notes[${requestIndex}].type`, "REQUEST");

            if (files.length > 0) {
              files.forEach((file, index) => {
                formData.append(`notes[${requestIndex}].files[${index}]`, file);
              });
            }

            if (deletedPhotoId.length > 0) {
              deletedPhotoId.forEach((id, index) => {
                formData.append(
                  `notes[${requestIndex}].deletedAttachmentIds[${index}]`,
                  id.toString()
                );
              });
            }

            if (reservationId) {
              setLoading(true);
              await api.post(
                `/rest/v2/reservation/${reservationId}/note`,
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );
              toast.success("저장되었습니다");
              await loadSchedule();
              if (fetchCurrentPage) fetchCurrentPage();
              popPopup();
            }
          }}
        >
          저장
        </Button>
      </VFlex>
    </VFlex>
  );
};

export default EditReservationMemo;

const StyleMemoSection = ({
  memos,
  setMemos,
}: {
  memos: Memo<keyof typeof MemoType>[];
  setMemos: (memos: Memo<keyof typeof MemoType>[]) => void;
}) => {
  if (!memos.length) return <></>;

  return (
    <VFlex>
      {memos.slice(7).map((i, index) => (
        <TextArea
          key={i.type}
          caption={MemoType[i.type]}
          placeholder={"메모하기"}
          value={i.note}
          height={72}
          maxLength={800}
          onChangeValue={(v) => {
            const newMemos = [...memos];

            const newMemo: Memo<keyof typeof MemoType> = {
              type: i?.type,
              value: i?.value,
              note: v,
              displayOrder: i.displayOrder,
            };
            newMemos[index + 7] = newMemo;

            if (v?.length || 0 <= 800) setMemos(newMemos);
          }}
        />
      ))}
    </VFlex>
  );
};

const ConditionMemoSection = ({
  memos,
  setMemos,
}: {
  memos: Memo<keyof typeof MemoType>[];
  setMemos: (memos: Memo<keyof typeof MemoType>[]) => void;
}) => {
  if (!memos.length) return <></>;

  return (
    <VFlex g-16>
      {memos.slice(1, 6).map((i, index) => {
        return (
          <VFlex key={i.type} g-16>
            <GroupRadioButton
              value={
                !isNil(i.value)
                  ? {
                      value: Number(i.value),
                      label: MEMO_STATUS_VALUE[i.value],
                    }
                  : undefined
              }
              caption={MemoType[i.type]}
              options={[
                { label: "좋아요", value: 0 },
                { label: "보통", value: 1 },
                { label: "관찰이 필요해요", value: 2 },
              ]}
              onChange={(e) => {
                if (!isNil(e?.value)) {
                  const newMemos = [...memos];

                  const newMemo: Memo<keyof typeof MemoType> = {
                    type: i?.type,
                    value: e?.value,
                    note: i?.note,
                    displayOrder: i.displayOrder,
                  };
                  newMemos[index + 1] = newMemo;

                  setMemos(newMemos);
                } else {
                  const newMemos = [...memos];
                  const newMemo: Memo<keyof typeof MemoType> = {
                    type: memos[index + 1].type,
                    value: undefined,
                    note: memos[index + 1]?.note,
                    displayOrder: memos[index + 1].displayOrder,
                  };
                  newMemos[index + 1] = newMemo;
                  setMemos(newMemos);
                }
              }}
            />
            {i.value == 2 && (
              <TextArea
                value={i.note}
                placeholder={"내용을 입력해주세요."}
                height={120}
                maxLength={800}
                onChangeValue={(v) => {
                  const newMemos = [...memos];

                  const newMemo: Memo<keyof typeof MemoType> = {
                    type: memos[index + 1]?.type,
                    value: memos[index + 1]?.value,
                    note: v,
                    displayOrder: memos[index + 1].displayOrder,
                  };
                  newMemos[index + 1] = newMemo;

                  if (v?.length || 0 <= 800) setMemos(newMemos);
                }}
              />
            )}
          </VFlex>
        );
      })}
      <VFlex key={memos[6]?.type} g-16>
        <GroupRadioButton
          value={
            !isNil(memos[6].value)
              ? {
                  value: Number(memos[6]?.value),
                  label: MEMO_STATUS_VALUE[memos[6]?.value],
                }
              : undefined
          }
          caption={MemoType[memos[6].type]}
          options={[
            { label: "없어요", value: 3 },
            { label: "있어요", value: 4 },
          ]}
          onChange={(e) => {
            if (!isNil(e?.value)) {
              const newMemos = [...memos];

              const newMemo: Memo<keyof typeof MemoType> = {
                type: memos[6]?.type,
                value: e?.value,
                note: memos[6]?.note,
                displayOrder: memos[6].displayOrder,
              };
              newMemos[6] = newMemo;

              setMemos(newMemos);
            } else {
              const newMemos = [...memos];

              const newMemo: Memo<keyof typeof MemoType> = {
                type: memos[6].type,
                value: undefined,
                note: memos[6]?.note,
                displayOrder: memos[6].displayOrder,
              };
              newMemos[6] = newMemo;

              setMemos(newMemos);
            }
          }}
        />
        {memos[6].value == 2 && (
          <TextInput
            value={memos[6].note}
            onChange={(e) => {
              const newMemos = [...memos];

              const newMemo: Memo<keyof typeof MemoType> = {
                type: memos[6]?.type,
                value: memos[6]?.value,
                note: e.target.value,
                displayOrder: memos[6].displayOrder,
              };
              newMemos[6] = newMemo;

              setMemos(newMemos);
            }}
          />
        )}
      </VFlex>
    </VFlex>
  );
};

const ResMemoSection = ({
  loading,
  photos,
  memos,
  files,
  setPhotos,
  setMemos,
  setFiles,
  removeFile,
}: {
  loading: boolean;
  photos: Photo[];
  memos: Memo<keyof typeof MemoType>[];
  files: File[];
  setPhotos: any;
  setMemos: (memos: Memo<keyof typeof MemoType>[]) => void;
  setFiles: any;
  removeFile: any;
}) => {
  const pushPhotosSwiperPopup = (index: number) => {
    pushPopup({
      element: PhotosSwiperPopup,
      kind: PopupKind.Swiper,
      props: {
        attachments: photos,
        index,
      },
    });
  };

  return (
    <VFlex g-16>
      {loading && (
        <VFlex
          a-c
          j-c
          bdr-24
          width={"100%"}
          height={"100%"}
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            margin: "0 auto",
            top: "50%",
            transform: "translateY(-50%)",
            background: "rgba(0,0,0,0.2)",
            zIndex: 99,
          }}
        >
          <Spinner />
        </VFlex>
      )}

      <VFlex p-24-r>
        <TextArea
          placeholder="내용을 입력해주세요."
          value={memos[0]?.note}
          onChangeValue={(v) => {
            const newMemos = [...memos];
            newMemos[0] = { ...newMemos[0], note: v };
            if (v?.length || 0 <= 800) setMemos(newMemos);
          }}
          height={120}
          maxLength={800}
        />
      </VFlex>

      <HFlex a-c g-8>
        <VFlex bd-t5 bdr-16 a-c j-c sized width={56} height={56}>
          <label
            htmlFor="upload"
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Image src="/icons/action/photo_camera_s6.svg" size={24} />
            <FilesUpload
              id="upload"
              multiple
              onLoad={async (f: File[]) => {
                if (!files) {
                  setFiles(f);
                } else {
                  setFiles([...files!, ...f]);
                }
              }}
            />
          </label>
        </VFlex>
        <HFlex
          p-10-t
          p-8-r
          g-8
          ovf-s-d
          style={{
            marginTop: "-10px",
          }}
        >
          {[...photos, ...files].map((item, i) => {
            const isPhoto = "path" in item;
            return (
              <VFlex key={i} rel>
                <Image
                  width={56}
                  height={56}
                  bdr-16
                  src={isPhoto ? item.path : URL.createObjectURL(item)}
                  clickable
                  onClick={() => {
                    // pushPhotosSwiperPopup(i);
                  }}
                />
                <Absolute
                  top={-3}
                  right={-3}
                  bottom={"auto"}
                  left={"auto"}
                  width={20}
                  height={20}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 99,
                  }}
                  clickable
                  onClick={(e) => {
                    e.preventDefault();
                    if (isPhoto) {
                      setPhotos(photos.filter((p) => p.id !== item.id));
                    } else {
                      removeFile(i - photos.length);
                    }
                  }}
                >
                  <Image src="/icons/delete_photo.svg" size={16} />
                </Absolute>
              </VFlex>
            );
          })}
        </HFlex>
      </HFlex>
    </VFlex>
  );
};
