import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import api from "../../../common/api";
import Checkbox from "../../../components/Checkbox";
import Toggle from "../../../components/Toggle";
import Button from "../../../layouts/Button";
import HFlex from "../../../layouts/HFlex";
import PercentageInput from "../../../layouts/PercentageInput";
import Text from "../../../layouts/Text";
import VFlex from "../../../layouts/VFlex";
import ConfirmPopup from "../../../popup/ConfirmPopup";
import { PopupAlign, PopupKind } from "../../../popup/Popup";
import { usePartner } from "../../../providers/partner";
import { pushPopup } from "../../../providers/popups";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";

function MileageSettings({ popPopup }: { popPopup: () => void }) {
  const {
    trackClickMileageSettings,
    trackSaveMileageSettings,
    trackClickMileageDeduction,
  } = useAmplitudeTrack();
  const { partner } = usePartner();
  const [isUsing, setIsUsing] = useState(false);
  const [savingRatio, setSavingRatio] = useState({
    cash: "",
    card: "",
    transfer: "",
  });
  const [mileageDeduction, setMileageDeduction] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const fetchMileagePolicy = async () => {
    const res: any = await api.get(
      `/rest/group/${partner.id}/meta?type=MILEAGE_POLICY`
    );

    setIsUsing(res[0]?.value === "TRUE");
    setSavingRatio({
      cash: res[0]?.childs[0]?.value ?? "",
      card: res[0]?.childs[1]?.value ?? "",
      transfer: res[0]?.childs[2]?.value ?? "",
    });
    setMileageDeduction(res[0]?.childs[3]?.value === "TRUE");
  };

  useEffect(() => {
    fetchMileagePolicy();
  }, []);

  const handleChangeIsUsing = async (v: boolean) => {
    if (!v) {
      pushPopup({
        kind: PopupKind.Popup,
        align: PopupAlign.TopCenter,
        element: ConfirmPopup,
        props: {
          title: `적립금 정책을 그만 사용할까요?\n비활성화 시 고객의 보유 적립금이 더이상 노출되지 않습니다.`,
          onConfirm: () => {
            api.post(`/rest/group/${partner.id}/meta`, {
              meta: [
                {
                  type: "MILEAGE_POLICY",
                  value: "FALSE",
                  valueType: "BOOLEAN",
                },
              ],
            });
            setIsUsing(false);
            toast.success("적립금 정책을 사용하지 않습니다");
            trackClickMileageSettings(false, partner.title);
          },
        },
      });
    } else {
      setIsUsing(true);
      api.post(`/rest/group/${partner.id}/meta`, {
        meta: [
          {
            type: "MILEAGE_POLICY",
            value: "TRUE",
            valueType: "BOOLEAN",
          },
        ],
      });
      toast.success("적립금 정책을 사용합니다");
      trackClickMileageSettings(true, partner.title);
    }
  };

  return (
    <VFlex f-1 a-st p-24-t rel ovf-h>
      <HFlex m-16-b>
        <Text t-18-600 c-s8 m-32-rl>
          적립금 정책
        </Text>
        <Toggle value={isUsing} onChange={(v) => handleChangeIsUsing(v)} />
      </HFlex>
      {isUsing ? (
        <VFlex f-1>
          <VFlex f-1>
            <Text m-32-rl m-24-b t-16-600-s6>
              결제 방법별 적립금 설정
            </Text>

            <VFlex m-32-rl g-16>
              <PercentageInput
                value={savingRatio.transfer}
                caption={"계좌이체"}
                onChangeValue={(value) => {
                  setIsChanged(true);
                  setSavingRatio((p) => ({
                    ...p,
                    transfer: value ?? "",
                  }));
                }}
              />
              <PercentageInput
                value={savingRatio.cash}
                caption={"현금"}
                onChangeValue={(value) => {
                  setIsChanged(true);
                  setSavingRatio((p) => ({ ...p, cash: value ?? "" }));
                }}
              />
              <PercentageInput
                value={savingRatio.card}
                caption={"카드"}
                onChangeValue={(value) => {
                  setIsChanged(true);
                  setSavingRatio((p) => ({ ...p, card: value ?? "" }));
                }}
              />

              <VFlex m-16-t>
                <Checkbox
                  rightLabel="차감시에도 적립금 지급"
                  initialValue={mileageDeduction}
                  onChange={(e) => {
                    setIsChanged(true);
                    setMileageDeduction(e);
                    trackClickMileageDeduction(e, partner.title);
                  }}
                />
              </VFlex>
            </VFlex>
          </VFlex>

          <HFlex g-8 m-24-t p-32-rl height={86} bd-t-t3 a-c>
            <VFlex f-1 />
            <Button
              type="cancel"
              onClick={() => {
                isChanged
                  ? pushPopup({
                      kind: PopupKind.Popup,
                      align: PopupAlign.TopCenter,
                      element: ConfirmPopup,
                      props: {
                        title: "변경사항을 저장하지 않고 닫을까요?",
                        onConfirm: () => {
                          popPopup();
                        },
                      },
                    })
                  : popPopup();
              }}
              caption="취소"
            />
            <Button
              type="confirm"
              enabled={isChanged}
              onClick={async () => {
                if (!isChanged) {
                  return;
                }
                pushPopup({
                  kind: PopupKind.Popup,
                  align: PopupAlign.TopCenter,
                  element: ConfirmPopup,
                  props: {
                    title:
                      "내용을 저장하시겠어요? 새롭게 바뀐 정보가 저장됩니다.",
                    icon: "/icons/icon_good_warning.png",
                    confirmButtonLabel: "저장하기",
                    confirmButtonType: "confirm",
                    onConfirm: async () => {
                      api.post(`/rest/group/${partner.id}/meta`, {
                        meta: [
                          {
                            type: "MILEAGE_POLICY",
                            value: isUsing ? "TRUE" : "FALSE",
                            valueType: "BOOLEAN",
                          },
                          {
                            type: "MILEAGE_CASH",
                            value: savingRatio.cash || "0",
                            valueType: "INTEGER",
                          },
                          {
                            type: "MILEAGE_CARD",
                            value: savingRatio.card || "0",
                            valueType: "INTEGER",
                          },
                          {
                            type: "MILEAGE_TRANSFER",
                            value: savingRatio.transfer || "0",
                            valueType: "INTEGER",
                          },
                          {
                            type: "MILEAGE_DEDUCTION",
                            value: mileageDeduction ? "TRUE" : "FALSE",
                            valueType: "BOOLEAN",
                          },
                        ],
                      });
                      toast.success("적립금 정책이 저장되었습니다");
                      setIsChanged(false);
                      trackSaveMileageSettings(partner.title);
                    },
                  },
                });
              }}
              caption="저장"
            />
          </HFlex>
        </VFlex>
      ) : (
        <HFlex m-32-rl bc-t1 bd-t3 bdr-16 p-16-rl p-16-tb>
          <Text
            t-16-500
            style={{ whiteSpace: "break-spaces", wordBreak: "break-word" }}
          >
            고객에게 재방문 시 사용 가능한 적립금 제도를 안내해 보세요. 결제
            방법별로 적립금을 설정하고, 결제 정보 입력 시 적립금을 적용할 수
            있습니다.
          </Text>
        </HFlex>
      )}
    </VFlex>
  );
}

export default MileageSettings;
