import { useState, useMemo } from "react";

import FilterChip from "../../layouts/FilterChip";
import Text from "../../layouts/Text";
import DropdownWithSearch from "../DropdownWithSearch";
import Dropdown from "../../layouts/Dropdown";

import { SpeciesFilter as SpeciesFilterType } from "../../providers/types";

const SpeciesFilter = ({
  options,
  speciesList,
  setSpeciesFilter,
  removeFilter,
}: {
  options: SpeciesFilterType[];
  speciesList: SpeciesFilterType[];
  setSpeciesFilter: (s: SpeciesFilterType[]) => void;
  removeFilter: () => void;
}) => {
  const [keyword, setKeyword] = useState("");

  const filteredSpeciesOptions = useMemo(() => {
    return options.filter((species) => species.name.includes(keyword));
  }, [keyword, options]);

  return (
    <Dropdown
      position="left"
      trigger={
        speciesList.length > 0 ? (
          <FilterChip
            size="S"
            type="selected"
            onClick={() => {}}
            suffixIcon="/icons/active_arrow_drop_down.svg"
            suffixIconSize={18}
          >
            {`품종 : ${speciesList.map((s) => s.name).join(", ")}`}
          </FilterChip>
        ) : (
          <FilterChip
            size="S"
            onClick={() => {}}
            suffixIcon="/icons/navigation/arrow_drop_down.svg"
            suffixIconSize={18}
          >
            품종
          </FilterChip>
        )
      }
      renderOptions={({ close }) => (
        <DropdownWithSearch
          keyword={keyword}
          setKeyword={setKeyword}
          resetSelect={() => setSpeciesFilter([])}
          removeFilter={() => {
            removeFilter();
            close();
          }}
        >
          {(keyword ? filteredSpeciesOptions : options).map((i) => (
            <DropdownWithSearch.ListItem
              key={i.id}
              selected={!!speciesList.find((s) => s.id === i.id)}
              handleClick={() => {
                let newSpecies = [...speciesList];

                if (speciesList.find((s) => s.id === i.id)) {
                  newSpecies = newSpecies.filter((s) => s.id !== i.id);
                } else {
                  newSpecies.push(i);
                }

                setSpeciesFilter(newSpecies);
              }}
            >
              <Text t-14-500-s8>{i.name}</Text>
            </DropdownWithSearch.ListItem>
          ))}
          {keyword && filteredSpeciesOptions.length <= 0 && (
            <DropdownWithSearch.EmptySearchResult />
          )}
        </DropdownWithSearch>
      )}
    />
  );
};

export default SpeciesFilter;
