import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useCount } from "../../../../providers/count";
import { usePartner } from "../../../../providers/partner";
import { useSchedules } from "../../../../providers/schedules";
import { useStaffs } from "../../../../providers/staffs";
import {
  Attachment,
  DetailsType,
  Guardian,
  MEMO_STATUS_VALUE,
  MEMO_STATUS_VALUES,
  Memo,
  MemoType,
  Pet,
  Product,
  Reservation,
  Service,
  Staff,
} from "../../../../providers/types";
import { pushPopup, usePopups } from "../../../../providers/popups";
import { useInquiries } from "../../../../providers/inquiries";

import api from "../../../../common/api";
import { getNearestNextMinInter30 } from "../../../../common/utils/reservation";
import Spinner from "../../../../common/Spinner/Spinner";
import {
  RESERVATION_MAX_DATE,
  RESERVATION_MIN_DATE,
} from "../../../../common/constants";
import { useAmplitudeTrack } from "../../../../common/useAmplitudeTrack";

import VFlex from "../../../../layouts/VFlex";
import HFlex from "../../../../layouts/HFlex";
import Image from "../../../../layouts/Image";
import Text from "../../../../layouts/Text";
import Divider from "../../../../layouts/Divider";
import Flex from "../../../../layouts/Flex";
import TextArea from "../../../../layouts/TextareaV2";
import Button from "../../../../layouts/ButtonV2";
import Absolute from "../../../../layouts/Absolute";
import FilterChip from "../../../../layouts/FilterChip";
import TextInput from "../../../../layouts/TextInput";

import { PopupAlign, PopupKind } from "../../../../popup/Popup";
import ConfirmPopup from "../../../../popup/ConfirmPopup";

import FilesUpload from "../../../../components/FilesUpload";
import GroupRadioButton from "../../../../components/GroupRadioButton";
import StaffDropdown from "../../../../components/StaffDropdown";
import Dropdown from "../../../../components/Dropdown";
import DateTimePickerContainer from "../../../../components/DateTimePicker";
import PhotosSwiperPopup from "../../../../components/PhotosSwiperPopup";

import AddScheduleCustomerV2 from "../AddScheduleCustomerV2";
import MenuSelect from "../MenuSelectV2";

import _, { isNil } from "lodash";
import toast from "react-hot-toast";
import {
  addHours,
  addMinutes,
  format,
  isSameDay,
  setDate,
  setHours,
  setMinutes,
  setMonth,
  setYear,
} from "date-fns";
import { ko } from "date-fns/locale";
import CopyToClipboard from "react-copy-to-clipboard";

const MEMO_OPTIONS = ["스타일 메모", "컨디션 메모", "예약 메모"];

interface LocationState {
  newReservation: {
    startDateTime: Date;
    endDateTime: Date;
    selectedCustomer?: Pet;
    selectedProducts?: Service[];
    selectedStaffs: Staff[];
    memos: Memo<keyof typeof MemoType>[];
    files: File[];
  };
}

export interface InquiryData {
  id: number;
  inquiredDateTime: number;
  reservationDate: number;
  time: string[];
  products: Product[];
}

function NewReservation({
  type,
  popPopup,
  reservationId,
  selectedPet,
  initialStaffs,
  startTime,
  fetchCurrentPage,
  inquiryData,
  ...props
}: {
  type?: string;
  popPopup: () => void;
  reservationId?: number;
  initialStaffs?: Staff[];
  selectedPet?: Pet;
  startDateTime?: Date;
  endDateTime?: Date;
  startTime?: Date;
  selectedCustomer?: Pet;
  inquiryData?: InquiryData;
  fetchCurrentPage: () => void;
}) {
  const { trackClickSaveReservationSchedule, trackEditReservationSchedule } =
    useAmplitudeTrack();

  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as LocationState | null;
  const newReservation = state?.newReservation;
  const [toCalendar, setToCalendar] = useState(false);

  useEffect(() => {
    if (toCalendar)
      navigate("/main/reservation/create", {
        state: {
          newReservation: {
            startDateTime,
            endDateTime,
            selectedCustomer,
            selectedProducts,
            selectedStaffs,
            selectedGuardian:
              selectedGuardian ?? selectedCustomer?.guardianList[0],
            memos,
            files,
          },
        },
      });

    return () => {
      if (!toCalendar) navigate("", { replace: true });
      popPopup();
    };
  }, [toCalendar]);

  const { partner } = usePartner();
  const { staffs } = useStaffs();
  const { fetchCount } = useCount();
  const { closeAllPopup } = usePopups();
  const [editingSchedule, setEditingSchedule] = useState<Reservation>();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedStaffs, setSelectedStaffs] = useState<Staff[] | undefined>(
    newReservation?.selectedStaffs ?? initialStaffs
  );

  const [startDateTime, setStartDateTime] = useState(() => {
    if (inquiryData?.reservationDate) {
      return new Date(inquiryData.reservationDate * 1000);
    }

    return (
      newReservation?.startDateTime ??
      (props.startDateTime || getNearestNextMinInter30(new Date()))
    );
  });

  const [endDateTime, setEndDateTime] = useState(
    newReservation?.endDateTime ??
      (props.endDateTime || addHours(getNearestNextMinInter30(new Date()), 1))
  );
  const [inquiryVisible, setInquiryVisible] = useState(true);

  const [selectedProducts, setSelectedProducts] = useState<Service[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<Pet | undefined>(
    newReservation?.selectedCustomer ?? (selectedPet || props.selectedCustomer)
  );
  const [selectedGuardian, setSelectedGuardian] = useState<
    Guardian | undefined
  >();
  const [startDateTimeChanged, setStartDateTimeChanged] = useState(false);
  const [endDateTimeChanged, setEndDateTimeChanged] = useState(false);
  const [dateTimeError, setDateTimeError] = useState(false);

  const [memos, setMemos] = useState<Memo<keyof typeof MemoType>[]>([]);
  const [prevMemos, setPrevMemos] = useState<Memo<keyof typeof MemoType>[]>([]);
  const [photos, setPhotos] = useState<Attachment[]>([]);
  const [prevPhotos, setPrevPhotos] = useState<Attachment[]>([]);
  const [deletedPhotoId, setDeletedPhotoId] = useState<number[]>([]);

  useEffect(() => {
    const fetchMemos = async () => {
      let memoList = [];

      if (reservationId) {
        const memoResponse = await api.get(
          `/rest/v2/reservation/${reservationId}/note`
        );

        memoList = Object.keys(MemoType).map((type, index) => {
          const data = memoResponse.find((r: any) => r.type.value === type);

          return {
            type: data?.type?.value ?? type,
            note: data?.note,
            value: data?.value?.value,
            displayOrder: index,
            attachments: data?.attachments,
          };
        });
      } else {
        memoList = Object.keys(MemoType).map((type, index) => ({
          type: type as keyof typeof MemoType,
          note: undefined,
          value: undefined,
          displayOrder: index,
          attachments: [],
        }));
      }

      setMemos(newReservation?.memos || memoList);
      setPrevMemos(newReservation?.memos || memoList);

      const requestIndex = memoList.findIndex((m) => m.type === "REQUEST");
      if (memoList[requestIndex]?.attachments) {
        setPhotos(memoList[requestIndex].attachments);
        setPrevPhotos(memoList[requestIndex].attachments);
      }
    };

    fetchMemos();
  }, [reservationId, newReservation]);

  useEffect(() => {
    const result = prevPhotos.filter((value) => !photos.includes(value));
    setDeletedPhotoId(result.map((r) => r.id));
  }, [photos]);

  const [files, setFiles] = useState<File[]>(newReservation?.files || []);
  const [prevFiles, setPrevFiles] = useState<File[]>(
    newReservation?.files || []
  );
  const removeFile = (idx: number) => {
    const newFiles =
      files &&
      files.filter((file, i) => {
        return idx !== i;
      });
    setFiles(newFiles);
  };

  useEffect(() => {
    setPrevFiles([]);
  }, []);

  const [newPetMemo, setNewPetMemo] = useState<string | undefined>(undefined);
  const savePetMemo = async () => {
    try {
      if (!selectedCustomer) return;

      await api.put(
        `/rest/v2/pet/${selectedCustomer.id}/single?groupId=${
          partner.id
        }&type=NOTE&value=${
          (newPetMemo && encodeURIComponent(newPetMemo)) || null
        }`
      );
      toast.success("변경사항이 저장되었습니다.");

      setSelectedCustomer((customer) => {
        const newCustomer = JSON.parse(JSON.stringify(customer));
        newCustomer!.note = newPetMemo;

        return newCustomer;
      });
    } catch (e) {}
  };

  const loadSchedule = useCallback(async () => {
    const reservation = await api.get<Reservation>(
      `/rest/reservation/${reservationId}?groupId=${partner.id}`
    );
    if (reservation) {
      setSelectedStaffs(reservation.staffs);
      setStartDateTime(
        new Date([reservation.date, reservation.startTime].join(" "))
      );
      setEndDateTime(
        new Date([reservation.date, reservation.endTime].join(" "))
      );
      setSelectedProducts(reservation.products);
      setSelectedCustomer({
        id: reservation.petId,
        name: reservation.pet.petInfo.name,
        species: reservation.pet.petInfo.species.name,
        note: reservation.pet.petInfo.note,
        attachment: reservation.pet.petInfo.attachment,
        guardianList: [reservation.guardian],
      });
      setNewPetMemo(reservation.pet.petInfo.note);
      setEditingSchedule({ ...reservation });
      setLoading(false);
    }
  }, [partner, reservationId]);

  useEffect(() => {
    if (reservationId) {
      setLoading(true);
      loadSchedule();
    }
  }, [loadSchedule, reservationId]);

  const [isStaff, setIsStaff] = useState<DetailsType>();
  const fetchDetils = async () => {
    const res = await api.get(
      `/rest/group/${partner.id}/details?type=CALENDAR_S`
    );
    setIsStaff(res[0]);
  };
  useEffect(() => {
    fetchDetils();
    if (inquiryData && selectedCustomer) {
      setNewPetMemo(selectedCustomer.note);
    }
  }, []);

  const isCompleted = useCallback(() => {
    return (
      !dateTimeError &&
      !!selectedCustomer &&
      selectedProducts.length > 0 &&
      (isStaff?.value === "FALSE" &&
      !!(selectedStaffs?.length === 0 || selectedStaffs === undefined)
        ? false
        : true)
    );
  }, [dateTimeError, selectedCustomer, selectedProducts, selectedStaffs]);

  const isUserNotifiedFieldsChanged = useCallback(() => {
    if (editingSchedule) {
      return (
        format(startDateTime, "yyyy-MM-dd") !== editingSchedule.date ||
        format(startDateTime, "HH:mm") !== editingSchedule.startTime ||
        JSON.stringify(selectedProducts) !==
          JSON.stringify(editingSchedule?.products) ||
        selectedCustomer?.id !== editingSchedule.petId
      );
    }
  }, [editingSchedule, startDateTime, selectedProducts, selectedCustomer]);

  const isChanged = useCallback(() => {
    if (editingSchedule) {
      return (
        JSON.stringify(selectedStaffs) !==
          JSON.stringify(editingSchedule.staffs) ||
        format(startDateTime, "yyyy-MM-dd") !== editingSchedule.date ||
        format(startDateTime, "HH:mm") !== editingSchedule.startTime ||
        format(endDateTime, "HH:mm") !== editingSchedule.endTime ||
        JSON.stringify(selectedProducts) !==
          JSON.stringify(editingSchedule?.products) ||
        selectedCustomer?.id !== editingSchedule.petId ||
        JSON.stringify(prevMemos) !== JSON.stringify(memos) ||
        prevPhotos.length !== photos.length ||
        prevFiles.length !== files.length
      );
    } else {
      return (
        !startDateTime ||
        !endDateTime ||
        !!selectedCustomer ||
        selectedProducts.length > 0 ||
        JSON.stringify(prevMemos) === JSON.stringify(memos) ||
        prevPhotos.length === photos.length ||
        prevFiles.length === files.length
      );
    }
  }, [
    startDateTime,
    endDateTime,
    selectedCustomer,
    selectedProducts,
    editingSchedule,
    selectedStaffs,
    prevMemos,
    memos,
    prevPhotos,
    photos,
    prevFiles,
    files,
  ]);

  const [selectedMemoTab, setSelectedMemoTab] = useState<string>(
    MEMO_OPTIONS[0]
  );

  const timeGap = endDateTime.getTime() - startDateTime.getTime();
  const timeDiffInMinutes = timeGap / (1000 * 60);

  const [durationTime, setDurationTime] = useState<number>();

  useEffect(() => {
    setDurationTime(
      selectedProducts
        ? selectedProducts
            .map((product) => product.product.duration * product.quantity)
            .reduce((a, c) => {
              return a + c;
            }, 0)
        : 0
    );
  }, [selectedProducts]);

  const handleChangeStartDate = (sdt: Date) => {
    if (
      (!endDateTimeChanged && !startDateTimeChanged) ||
      (!endDateTimeChanged && startDateTimeChanged)
    ) {
      setEndDateTime((end) => {
        end = setYear(end, sdt.getFullYear());
        end = setMonth(end, sdt.getMonth());
        end = setDate(end, sdt.getDate());

        return end;
      });
    } else {
      // 에러 케이스추가
      setEndDateTime(new Date(sdt.getTime() + timeGap));
    }

    setStartDateTime(sdt);
  };

  useEffect(() => {
    if (startDateTime.getTime() >= endDateTime.getTime()) {
      setDateTimeError(true);
    } else {
      setDateTimeError(false);
    }
  }, [startDateTime, endDateTime]);

  useEffect(() => {
    if (props.startDateTime && props.endDateTime) {
      setStartDateTimeChanged(true);
      setEndDateTimeChanged(true);
    }
  }, []);

  useEffect(() => {
    if (!selectedCustomer) setNewPetMemo(undefined);
    else setNewPetMemo(selectedCustomer.note);
  }, [selectedCustomer]);

  const { fetchSchedules } = useSchedules();
  const { fetchInquiries } = useInquiries();

  const popSchedulePopup = useCallback(
    async (toastText: string) => {
      await fetchSchedules(startDateTime);
      if (inquiryData) {
        await fetchInquiries({ page: 0 });
        await closeAllPopup();
      }
      if (fetchCurrentPage) fetchCurrentPage();
      toast.success(toastText);
      popPopup();
    },
    [fetchSchedules, popPopup, startDateTime]
  );

  useEffect(() => {
    const isDifferentDate = startDateTime.getDate() !== endDateTime.getDate();

    if (isDifferentDate) {
      const newEndDateTime = new Date(startDateTime);
      newEndDateTime.setHours(23, 55);
      setEndDateTime(newEndDateTime);
    }
  }, [startDateTime, endDateTime]);

  const [fixAutoDuration, setFixAutoDuration] = useState(false);

  useEffect(() => {
    if (startDateTimeChanged || endDateTimeChanged) {
      setFixAutoDuration(true);
    }
  }, [startDateTime, endDateTime]);

  return (
    <VFlex
      a-st
      style={{
        height: "95vh",
        minHeight: "600px",
        maxHeight: "850px",
        maxWidth: "1024px",
        width: "95vw",
      }}
      ovf-h
    >
      <HFlex p-24 a-c>
        {reservationId ? (
          <>
            <HFlex a-c g-16>
              <Image
                onClick={() => {
                  if (isChanged()) {
                    pushPopup({
                      kind: PopupKind.Popup,
                      align: PopupAlign.TopCenter,
                      element: ConfirmPopup,
                      props: {
                        title: "변경사항을 저장하지 않고 닫을까요?",
                        onConfirm: () => {
                          popPopup();
                          fetchCurrentPage();
                        },
                      },
                    });
                  } else {
                    popPopup();
                  }
                }}
                src={"/icons/navigation/arrow_back_ios.svg"}
                size={24}
                clickable
              />
              <Text t-22-600-s8>예약 일정 수정</Text>
            </HFlex>
          </>
        ) : (
          <>
            <Text t-22-600-s8>새로운 예약</Text>
            <Flex f-1 />
            <HFlex g-24>
              {/* <Image
                onClick={() => {
                  setToCalendar(true);
                }}
                src={"/icons/action/move_to_left_s6.svg"}
                size={24}
                clickable
              /> */}
              <Image
                src={"/icons/icon_close_s6.svg"}
                size={24}
                clickable
                onClick={() => {
                  pushPopup({
                    kind: PopupKind.Popup,
                    align: PopupAlign.TopCenter,
                    element: ConfirmPopup,
                    props: {
                      title: "변경사항을 저장하지 않고 닫을까요?",
                      onConfirm: () => {
                        popPopup();
                      },
                    },
                  });
                }}
              />
            </HFlex>
          </>
        )}
      </HFlex>
      <Divider height={1} />

      {loading ? (
        <VFlex c-c f-1>
          <Spinner />
        </VFlex>
      ) : (
        <HFlex f-1 a-st ovf-h>
          <VFlex width={376} style={{ boxSizing: "border-box" }}>
            <VFlex ovf-a height={"100%"}>
              <AddScheduleCustomerV2
                popPopup={popPopup}
                editingReservation={editingSchedule}
                selectedCustomer={selectedCustomer}
                selectedGuardian={selectedGuardian}
                setSelectedCustomer={setSelectedCustomer}
                setSelectedGuardian={setSelectedGuardian}
                loadSchedule={loadSchedule}
                fetchCurrentPage={fetchCurrentPage}
                inquiryData={inquiryData}
                newPetMemo={newPetMemo}
                setNewPetMemo={setNewPetMemo}
                savePetMemo={savePetMemo}
                reservationId={reservationId}
              />
            </VFlex>
          </VFlex>

          {/* 오른쪽 */}
          <VFlex f-1 ovf-a bc-t2>
            <VFlex p-24 g-12>
              {/* TODO */}
              {/* {selectedCustomer && (
                <HFlex g-16 bc-w p-24 bdr-16 a-c j-b>
                  <VFlex>
                    <Text t-16-600-s8>최근 서비스 기록을 불러올까요?</Text>
                    <Text t-14-s6 l-1>
                      {"가위컷"}
                    </Text>
                  </VFlex>
                  <HFlex j-b a-c g-16>
                    <HFlex g-8>
                      <VFlex
                        p-16-rl
                        p-8-tb
                        bc-t2
                        bdr-12
                        t-14-600-s8
                        clickable
                        onClick={() => {}}
                      >
                        불러오기
                      </VFlex>
                      <VFlex
                        p-16-rl
                        p-8-tb
                        bc-t2
                        bdr-12
                        t-14-600-s8
                        clickable
                        onClick={() => {}}
                      >
                        다른 서비스
                      </VFlex>
                    </HFlex>
                    <Image src={`/icons/navigation/close_s3.svg`} size={24} />
                  </HFlex>
                </HFlex>
              )} */}

              {inquiryData && (
                <VFlex g-16 bc-w p-24 bdr-16>
                  <HFlex g-12 a-c j-b>
                    <Text t-20-600-s8>문의 정보</Text>
                    <Image
                      src={`/icons/navigation/expand_${
                        inquiryVisible ? "less" : "more"
                      }_s3.svg`}
                      size={24}
                      clickable
                      onClick={() => {
                        setInquiryVisible((prev) => !prev);
                      }}
                    />
                  </HFlex>
                  <VFlex
                    g-8
                    style={{
                      display: inquiryVisible ? "flex" : "none",
                    }}
                  >
                    <HFlex g-16>
                      <Flex t-14-500-s4 width={100}>
                        문의 일시
                      </Flex>
                      <Flex t-14-s6 f-1>
                        {format(
                          new Date(inquiryData.inquiredDateTime * 1000),
                          "M월 d일 HH:mm",
                          {
                            locale: ko,
                          }
                        )}
                      </Flex>
                    </HFlex>
                    <HFlex g-16>
                      <Flex t-14-500-s4 width={100}>
                        희망 예약 날짜
                      </Flex>
                      <Flex t-14-s6 f-1>
                        {format(
                          new Date(inquiryData.reservationDate * 1000),
                          "yyyy년 M월 d일 (EEEEE)",
                          {
                            locale: ko,
                          }
                        )}
                      </Flex>
                    </HFlex>
                    <HFlex g-16>
                      <Flex t-14-500-s4 width={100}>
                        희망 시작 시간
                      </Flex>
                      <Flex
                        t-14-s6
                        f-1
                        style={{
                          whiteSpace: "pre-wrap",
                          wordBreak: "break-word",
                        }}
                      >
                        {inquiryData.time.map((item) => item).join(", ")}
                      </Flex>
                    </HFlex>
                    <HFlex g-16>
                      <Flex t-14-500-s4 width={100}>
                        희망 서비스
                      </Flex>
                      <Flex
                        t-14-s6
                        f-1
                        style={{
                          whiteSpace: "pre-wrap",
                          wordBreak: "break-word",
                        }}
                      >
                        {inquiryData.products.map((p) => p.name).join(", ")}
                      </Flex>
                    </HFlex>
                  </VFlex>
                </VFlex>
              )}

              <VFlex g-16 bc-w p-24 bdr-16>
                <VFlex f-1 g-8>
                  <Dropdown
                    placeholder="서비스를 선택하세요"
                    isDisabled={true}
                    value={selectedProducts.length > 0 ? 0 : undefined}
                    options={[
                      {
                        value: 0,
                        label: selectedProducts
                          .map(
                            (product) =>
                              `${product.product.name}${
                                product.quantity > 1
                                  ? `*${product.quantity}`
                                  : ""
                              }`
                          )
                          .join(", "),
                      },
                    ]}
                    caption={"서비스명"}
                    onClick={() => {
                      pushPopup({
                        kind: PopupKind.Popup,
                        element: MenuSelect,
                        options: {
                          backdropColor: "none",
                        },
                        dimClick: "none",
                        props: {
                          selectedProducts: selectedProducts,
                          onSubmit: (products: Service[]) => {
                            const newDuration = products.reduce(
                              (prev, cur) =>
                                prev + cur.product.duration * cur.quantity,
                              0
                            );
                            let MAX_EDT = startDateTime;
                            MAX_EDT = setHours(MAX_EDT, 23);
                            MAX_EDT = setMinutes(MAX_EDT, 55);

                            // 예약 수정, 드래그로 예약 등록이 아닐때만 적용
                            if (
                              !reservationId &&
                              type !== "drag" &&
                              !fixAutoDuration
                            ) {
                              if (
                                selectedProducts.length === 0 &&
                                !startDateTimeChanged &&
                                !endDateTimeChanged
                              ) {
                                let newEDT = addMinutes(
                                  startDateTime,
                                  newDuration
                                );

                                if (newDuration === 0) {
                                  newEDT = addMinutes(newEDT, 5);
                                }

                                // 종료시간 24시 넘어가지 않도록 임시처리
                                if (
                                  newEDT.getTime() > MAX_EDT.getTime() &&
                                  startDateTime.getTime() === MAX_EDT.getTime()
                                ) {
                                  setStartDateTime(
                                    new Date(
                                      MAX_EDT.getTime() - newDuration * 60000
                                    )
                                  );
                                  setEndDateTime(MAX_EDT);
                                } else if (
                                  newEDT.getTime() > MAX_EDT.getTime()
                                ) {
                                  setEndDateTime(MAX_EDT);
                                } else {
                                  setEndDateTime(newEDT);
                                }
                              } else if (
                                selectedProducts.length === 0 &&
                                (startDateTimeChanged || endDateTimeChanged)
                              ) {
                              } else {
                                const prevDuration = selectedProducts.reduce(
                                  (prev, cur) =>
                                    prev + cur.product.duration * cur.quantity,
                                  0
                                );

                                let diffDuration = newDuration - prevDuration;
                                let newEDT = addMinutes(
                                  startDateTime,
                                  newDuration
                                );

                                if (newDuration === 0) {
                                  newEDT = addMinutes(newEDT, 5);
                                }

                                if (
                                  newEDT.getTime() > MAX_EDT.getTime() &&
                                  startDateTime.getTime() === MAX_EDT.getTime()
                                ) {
                                  setStartDateTime(
                                    new Date(
                                      MAX_EDT.getTime() - newDuration * 60000
                                    )
                                  );
                                  setEndDateTime(MAX_EDT);
                                } else if (
                                  newEDT.getTime() > MAX_EDT.getTime()
                                ) {
                                  setEndDateTime(MAX_EDT);
                                } else {
                                  setEndDateTime(newEDT);
                                }

                                const formatDuration = (duration: number) => {
                                  const absDuration = Math.abs(duration);
                                  return absDuration >= 60
                                    ? `${Math.floor(absDuration / 60)}시간 ${
                                        absDuration % 60 === 0
                                          ? ""
                                          : (absDuration % 60) + "분"
                                      }`
                                    : `${absDuration}분`;
                                };

                                if (diffDuration !== 0) {
                                  const message = `예상 소요 시간이 ${formatDuration(
                                    diffDuration
                                  )} ${
                                    diffDuration > 0 ? "추가" : "감소"
                                  }되었습니다.`;
                                  toast.success(message);
                                }
                              }
                            }

                            setSelectedProducts(products);
                          },
                        },
                        width: 950,
                      });
                    }}
                    required={true}
                    controlBgColor="#fff"
                  />
                  {selectedProducts && durationTime! > 0 && (
                    <div>
                      <Text p-2-tb p-8-rl bc-t2 bdr-8 t-14-s6>
                        예상 소요 시간 :
                        {durationTime! >= 60
                          ? ` ${Math.floor(durationTime! / 60)}시간 ${
                              durationTime! % 60 === 0
                                ? ""
                                : (durationTime! % 60) + "분"
                            }`
                          : ` ${durationTime}분`}
                      </Text>
                    </div>
                  )}
                </VFlex>
                <VFlex f-1 g-4>
                  <HFlex>
                    <Text t-14-500-s6 m-4-r>
                      예약 일시
                    </Text>
                    <Text t-14-500-r1>*</Text>
                  </HFlex>

                  <VFlex g-8>
                    <DateTimePickerContainer
                      isRange
                      minDate={RESERVATION_MIN_DATE}
                      maxDate={RESERVATION_MAX_DATE}
                      showReservationCount
                      startDateTime={startDateTime}
                      setStartDateTime={(dt) => {
                        handleChangeStartDate(dt);
                        setStartDateTimeChanged(true);
                        if (!isSameDay(dt, endDateTime)) {
                          const newEDT = endDateTime;
                          newEDT.setFullYear(dt.getFullYear());
                          newEDT.setMonth(dt.getMonth());
                          newEDT.setDate(dt.getDate());

                          setEndDateTime(newEDT);
                        }
                      }}
                      endDateTime={endDateTime}
                      setEndDateTime={(dt) => {
                        setEndDateTime(dt);
                        setEndDateTimeChanged(true);
                        if (!isSameDay(dt, startDateTime)) {
                          const newEDT = startDateTime;
                          newEDT.setFullYear(dt.getFullYear());
                          newEDT.setMonth(dt.getMonth());
                          newEDT.setDate(dt.getDate());

                          setStartDateTime(newEDT);
                        }
                      }}
                      renderTrigger={() => (
                        <HFlex
                          p-12-tb
                          p-16-rl
                          bd-t5={!dateTimeError}
                          bd-r1={dateTimeError}
                          bdr-16
                          j-b
                        >
                          <Text t-16-500-s8 f-1>{`${format(
                            startDateTime,
                            "yyyy년 M월 d일 (E) a hh:mm",
                            { locale: ko }
                          )} ~ ${format(
                            endDateTime,
                            isSameDay(startDateTime, endDateTime)
                              ? "a hh:mm"
                              : "yyyy년 M월 d일 (E) a hh:mm",
                            {
                              locale: ko,
                            }
                          )}`}</Text>
                          <Image
                            src="/icons/navigation/arrow_drop_down_s3.svg"
                            size={22}
                            clickable
                          />
                        </HFlex>
                      )}
                    />
                    {timeDiffInMinutes < durationTime! && (
                      <HFlex
                        a-c
                        p-16
                        g-12
                        bdr-12
                        style={{
                          border: `1px solid rgba(255, 195, 94, 0.60)`,
                          background: `rgba(255, 195, 94, 0.10)`,
                        }}
                      >
                        <Image size={22} src={`/icons/action/info_s6.svg`} />
                        <Text t-14-s7>
                          예상 소요 시간보다 종료 시간이 빠릅니다.
                        </Text>
                      </HFlex>
                    )}
                  </VFlex>

                  {dateTimeError && (
                    <Text t-14-500-r1>
                      일정 종료는 시작 시간 이후여야 합니다.
                    </Text>
                  )}
                </VFlex>
                <VFlex f-1>
                  <StaffDropdown
                    placeholder={
                      isStaff?.value === "FALSE"
                        ? "담당자를 선택하세요"
                        : "미지정"
                    }
                    caption={"담당자"}
                    value={selectedStaffs?.map((s) => s.id.toString())}
                    options={staffs?.map((staff) => ({
                      value: staff.id.toString(),
                      obj: staff,
                      label: `${staff.name} ${staff.jobTitle}`,
                    }))}
                    onChange={async (values) => {
                      setSelectedStaffs(values?.map((value) => value.obj));
                    }}
                    required={true}
                  />
                </VFlex>
              </VFlex>

              <VFlex g-16 bc-w p-24-tb bdr-16>
                <MemoSection
                  reservationId={reservationId ?? 0}
                  selectedMemoTab={selectedMemoTab}
                  setSelectedMemoTab={setSelectedMemoTab}
                  memos={memos}
                  setMemos={setMemos}
                  photos={photos}
                  setPhotos={setPhotos}
                  files={files ?? []}
                  setFiles={setFiles}
                  removeFile={removeFile}
                />
              </VFlex>

              {selectedCustomer && (
                <VFlex g-16 bc-w p-24 bdr-16>
                  <HFlex g-12 a-c>
                    <Text t-20-600-s8>예약자 정보</Text>
                  </HFlex>
                  <VFlex g-16>
                    <HFlex j-b a-c bd-b-t2 p-12-tb>
                      <HFlex g-8 a-c>
                        <Text t-16-s8>
                          {selectedGuardian
                            ? selectedGuardian.name
                            : selectedCustomer.guardianList[0].name}
                        </Text>
                        {selectedGuardian
                          ? selectedGuardian.primaryGuardian && (
                              <VFlex t-14-s6 p-2-tb p-8-rl bd-t4 bdr-8>
                                대표 보호자
                              </VFlex>
                            )
                          : selectedCustomer.guardianList[0]
                              .primaryGuardian && (
                              <VFlex t-14-s6 p-2-tb p-8-rl bd-t4 bdr-8>
                                대표 보호자
                              </VFlex>
                            )}
                      </HFlex>
                      <CopyToClipboard
                        text={
                          selectedGuardian
                            ? selectedGuardian.cellnumber
                            : selectedCustomer.guardianList[0].cellnumber
                        }
                        onCopy={() => {
                          toast.success("전화번호가 복사되었습니다.");
                        }}
                      >
                        <HFlex g-16 a-c clickable>
                          <Text t-14-s6>
                            {selectedGuardian
                              ? selectedGuardian.cellnumber
                              : selectedCustomer.guardianList[0].cellnumber}
                          </Text>
                          <Image size={22} src={`/icons/content/copy_s6.svg`} />
                        </HFlex>
                      </CopyToClipboard>
                    </HFlex>

                    {selectedGuardian ? (
                      selectedGuardian.note ? (
                        <Text t-14-s6 l-m>
                          {selectedGuardian.note}
                        </Text>
                      ) : (
                        <Text t-14-s2>작성된 내용이 없습니다.</Text>
                      )
                    ) : selectedCustomer.guardianList[0].note ? (
                      <Text t-14-s6 l-m>
                        {selectedCustomer.guardianList[0].note}
                      </Text>
                    ) : (
                      <Text t-14-s2>작성된 내용이 없습니다.</Text>
                    )}
                  </VFlex>
                </VFlex>
              )}
            </VFlex>
          </VFlex>
        </HFlex>
      )}

      <VFlex
        a-e
        p-24-rl
        j-c
        height={104}
        style={{
          boxShadow:
            "0px -1px 2px 0px rgba(0, 0, 0, 0.08), 0px -2px 12px 1px rgba(0, 0, 0, 0.08)",
          zIndex: 1,
        }}
      >
        <HFlex g-8>
          {/* TODO */}
          {/* <Button size="M" type="grey_outlined" onClick={() => null}>
            임시 저장
          </Button> */}
          <Button
            size="M"
            type="filled"
            enabled={
              !loading && editingSchedule && !dateTimeError
                ? isChanged()
                : isCompleted()
            }
            onClick={async () => {
              if (loading || !(editingSchedule ? isChanged() : isCompleted())) {
                return;
              }
              if (editingSchedule) {
                const save = async () => {
                  const formData = new FormData();
                  let memoCnt = 0;
                  memos.forEach((memo, index) => {
                    if (
                      (!isNil(memo.value) && !isNaN(memo.value)) ||
                      memo.note
                    ) {
                      formData.append(`notes[${memoCnt}].type`, memo.type);
                      if (!isNil(memo.value) && !isNaN(memo.value)) {
                        formData.append(
                          `notes[${memoCnt}].condition`,
                          MEMO_STATUS_VALUES[memo.value]
                        );
                      }

                      if (
                        memo.note &&
                        ((!isNil(memo.value) &&
                          !isNaN(memo.value) &&
                          memo.value == 2) ||
                          !memo.type.startsWith("C_"))
                      ) {
                        formData.append(`notes[${memoCnt}].note`, memo.note);
                      }
                      memoCnt++;
                    }
                  });

                  const requestIndex = !memos.find((v) => v.type === "REQUEST")
                    ?.note
                    ? memoCnt
                    : memos.findIndex((v) => v.type === "REQUEST");

                  formData.append(`notes[${requestIndex}].type`, "REQUEST");

                  if (files.length > 0) {
                    files.forEach((file, index) => {
                      formData.append(
                        `notes[${requestIndex}].files[${index}]`,
                        file
                      );
                    });
                  }

                  if (deletedPhotoId.length > 0) {
                    deletedPhotoId.forEach((id, index) => {
                      formData.append(
                        `notes[${requestIndex}].deletedAttachmentIds[${index}]`,
                        id.toString()
                      );
                    });
                  }

                  selectedStaffs?.forEach((staff, index) => {
                    formData.append(
                      `userIdList[${index}]`,
                      staff.id.toString()
                    );
                  });

                  selectedProducts.forEach((product, index) => {
                    formData.append(
                      `products[${index}].product.id`,
                      product.product.id.toString()
                    );
                    formData.append(
                      `products[${index}].quantity`,
                      product.quantity.toString()
                    );
                  });

                  const data = {
                    id: editingSchedule.id,
                    date: format(startDateTime!, "yyyy-MM-dd"),
                    startTime: format(startDateTime, "HH:mm"),
                    endTime: format(endDateTime, "HH:mm"),
                    petId: selectedCustomer!.id,
                    guardianId: selectedCustomer!.guardianList[0].id,
                    prepaid: 0,
                    ...Object.fromEntries(formData),
                  };

                  await api.post(
                    `/rest/v2/reservation/group/${partner.id}/update`,
                    data,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    }
                  );

                  if (fetchCurrentPage) fetchCurrentPage();

                  trackEditReservationSchedule({
                    reservationId: reservationId!,
                    date: format(startDateTime!, "yyyy-MM-dd"),
                    startTime: format(startDateTime, "HH:mm"),
                    endTime: format(endDateTime, "HH:mm"),
                    petId: selectedCustomer!.id,
                    products: selectedProducts,
                    userIdList: selectedStaffs?.map((staff) => staff.id),
                    guardianId: selectedCustomer!.guardianList[0].id,
                    ...Object.fromEntries(formData),
                  });
                };

                if (isUserNotifiedFieldsChanged()) {
                  pushPopup({
                    kind: PopupKind.Popup,
                    align: PopupAlign.TopCenter,
                    element: ConfirmPopup,
                    props: {
                      title: `예약을 변경할까요?\n날짜, 서비스, 시간을 변경하면 고객에게 변경 사항에 대한\n메세지가 전송됩니다.`,
                      icon: "/icons/icon_success.png",
                      confirmButtonLabel: "확인",
                      confirmButtonType: "confirm",
                      onConfirm: async () => {
                        setLoading(true);
                        popPopup();
                        await save();
                        popSchedulePopup("예약이 변경되었습니다.");
                        await fetchCount({
                          date: Math.floor(startDateTime.getTime() / 1000),
                        });
                      },
                    },
                  });
                } else {
                  if (
                    isStaff?.value === "FALSE" &&
                    selectedStaffs?.length === 0
                  ) {
                    return toast.error("담당자를 선택해주세요.");
                  }
                  setLoading(true);
                  await save();
                  popSchedulePopup("예약 정보가 수정되었습니다.");
                }
              } else {
                // 새로운 예약 등록
                const formData = new FormData();
                let memoCnt = 0;
                memos.forEach((memo, index) => {
                  if ((!isNil(memo.value) && !isNaN(memo.value)) || memo.note) {
                    formData.append(`notes[${memoCnt}].type`, memo.type);
                    if (!isNil(memo.value) && !isNaN(memo.value)) {
                      formData.append(
                        `notes[${memoCnt}].condition`,
                        MEMO_STATUS_VALUES[memo.value]
                      );
                    }

                    if (memo.note) {
                      formData.append(`notes[${memoCnt}].note`, memo.note);
                    }
                    memoCnt++;
                  }
                });

                if (files.length > 0) {
                  const requestIndex = isNil(
                    memos.find((v) => v.type === "REQUEST")?.note
                  )
                    ? memoCnt
                    : memos.findIndex((v) => v.type === "REQUEST");

                  files.forEach((file, index) => {
                    formData.append(
                      `notes[${requestIndex}].files[${index}]`,
                      file
                    );
                  });
                  if (requestIndex >= 0) {
                    formData.append(`notes[${requestIndex}].type`, "REQUEST");
                  }
                }

                selectedProducts.forEach((product, index) => {
                  formData.append(
                    `products[${index}].product.id`,
                    product.product.id.toString()
                  );
                  formData.append(
                    `products[${index}].quantity`,
                    product.quantity.toString()
                  );
                });

                const data = {
                  date: format(startDateTime!, "yyyy-MM-dd"),
                  startTime: format(startDateTime, "HH:mm"),
                  endTime: format(endDateTime, "HH:mm"),
                  petId: selectedCustomer!.id,
                  userIdList: selectedStaffs
                    ?.map((staff) => staff.id)
                    .join(","),
                  guardianId: selectedGuardian
                    ? selectedGuardian.id
                    : selectedCustomer!.guardianList[0].id,
                  prepaid: 0,
                  ...Object.fromEntries(formData),
                  // 예약 문의 등록 시
                  ...(inquiryData
                    ? {
                        id: inquiryData.id,
                        inquiredReservationId: inquiryData.id,
                      }
                    : {}),
                };

                pushPopup({
                  kind: PopupKind.Popup,
                  align: PopupAlign.TopCenter,
                  element: ConfirmPopup,
                  props: {
                    title: `예약을 확정할까요?\n고객에게 예약 확정 메세지가 전송됩니다.`,
                    icon: "/icons/icon_success.png",
                    confirmButtonLabel: "확인",
                    confirmButtonType: "confirm",
                    onConfirm: async () => {
                      setLoading(true);
                      await api.post(
                        `/rest/v2/reservation/group/${partner?.id}${
                          inquiryData
                            ? `?inquiredReservationId=${inquiryData.id}`
                            : ""
                        }`,
                        data,
                        {
                          headers: {
                            "Content-Type": "multipart/form-data",
                          },
                        }
                      );
                      popSchedulePopup("예약이 확정되었습니다.");
                      await fetchCount({
                        date: Math.floor(startDateTime.getTime() / 1000),
                      });
                      if (fetchCurrentPage) fetchCurrentPage();

                      trackClickSaveReservationSchedule({
                        date: format(startDateTime!, "yyyy-MM-dd"),
                        startTime: format(startDateTime, "HH:mm"),
                        endTime: format(endDateTime, "HH:mm"),
                        petId: selectedCustomer!.id,
                        products: selectedProducts,
                        userIdList: selectedStaffs?.map((staff) => staff.id),
                        guardianId: selectedGuardian
                          ? selectedGuardian!.id
                          : selectedCustomer!.guardianList[0].id,
                        ...Object.fromEntries(formData),
                      });
                    },
                  },
                });
              }
            }}
          >
            {reservationId ? "저장하기" : "예약 등록"}
          </Button>
        </HFlex>
      </VFlex>
    </VFlex>
  );
}

export default NewReservation;

const MemoSection = ({
  reservationId,
  selectedMemoTab,
  setSelectedMemoTab,
  memos,
  setMemos,
  photos,
  setPhotos,
  files,
  setFiles,
  removeFile,
}: {
  reservationId: number;
  selectedMemoTab: string;
  setSelectedMemoTab: any;
  memos: any;
  setMemos: any;
  photos: Attachment[];
  setPhotos: any;
  files: File[];
  setFiles: any;
  removeFile: any;
}) => {
  const [memoVisible, setMemoVisible] = useState(true);

  return (
    <>
      <HFlex p-24-rl g-12 a-c j-b>
        <HFlex g-12 a-c>
          <Text t-20-600-s8>예약 메모</Text>
          <Text t-14-s4>우리 업체만 볼 수 있는 메모입니다.</Text>
        </HFlex>
        <Image
          src={`/icons/navigation/expand_${
            memoVisible ? "less" : "more"
          }_s3.svg`}
          size={24}
          clickable
          onClick={() => {
            setMemoVisible((prev) => !prev);
          }}
        />
      </HFlex>
      <VFlex
        style={{
          display: memoVisible ? "block" : "none",
        }}
      >
        <HFlex g-12 m-8-t m-24-b j-b a-c>
          <HFlex p-24-rl height={34} g-6>
            <FilterChip
              type={
                selectedMemoTab === MEMO_OPTIONS[0] ? "selected" : undefined
              }
              onClick={() => {
                setSelectedMemoTab(MEMO_OPTIONS[0]);
              }}
            >
              스타일 메모
            </FilterChip>
            <FilterChip
              type={
                selectedMemoTab === MEMO_OPTIONS[1] ? "selected" : undefined
              }
              onClick={() => {
                setSelectedMemoTab(MEMO_OPTIONS[1]);
              }}
            >
              컨디션 메모
            </FilterChip>
            <FilterChip
              type={
                selectedMemoTab === MEMO_OPTIONS[2] ? "selected" : undefined
              }
              onClick={() => {
                setSelectedMemoTab(MEMO_OPTIONS[2]);
              }}
            >
              예약 메모
            </FilterChip>
          </HFlex>
        </HFlex>
        <VFlex>
          {selectedMemoTab === MEMO_OPTIONS[0] && (
            <StyleMemoSection memos={memos} setMemos={setMemos} />
          )}
          {selectedMemoTab === MEMO_OPTIONS[1] && (
            <ConditionMemoSection memos={memos} setMemos={setMemos} />
          )}
          {selectedMemoTab === MEMO_OPTIONS[2] && (
            <ResMemoSection
              memos={memos}
              setMemos={setMemos}
              photos={photos}
              setPhotos={setPhotos}
              files={files}
              setFiles={setFiles}
              removeFile={removeFile}
            />
          )}
        </VFlex>
      </VFlex>
    </>
  );
};

const StyleMemoSection = ({
  memos,
  setMemos,
}: {
  memos: Memo<keyof typeof MemoType>[];
  setMemos: (memos: Memo<keyof typeof MemoType>[]) => void;
}) => {
  return (
    <VFlex p-24-rl>
      {memos.slice(7).map((i, index) => (
        <TextArea
          key={i.type}
          caption={MemoType[i.type]}
          placeholder={"메모하기"}
          value={i.note}
          height={72}
          maxLength={800}
          onChangeValue={(v) => {
            const newMemos = [...memos];

            const newMemo: Memo<keyof typeof MemoType> = {
              type: i?.type,
              value: i?.value,
              note: v,
              displayOrder: i.displayOrder,
            };
            newMemos[index + 7] = newMemo;

            if (v?.length || 0 <= 800) setMemos(newMemos);
          }}
        />
      ))}
    </VFlex>
  );
};

const ConditionMemoSection = ({
  memos,
  setMemos,
}: {
  memos: Memo<keyof typeof MemoType>[];
  setMemos: (memos: Memo<keyof typeof MemoType>[]) => void;
}) => {
  return (
    <VFlex p-24-rl g-16>
      {memos.slice(1, 6).map((i, index) => {
        return (
          <VFlex key={i.type} g-16>
            <GroupRadioButton
              value={
                !isNil(i.value)
                  ? {
                      value: Number(i.value),
                      label: MEMO_STATUS_VALUE[i.value],
                    }
                  : undefined
              }
              caption={MemoType[i.type]}
              options={[
                { label: "좋아요", value: 0 },
                { label: "보통", value: 1 },
                { label: "관찰이 필요해요", value: 2 },
              ]}
              onChange={(e) => {
                if (!isNil(e?.value)) {
                  const newMemos = [...memos];

                  const newMemo: Memo<keyof typeof MemoType> = {
                    type: i?.type,
                    value: e?.value,
                    note: i?.note,
                    displayOrder: i.displayOrder,
                  };
                  newMemos[index + 1] = newMemo;

                  setMemos(newMemos);
                } else {
                  const newMemos = [...memos];
                  const newMemo: Memo<keyof typeof MemoType> = {
                    type: memos[index + 1].type,
                    value: undefined,
                    note: memos[index + 1]?.note,
                    displayOrder: memos[index + 1].displayOrder,
                  };
                  newMemos[index + 1] = newMemo;
                  setMemos(newMemos);
                }
              }}
            />
            {i.value == 2 && (
              <TextArea
                value={i.note}
                placeholder={"내용을 입력해주세요."}
                height={120}
                maxLength={800}
                onChangeValue={(v) => {
                  const newMemos = [...memos];

                  const newMemo: Memo<keyof typeof MemoType> = {
                    type: memos[index + 1]?.type,
                    value: memos[index + 1]?.value,
                    note: v,
                    displayOrder: memos[index + 1].displayOrder,
                  };
                  newMemos[index + 1] = newMemo;

                  if (v?.length || 0 <= 800) setMemos(newMemos);
                }}
              />
            )}
          </VFlex>
        );
      })}
      <VFlex key={memos[6]?.type} g-16>
        <GroupRadioButton
          value={
            !isNil(memos[6].value)
              ? {
                  value: Number(memos[6]?.value),
                  label: MEMO_STATUS_VALUE[memos[6]?.value],
                }
              : undefined
          }
          caption={MemoType[memos[6].type]}
          options={[
            { label: "없어요", value: 3 },
            { label: "있어요", value: 4 },
          ]}
          onChange={(e) => {
            if (!isNil(e?.value)) {
              const newMemos = [...memos];

              const newMemo: Memo<keyof typeof MemoType> = {
                type: memos[6]?.type,
                value: e?.value,
                note: memos[6]?.note,
                displayOrder: memos[6].displayOrder,
              };
              newMemos[6] = newMemo;

              setMemos(newMemos);
            } else {
              const newMemos = [...memos];

              const newMemo: Memo<keyof typeof MemoType> = {
                type: memos[6].type,
                value: undefined,
                note: memos[6]?.note,
                displayOrder: memos[6].displayOrder,
              };
              newMemos[6] = newMemo;

              setMemos(newMemos);
            }
          }}
        />
        {memos[6].value == 2 && (
          <TextInput
            value={memos[6].note}
            onChange={(e) => {
              const newMemos = [...memos];

              const newMemo: Memo<keyof typeof MemoType> = {
                type: memos[6]?.type,
                value: memos[6]?.value,
                note: e.target.value,
                displayOrder: memos[6].displayOrder,
              };
              newMemos[6] = newMemo;

              setMemos(newMemos);
            }}
          />
        )}
      </VFlex>
    </VFlex>
  );
};

const ResMemoSection = ({
  memos,
  setMemos,
  photos,
  setPhotos,
  files,
  setFiles,
  removeFile,
}: {
  memos: Memo<keyof typeof MemoType>[];
  setMemos: (memos: Memo<keyof typeof MemoType>[]) => void;
  photos: Attachment[];
  setPhotos: any;
  files: File[];
  setFiles: any;
  removeFile: any;
}) => {
  const pushPhotosSwiperPopup = (index: number) => {
    pushPopup({
      element: PhotosSwiperPopup,
      kind: PopupKind.Swiper,
      props: {
        attachments: files,
        files: files,
        index,
      },
    });
  };

  return (
    <VFlex g-12>
      <VFlex p-24-rl>
        <TextArea
          placeholder="내용을 입력해주세요."
          value={memos[0]?.note}
          onChangeValue={(v) => {
            const newMemos = [...memos];
            newMemos[0] = { ...newMemos[0], note: v };
            if (v?.length || 0 <= 800) setMemos(newMemos);
          }}
          height={120}
          maxLength={800}
        />
      </VFlex>
      <HFlex p-24-l a-c g-8>
        <VFlex bd-t5 bdr-16 a-c j-c sized width={56} height={56}>
          <label
            htmlFor="upload"
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Image src="/icons/action/photo_camera_s6.svg" size={24} />
            <FilesUpload
              id="upload"
              multiple
              onLoad={async (f: File[]) => {
                if (!files) {
                  setFiles(f);
                } else {
                  setFiles([...files!, ...f]);
                }
              }}
            />
          </label>
        </VFlex>
        <HFlex
          p-10-t
          p-8-r
          g-8
          ovf-s-d
          style={{
            marginTop: "-10px",
          }}
        >
          {[...photos, ...files].map((item, i) => {
            const isPhoto = "path" in item;
            return (
              <VFlex key={i} rel>
                <Image
                  width={56}
                  height={56}
                  bdr-16
                  src={isPhoto ? item.path : URL.createObjectURL(item)}
                  clickable
                  onClick={() => {
                    // pushPhotosSwiperPopup(i);
                  }}
                />
                <Absolute
                  top={-3}
                  right={-3}
                  bottom={"auto"}
                  left={"auto"}
                  width={20}
                  height={20}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    zIndex: 99,
                  }}
                  clickable
                  onClick={(e) => {
                    e.preventDefault();
                    if (isPhoto) {
                      setPhotos(photos.filter((p) => p.id !== item.id));
                    } else {
                      removeFile(i - photos.length);
                    }
                  }}
                >
                  <Image src="/icons/delete_photo.svg" size={16} />
                </Absolute>
              </VFlex>
            );
          })}
        </HFlex>
      </HFlex>
    </VFlex>
  );
};
