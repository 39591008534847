import { useState } from "react";

import api from "../../../../common/api";
import {
  NoticeInterface,
  PetInfo,
  Reservation,
  ScheduleStatus,
} from "../../../../providers/types";
import { usePartner } from "../../../../providers/partner";
import { pushPopup } from "../../../../providers/popups";
import { PopupAlign, PopupKind } from "../../../../popup/Popup";
import ConfirmPopup from "../../../../popup/ConfirmPopup";
import PhotosSwiperPopup from "../../../../components/PhotosSwiperPopup";
import SendNotice from "../SendNotice";

import HFlex from "../../../../layouts/HFlex";
import VFlex from "../../../../layouts/VFlex";
import Text from "../../../../layouts/Text";
import Image from "../../../../layouts/Image";
import Flex from "../../../../layouts/Flex";
import Absolute from "../../../../layouts/Absolute";
import ButtonV2 from "../../../../layouts/ButtonV2";

import { Swiper, SwiperSlide } from "swiper/react";
import { format } from "date-fns";
import { ko } from "date-fns/locale";

interface NoticeDetailPopupInterface {
  status: ScheduleStatus;
  reservationId?: number;
  notice: NoticeInterface | undefined;
  petInfo: PetInfo;
  startDateTime: Date;
  popSchedulePopup: (toastText: string) => Promise<void>;
  fetchCurrentPage?: () => void;
  editingSchedule: Reservation | undefined;
}

const ServiceNote = ({
  status,
  reservationId,
  notice,
  petInfo,
  startDateTime,
  popSchedulePopup,
  fetchCurrentPage,
  editingSchedule,
}: NoticeDetailPopupInterface) => {
  const { partner } = usePartner();
  const [slideIndex, setSlideIndex] = useState(0);

  const startDate = new Date(startDateTime);
  const endDate = new Date(startDate);
  endDate.setMinutes(endDate.getMinutes() + 30);

  return notice ? (
    <VFlex m-24 bc-w bdr-16 sized>
      <VFlex g-4 bd-b-t2 p-24>
        <Text t-20-700 style={{ color: "#383B3A" }}>
          <Text c-gr5>{notice.pet.name}</Text>의 알림장
        </Text>
        <Text t-16-500-s4>
          {format(new Date((notice.created ?? 0) * 1000), "yyyy. MM. dd (E)", {
            locale: ko,
          })}
        </Text>
      </VFlex>

      {notice?.attachments && (
        <Flex p-24-t m-24-l>
          <Swiper
            style={{
              position: "relative",
              width: "100%",
              height: "160px",
              overflow: "hidden",
            }}
            spaceBetween={8}
            slidesPerView={2.5}
            scrollbar={{ draggable: true }}
            onActiveIndexChange={(swiper: any) =>
              setSlideIndex(swiper.activeIndex)
            }
            onClick={() => {
              pushPopup({
                kind: PopupKind.Swiper,
                element: PhotosSwiperPopup,
                key: "notice_photos",
                props: {
                  date: new Date((notice?.created ?? 0) * 1000),
                  petName: notice.pet.name,
                  attachments: notice.attachments,
                },
              });
            }}
          >
            {notice?.attachments.map((attachment, i) => (
              <SwiperSlide key={i} style={{ display: "inline-block" }}>
                <Image
                  bdr-16
                  width={214}
                  height={160}
                  src={attachment.path}
                  style={{ objectFit: "cover" }}
                  clickable
                />
              </SwiperSlide>
            ))}
            <Absolute
              left={8}
              bottom={8}
              top={"auto"}
              right={"auto"}
              style={{ zIndex: 100000 }}
            >
              <HFlex
                p-2-tb
                p-12-rl
                bdr-16
                style={{ background: "rgba(0,0,0,0.5)" }}
              >
                <Text t-12-w>
                  {slideIndex + 1}/{notice?.attachments.length}
                </Text>
              </HFlex>
            </Absolute>
          </Swiper>
        </Flex>
      )}

      <VFlex p-24 g-24 ovf-a>
        {notice?.notice && (
          <HFlex g-16>
            <Text width={120} t-14-500-s8>
              전달사항
            </Text>
            <Flex f-1 p-16 bc-t1 bd-t2 bdr-12 t-14 c-s7 l-m>
              {notice?.notice}
            </Flex>
          </HFlex>
        )}
        {notice?.beautyManner && (
          <HFlex g-16>
            <Text width={120} t-14-500-s8>
              미용 매너
            </Text>
            <Flex f-1 t-14-500-s7>
              {notice?.beautyManner}
            </Flex>
          </HFlex>
        )}
        {notice?.notes &&
          notice?.notes.map((note, i) => {
            if (note.value.type === "관심필요") {
              return (
                <HFlex key={i} g-16>
                  <Text width={120} t-14-500-s8>
                    {note.type.value}
                  </Text>
                  <VFlex f-1 p-16 g-4 bc-t1 bd-t2 bdr-12>
                    <Text t-14-500-sf>관찰이 필요해요</Text>
                    {note.note && (
                      <Text t-14-500-s7 l-m>
                        {note.note}
                      </Text>
                    )}
                  </VFlex>
                </HFlex>
              );
            }
            return (
              <HFlex key={i} g-16>
                <Text width={120} t-14-500-s8>
                  {note.type.value}
                </Text>
                <VFlex f-1 t-14-500-s7>
                  {note.value.type}
                </VFlex>
              </HFlex>
            );
          })}
      </VFlex>
    </VFlex>
  ) : status === 4 ? (
    <>
      <VFlex bc-w m-24-trl bdr-12>
        <VFlex
          p-16
          bdr-12
          style={{
            border: "1px solid rgba(49, 138, 255, 0.30)",
            background: `rgba(49, 138, 255, 0.20)`,
          }}
        >
          <HFlex j-b a-s g-12>
            <HFlex a-s g-12>
              <Image size={22} src={`/icons/action/book_s6.svg`} />
              <VFlex g-4>
                <Text t-14-600-s8>
                  알림장을 작성하고 반려가족과 소통해보세요.
                </Text>
                <HFlex g-4>
                  <Text t-14-s7>알림장 사용이 처음이신가요?</Text>
                  <Text
                    t-14-600-b1
                    clickable
                    onClick={() => {
                      window.open(
                        "https://www.notion.so/pawdly/80e0bac7939e4e8692a4a9756e265ddb",
                        "_blnck"
                      );
                    }}
                  >
                    이용가이드 보기
                  </Text>
                </HFlex>
              </VFlex>
            </HFlex>
          </HFlex>
        </VFlex>
      </VFlex>
      <VFlex a-c j-c m-24 bc-w bdr-16 g-8 height={300}>
        <Image size={24} src={`/icons/icon_noti.svg`} />
        <Text t-16-s2 m-8-b>
          작성된 알림장이 없습니다.
        </Text>
        {!editingSchedule?.partnerDeleted && (
          <ButtonV2
            type="filled"
            onClick={() => {
              pushPopup({
                kind: PopupKind.Drawer,
                element: SendNotice,
                props: {
                  petName: petInfo.name,
                  reservationId,
                  loadSchedule: fetchCurrentPage,
                },
              });
            }}
          >
            알림장 작성하기
          </ButtonV2>
        )}
      </VFlex>
    </>
  ) : status === 3 ? (
    <VFlex height={300} bc-tw c-c bdr-16 m-24>
      <Image src="/icons/icon_info_s3.svg" size={24} m-8-b />
      <Text t-14-s4>이용완료하고 알림장을 작성해 보세요.</Text>
      {new Date(
        [editingSchedule!.date, editingSchedule!.endTime].join(" ")
      ).getTime() < new Date().getTime() && (
        <VFlex m-16-t>
          <ButtonV2
            type="filled"
            onClick={() => {
              pushPopup({
                kind: PopupKind.Popup,
                align: PopupAlign.TopCenter,
                element: ConfirmPopup,
                props: {
                  title: `이용 완료 처리를 하면\n고객에게 서비스 후 주의사항 메세지가 보내집니다.`,
                  icon: "/icons/icon_good_warning.png",
                  confirmButtonLabel: "이용 완료 변경하기",
                  confirmButtonType: "confirm",
                  onConfirm: async () => {
                    await api.put(
                      `/rest/reservation/${reservationId}/complete?groupId=${partner.id}`
                    );
                    popSchedulePopup("이용완료 처리되었습니다.");
                    if (fetchCurrentPage) fetchCurrentPage();
                  },
                },
              });
            }}
          >
            이용 완료
          </ButtonV2>
        </VFlex>
      )}
    </VFlex>
  ) : (
    <VFlex height={300} bc-tw c-c bdr-16 m-24>
      <Image src="/icons/icon_info_s3.svg" size={24} m-8-b />
      <Text t-14-s4>작성된 알림장이 없습니다.</Text>
    </VFlex>
  );
};

export default ServiceNote;
