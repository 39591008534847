import { useContext } from "react";

import { SidebarContext, SidebarItemType } from "./Sidebar";

import HFlex from "../layouts/HFlex";
import Image from "../layouts/Image";
import Text from "../layouts/Text";

const SidebarItem = ({
  item,
  onSelect,
}: {
  item: SidebarItemType;
  onSelect?: () => void;
}) => {
  const { selectedItem, setSelectedItem, onChange, sidebarCollapsed } =
    useContext(SidebarContext)!;
  const isSelected = selectedItem?.key === item.key;

  return (
    <HFlex
      a-c
      g-12
      height={sidebarCollapsed ? 36 : 40}
      j-c={sidebarCollapsed}
      bdr-12
      bc-t2={isSelected}
      bc-t1={!isSelected}
      clickable
      sidebar-hover
      m-16-rl
      p-16-rl
      onClick={() => {
        if (onSelect) {
          onSelect();
        } else {
          setSelectedItem(item);
          onChange?.(item);
        }
      }}
    >
      {!!item.icon && (
        <Image
          size={24}
          src={`/icons/menu/${item.icon}${isSelected ? "_selected" : ""}.svg`}
        />
      )}
      {!sidebarCollapsed && (
        <HFlex g-6>
          <Text t-14-600 c-s8={isSelected} c-s2={!isSelected}>
            {item.name}
          </Text>
          {item.beta && (
            <Image
              src={`/icons/menu/beta${isSelected ? "_selected" : ""}.svg`}
              width={43}
            />
          )}
        </HFlex>
      )}
    </HFlex>
  );
};

export default SidebarItem;
