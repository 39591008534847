import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import api from "../common/api";
import useDebounce from "../common/useDebounce";
import { usePartner } from "./partner";
import { Pet, Sorts, SpeciesFilter, TagFilter } from "./types";

// TODO
const ITEMS_PER_PAGE = 10;
interface CustomersContextProps {
  totalCount: number;
  pets: Pet[];
  searchText: string;
  setSearchText: (t: string) => void;
  speciesFilterId: number | null;
  setSpeciesFilterId: (id: number) => void;
  speciesFilters: SpeciesFilter[];
  setSpeciesFilters: (s: SpeciesFilter[]) => void;
  tagFilterId: number | null;
  setTagFilterId: (id: number) => void;
  tagFilters: TagFilter[];
  setTagFilters: (s: TagFilter[]) => void;
  sortFilters: Sorts[];
  setSortFilters: Dispatch<SetStateAction<Sorts[]>>;
  page: number;
  setPage: (p: number) => void;
  fetchCurrentPage: () => void;
  deleteCustomer: (petId: number) => void;
  deleteCustomers: (petIds: number[]) => void;
  isLoading: boolean;
}

const CustomersContext = createContext<CustomersContextProps | undefined>(
  undefined
);

export const CustomersProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { partner } = usePartner();

  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState<string>("");
  const [pets, setPets] = useState<Pet[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const debouncedSearchText = useDebounce({ value: searchText, delay: 500 });

  const [speciesFilterId, setSpeciesFilterId] = useState<number | null>(null);
  const [speciesFilters, setSpeciesFilters] = useState<SpeciesFilter[]>([]);
  const [tagFilterId, setTagFilterId] = useState<number | null>(null);
  const [tagFilters, setTagFilters] = useState<TagFilter[]>([]);
  const [sortFilters, setSortFilters] = useState<Sorts[]>([]);

  const fetchPets = async ({ page, size }: { page: number; size: number }) => {
    const response: {
      data: Pet[];
      totalCount: number;
    } = await api.post(
      `/rest/group/${partner.id}/pets?page=${page * size}&size=${size}${
        debouncedSearchText ? `&search=${debouncedSearchText}` : ""
      }`,
      {
        filters: [
          {
            id: speciesFilterId,
            category: "SPECIES",
            data: speciesFilters.map((s) => ({ id: s.id })),
          },
          {
            id: tagFilterId,
            category: "CUSTOMER_TAG",
            data: tagFilters.map((s) => ({ id: s.id })),
          },
        ],
        sorts: sortFilters,
      },
      {
        params: {
          deleted: false,
        },
      }
    );
    return response;
  };

  const fetchCurrentPage = async () => {
    setIsLoading(true);
    const { data, totalCount } = await fetchPets({
      page,
      size: ITEMS_PER_PAGE,
    });

    setPets(data);
    setTotalCount(totalCount);
    setIsLoading(false);
  };

  const deleteCustomer = async (petId: number) => {
    await api.delete(`/rest/pet/${petId}?groupId=${partner.id}&status=true`);
  };

  const deleteCustomers = async (petIds: number[]) => {
    await api.delete(
      `/rest/pets?groupId=${partner.id}&petIds=${petIds}&status=true`
    );
  };

  useEffect(() => {
    setPage(0);
  }, [searchText]);

  useEffect(() => {
    fetchCurrentPage();
  }, [page, debouncedSearchText, speciesFilters, tagFilters, sortFilters]);

  const contextValue: CustomersContextProps = {
    totalCount,
    pets,
    searchText,
    setSearchText,
    page,
    setPage,
    speciesFilterId,
    setSpeciesFilterId,
    speciesFilters,
    setSpeciesFilters: (s: SpeciesFilter[]) => {
      setSpeciesFilters(s);
      setPage(0);
    },
    tagFilterId,
    setTagFilterId,
    tagFilters,
    setTagFilters: (s: TagFilter[]) => {
      setTagFilters(s);
      setPage(0);
    },
    sortFilters,
    setSortFilters,
    fetchCurrentPage,
    deleteCustomer,
    deleteCustomers,
    isLoading,
  };

  return (
    <CustomersContext.Provider value={contextValue}>
      {children}
    </CustomersContext.Provider>
  );
};

export default function useCustomersContext() {
  const context = useContext(CustomersContext);

  if (!context) {
    throw new Error(
      "useCustomersContext must be used within CustomersProvider"
    );
  }

  return context;
}
