import _ from "lodash";
import { HTMLAttributes } from "react";
import {
  ButtonType,
  FlexProps,
  MiscellaneousProps,
  propsToClasses,
  restOfProps,
} from "./index_generated";
import "./components.scss";

type ButtonProps = {
  classes?: string[];
  enabled?: boolean;
  type?: ButtonType;
  width?: number;
  height?: number;
  caption?: string;
} & HTMLAttributes<HTMLDivElement> &
  FlexProps &
  MiscellaneousProps;

const Button = ({
  classes = [],
  children,
  type = "primary",
  enabled = true,
  caption,
  width,
  height,
  style,
  onClick,
  ...props
}: ButtonProps) => {
  return (
    <div
      className={_.compact([
        ...propsToClasses(props),
        `${type}-button`,
        enabled ? "" : "disabled",
        "clickable",
        ...classes,
      ]).join(" ")}
      style={{
        ...style,
        ...(width ? { width: `${width}px` } : {}),
        ...(height ? { height: `${height}px` } : {}),
      }}
      onClick={(e) => {
        if (enabled && onClick) {
          onClick(e);
        }
      }}
      {...restOfProps(props)}
    >
      {children ?? caption}
    </div>
  );
};

export default Button;
