import { InputHTMLAttributes, useRef, useState } from "react";

import "./Search.scss";

import HFlex from "./HFlex";
import VFlex from "./VFlex";
import Image from "./Image";
import Flex from "./Flex";

interface SearchProps {
  size?: "S" | "M";
  type?: "outline" | "fill";
  disabled?: boolean;
  placeholder?: string;
  value: string;
  onChangeValue: (value: string) => void;
  onBlur?: () => void;
}

function Search({
  size,
  type,
  disabled,
  placeholder,
  value,
  onChangeValue,
  onBlur,
}: SearchProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [focused, setFocused] = useState<boolean>(false);
  const [cancelMouseDown, setCancelMouseDown] = useState<boolean>(false);
  return (
    <HFlex
      className={`search ${type} ${size} ${disabled ? "disabled" : ""} ${
        focused ? "focused" : ""
      }`}
      onClick={() => {
        inputRef.current?.focus();
      }}
    >
      <Image size={size === "S" ? 20 : 24} src={`/icons/icon_search.svg`} />
      <HFlex f-1 a-c g-4={size === "S"} g-8={size === "M"}>
        <input
          placeholder={placeholder}
          ref={inputRef}
          value={value ?? ""}
          onChange={(e) => onChangeValue(e.target.value)}
          onFocus={() => setFocused(true)}
          onBlur={() => {
            setFocused(false);
            onBlur?.();
          }}
          style={{ flex: 1, fontSize: 14 }}
          disabled={disabled}
        />

        {!!value && (focused || cancelMouseDown) && (
          <HFlex
            c-c
            onClick={(e) => {
              onChangeValue?.("");
              e.stopPropagation();
              setCancelMouseDown(false);
            }}
            onMouseDown={() => setCancelMouseDown(true)}
          >
            <Image src="/icons/popup_close.png" size={20} />
          </HFlex>
        )}
      </HFlex>
    </HFlex>
  );
}

export default Search;
