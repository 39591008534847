import { format } from "date-fns";
import { ko } from "date-fns/locale";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import api from "../../../common/api";
import Pagination from "../../../components/Pagination";
import TabBar, { TabItemType } from "../../../components/TabBar";
import HFlex from "../../../layouts/HFlex";
import VFlex from "../../../layouts/VFlex";
import Image from "../../../layouts/Image";
import Text from "../../../layouts/Text";
import { PopupKind } from "../../../popup/Popup";
import { usePartner } from "../../../providers/partner";
import { usePopups } from "../../../providers/popups";
import { FilteredNotificationsInterface } from "../../../providers/types";
import { SettingsTitle } from ".";
import React from "react";
import NoticeDetail from "./NoticeDetail";

interface NoticeProps extends TabItemType {
  title?: string | undefined;
}

const ITEMS_PER_PAGE = 10;

function NoticeHistories({
  setSelectedSubpage,
  initialTabIndex,
}: {
  setSelectedSubpage: (component?: ReactElement) => void;
  initialTabIndex: number;
}) {
  const tabs = useMemo(
    () => [
      {
        key: "1",
        title: "알림장",
        name: "알림장 내역",
        component: () => <NoticeHistoiresList />,
      },
    ],
    []
  );
  const [selectedTab, setSelectedTab] = useState<NoticeProps>(
    tabs[initialTabIndex]
  );

  useEffect(() => {
    return () => {
      setSelectedSubpage(undefined);
    };
  }, [setSelectedSubpage]);

  return (
    <VFlex a-st>
      <SettingsTitle
        title="알림장"
        setSelectedSubpage={setSelectedSubpage}
        border={false}
      />
      <VFlex p-32-rl height={42} sized bd-b-t3={true}>
        <TabBar
          value={selectedTab}
          items={tabs}
          onChange={(item) => setSelectedTab(item)}
        />
      </VFlex>
      {selectedTab && React.createElement(selectedTab.component)}
    </VFlex>
  );
}

function NoticeHistoiresList() {
  const { pushPopup } = usePopups();
  const { partner } = usePartner();
  const [page, setPage] = useState(0);
  const [groupNotifications, setGroupNotifications] = useState<
    FilteredNotificationsInterface[]
  >([]);
  const [filteredNotificationsCount, setFilteredNotificationsCount] =
    useState<number>(0);

  const fetchGroupNotifications = useCallback(async () => {
    const notifications = await api.get(
      `/rest/reservation/notice-list?groupId=${
        partner.id
      }&type=ALL&platform=PARTNER_WEB&page=${
        page * ITEMS_PER_PAGE
      }&size=${ITEMS_PER_PAGE}`
    );
    setGroupNotifications(notifications.notices ?? []);
    setFilteredNotificationsCount(notifications.totalCount ?? 0);
  }, [partner.id, page]);

  useEffect(() => {
    fetchGroupNotifications();
  }, [fetchGroupNotifications]);

  return (
    <VFlex f-1 a-st p-16-t p-32-rl>
      {groupNotifications.length === 0 ? (
        <HFlex c-c height={330} bdr-16 bd-t3 m-16-tb>
          <VFlex a-c g-8>
            <Image size={24} src={`/icons/icon_info_gray.png`} />
            <Text t-16-500-s4>내역이 존재하지 않습니다.</Text>
          </VFlex>
        </HFlex>
      ) : (
        <VFlex f-1 ovf-a>
          <VFlex f-1 g-24 m-32-t>
            <VFlex bd-tbl-t3 m-32-b style={{ minWidth: 800 }}>
              <HFlex a-c height={54} bc-t1 bd-b-t3 a-st>
                <HFlex a-c bd-r-t3 p-16-rl f-1>
                  <Text t-14-500-s4>발송 날짜</Text>
                </HFlex>
                <HFlex a-c bd-r-t3 p-16-rl width={150} sized>
                  <Text t-14-500-s4>발송 시간</Text>
                </HFlex>
                <HFlex a-c bd-r-t3 p-16-rl f-1>
                  <Text t-14-500-s4>고객 정보</Text>
                </HFlex>
                <HFlex a-c bd-r-t3 p-16-rl f-1>
                  <Text t-14-500-s4>연락처</Text>
                </HFlex>
                <HFlex a-c bd-r-t3 p-16-rl f-1>
                  <Text t-14-500-s4>발송 채널</Text>
                </HFlex>
                <HFlex a-c bd-r-t3 p-16-rl width={180} sized>
                  <Text t-14-500-s4>발송 내용</Text>
                </HFlex>
                <HFlex a-c bd-r-t3 p-16-rl f-1>
                  <Text t-14-500-s4>발송 상태</Text>
                </HFlex>
              </HFlex>
              {groupNotifications.map((notification, i) => (
                <HFlex
                  key={i}
                  a-c
                  height={54}
                  bd-b-t3={i !== groupNotifications.length - 1}
                  a-st
                  classes={["table-row"]}
                  clickable
                  onClick={() => {
                    pushPopup({
                      kind: PopupKind.Drawer,
                      element: NoticeDetail,
                      props: {
                        reservationNumber: notification.reservationNumber,
                      },
                    });
                  }}
                >
                  <HFlex a-c bd-r-t3 p-16-rl f-1 g-8 ovf-h>
                    <Text t-14-500-s9>
                      {format(
                        new Date(notification.created * 1000),
                        "yyyy.MM.dd (EEEEE)",
                        {
                          locale: ko,
                        }
                      )}
                    </Text>
                  </HFlex>
                  <HFlex a-c bd-r-t3 p-16-rl width={150} sized g-8 ovf-h>
                    <Text t-14-500-s9>
                      {format(
                        new Date(notification.created * 1000),
                        "a hh:mm",
                        {
                          locale: ko,
                        }
                      )}
                    </Text>
                  </HFlex>
                  <HFlex a-c bd-r-t3 p-16-rl g-8 f-1 ovf-h>
                    <Text
                      t-14-500-s9
                    >{`${notification.pet.name} (${notification.pet.species.name})`}</Text>
                  </HFlex>
                  <HFlex a-c bd-r-t3 p-16-rl g-8 f-1 ovf-h>
                    <Text t-14-500-s9>{notification.cellNumber}</Text>
                  </HFlex>
                  <HFlex a-c bd-r-t3 p-16-rl f-1 g-8 ovf-h>
                    <Text t-14-500-s9>{notification.label}</Text>
                  </HFlex>
                  <HFlex a-c bd-r-t3 p-16-rl g-8 width={180} sized ovf-h>
                    <Text t-14-500-s9>{notification.content}</Text>
                  </HFlex>
                  <HFlex
                    a-c
                    bd-r-t3
                    p-16-rl
                    f-1
                    g-8
                    ovf-h
                    style={{
                      color:
                        notification.alimtalkSucceed || notification.smsSucceed
                          ? "#3EBA86"
                          : notification.scheduled
                          ? "#000000"
                          : "#B62C00",
                    }}
                  >
                    <Text t-14-700>
                      {notification.alimtalkSucceed || notification.smsSucceed
                        ? "발송 성공"
                        : notification.scheduled
                        ? "발송 예정"
                        : "발송 실패"}
                    </Text>
                  </HFlex>
                </HFlex>
              ))}
            </VFlex>
          </VFlex>
        </VFlex>
      )}
      <Pagination
        page={page}
        pageCount={Math.ceil(filteredNotificationsCount / ITEMS_PER_PAGE)}
        onPageChange={(n) => setPage(n)}
      />
    </VFlex>
  );
}

export default NoticeHistories;
