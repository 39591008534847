import { useCallback, useEffect, useRef, useState } from "react";

import Spinner from "../../../common/Spinner/Spinner";
import { ListCalnderRow } from "./ListCalendar";

import { ReservationStatus, Staff } from "../../../providers/types";
import { useFilteredSchedules } from "../../../providers/filtered_schedules";

import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Text from "../../../layouts/Text";
import Image from "../../../layouts/Image";

import _ from "lodash";
import { format, parseISO, isBefore } from "date-fns";
import { ko } from "date-fns/locale";

const ListCalendarBySearch = ({
  searchText,
  statusList,
}: {
  selectedStaffs: Staff[] | undefined;
  searchText?: string;
  statusList?: ReservationStatus[];
}) => {
  const { filteredSchedules, fetchFilteredSchedules } = useFilteredSchedules();
  const [searchPage, setSearchPage] = useState(1);
  const interSectRef = useRef(null);

  const handleObserver: IntersectionObserverCallback = useCallback(
    async (entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        setSearchPage((page) => page + 1);
      }
    },
    []
  );

  useEffect(() => {
    setSearchPage(0);
  }, [searchText]);

  useEffect(() => {
    if (searchText == null) {
      return;
    }
    fetchFilteredSchedules({
      search: searchText,
      page: searchPage,
    });
  }, [fetchFilteredSchedules, searchPage, searchText]);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      threshold: 1.0,
    });
    if (interSectRef.current) observer.observe(interSectRef.current);
    return () => observer.disconnect();
  }, [handleObserver]);

  const data = (filteredSchedules ?? []).map((schedule: any) => ({
    ...schedule,
    date: format(schedule.startDateTime, "yyyy-MM-dd"),
  }));
  const dataByDate = _.groupBy(data, "date");

  return (
    <VFlex f-1 ovf-a>
      {data.length === 0 && (
        <VFlex
          a-c
          j-c
          bd-t3
          bdr-16
          g-8
          style={{
            padding: "100px 0",
          }}
        >
          <Image size={24} src={`/icons/icon_noti.svg`} />
          <Text t-16-s2>검색 결과가 없습니다.</Text>
        </VFlex>
      )}

      {dataByDate ? (
        Object.keys(dataByDate)
          .sort((a, b) => (isBefore(parseISO(a), parseISO(b)) ? 1 : -1))
          .map(
            (date, i) =>
              dataByDate[date] && (
                <VFlex key={i} g-16 m-16-tb sized>
                  <HFlex a-c g-4 height={24} sized>
                    <Text t-16-600-s9>
                      {format(parseISO(date), "M월 d일 EEEE", {
                        locale: ko,
                      })}
                    </Text>
                  </HFlex>
                  <VFlex g-8>
                    {dataByDate[date].map((schedule, j) => (
                      <ListCalnderRow
                        key={j}
                        schedule={schedule}
                        statusList={statusList}
                      />
                    ))}
                  </VFlex>
                </VFlex>
              )
          )
      ) : (
        <VFlex c-c style={{ marginTop: 120 }}>
          <Spinner />
        </VFlex>
      )}
      <VFlex ref={interSectRef} />
    </VFlex>
  );
};

export default ListCalendarBySearch;
