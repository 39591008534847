import { useLocation, useNavigate } from "react-router-dom";
import TextInput from "../../layouts/TextInput";
import { useEffect, useState } from "react";
import api from "../../common/api";
import { toast } from "react-hot-toast";
import VFlex from "../../layouts/VFlex";
import Text from "../../layouts/Text";
import HFlex from "../../layouts/HFlex";
import Image from "../../layouts/Image";
import Button from "../../layouts/Button";

function LoginConfirmPassword() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { phone, password } = (state ?? {}) as {
    phone: string;
    password: string;
  };
  useEffect(() => {
    if (!state) {
      navigate("/problem");
    }
  }, [state, navigate]);
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const [passwordMatch, setPasswordMatch] = useState(false);
  useEffect(() => {
    if (!!password && !!confirmPassword && password === confirmPassword) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
    }
  }, [password, confirmPassword, setPasswordMatch]);
  return (
    <VFlex f-1 a-c bc-w ovf-a>
      <VFlex a-st g-24 p-24-rl width={428} style={{ marginTop: 150 }}>
        <VFlex a-st g-24 style={{ marginBottom: 100 }}>
          <VFlex a-c g-8>
            <HFlex a-c>
              <Text t-32-600-s9 l-m style={{ textAlign: "center" }}>
                <Text t-32-600-gr5>새로운 비밀번호를</Text>
                {"\n"}한번 더 입력해주세요.
              </Text>
            </HFlex>
          </VFlex>
          <VFlex g-8>
            <TextInput
              pwd
              type={"password"}
              placeholder={"비밀번호 입력"}
              value={confirmPassword}
              onChangeValue={(value) => setConfirmPassword(value)}
            />
          </VFlex>
          <VFlex as-st g-8 p-16 bdr-8 bc-t1 m-24-rl>
            <HFlex a-c>
              <Image
                size={24}
                src={
                  passwordMatch
                    ? `/icons/signup_check_checked.png`
                    : `/icons/signup_check_unchecked.png`
                }
              />
              <Text t-14-600-s3 m-8-l>
                비밀번호 일치 여부
              </Text>
            </HFlex>
          </VFlex>
          <VFlex p-24-tb>
            <Button
              type={"cta-lg"}
              enabled={passwordMatch}
              onClick={async () => {
                if (!passwordMatch) {
                  return;
                }
                await api.put(
                  `/rest/user-update/password?password=${password}&cellNumber=${phone}`
                );
                toast.success("변경되었습니다. 다시 로그인 해주세요.");
                navigate("/");
              }}
              caption="비밀번호 변경하기"
            />
          </VFlex>
        </VFlex>
      </VFlex>
    </VFlex>
  );
}

export default LoginConfirmPassword;
