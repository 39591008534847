import React, { ReactElement } from "react";
import HFlex from "./HFlex";
import VFlex from "./VFlex";
import Text from "./Text";

interface RadioGroupProps<T> {
  caption?: string;
  required?: boolean;
  value: T | undefined;
  options: T[];
  renderOption: (
    t: T,
    handleOptionChange: (newValue: T | undefined) => void
  ) => ReactElement;
  onChange: (value: T | undefined) => void;
  allowUnselect?: boolean;
}

const RadioGroup = <T,>({
  caption,
  required,
  options,
  value,
  onChange,
  allowUnselect = false,
  renderOption,
}: RadioGroupProps<T>) => {
  const handleOptionChange = (newValue: T | undefined) => {
    if (allowUnselect && value === newValue) {
      onChange(undefined);
    } else {
      onChange(newValue);
    }
  };

  return (
    <VFlex g-8>
      {caption && (
        <HFlex g-4>
          <Text t-14-s8>{caption}</Text>
          {required && <Text t-14-700-rd1>*</Text>}
        </HFlex>
      )}
      <HFlex g-8>
        {options.map((opt, idx) => (
          <React.Fragment key={`${idx}`}>
            {renderOption(opt, handleOptionChange)}
          </React.Fragment>
        ))}
      </HFlex>
    </VFlex>
  );
};

export default RadioGroup;
