import _ from "lodash";
import { useCallback } from "react";
import api from "../common/api";

export const useAccessLog = () => {
  const getBrowser = (agent: string) => {
    const userAgent = agent.toLowerCase();

    if (userAgent.indexOf("edge") > -1) {
      return "Edge";
    } else if (userAgent.indexOf("whale") > -1) {
      return "Whale";
    } else if (userAgent.indexOf("chrome") > -1) {
      return "Chrome";
    } else if (userAgent.indexOf("firefox") > -1) {
      return "Firefox";
    } else {
      return "Internet explorer";
    }
  };

  const getOS = (agent: string) => {
    if (agent.indexOf("Windows NT 10.0") !== -1) return "Windows";
    if (agent.indexOf("Mac") !== -1) return "Mac/iOS";
    if (agent.indexOf("X11") !== -1) return "UNIX";
    if (agent.indexOf("Linux") !== -1) return "Linux";
    return null;
  };

  const fetchAccessLog = useCallback(async () => {
    if (sessionStorage.getItem("access_log")) return;

    const partners = await api.get("/rest/user/groups");
    const partner = partners[0];
    const partnerDetail = partner?.id
      ? await api.get(`/rest/group/${partner.id}`)
      : null;

    if (
      _.isEmpty(partners) ||
      !partnerDetail.businessRegistration ||
      !partnerDetail.categories ||
      !partnerDetail.licenses
    )
      return;

    const userAgent = navigator.userAgent;

    await api.post(
      `/rest/user/access?groupId=${
        partner.id
      }&productType=WEB&userType=PARTNER&vendor=${getBrowser(
        userAgent
      )}&model=${null}&os=${getOS(userAgent)}`
    );

    sessionStorage.setItem("access_log", "true");
  }, []);

  return fetchAccessLog;
};
