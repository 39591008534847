import { useMemo } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

import { SidebarItemType } from "../components/Sidebar";

import Calendar from "../scenes/Main/Calendar";
import Deposit from "../scenes/Main/Deposit";
import Sales from "../scenes/Main/Sales/Sales";
import Menus from "../scenes/Main/Menus/Menus";
import Customers from "../scenes/Main/Customers/Customers";
import CustomersTag from "../scenes/Main/CustomersTag/CustomersTag";
import Settings from "../scenes/Main/Settings";

const sidebarMenus = [
  {
    key: "reservation",
    name: "예약",
    icon: "reservation",
    component: () => <Calendar />,
  },
  // {
  //   key: "deposit",
  //   name: "예약금",
  //   icon: "deposit",
  //   beta: true,
  //   component: () => <Deposit />,
  // },
  {
    key: "sales",
    name: "매출 통계",
    icon: "sales",
    component: () => <Sales />,
  },
  {
    key: "menu",
    name: "메뉴",
    icon: "menu",
    component: () => <Menus />,
  },
  {
    key: "customers",
    name: "고객",
    icon: "customers",
    component: () => <Customers />,
  },
  {
    key: "customerstag",
    name: "고객 태그",
    icon: "tag",
    beta: true,
    component: () => <CustomersTag />,
  },
  {
    key: "settings",
    name: "설정",
    icon: "settings",
    component: () => <Settings />,
  },
];

const mainTabIndexStore = create(
  immer<{
    sidebarMenus: SidebarItemType[];
    selectedMenu: SidebarItemType;
    setSelectedMenuIndex: (tab: string) => void;
    onChangeMainSideBar: (
      navigate: NavigateFunction
    ) => (item: SidebarItemType) => void;
  }>((set, get) => ({
    sidebarMenus,
    selectedMenu: sidebarMenus[0],
    setSelectedMenuIndex: (tab: string) => {
      set((state) => ({
        ...state,
        selectedMenu:
          sidebarMenus.find((sidebar) => sidebar.key === tab) ??
          sidebarMenus[0],
      }));
    },
    onChangeMainSideBar:
      (navigate: NavigateFunction) => (item: SidebarItemType) => {
        set((state) => ({
          ...state,
          item,
        }));
        if (!get().selectedMenu) {
          navigate(`/main/${item.key}`, { replace: true });
        } else if (get().selectedMenu !== item) {
          navigate(`/main/${item.key}`);
        }
      },
  }))
);

export const useMainTabIndex = () => {
  const navigate = useNavigate();
  const { onChangeMainSideBar, ...rest } = mainTabIndexStore();
  return {
    onChangeMainSideBar: useMemo(
      () => onChangeMainSideBar(navigate),
      [onChangeMainSideBar, navigate]
    ),
    ...rest,
  };
};
