import { useNavigate } from "react-router-dom";
import TextInput from "../../layouts/TextInput";
import { useEffect, useMemo, useState } from "react";
import { formatPhoneNumber } from "../../common/format";
import api from "../../common/api";
import VFlex from "../../layouts/VFlex";
import Text from "../../layouts/Text";
import HFlex from "../../layouts/HFlex";
import Image from "../../layouts/Image";
import Button from "../../layouts/Button";
import { toast } from "react-hot-toast";

declare global {
  var IMP: any;
}

function LoginForgotPassword() {
  useEffect(() => {
    var IMP = window.IMP;
    IMP.init("imp08477488");
  }, []);
  const navigate = useNavigate();
  const [phoneExists, setPhoneExists] = useState<boolean>(true);
  const [phone, setPhone] = useState<string>();
  const ready = useMemo(() => phone?.length === 13, [phone]);
  return (
    <VFlex as-st f-1 c-c bc-w>
      <VFlex c-c a-st g-24 p-24-rl width={428}>
        <VFlex g-8 m-16-b>
          <Text t-32-700>
            <Text c-gr5>휴대폰 번호</Text>를 입력해주세요.
          </Text>
        </VFlex>
        <VFlex g-8>
          <TextInput
            error={!phoneExists}
            placeholder={"휴대폰 번호 입력"}
            value={phone}
            inputMode="numeric"
            onKeyDown={(e) => {
              if (!(e.key === "Backspace" || /[0-9]/.test(e.key))) {
                e.preventDefault();
              }
            }}
            onChangeValue={(value) => {
              const result = value?.replace(/[^0-9]/g, "") || "";
              setPhoneExists(true);
              setPhone((phone) => formatPhoneNumber(phone, result));
            }}
          />
          {!phoneExists && (
            <HFlex p-8-rl a-c>
              <Image size={24} m-8-r src={`/icons/login_failed.png`} />
              <Text t-14-500-err1>가입 되어있지 않은 휴대폰 번호입니다.</Text>
            </HFlex>
          )}
        </VFlex>
        <VFlex p-24-tb>
          <Button
            enabled={ready}
            type="cta-lg"
            onClick={async () => {
              const phoneExist = await api.get(
                `/rest/user/cell-number-check?cellnumber=${phone}&type=PARTNER_SIGNIN`
              );
              setPhoneExists(phoneExist);
              if (!phoneExist) {
                return;
              }
              window.IMP.certification(
                {
                  merchant_uid: "merchant_" + new Date().getTime(),
                  phone,
                },
                async (rsp: any) => {
                  if (rsp.success) {
                    navigate("/changepassword", { state: { phone } });
                  } else {
                    toast.error("본인인증에 실패하였습니다.");
                  }
                }
              );
            }}
            caption={"인증하기"}
          />
        </VFlex>
      </VFlex>
    </VFlex>
  );
}

export default LoginForgotPassword;
