import { useState } from "react";
import Button from "../../layouts/Button";
import HFlex from "../../layouts/HFlex";
import Image from "../../layouts/Image";
import { ButtonType } from "../../layouts/index_generated";
import Text from "../../layouts/Text";
import TextArea from "../../layouts/TextArea";
import VFlex from "../../layouts/VFlex";

function CancelSchedulePopup({
  popPopup,
  title,
  icon,
  cancelButtonLabel,
  confirmButtonLabel,
  confirmButtonStyle,
  onConfirm,
}: {
  popPopup: () => void;
  title: string;
  onConfirm: (reason: string) => void;
  icon?: string;
  cancelButtonLabel?: string;
  confirmButtonStyle?: ButtonType;
  confirmButtonLabel?: string;
}) {
  const [reason, setReason] = useState<string>("");
  return (
    <VFlex a-st p-24 g-8>
      <HFlex>
        <Image size={24} src={icon ?? `/icons/icon_warning.png`} />
      </HFlex>
      <Text t-18-500-s9>{title}</Text>
      <VFlex a-st m-32-t>
        <TextArea
          caption="취소 사유"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          placeholder={"취소 사유를 여기에 입력해주세요."}
          height={100}
        />
      </VFlex>
      <HFlex g-8 m-24-t>
        <VFlex f-1 />
        <Button
          type="cancel"
          caption={cancelButtonLabel ?? "취소"}
          onClick={() => popPopup()}
        />
        <Button
          type={confirmButtonStyle ?? "confirm"}
          caption={confirmButtonLabel ?? "확인"}
          onClick={() => {
            popPopup();
            onConfirm(reason);
          }}
        />
      </HFlex>
    </VFlex>
  );
}

export default CancelSchedulePopup;
