import { useEffect, useState } from "react";

import HFlex from "../../layouts/HFlex";
import VFlex from "../../layouts/VFlex";
import Text from "../../layouts/Text";
import Image from "../../layouts/Image";
import Flex from "../../layouts/Flex";
import FilterChip from "../../layouts/FilterChip";
import Notification from "../../layouts/Notification";
import ButtonV2 from "../../layouts/ButtonV2";

import { numberWithCommas } from "../../common/utils";
import { pushPopup } from "../../providers/popups";
import { PopupKind } from "../../popup/Popup";
import api from "../../common/api";
import { ko } from "date-fns/locale";
import { format } from "date-fns";
import { usePartner } from "../../providers/partner";
import _ from "lodash";

import AddMileage from "../../scenes/Main/Customers/AddMileage";
import { useAmplitudeTrack } from "../../common/useAmplitudeTrack";

const MILEAGE_FILTER_OPTIONS = ["전체", "사용", "적립"] as const;
const MILEAGE_HISTORY_TYPE = ["ALL", "DECREASE", "INCREASE"];

interface History {
  petId: number;
  amount: number;
  finalAmount: number;
  type: string;
  status: number;
  note: string;
  createdBy: string;
  lastModifiedBy: string;
  created: number;
  lastModified: number;
}

const MileageHistory = ({
  petId,
  isDeletedCustomer,
}: {
  petId: number;
  isDeletedCustomer?: boolean;
}) => {
  const { trackAddPoint } = useAmplitudeTrack();
  const { partner } = usePartner();
  const [history, setHistory] = useState<History[]>([]);
  const [statusFilter, setStatusFilter] = useState<
    (typeof MILEAGE_FILTER_OPTIONS)[number]
  >(MILEAGE_FILTER_OPTIONS[0]);
  const [currentMileage, setCurrentMileage] = useState(0);

  useEffect(() => {
    fetchMileageHistory(
      MILEAGE_FILTER_OPTIONS.findIndex((opt) => opt === statusFilter)
    );
    if (!currentMileage) fetchMileageHistory();
  }, [statusFilter, currentMileage]);

  const fetchMileageHistory = async (status?: number) => {
    if (status?.toString()) {
      const res = await api.get<History[]>(
        `/rest/mileage/history?groupId=${partner.id}&petId=${petId}&type=${MILEAGE_HISTORY_TYPE[status]}`
      );

      setHistory(res);
      return;
    } else {
      const res = await api.get<History[]>(
        `/rest/mileage/history?groupId=${partner.id}&petId=${petId}&type=${MILEAGE_HISTORY_TYPE[0]}`
      );
      setCurrentMileage(res[0]?.finalAmount ?? 0);
    }
  };

  return (
    <>
      {/* {isDeletedCustomer ? (
        <>
          <VFlex>
            <Notification type={"full"} color="err">
              <HFlex a-c>
                <Image src="/icons/action/info_w.svg" size={22} m-12-r />
                <HFlex g-8>
                  <Notification.Title color>
                    삭제된 고객입니다.
                  </Notification.Title>
                </HFlex>
              </HFlex>
            </Notification>
          </VFlex>
          <VFlex a-c j-c m-24 bc-w bdr-16 g-8 height={300}>
            <Image size={24} src={`/icons/icon_noti.svg`} />
            <Text t-16-s2 m-8-b>
              삭제된 정보를 보려면 plus plan으로 업그레이드하세요.
            </Text>
            <ButtonV2 type="filled" onClick={() => {}}>
              업그레이드
            </ButtonV2>
          </VFlex>
        </>
      ) : ( */}
      {isDeletedCustomer && (
        <VFlex>
          <Notification type={"full"} color="err">
            <HFlex a-c p-8-rl>
              <Image src="/icons/action/info_w.svg" size={22} m-12-r />
              <HFlex g-8>
                <Notification.Title color>
                  삭제된 고객입니다.
                </Notification.Title>
              </HFlex>
            </HFlex>
          </Notification>
        </VFlex>
      )}
      <VFlex f-1 p-24 a-st ovf-h>
        <VFlex bc-tw bdr-16>
          <VFlex p-16-tb p-24-rl g-4 bd-b-t2>
            <Text t-16-500-s4>보유 적립금</Text>
            <HFlex a-c j-b>
              <Text t-28-600-s8>{numberWithCommas(currentMileage) ?? 0}원</Text>
              <Flex
                width={36}
                height={36}
                c-c
                bdr-12
                bd-t5
                onClick={() => {
                  trackAddPoint();
                  pushPopup({
                    kind: PopupKind.Popup,
                    element: AddMileage,
                    width: 480,
                    props: {
                      petId,
                      fetchHistory: fetchMileageHistory,
                    },
                  });
                }}
              >
                <Image
                  size={24}
                  src={`/icons/icon_add_s6.svg`}
                  style={{ cursor: "pointer" }}
                />
              </Flex>
            </HFlex>
          </VFlex>
          <VFlex f-1 ovf-h p-24-b>
            <HFlex p-16-tb p-24-rl g-6>
              {MILEAGE_FILTER_OPTIONS.map((opt, i) => {
                return (
                  <FilterChip
                    key={i}
                    type={statusFilter === opt ? "selected" : undefined}
                    onClick={() => {
                      setStatusFilter(opt);
                    }}
                    size="M"
                  >
                    {opt}
                  </FilterChip>
                );
              })}
            </HFlex>
            <VFlex p-24-rl f-1 ovf-a>
              {history.length === 0 ? (
                <VFlex height={300} bc-tw c-c bdr-16>
                  <Image src="/icons/icon_info_s3.svg" size={24} m-8-b />
                  <Text t-14-500-s4>적립금 이력이 없습니다.</Text>
                </VFlex>
              ) : (
                history.map((h) => {
                  return <ListItem {...h} key={h.lastModified.toString()} />;
                })
              )}
            </VFlex>
          </VFlex>
        </VFlex>
      </VFlex>
      {/* )} */}
    </>
  );
};

const ListItem = ({
  amount,
  finalAmount,
  type,
  status,
  note,
  created,
}: History) => {
  return (
    <HFlex j-b g-16 p-12-tb>
      <VFlex g-4 f-1>
        {note ? (
          <Text t-16-500-s8 l-1>
            {note}
          </Text>
        ) : (
          <Text t-16-500-s2>미입력</Text>
        )}
        <Text t-14-500-s4>
          {format(created, "M월 d일 (EEE)", { locale: ko })}
        </Text>
      </VFlex>
      <VFlex a-e g-4>
        {status === 1 ? (
          <Text t-16-600-s8>- {numberWithCommas(amount)}원</Text>
        ) : (
          <Text t-16-600-gr5>+ {numberWithCommas(amount)}원</Text>
        )}

        <Text t-14-500-s4>{numberWithCommas(finalAmount)}원</Text>
      </VFlex>
    </HFlex>
  );
};

export default MileageHistory;
