import React, { ReactElement } from "react";

import { pushPopup } from "../../../providers/popups";
import ConfirmPopup from "../../../popup/ConfirmPopup";
import { PopupAlign, PopupKind } from "../../../popup/Popup";
import { IColor, ITag } from "../../../providers/types";

import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Image from "../../../layouts/Image";
import Text from "../../../layouts/Text";
import TagSetting from "./TagSetting";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";

function TagDetailPopup({
  popPopup,
  item,
  tagColors,
  fetchTagList,
}: {
  popPopup: () => void;
  item: ITag;
  tagColors: IColor;
  fetchTagList: () => void;
}): ReactElement {
  const { trackClickTagSetting } = useAmplitudeTrack();
  const editTags = () => {
    // pushPopup({
    //   element: ConfirmPopup,
    //   kind: PopupKind.Popup,
    //   align: PopupAlign.TopCenter,
    //   props: {
    //     icon: "/icons/icon_noti_info.png",
    //     title: `플러스 플랜으로 업그레이드하고 고객 태그 조건을\n수정하시겠어요?`,
    //     confirmButtonType: "confirm",
    //     confirmButtonLabel: "업그레이드",
    //     onConfirm: () => {
    //       window.open("https://www.pawdlypartners.com/pricing/");
    //     },
    //   },
    // });

    trackClickTagSetting("detail");
    popPopup();
    pushPopup({
      kind: PopupKind.Popup,
      element: TagSetting,
      props: { item, tagColors, fetchTagList },
    });
  };

  return (
    <VFlex
      f-1
      a-st
      p-24
      rel
      ovf-a
      style={{
        maxWidth: 530,
      }}
    >
      <HFlex j-b>
        <VFlex
          p-2-tb
          p-8-rl
          bdr-8
          style={{
            background: item.backgroundColor,
            color: item.textColor,
          }}
        >
          <Text t-18-600>{item.title}</Text>
        </VFlex>
        <HFlex g-24>
          <Image size={24} src="/icons/edit.svg" clickable onClick={editTags} />
          <Image
            size={24}
            src={`/icons/close.svg`}
            clickable
            onClick={() => popPopup()}
          />
        </HFlex>
      </HFlex>

      <VFlex m-24-tb ovf-a p-16 bc-t1 bd-t5 bdr-16>
        <HFlex g-8>
          <Image size={24} src={`/icons/action/tag_outline.svg`} />
          <HFlex
            t-16-s6
            dangerouslySetInnerHTML={{ __html: item.criterionLabel }}
          />
        </HFlex>
      </VFlex>

      {item.description ? (
        <Text t-16-500-s8 p-16-b l-m>
          {item.description}
        </Text>
      ) : (
        <Text t-16-500-s4 p-16-b>
          작성된 내용이 없습니다.
        </Text>
      )}
    </VFlex>
  );
}

export default TagDetailPopup;
