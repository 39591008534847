import { format } from "date-fns";
import { ko } from "date-fns/locale";
import { Swiper, SwiperSlide } from "swiper/react";
import HFlex from "../layouts/HFlex";
import VFlex from "../layouts/VFlex";
import Image from "../layouts/Image";
import Text from "../layouts/Text";
import { useState } from "react";
import { Attachment } from "../providers/types";

const PhotosSwiperPopup = ({
  attachments,
  files,
  // petName,
  // date,
  popPopup,
  index,
}: {
  attachments: Attachment[];
  files?: File[];
  // petName?: string;
  // date: Date;
  popPopup: () => void;
  index?: number;
}) => {
  const [slideIndex, setSlideIndex] = useState(index ?? 0);

  return (
    <VFlex j-c style={{ alignItems: "flex-start" }}>
      <HFlex
        width={"100%"}
        a-s
        style={{
          justifyContent: "space-between",
        }}
        m-32-tb
      >
        <Text t-20-700-w>
          {slideIndex + 1}/{attachments.length}
        </Text>
        <Image
          src={"/icons/icon_close.png"}
          size={24}
          clickable
          onClick={() => popPopup()}
        />
      </HFlex>
      <VFlex style={{ alignSelf: "center" }}>
        <Image
          src={
            files
              ? URL.createObjectURL(files[slideIndex])
              : attachments[slideIndex].path
          }
          width={800}
          height={600}
          m-16-b
          style={{
            objectFit: "contain",
          }}
        />
        <Swiper
          style={{ width: "800px", overflow: "hidden" }}
          spaceBetween={16}
          slidesPerView={4.5}
        >
          {attachments.map((attachment, i) => (
            <SwiperSlide
              key={i}
              style={{ display: "inline-block" }}
              onClick={() => setSlideIndex(i)}
            >
              <Image
                width={160}
                height={120}
                src={files ? URL.createObjectURL(files[i]) : attachment.path}
                style={{ objectFit: "cover" }}
                clickable
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </VFlex>
    </VFlex>
  );
};

export default PhotosSwiperPopup;
