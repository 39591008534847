// base
import React, { useCallback, useEffect, useState } from "react";

import api from "../../../common/api";

// components
import { PopupAlign, PopupKind } from "../../../popup/Popup";
import ConfirmPopup from "../../../popup/ConfirmPopup";
import AddPersonalSchedule from "./AddPersonalSchedule";

// providers
import { Schedule, Staff } from "../../../providers/types";
import { usePartner } from "../../../providers/partner";
import { pushPopup } from "../../../providers/popups";
import { useSchedules } from "../../../providers/schedules";
import { useCount } from "../../../providers/count";

// layouts
import Divider from "../../../layouts/Divider";
import HFlex from "../../../layouts/HFlex";
import Image from "../../../layouts/Image";
import VFlex from "../../../layouts/VFlex";

// lib
import { format, startOfDay } from "date-fns";
import { ko } from "date-fns/locale";

function PersonalScheduleMini({
  popPopup,
  personalSchedule,
  initialStaffs,
  startDateTime,
  endDateTime,
}: {
  popPopup: () => void;
  personalSchedule?: Schedule;
  initialStaffs?: Staff[];
  startDateTime?: Date;
  endDateTime?: Date;
}) {
  const { partner } = usePartner();
  const { fetchSchedules } = useSchedules();
  const { fetchCount } = useCount();
  const [scheduleName, setScheduleName] = useState<string | undefined>(
    undefined
  );
  const [startDate, setStartDate] = useState<Date | undefined>(
    startDateTime ?? personalSchedule?.startDateTime
      ? startOfDay((startDateTime ?? personalSchedule?.startDateTime)!)
      : undefined
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    endDateTime ?? personalSchedule?.endDateTime
      ? startOfDay((endDateTime ?? personalSchedule?.endDateTime)!)
      : undefined
  );
  const [selectedStaffs, setSelectedStaffs] = useState<Staff[] | undefined>(
    initialStaffs || []
  );
  const [memo, setMemo] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (personalSchedule) {
      setStartDate(personalSchedule.startDateTime);
      setEndDate(personalSchedule.endDateTime);
      setMemo(personalSchedule.memo);
      setScheduleName(personalSchedule.title);
      setSelectedStaffs(personalSchedule.staffs);
    }
  }, [personalSchedule]);

  const deletePersonalSchedule = useCallback(() => {
    const deletRequest = async () =>
      await api.delete(
        `/rest/group/${partner.id}/staff/schedule/${personalSchedule?.reservationId}`
      );
    pushPopup({
      kind: PopupKind.Popup,
      align: PopupAlign.TopCenter,
      element: ConfirmPopup,
      props: {
        title: "해당 일정을 삭제하시겠습니까?",
        onConfirm: async () => {
          await deletRequest();
          await fetchSchedules(startDate);
          await fetchCount({
            date: startDate!.getTime() / 1000,
          });
          popPopup();
        },
      },
    });
  }, [partner, personalSchedule, fetchSchedules, popPopup]);

  return (
    <VFlex
      abs
      p-24
      width={400}
      bc-w
      bdr-16
      style={{
        boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.2)",
      }}
    >
      <VFlex p-24-b>
        <HFlex p-12-b j-b a-s>
          <HFlex a-c>
            <VFlex t-18-600-s8 l-m>
              {scheduleName}
            </VFlex>
          </HFlex>
          <HFlex g-8>
            <VFlex
              p-4
              bd-t3
              bdr-8
              clickable
              onClick={() => {
                pushPopup({
                  key: "addPersonalSchedule",
                  kind: PopupKind.Drawer,
                  element: AddPersonalSchedule,
                  props: {
                    personalSchedule: personalSchedule,
                  },
                });
                popPopup();
              }}
            >
              <Image size={16} src={`/icons/schedule_popup/open.svg`} />
            </VFlex>
            <VFlex p-4 bd-t3 bdr-8 clickable onClick={deletePersonalSchedule}>
              <Image size={16} src={`/icons/schedule_popup/delete.svg`} />
            </VFlex>
          </HFlex>
        </HFlex>
        {selectedStaffs?.length === 0 && (
          <HFlex g-6 t-14-s4>
            <VFlex bc-t2 bdr-4 p-6-rl a-c j-c>
              미지정
            </VFlex>
          </HFlex>
        )}
        <VFlex g-6>
          {selectedStaffs?.map((v) => {
            return (
              <HFlex key={v.id} g-6 t-14-s4>
                <VFlex bc-t2 bdr-4 p-6-rl a-c j-c>
                  {`${v.name}・`}
                  {v.workingCellNumber}
                </VFlex>
              </HFlex>
            );
          })}
        </VFlex>
      </VFlex>
      <Divider height={1} />
      <VFlex p-12-t g-12>
        <VFlex>
          <HFlex g-8 a-c>
            <Image size={16} src={`/icons/schedule_popup/date.svg`} />
            <VFlex t-14-s8>
              {startDate &&
                format(startDate, "M월 d일 (E) a h:mm ~ ", { locale: ko })}
              {endDate && format(endDate, "a h:mm", { locale: ko })}
            </VFlex>
          </HFlex>
          {/* TODO : 반복 예약 */}
          {/* <VFlex m-24-l t-14-s4>
            매주 수요일
          </VFlex> */}
        </VFlex>
      </VFlex>
      <VFlex bc-t1 bdr-16 p-16 m-12-t t-14-s8 height={100} sized ovf-s>
        {memo ? <VFlex l-m>{memo}</VFlex> : <VFlex t-14-s2>미입력</VFlex>}
      </VFlex>
    </VFlex>
  );
}

export default PersonalScheduleMini;
