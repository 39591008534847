import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import api from "../../../../common/api";
import {
  scheduleStatusColor,
  scheduleStatusString,
} from "../../../../common/constants";
import { useAmplitudeTrack } from "../../../../common/useAmplitudeTrack";

import {
  Attachment,
  DetailsType,
  Guardian,
  Note,
  Reservation,
  ScheduleStatus,
  Service,
  Staff,
} from "../../../../providers/types";
import { useStaffs } from "../../../../providers/staffs";
import { pushPopup } from "../../../../providers/popups";
import { useSchedules } from "../../../../providers/schedules";

import VFlex from "../../../../layouts/VFlex";
import Text from "../../../../layouts/Text";
import HFlex from "../../../../layouts/HFlex";
import Image from "../../../../layouts/Image";
import Flex from "../../../../layouts/Flex";
import FilterChip from "../../../../layouts/FilterChip";
import Absolute from "../../../../layouts/Absolute";
import Notification from "../../../../layouts/Notification";

import { PopupAlign, PopupKind } from "../../../../popup/Popup";
import ConfirmPopup from "../../../../popup/ConfirmPopup";
import CancelSchedulePopup from "../../../../popup/ConfirmPopup/CancelSchedulePopup";
import StaffsDropdown from "../../../../components/StaffsDropdown";
import PhotoPreview from "../../../../components/PhotoPreview";
import EditReservationMemo from "./EditReseravtionMemo";
import SendNotice from "../SendNotice";
import NewReservation from "../NewReservtaion";

import toast from "react-hot-toast";
import { format } from "date-fns";
import { ko } from "date-fns/locale";
import { isNil } from "lodash";
import CopyToClipboard from "react-copy-to-clipboard";

const ReservationInfo = ({
  groupId,
  reservationId,
  setSelectedDetailTab,
  tabs,
  popSchedulePopup,
  fetchCurrentPage,
  editingSchedule,
  notes,
}: {
  groupId: number;
  reservationId: number;
  setSelectedDetailTab: any;
  tabs: any;
  popSchedulePopup: (toastText: string) => Promise<void>;
  fetchCurrentPage?: () => void;
  editingSchedule: Reservation | undefined;
  notes: Note[];
}) => {
  const { trackLandReservationDetail } = useAmplitudeTrack();

  const { staffs } = useStaffs();
  const { fetchSchedules } = useSchedules();
  const [loading, setLoading] = useState<boolean>(false);

  const [resNumber, setResNumber] = useState<string>();
  const [date, setDate] = useState<string>();
  const [selectedProducts, setSelectedProducts] = useState<Service[]>([]);
  const [selectedStaffs, setSelectedStaffs] = useState<Staff[]>();
  const [requestMemo, setRequestMemo] = useState<string>();
  const [status, setStatus] = useState<ScheduleStatus | undefined>();
  const [inquiredAttachments, setInquiredAttachments] =
    useState<Attachment[]>();
  const [guardian, setGuardian] = useState<Guardian>();
  const [onboardings, setOnboardings] = useState<
    [
      {
        type: string;
      }
    ]
  >();
  const [isDeletedReservation, setIsDeletedReservation] = useState(false);
  const [isStaff, setIsStaff] = useState<DetailsType>();

  const fetchDetils = async () => {
    const res = await api.get(`/rest/group/${groupId}/details?type=CALENDAR_S`);
    setIsStaff(res[0]);
  };

  useEffect(() => {
    fetchDetils();
  }, []);

  useEffect(() => {
    if (editingSchedule) {
      setLoading(false);
      setSelectedStaffs(editingSchedule.staffs);
      setDate(editingSchedule.date);
      setSelectedProducts(editingSchedule.products);
      setRequestMemo(editingSchedule.requestMemo);
      setInquiredAttachments(editingSchedule.inquiredAttachments ?? []);
      setStatus(editingSchedule.status);
      setGuardian(editingSchedule.guardian);
      setResNumber(editingSchedule.reservationNumber);
      setOnboardings(editingSchedule.unCompletedReservationOnboardings);
      setIsDeletedReservation(editingSchedule.partnerDeleted);
    }
  }, [reservationId]);

  const pushSchedulePopup = () => {
    pushPopup({
      kind: PopupKind.Popup,
      element: NewReservation,
      dimClick: "none",
      props: {
        reservationId: reservationId,
        startTime: date,
        fetchCurrentPage,
      },
      options: {
        backdropColor: "none",
      },
    });
  };

  useEffect(() => {
    if (editingSchedule?.partnerDeleted) {
      trackLandReservationDetail("deleted");
    } else if (editingSchedule?.hidden) {
      trackLandReservationDetail("hidden");
    } else {
      trackLandReservationDetail();
    }
  }, []);

  if (!editingSchedule) return <></>;

  return (
    <>
      {(editingSchedule?.hidden || editingSchedule?.partnerDeleted) && (
        <VFlex>
          <Notification type={"full"} color="err">
            <HFlex a-c>
              <Image src="/icons/action/info_w.svg" size={22} m-12-r />
              <HFlex g-8>
                <Notification.Title color>
                  {editingSchedule.hidden ? "숨겨진 " : "삭제된 "}
                  예약입니다.
                </Notification.Title>
              </HFlex>
            </HFlex>
          </Notification>
        </VFlex>
      )}
      {editingSchedule.pet.deleted && (
        <VFlex>
          <Notification type={"full"} color="err">
            <HFlex a-c p-8-rl>
              <Image src="/icons/action/info_w.svg" size={22} m-12-r />
              <HFlex g-8>
                <Notification.Title color>
                  삭제된 고객입니다.
                </Notification.Title>
              </HFlex>
            </HFlex>
          </Notification>
        </VFlex>
      )}

      <VFlex bc-w>
        {onboardings && (
          <>
            {onboardings.some((v) => v.type === "RESV_UNWRITTEN_NOTICE") && (
              <ServiceNoteBanner
                editingSchedule={editingSchedule!}
                fetchCurrentPage={fetchCurrentPage}
                reservationId={reservationId}
              />
            )}

            {onboardings.some(
              (v) =>
                v.type === "RESV_UNWRITTEN_QUESTIONNAIRE" ||
                v.type === "RESV_CHECK_QUESTIONNAIRE"
            ) && (
              <SafequestionaireBanner
                type={onboardings
                  .filter(
                    (v) =>
                      v.type === "RESV_UNWRITTEN_QUESTIONNAIRE" ||
                      v.type === "RESV_CHECK_QUESTIONNAIRE"
                  )
                  .map((v) => v.type)}
                fetchCurrentPage={fetchCurrentPage}
                setSelectedDetailTab={setSelectedDetailTab}
                reservationId={reservationId}
              />
            )}
          </>
        )}
      </VFlex>

      <VFlex p-24 g-12>
        {status && (
          <ReservationStatus
            status={status}
            setStatus={setStatus}
            reservationId={reservationId}
            groupId={groupId}
            popSchedulePopup={popSchedulePopup}
            startDateTime={
              new Date(
                [editingSchedule!.date, editingSchedule!.startTime].join(" ")
              )
            }
          />
        )}
        <VFlex p-24-trl p-16-b bc-w bdr-16 g-8 ovf-v>
          <HFlex a-c j-b>
            <HFlex g-12 a-c>
              <Text t-20-600-s8>예약 정보</Text>
              <Text t-14-s4>{resNumber}</Text>
            </HFlex>
            {(status === 3 || status === 10) && (
              <Image
                src="/icons/content/edit_s3.svg"
                size={24}
                clickable
                onClick={pushSchedulePopup}
              />
            )}
          </HFlex>
          <VFlex>
            <HFlex j-b a-s bd-b-t2 p-12-tb g-16>
              <HFlex g-12 a-c>
                <Image size={22} src={`/icons/action/event_available_s6.svg`} />
                <Text t-16-s6>예약 일시</Text>
              </HFlex>
              <VFlex
                t-16-600-s8
                style={{
                  textAlign: "right",
                }}
              >
                {
                  <>
                    <Text>
                      {format(
                        new Date(
                          [
                            editingSchedule!.date,
                            editingSchedule!.startTime,
                          ].join(" ")
                        ),
                        "yyyy년 M월 d일 (E)",
                        {
                          locale: ko,
                        }
                      )}
                    </Text>
                    <Text>
                      {format(
                        new Date(
                          [
                            editingSchedule!.date,
                            editingSchedule!.startTime,
                          ].join(" ")
                        ),
                        "a h:mm ~ ",
                        {
                          locale: ko,
                        }
                      )}
                      {format(
                        new Date(
                          [
                            editingSchedule!.date,
                            editingSchedule!.endTime,
                          ].join(" ")
                        ),
                        "a h:mm",
                        { locale: ko }
                      )}
                    </Text>
                  </>
                }
              </VFlex>
            </HFlex>
            <HFlex j-b a-s bd-b-t2 p-12-tb g-16>
              <HFlex g-12 a-c>
                <Image size={22} src={`/icons/action/scissors_s6.svg`} />
                <Text t-16-s6>서비스명</Text>
              </HFlex>
              <Text
                t-16-600-s8
                l-m
                style={{
                  textAlign: "right",
                }}
              >
                {editingSchedule.products
                  .map(
                    (product) =>
                      `${product.product.name}${
                        product.quantity > 1 ? `*${product.quantity}` : ""
                      }`
                  )
                  .join(", ")}
              </Text>
            </HFlex>
            <HFlex j-b a-s p-12-tb bd-b-t2={!!requestMemo} rel g-16>
              <HFlex g-12 a-c>
                <Image size={22} src={`/icons/action/face_s6.svg`} />
                <Text t-16-s6>담당자</Text>
              </HFlex>
              <HFlex g-2 a-s>
                <Text
                  t-16-600-s8
                  l-m
                  style={{
                    textAlign: "right",
                  }}
                >
                  {editingSchedule.staffs && editingSchedule.staffs?.length > 0
                    ? editingSchedule.staffs
                        .map((staff) => {
                          return `${staff.name} ${staff.jobTitle}`;
                        })
                        .join(", ")
                    : "미지정"}
                </Text>
                {isDeletedReservation ? null : (
                  <StaffsDropdown
                    value={editingSchedule.staffs?.map((s) => s.id.toString())}
                    options={staffs?.map((staff) => ({
                      value: staff.id.toString(),
                      obj: staff,
                      label: `${staff.name} ${staff.jobTitle}`,
                    }))}
                    onChange={async (values) => {
                      if (
                        isStaff?.value === "FALSE" &&
                        editingSchedule.staffs &&
                        editingSchedule.staffs?.length < 0
                      ) {
                        return toast.error("담당자를 선택해주세요.");
                      } else {
                        setSelectedStaffs(values?.map((value) => value.obj));

                        await api.put(
                          `/rest/reservation/${reservationId}/chargers?${values
                            ?.map((value) => value.obj)
                            ?.map((staff) => `chargers=${staff.id}`)
                            .join("&")}`
                        );
                        toast.success("담당자가 변경되었습니다.");
                        await fetchSchedules();
                        if (fetchCurrentPage) fetchCurrentPage();
                      }
                    }}
                  />
                )}
              </HFlex>
            </HFlex>
            {requestMemo && (
              <HFlex p-12-tb g-12 a-s>
                <Image
                  size={22}
                  src={`/icons/action/sticky_note_outline_s6.svg`}
                />
                <VFlex g-8>
                  <Text t-16-s6>고객 요청사항</Text>
                  <Text t-14-s6 l-m>
                    {requestMemo}
                  </Text>
                  {inquiredAttachments && (
                    <HFlex g-8>
                      <PhotoPreview photos={inquiredAttachments} />
                    </HFlex>
                  )}
                </VFlex>
              </HFlex>
            )}
          </VFlex>
        </VFlex>
        <ReservationMemo
          notes={notes}
          reservationId={reservationId}
          setSelectedDetailTab={setSelectedDetailTab}
          tabs={tabs}
          isDeletedReservation={isDeletedReservation}
          fetchCurrentPage={fetchCurrentPage}
        />
        {guardian && <GuardianInfo guardian={guardian} />}
      </VFlex>
    </>
  );
};

export default ReservationInfo;

const ServiceNoteBanner = ({
  editingSchedule,
  fetchCurrentPage,
  reservationId,
}: {
  editingSchedule: Reservation;
  fetchCurrentPage?: () => void;
  reservationId: number;
}) => {
  const { trackClickAddServiceNote } = useAmplitudeTrack();
  const [showBanner, setShowBanner] = useState(true);

  return (
    <HFlex
      p-24-rl
      a-c
      j-b
      height={54}
      style={{
        display: showBanner ? "flex" : "none",
        background: "rgba(49, 138, 255, 0.20)",
      }}
    >
      <HFlex g-12>
        <Image size={22} src={`/icons/alert/notifications_unread_s6.svg`} />

        <Text t-14-600-s8>
          오늘 서비스에 대한 알림장을 전달해보세요!
          <Text
            m-8-l
            t-14-500-b1
            clickable
            onClick={async () => {
              setShowBanner(false);

              await api.post(
                `/rest/reservation/${reservationId}/onboarding?type=RESV_UNWRITTEN_NOTICE`
              );

              pushPopup({
                kind: PopupKind.Drawer,
                element: SendNotice,
                props: {
                  petName: editingSchedule.pet.petInfo.name,
                  reservationId: editingSchedule.id,
                  loadSchedule: fetchCurrentPage,
                },
              });
              trackClickAddServiceNote();
            }}
          >
            작성하기
          </Text>
        </Text>
      </HFlex>
      <Image
        size={22}
        src="/icons/close.svg"
        onClick={async () => {
          setShowBanner(false);
          await api.post(
            `/rest/reservation/${reservationId}/onboarding?type=RESV_UNWRITTEN_NOTICE`
          );
        }}
        clickable
      />
    </HFlex>
  );
};

const SafequestionaireBanner = ({
  type,
  setSelectedDetailTab,
  reservationId,
  fetchCurrentPage,
}: {
  type: string[];
  setSelectedDetailTab: any;
  reservationId: number;
  fetchCurrentPage?: () => void;
}) => {
  const [showBanner, setShowBanner] = useState(true);

  return (
    <HFlex
      p-24-rl
      a-c
      j-b
      height={54}
      style={{
        display: showBanner ? "flex" : "none",
        background: type.includes("RESV_UNWRITTEN_QUESTIONNAIRE")
          ? "rgba(255, 79, 23, 0.2)"
          : "rgba(49, 138, 255, 0.20)",
      }}
    >
      <HFlex g-12>
        <Image size={22} src={`/icons/alert/notifications_unread_s6.svg`} />
        {type.includes("RESV_UNWRITTEN_QUESTIONNAIRE") ? (
          <Text t-14-600-s8>고객이 안전문진표를 작성하지 않았습니다.</Text>
        ) : (
          <Text t-14-600-s8>
            고객이 안전문진표 작성을 완료했습니다.
            <Text
              m-8-l
              t-14-500-b1
              clickable
              onClick={async () => {
                setSelectedDetailTab(4);
                await api.post(
                  `/rest/reservation/${reservationId}/onboarding?type=RESV_CHECK_QUESTIONNAIRE`
                );
                if (fetchCurrentPage) fetchCurrentPage();
              }}
            >
              확인하기
            </Text>
          </Text>
        )}
      </HFlex>
      <Image
        size={22}
        src="/icons/close.svg"
        onClick={async () => {
          setShowBanner(false);
          await api.post(
            `/rest/reservation/${reservationId}/onboarding?type=${
              type.includes("RESV_UNWRITTEN_QUESTIONNAIRE")
                ? "RESV_UNWRITTEN_QUESTIONNAIRE"
                : "RESV_CHECK_QUESTIONNAIRE"
            }`
          );
          if (fetchCurrentPage) fetchCurrentPage();
        }}
        clickable
      />
    </HFlex>
  );
};

const ReservationStatus = ({
  status,
  setStatus,
  reservationId,
  groupId,
  popSchedulePopup,
  startDateTime,
}: {
  status: ScheduleStatus;
  setStatus: React.Dispatch<React.SetStateAction<ScheduleStatus | undefined>>;
  reservationId: number;
  groupId: number;
  popSchedulePopup: (toastText: string) => Promise<void>;
  startDateTime: Date;
}) => {
  const [statusVisible, setStatusVisible] = useState(false);
  const [isCompleteHovered, setIsCompleteHovered] = useState(false);
  const [isNoshowHovered, setIsNoshowHovered] = useState(false);
  const [isCancelHovered, setIsCancelHovered] = useState(false);

  return (
    <VFlex p-24 bc-w bdr-16 g-6 ovf-v>
      <HFlex a-c g-12 rel>
        <Image
          size={24}
          src={`/icons/state/${
            status === 6 || status === 7 ? "cancel" : "check"
          }.svg`}
        />

        <HFlex g-4 a-c>
          <Text
            t-18-600
            style={{
              color: scheduleStatusColor[status],
            }}
          >
            {status === 10 ? "예약확정" : scheduleStatusString[status]}
          </Text>

          {(status === 3 || status === 10) && (
            <Image
              size={24}
              src={`/icons/navigation/${
                statusVisible ? "arrow_drop_up" : "arrow_drop_down"
              }_s6.svg`}
              clickable
              onClick={() => {
                setStatusVisible((prev) => !prev);
              }}
            />
          )}

          {statusVisible && (
            <Absolute style={{ left: 0, top: 35, zIndex: 2 }}>
              <VFlex width={180} p-8 g-4 bdr-12 bc-w sdw-6>
                <Text
                  p-8
                  t-14-500-s8
                  clickable
                  bdr-8
                  bc-t2={isCompleteHovered}
                  onMouseEnter={() => setIsCompleteHovered(true)}
                  onMouseLeave={() => setIsCompleteHovered(false)}
                  onClick={() => {
                    if (startDateTime.getTime() > new Date().getTime()) {
                      pushPopup({
                        kind: PopupKind.Popup,
                        align: PopupAlign.TopCenter,
                        element: ConfirmPopup,
                        props: {
                          title:
                            "이용완료 처리는 예약시간 30분 이후부터 가능합니다.",
                          icon: "/icons/icon_good_warning.png",
                          cancelButtonDisplay: "none",
                          confirmButtonLabel: "확인",
                          confirmButtonType: "confirm",
                          onConfirm: () => {},
                        },
                      });
                    } else {
                      pushPopup({
                        kind: PopupKind.Popup,
                        align: PopupAlign.TopCenter,
                        element: ConfirmPopup,
                        props: {
                          title: `이용 완료 처리를 하면\n고객에게 서비스 후 주의사항 메세지가 보내집니다.`,
                          icon: "/icons/icon_good_warning.png",
                          confirmButtonLabel: "이용 완료 변경하기",
                          confirmButtonType: "confirm",
                          onConfirm: async () => {
                            await api.put(
                              `/rest/reservation/${reservationId}/complete?groupId=${groupId}`
                            );
                            popSchedulePopup("이용완료 처리되었습니다.");
                            setStatus(3);
                            setStatusVisible(false);
                          },
                        },
                      });
                    }
                  }}
                >
                  이용 완료
                </Text>
                <Text
                  p-8
                  t-14-500-s8
                  clickable
                  bdr-8
                  bc-t2={isNoshowHovered}
                  onMouseEnter={() => setIsNoshowHovered(true)}
                  onMouseLeave={() => setIsNoshowHovered(false)}
                  onClick={() => {
                    const startDate = new Date(startDateTime);
                    if (startDate.getTime() > new Date().getTime()) {
                      pushPopup({
                        kind: PopupKind.Popup,
                        align: PopupAlign.TopCenter,
                        element: ConfirmPopup,
                        props: {
                          title: "노쇼 처리는 예약시간 이후부터 가능합니다.",
                          icon: "/icons/icon_good_warning.png",
                          cancelButtonDisplay: "none",
                          confirmButtonLabel: "확인",
                          confirmButtonType: "confirm",
                          onConfirm: () => {},
                        },
                      });
                    } else {
                      pushPopup({
                        kind: PopupKind.Popup,
                        align: PopupAlign.TopCenter,
                        element: ConfirmPopup,
                        props: {
                          title: `고객이 연락 없이 오지 않았다면, 노쇼 처리가 가능합니다.\n노쇼 처리시, 고객에게 메세지가 전송됩니다.`,
                          icon: "/icons/icon_good_warning.png",
                          confirmButtonLabel: "노쇼 처리하고 메세지 보내기",
                          confirmButtonType: "confirm",
                          onConfirm: async () => {
                            await api.put(
                              `/rest/reservation/${reservationId}/no-show`
                            );
                            popSchedulePopup("노쇼 처리되었습니다.");
                            setStatus(7);
                            setStatusVisible(false);
                          },
                        },
                      });
                    }
                  }}
                >
                  노쇼 처리
                </Text>
                <Text
                  p-8
                  t-14-500-s8
                  clickable
                  bdr-8
                  bc-t2={isCancelHovered}
                  onMouseEnter={() => setIsCancelHovered(true)}
                  onMouseLeave={() => setIsCancelHovered(false)}
                  onClick={() => {
                    pushPopup({
                      kind: PopupKind.Popup,
                      align: PopupAlign.TopCenter,
                      element: CancelSchedulePopup,
                      props: {
                        title:
                          "예약을 취소하면 고객에게 취소에 대한 메세지가 보내집니다.",
                        confirmButtonLabel: "예약 취소하고 메세지 보내기",
                        confirmButtonStyle: "warning",
                        onConfirm: async (reason: any) => {
                          await api.put(
                            `/rest/reservation/${reservationId}/cancel?accountType=2`,
                            {
                              cancelNote: reason,
                              id: reservationId,
                              requestNote: "",
                              type: "",
                            }
                          );
                          popSchedulePopup("예약이 취소되었습니다.");
                          setStatus(6);
                          setStatusVisible(false);
                        },
                      },
                    });
                  }}
                >
                  예약 취소
                </Text>
              </VFlex>
            </Absolute>
          )}
        </HFlex>
      </HFlex>
      {statusVisible && (
        <Absolute
          onClick={() => {
            setStatusVisible(false);
          }}
          style={{ zIndex: 1 }}
        />
      )}
      {status === 10 && <Text t-14-s4>고객이 방문을 확정했습니다.</Text>}
    </VFlex>
  );
};

const ReservationMemo = ({
  reservationId,
  setSelectedDetailTab,
  tabs,
  isDeletedReservation,
  notes,
  fetchCurrentPage,
}: {
  reservationId: number;
  setSelectedDetailTab: any;
  tabs: any;
  isDeletedReservation: boolean;
  notes: Note[];
  fetchCurrentPage?: () => void;
}) => {
  const {
    trackClickResMemoTab,
    trackEditResDetailResMemo,
    trackClickResDetailStyleMemo,
    trackClickResDetailConditionMemo,
    trackClickResDetailResMemo,
    trackClickResDetailResMemoImg,
  } = useAmplitudeTrack();
  const MEMO_OPTIONS = ["스타일 메모", "컨디션 메모", "예약 메모", "첨부파일"];

  const [selectedMemoTab, setSelectedMemoTab] = useState<string>(
    MEMO_OPTIONS[0]
  );
  const [isMemoExpanded, setIsMemoExpanded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const paragraphRef = useRef<HTMLParagraphElement>(null);
  const imageCount = useMemo(() => {
    return notes.find((n) => n.attachments)?.attachments?.length;
  }, [notes]);

  const pushEditMemoPopup = () => {
    if (isDeletedReservation) return;

    pushPopup({
      kind: PopupKind.Popup,
      element: EditReservationMemo,
      width: 530,
      props: {
        tabIndex: selectedMemoTab,
        reservationId,
        loadSchedule: fetchCurrentPage,
      },
      dimClick: "none",
    });
  };

  const memos = useMemo(() => {
    const styleMemo = notes
      ?.filter((n) => n.type.value[0] === "S")
      .filter((v) => v.note)
      .map(
        (n) =>
          `<span class="t-14-500 ${isHovered ? "c-sf" : "c-s6"}">${
            n.type.type
          }</span>: ${n.note}`
      )
      .join(" / ");

    const conditionMemo = notes
      ?.filter((n) => n.type.value[0] === "C")
      .filter((v) => v.value)
      ?.map(
        (n) =>
          `<span class="t-14-500 ${isHovered ? "c-sf" : "c-s6"}">${
            n.type.type
          }</span>: ${n.value?.type}${
            n.value?.type === "관심필요" && n.note ? " " + n.note : ""
          }`
      )
      .join(" / ");

    const resMemo = notes
      .filter((n) => n.type.value[0] === "R")
      .filter((v) => v.note)
      .map(
        (n) =>
          `<span class="t-14-500 ${isHovered ? "c-sf" : "c-s6"}">${
            n.note
          }</span>`
      )
      .join("");

    return [styleMemo, conditionMemo, resMemo];
  }, [notes, isHovered]);

  const [isLineClamped, setIsLineClamped] = useState<boolean>(false);
  const requestIndex = notes.findIndex((m) => m.type.value === "REQUEST");

  useEffect(() => {
    const element = paragraphRef.current;

    if (element) {
      const isClamped = element.scrollHeight > element.clientHeight;

      setIsLineClamped(isClamped);
    } else {
      setIsLineClamped(false);
    }
  }, [selectedMemoTab, fetchCurrentPage]);

  return (
    <VFlex p-24 bc-w bdr-16 g-8>
      <HFlex g-12 a-c j-b>
        <Text t-20-600-s8>예약 메모</Text>
        <Image
          src={"/icons/navigation/chevron_right_s3.svg"}
          size={24}
          clickable
          onClick={() => {
            setSelectedDetailTab(1);
            trackClickResMemoTab();
          }}
        />
      </HFlex>
      <HFlex g-12 m-8-t j-b a-c>
        <HFlex height={34} g-6>
          <FilterChip
            type={selectedMemoTab === MEMO_OPTIONS[0] ? "selected" : undefined}
            onClick={() => {
              setSelectedMemoTab(MEMO_OPTIONS[0]);
              setIsMemoExpanded(false);
              trackClickResDetailStyleMemo();
            }}
          >
            스타일 메모
          </FilterChip>
          <FilterChip
            type={selectedMemoTab === MEMO_OPTIONS[1] ? "selected" : undefined}
            onClick={() => {
              setSelectedMemoTab(MEMO_OPTIONS[1]);
              setIsMemoExpanded(false);
              trackClickResDetailConditionMemo();
            }}
          >
            컨디션 메모
          </FilterChip>
          <FilterChip
            type={selectedMemoTab === MEMO_OPTIONS[2] ? "selected" : undefined}
            onClick={() => {
              setSelectedMemoTab(MEMO_OPTIONS[2]);
              setIsMemoExpanded(false);
              trackClickResDetailResMemo();
            }}
          >
            예약 메모
          </FilterChip>
          <Flex
            p-8
            bc-t1={!imageCount}
            bd-t5={selectedMemoTab !== MEMO_OPTIONS[3]}
            bd-g1={selectedMemoTab === MEMO_OPTIONS[3]}
            bdr-12
            c-c
            style={
              selectedMemoTab === MEMO_OPTIONS[3]
                ? { backgroundColor: "rgba(39, 189, 99, 0.04)" }
                : {}
            }
            clickable={!!imageCount}
            onClick={() => {
              if (imageCount) {
                setSelectedMemoTab(MEMO_OPTIONS[3]);
                setIsMemoExpanded(false);
                trackClickResDetailResMemoImg();
              }
            }}
          >
            <Image
              src={
                imageCount
                  ? selectedMemoTab === MEMO_OPTIONS[3]
                    ? "/icons/icon_image_g1.svg"
                    : "/icons/icon_image_s3.svg"
                  : "/icons/icon_image_t1.svg"
              }
              size={20}
            />
          </Flex>
        </HFlex>
        {isDeletedReservation ? null : (
          <Image
            src="/icons/content/edit_s3.svg"
            size={24}
            clickable
            onClick={() => {
              pushEditMemoPopup();
              trackEditResDetailResMemo();
            }}
          />
        )}
      </HFlex>

      {selectedMemoTab !== MEMO_OPTIONS[3] &&
        (memos[MEMO_OPTIONS.findIndex((i) => i === selectedMemoTab)]?.length >
        0 ? (
          <p
            ref={paragraphRef}
            className={`m-12-t m-4-b t-14-500 ${isMemoExpanded ? "" : "l-2"} ${
              isHovered ? "c-sf" : "c-s3"
            }`}
            style={{
              whiteSpace: "pre-wrap",
              cursor: "pointer",
              // color: isHovered ? "#0092E4" : "#9E9B97",
              display: "webkit-box",
            }}
            dangerouslySetInnerHTML={{
              __html:
                memos[MEMO_OPTIONS.findIndex((i) => i === selectedMemoTab)],
            }}
            onMouseOver={() => setIsHovered(true)}
            onMouseOut={() => setIsHovered(false)}
            onClick={() => {
              pushEditMemoPopup();
            }}
          />
        ) : (
          <Text
            t-14-500
            m-12-t
            m-4-b
            clickable
            c-s4={!isHovered}
            c-sf={isHovered}
            onMouseOver={() => setIsHovered(true)}
            onMouseOut={() => setIsHovered(false)}
            onClick={() => {
              pushEditMemoPopup();
            }}
          >
            작성된 내용이 없습니다.
          </Text>
        ))}

      {selectedMemoTab === MEMO_OPTIONS[3] && (
        <HFlex m-12-t m-16-b>
          <PhotoPreview photos={notes[requestIndex].attachments ?? []} />
        </HFlex>
      )}

      {
        <HFlex>
          {selectedMemoTab === MEMO_OPTIONS[2] &&
            !isNil(imageCount) &&
            imageCount > 0 && (
              <HFlex
                g-2
                a-c
                clickable
                onMouseOver={() => setIsHovered(true)}
                onMouseOut={() => setIsHovered(false)}
                onClick={() => {
                  pushEditMemoPopup();
                }}
              >
                <Image
                  src={
                    isHovered
                      ? "/icons/icon_image_b1.svg"
                      : "/icons/icon_image_s3.svg"
                  }
                  size={18}
                />
                <Text
                  t-14-500
                  style={{ color: isHovered ? "#0092E4" : "#9E9B97" }}
                >
                  {imageCount}
                </Text>
              </HFlex>
            )}
          <Flex f-1 />
          {!!isLineClamped && (
            <Image
              src={
                isMemoExpanded
                  ? "/icons/icon_expand_less_s3.svg"
                  : "/icons/icon_expand_more_s3.svg"
              }
              size={24}
              clickable
              onClick={() => setIsMemoExpanded((v) => !v)}
            />
          )}
        </HFlex>
      }
    </VFlex>
  );
};

const GuardianInfo = ({ guardian }: { guardian: Guardian }) => {
  return (
    <VFlex p-24 bc-w bdr-16 g-8>
      <HFlex g-12 a-c>
        <Text t-20-600-s8>예약자 정보</Text>
      </HFlex>
      <VFlex g-16>
        <HFlex j-b a-c bd-b-t2 p-12-tb>
          <HFlex g-8 a-c>
            <Text t-16-s8>{guardian.name}</Text>
            {guardian.primaryGuardian && (
              <VFlex t-14-s6 p-2-tb p-8-rl bd-t4 bdr-8>
                대표 보호자
              </VFlex>
            )}
          </HFlex>
          <CopyToClipboard
            text={guardian.cellnumber}
            onCopy={() => {
              toast.success("전화번호가 복사되었습니다.");
            }}
          >
            <HFlex g-16 a-c clickable>
              <Text t-14-s6>{guardian.cellnumber}</Text>
              <Image size={22} src={`/icons/content/copy_s6.svg`} />
            </HFlex>
          </CopyToClipboard>
        </HFlex>
        {guardian.note ? (
          <Text t-14-s6 l-m>
            {guardian.note}
          </Text>
        ) : (
          <Text t-14-s2>작성된 내용이 없습니다.</Text>
        )}
      </VFlex>
    </VFlex>
  );
};
