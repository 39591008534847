import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { SettingsTitle } from ".";
import TabBar, { TabItemType } from "../../../components/TabBar";
import VFlex from "../../../layouts/VFlex";
import { RepeatType } from "../../../providers/types";
import BusinessHolidays from "./BusinessHolidays";
import TemporaryHolidays from "./TemporaryHolidays";
import { usePartner } from "../../../providers/partner";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";

export const repeatTypes = [
  RepeatType.FIRST_WEEK_OF_MONTH,
  RepeatType.SECOND_WEEK_OF_MONTH,
  RepeatType.THIRD_WEEK_OF_MONTH,
  RepeatType.FOURTH_WEEK_OF_MONTH,
  RepeatType.FIFTH_WEEK_OF_MONTH,
];

export const dayConversion: Record<string, string> = {
  MONDAY: "월",
  TUESDAY: "화",
  WEDNESDAY: "수",
  THURSDAY: "목",
  FRIDAY: "금",
  SATURDAY: "토",
  SUNDAY: "일",
};

function Holidays({
  setSelectedSubpage,
}: {
  setSelectedSubpage: (component?: ReactElement) => void;
}) {
  const { partner } = usePartner();
  const { trackLandHolidays, trackClickHolidaysTab } = useAmplitudeTrack();

  useEffect(() => {
    trackLandHolidays();
  }, []);

  const tabs = useMemo(
    () => [
      {
        key: "1",
        name: "정기 휴무일",
        component: BusinessHolidays,
      },
      {
        key: "2",
        name: "임시 휴무일",
        component: TemporaryHolidays,
      },
    ],
    []
  );
  const [selectedTab, setSelectedTab] = useState<TabItemType>(tabs[0]);
  return (
    <VFlex a-st bd-b-t3>
      <SettingsTitle
        title="휴무일"
        setSelectedSubpage={setSelectedSubpage}
        border={false}
      />
      <VFlex p-32-rl height={42} sized bd-b-t3={true}>
        <TabBar
          value={selectedTab}
          items={tabs}
          onChange={(item) => {
            setSelectedTab(item);
            trackClickHolidaysTab(item.name, partner.title);
          }}
        />
      </VFlex>
      {selectedTab && React.createElement(selectedTab.component)}
    </VFlex>
  );
}

export default Holidays;
