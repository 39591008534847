import { useMemo } from "react";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import api from "../common/api";
import { usePartner } from "./partner";
import {
  GroupNotification,
  GroupNotificationSetting,
  Notification,
  Partner,
} from "./types";

const notificationsStore = create(
  immer<{
    notifications: Notification[];
    groupNotifications: GroupNotification[];
    groupNotificationSettings: GroupNotificationSetting[];
    totalGroupNotiCount: number;
    fetchNotifications: (partner: Partner) => () => void;
    fetchGroupNotifications: (
      partner: Partner
    ) => ({
      page,
      status,
      date,
    }: {
      page: number;
      status?: string;
      date?: number;
    }) => void;
    fetchGroupNotificationSettings: (partner: Partner) => () => void;
  }>((set) => ({
    notifications: [],
    groupNotifications: [],
    groupNotificationSettings: [],
    totalGroupNotiCount: 0,
    fetchNotifications: (partner: Partner) => async () => {
      const notifications = await api.get(
        `/rest/notification?groupId=${partner.id}`
      );
      set((state) => ({ ...state, notifications }));
    },
    fetchGroupNotifications:
      (partner: Partner) =>
      async ({
        page,
        status,
        date,
      }: {
        page: number;
        status?: string;
        date?: number;
      }) => {
        const groupNotifications = await api.get(
          `/rest/group/${partner.id}/notification/history?&size=10&page=${
            page * 10
          }&platformType=PARTNER_WEB` +
            (status ? `&status=${status}` : "") +
            (date ? `&date=${date}` : "")
        );
        set((state) => ({
          ...state,
          groupNotifications: groupNotifications.notificationHistory ?? [],
          totalGroupNotiCount: groupNotifications.totalCount ?? 0,
        }));
      },
    fetchGroupNotificationSettings: (partner: Partner) => async () => {
      const alimTalkSettings = await api.get(
        `/rest/notification/config?groupId=${partner.id}&type=ALIMTALK`
      );
      const pushSettings = await api.get(
        `/rest/notification/config?groupId=${partner.id}&type=PUSH`
      );
      set((state) => ({
        ...state,
        groupNotificationSettings: [
          ...alimTalkSettings.map((i: any) => ({ ...i, type: "ALIMTALK" })),
          ...pushSettings.map((i: any) => ({ ...i, type: "PUSH" })),
        ],
      }));
    },
  }))
);

export const useNotifications = () => {
  const { partner } = usePartner();
  const {
    fetchNotifications,
    fetchGroupNotifications,
    fetchGroupNotificationSettings,
    ...rest
  } = notificationsStore();
  return {
    fetchNotifications: useMemo(
      () => fetchNotifications(partner),
      [fetchNotifications, partner]
    ),
    fetchGroupNotifications: useMemo(
      () => fetchGroupNotifications(partner),
      [fetchGroupNotifications, partner]
    ),
    fetchGroupNotificationSettings: useMemo(
      () => fetchGroupNotificationSettings(partner),
      [fetchGroupNotificationSettings, partner]
    ),
    ...rest,
  };
};
