import moment from "moment";

export const getNearestNextMinInter30 = (dateTime: Date) => {
  const start = moment(dateTime);
  const remainder = 30 - (start.minute() % 30);

  const next = moment(start).add(remainder, "minutes").toDate();

  return next;
};
