import _ from "lodash";

export const formatPhoneNumber = (
  previousValue?: string,
  currentValue?: string
) => {
  if (!currentValue || !previousValue) {
    return currentValue;
  }
  currentValue = currentValue.replace(/[^0-9]/g, "");
  let formattedPhone = currentValue.slice(0, 3);
  if (
    previousValue.length > currentValue.length
      ? currentValue.length > 3
      : currentValue.length >= 3
  ) {
    formattedPhone += `-${currentValue.slice(3, 7)}`;
  }
  if (
    previousValue.length > currentValue.length
      ? currentValue.length > 7
      : currentValue.length >= 7
  ) {
    formattedPhone += `-${currentValue.slice(7, 11)}`;
  }
  return formattedPhone;
};

export const isValidDate = (obj: Date) => {
  return _.isDate(obj) && obj.toString() !== "Invalid Date";
};

export const timeStringToMinutes = (time: string) => {
  if (!time) {
    return;
  }
  const [hours, minutes] = time.split(":");
  return parseInt(hours) * 60 + parseInt(minutes);
};

export const minutesToTimeString = (minutes: number) => {
  const isAm = minutes < 720;
  const hours =
    (isAm ? Math.floor(minutes / 60) : Math.floor(minutes / 60) - 12) || 12;
  const mins = minutes % 60;
  return `${isAm ? "오전" : "오후"} ${("0" + hours.toString()).slice(-2)}:${(
    "0" + mins.toString()
  ).slice(-2)}`;
};

export const reg = (value: string) => {
  if (!value) {
    return value;
  }
  const text = value.replace(
    /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gim,
    ""
  );
  return text;
};
