import { useCallback, useState } from "react";
import api from "../../../common/api";
import { QuestionnareTemplateItem } from "../../../providers/types";
import _ from "lodash";
import Text from "../../../layouts/Text";
import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Image from "../../../layouts/Image";
import Flex from "../../../layouts/Flex";
import Toggle from "../../../components/Toggle";
import { usePartner } from "../../../providers/partner";
import { useQuestionnaires } from "../../../providers/questionnaires";
import { toast } from "react-hot-toast";

function AddQuestionnaireForm({
  popPopup,
  templateId,
  templateTitle,
  questionnaireTemplate,
  isAdded,
  isUsing,
}: {
  popPopup: () => void;
  templateId: number;
  templateTitle: string;
  questionnaireTemplate: QuestionnareTemplateItem[];
  isAdded?: boolean;
  isUsing?: boolean;
}) {
  const { partner } = usePartner();
  const { fetchQuestionnaires } = useQuestionnaires();
  const [using, setUsing] = useState(isUsing ?? true);

  return (
    <VFlex f-1 a-st p-24-t ovf-a rel>
      <HFlex p-32-rl>
        <VFlex f-1 />
        <Image
          size={24}
          src={`/icons/popup_close.png`}
          clickable
          onClick={() => popPopup()}
        />
      </HFlex>

      <VFlex p-32-rl p-16-tb>
        <VFlex g-16>
          <HFlex>
            <Text t-24-600-s8>{templateTitle}</Text>
            {isAdded && (
              <Toggle
                value={using}
                onChange={async (value) => {
                  await api.post(
                    `/rest/questionnaire/${templateId}?status=${
                      value ? "TRUE" : "FALSE"
                    }&groupId=${partner.id}`
                  );
                  setUsing(value);
                  await fetchQuestionnaires();
                }}
              />
            )}
          </HFlex>
          <Text t-16-500-s4 m-16-b>
            보호자에게 발송되는 문진 질문입니다.
          </Text>
        </VFlex>

        <VFlex g-8 style={{ paddingBottom: isAdded ? "0" : "100px" }}>
          {questionnaireTemplate?.map((q) => (
            <HFlex p-16-tb key={q.id}>
              <Image src={"/icons/icon_question.png"} size={28} m-16-r />
              <Text t-18-600-s8>{q.title}</Text>
              <Flex f-1 />
              {q.required && (
                <Flex
                  c-c
                  p-8-rl
                  bdr-8
                  style={{ backgroundColor: "rgba(39,189,99,0.15)" }}
                >
                  <Text t-16-500-gr5>필수</Text>
                </Flex>
              )}
            </HFlex>
          ))}
        </VFlex>
      </VFlex>
      {!isAdded && (
        <Flex
          width={600}
          p-24-rl
          p-16-t
          style={{
            background:
              "linear-gradient(360deg, #FFF 70%, rgba(255, 255, 255, 0.00) 100%)",
            paddingBottom: "40px",
            position: "fixed",
            bottom: 0,
          }}
        >
          <Flex
            f-1
            p-20-tb
            bc-gr5
            c-c
            bdr-16
            t-18-600-w
            clickable
            onClick={async () => {
              if (isUsing) {
                popPopup();
                toast.success("이미 추가된 문진표 입니다");
                return;
              }
              await api.post(
                `/rest/questionnaire/${templateId}?status=TRUE&groupId=${partner.id}`
              );
              toast.success("문진표를 만들었습니다");
            }}
          >
            사용하기
          </Flex>
        </Flex>
      )}
    </VFlex>
  );
}

export default AddQuestionnaireForm;
