// base
import React from "react";

import { staffColors } from "../../../common/constants";
import AddSchedule from "./AddSchedule";
import AddPersonalSchedule from "./AddPersonalSchedule";
import { PopupKind } from "../../../popup/Popup";

// providers
import { ReservationStatus, Schedule } from "../../../providers/types";
import { pushPopup } from "../../../providers/popups";

// layouts
import HFlex from "../../../layouts/HFlex";
import Image from "../../../layouts/Image";
import VFlex from "../../../layouts/VFlex";
import Text from "../../../layouts/Text";

// lib
import { format } from "date-fns";
import { ko } from "date-fns/locale";
import ReservationDetail from "./ReservationDetail";

function MoreMini({
  popPopup,
  schedules,
  personalList,
  statusList,
}: {
  popPopup: () => void;
  schedules: Schedule[];
  personalList?: ReservationStatus[];
  statusList?: ReservationStatus[];
}) {
  const isStatusShow = statusList
    ?.filter((i: ReservationStatus) => i.showed)
    ?.map((status) => status.value)
    .map(Number);

  const isStatusActive = statusList
    ?.filter((i: ReservationStatus) => i.actived)
    ?.map((status) => status.value)
    .map(Number);

  const isPersonalActive = personalList && personalList[0]?.actived;

  return (
    <VFlex
      abs
      width={300}
      p-16-trl
      p-20-b
      bc-w
      bdr-16
      style={{
        maxHeight: 420,
        overflow: "hidden",
        boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.2)",
      }}
    >
      <VFlex g-4 p-12-b>
        <HFlex j-b>
          <VFlex t-16-600-s8>일정 {schedules.length}건</VFlex>
          <Image
            size={24}
            src={`/icons/popup_close.png`}
            clickable
            onClick={() => popPopup()}
          />
        </HFlex>
        <VFlex t-12-s6>
          {format(schedules[0].startDateTime, "yyyy년 M월 dd일 (E)", {
            locale: ko,
          })}
        </VFlex>
      </VFlex>
      <VFlex
        g-4
        style={{
          maxHeight: 328,
          overflowY: "scroll",
        }}
      >
        {schedules.map((value) => {
          return value.type === "PARTNER_SCHEDULE" ? (
            <VFlex key={value.reservationId}>
              <HFlex
                clickable
                a-c
                g-4
                p-4
                bdr-4
                style={{
                  display: isPersonalActive ? "inline-flex" : "none",
                  background:
                    value.staffs.length <= 1
                      ? `repeating-linear-gradient(-45deg, ${value.backgroundColor}, ${value.backgroundColor} 2px, transparent 0, transparent 5px)`
                      : `repeating-linear-gradient(-45deg, #fff, #fff 2px, transparent 0, transparent 5px), linear-gradient(to left, ${
                          staffColors[
                            value.staffs[value.staffs.length - 1]?.color
                          ]?.bgColor
                        }, ${staffColors[value.staffs[0]?.color]?.bgColor})`,
                }}
                onClick={() => {
                  pushPopup({
                    key: "addPersonalSchedule",
                    kind: PopupKind.Drawer,
                    element: AddPersonalSchedule,
                    props: {
                      personalSchedule: value,
                    },
                  });
                }}
              >
                <VFlex
                  style={{
                    width: 10,
                    height: 10,
                    background: value.mainColor,
                    borderRadius: "100%",
                  }}
                ></VFlex>
                <Text t-12-600-s8 l-1>
                  {value.title}
                </Text>
              </HFlex>
            </VFlex>
          ) : (
            <VFlex key={value.reservationId} clickable>
              <VFlex
                j-c
                bdr-4
                style={{
                  display:
                    isStatusShow?.includes(value.status) &&
                    isStatusActive?.includes(value.status)
                      ? "inline-flex"
                      : "none",
                  background: value.backgroundColor,
                  textDecoration: [5, 6, 7].includes(value.status)
                    ? "line-through"
                    : "none",
                  opacity: [4, 6, 7].includes(value.status) ? 0.5 : 1,
                  border: [6, 7].includes(value.status)
                    ? value.staffs.length === 0
                      ? `2px solid #979E9B`
                      : value.staffs.length === 1
                      ? `2px solid ${
                          staffColors[value.staffs[0]?.color]?.bgColor
                        }`
                      : `2px solid transparent`
                    : "none",
                  borderRadius: "4px",
                  backgroundImage:
                    [6, 7].includes(value.status) && value.staffs.length > 1
                      ? `linear-gradient(#fff, #fff), linear-gradient(to left, ${
                          staffColors[
                            value.staffs[value.staffs.length - 1]?.color
                          ]?.bgColor
                        }, ${staffColors[value.staffs[0]?.color]?.bgColor})`
                      : "#fff",
                  backgroundOrigin: "border-box",
                  backgroundClip: "content-box, border-box",
                }}
                onClick={() => {
                  // pushPopup({
                  //   key: "addSchedule",
                  //   kind: PopupKind.Popup,
                  //   element: AddSchedule,
                  //   props: {
                  //     reservationId: value.reservationId,
                  //     startTime: value.startDateTime,
                  //   },
                  // });
                  pushPopup({
                    key: "addSchedule",
                    kind: PopupKind.Popup,
                    element: ReservationDetail,
                    props: {
                      reservationId: value.reservationId,
                      // petId: schedule.,
                      schedule: value,
                    },
                    dimClick: "none",
                  });
                }}
              >
                <VFlex p-4>
                  <HFlex>
                    <VFlex t-12-600-s8>
                      {value.petName}({value.species})・
                    </VFlex>
                    <Text t-12-s8 l-1>
                      {value.productList?.join(",")}
                    </Text>
                  </HFlex>
                  <HFlex g-4 a-c>
                    <Text t-12-s7 l-1>
                      {`${format(value.startDateTime, "a h:mm", {
                        locale: ko,
                      })} ~ ${format(value.endDateTime, "a h:mm", {
                        locale: ko,
                      })}`}
                    </Text>
                    {/* TODO : 결제 추가 후 작업 */}
                    {/* <Image size={14} src={`/icons/icon_money.png`} /> */}
                    {(value.incomingPlatform === "PUA" ||
                      value.incomingPlatform === "PUW") && (
                      <Image size={14} src={`/icons/icon_send.png`} />
                    )}
                  </HFlex>
                </VFlex>
              </VFlex>
            </VFlex>
          );
        })}
      </VFlex>
    </VFlex>
  );
}

export default MoreMini;
