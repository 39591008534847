import { ReactElement, useEffect, useMemo, useState } from "react";
import Select, { GroupBase, OptionsOrGroups } from "react-select";
import _ from "lodash";
import VFlex from "../layouts/VFlex";
import HFlex from "../layouts/HFlex";
import Image from "../layouts/Image";
import Text from "../layouts/Text";
import Checkbox from "./Checkbox";

export type DropdownItem = {
  value: any;
  label: string;
  obj?: any;
  icon?: string;
  status?: boolean;
};

function TagColorDropdown({
  value,
  values,
  obj,
  isDisabled,
  caption,
  required,
  placeholder,
  placeholderColor,
  multi,
  isSearchable,
  onChange,
  onChangeValues,
  options,
}: {
  value?: any;
  values?: any[];
  obj?: any;
  isDisabled?: boolean;
  caption?: string;
  required?: boolean;
  placeholder?: string;
  placeholderColor?: string;
  multi?: boolean;
  isSearchable?: boolean;
  onChange?: (value: DropdownItem | undefined) => void;
  onChangeValues?: (values: DropdownItem[] | undefined) => void;
  options: OptionsOrGroups<DropdownItem, GroupBase<DropdownItem>>;
}): ReactElement {
  const flattenedOptions = useMemo(
    () =>
      _.flatten(
        options.map((option) =>
          (option as GroupBase<DropdownItem>).options
            ? (option as GroupBase<DropdownItem>).options
            : [option as DropdownItem]
        )
      ),
    [options]
  );
  const [innerValue, setInnerValue] = useState<DropdownItem | undefined>(
    flattenedOptions.find((option) => option.value === value)
  );
  const [innerValues, setInnerValues] = useState<DropdownItem[] | undefined>(
    values
      ? flattenedOptions.filter((option) => values.includes(option.value))
      : []
  );
  // when value/options is changed from outside, update innerValue
  useEffect(() => {
    setInnerValue(flattenedOptions.find((option) => option.value === value));
    setInnerValues(
      values
        ? flattenedOptions.filter((option) => values.includes(option.value))
        : []
    );
  }, [value, values, flattenedOptions, setInnerValue, setInnerValues]);

  return (
    <VFlex g-8 clickable>
      <Select
        key={innerValue?.value.toString()}
        isDisabled={isDisabled}
        isMulti={false}
        noOptionsMessage={() => "검색 결과가 없습니다."}
        placeholder={placeholder ?? ""}
        styles={{
          control: () => ({
            padding: "16px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            WebkitUserSelect: "none",
            msUserSelect: "none",
            userSelect: "none",
            border: "1px solid #DEE2E0",
            borderRadius: 16,
          }),
          valueContainer: () => ({
            flex: 1,
            display: "flex",
          }),
          menu: () => ({
            left: 0,
            top: 4,
            position: "absolute",
            padding: 8,
            backgroundColor: "#fff",
            width: 482,
            boxShadow:
              "0px 1px 2px 0px rgba(0, 0, 0, 0.15), 0px 4px 8px 1px rgba(0, 0, 0, 0.20)",
            borderRadius: 12,
          }),
          menuPortal: (provided) => ({
            ...provided,
            width: 100,
            zIndex: 100000000000,
          }),
          singleValue: (provided, state) => ({
            ...provided,
            padding: "2px 8px",
            borderRadius: "8px",
            fontSize: "14px",
            lineHeight: "22px",
            cursor: "pointer",
            background: state.data.obj ? state.data.obj.bgColor : undefined,
            color: state.data.obj ? state.data.obj.textColor : undefined,
          }),
        }}
        components={{
          IndicatorSeparator: () => null,
          Option: (props: any) => {
            const { innerProps, innerRef } = props;
            return (
              <article
                ref={innerRef}
                {...innerProps}
                style={{
                  padding: 8,
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: props.isFocused ? "#F1F3F2" : "#fff",
                  borderRadius: "8px",
                  WebkitUserSelect: "none",
                  msUserSelect: "none",
                  userSelect: "none",
                  cursor: "pointer",
                  justifyContent: "space-between",
                }}
              >
                <HFlex
                  a-c
                  j-c
                  p-2-tb
                  p-8-rl
                  bdr-8
                  style={{
                    background: props.data.obj.bgColor,
                  }}
                >
                  <Text
                    t-14
                    style={{
                      color: props.data.obj.textColor,
                    }}
                  >
                    {props.data.label}
                  </Text>
                </HFlex>
                <Image
                  src={`/icons/icon_check.png`}
                  size={20}
                  style={{
                    display: props.isSelected ? "block" : "none",
                  }}
                />
              </article>
            );
          },
          DropdownIndicator: () => (
            <Image
              size={24}
              src={`/icons/schedule_popup/drop_down.svg`}
              style={{ cursor: "pointer" }}
            />
          ),
        }}
        value={innerValue}
        onChange={(item) => {
          setInnerValue(item ?? undefined);
          onChange?.(item ?? undefined);
        }}
        options={options}
        menuPortalTarget={document.querySelector("body")}
        isSearchable={isSearchable}
      />
    </VFlex>
  );
}

export default TagColorDropdown;
