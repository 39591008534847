import { useState } from "react";

import HFlex from "../../layouts/HFlex";
import VFlex from "../../layouts/VFlex";
import Text from "../../layouts/Text";
import Image from "../../layouts/Image";
import Button from "../../layouts/Button";
import TextArea from "../../layouts/TextareaV2";

const EditPetAllergy = ({
  popPopup,
  value,
  fetch,
}: {
  popPopup: () => void;
  value: string | undefined;
  fetch: (v: string | undefined) => void;
}) => {
  const [allergy, setAllergy] = useState(value);

  return (
    <VFlex p-24>
      <HFlex j-b a-c m-24-b>
        <Text t-24-600-s8>고객 정보 수정</Text>
        <Image
          src="/icons/icon_close_s6.svg"
          size={24}
          clickable
          onClick={popPopup}
        />
      </HFlex>
      <TextArea
        caption="알레르기 정보"
        placeholder="예: 캥거루 고기, 가금류"
        value={allergy}
        onChangeValue={(v) => {
          setAllergy(v);
        }}
        maxLength={800}
      />
      <Button
        m-24-t
        bdr-12
        height={56}
        enabled={!!allergy && value !== allergy}
        onClick={() => {
          fetch(allergy);
          popPopup();
        }}
      >
        저장
      </Button>
    </VFlex>
  );
};

export default EditPetAllergy;
