import { ReactElement, useEffect, useMemo, useState } from "react";
import Select, { GroupBase, OptionsOrGroups } from "react-select";
import _ from "lodash";
import Checkbox from "./Checkbox";
import VFlex from "../layouts/VFlex";
import HFlex from "../layouts/HFlex";
import Text from "../layouts/Text";
import Image from "../layouts/Image";
import { useAmplitudeTrack } from "../common/useAmplitudeTrack";

export type StaffDropdownItem = {
  value: string;
  label: string;
  obj?: any;
  url?: string;
  status?: boolean;
};

function StatusDropdown({
  value,
  isDisabled,
  caption,
  required,
  onChange,
  onClick,
  options,
}: {
  value?: any;
  isDisabled?: boolean;
  caption?: string;
  required?: boolean;
  onChange?: (value: StaffDropdownItem[]) => void;
  onClick?: () => void;
  options: OptionsOrGroups<StaffDropdownItem, GroupBase<StaffDropdownItem>>;
}): ReactElement {
  const { trackClickPanelAppointmentsStateSetting } = useAmplitudeTrack();
  const flattenedOptions = useMemo(
    () =>
      _.flatten(
        options.map((option) =>
          (option as GroupBase<StaffDropdownItem>).options
            ? (option as GroupBase<StaffDropdownItem>).options
            : [option as StaffDropdownItem]
        )
      ),
    [options]
  );
  const [innerValue, setInnerValue] = useState<StaffDropdownItem[]>(
    flattenedOptions.filter((option) => value.includes(option.value))
  );

  // when value/options is changed from outside, update innerValue
  useEffect(() => {
    setInnerValue(
      flattenedOptions.filter((option) => value.includes(option.value))
    );
  }, [value, flattenedOptions, setInnerValue]);

  return (
    <VFlex g-8 onClick={(e) => onClick?.()}>
      {caption && (
        <HFlex g-8>
          <Text t-14-s8>{caption}</Text>
          {required && <Text t-14-700-rd1>*</Text>}
        </HFlex>
      )}
      <Select
        isDisabled={isDisabled}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        isMulti={true}
        styles={{
          placeholder: () => ({ display: "none" }),
          control: () => ({
            display: "flex",
            backgroundColor: "#fff",
            WebkitUserSelect: "none",
            msUserSelect: "none",
            userSelect: "none",
          }),
          indicatorSeparator: () => ({}),
          valueContainer: () => ({
            flex: 1,
            display: "flex",
          }),
          menu: () => ({
            left: 10,
            top: 0,
            position: "absolute",
            backgroundColor: "#fff",
            width: "100%",
            maxHeight: "122px",
            overflow: "scroll",
            border: "1px solid #EBEDEC",
            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.2)",
            borderRadius: 8,
          }),
          menuPortal: (provided) => ({
            ...provided,
            width: 160,
            zIndex: 100000000000,
          }),
          menuList: (provided) => ({
            ...provided,
            maxHeight: "122px",
          }),
          singleValue: () => {
            return {
              color: "#596371",
              fontFamily: "Pretendard-SemiBold",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "24px",
            };
          },
          multiValue: () => {
            return { display: "none" };
          },
          clearIndicator: () => {
            return { display: "none" };
          },
        }}
        formatOptionLabel={(data) => (
          <HFlex g-16 a-c>
            <HFlex a-c g-8>
              <Text t-14-700>{data.label}</Text>
              {!!data.status && <VFlex width={8} height={8} bdr-8 bc-gr5 />}
            </HFlex>
          </HFlex>
        )}
        components={{
          Option: (props: any) => {
            const { innerProps, innerRef } = props;
            return (
              <article
                ref={innerRef}
                {...innerProps}
                style={{
                  height: "38px",
                  color: "#383B3A",
                  display: "flex",
                  backgroundColor: props.isFocused ? "#F8F9F8" : "#fff",
                  fontSize: "14px",
                  lineHeight: "22px",
                  WebkitUserSelect: "none",
                  msUserSelect: "none",
                  userSelect: "none",
                }}
              >
                <HFlex g-4 a-c p-8-l width={"100%"}>
                  <Checkbox initialValue={props.isSelected} size={16} />
                  <Text clickable t-14-s8 f-1>
                    {props.data.label}
                  </Text>
                </HFlex>
              </article>
            );
          },
          DropdownIndicator: () => (
            <Image
              clickable
              m-8-l
              size={20}
              src={`/icons/icon_plus.svg`}
              onClick={() => {
                trackClickPanelAppointmentsStateSetting();
              }}
            />
          ),
        }}
        value={innerValue ?? []}
        onChange={(item) => {
          const items = [...item];
          setInnerValue(items);
          onChange?.(items);
        }}
        options={options}
        menuPortalTarget={document.querySelector("body")}
        isSearchable={false}
      />
    </VFlex>
  );
}

export default StatusDropdown;
