import "./Menu.scss";

import HFlex from "./HFlex";
import VFlex from "./VFlex";
import Text from "./Text";
import { PropsWithChildren } from "react";

interface MenuProps extends PropsWithChildren {
  width: number;
}

const Menu = ({ width, children }: MenuProps) => {
  return (
    <VFlex className="menu" width={width}>
      {children}
    </VFlex>
  );
};

export default Menu;

interface MenuItemProps extends PropsWithChildren {
  handleClick?: () => void;
}

const MenuItem = ({ children, handleClick }: MenuItemProps) => {
  return (
    <HFlex classes={["menu-item"]} onClick={handleClick} clickable hover>
      {children}
    </HFlex>
  );
};

Menu.Item = MenuItem;
