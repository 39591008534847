import React, { useEffect, useMemo, useState } from "react";

import api from "../../../../common/api";
import { pushPopup } from "../../../../providers/popups";
import { InquiryDetailType } from "../../../../providers/types";
import { usePartner } from "../../../../providers/partner";
import { useInquiries } from "../../../../providers/inquiries";

import TabBar, { TabItemType } from "../../../../components/TabBar";
import PetProfile from "../../../../components/PetProfile";
import Tooltip from "../../../../components/Tooltip";
import InquiryInfo from "./InquiryInfo";
import ServiceHistory from "../ReservationDetail/ServiceHistory";
import NewReservation from "../NewReservtaion";
import ConfirmPopup from "../../../../popup/ConfirmPopup";
import { PopupKind, PopupAlign } from "../../../../popup/Popup";
import Questionnaire from "./Questionnaire";

import HFlex from "../../../../layouts/HFlex";
import Text from "../../../../layouts/Text";
import Image from "../../../../layouts/Image";
import VFlex from "../../../../layouts/VFlex";
import Divider from "../../../../layouts/Divider";
import Flex from "../../../../layouts/Flex";
import LoadingView from "../../../../layouts/LoadingView";
import Absolute from "../../../../layouts/Absolute";

import { toast } from "react-hot-toast";

interface InquiryDetailInterface {
  inquiryId: number;
  popPopup: () => void;
}

function InquiryDetail({ inquiryId, popPopup }: InquiryDetailInterface) {
  const { partner } = usePartner();
  const { fetchInquiries } = useInquiries();
  const [reservationInfo, setReservationInfo] = useState<InquiryDetailType>();
  const [newPetMemo, setNewPetMemo] = useState<string | undefined>(undefined);
  const savePetMemo = async () => {
    try {
      if (!reservationInfo?.pet.petInfo) return;

      const petInfo = reservationInfo.pet.petInfo;

      await api.put(
        `/rest/v2/pet/${petInfo.id}/single?groupId=${
          partner.id
        }&type=NOTE&value=${
          (newPetMemo && encodeURIComponent(newPetMemo)) || null
        }`
      );
      toast.success("변경사항이 저장되었습니다.");

      setReservationInfo((resInfo) => {
        const newReservationInfo = JSON.parse(JSON.stringify(resInfo));
        newReservationInfo!.pet.petInfo.note = newPetMemo;

        return newReservationInfo;
      });
    } catch (e) {}
  };

  const tabs = [
    {
      key: "1",
      name: "문의 정보",
      component: () => (
        <InquiryInfo
          inquiryId={inquiryId}
          popPopup={popPopup}
          setSelectedDetailTab={setSelectedDetailTab}
          tabs={tabs}
        />
      ),
    },
    {
      key: "2",
      name: "안전문진표",
      component: () => (
        <Questionnaire
          questionnaires={reservationInfo?.groomingQuestionnaire ?? []}
          petName={reservationInfo?.pet.petInfo.name ?? ""}
        />
      ),
    },
    {
      key: "3",
      name: "예약 목록",
      component: () => (
        <ServiceHistory
          petId={reservationInfo?.pet.petInfo.id ?? 0}
          groupId={partner.id}
        />
      ),
    },
  ];

  const [selectedDetailTab, setSelectedDetailTab] = useState(0);

  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredArr, setIsHoveredArr] = useState(false);
  const [statusVisible, setStatusVisible] = useState(false);
  const [isNoshowHovered, setIsNoshowHovered] = useState(false);

  const fetchInquiryDetailInfo = async () => {
    const reservation = await api.get<InquiryDetailType>(
      `/rest/reservation/inquiry/${inquiryId}/detail`
    );
    setReservationInfo(reservation);
    setNewPetMemo(reservation.pet.petInfo.note);
  };

  const closeInquiry = () => {
    pushPopup({
      kind: PopupKind.Popup,
      key: "inquiryEnd",
      element: ConfirmPopup,
      align: PopupAlign.TopCenter,
      props: {
        title: `문의를 취소하시겠어요?\n문의 취소시, 고객에게 알림톡이 전송됩니다.`,
        confirmButtonLabel: "문의 취소하고 알림톡 보내기",
        onConfirm: () => {
          (async () => {
            await api.put(
              `/rest/reservation/${inquiryId}/cancel?accountType=PARTNER&type=INQUIRY`
            );
            await fetchInquiries({ page: 0 });
          })();
          toast.success("문의가 취소되었습니다");
          popPopup();
        },
      },
    });
  };

  const openAddReservationPopup = () => {
    pushPopup({
      key: "inquiryReservation",
      kind: PopupKind.Popup,
      element: NewReservation,
      dimClick: "none",
      props: {
        inquiryData: {
          id: reservationInfo?.id,
          inquiredDateTime: reservationInfo?.inquiredDateTime,
          reservationDate: reservationInfo?.reservationDate,
          time: reservationInfo?.time,
          products: reservationInfo?.products,
        },
        selectedCustomer: {
          id: reservationInfo?.pet.petInfo.id,
          name: reservationInfo?.pet.petInfo.name,
          species: reservationInfo?.pet.petInfo.species.name,
          attachment: reservationInfo?.pet.petInfo.attachment,
          guardianList: reservationInfo?.pet.guardians,
          note: reservationInfo?.pet.petInfo.note,
        },
      },
    });
    popPopup();
  };

  useEffect(() => {
    fetchInquiryDetailInfo();
  }, []);

  return (
    <VFlex
      a-st
      style={{
        height: "95vh",
        minHeight: "600px",
        maxHeight: "850px",
        maxWidth: "1024px",
        width: "95vw",
      }}
      ovf-h
      bdr-24
    >
      <HFlex p-24 a-c>
        <Text t-22-600-s8>예약 문의 상세</Text>
        <Flex f-1 />
        <HFlex g-24>
          <Image
            src={"/icons/icon_close_s6.svg"}
            size={24}
            clickable
            onClick={() => {
              if (newPetMemo !== reservationInfo?.pet.petInfo?.note) {
                pushPopup({
                  kind: PopupKind.Popup,
                  key: "confirm_discard_pet_memo",
                  element: ConfirmPopup,
                  align: PopupAlign.TopCenter,
                  props: {
                    title: `반려동물 메모가 저장되지 않았습니다.\n저장하지 않고 나갈까요?`,
                    confirmButtonLabel: "중단하기",
                    confirmButtonType: "delete",
                    onConfirm: () => {
                      popPopup();
                    },
                  },
                });
              } else {
                popPopup();
              }
            }}
          />
        </HFlex>
      </HFlex>
      <Divider height={1} />

      {!!reservationInfo ? (
        <>
          <HFlex f-1 a-st ovf-h>
            <VFlex width={376} style={{ boxSizing: "border-box" }}>
              <VFlex ovf-a>
                <PetProfile
                  petInfo={reservationInfo.pet.petInfo}
                  tags={reservationInfo.pet.tags}
                  guardianList={reservationInfo.pet.guardians}
                  petProfileImg={reservationInfo.pet.petInfo.attachment}
                  newPetMemo={newPetMemo}
                  setNewPetMemo={setNewPetMemo}
                  savePetMemo={savePetMemo}
                />
              </VFlex>
            </VFlex>
            <Divider width={1} />
            <VFlex f-1>
              <Flex height={50} p-24-rl style={{ paddingTop: 15 }}>
                <TabBar
                  value={tabs[selectedDetailTab]}
                  items={tabs}
                  onChange={(item) =>
                    setSelectedDetailTab(
                      tabs.findIndex((t) => t.key === item.key)
                    )
                  }
                />
              </Flex>
              <Divider height={1} />
              <VFlex f-1 bc-t2 ovf-a>
                {tabs[selectedDetailTab]?.component()}
              </VFlex>
            </VFlex>
          </HFlex>

          <VFlex
            a-e
            p-24-rl
            j-c
            height={104}
            style={{
              boxShadow:
                "0px -1px 2px 0px rgba(0, 0, 0, 0.08), 0px -2px 12px 1px rgba(0, 0, 0, 0.08)",
              zIndex: 1,
            }}
          >
            <VFlex g-16 rel>
              <HFlex a-c bdr-16>
                <VFlex
                  p-16-tb
                  p-24-rl
                  bc-gr5
                  t-18-600-w
                  clickable
                  op-hover
                  onMouseEnter={(e) => {
                    setIsHovered(true);
                    e.stopPropagation();
                  }}
                  onMouseLeave={(e) => {
                    setIsHovered(false);
                    e.stopPropagation();
                  }}
                  onClick={() => {
                    openAddReservationPopup();
                  }}
                >
                  문의 확정
                </VFlex>
                <span
                  style={{
                    position: "relative",
                    width: 1,
                    height: "100%",
                    background: "#27BD63",
                  }}
                >
                  <span
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: 0,
                      right: 0,
                      margin: "0 auto",
                      width: 1,
                      height: 40,
                      background: "rgba(255,255,255,0.6)",
                      transform: "translateY(-50%)",
                      zIndex: 1,
                    }}
                  ></span>
                </span>
                <VFlex
                  j-c
                  p-16-rl
                  height={58}
                  bc-gr5
                  op-hover
                  clickable
                  onClick={() => {
                    setStatusVisible((prev) => !prev);
                  }}
                  onMouseEnter={(e) => {
                    setIsHoveredArr(true);
                    e.stopPropagation();
                  }}
                  onMouseLeave={(e) => {
                    setIsHoveredArr(false);
                    e.stopPropagation();
                  }}
                >
                  <Image
                    size={24}
                    src={`/icons/navigation/${
                      statusVisible ? "arrow_drop_up" : "arrow_drop_down"
                    }_w.svg`}
                  />
                </VFlex>

                {statusVisible && (
                  <Absolute
                    style={{
                      height: 90,
                      left: "-14px",
                      top: "-58px",
                      zIndex: 2,
                    }}
                  >
                    <VFlex width={180} p-8 g-4 bdr-12 bc-w sdw-6>
                      <Text
                        p-8
                        t-14-500-r1
                        clickable
                        bdr-8
                        bc-t2={isNoshowHovered}
                        onMouseEnter={() => setIsNoshowHovered(true)}
                        onMouseLeave={() => setIsNoshowHovered(false)}
                        onClick={() => {
                          closeInquiry();
                        }}
                      >
                        문의 취소
                      </Text>
                    </VFlex>
                  </Absolute>
                )}

                {isHovered && (
                  <Tooltip
                    type="bc"
                    positionTop={-56}
                    positionLeft={-20}
                    arrowOffsetRight={65}
                    content="예약 일정 등록 진행하기"
                  />
                )}

                {isHoveredArr && (
                  <VFlex
                    abs
                    a-c
                    t-14-w
                    p-12
                    bdr-8
                    bc-s8
                    onMouseEnter={() => setIsHoveredArr(true)}
                    onMouseLeave={() => setIsHoveredArr(false)}
                    clickable
                    style={{
                      top: "-56px",
                      right: 0,
                      overflow: "unset",
                    }}
                  >
                    <Text>다른 예약 상태로 변경</Text>
                    <Flex
                      style={{
                        position: "absolute",
                        bottom: "-16px",
                        right: 21,
                        width: 0,
                        height: 0,
                        borderTop: "8px solid #383B3A",
                        borderRight: "8px solid transparent",
                        borderBottom: "8px solid transparent",
                        borderLeft: "8px solid transparent",
                      }}
                    />
                  </VFlex>
                )}
              </HFlex>
            </VFlex>
            {statusVisible && (
              <Absolute
                onClick={() => {
                  setStatusVisible(false);
                }}
                style={{ zIndex: 1 }}
              />
            )}
          </VFlex>
        </>
      ) : (
        <LoadingView />
      )}
    </VFlex>
  );
}

export default InquiryDetail;
