import { useEffect, useMemo, useRef, useState } from "react";

import {
  scheduleStatusColor,
  scheduleStatusString,
} from "../../common/constants";

import HFlex from "../../layouts/HFlex";
import VFlex from "../../layouts/VFlex";
import Image from "../../layouts/Image";
import Text from "../../layouts/Text";
import Flex from "../../layouts/Flex";
import FilterChip from "../../layouts/FilterChip";
import PhotoPreview from "../PhotoPreview";

import EditReservationMemo from "../../scenes/Main/Calendar/ReservationDetail/EditReseravtionMemo";
import ReservationDetail from "../../scenes/Main/Calendar/ReservationDetail";

import { Note, Reservation } from "../../providers/types";
import { pushPopup } from "../../providers/popups";
import { PopupKind } from "../../popup/Popup";
import api from "../../common/api";
import { usePartner } from "../../providers/partner";

import { format } from "date-fns/esm";
import { ko } from "date-fns/locale";
import { isNil } from "lodash";
import { numberWithCommas } from "../../common/utils";

export interface ServiceSummaryItem {
  id: number;
  status: number;
  date: string;
  reservationDate: number;
  styleNotes: Note[];
  conditionNotes: Note[];
  productName: string;
  otherNotes: Note[];
  amount?: number;
}

const MEMO_OPTIONS = ["스타일 메모", "컨디션 메모", "예약 메모", "첨부파일"];

const ServiceSummary = ({
  serviceSummary,
  loadSchedule,
  noneBorderRadius,
  petId,
}: {
  serviceSummary: ServiceSummaryItem;
  loadSchedule: () => void;
  noneBorderRadius?: boolean;
  petId: number;
}) => {
  const { partner } = usePartner();
  const [selectedMemoTab, setSelectedMemoTab] = useState<string>(
    MEMO_OPTIONS[0]
  );
  const [isMemoExpanded, setIsMemoExpanded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const paragraphRef = useRef<HTMLParagraphElement>(null);
  const imageCount = useMemo(() => {
    return serviceSummary.otherNotes?.find(
      (n) => n?.imageCount && n.imageCount > 0
    )?.imageCount;
  }, [serviceSummary]);

  const memos = useMemo(() => {
    const styleMemo = serviceSummary.styleNotes
      ?.filter((n) => !!n.note)
      .map(
        (n) =>
          `<span class="t-14-500 ${isHovered ? "c-sf" : "c-s6"}">${
            n.type.type
          }</span>: ${n.note}`
      )
      .join(" / ");

    const conditionMemo = serviceSummary.conditionNotes
      ?.filter((n) => !isNil(n.value?.type))
      ?.map(
        (n) =>
          `<span class="t-14-500 ${isHovered ? "c-sf" : "c-s6"}">${
            n.type.type
          }</span>: ${n.value?.type}${
            n.value?.type === "관심필요" && n.note ? " " + n.note : ""
          }`
      )
      .join(" / ");

    const etcMemo =
      (serviceSummary.otherNotes && serviceSummary.otherNotes[0]?.note) ?? "";

    return [styleMemo, conditionMemo, etcMemo];
  }, [serviceSummary, isHovered]);

  const pushSchedulePopup = async () => {
    const reservation = await api.get<Reservation>(
      `/rest/reservation/${serviceSummary.id}?groupId=${partner.id}`
    );

    await pushPopup({
      kind: PopupKind.Popup,

      element: ReservationDetail,
      props: {
        reservationId: serviceSummary.id,
        petId,
        editingReservation: reservation,
      },
    });
  };

  const pushEditMemoPopup = () => {
    pushPopup({
      kind: PopupKind.Popup,
      element: EditReservationMemo,
      width: 530,
      props: {
        tabIndex: selectedMemoTab,
        reservationId: serviceSummary.id,
        loadSchedule,
      },
      dimClick: "none",
    });
  };

  const [isLineClamped, setIsLineClamped] = useState<boolean>(false);

  useEffect(() => {
    const element = paragraphRef.current;

    if (element) {
      const isClamped = element.scrollHeight > element.clientHeight;

      setIsLineClamped(isClamped);
    } else {
      setIsLineClamped(false);
    }
  }, [selectedMemoTab]);

  return (
    <VFlex bc-tw bdr-16={!noneBorderRadius} p-24>
      <VFlex onClick={pushSchedulePopup} clickable>
        <HFlex a-c j-b m-8-b>
          <Text
            t-14-500-s4
            style={{ color: scheduleStatusColor[serviceSummary.status] }}
          >
            {
              scheduleStatusString[
                serviceSummary.status === 10 ? 3 : serviceSummary.status
              ]
            }
          </Text>
          <HFlex a-c g-4>
            <Text t-14-500-s4>
              {format(
                serviceSummary.reservationDate * 1000,
                "yyyy.MM.dd(EEE)",
                {
                  locale: ko,
                }
              )}
            </Text>
            <Image src={"/icons/chevron_right.png"} size={22} />
          </HFlex>
        </HFlex>
      </VFlex>
      <Text t-16-600-s8 m-16-b l-1>
        {serviceSummary.productName}
      </Text>
      <HFlex height={34} g-6>
        <FilterChip
          type={selectedMemoTab === MEMO_OPTIONS[0] ? "selected" : undefined}
          onClick={() => {
            setSelectedMemoTab(MEMO_OPTIONS[0]);
            setIsMemoExpanded(false);
          }}
        >
          스타일 메모
        </FilterChip>
        <FilterChip
          type={selectedMemoTab === MEMO_OPTIONS[1] ? "selected" : undefined}
          onClick={() => {
            setSelectedMemoTab(MEMO_OPTIONS[1]);
            setIsMemoExpanded(false);
          }}
        >
          컨디션 메모
        </FilterChip>
        <FilterChip
          type={selectedMemoTab === MEMO_OPTIONS[2] ? "selected" : undefined}
          onClick={() => {
            setSelectedMemoTab(MEMO_OPTIONS[2]);
            setIsMemoExpanded(false);
          }}
        >
          예약 메모
        </FilterChip>
        <Flex
          p-8
          bc-t1={!imageCount}
          bd-t5={selectedMemoTab !== MEMO_OPTIONS[3]}
          bd-g1={selectedMemoTab === MEMO_OPTIONS[3]}
          bdr-12
          c-c
          style={
            selectedMemoTab === MEMO_OPTIONS[3]
              ? { backgroundColor: "rgba(39, 189, 99, 0.04)" }
              : {}
          }
          clickable={!!imageCount}
          onClick={() => {
            if (imageCount) {
              setSelectedMemoTab(MEMO_OPTIONS[3]);
              setIsMemoExpanded(false);
            }
          }}
        >
          <Image
            src={
              imageCount
                ? selectedMemoTab === MEMO_OPTIONS[3]
                  ? "/icons/icon_image_g1.svg"
                  : "/icons/icon_image_s3.svg"
                : "/icons/icon_image_t1.svg"
            }
            size={20}
          />
        </Flex>
      </HFlex>

      {selectedMemoTab !== MEMO_OPTIONS[3] &&
        (memos[MEMO_OPTIONS.findIndex((i) => i === selectedMemoTab)]?.length >
        0 ? (
          <p
            ref={paragraphRef}
            className={`m-12-t m-4-b t-14-500 ${isMemoExpanded ? "" : "l-2"} ${
              isHovered ? "c-sf" : "c-s3"
            }`}
            style={{
              whiteSpace: "pre-wrap",
              cursor: "pointer",
              // color: isHovered ? "#0092E4" : "#9E9B97",
              display: "webkit-box",
            }}
            dangerouslySetInnerHTML={{
              __html:
                memos[MEMO_OPTIONS.findIndex((i) => i === selectedMemoTab)],
            }}
            onMouseOver={() => setIsHovered(true)}
            onMouseOut={() => setIsHovered(false)}
            onClick={() => {
              pushEditMemoPopup();
            }}
          />
        ) : (
          <Text
            t-14-500
            m-12-t
            m-4-b
            clickable
            c-s4={!isHovered}
            c-sf={isHovered}
            onMouseOver={() => setIsHovered(true)}
            onMouseOut={() => setIsHovered(false)}
            onClick={() => {
              pushEditMemoPopup();
            }}
          >
            작성된 내용이 없습니다.
          </Text>
        ))}

      {selectedMemoTab === MEMO_OPTIONS[3] && (
        <HFlex m-12-t m-16-b>
          <PhotoPreview
            photos={serviceSummary.otherNotes[0].attachments ?? []}
          />
        </HFlex>
      )}

      {
        <HFlex>
          {selectedMemoTab === MEMO_OPTIONS[2] &&
            !isNil(imageCount) &&
            imageCount > 0 && (
              <HFlex
                g-2
                a-c
                clickable
                onMouseOver={() => setIsHovered(true)}
                onMouseOut={() => setIsHovered(false)}
                onClick={() => {
                  pushEditMemoPopup();
                }}
              >
                <Image
                  src={
                    isHovered
                      ? "/icons/icon_image_b1.svg"
                      : "/icons/icon_image_s3.svg"
                  }
                  size={18}
                />
                <Text
                  t-14-500
                  style={{ color: isHovered ? "#0092E4" : "#9E9B97" }}
                >
                  {imageCount}
                </Text>
              </HFlex>
            )}
          <Flex f-1 />
          {!!isLineClamped && (
            <Image
              src={
                isMemoExpanded
                  ? "/icons/icon_expand_less_s3.svg"
                  : "/icons/icon_expand_more_s3.svg"
              }
              size={24}
              clickable
              onClick={() => setIsMemoExpanded((v) => !v)}
            />
          )}
        </HFlex>
      }

      {!isNil(serviceSummary.amount) && (
        <HFlex m-16-t p-16-t bd-t-t2>
          <Text t-14-500-s8>
            총 결제 금액 ({numberWithCommas(serviceSummary.amount)}원)
          </Text>
        </HFlex>
      )}
    </VFlex>
  );
};

export default ServiceSummary;
