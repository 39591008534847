import { ReactElement, useEffect } from "react";
import { SettingsTitle } from ".";
import { staffColors } from "../../../common/constants";
import Button from "../../../layouts/Button";
import HFlex from "../../../layouts/HFlex";
import Image from "../../../layouts/Image";
import Text from "../../../layouts/Text";
import VFlex from "../../../layouts/VFlex";
import { PopupKind } from "../../../popup/Popup";
import { pushPopup } from "../../../providers/popups";
import { useStaffs } from "../../../providers/staffs";
import AddStaff from "./AddStaff";
import { usePartner } from "../../../providers/partner";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";

function Staffs({
  setSelectedSubpage,
}: {
  setSelectedSubpage: (component?: ReactElement) => void;
}) {
  const { staffs } = useStaffs();
  const { partner } = usePartner();
  const { trackLandStaffs, trackAddStaff } = useAmplitudeTrack();

  useEffect(() => {
    trackLandStaffs();
  }, []);

  return (
    <VFlex f-1 a-st>
      <SettingsTitle title="직원 목록" setSelectedSubpage={setSelectedSubpage}>
        <Button
          type={"add"}
          onClick={() => {
            pushPopup({ kind: PopupKind.Drawer, element: AddStaff });
            trackAddStaff(partner.title);
          }}
        >
          <HFlex g-4 a-c>
            <Image size={24} src={`/icons/icon_add.png`} />
            <Text t-14-700-w>직원 등록</Text>
          </HFlex>
        </Button>
      </SettingsTitle>
      <VFlex f-1 ovf-a>
        <VFlex f-1 g-24 m-32-t p-32-rl>
          <VFlex bd-tbl-t3 m-32-b style={{ minWidth: 800 }}>
            <HFlex a-c height={54} bc-t1 bd-b-t3 a-st>
              <HFlex a-c bd-r-t3 p-16-rl f-1>
                <Text t-14-500-s4>이름</Text>
              </HFlex>
              <HFlex a-c bd-r-t3 p-16-rl f-1>
                <Text t-14-500-s4>직책</Text>
              </HFlex>
              <HFlex a-c bd-r-t3 p-16-rl f-1>
                <Text t-14-500-s4>휴대폰 번호</Text>
              </HFlex>
              <HFlex a-c bd-r-t3 p-16-rl f-1>
                <Text t-14-500-s4>지정 색깔</Text>
              </HFlex>
            </HFlex>
            {staffs.map((staff, i) => (
              <HFlex
                key={i}
                a-c
                height={54}
                bd-b-t3={i !== staffs.length - 1}
                a-st
                classes={["table-row"]}
                clickable
                onClick={() =>
                  pushPopup({
                    kind: PopupKind.Drawer,
                    element: AddStaff,
                    props: { staff },
                  })
                }
              >
                <HFlex a-c bd-r-t3 p-16-rl f-1 g-8>
                  <Image
                    size={22}
                    bdr-8
                    bc-s4
                    src={staff.attachment?.path ?? `/profile_example.png`}
                  />
                  <Text t-14-500-s4>{staff.name}</Text>
                </HFlex>
                <HFlex a-c bd-r-t3 p-16-rl f-1 g-8>
                  <Text t-14-500-s4>{staff.jobTitle}</Text>
                </HFlex>
                <HFlex a-c bd-r-t3 p-16-rl f-1 g-8>
                  <Text t-14-500-s4>{staff.workingCellNumber}</Text>
                </HFlex>
                <HFlex a-c bd-r-t3 p-16-rl f-1 g-8>
                  <HFlex
                    width={22}
                    height={22}
                    bdr-24
                    style={{
                      backgroundColor: staffColors[staff.color]?.mainColor,
                    }}
                  />
                </HFlex>
              </HFlex>
            ))}
          </VFlex>
        </VFlex>
      </VFlex>
    </VFlex>
  );
}

export default Staffs;
