import _ from "lodash";
import { TextareaHTMLAttributes, useRef, useState } from "react";
import Absolute from "./Absolute";
import HFlex from "./HFlex";
import {
  ColorProps,
  FontProps,
  LineProps,
  propsToClasses,
  restOfProps,
} from "./index_generated";
import "./layout.scss";
import Text from "./Text";
import VFlex from "./VFlex";

type TextAreaProps = {
  classes?: string[];
  placeholder?: string;
  required?: boolean;
  caption?: string;
  disabled?: boolean;
  height?: number;
  value?: string;
  onChangeValue?: (value: string | undefined) => void;
} & TextareaHTMLAttributes<HTMLTextAreaElement> &
  FontProps &
  ColorProps &
  FontProps &
  LineProps;

const TextArea = ({
  classes = [],
  placeholder,
  caption,
  required,
  value,
  disabled,
  height,
  onChangeValue,
  ...props
}: TextAreaProps) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [focused, setFocused] = useState<boolean>(false);
  return (
    <VFlex g-8 rel unclickable={disabled} clickable={!disabled}>
      {caption && (
        <HFlex g-8>
          <Text t-14-s8>{caption}</Text>
          {required && <Text t-14-700-rd1>*</Text>}
        </HFlex>
      )}
      <VFlex
        bc-t1
        rel
        bdr-16
        bd-t3={!focused}
        bd-gr5={focused}
        p-16-rl
        p-16-tb
        height={height}
        onClick={() => {
          setFocused(true);
          inputRef.current?.focus();
        }}
      >
        <Absolute left={16} top={13} unclickable>
          {(!!caption ? !value : !value && !!placeholder) && (
            <Text t-16-s1 transition>
              {placeholder}
            </Text>
          )}
        </Absolute>
        <textarea
          ref={inputRef}
          style={{
            height: "100%",
            width: "100%",
            fontWeight: 500,
            fontSize: 16,
          }}
          className={_.compact([...propsToClasses(props), ...classes]).join(
            " "
          )}
          value={value}
          autoFocus={false}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onChange={(e) => {
            onChangeValue?.(e.target.value);
          }}
          {...restOfProps(props)}
        />
      </VFlex>
    </VFlex>
  );
};

export default TextArea;
