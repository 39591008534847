import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { Panel } from "./types";

export const panelStore = create(
  persist<Panel>(
    (set, get) => ({
      panelCollapsed: true,
      setPanelCollapsed: () => set({ panelCollapsed: !get().panelCollapsed }),
    }),
    {
      name: "panel",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export const usePanel = () => {
  const { ...rest } = panelStore();
  return {
    ...rest,
  };
};
