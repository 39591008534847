import { InputHTMLAttributes, useRef, useState } from "react";
import HFlex from "../layouts/HFlex";
import Image from "../layouts/Image";

function PetSearchInput({
  className,
  placeholder,
  value,
  onChangeValue,
  ...props
}: InputHTMLAttributes<HTMLInputElement> & {
  onChangeValue: (value: string) => void;
  [key: string]: any;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchText, setSearchText] = useState<string>();
  return (
    <HFlex
      height={40}
      bc-t0
      ovf-h
      bd-t3
      bdr-8
      g-12
      a-c
      p-16-rl
      rel
      onClick={() => {
        inputRef.current?.focus();
      }}
      style={{
        position: searchText ? "absolute" : "static",
        left: searchText ? 32 : "",
        width: searchText ? 528 : 240,
        cursor: "text",
      }}
    >
      <Image size={24} src={`/icons/icon_search.svg`} />
      <HFlex a-c f-1 t-14-s2 j-b>
        <input
          placeholder={placeholder}
          ref={inputRef}
          value={value ?? ""}
          onChange={(e) => {
            onChangeValue(e.target.value);
            setSearchText(e.target.value);
          }}
          style={{ width: 100, fontSize: 14 }}
          {...props}
        />
        <Image
          clickable
          size={18}
          src={`/icons/input_cancel.png`}
          style={
            value
              ? { pointerEvents: "auto", opacity: 1 }
              : { pointerEvents: "none", opacity: 0 }
          }
          onClick={(e) => {
            onChangeValue("");
            setSearchText("");
            e.stopPropagation();
          }}
        />
      </HFlex>
    </HFlex>
  );
}

export default PetSearchInput;
