import { useMemo, useState } from "react";

import { pushPopup, usePopups } from "../../../providers/popups";
import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Flex from "../../../layouts/Flex";
import Image from "../../../layouts/Image";
import Text from "../../../layouts/Text";
import TabBar, { TabItemType } from "../../../components/TabBar";
import Divider from "../../../layouts/Divider";
import TextArea from "../../../layouts/TextArea";
import React from "react";
import TextInput from "../../../layouts/TextInput";
import Button from "../../../layouts/Button";
import GroupRadioButton from "../../../components/GroupRadioButton";
import {
  Memo,
  MEMO_STATUS_VALUE,
  NoticeMemoType,
} from "../../../providers/types";
import { format } from "date-fns";
import { ko } from "date-fns/locale";
import { isNil } from "lodash";
import { useNotices } from "../../../providers/notices";
import { toast } from "react-hot-toast";
import { PopupAlign, PopupKind } from "../../../popup/Popup";
import ConfirmPopup from "../../../popup/ConfirmPopup";
import FilesUpload from "../../../components/FilesUpload";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";

const keys = <T extends object>(o: T): (keyof T)[] =>
  Object.keys(o) as (keyof T)[];

function SendNotice({
  reservationId,
  petName,
  initialIndex = 0,
  loadSchedule,
}: {
  reservationId: number;
  petName: string;
  loadSchedule: () => void;
  initialIndex: number;
}) {
  const { trackClickSaveServiceNote } = useAmplitudeTrack();
  const [memos, setMemos] = useState<Memo<keyof typeof NoticeMemoType>[]>(
    keys(NoticeMemoType).map((t, i) => ({ type: t, displayOrder: i }))
  );
  const [photos, setPhotos] = useState<[]>([]);

  const tabs = useMemo<TabItemType[]>(() => {
    return [
      {
        key: "1",
        name: "매너 및 컨디션",
        component: MemoTab,
      },
      {
        key: "2",
        name: "그외 전달사항",
        component: EtcMemoTab,
      },
    ];
  }, [photos, memos, setMemos]);

  const [selectedTab, setSelectedTab] = useState<TabItemType>(
    tabs[initialIndex]
  );

  const isSavable = useMemo(() => {
    return (
      !isNil(memos.find((memo) => memo.type === "N_B_MANNER")?.value) &&
      !isNil(memos.find((memo) => memo.type === "C_COND")?.value)
    );
  }, [memos]);

  const { popPopup } = usePopups();
  const { fetchPostNotice } = useNotices();

  return (
    <VFlex f-1 ovf-a style={{ position: "relative" }}>
      <HFlex p-24-t p-32-rl>
        <Flex f-1 />
        <Image
          size={24}
          clickable
          src={`/icons/popup_close.png`}
          alt="popup_close"
          onClick={() => {
            popPopup();
          }}
        />
      </HFlex>
      <VFlex m-32-rl m-24-b m-16-t>
        <Text t-20-700 style={{ color: "#383B3A" }}>
          <Text c-gr5>{petName}</Text>의 알림장
        </Text>
        <Text t-16-500-s4>
          {format(new Date(), "yyyy년 M월 dd일 (EEEEE)", {
            locale: ko,
          })}
        </Text>
      </VFlex>

      <Flex p-32-rl height={42}>
        <TabBar
          value={selectedTab}
          items={tabs}
          onChange={(item) => setSelectedTab(item)}
        />
      </Flex>
      <Divider height={1} />

      <Flex p-24-t p-32-rl ovf-a style={{ paddingBottom: "120px" }}>
        {selectedTab &&
          React.createElement(selectedTab.component, {
            photos,
            memos,
            setMemos,
            setPhotos,
          })}
      </Flex>

      {/* TODO align */}
      <HFlex
        p-24-tb
        p-32-rl
        g-8
        bd-t
        bc-w
        width={600}
        style={{ position: "fixed", bottom: 0 }}
      >
        <Flex f-1 />
        <Button
          type="cancel"
          onClick={() => {
            pushPopup({
              kind: PopupKind.Popup,
              element: ConfirmPopup,
              align: PopupAlign.TopCenter,
              props: {
                title: "알림장 작성을 그만할까요?",
                confirmButtonLabel: "저장하지 않고 나가기",
                onConfirm: () => {
                  popPopup();
                },
              },
            });
          }}
        >
          취소
        </Button>
        <Button
          type="confirm"
          enabled={isSavable}
          onClick={async () => {
            await fetchPostNotice({ reservationId, photos, memos });
            toast.success("알림장이 발송되었습니다");
            popPopup();
            await loadSchedule();
            trackClickSaveServiceNote();
          }}
        >
          저장하기
        </Button>
      </HFlex>
    </VFlex>
  );
}

function EtcMemoTab({
  photos,
  memos,
  setMemos,
  setPhotos,
}: {
  photos: File[];
  memos: Memo<keyof typeof NoticeMemoType>[];
  setMemos: (Memo: Memo<keyof typeof NoticeMemoType>[]) => void;
  setPhotos: (photos: File[]) => void;
}) {
  return (
    <VFlex g-16>
      <TextArea
        caption="전달사항"
        placeholder="전달사항 입력"
        height={56}
        value={memos[7].note}
        onChange={(e) => {
          const newMemos = [...memos];

          const newMemo: Memo<keyof typeof NoticeMemoType> = {
            type: memos[7]?.type,
            value: memos[7]?.value,
            note: e.target.value,
            displayOrder: memos[7].displayOrder,
          };
          newMemos[7] = newMemo;

          setMemos(newMemos);
        }}
      />

      <VFlex g-8>
        <Text t-14-500-s7>사진 등록하기</Text>
        <HFlex g-8>
          <label htmlFor="notice-attachments-upload">
            <Image src="/icons/icon_addimgfile.png" clickable size={58} />
            <FilesUpload
              id="notice-attachments-upload"
              multiple
              onLoad={(files: File[]) => {
                setPhotos(files);
              }}
            />
          </label>

          {photos?.map((photo, index) => (
            <Flex style={{ position: "relative" }} key={photo.name}>
              <Flex
                c-c
                width={16}
                height={16}
                bc-s8
                bdr-16
                p-2
                clickable
                style={{ position: "absolute", right: 0, top: 0 }}
              >
                <Image
                  src="/icons/delete_photo.svg"
                  size={12}
                  onClick={() => {
                    const newPhotos = photos.filter((_, i) => i !== index);
                    setPhotos(newPhotos);
                  }}
                />
              </Flex>
              <Image src={URL.createObjectURL(photo)} bdr-24 size={58} />
            </Flex>
          ))}
        </HFlex>
      </VFlex>
    </VFlex>
  );
}

function MemoTab({
  memos,
  setMemos,
}: {
  memos: Memo<keyof typeof NoticeMemoType>[];
  setMemos: (Memo: Memo<keyof typeof NoticeMemoType>[]) => void;
}) {
  return (
    <VFlex g-16>
      <VFlex g-16>
        <GroupRadioButton
          value={
            !isNil(memos[0]?.value)
              ? {
                  label: MEMO_STATUS_VALUE[memos[0]?.value],
                  value: memos[0]?.value,
                }
              : undefined
          }
          required
          caption={NoticeMemoType[memos[0]?.type]}
          options={[
            { label: "좋아요", value: 0 },
            { label: "힘들어 했어요", value: 5 },
            { label: "직접 입력", value: 1 },
          ]}
          onChange={(e) => {
            if (!isNil(e?.value)) {
              const newMemos = [...memos];

              const newMemo: Memo<keyof typeof NoticeMemoType> = {
                type: memos[0]?.type,
                value: e?.value,
                note: memos[0]?.note,
                displayOrder: memos[0].displayOrder,
              };
              newMemos[0] = newMemo;

              setMemos(newMemos);
            } else {
              const newMemos = [...memos];

              const newMemo: Memo<keyof typeof NoticeMemoType> = {
                type: memos[0].type,
                value: undefined,
                note: memos[0]?.note,
                displayOrder: memos[0].displayOrder,
              };
              newMemos[0] = newMemo;

              setMemos(newMemos);
            }
          }}
        />
        {memos[0].value == 1 && (
          <TextInput
            value={memos[0].note}
            onChange={(e) => {
              const newMemos = [...memos];

              const newMemo: Memo<keyof typeof NoticeMemoType> = {
                type: memos[0]?.type,
                value: memos[0]?.value,
                note: e.target.value,
                displayOrder: memos[0].displayOrder,
              };
              newMemos[0] = newMemo;

              setMemos(newMemos);
            }}
          />
        )}
      </VFlex>
      {memos.slice(1, 6).map((i, index) => {
        return (
          <VFlex key={i.type} g-16>
            <GroupRadioButton
              value={
                !isNil(i.value)
                  ? {
                      value: i.value,
                      label: MEMO_STATUS_VALUE[Number(i?.value)],
                    }
                  : undefined
              }
              required={i.type === "C_COND"}
              caption={NoticeMemoType[i.type]}
              options={[
                { label: "좋아요", value: 0 },
                { label: "보통", value: 1 },
                { label: "관찰이 필요해요", value: 2 },
              ]}
              // TODO
              onChange={(e) => {
                if (!isNil(e?.value)) {
                  const newMemos = [...memos];

                  const newMemo: Memo<keyof typeof NoticeMemoType> = {
                    type: i.type,
                    value: e?.value,
                    note: i?.note,
                    displayOrder: i.displayOrder,
                  };
                  newMemos[index + 1] = newMemo;

                  setMemos(newMemos);
                } else {
                  const newMemos = [...memos];

                  const newMemo: Memo<keyof typeof NoticeMemoType> = {
                    type: i.type,
                    value: undefined,
                    note: i?.note,
                    displayOrder: i.displayOrder,
                  };
                  newMemos[index + 1] = newMemo;

                  setMemos(newMemos);
                }
              }}
            />
            {i.value == 2 && (
              <TextInput
                value={i.note}
                onChange={(e) => {
                  const newMemos = [...memos];

                  const newMemo: Memo<keyof typeof NoticeMemoType> = {
                    type: memos[index + 1]?.type,
                    value: memos[index + 1]?.value,
                    note: e.target.value,
                    displayOrder: memos[index + 1].displayOrder,
                  };
                  newMemos[index + 1] = newMemo;

                  setMemos(newMemos);
                }}
              />
            )}
          </VFlex>
        );
      })}

      <GroupRadioButton
        value={
          !isNil(memos[6]?.value)
            ? {
                label: MEMO_STATUS_VALUE[memos[6]?.value],
                value: memos[6].value,
              }
            : undefined
        }
        caption={NoticeMemoType[memos[6]?.type]}
        options={[
          { label: "없어요", value: 3 },
          { label: "있어요", value: 4 },
        ]}
        onChange={(e) => {
          if (!isNil(e?.value)) {
            const newMemos = [...memos];

            const newMemo: Memo<keyof typeof NoticeMemoType> = {
              type: memos[6]?.type,
              value: e?.value,
              note: memos[6]?.note,
              displayOrder: memos[6].displayOrder,
            };
            newMemos[6] = newMemo;

            setMemos(newMemos);
          } else {
            const newMemos = [...memos];

            const newMemo: Memo<keyof typeof NoticeMemoType> = {
              type: memos[6].type,
              value: undefined,
              note: memos[6]?.note,
              displayOrder: memos[6].displayOrder,
            };
            newMemos[6] = newMemo;

            setMemos(newMemos);
          }
        }}
      />
    </VFlex>
  );
}

export default SendNotice;
