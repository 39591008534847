import { useEffect, useState } from "react";

import api from "../../../../common/api";
import { scheduleStatusColor } from "../../../../common/constants";
import {
  Guardian,
  InquiryDetailType,
  ScheduleStatus,
} from "../../../../providers/types";
import { pushPopup } from "../../../../providers/popups";
import { useInquiries } from "../../../../providers/inquiries";

import VFlex from "../../../../layouts/VFlex";
import Text from "../../../../layouts/Text";
import HFlex from "../../../../layouts/HFlex";
import Image from "../../../../layouts/Image";
import Absolute from "../../../../layouts/Absolute";

import { PopupAlign, PopupKind } from "../../../../popup/Popup";
import ConfirmPopup from "../../../../popup/ConfirmPopup";
import PhotoPreview from "../../../../components/PhotoPreview";
import NewReservation from "../NewReservtaion";

import toast from "react-hot-toast";
import { format } from "date-fns";
import { ko } from "date-fns/locale";
import CopyToClipboard from "react-copy-to-clipboard";

const INQUIRY_STATUS = [
  "문의 진행중",
  "문의 종료",
  "문의 종료",
  "예약 등록 완료",
];

const InquiryInfo = ({
  inquiryId,
  popPopup,
  setSelectedDetailTab,
  tabs,
}: {
  inquiryId: number;
  popPopup: () => void;
  setSelectedDetailTab: any;
  tabs: any;
}) => {
  const { fetchInquiries } = useInquiries();
  const [reservationInfo, setReservationInfo] = useState<InquiryDetailType>();

  const fetchInquiryDetailInfo = async () => {
    const reservation = await api.get(
      `/rest/reservation/inquiry/${inquiryId}/detail`
    );
    setReservationInfo(reservation);
  };

  useEffect(() => {
    fetchInquiryDetailInfo();
  }, []);

  const openAddReservationPopup = () => {
    pushPopup({
      key: "inquiryReservation",
      kind: PopupKind.Popup,
      element: NewReservation,
      dimClick: "none",
      props: {
        inquiryData: {
          id: reservationInfo?.id,
          inquiredDateTime: reservationInfo?.inquiredDateTime,
          reservationDate: reservationInfo?.reservationDate,
          time: reservationInfo?.time,
          products: reservationInfo?.products,
        },
        selectedCustomer: {
          id: reservationInfo?.pet.petInfo.id,
          name: reservationInfo?.pet.petInfo.name,
          species: reservationInfo?.pet.petInfo.species.name,
          attachment: reservationInfo?.pet.petInfo.attachment,
          guardianList: reservationInfo?.pet.guardians,
        },
      },
    });
    popPopup();
  };

  const closeInquiry = () => {
    pushPopup({
      kind: PopupKind.Popup,
      key: "inquiryEnd",
      element: ConfirmPopup,
      align: PopupAlign.TopCenter,
      props: {
        title: `문의를 취소하시겠어요?\n문의 취소시, 고객에게 알림톡이 전송됩니다.`,
        confirmButtonLabel: "문의 취소하고 알림톡 보내기",
        onConfirm: () => {
          (async () => {
            await api.put(
              `/rest/reservation/${inquiryId}/cancel?accountType=PARTNER&type=INQUIRY`
            );
            await fetchInquiries({ page: 0 });
          })();
          toast.success("문의가 취소되었습니다");
          popPopup();
        },
      },
    });
  };

  return (
    <>
      {reservationInfo?.unCompletedOnboardings &&
        reservationInfo?.unCompletedOnboardings.map((v) => v.type).length !==
          0 && (
          <VFlex bc-w>
            <SafequestionaireBanner
              type={!!reservationInfo?.groomingQuestionnaire ? 0 : 1}
              setSelectedDetailTab={setSelectedDetailTab}
              tabs={tabs}
              reservationId={reservationInfo?.id ?? 0}
              fetchCurrentPage={fetchInquiryDetailInfo}
            />
          </VFlex>
        )}

      <VFlex p-24 g-12>
        <ReservationStatus
          status={reservationInfo?.status ?? 0}
          inquiredDateTime={reservationInfo?.inquiredDateTime ?? 0}
          openAddReservationPopup={openAddReservationPopup}
          closeInquiry={closeInquiry}
        />

        <VFlex p-24-trl p-16-b bc-w bdr-16 g-8 ovf-v>
          <HFlex a-c j-b>
            <HFlex g-12 a-c>
              <Text t-20-600-s8>문의 정보</Text>
              <Text t-14-s4>{reservationInfo?.reservationNumber}</Text>
            </HFlex>
          </HFlex>
          <VFlex>
            <HFlex j-b a-s bd-b-t2 p-12-tb g-16>
              <HFlex g-12 a-c>
                <Image size={22} src={`/icons/action/event_available_s6.svg`} />
                <Text t-16-s6>희망 예약 날짜</Text>
              </HFlex>
              <VFlex
                t-16-600-s8
                style={{
                  textAlign: "right",
                }}
              >
                <Text>
                  {reservationInfo &&
                    format(
                      reservationInfo?.reservationDate * 1000,
                      "yyyy년 M월 d일 (E)",
                      {
                        locale: ko,
                      }
                    )}
                </Text>
              </VFlex>
            </HFlex>
            <HFlex j-b a-s bd-b-t2 p-12-tb g-16>
              <HFlex g-12 a-c>
                <Image size={22} src={`/icons/action/schedule_s6.svg`} />
                <Text t-16-s6>희망 시작 시간</Text>
              </HFlex>
              <VFlex
                t-16-600-s8
                style={{
                  textAlign: "right",
                }}
              >
                <Text>{reservationInfo?.time.map((v) => v).join(", ")}</Text>
              </VFlex>
            </HFlex>
            <HFlex
              j-b
              a-s
              bd-b-t2={!!reservationInfo?.requestNote}
              p-12-tb
              g-16
            >
              <HFlex g-12 a-c>
                <Image size={22} src={`/icons/action/scissors_s6.svg`} />
                <Text t-16-s6>희망 서비스</Text>
              </HFlex>
              <Text
                t-16-600-s8
                l-m
                style={{
                  textAlign: "right",
                }}
              >
                {reservationInfo?.products
                  .map((v) => {
                    return v.name;
                  })
                  .join(", ")}
              </Text>
            </HFlex>
            {reservationInfo?.requestNote && (
              <HFlex p-12-tb g-12 a-s>
                <Image
                  size={22}
                  src={`/icons/action/sticky_note_outline_s6.svg`}
                />
                <VFlex g-8>
                  <Text t-16-s6>고객 요청사항</Text>
                  <Text t-14-s6 l-m>
                    {reservationInfo?.requestNote}
                  </Text>
                  {reservationInfo.attachments && (
                    <HFlex g-8>
                      <PhotoPreview photos={reservationInfo.attachments} />
                    </HFlex>
                  )}
                </VFlex>
              </HFlex>
            )}
          </VFlex>
        </VFlex>
        {reservationInfo && (
          <GuardianInfo guardian={reservationInfo.guardian} />
        )}
      </VFlex>
    </>
  );
};

export default InquiryInfo;

const SafequestionaireBanner = ({
  type,
  setSelectedDetailTab,
  tabs,
  reservationId,
  fetchCurrentPage,
}: {
  type: number;
  setSelectedDetailTab: any;
  tabs: any;
  reservationId: number;
  fetchCurrentPage?: () => void;
}) => {
  const [showBanner, setShowBanner] = useState(true);

  return (
    <HFlex
      p-24-rl
      a-c
      j-b
      height={54}
      style={{
        display: showBanner ? "flex" : "none",
        background:
          type === 1 ? "rgba(255, 79, 23, 0.20)" : "rgba(49, 138, 255, 0.20)",
      }}
    >
      <HFlex g-12>
        <Image size={22} src={`/icons/alert/notifications_unread_s6.svg`} />
        {type === 1 ? (
          <Text t-14-600-s8>고객이 안전문진표를 작성하지 않았습니다.</Text>
        ) : (
          <Text t-14-600-s8>
            고객이 안전문진표 작성을 완료했습니다.
            <Text
              m-8-l
              t-14-500-b1
              clickable
              onClick={async () => {
                setSelectedDetailTab(1);
                await api.post(
                  `/rest/reservation/${reservationId}/onboarding?type=INQ_RESV_CHECK_QUESTIONNAIRE`
                );
                if (fetchCurrentPage) fetchCurrentPage();
              }}
            >
              확인하기
            </Text>
          </Text>
        )}
      </HFlex>
      <Image
        size={22}
        src="/icons/close.svg"
        onClick={async () => {
          setShowBanner(false);
          await api.post(
            `/rest/reservation/${reservationId}/onboarding?type=${
              type === 1
                ? "INQ_RESV_UNWRITTEN_QUESTIONNAIRE"
                : "INQ_RESV_CHECK_QUESTIONNAIRE"
            }`
          );
          if (fetchCurrentPage) fetchCurrentPage();
        }}
        clickable
      />
    </HFlex>
  );
};

const ReservationStatus = ({
  status,
  inquiredDateTime,
  openAddReservationPopup,
  closeInquiry,
}: {
  status: ScheduleStatus;
  inquiredDateTime: number;
  openAddReservationPopup: () => void;
  closeInquiry: () => void;
}) => {
  const [statusVisible, setStatusVisible] = useState(false);
  const [isCompleteHovered, setIsCompleteHovered] = useState(false);
  const [isCancelHovered, setIsCancelHovered] = useState(false);

  return (
    <VFlex p-24 bc-w bdr-16 g-6 ovf-v>
      <HFlex a-c g-12 rel>
        <Image size={24} src={`/icons/state/check.svg`} />
        <HFlex g-4 a-c>
          <Text
            t-18-600
            style={{
              color: scheduleStatusColor[status],
            }}
          >
            {INQUIRY_STATUS[status]}
          </Text>

          {status === 0 && (
            <Image
              size={24}
              src={`/icons/navigation/${
                statusVisible ? "arrow_drop_up" : "arrow_drop_down"
              }_s6.svg`}
              clickable
              onClick={() => {
                setStatusVisible((prev) => !prev);
              }}
            />
          )}

          {statusVisible && (
            <Absolute style={{ left: 0, top: 30, zIndex: 2 }}>
              <VFlex width={180} p-8 g-4 bdr-12 bc-w sdw-6>
                <Text
                  p-8
                  t-14-500-s8
                  clickable
                  bdr-8
                  bc-t2={isCompleteHovered}
                  onMouseEnter={() => setIsCompleteHovered(true)}
                  onMouseLeave={() => setIsCompleteHovered(false)}
                  onClick={() => {
                    openAddReservationPopup();
                    setStatusVisible(false);
                  }}
                >
                  문의 확정
                </Text>
                <Text
                  p-8
                  t-14-500-r1
                  clickable
                  bdr-8
                  bc-t2={isCancelHovered}
                  onMouseEnter={() => setIsCancelHovered(true)}
                  onMouseLeave={() => setIsCancelHovered(false)}
                  onClick={() => {
                    closeInquiry();
                    setStatusVisible(false);
                  }}
                >
                  문의 취소
                </Text>
              </VFlex>
            </Absolute>
          )}
        </HFlex>
      </HFlex>
      <Text t-14-s4>
        문의 일시 : {format(inquiredDateTime * 1000, "M월 d일 HH:mm")}
      </Text>
    </VFlex>
  );
};

const GuardianInfo = ({ guardian }: { guardian: Guardian }) => {
  return (
    <VFlex p-24 bc-w bdr-16 g-8>
      <HFlex g-12 a-c>
        <Text t-20-600-s8>예약자 정보</Text>
      </HFlex>
      <VFlex g-16>
        <HFlex j-b a-c bd-b-t2 p-12-tb>
          <HFlex g-8 a-c>
            <Text t-16-s8>{guardian.name}</Text>
            {guardian.primaryGuardian && (
              <VFlex t-14-s6 p-2-tb p-8-rl bd-t4 bdr-8>
                대표 보호자
              </VFlex>
            )}
          </HFlex>
          <CopyToClipboard
            text={guardian.cellnumber}
            onCopy={() => {
              toast.success("전화번호가 복사되었습니다.");
            }}
          >
            <HFlex g-16 a-c clickable>
              <Text t-14-s6>{guardian.cellnumber}</Text>
              <Image size={22} src={`/icons/content/copy_s6.svg`} />
            </HFlex>
          </CopyToClipboard>
        </HFlex>
        {guardian.note ? (
          <Text t-14-s6 l-m>
            {guardian.note}
          </Text>
        ) : (
          <Text t-14-s2>작성된 내용이 없습니다.</Text>
        )}
      </VFlex>
    </VFlex>
  );
};
