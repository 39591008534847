import { Dispatch, PropsWithChildren, SetStateAction, useState } from "react";

import VFlex from "../layouts/VFlex";
import HFlex from "../layouts/HFlex";
import Flex from "../layouts/Flex";
import Image from "../layouts/Image";
import Text from "../layouts/Text";
import Search from "../layouts/Search";

interface DropdownWithSearchProps extends PropsWithChildren {
  keyword: string;
  setKeyword: (k: string) => void;
  resetSelect: () => void;
  removeFilter: () => void;
}

const DropdownWithSearch = ({
  children,
  keyword,
  setKeyword,
  resetSelect,
  removeFilter,
}: DropdownWithSearchProps) => {
  return (
    <VFlex
      width={240}
      p-8
      p-10-t
      bdr-12
      bc-tw
      style={{
        boxShadow:
          "0px 4px 8px 1px rgba(0, 0, 0, 0.20), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)",
      }}
    >
      <HFlex a-c m-10-b>
        <HFlex a-c clickable onClick={resetSelect}>
          <Image size={20} src="/icons/refresh.svg" m-4-r />
          <Text t-14-500-s6>모든 항목 선택 해제</Text>
        </HFlex>
        <Flex f-1 />
        <Image
          size={20}
          src="/icons/delete.svg"
          clickable
          onClick={removeFilter}
        />
      </HFlex>
      <Search
        size="S"
        type="outline"
        placeholder="검색"
        value={keyword}
        onChangeValue={setKeyword}
      />
      <VFlex m-8-t ovf-a style={{ maxHeight: "190px" }}>
        {children}
      </VFlex>
    </VFlex>
  );
};

interface ListItemProps extends PropsWithChildren {
  selected: boolean;
  handleClick: () => void;
}

const ListItem = ({ children, selected, handleClick }: ListItemProps) => {
  return (
    <HFlex
      j-b
      a-c
      p-8
      bdr-8
      clickable
      sized
      onClick={handleClick}
      classes={["hover"]}
    >
      <Flex>{children}</Flex>
      <Image
        src={`/icons/${
          selected ? "checkbox_checked.png" : "checkbox_unchecked.png"
        }`}
        size={20}
      />
    </HFlex>
  );
};

const EmptySearchResult = () => {
  return (
    <HFlex p-8>
      <Text t-14-500-s4>검색 결과가 없습니다.</Text>
    </HFlex>
  );
};

DropdownWithSearch.ListItem = ListItem;
DropdownWithSearch.EmptySearchResult = EmptySearchResult;

export default DropdownWithSearch;
