import { PropsWithChildren } from "react";

import "./FilterChip.scss";

import HFlex from "./HFlex";
import Image from "./Image";
import Flex from "./Flex";

interface FilterChipProps extends PropsWithChildren {
  size?: "S" | "M" | "L";
  type?: "selected";
  prefixIcon?: string;
  prefixIconSize?: number;
  suffixIcon?: string;
  suffixIconSize?: number;
  onClick?: () => void;
}

function FilterChip({
  type,
  size = "S",
  prefixIcon,
  prefixIconSize,
  suffixIcon,
  suffixIconSize,
  onClick,
  children,
}: FilterChipProps) {
  return (
    <Flex
      className={`filter-chip ${type || ""} ${size} sized a-c`}
      onClick={onClick}
    >
      <HFlex g-4 a-c>
        {prefixIcon && <Image src={prefixIcon} size={prefixIconSize} />}
        <span className="l-1">{children}</span>
        {suffixIcon && <Image src={suffixIcon} size={suffixIconSize} />}
      </HFlex>
    </Flex>
  );
}

export default FilterChip;
