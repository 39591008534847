import { ReactElement, useEffect, useMemo, useRef, useState } from "react";

import api from "../../../common/api";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";

import Text from "../../../layouts/Text";
import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Image from "../../../layouts/Image";
import Flex from "../../../layouts/Flex";
import Divider from "../../../layouts/Divider";

import NewReservation from "./NewReservtaion";
import InquiryDetail from "./InquiryDetail/index";
import ConfirmPopup from "../../../popup/ConfirmPopup";

import { CalendarType, InquiryListType } from "../../../providers/types";
import { useSchedules } from "../../../providers/schedules";
import { useMainTabIndex } from "../../../providers/mainTabIndex";
import { pushPopup } from "../../../providers/popups";
import { PopupAlign, PopupKind } from "../../../popup/Popup";
import { useInquiries } from "../../../providers/inquiries";

import Select, { GroupBase, OptionsOrGroups } from "react-select";
import toast from "react-hot-toast";
import _ from "lodash";
import { format } from "date-fns";
import { ko } from "date-fns/locale";

export type DropdownItem = {
  value: any;
  label: string;
  obj?: any;
  icon?: string;
  status?: boolean;
};

function InquiryDropdown({
  value,
  values,
  isDisabled,
  onChange,
  options,
}: {
  value?: any;
  values?: any[];
  isDisabled?: boolean;
  onChange?: (value: DropdownItem | undefined) => void;
  options: OptionsOrGroups<DropdownItem, GroupBase<DropdownItem>>;
}): ReactElement {
  const flattenedOptions = useMemo(
    () =>
      _.flatten(
        options.map((option) =>
          (option as GroupBase<DropdownItem>).options
            ? (option as GroupBase<DropdownItem>).options
            : [option as DropdownItem]
        )
      ),
    [options]
  );
  const [innerValue, setInnerValue] = useState<DropdownItem | undefined>(
    flattenedOptions.find((option) => option.value === value)
  );
  useEffect(() => {
    setInnerValue(flattenedOptions.find((option) => option.value === value));
  }, [value, values, flattenedOptions, setInnerValue]);

  return (
    <Select
      key={innerValue?.value.toString()}
      isDisabled={isDisabled}
      styles={{
        placeholder: () => ({ display: "none" }),
        option: (_provided, state) => ({
          width: "100%",
          height: "38px",
          color: "#35493D",
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
          backgroundColor: state.isFocused ? "#F5F5F5" : "white",
          backgroundSize: "18px 18px",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "left 10px center",
          borderRadius: "8px",
          fontSize: "14px",
          lineHeight: "22px",
          padding: "8px 10px",
          WebkitUserSelect: "none",
          msUserSelect: "none",
          userSelect: "none",
          cursor: "pointer",
        }),
        container: () => ({
          flex: 1,
        }),
        control: () => ({
          flex: 1,
          display: "flex",
          height: "38px",
          backgroundColor: "#fff",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "#DEE2E0",
          color: "#383B3A",
          borderRadius: "12px",
          alignItems: "center",
          WebkitUserSelect: "none",
          msUserSelect: "none",
          userSelect: "none",
          cursor: "pointer",
        }),

        indicatorsContainer: () => ({}),
        indicatorSeparator: () => ({}),
        valueContainer: () => ({
          flex: 1,
          display: "flex",
          marginLeft: "16px",
        }),
        menu: () => ({
          marginTop: "8px",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "#E9E9EA",
          borderRadius: "12px",
          position: "absolute",
          backgroundColor: "white",
          top: "100%",
          width: "100%",
          maxHeight: "190px",
          boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.2)",
        }),
        menuPortal: (provided) => ({ ...provided, zIndex: 100000000000 }),
        menuList: (provided) => ({
          ...provided,
          maxHeight: "190px",
          padding: "8px",
        }),
        dropdownIndicator: () => ({
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#1D201F",
          width: "24px",
          height: "24px",
          marginRight: "24px",
          overflow: "scroll",
          cursor: "pointer",
        }),
        singleValue: () => {
          return {
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "22px",
          };
        },
      }}
      formatOptionLabel={(data) => (
        <div className="f-r-c g-16">
          {!!data.icon && (
            <img className="img-24" src={`/${data.icon}.png`} alt={data.icon} />
          )}
          <div
            className="f-r-c g-8 line"
            style={{
              color: data.label === "문의 취소" ? "#FF4F17" : "#383B3A",
            }}
          >
            {data.label}
            {!!data.status && <div className="img-8 bc-gr bdr-8" />}
          </div>
        </div>
      )}
      components={{
        DropdownIndicator: () => (
          <div
            className="dropdown-indicator p-10-r"
            style={{ border: "none", display: "flex", alignItems: "center" }}
          >
            <img
              className="img-18"
              src={`/icons/navigation/arrow_drop_down.svg`}
              alt="icon_dropdown"
            />
          </div>
        ),
      }}
      value={innerValue}
      onChange={(item) => {
        setInnerValue(item ?? undefined);
        onChange?.(item ?? undefined);
      }}
      options={options}
      menuPortalTarget={document.querySelector("body")}
      isSearchable={false}
    />
  );
}

function InquiryListItem({
  id,
  inquiredDateTime,
  pet,
  guardian,
  products,
  reservationDate,
  time,
  popPopup,
}: InquiryListType & { popPopup: () => void }) {
  const { trackClickInquiryDetail } = useAmplitudeTrack();
  const { setCalendarType, fetchSchedules } = useSchedules();
  const { setSelectedMenuIndex } = useMainTabIndex();
  const { fetchInquiries } = useInquiries();

  const openInquiryDetailPopup = () => {
    pushPopup({
      key: `inquiryDetail_${id}`,
      kind: PopupKind.Popup,
      element: InquiryDetail,
      props: {
        inquiryId: id,
      },
    });
    trackClickInquiryDetail();
  };

  const openAddReservationPopup = () => {
    pushPopup({
      key: "inquiryReservation",
      kind: PopupKind.Popup,
      element: NewReservation,
      dimClick: "none",
      props: {
        inquiryData: {
          id,
          inquiredDateTime,
          reservationDate,
          time,
          products,
        },
        selectedCustomer: {
          id: pet.petInfo.id,
          name: pet.petInfo.name,
          species: pet.petInfo.species.name,
          attachment: pet.petInfo.attachment,
          guardianList: pet.guardians,
        },
      },
    });
  };

  const closeInquiry = () => {
    pushPopup({
      kind: PopupKind.Popup,
      key: "inquiryEnd",
      element: ConfirmPopup,
      align: PopupAlign.TopCenter,
      props: {
        title: `문의를 취소하시겠어요?\n문의 취소시, 고객에게 알림톡이 전송됩니다.`,
        confirmButtonLabel: "문의 취소하고 알림톡 보내기",
        onConfirm: () => {
          (async () => {
            await api.put(
              `/rest/reservation/${id}/cancel?accountType=PARTNER&type=INQUIRY`
            );
            await fetchInquiries({ page: 0 });
          })();
          toast.success("문의가 취소되었습니다");
        },
      },
    });
  };

  return (
    <VFlex g-24 p-24>
      <HFlex
        style={{ width: "100%" }}
        g-16
        c-c
        clickable
        onClick={openInquiryDetailPopup}
      >
        <Flex>
          <Image
            src={
              pet.petInfo.attachment
                ? pet.petInfo.attachment.path
                : `/icons/default_profile.svg`
            }
            size={48}
            bdr-16
          />
        </Flex>
        <VFlex f-1>
          <Text t-14-500-s4>{pet.petInfo.species.name}</Text>
          <Text
            t-16-500-s8
            style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
          >
            {pet.petInfo.name} ({guardian.cellnumber})
          </Text>
        </VFlex>
        <Image src="/icons/chevron_right.svg" size={24} />
      </HFlex>
      <VFlex g-8>
        <HFlex g-16>
          <Flex t-14-500-s4 width={100}>
            문의 일시
          </Flex>
          <Flex t-14-s6 f-1>
            {format(new Date(inquiredDateTime * 1000), "M월 d일 HH:mm", {
              locale: ko,
            })}
          </Flex>
        </HFlex>
        <HFlex g-16>
          <Flex t-14-500-s4 width={100}>
            희망 예약 날짜
          </Flex>
          <Flex t-14-s6 f-1>
            {format(
              new Date(reservationDate * 1000),
              "yyyy년 M월 d일 (EEEEE)",
              {
                locale: ko,
              }
            )}
          </Flex>
        </HFlex>
        <HFlex g-16>
          <Flex t-14-500-s4 width={100}>
            희망 시작 시간
          </Flex>
          <Flex
            t-14-s6
            f-1
            style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
          >
            {time.map((item) => item).join(", ")}
          </Flex>
        </HFlex>
        <HFlex g-16>
          <Flex t-14-500-s4 width={100}>
            희망 서비스
          </Flex>
          <Flex
            t-14-s6
            f-1
            style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
          >
            {products.map((item) => item.name).join(", ")}
          </Flex>
        </HFlex>
      </VFlex>

      <HFlex g-8>
        <HFlex
          f-1
          c-c
          g-8
          bc-t2
          bdr-12
          clickable
          onClick={async () => {
            setSelectedMenuIndex("reservation");
            setCalendarType(CalendarType.DAILY);
            fetchSchedules(new Date(reservationDate * 1000));
            popPopup();
          }}
        >
          <Text t-14-500-s7>캘린더 보기</Text>
        </HFlex>
        <InquiryDropdown
          value={"문의 진행중"}
          options={[
            { value: "문의 진행중", label: "문의 진행중" },
            { value: "문의 확정", label: "문의 확정" },
            { value: "문의 취소", label: "문의 취소" },
          ]}
          onChange={(value) => {
            if (value?.value === "문의 진행중") {
              openInquiryDetailPopup();
            }
            if (value?.value === "문의 확정") {
              openAddReservationPopup();
            }
            if (value?.value === "문의 취소") {
              closeInquiry();
            }
          }}
        />
      </HFlex>
    </VFlex>
  );
}

function InquiriesList({ popPopup }: { popPopup: () => void }) {
  const { trackLandInquiryList } = useAmplitudeTrack();
  useEffect(() => {
    trackLandInquiryList();
  }, []);

  const { inquiriesCount, fetchInquiries } = useInquiries();
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [isAtTop, setIsAtTop] = useState(true);

  const handleScroll = () => {
    const { scrollTop } = scrollRef.current!;
    setIsAtTop(scrollTop === 0);
  };

  useEffect(() => {
    const scrollContainer = scrollRef.current!;
    scrollContainer.addEventListener("scroll", handleScroll);
    return () => {
      scrollContainer.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [pageNum, setPageNum] = useState(0);
  const [inquiries, setInquiries] = useState<InquiryListType[]>([]);
  const [hasNext, setHasNext] = useState(true);
  const [loading, setLoading] = useState(false);
  const [lastElement, setLastElement] = useState<HTMLDivElement | null>(null);

  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        setPageNum((no) => no + 1);
      }
    })
  );

  const fetchInquiriesList = async () => {
    if (loading || !hasNext) return;

    try {
      setLoading(true);
      const list = await fetchInquiries({ page: pageNum });
      setInquiries((prev) => [...prev, ...list]);
      setHasNext(list.length !== 0);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInquiriesList();
  }, [pageNum]);

  useEffect(() => {
    setInquiries([]);
    setPageNum(0);
  }, [inquiriesCount]);

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  return (
    <VFlex
      a-st
      ovf-a
      ref={scrollRef}
      style={{ position: "relative", overflowX: "hidden" }}
    >
      <HFlex
        p-24
        j-b
        a-c
        g-16
        bc-w
        sdw-6={!isAtTop}
        width={400}
        style={{
          position: "fixed",
        }}
      >
        <Text t-20-700-bl4>문의목록 ({inquiriesCount})</Text>
        <Image
          size={24}
          src={`/icons/icon_close_s6.svg`}
          clickable
          onClick={() => popPopup()}
        />
      </HFlex>

      <VFlex f-1 style={{ paddingTop: "76px" }} ref={scrollRef}>
        {inquiries.map((i) => (
          <div key={i.id}>
            <InquiryListItem {...i} popPopup={popPopup} />
            <Divider height={1} />
          </div>
        ))}
        {!loading && hasNext && <VFlex ref={setLastElement} />}
      </VFlex>

      {inquiries.length === 0 && (
        <VFlex
          a-c
          j-c
          bd-t3
          bdr-16
          g-8
          m-32-rl
          style={{
            padding: "100px 0",
          }}
        >
          <Image size={24} src={`/icons/icon_noti.svg`} />
          <Text t-16-s2>문의 진행중인 예약이 없어요</Text>
        </VFlex>
      )}
    </VFlex>
  );
}

export default InquiriesList;
