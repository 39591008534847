import React, { useEffect, useMemo, useState } from "react";
import TabBar, { TabItemType } from "../../../components/TabBar";
import HFlex from "../../../layouts/HFlex";
import Image from "../../../layouts/Image";
import Text from "../../../layouts/Text";
import VFlex from "../../../layouts/VFlex";
import ConfirmPopup from "../../../popup/ConfirmPopup";
import { PopupAlign, PopupKind } from "../../../popup/Popup";
import { pushPopup } from "../../../providers/popups";
import OpenHours from "./OpenHours";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";

function OpenHoursSettings({
  popPopup,
  initialTabIndex,
}: {
  popPopup: () => void;
  initialTabIndex: number;
}) {
  const { trackLandBizHours } = useAmplitudeTrack();

  useEffect(() => {
    trackLandBizHours();
  }, []);

  const tabs = useMemo(
    () => [
      {
        key: "1",
        name: "영업 시간",
        component: OpenHours,
      },
    ],
    []
  );
  const [selectedTab, setSelectedTab] = useState<TabItemType>(
    tabs[initialTabIndex]
  );
  const [isChanged, setIsChanged] = useState(false);
  return (
    <VFlex f-1 a-st p-24-t>
      <HFlex m-32-rl>
        <VFlex f-1 />
        <Image
          size={24}
          src={`/icons/popup_close.png`}
          clickable
          onClick={() => {
            isChanged
              ? pushPopup({
                  kind: PopupKind.Popup,
                  align: PopupAlign.TopCenter,
                  element: ConfirmPopup,
                  props: {
                    title: "변경사항을 저장하지 않고 닫을까요?",
                    onConfirm: () => {
                      popPopup();
                    },
                  },
                })
              : popPopup();
          }}
        />
      </HFlex>
      <Text t-20-700-gr10 m-32-rl m-24-b>
        영업시간
      </Text>
      <HFlex height={42} m-32-rl>
        <TabBar
          value={selectedTab}
          items={tabs}
          onChange={(item) => setSelectedTab(item)}
        />
      </HFlex>
      <VFlex f-1 ovf-a>
        {React.createElement(selectedTab.component, { popPopup, setIsChanged })}
      </VFlex>
    </VFlex>
  );
}

export default OpenHoursSettings;
