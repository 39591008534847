import { useMemo } from "react";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import api from "../common/api";
import { usePartner } from "./partner";
import { DetailsType, Partner } from "./types";

const detailsStore = create(
  immer<{
    details: DetailsType[];
    fetchDetails: (partner: Partner) => () => void;
  }>((set) => ({
    details: [],
    fetchDetails: (partner: Partner) => async () => {
      const details = await api.get(
        `/rest/group/${partner.id}/details?type=CALENDAR_S`
      );
      set((state) => ({
        ...state,
        details: details,
      }));
    },
  }))
);

export const useDetails = () => {
  const { partner } = usePartner();
  const { fetchDetails, ...rest } = detailsStore();
  return {
    fetchDetails: useMemo(() => fetchDetails(partner), [fetchDetails, partner]),
    ...rest,
  };
};
