import { ChangeEvent, ReactElement, useCallback } from "react";

function FileUpload({
  id,
  onLoad,
}: {
  id: string;
  onLoad: (file: File) => void;
}): ReactElement {
  const photoUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const reader = new FileReader();
      const file = e.target.files?.[0];
      if (file) {
        reader.onloadend = async () => {
          onLoad?.(file);
        };
        reader.readAsDataURL(file);
      }
    },
    [onLoad]
  );
  return (
    <input
      id={id}
      type="file"
      accept="image/jpg, image/jpeg, image/png"
      onChange={photoUpload}
      onClick={(e: any) => {
        (e.target as HTMLInputElement).value = "";
      }}
    />
  );
}

export default FileUpload;
