import { useMemo } from "react";

import api from "../common/api";
import { CalendarType, Partner, Schedule } from "./types";

import { usePartner } from "./partner";
import { staffColors } from "../common/constants";
import { isValidDate } from "../common/format";

import _ from "lodash";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { format, startOfMonth, startOfWeek } from "date-fns";

const schedulesStore = create(
  immer<{
    partner?: Partner;
    schedules?: Schedule[];
    calendarType: CalendarType;
    calendarDate: Date;
    lastLoadedDate?: Date;
    fetchSchedules: (partner: Partner) => (date?: Date) => void;
    setCalendarType: (calendarType: CalendarType) => void;
    setCalendarDate: (calendarDate: Date) => void;
  }>((set, get, a) => ({
    partner: undefined,
    schedules: undefined,
    calendarType: CalendarType.DAILY,
    calendarDate: new Date(),
    lastLoadedDate: undefined,
    fetchSchedules: (partner: Partner) => async (date?: Date) => {
      set((state) => ({
        ...state,
        schedules: undefined,
      }));

      let dayToLoad = date ?? get().lastLoadedDate ?? new Date();
      set((state) => ({
        ...state,
        lastLoadedDate: dayToLoad,
      }));
      let period = 1;
      if (
        get().calendarType === CalendarType.WEEKLY ||
        get().calendarType === CalendarType.SCHEDULE
      ) {
        dayToLoad = startOfWeek(dayToLoad);
        period = 7;
      } else if (get().calendarType === CalendarType.MONTHLY) {
        dayToLoad = startOfMonth(dayToLoad);
        period = 31;
      }
      const dailySchedules = await api.get(
        `/rest/calendar/group/${partner.id}/schedule?startDate=${format(
          dayToLoad,
          "yyyy-MM-dd"
        )}&period=${period}&type=${"ALL"}`
      );
      const schedules = _.flatten(
        dailySchedules.map((schedules: any) => schedules.schedules)
      )
        .map<Schedule>((schedule: any) => ({
          reservationId: schedule.reservationId,
          status: schedule.status,
          petId: schedule.petId,
          petName: schedule.petName,
          species: schedule.species,
          productList: schedule.productList,
          chargerIdList: schedule.chargerIdList,
          staffs: schedule.staffs,
          note: schedule.note,
          startDateTime: new Date(
            schedule.reservationDate.replace(/-/g, "/") +
              " " +
              ("0" + schedule.reservationTime.split("~")[0]).slice(-5)
          ),
          endDateTime: new Date(
            schedule.reservationDate.replace(/-/g, "/") +
              " " +
              ("0" + schedule.reservationTime.split("~")[1]).slice(-5)
          ),
          backgroundColor: [6, 7].includes(schedule.status) // 예약취소, 노쇼
            ? "none"
            : schedule.staffs.length <= 1
            ? staffColors[schedule.staffs[0]?.color]?.bgColor ?? "#E4E8E6"
            : `linear-gradient(to left, ${
                staffColors[schedule.staffs[schedule.staffs.length - 1]?.color]
                  ?.bgColor
              }, ${staffColors[schedule.staffs[0]?.color]?.bgColor})`,
          requestNote: schedule.requestNote,
          nickname: schedule.nickname,
          cellNumber: schedule.cellNumber,
          title: schedule.title,
          type: schedule.type,
          memo: schedule.memo,
          mainColor:
            staffColors[schedule.staffs[0]?.color]?.mainColor ?? "#000000",
          incomingPlatform: schedule.incomingPlatform,
        }))
        .filter(
          (schedule) =>
            isValidDate(schedule.startDateTime) &&
            isValidDate(schedule.endDateTime)
        );
      set((state) => ({
        ...state,
        schedules,
        calendarDate: date || new Date(),
      }));
    },
    setCalendarType: (calendarType: CalendarType) => {
      set((state) => ({
        ...state,
        calendarType,
      }));
    },
    setCalendarDate: (calendarDate?: Date) => {
      set((state) => ({
        ...state,
        calendarDate,
      }));
    },
  }))
);

export const useSchedules = () => {
  const { partner } = usePartner();
  const { fetchSchedules, setCalendarType, ...rest } = schedulesStore();
  return {
    fetchSchedules: useMemo(
      () => fetchSchedules(partner),
      [fetchSchedules, partner]
    ),
    setCalendarType: useMemo(
      () => (calendarType: CalendarType) => {
        setCalendarType(calendarType);
      },
      [fetchSchedules, setCalendarType, partner]
    ),
    ...rest,
  };
};
