import _ from "lodash";
import VFlex from "../layouts/VFlex";

function TabIndicator({ index, widths }: { index: number; widths: number[] }) {
  return (
    <VFlex
      height={2}
      abs
      anim-1
      bc-s8
      style={{
        bottom: 0,
        left: _.sum(widths.slice(0, index)) + index * 24,
        width: widths[index],
      }}
    />
  );
}

export default TabIndicator;
