import { ReactElement, useEffect, useState } from "react";
import HFlex from "../layouts/HFlex";
import Text from "../layouts/Text";
import VFlex from "../layouts/VFlex";

function Toggle({
  value,
  label,
  subTitle,
  onChange,
}: {
  value: boolean;
  label?: string;
  subTitle?: string;
  onChange?: (value: boolean) => void;
}): ReactElement {
  const [innerValue, setInnerValue] = useState<boolean>(value);
  useEffect(() => {
    setInnerValue(value);
  }, [value]);
  return (
    <HFlex
      f-1
      a-c
      p-24-rl
      clickable
      onClick={() => {
        onChange?.(!value);
      }}
    >
      <VFlex f-1 a-s j-c g-4>
        <Text t-14-500-s10>{label}</Text>
        {subTitle && <Text t-12-400-s4>{subTitle}</Text>}
      </VFlex>
      <HFlex a-c p-4 rel bc-t3 bdr-10 height={32} width={80}>
        <VFlex
          height={24}
          bc-w
          bdr-8
          abs
          anim-1
          style={{
            position: "absolute",
            left: innerValue ? "41px" : "4px",
            width: innerValue ? "34px" : "36px",
            zIndex: 1,
          }}
        />
        <VFlex c-c width={35}>
          <Text
            t-12-800-err2={!innerValue}
            t-12-800-gr12={innerValue}
            style={{
              zIndex: 2,
            }}
          >
            OFF
          </Text>
        </VFlex>
        <VFlex f-1 />
        <VFlex c-c width={33}>
          <Text
            t-12-800-mt1={innerValue}
            t-12-800-gr12={!innerValue}
            style={{
              zIndex: 2,
            }}
          >
            ON
          </Text>
        </VFlex>
      </HFlex>
    </HFlex>
  );
}

export default Toggle;
