import { useState } from "react";
import { isNil } from "lodash";

import HFlex from "../../layouts/HFlex";
import VFlex from "../../layouts/VFlex";
import Text from "../../layouts/Text";
import Image from "../../layouts/Image";
import Flex from "../../layouts/Flex";
import Button from "../../layouts/Button";
import RadioGroup from "../../layouts/RadioGroup";
import FilterChip from "../../layouts/FilterChip";

import { NEUTRALIZED, NEUTRALIZED_VALUES } from "../../common/constants";

const EditPetNeutralized = ({
  popPopup,
  value,
  fetch,
}: {
  popPopup: () => void;
  value: string | undefined;
  fetch: (v: string | null) => void;
}) => {
  const [neutralized, setNeutralized] = useState(() => {
    const idx = NEUTRALIZED_VALUES.findIndex((v) => v === value);

    return idx < 0 ? undefined : idx;
  });

  return (
    <VFlex p-24>
      <HFlex j-b a-c m-24-b>
        <Text t-24-600-s8>고객 정보 수정</Text>
        <Image
          src="/icons/icon_close_s6.svg"
          size={24}
          clickable
          onClick={popPopup}
        />
      </HFlex>
      <VFlex g-8>
        <RadioGroup
          caption="중성화"
          value={
            !isNil(neutralized)
              ? {
                  label: NEUTRALIZED[neutralized],
                  value: neutralized,
                }
              : undefined
          }
          onChange={(nt) => {
            setNeutralized(nt?.value);
          }}
          options={NEUTRALIZED.map((n, i) => ({ label: n, value: i }))}
          renderOption={(t, handleChange) => (
            <Flex f-1>
              <FilterChip
                type={neutralized === t.value ? "selected" : undefined}
                onClick={() => handleChange(t)}
                size="L"
              >
                {t.label}
              </FilterChip>
            </Flex>
          )}
        />
      </VFlex>
      <Button
        m-24-t
        bdr-12
        height={56}
        enabled={
          !!(!isNil(neutralized) && value !== NEUTRALIZED_VALUES[neutralized])
        }
        onClick={() => {
          fetch(isNil(neutralized) ? null : NEUTRALIZED_VALUES[neutralized]);
          popPopup();
        }}
      >
        저장
      </Button>
    </VFlex>
  );
};

export default EditPetNeutralized;
