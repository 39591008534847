import Bluebird from "bluebird";
import _ from "lodash";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import api from "../common/api";
import { Species } from "./types";

const speciesListStore = create(
  immer<{
    speciesList: Species[];
    fetchSpeciesList: () => void;
  }>((set) => ({
    speciesList: [],
    fetchSpeciesList: async () => {
      const speciesIds = await api.get(`/rest/species`);
      const speciesList = await Bluebird.map(
        speciesIds,
        async (speciesId: any) => {
          const speciesList = await api.get(
            `/rest/species/${speciesId.id}/species-list`
          );
          return speciesList;
        }
      );
      set((state) => ({
        ...state,
        speciesList: _.flatten(speciesList),
      }));
    },
  }))
);

export const useSpeciesList = () => {
  return speciesListStore();
};
