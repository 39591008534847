import React, { ReactElement, useEffect, useMemo, useState } from "react";
import HFlex from "../layouts/HFlex";
import TabIndicator from "./TabIndicator";
import TabItem from "./TabItem";

export type TabItemType = {
  key: string;
  name: string;
  component: (...args: any[]) => ReactElement;
};

type TabViewContextInterface = {
  selectedItem: TabItemType;
  setSelectedItem: React.Dispatch<React.SetStateAction<TabItemType>>;
  onChange: ((item: TabItemType) => void) | undefined;
};

export const TabViewContext = React.createContext<
  TabViewContextInterface | undefined
>(undefined);

function getTextWidth(text: string, font: string = "14px Font-600") {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  if (!context) {
    return;
  }

  context.font = font;

  return context.measureText(text).width;
}

function TabBar({
  value,
  items,
  onChange,
}: {
  value: TabItemType;
  items: TabItemType[];
  onChange?: (item: TabItemType) => void;
}) {
  const [selectedItem, setSelectedItem] = useState<TabItemType>(value);
  useEffect(() => {
    setSelectedItem(value);
  }, [value]);
  const context = useMemo(
    () => ({ selectedItem, setSelectedItem, onChange }),
    [selectedItem, setSelectedItem, onChange]
  );
  const index = useMemo(
    () => items.findIndex((i) => i.key === selectedItem?.key),
    [items, selectedItem]
  );
  const widths = useMemo(
    () => items.map((item) => getTextWidth(item.name)!),
    [items]
  );
  return (
    <TabViewContext.Provider value={context}>
      <HFlex a-st rel g-24 height={"100%"}>
        {items.map((item, i) => (
          <TabItem key={i} item={item} width={widths[i]} />
        ))}
        <TabIndicator index={index} widths={widths} />
      </HFlex>
    </TabViewContext.Provider>
  );
}

export default TabBar;
