import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { Preference } from "./types";

export const preferenceStore = create(
  persist<Preference>(
    (set, get) => ({
      sidebarCollapsed: false,
      setSidebarCollapsed: () =>
        set({ sidebarCollapsed: !get().sidebarCollapsed }),
    }),
    {
      name: "preference",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export const usePreference = () => {
  const { ...rest } = preferenceStore();
  return {
    ...rest,
  };
};
