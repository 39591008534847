import { useEffect, useMemo, useRef } from "react";

import { shortOrdinals, staffColors } from "../../../common/constants";
import Spinner from "../../../common/Spinner/Spinner";

import AddSchedule from "./AddSchedule";
import AddPersonalSchedule from "./AddPersonalSchedule";
import {
  checkIsBusinessHoliday,
  checkIsTemporaryHoliday,
  getNthWeeks,
} from "./DailyCalendar";
import { PopupKind } from "../../../popup/Popup";

import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Text from "../../../layouts/Text";
import Image from "../../../layouts/Image";

import { ReservationStatus, Schedule, Staff } from "../../../providers/types";
import { pushPopup } from "../../../providers/popups";
import { useSchedules } from "../../../providers/schedules";
import { useBusinessHours } from "../../../providers/businessHours";

import _ from "lodash";
import { addDays, format, startOfWeek } from "date-fns";
import { ko } from "date-fns/locale";
import hangul from "hangul-js";
import ReservationDetail from "./ReservationDetail";

export const HolidayBlock = ({ text }: { text?: string }) => {
  return (
    <VFlex
      a-c
      j-c
      bdr-16
      p-10-rl
      height={22}
      style={{
        background: "#CAD1CE",
      }}
    >
      <HFlex g-4 a-c>
        <Image size={14} src={`/icons/icon_holiday.png`} />
        <Text t-12-600-w>{text}</Text>
      </HFlex>
    </VFlex>
  );
};

export const getDateRangeString = (startDate: Date, currentMonth: number) => {
  const endDate = new Date(startDate.getTime());
  endDate.setDate(endDate.getDate() + 6);
  if (startDate.getMonth() !== currentMonth) {
    startDate = new Date(startDate);
    startDate.setDate(1);
    startDate.setMonth(currentMonth);
  }
  const [month, nth] = getNthWeeks(startDate);
  return `${month + 1}월 ${shortOrdinals[nth - 1]}주 (${
    startDate.getMonth() + 1
  }월 ${startDate.getDate()}일 ~ ${
    endDate.getMonth() === startDate.getMonth()
      ? endDate.getDate()
      : `${endDate.getMonth() + 1}월 ${endDate.getDate()}`
  }일)`;
};

export const ListCalnderRow = ({
  schedule,
  personalList,
  statusList,
}: {
  schedule: Schedule;
  personalList?: ReservationStatus[];
  statusList?: ReservationStatus[];
}) => {
  const isStatusShow = statusList
    ?.filter((i: ReservationStatus) => i.showed)
    ?.map((status) => status.value)
    .map(Number);

  const isStatusActive = statusList
    ?.filter((i: ReservationStatus) => i.actived)
    ?.map((status) => status.value)
    .map(Number);

  const isPersonalActive = personalList && personalList[0]?.actived;

  const isPersonalSchedule = useMemo(() => {
    return schedule.type === "PARTNER_SCHEDULE";
  }, [schedule]);

  const isInquiriesSchedule = useMemo(() => {
    return (
      schedule.incomingPlatform === "PUA" || schedule.incomingPlatform === "PUW"
    );
  }, [schedule]);

  return (
    <HFlex
      bc-w
      height={56}
      a-c
      bdr-8
      g-16
      clickable
      hover
      style={{
        display:
          (isPersonalSchedule && isPersonalActive) ||
          (isStatusShow?.includes(schedule.status) &&
            isStatusActive?.includes(schedule.status))
            ? "inline-flex"
            : "none",
        background: isPersonalSchedule
          ? schedule.staffs.length <= 1
            ? `repeating-linear-gradient(-45deg, ${schedule.backgroundColor}, ${schedule.backgroundColor} 2px, transparent 0, transparent 5px)`
            : `repeating-linear-gradient(-45deg, #fff, #fff 2px, transparent 0, transparent 5px), linear-gradient(to left, ${
                staffColors[schedule.staffs[schedule.staffs.length - 1]?.color]
                  ?.bgColor
              }, ${staffColors[schedule.staffs[0]?.color]?.bgColor})`
          : schedule.backgroundColor,
        textDecoration: [5, 6, 7].includes(schedule.status)
          ? "line-through"
          : "none",
        opacity: [4, 6, 7].includes(schedule.status) ? 0.5 : 1,
        border: [6, 7].includes(schedule.status)
          ? schedule.staffs.length === 0
            ? `2px solid #979E9B`
            : schedule.staffs.length === 1
            ? `2px solid ${staffColors[schedule.staffs[0]?.color]?.bgColor}`
            : `2px solid transparent`
          : "none",
        borderRadius: "8px",
        backgroundImage:
          [6, 7].includes(schedule.status) && schedule.staffs.length > 1
            ? `linear-gradient(#fff, #fff), linear-gradient(to left, ${
                staffColors[schedule.staffs[schedule.staffs.length - 1]?.color]
                  ?.bgColor
              }, ${staffColors[schedule.staffs[0]?.color]?.bgColor})`
            : "#fff",
        backgroundOrigin: "border-box",
        backgroundClip: "content-box, border-box",
      }}
      onClick={() => {
        isPersonalSchedule
          ? pushPopup({
              key: "addPersonalSchedule",
              kind: PopupKind.Drawer,
              element: AddPersonalSchedule,
              props: {
                personalSchedule: schedule,
              },
            })
          : // pushPopup({
            //     key: "addSchedule",
            //     kind: PopupKind.Popup,
            //     element: AddSchedule,
            //     props: {
            //       reservationId: schedule.reservationId,
            //       startTime: schedule.startDateTime,
            //     },
            //   });
            pushPopup({
              key: "addSchedule",
              kind: PopupKind.Popup,
              element: ReservationDetail,
              props: {
                reservationId: schedule.reservationId,
                petId: schedule.petId,
                schedule,
              },
              dimClick: "none",
            });
      }}
    >
      {isPersonalSchedule ? (
        <HFlex g-4 a-c>
          <VFlex
            style={{
              marginLeft: 16,
              width: 10,
              height: 10,
              background: schedule.mainColor,
              borderRadius: "100%",
            }}
          ></VFlex>
          <Text t-14-700-s8 l-1 m-12-r>
            {schedule.title}
          </Text>
          <Text t-14-s8 l-1>
            {`${format(schedule.startDateTime, "a h:mm", {
              locale: ko,
            })} ~ ${format(schedule.endDateTime, "a h:mm", {
              locale: ko,
            })}`}
          </Text>
        </HFlex>
      ) : (
        <>
          <Text t-14-700-s8 l-1 p-16-l style={{ flex: 3 }}>
            {`${schedule.petName} (${schedule.species})`}
          </Text>
          <Text t-14-600-s8 l-1 style={{ flex: 3 }}>
            {schedule.productList?.join(",")}
          </Text>
          <Text t-14-s8 l-1 style={{ flex: 4 }}>
            {`${format(schedule.startDateTime, "a h:mm", {
              locale: ko,
            })} ~ ${format(schedule.endDateTime, "a h:mm", {
              locale: ko,
            })}`}
          </Text>
          <Text t-14-s8 l-1 style={{ flex: 8 }}>
            {schedule.requestNote}
          </Text>
          {/* TODO : 결제 추가 후 작업 */}
          {/* <Image style={{ marginRight: '-8px' }} size={22} src={`/icons/icon_money.png`} /> */}
          {isInquiriesSchedule && (
            <Image p-16-r size={22} src={`/icons/icon_send.png`} />
          )}
        </>
      )}
    </HFlex>
  );
};

const ListCalendar = ({
  selectedStaffs,
  searchText,
  statusList,
  personalList,
}: {
  selectedStaffs: Staff[] | undefined;
  searchText?: string;
  personalList?: ReservationStatus[];
  statusList?: ReservationStatus[];
}) => {
  const { schedules, calendarDate } = useSchedules();
  const { businessHours, businessHolidays, temporaryHolidays } =
    useBusinessHours();
  const divRef = useRef<HTMLDivElement>(null);
  const openHour = useMemo(
    () =>
      Math.max(
        ..._.compact(
          businessHours.map((businessHour) => {
            try {
              return parseInt(businessHour.start?.split(":")[0]);
            } catch {
              return undefined;
            }
          })
        ),
        0
      ),
    [businessHours]
  );

  useEffect(() => {
    divRef.current?.scrollTo({ top: 38 * openHour * 2, behavior: "smooth" });
  }, [divRef, openHour]);

  const filteredSchedules = useMemo(() => {
    return schedules?.filter((schedule) => {
      return (
        startOfWeek(schedule.startDateTime).getTime() ===
          startOfWeek(calendarDate).getTime() &&
        _.some(
          [
            schedule.petName,
            schedule.nickname ?? "",
            schedule.cellNumber ?? "",
          ],
          (text) => hangul.search(text, searchText ?? "") >= 0
        ) &&
        (schedule.staffs.length === 0 ||
          _.intersection(
            selectedStaffs?.map((staff) => staff.id),
            schedule.staffs.map((staff) => staff.id)
          ).length > 0)
      );
    });
  }, [schedules, searchText, calendarDate, selectedStaffs]);

  const monday = useMemo(() => startOfWeek(calendarDate), [calendarDate]);
  const schedulesByDay = useMemo(
    () =>
      filteredSchedules &&
      _(filteredSchedules)
        .groupBy((schedule: Schedule) => schedule.startDateTime.getDay())
        .mapValues((schedules) =>
          _.orderBy(
            schedules,
            (schedule) => schedule.startDateTime.getTime(),
            "desc"
          )
        )
        .value(),
    [filteredSchedules]
  );

  const startDate = useMemo(() => {
    const startDate = new Date(calendarDate);
    startDate.setDate(startDate.getDate() - startDate.getDay());
    return startDate;
  }, [calendarDate]);

  const isToday = (i: number) => {
    const date = new Date(startDate);
    date.setDate(date.getDate() + i);
    return (
      Math.floor(date.getTime() / (24 * 60 * 60 * 1000)) ===
      Math.floor(Date.now() / (24 * 60 * 60 * 1000))
    );
  };

  return (
    <VFlex f-1>
      <VFlex f-1 ovf-a rel ref={divRef}>
        {schedulesByDay ? (
          _.isEmpty(schedulesByDay) ? (
            <VFlex
              a-c
              j-c
              bd-t3
              bdr-16
              g-8
              style={{
                marginTop: 16,
                padding: "100px 0",
                borderStyle: "dashed",
              }}
            >
              <Image size={24} src={`/icons/icon_noti.svg`} />
              <Text t-16-s2>예약이 없습니다</Text>
            </VFlex>
          ) : (
            [...new Array(7).keys()].map(
              (i) =>
                schedulesByDay[i] && (
                  <VFlex key={i} g-16 m-16-tb sized>
                    <HFlex a-c g-4 height={24} sized>
                      <Text t-16-600-s8 m-8-r>
                        {format(addDays(monday, i), "d일 EEEE", {
                          locale: ko,
                        })}
                      </Text>
                      {isToday(i) && (
                        <VFlex height={22} j-c p-10-rl bc-gr5 bdr-16 t-12-600-w>
                          오늘
                        </VFlex>
                      )}
                      {businessHolidays.map(
                        (holiday, j) =>
                          checkIsBusinessHoliday(
                            holiday,
                            addDays(monday, i)
                          ) && <HolidayBlock key={j} text={"정기 휴무"} />
                      )}
                      {_.some(temporaryHolidays, (holiday) =>
                        checkIsTemporaryHoliday(holiday, addDays(monday, i))
                      ) && <HolidayBlock text={"임시 휴무"} />}
                    </HFlex>
                    <VFlex g-8>
                      {schedulesByDay[i].map((schedule, j) => (
                        <ListCalnderRow
                          schedule={schedule}
                          key={j}
                          statusList={statusList}
                          personalList={personalList}
                        />
                      ))}
                    </VFlex>
                  </VFlex>
                )
            )
          )
        ) : (
          <VFlex abs c-c style={{ inset: 0 }}>
            <Spinner />
          </VFlex>
        )}
      </VFlex>
    </VFlex>
  );
};

export default ListCalendar;
