import { isNil } from "lodash";
import { sex } from "../constants";

export const getSex = (s: number | undefined) => {
  return s === 0 || s === 1 ? sex[s] : undefined;
};

export const getNeutralized = (n: boolean | undefined | null) => {
  return isNil(n) ? "모르겠어요" : n ? "예" : "아니오";
};

export const validatePhoneNumber = (phoneNumber: string) => {
  return /^[0-9]{3}[-]+[0-9]{4}[-]+[0-9]{4}$/.test(phoneNumber);
};

export const validatePetName = (petName: string) => {
  return /^[ㄱ-ㅎ가-힣a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]{0,50}$/.test(
    petName
  );
};

export const validateWeight = (weight: string) => {
  return /^\d{1,3}(\.\d{0,1})?$/.test(weight);
};

export function estimateBirthYearMonth(
  ageYears: number,
  ageMonths: number
): string {
  const today = new Date();
  let estimatedYear = today.getFullYear() - ageYears;
  let estimatedMonth = today.getMonth() + 1 - ageMonths;
  if (estimatedMonth < 1) {
    estimatedYear--;
    estimatedMonth += 12;
  }
  return `${estimatedYear}.${String(estimatedMonth).padStart(2, "0")}`;
}

export function parseDateString(dateString: string): Date {
  const year = parseInt(dateString.substring(0, 4));
  const month = parseInt(dateString.substring(4, 6)) - 1;
  const day = parseInt(dateString.substring(6, 8));
  return new Date(year, month, day);
}

export function formatAgeAndMonths(
  age: number | undefined,
  months: number | undefined
) {
  if (isNil(age) && isNil(months)) {
    return "";
  }

  if ((isNil(age) && !isNil(months)) || (age === 0 && !isNil(months))) {
    return `${months}개월`;
  }

  if ((isNil(months) && !isNil(age)) || (!isNil(age) && age >= 2)) {
    return `${age}살`;
  }

  return `${age}살 ${months}개월`;
}

export function formatPetBirthDay(birthday: string) {
  if (!birthday || birthday.length !== 8) {
    return "";
  }

  const [year, month, day] = [
    birthday.substring(0, 4),
    birthday.substring(4, 6),
    birthday.substring(6, 8),
  ];

  if (month === "00" && day === "00") {
    return year;
  }

  return [year, month !== "00" ? month : "", day !== "00" ? day : ""]
    .filter(Boolean)
    .join(".");
}

export const calculateBirthday = (dateString: string): string => {
  const today = new Date();
  const year = parseInt(dateString.slice(0, 4));
  const month = parseInt(dateString.slice(4, 6));
  const day = parseInt(dateString.slice(6, 8));

  if (isNaN(year)) {
    throw new Error("Invalid year in date string");
  }

  const monthIsValid = !isNaN(month) && month >= 1 && month <= 12;
  const dayIsValid = !isNaN(day) && day >= 1 && day <= 31;

  if (!dayIsValid && !monthIsValid) {
    return `생후 ${today.getFullYear() - year}년`;
  }

  const birthDate = new Date(year, month - 1, day || 1);

  let years = today.getFullYear() - birthDate.getFullYear();
  let months = today.getMonth() - birthDate.getMonth();
  let days = today.getDate() - birthDate.getDate();

  if (months < 0 || (months === 0 && days < 0)) {
    years--;
    months += 12;
  }

  if (days < 0) {
    const previousMonth = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      0
    );
    days += previousMonth.getDate();
    months--;
  }

  const diffTime = today.getTime() - birthDate.getTime();
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays < 30) {
    if (!isNaN(day)) {
      return `생후 ${diffDays}일`;
    } else {
      return "생후 1개월 미만";
    }
  }

  const remainingMonths = months < 0 ? 12 + months : months;
  return `생후 ${years}년${remainingMonths ? ` ${remainingMonths}개월` : ""}`;
};
