import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  CalendarType,
  DetailsType,
  Guardian,
  MEMO_STATUS_VALUE,
  MEMO_STATUS_VALUES,
  Memo,
  MemoType,
  Pet,
  PetInfo,
  ReservationStatus,
  Schedule,
  Service,
  Staff,
} from "../../../../providers/types";
import {
  addDays,
  addMinutes,
  differenceInMinutes,
  format,
  isSameDay,
  setDate,
  setMonth,
  setYear,
  startOfDay,
  startOfWeek,
} from "date-fns";
import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import HFlex from "../../../../layouts/HFlex";
import VFlex from "../../../../layouts/VFlex";
import Text from "../../../../layouts/Text";
import Image from "../../../../layouts/Image";
import { useSchedules } from "../../../../providers/schedules";
import Absolute from "../../../../layouts/Absolute";
import Flex from "../../../../layouts/Flex";
import ButtonV2 from "../../../../layouts/ButtonV2";
import _, { isNil } from "lodash";
import Spinner from "../../../../common/Spinner/Spinner";
import { dayOfWeek, days, staffColors } from "../../../../common/constants";
import {
  checkIsBusinessHolidays,
  HolidayBlock,
  checkIsTemporaryHolidays,
  ScheduleBlock,
  checkIsBusinessHour,
} from "../DailyCalendar";
import { useLongPress, LongPressEvent } from "use-long-press";
import Divider from "../../../../layouts/Divider";
import Dropdown from "../../../../components/Dropdown";
import { PopupKind } from "../../../../popup/Popup";
import { pushPopup } from "../../../../providers/popups";
import { ko } from "date-fns/locale";
import DateTimePickerContainer from "../../../../components/DateTimePicker";
import StaffDropdown from "../../../../components/StaffDropdown";
import { useStaffs } from "../../../../providers/staffs";
import { CreatingScheduleBlock } from "../../../../components/Calendar/CreatingScheduleBlock";
import { getDateRangeString } from "../ListCalendar";
import { useBusinessHours } from "../../../../providers/businessHours";
import api from "../../../../common/api";
import { usePartner } from "../../../../providers/partner";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import PhotosSwiperPopup from "../../../../components/PhotosSwiperPopup";
import TextArea from "../../../../layouts/TextareaV2";
import FilterChip from "../../../../layouts/FilterChip";
import GroupRadioButton from "../../../../components/GroupRadioButton";
import FilesUpload from "../../../../components/FilesUpload";
import TextInput from "../../../../layouts/TextInput";
import { useCount } from "../../../../providers/count";

interface LocationState {
  newReservation: {
    startDateTime: Date;
    endDateTime: Date;
    selectedCustomer?: Pet;
    selectedProducts?: Service[];
    selectedStaffs: Staff[];
    selectedGuardian?: Guardian;
    memos: Memo<keyof typeof MemoType>[];
    files: File[];
  };
}

const MEMO_OPTIONS = ["스타일 메모", "컨디션 메모", "예약 메모"];
const CALENDAR_TYPE = [CalendarType.DAILY, CalendarType.WEEKLY];

const NewReservationCalendarView = () => {
  const location = useLocation();
  const { newReservation } = location.state as LocationState;

  const { staffs } = useStaffs();
  const navigate = useNavigate();
  const {
    fetchSchedules,
    calendarDate,
    setCalendarDate,
    calendarType,
    setCalendarType,
  } = useSchedules();

  const [selectedStaffs, setSelectedStaffs] = useState<Staff[]>(
    newReservation.selectedStaffs
  );

  const [startDateTime, setStartDateTime] = useState(
    newReservation.startDateTime
  );
  const [endDateTime, setEndDateTime] = useState(newReservation.endDateTime);

  const [selectedProducts, setSelectedProducts] = useState<Service[]>(
    newReservation.selectedProducts ?? []
  );
  const [selectedCustomer, setSelectedCustomer] = useState<Pet | undefined>(
    newReservation.selectedCustomer
  );
  const [petInfo, setPetInfo] = useState<PetInfo | undefined>();
  const [selectedGuardian, setSelectedGuardian] = useState<
    Guardian | undefined
  >(newReservation.selectedGuardian);
  const { partner } = usePartner();

  const fetchMeta = async () => {
    const res = await api.get(
      `/rest/user/meta?groupId=${partner.id}&platform=PARTNER_WEB&type=ALL`
    );
    if (res) {
      setStatusList(res.reservationStatus);
      setPersonalList(res.staffSchedule);
    }
  };

  const handleChangeStartDate = (sdt: Date) => {
    if (
      (!endDateTimeChanged && !startDateTimeChanged) ||
      (!endDateTimeChanged && startDateTimeChanged)
    ) {
      setEndDateTime((end) => {
        end = setYear(end, sdt.getFullYear());
        end = setMonth(end, sdt.getMonth());
        end = setDate(end, sdt.getDate());

        return end;
      });
    } else {
      // 에러 케이스추가
      const timeGap = endDateTime.getTime() - startDateTime.getTime();

      setEndDateTime(new Date(sdt.getTime() + timeGap));
    }

    setStartDateTime(sdt);
  };

  const [startDateTimeChanged, setStartDateTimeChanged] = useState(false);
  const [endDateTimeChanged, setEndDateTimeChanged] = useState(false);
  const [dateTimeError, setDateTimeError] = useState(false);

  useEffect(() => {
    if (startDateTime.getTime() > endDateTime.getTime()) {
      setDateTimeError(true);
    } else {
      setDateTimeError(false);
    }
  }, [startDateTime, endDateTime]);

  useEffect(() => {
    fetchMeta();
    setCalendarDate(newReservation.startDateTime);
  }, []);

  const [statusList, setStatusList] = useState<ReservationStatus[]>([]);
  const [personalList, setPersonalList] = useState<ReservationStatus[]>([]);
  const [staffsFilter, setStaffsFilter] = useState<Staff[] | undefined>(staffs);

  const currentMonth = useMemo(() => calendarDate.getMonth(), [calendarDate]);
  const startDate = useMemo(() => {
    const startDate = new Date(calendarDate);
    startDate.setDate(startDate.getDate() - startDate.getDay());
    return startDate;
  }, [calendarDate]);
  const [loading, setLoading] = useState<boolean>(false);

  const [selectedMemoTab, setSelectedMemoTab] = useState<string>(
    MEMO_OPTIONS[0]
  );
  const [memos, setMemos] = useState<Memo<keyof typeof MemoType>[]>(
    newReservation.memos
  );

  useEffect(() => {
    fetchDetils();
    setCalendarType(CalendarType.DAILY);
  }, []);

  const [isStaff, setIsStaff] = useState<DetailsType>();
  const fetchDetils = async () => {
    const res = await api.get(
      `/rest/group/${partner.id}/details?type=CALENDAR_S`
    );
    setIsStaff(res[0]);
  };

  const { fetchCount } = useCount();

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isSameDay(startDateTime, calendarDate))
      divRef.current?.scrollTo({
        top: 38 * startDateTime.getHours() * 2,
      });
  }, [divRef, calendarType, calendarDate]);

  const [files, setFiles] = useState<File[]>(newReservation.files || []);
  const removeFile = (idx: number) => {
    const newFiles =
      files &&
      files.filter((file, i) => {
        return idx !== i;
      });
    setFiles(newFiles);
  };

  const isCompleted = useCallback(() => {
    return (
      !dateTimeError &&
      !!selectedCustomer &&
      selectedProducts.length > 0 &&
      (isStaff?.value === "FALSE" &&
      !!(selectedStaffs?.length === 0 || selectedStaffs === undefined)
        ? false
        : true)
    );
  }, [dateTimeError, selectedCustomer, selectedProducts, selectedStaffs]);

  const durationTime = useMemo(
    () =>
      selectedProducts
        ? selectedProducts
            .map((product) => product.product.duration)
            .reduce((a, c) => {
              return a + c;
            }, 0)
        : 0,
    [JSON.stringify(selectedProducts)]
  );

  return (
    <HFlex f-1 style={{ maxHeight: "100vh" }}>
      <VFlex p-16>
        <VFlex
          width={498}
          f-1
          bdr-16
          style={{
            boxShadow:
              "0px 3px 8px 0px rgba(0, 0, 0, 0.15), 0px 0px 24px 4px rgba(0, 0, 0, 0.25)",
          }}
        >
          <HFlex p-24 a-c j-b bd-b-t2>
            <Text t-24-600-s8>새로운 예약</Text>
            <HFlex g-24 a-c>
              <Link
                style={{ display: "flex", alignItems: "center" }}
                to={"/main/reservation"}
                state={{
                  newReservation: {
                    startDateTime,
                    endDateTime,
                    selectedCustomer,
                    selectedProducts,
                    selectedStaffs,
                    selectedGuardian,
                    memos,
                    files,
                  },
                }}
              >
                <Image
                  src={"/icons/icon_move_to_middle_s6.svg"}
                  size={24}
                  clickable
                />
              </Link>
              <Image
                src={"/icons/icon_close_s6.svg"}
                size={24}
                clickable
                onClick={() => {
                  // isChanged()
                  //   ? pushPopup({
                  //       kind: PopupKind.Popup,
                  //       align: PopupAlign.TopCenter,
                  //       element: ConfirmPopup,
                  //       props: {
                  //         title: "변경사항을 저장하지 않고 닫을까요?",
                  //         onConfirm: () => {
                  //           popPopup();
                  //         },
                  //       },
                  //     })
                  //   : popPopup();
                }}
              />
            </HFlex>
          </HFlex>
          <HFlex p-24>
            <Image
              src={
                selectedCustomer?.attachment?.path ??
                "/icons/default_profile.svg"
              }
              size={56}
              m-16-r
              bdr-16
            />
            <VFlex f-1 g-4>
              <Text
                t-16-600-s8
              >{`${selectedCustomer?.name} (${selectedCustomer?.species})`}</Text>
              <Text
                t-14-500-s4
              >{`${selectedGuardian?.name} (${selectedGuardian?.cellnumber})`}</Text>
            </VFlex>
            <VFlex>
              <Image
                src="/icons/icons_cancel_t8.svg"
                size={24}
                clickable
                onClick={() => {
                  setSelectedCustomer(undefined);
                  setSelectedGuardian(undefined);
                }}
              />
            </VFlex>
          </HFlex>

          <VFlex f-1 rel ovf-h>
            <VFlex ovf-a>
              <Divider height={12} bc-t2 />
              <VFlex p-24 g-16>
                <VFlex g-8>
                  <Dropdown
                    placeholder="서비스를 선택하세요"
                    isDisabled={true}
                    value={selectedProducts.length > 0 ? 0 : undefined}
                    options={[
                      {
                        value: 0,
                        label: selectedProducts
                          .map((product) => product.product.name)
                          .join(", "),
                      },
                    ]}
                    caption={"서비스명"}
                    onClick={() => {
                      // pushPopup({
                      //   kind: PopupKind.Popup,
                      //   element: MenuSelect,
                      //   props: {
                      //     selectedProducts: selectedProducts,
                      //     onSubmit: (selectedProducts: any) => {
                      //       setSelectedProducts(selectedProducts);
                      //     },
                      //   },
                      //   width: 950,
                      // });
                    }}
                    required={true}
                    controlBgColor="#fff"
                  />

                  {selectedProducts && durationTime > 0 && (
                    <HFlex>
                      <VFlex bc-t2 bdr-8 p-2-tb p-8-rl t-14-s6 j-c>
                        예상 소요 시간 :
                        {durationTime >= 60
                          ? ` ${Math.floor(durationTime / 60)}시간 ${
                              durationTime % 60 === 0
                                ? ""
                                : (durationTime % 60) + "분"
                            }`
                          : ` ${durationTime}분`}
                      </VFlex>
                    </HFlex>
                  )}
                </VFlex>

                <VFlex f-1 g-4>
                  <HFlex>
                    <Text t-14-500-s6 m-4-r>
                      예약 일시
                    </Text>
                    <Text t-14-500-r1>*</Text>
                  </HFlex>
                  <DateTimePickerContainer
                    isRange
                    showReservationCount
                    startDateTime={startDateTime}
                    setStartDateTime={(dt) => {
                      handleChangeStartDate(dt);
                      setStartDateTimeChanged(true);
                      setCalendarDate(dt);
                    }}
                    endDateTime={endDateTime}
                    setEndDateTime={(dt) => {
                      setEndDateTime(dt);
                      setEndDateTimeChanged(true);
                    }}
                    renderTrigger={() => (
                      <HFlex
                        p-12-tb
                        p-16-rl
                        bd-t5
                        bd-r1={dateTimeError}
                        bdr-16
                        j-b
                      >
                        <Text t-16-500-s8 f-1>{`${format(
                          startDateTime,
                          "yyyy년 M월 d일 (E) a hh:mm",
                          { locale: ko }
                        )} ~ ${format(
                          endDateTime,
                          isSameDay(startDateTime, endDateTime)
                            ? "a hh:mm"
                            : "yyyy년 M월 d일 (E) a hh:mm",
                          {
                            locale: ko,
                          }
                        )}`}</Text>
                        <Image
                          src="/icons/navigation/arrow_drop_down_s3.svg"
                          size={22}
                          clickable
                        />
                      </HFlex>
                    )}
                  />
                  {dateTimeError && (
                    <Text t-14-500-r1>
                      일정 종료는 시작 시간 이후여야 합니다.
                    </Text>
                  )}
                  {!dateTimeError &&
                    differenceInMinutes(endDateTime, startDateTime) <
                      durationTime && (
                      <HFlex
                        a-c
                        p-16
                        g-12
                        bdr-12
                        m-4-t
                        style={{
                          border: `1px solid rgba(255, 195, 94, 0.60)`,
                          background: `rgba(255, 195, 94, 0.10)`,
                        }}
                      >
                        <Image size={22} src={`/icons/action/info_s6.svg`} />
                        <Text t-14-s7>
                          예상 소요 시간보다 종료 시간이 빠릅니다.
                        </Text>
                      </HFlex>
                    )}
                </VFlex>
                <StaffDropdown
                  placeholder={
                    // isStaff?.value === "FALSE"
                    //   ? "담당자를 선택하세요"
                    //   : "미지정"
                    "담당자를 선택하세요"
                  }
                  caption={"담당자"}
                  value={selectedStaffs?.map((s) => s.id).join(", ")}
                  options={staffs?.map((staff) => ({
                    value: staff.id.toString(),
                    obj: staff,
                    label: `${staff.name} ${staff.jobTitle}`,
                  }))}
                  onChange={async (values) => {
                    setSelectedStaffs(values?.map((value) => value.obj));
                  }}
                  required={true}
                />
              </VFlex>
              <Divider height={12} bc-t2 />
              <MemoSection
                selectedMemoTab={selectedMemoTab}
                setSelectedMemoTab={setSelectedMemoTab}
                memos={memos}
                setMemos={setMemos}
                files={files ?? []}
                setFiles={setFiles}
                removeFile={removeFile}
              />

              <Divider height={12} bc-t2 />
              {selectedCustomer && (
                <VFlex g-16 bc-w p-24 bdr-16 sized>
                  <HFlex g-12 a-c>
                    <Text t-20-600-s8>예약자 정보</Text>
                  </HFlex>
                  <VFlex g-16>
                    <HFlex j-b a-c bd-b-t2 p-12-tb>
                      <HFlex g-8 a-c>
                        <Text t-16-s8>
                          {selectedGuardian
                            ? selectedGuardian.name
                            : selectedCustomer.guardianList[0].name}
                        </Text>
                        {selectedGuardian
                          ? selectedGuardian.primaryGuardian && (
                              <VFlex t-14-s6 p-2-tb p-8-rl bd-t4 bdr-8>
                                대표 보호자
                              </VFlex>
                            )
                          : selectedCustomer.guardianList[0]
                              .primaryGuardian && (
                              <VFlex t-14-s6 p-2-tb p-8-rl bd-t4 bdr-8>
                                대표 보호자
                              </VFlex>
                            )}
                      </HFlex>
                      <CopyToClipboard
                        text={
                          selectedGuardian
                            ? selectedGuardian.cellnumber
                            : selectedCustomer.guardianList[0].cellnumber
                        }
                        onCopy={() => {
                          toast.success("전화번호가 복사되었습니다.");
                        }}
                      >
                        <HFlex g-16 a-c clickable>
                          <Text t-14-s6>
                            {selectedGuardian
                              ? selectedGuardian.cellnumber
                              : selectedCustomer.guardianList[0].cellnumber}
                          </Text>
                          <Image size={22} src={`/icons/content/copy_s6.svg`} />
                        </HFlex>
                      </CopyToClipboard>
                    </HFlex>

                    {selectedGuardian ? (
                      selectedGuardian.note ? (
                        <Text t-14-s6 l-m>
                          {selectedGuardian.note}
                        </Text>
                      ) : (
                        <Text t-14-s2>작성된 내용이 없습니다.</Text>
                      )
                    ) : selectedCustomer.guardianList[0].note ? (
                      <Text t-14-s6 l-m>
                        {selectedCustomer.guardianList[0].note}
                      </Text>
                    ) : (
                      <Text t-14-s2>작성된 내용이 없습니다.</Text>
                    )}
                  </VFlex>
                </VFlex>
              )}

              <Divider bc-tw height={108} />
            </VFlex>
            <HFlex
              bc-tw
              p-24
              abs
              g-8
              style={{
                width: "100%",
                bottom: 0,
                boxShadow:
                  "0px -2px 12px 1px rgba(0, 0, 0, 0.08), 0px -1px 2px 0px rgba(0, 0, 0, 0.08)",
                zIndex: 1,
              }}
            >
              <Flex f-1 />
              {/* <ButtonV2 size="M" type="grey_outlined" onClick={() => {}}>
                임시 저장
              </ButtonV2> */}
              <ButtonV2
                size="M"
                type="filled"
                enabled={isCompleted()}
                onClick={async () => {
                  const formData = new FormData();
                  let memoCnt = 0;
                  memos.forEach((memo, index) => {
                    if (
                      (!isNil(memo.value) && !isNaN(memo.value)) ||
                      memo.note
                    ) {
                      formData.append(`notes[${memoCnt}].type`, memo.type);
                      if (!isNil(memo.value) && !isNaN(memo.value)) {
                        formData.append(
                          `notes[${memoCnt}].condition`,
                          MEMO_STATUS_VALUES[memo.value]
                        );
                      }

                      if (memo.note) {
                        formData.append(`notes[${memoCnt}].note`, memo.note);
                      }
                      memoCnt++;
                    }
                  });
                  if (files) {
                    const requestIndex = memos.findIndex(
                      (m) => m.type === "REQUEST"
                    );
                    files.forEach((file, index) => {
                      formData.append(
                        `notes[${
                          requestIndex >= 0 ? requestIndex : memoCnt
                        }].files[${index}]`,
                        file
                      );
                    });
                  }

                  const data = {
                    date: format(startDateTime!, "yyyy-MM-dd"),
                    startTime: format(startDateTime, "HH:mm"),
                    endTime: format(endDateTime, "HH:mm"),
                    petId: selectedCustomer!.id,
                    productIdList: selectedProducts
                      .map((product) => product.product.id)
                      .join(","),
                    userIdList: selectedStaffs
                      ?.map((staff) => staff.id)
                      .join(","),
                    guardianId: selectedGuardian
                      ? selectedGuardian?.id
                      : selectedCustomer?.guardianList[0].id,
                    prepaid: 0,
                    ...Object.fromEntries(formData),
                  };

                  try {
                    setLoading(true);
                    const { id } = await api.post(
                      `/rest/v2/reservation/group/${partner?.id}`,
                      data,
                      {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      }
                    );
                    await fetchCount({
                      date: Math.floor(startDateTime.getTime() / 1000),
                    });

                    toast.success("예약이 확정되었습니다");
                    navigate("/main/reservation", {
                      // state: {
                      //   reservationDetail: {
                      //     id,
                      //   },
                      // },
                    });
                  } catch (e) {
                    toast.error("예약 등록에 실패하였습니다");
                    setLoading(false);
                  }
                }}
              >
                예약 등록
              </ButtonV2>
            </HFlex>
          </VFlex>
        </VFlex>
      </VFlex>
      <VFlex f-1>
        <HFlex a-c p-24 j-b style={{ paddingLeft: "8px" }}>
          <Text t-24-600-s8>예약</Text>
          <Image src="/icons/icon_help_s8.svg" size={24} />
        </HFlex>
        <HFlex p-16-tb a-c>
          <HFlex f-1>
            <HFlex p-4 bc-t1 bdr-8 sized m-8-r ovf-h height={40}>
              {CALENDAR_TYPE.map((type, i) => (
                <HFlex key={i}>
                  <HFlex
                    p-16-rl
                    c-c
                    bdr-6
                    bc-w={calendarType === type}
                    onClick={() => {
                      setCalendarType(type);
                      fetchSchedules(calendarDate);
                      // trackClickCalendarDisplay(type);
                    }}
                    clickable
                    style={{
                      boxShadow:
                        calendarType === type
                          ? "0px 2px 6px 0px rgba(0, 0, 0, 0.20)"
                          : "none",
                    }}
                  >
                    <Text
                      t-14-600-s8={calendarType === type}
                      t-14-s2={calendarType !== type}
                    >
                      {type}
                    </Text>
                  </HFlex>
                </HFlex>
              ))}
            </HFlex>

            <ButtonV2
              type="grey_outlined"
              size="XS"
              onClick={() => {
                const today = startOfDay(new Date());
                setCalendarDate(today);
                fetchSchedules(today);
                // setIsIndicatorShown(true);
              }}
            >
              오늘
            </ButtonV2>
          </HFlex>

          <HFlex f-1 c-c g-12>
            <Image
              src="/icons/icon_chevron_left_s3.svg"
              size={24}
              clickable
              onClick={() => {
                if (
                  // 일, 담당자
                  calendarType === CalendarType.DAILY ||
                  calendarType === CalendarType.STAFF
                ) {
                  const prevDay = addDays(calendarDate, -1);
                  setCalendarDate(prevDay);
                  fetchSchedules(prevDay);
                } else if (
                  // 주, 스케줄
                  calendarType === CalendarType.WEEKLY ||
                  calendarType === CalendarType.SCHEDULE
                ) {
                  const prevWeek = addDays(calendarDate, -7);
                  setCalendarDate(prevWeek);
                  fetchSchedules(prevWeek);
                }
              }}
            />
            <Text t-18-600-s8>
              {/* 일, 담당자 */}
              {(calendarType === CalendarType.DAILY ||
                calendarType === CalendarType.STAFF) &&
                format(calendarDate, "M월 d일 EEEE", { locale: ko })}

              {/* 주, 스케줄 */}
              {(calendarType === CalendarType.WEEKLY ||
                calendarType === CalendarType.SCHEDULE) &&
                getDateRangeString(startDate, currentMonth)}
            </Text>
            <Image
              src="/icons/icon_chevron_right_s3.svg"
              size={24}
              clickable
              onClick={() => {
                if (
                  // 일, 담당자
                  calendarType === CalendarType.DAILY ||
                  calendarType === CalendarType.STAFF
                ) {
                  const nextDay = addDays(calendarDate, 1);
                  setCalendarDate(nextDay);
                  fetchSchedules(nextDay);
                } else if (
                  // 주, 스케줄
                  calendarType === CalendarType.WEEKLY ||
                  calendarType === CalendarType.SCHEDULE
                ) {
                  const nextWeek = addDays(calendarDate, 7);
                  setCalendarDate(nextWeek);
                  fetchSchedules(nextWeek);
                }
              }}
            />
          </HFlex>

          <Flex f-1 />
        </HFlex>
        <VFlex f-1 rel sized p-24-r ovf-a ref={divRef}>
          {calendarType === CALENDAR_TYPE[0] && (
            <DailyCalendar
              staffsFilter={staffsFilter}
              startDateTime={startDateTime}
              endDateTime={endDateTime}
              dateTimeError={dateTimeError}
              selectedStaffs={selectedStaffs}
              statusList={statusList}
              setStartDateTime={setStartDateTime}
              setEndDateTime={setEndDateTime}
            />
          )}
          {calendarType === CALENDAR_TYPE[1] && (
            <WeeklyCalendar
              startDate={startDate}
              startDateTime={startDateTime}
              endDateTime={endDateTime}
              dateTimeError={dateTimeError}
              selectedStaffs={selectedStaffs}
              staffsFilter={staffsFilter}
              statusList={statusList}
              setStartDateTime={setStartDateTime}
              setEndDateTime={setEndDateTime}
            />
          )}
        </VFlex>
      </VFlex>
    </HFlex>
  );
};

const DailyCalendar = ({
  staffsFilter,
  startDateTime,
  endDateTime,
  dateTimeError,
  personalList,
  statusList,
  selectedStaffs,
  setStartDateTime,
  setEndDateTime,
}: {
  staffsFilter: Staff[] | undefined;
  startDateTime: Date;
  endDateTime: Date;
  dateTimeError: boolean;
  personalList?: ReservationStatus[];
  statusList?: ReservationStatus[];
  selectedStaffs: Staff[] | undefined;
  setStartDateTime: React.Dispatch<SetStateAction<Date>>;
  setEndDateTime: React.Dispatch<SetStateAction<Date>>;
}) => {
  const { schedules, calendarDate } = useSchedules();
  const [startPosition, setStartPosition] = useState<{
    x: number;
    y: number;
  }>();
  const scheduleBoxRef = useRef<HTMLDivElement>(null);
  const [newStartDateTime, setNewStartDateTime] = useState<Date>();
  const [newEndDateTime, setNewEndDateTime] = useState<Date>();
  const [isDragging, setIsDragging] = useState(false);

  const { businessHours, businessHolidays, temporaryHolidays } =
    useBusinessHours();
  const businessHoursByDay = useMemo(() => {
    return _.keyBy(businessHours, (businessHour) => {
      return dayOfWeek.findIndex((day) => day === businessHour.dayOfWeek);
    });
  }, [businessHours]);

  const boundaryRef = useRef<HTMLDivElement>(null);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (isDragging) {
      setIsDragging(false);
      return;
    }

    const { y } = scheduleBoxRef.current?.getBoundingClientRect()!;

    const curY = Math.floor((e.clientY - y) / 38);

    const newStartDateTime = new Date(
      calendarDate.getFullYear(),
      calendarDate.getMonth(),
      calendarDate.getDate(),
      Math.floor(curY / 2),
      (curY % 2) * 30
    );
    const newEndDateTime = new Date(
      calendarDate.getFullYear(),
      calendarDate.getMonth(),
      calendarDate.getDate(),
      Math.floor(curY / 2),
      (curY % 2) * 30 + differenceInMinutes(endDateTime, startDateTime)
    );
    setStartDateTime(newStartDateTime);
    setEndDateTime(newEndDateTime);
  };

  const bind = useLongPress(
    () => {
      const startDateTime = new Date(
        calendarDate.getFullYear(),
        calendarDate.getMonth(),
        calendarDate.getDate(),
        Math.floor(startPosition!.y / 2),
        (startPosition!.y % 2) * 30
      );
      const endDateTime = new Date(
        calendarDate.getFullYear(),
        calendarDate.getMonth(),
        calendarDate.getDate(),
        Math.floor(startPosition!.y / 2),
        (startPosition!.y % 2) * 30 + 60
      );
      setNewStartDateTime(startDateTime);
      setNewEndDateTime(endDateTime);
    },
    {
      filterEvents: (e: any) => {
        // filter out right click
        return e.buttons === 1;
      },
      onStart: (e: any) => {
        const { x, y, width } =
          scheduleBoxRef.current?.getBoundingClientRect()!;
        setStartPosition({
          x: Math.floor(
            (e.clientX - x) / (width / ((selectedStaffs?.length ?? 0) + 1))
          ),
          y: Math.floor((e.clientY - y) / 38),
        });
      },
      onMove: (e: any) => {
        const { x, y, width } =
          scheduleBoxRef.current?.getBoundingClientRect()!;
        if (startPosition) {
          const newEndPosition = {
            x: Math.floor(
              (e.clientX - x) / (width / ((selectedStaffs?.length ?? 0) + 1))
            ),
            y: Math.floor((e.clientY - y) / 38) + 1,
          };

          if (newEndDateTime && newStartDateTime) {
            let newEDT = new Date(
              calendarDate.getFullYear(),
              calendarDate.getMonth(),
              newStartDateTime.getDate(),
              Math.floor(newEndPosition.y / 2),
              (newEndPosition.y % 2) * 30
            );

            if (newEndDateTime.getTime() !== newEDT.getTime()) {
              if (
                differenceInMinutes(newEDT, newStartDateTime.getTime()) < 60
              ) {
                newEDT = addMinutes(newStartDateTime, 60);
              }

              setNewEndDateTime(newEDT);
            }
          }
        }
      },
      onFinish: async (e: LongPressEvent) => {
        if ("buttons" in e && e.buttons > 0) {
          setStartPosition(undefined);
          setNewEndDateTime(undefined);
          setNewStartDateTime(undefined);
          return;
        }
        setStartPosition(undefined);
        if (newStartDateTime && newEndDateTime) {
          setStartDateTime(newStartDateTime);
          setEndDateTime(newEndDateTime);
          setNewEndDateTime(undefined);
          setNewStartDateTime(undefined);
        }
      },
      onCancel: () => {
        setStartPosition(undefined);
        setNewEndDateTime(undefined);
        setNewStartDateTime(undefined);
      },
      threshold: 200,
    }
  );
  const filteredSchedules = useMemo(() => {
    return schedules?.filter((schedule) => {
      return (
        startOfDay(schedule.startDateTime).getTime() ===
          startOfDay(calendarDate).getTime() &&
        _.some([
          schedule.petName,
          schedule.nickname ?? "",
          schedule.cellNumber ?? "",
        ]) &&
        (schedule.staffs.length === 0 ||
          _.intersection(
            staffsFilter?.map((staff) => staff.id),
            schedule.staffs.map((staff) => staff.id)
          ).length > 0)
      );
    });
  }, [schedules, calendarDate, staffsFilter]);

  const schedulesByStaff = useMemo(() => {
    return (
      filteredSchedules &&
      _(filteredSchedules)
        .flatMap(({ staffs, ...fields }) =>
          staffs.length === 0
            ? [{ ...fields, staffs, background: "#E4E8E6" }]
            : staffs.map<Schedule>((staff) => ({
                ...fields,
                staffs: [staff],
                background: staffColors[staff?.color]?.bgColor ?? "#E4E8E6",
                mainColor: staffColors[staff?.color]?.mainColor ?? "#4B4F4D",
              }))
        )
        .groupBy((schedule) => 0)
        .mapValues((schedules) =>
          _.orderBy(schedules, (schedule) => schedule.startDateTime.getTime())
        )
        .value()
    );
  }, [filteredSchedules]);

  const maxOverlapByStaff = useMemo(() => {
    return _.mapValues(schedulesByStaff, (schedules) => {
      if (!schedules.length) {
        return {
          maxOverlapIndex: 0,
          schedules: {},
        };
      }
      let schedulesToIterate = schedules;
      let currentEndTime;
      let overlapIndex = 0;
      let nextSchedulesToIterate = [];
      const schedulesByOverlapIndex: Record<string, Schedule[]> = {};
      while (true) {
        for (const schedule of schedulesToIterate) {
          if (currentEndTime && currentEndTime > schedule.startDateTime) {
            nextSchedulesToIterate.push(schedule);
            continue;
          }
          currentEndTime = schedule.endDateTime;
          schedulesByOverlapIndex[overlapIndex.toString()] ||= [];
          schedulesByOverlapIndex[overlapIndex.toString()].push(schedule);
        }
        if (nextSchedulesToIterate.length === 0) {
          break;
        }
        overlapIndex++;
        schedulesToIterate = [...nextSchedulesToIterate];
        nextSchedulesToIterate = [];
        currentEndTime = undefined;
      }
      return {
        maxOverlapIndex: overlapIndex + 1,
        schedules: schedulesByOverlapIndex,
      };
    });
  }, [schedulesByStaff]);

  return (
    <VFlex f-1>
      <HFlex f-1 rel>
        <VFlex width={80} bd-r-t5>
          {[...Array(24).keys()]
            .map((value) => value * 60)
            .map((value, i) => (
              <VFlex key={i}>
                <VFlex
                  c-c
                  height={38}
                  style={{
                    borderBottom: "1px dashed #DEE2E0",
                  }}
                >
                  <Text t-14-500-s4>{`${value >= 720 ? "오후" : "오전"} ${
                    Math.floor(value / 60) % 12 || 12
                  }:${("0" + (value % 60)).slice(-2)}`}</Text>
                </VFlex>
                <VFlex c-c height={38} bd-b-t5 />
              </VFlex>
            ))}
        </VFlex>
        {/* 현재 시간 바 */}
        {calendarDate.getDate() === new Date().getDate() && (
          <VFlex
            style={{
              position: "absolute",
              top: `${
                19 *
                Math.floor(
                  (new Date().getHours() * 60 + new Date().getMinutes()) / 15
                )
              }px`,
              left: 80,
              width: `calc(100% - 80px)`,
              height: 1,
              background: "#0092E4",
              zIndex: 1,
            }}
          >
            <div
              style={{
                position: "absolute",
                top: -5,
                left: -5,
                width: 11,
                height: 11,
                borderRadius: "100%",
                background: "#0092ea",
                zIndex: 1,
              }}
            ></div>
          </VFlex>
        )}
        <VFlex f-1 ovf-h>
          <HFlex f-1 ref={scheduleBoxRef} {...bind()} onClick={handleClick}>
            <VFlex f-1 rel ref={boundaryRef}>
              <Absolute>
                {_.entries(maxOverlapByStaff[0]?.schedules).map(
                  ([overlapIndex, schedules], idx) => (
                    <VFlex key={idx} f-1 rel>
                      {schedules.map((schedule, j) => (
                        <ScheduleBlock
                          key={j}
                          schedule={schedule}
                          overlapIndex={parseInt(overlapIndex)}
                          maxOverlapIndex={maxOverlapByStaff[0].maxOverlapIndex}
                          personalList={personalList}
                          statusList={statusList}
                        />
                      ))}
                    </VFlex>
                  )
                )}
              </Absolute>
              {newStartDateTime && newEndDateTime && (
                <Absolute>
                  <CreatingScheduleBlock
                    startDateTime={newStartDateTime}
                    endDateTime={newEndDateTime}
                    boundaryRef={boundaryRef}
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                    calendarType={CalendarType.DAILY}
                    setIsDragging={setIsDragging}
                  />
                </Absolute>
              )}
              {isNil(newStartDateTime) &&
                isNil(newEndDateTime) &&
                !dateTimeError &&
                (isSameDay(calendarDate, startDateTime) ||
                  isSameDay(calendarDate, endDateTime)) && (
                  <Absolute>
                    <CreatingScheduleBlock
                      startDateTime={startDateTime}
                      endDateTime={endDateTime}
                      boundaryRef={boundaryRef}
                      setStartDateTime={setStartDateTime}
                      setEndDateTime={setEndDateTime}
                      calendarType={CalendarType.DAILY}
                      setIsDragging={setIsDragging}
                    />
                  </Absolute>
                )}

              {Array(48)
                .fill(1)
                .map((_, j) => (
                  <VFlex
                    key={j}
                    height={38}
                    bc-t1={checkIsBusinessHour({
                      date: calendarDate,
                      businessHours:
                        businessHoursByDay[calendarDate.getDay().toString()],
                      businessHolidays,
                      temporaryHolidays,
                      time: j * 30,
                    })}
                    style={{
                      borderBottom: `1px ${
                        j % 2 === 0 ? "dashed" : "solid"
                      } #DEE2E0`,
                    }}
                  />
                ))}
            </VFlex>
          </HFlex>
        </VFlex>
      </HFlex>
    </VFlex>
  );
};

const WeeklyCalendar = ({
  startDate,
  staffsFilter,
  startDateTime,
  endDateTime,
  dateTimeError,
  personalList,
  statusList,
  selectedStaffs,
  setStartDateTime,
  setEndDateTime,
}: {
  startDate: Date;
  startDateTime: Date;
  endDateTime: Date;
  dateTimeError: boolean;
  personalList?: ReservationStatus[];
  statusList?: ReservationStatus[];
  selectedStaffs: Staff[] | undefined;
  staffsFilter: Staff[] | undefined;
  setStartDateTime: React.Dispatch<SetStateAction<Date>>;
  setEndDateTime: React.Dispatch<SetStateAction<Date>>;
}) => {
  const scheduleBoxRef = useRef<HTMLDivElement>(null);
  const boundaryRef = useRef<HTMLDivElement>(null);
  const { schedules, calendarDate } = useSchedules();
  const { businessHours, businessHolidays, temporaryHolidays } =
    useBusinessHours();

  const [isDragging, setIsDragging] = useState(false);
  const filteredSchedules = useMemo(() => {
    return schedules?.filter((schedule) => {
      return (
        startOfWeek(schedule.startDateTime).getTime() ===
          startOfWeek(calendarDate).getTime() &&
        _.some([
          schedule.petName,
          schedule.nickname ?? "",
          schedule.cellNumber ?? "",
        ]) &&
        (schedule.staffs.length === 0 ||
          _.intersection(
            staffsFilter?.map((staff) => staff.id),
            schedule.staffs.map((staff) => staff.id)
          ).length > 0)
      );
    });
  }, [schedules, calendarDate, staffsFilter]);

  const [startPosition, setStartPosition] = useState<{
    x: number;
    y: number;
  }>();
  const [newStartDateTime, setNewStartDateTime] = useState<Date>();
  const [newEndDateTime, setNewEndDateTime] = useState<Date>();

  const schedulesByDay = useMemo(
    () =>
      filteredSchedules &&
      _(filteredSchedules)
        .groupBy((schedule: Schedule) => schedule.startDateTime.getDay())
        .mapValues((schedules) =>
          _.orderBy(schedules, (schedule) => schedule.startDateTime.getTime())
        )
        .value(),
    [filteredSchedules]
  );
  const maxOverlapByDay = useMemo(
    () =>
      _.mapValues(schedulesByDay, (schedules) => {
        if (!schedules.length) {
          return {
            maxOverlapIndex: 0,
            schedules: {},
          };
        }
        let schedulesToIterate = schedules;
        let currentEndTime;
        let overlapIndex = 0;
        let nextSchedulesToIterate = [];
        const schedulesByOverlapIndex: Record<string, Schedule[]> = {};
        while (true) {
          for (const schedule of schedulesToIterate) {
            if (currentEndTime && currentEndTime > schedule.startDateTime) {
              nextSchedulesToIterate.push(schedule);
              continue;
            }
            currentEndTime = schedule.endDateTime;
            schedulesByOverlapIndex[overlapIndex.toString()] ||= [];
            schedulesByOverlapIndex[overlapIndex.toString()].push(schedule);
          }
          if (nextSchedulesToIterate.length === 0) {
            break;
          }
          overlapIndex++;
          schedulesToIterate = [...nextSchedulesToIterate];
          nextSchedulesToIterate = [];
          currentEndTime = undefined;
        }
        return {
          maxOverlapIndex: overlapIndex + 1,
          schedules: schedulesByOverlapIndex,
        };
      }),
    [schedulesByDay]
  );

  const businessHoursByDay = useMemo(() => {
    return _.keyBy(businessHours, (businessHour) => {
      return dayOfWeek.findIndex((day) => day === businessHour.dayOfWeek);
    });
  }, [businessHours]);

  const isToday = (i: number) => {
    const date = new Date(startDate);
    date.setDate(date.getDate() + i);

    return isSameDay(date, new Date());
  };
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (isDragging) {
      setIsDragging(false);
      return;
    }

    const { x, y, width } = scheduleBoxRef.current?.getBoundingClientRect()!;

    const curX = Math.floor((e.clientX - x) / (width / 7));
    const curY = Math.floor((e.clientY - y) / 38);

    const newStartDateTime = new Date(
      calendarDate.getFullYear(),
      calendarDate.getMonth(),
      addDays(startDate, curX).getDate(),
      Math.floor(curY / 2),
      (curY % 2) * 30
    );
    const newEndDateTime = new Date(
      calendarDate.getFullYear(),
      calendarDate.getMonth(),
      addDays(startDate, curX).getDate(),
      Math.floor(curY / 2),
      (curY % 2) * 30 + differenceInMinutes(endDateTime, startDateTime)
    );
    setStartDateTime(newStartDateTime);
    setEndDateTime(newEndDateTime);
  };
  const bind = useLongPress(
    () => {
      const startDateTime = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + startPosition!.x,
        Math.floor(startPosition!.y / 2),
        (startPosition!.y % 2) * 30
      );
      const endDateTime = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + startPosition!.x,
        Math.floor(startPosition!.y / 2),
        (startPosition!.y % 2) * 30 + 60
      );
      setNewStartDateTime(startDateTime);
      setNewEndDateTime(endDateTime);
    },
    {
      filterEvents: (e: any) => {
        // filter out right click
        return e.buttons === 1;
      },
      onStart: (e: any) => {
        const { x, y, width } =
          scheduleBoxRef.current?.getBoundingClientRect()!;

        setStartPosition({
          x: Math.floor((e.clientX - x) / (width / 7)),
          y: Math.floor((e.clientY - y) / 38),
        });
      },
      onMove: (e: any) => {
        const { x, y, width } =
          scheduleBoxRef.current?.getBoundingClientRect()!;
        if (startPosition) {
          const newEndPosition = {
            x: Math.floor((e.clientX - x) / (width / 7)),
            y: Math.floor((e.clientY - y) / 38) + 1,
          };

          if (newEndDateTime && newStartDateTime) {
            let newEDT = new Date(
              calendarDate.getFullYear(),
              calendarDate.getMonth(),
              newStartDateTime.getDate(),
              Math.floor(newEndPosition.y / 2),
              (newEndPosition.y % 2) * 30
            );

            if (newEndDateTime.getTime() !== newEDT.getTime()) {
              if (
                differenceInMinutes(newEDT, newStartDateTime.getTime()) < 60
              ) {
                newEDT = addMinutes(newStartDateTime, 60);
              }

              setNewEndDateTime(newEDT);
            }
          }
        }
      },
      onFinish: async (e: LongPressEvent) => {
        if ("buttons" in e && e.buttons > 0) {
          setStartPosition(undefined);
          setNewEndDateTime(undefined);
          setNewStartDateTime(undefined);
          return;
        }
        setStartPosition(undefined);
        if (newStartDateTime && newEndDateTime) {
          setStartDateTime(newStartDateTime);
          setEndDateTime(newEndDateTime);
          setNewEndDateTime(undefined);
          setNewStartDateTime(undefined);
        }
      },
      onCancel: () => {
        setStartPosition(undefined);
        setNewEndDateTime(undefined);
        setNewStartDateTime(undefined);
      },
      threshold: 200,
    }
  );

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const endDate = addDays(startDate, 7);
    const inRange = startDateTime >= startDate && startDateTime <= endDate;

    if (inRange)
      divRef.current?.scrollTo({
        top: 38 * startDateTime.getHours() * 2,
      });
  }, [divRef, calendarDate]);

  return (
    <VFlex f-1 ovf-h>
      <HFlex>
        <VFlex width={80} bd-b-t5 c-c />
        <VFlex f-1>
          <HFlex>
            {days.map((day, i) => (
              <VFlex key={i} f-1 c-c height={56} ovf-h>
                <Text t-12-s4={!isToday(i)} t-12-gr5={isToday(i)} l-1>
                  {day}
                </Text>
                <Text t-18-s8={!isToday(i)} t-18-gr5={isToday(i)} l-1>
                  {new Date(
                    startDate.getTime() + 24 * 60 * 60 * 1000 * i
                  ).getDate()}
                </Text>
              </VFlex>
            ))}
          </HFlex>
          <HFlex bd-b-t5>
            {days.map((_day, i) => (
              <VFlex key={i} f-1 rel>
                {checkIsBusinessHolidays(
                  businessHolidays,
                  addDays(startDate, i)
                ) && <HolidayBlock text="정기 휴무" height={18} />}
                {checkIsTemporaryHolidays(
                  temporaryHolidays,
                  addDays(startDate, i)
                ) && <HolidayBlock text="임시 휴무" height={18} />}
              </VFlex>
            ))}
          </HFlex>
        </VFlex>
      </HFlex>
      <VFlex rel ovf-a ref={divRef}>
        <HFlex f-1 rel>
          <VFlex width={80}>
            {[...Array(24).keys()]
              .map((value) => value * 60)
              .map((value, i) => (
                <VFlex key={i}>
                  <VFlex
                    c-c
                    height={38}
                    bd-br-t5
                    style={{
                      borderBottom: "1px dashed #DEE2E0",
                    }}
                  >
                    <Text t-14-500-s4>{`${value >= 720 ? "오후" : "오전"} ${
                      Math.floor(value / 60) % 12 || 12
                    }:${("0" + (value % 60)).slice(-2)}`}</Text>
                  </VFlex>
                  <VFlex c-c height={38} bd-br-t5 />
                </VFlex>
              ))}
          </VFlex>
          <VFlex f-1>
            <HFlex f-1 ref={scheduleBoxRef} {...bind()} onClick={handleClick}>
              {days.map((__, i) => (
                <VFlex key={i} f-1 rel>
                  <Absolute bd-l-t5={i > 0}>
                    {_.entries(maxOverlapByDay[i]?.schedules).map(
                      ([overlapIndex, schedules], idx) => (
                        <VFlex key={idx} f-1 rel>
                          {schedules.map((schedule, j) => {
                            return (
                              <ScheduleBlock
                                key={j}
                                schedule={schedule}
                                overlapIndex={parseInt(overlapIndex)}
                                maxOverlapIndex={
                                  maxOverlapByDay[i].maxOverlapIndex
                                }
                                personalList={personalList}
                                statusList={statusList}
                              />
                            );
                          })}
                        </VFlex>
                      )
                    )}
                  </Absolute>
                  {isToday(i) && (
                    // 현재 시간 바
                    <VFlex
                      style={{
                        position: "absolute",
                        top: `${
                          19 *
                          Math.floor(
                            (new Date().getHours() * 60 +
                              new Date().getMinutes()) /
                              15
                          )
                        }px`,
                        left: 0,
                        width: `100%`,
                        height: 1,
                        background: "#0092E4",
                        zIndex: 1,
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: -5,
                          left: -5,
                          width: 11,
                          height: 11,
                          borderRadius: "100%",
                          background: "#0092ea",
                          zIndex: 1,
                        }}
                      ></div>
                    </VFlex>
                  )}
                  {newStartDateTime &&
                    newEndDateTime &&
                    isSameDay(
                      newStartDateTime,
                      new Date(startDate).setDate(startDate.getDate() + i)
                    ) && (
                      <Absolute>
                        <CreatingScheduleBlock
                          startDateTime={newStartDateTime}
                          endDateTime={newEndDateTime}
                          boundaryRef={scheduleBoxRef}
                          setStartDateTime={setStartDateTime}
                          setEndDateTime={setEndDateTime}
                          calendarType={CalendarType.WEEKLY}
                          startDate={startDate}
                        />
                      </Absolute>
                    )}
                  {isNil(newStartDateTime) &&
                    isNil(newEndDateTime) &&
                    !dateTimeError &&
                    isSameDay(
                      startDateTime,
                      new Date(startDate).setDate(startDate.getDate() + i)
                    ) && (
                      <Absolute>
                        <CreatingScheduleBlock
                          startDateTime={startDateTime}
                          endDateTime={endDateTime}
                          boundaryRef={scheduleBoxRef}
                          setStartDateTime={setStartDateTime}
                          setEndDateTime={setEndDateTime}
                          calendarType={CalendarType.WEEKLY}
                          startDate={startDate}
                          setIsDragging={setIsDragging}
                        />
                      </Absolute>
                    )}
                  {Array(48)
                    .fill(1)
                    .map((_, j) => (
                      <VFlex
                        key={j}
                        height={38}
                        bc-t1={checkIsBusinessHour({
                          date: addDays(startOfWeek(calendarDate), i),
                          businessHours: businessHoursByDay[i],
                          businessHolidays,
                          temporaryHolidays,
                          time: j * 30,
                        })}
                        style={{
                          borderBottom: `1px ${
                            j % 2 === 0 ? "dashed" : "solid"
                          } #DEE2E0`,
                        }}
                      />
                    ))}
                </VFlex>
              ))}
            </HFlex>
          </VFlex>
          {!schedulesByDay && (
            <VFlex abs c-c style={{ inset: 0 }} unclickable>
              <Spinner />
            </VFlex>
          )}
        </HFlex>
      </VFlex>
    </VFlex>
  );
};

export default NewReservationCalendarView;

const MemoSection = ({
  selectedMemoTab,
  setSelectedMemoTab,
  memos,
  setMemos,
  files,
  setFiles,
  removeFile,
}: {
  reservationId?: number;
  selectedMemoTab: string;
  setSelectedMemoTab: any;
  memos: any;
  setMemos: any;
  files: File[];
  setFiles: any;
  removeFile: any;
}) => {
  const [memoVisible, setMemoVisible] = useState(true);

  return (
    <>
      <HFlex p-24 g-12 a-c j-b>
        <HFlex g-12 a-c>
          <Text t-20-600-s8>예약 메모</Text>
          <Text t-14-s4>우리 업체만 볼 수 있는 메모입니다.</Text>
        </HFlex>
        <Image
          src={`/icons/navigation/expand_${
            memoVisible ? "less" : "more"
          }_s3.svg`}
          size={24}
          clickable
          onClick={() => {
            setMemoVisible((prev) => !prev);
          }}
        />
      </HFlex>
      <VFlex
        style={{
          display: memoVisible ? "block" : "none",
        }}
      >
        <HFlex g-12 m-8-t m-24-b j-b a-c>
          <HFlex p-24-rl height={34} g-6>
            <FilterChip
              type={
                selectedMemoTab === MEMO_OPTIONS[0] ? "selected" : undefined
              }
              onClick={() => {
                setSelectedMemoTab(MEMO_OPTIONS[0]);
              }}
            >
              스타일 메모
            </FilterChip>
            <FilterChip
              type={
                selectedMemoTab === MEMO_OPTIONS[1] ? "selected" : undefined
              }
              onClick={() => {
                setSelectedMemoTab(MEMO_OPTIONS[1]);
              }}
            >
              컨디션 메모
            </FilterChip>
            <FilterChip
              type={
                selectedMemoTab === MEMO_OPTIONS[2] ? "selected" : undefined
              }
              onClick={() => {
                setSelectedMemoTab(MEMO_OPTIONS[2]);
              }}
            >
              예약 메모
            </FilterChip>
          </HFlex>
        </HFlex>
        <VFlex p-24-b>
          {selectedMemoTab === MEMO_OPTIONS[0] && (
            <StyleMemoSection memos={memos} setMemos={setMemos} />
          )}
          {selectedMemoTab === MEMO_OPTIONS[1] && (
            <ConditionMemoSection memos={memos} setMemos={setMemos} />
          )}
          {selectedMemoTab === MEMO_OPTIONS[2] && (
            <ResMemoSection
              memos={memos}
              setMemos={setMemos}
              files={files}
              setFiles={setFiles}
              removeFile={removeFile}
            />
          )}
        </VFlex>
      </VFlex>
    </>
  );
};

const StyleMemoSection = ({
  memos,
  setMemos,
}: {
  memos: Memo<keyof typeof MemoType>[];
  setMemos: (memos: Memo<keyof typeof MemoType>[]) => void;
}) => {
  return (
    <VFlex p-24-rl>
      {memos.slice(7).map((i, index) => (
        <TextArea
          key={i.type}
          caption={MemoType[i.type]}
          placeholder={"메모하기"}
          value={i.note}
          height={72}
          maxLength={800}
          onChangeValue={(v) => {
            const newMemos = [...memos];

            const newMemo: Memo<keyof typeof MemoType> = {
              type: i?.type,
              value: i?.value,
              note: v,
              displayOrder: i.displayOrder,
            };
            newMemos[index + 7] = newMemo;

            if (v?.length || 0 <= 800) setMemos(newMemos);
          }}
        />
      ))}
    </VFlex>
  );
};

const ConditionMemoSection = ({
  memos,
  setMemos,
}: {
  memos: Memo<keyof typeof MemoType>[];
  setMemos: (memos: Memo<keyof typeof MemoType>[]) => void;
}) => {
  return (
    <VFlex p-24-rl g-16>
      {memos.slice(1, 6).map((i, index) => {
        return (
          <VFlex key={i.type} g-16>
            <GroupRadioButton
              value={
                !isNil(i.value)
                  ? {
                      value: i.value,
                      label: MEMO_STATUS_VALUE[i.value],
                    }
                  : undefined
              }
              caption={MemoType[i.type]}
              options={[
                { label: "좋아요", value: 0 },
                { label: "보통", value: 1 },
                { label: "관찰이 필요해요", value: 2 },
              ]}
              onChange={(e) => {
                if (!isNil(e?.value)) {
                  const newMemos = [...memos];

                  const newMemo: Memo<keyof typeof MemoType> = {
                    type: i?.type,
                    value: e?.value,
                    note: i?.note,
                    displayOrder: i.displayOrder,
                  };
                  newMemos[index + 1] = newMemo;

                  setMemos(newMemos);
                } else {
                  const newMemos = [...memos];

                  const newMemo: Memo<keyof typeof MemoType> = {
                    type: memos[index + 1].type,
                    value: undefined,
                    note: memos[0]?.note,
                    displayOrder: memos[0].displayOrder,
                  };
                  newMemos[index + 1] = newMemo;

                  setMemos(newMemos);
                }
              }}
            />
            {i.value == 2 && (
              <TextArea
                value={i.note}
                placeholder={"내용을 입력해주세요."}
                height={120}
                maxLength={800}
                onChangeValue={(v) => {
                  const newMemos = [...memos];

                  const newMemo: Memo<keyof typeof MemoType> = {
                    type: memos[index + 1]?.type,
                    value: memos[index + 1]?.value,
                    note: v,
                    displayOrder: memos[index + 1].displayOrder,
                  };
                  newMemos[index + 1] = newMemo;

                  if (v?.length || 0 <= 800) setMemos(newMemos);
                }}
              />
            )}
          </VFlex>
        );
      })}
      <VFlex key={memos[6]?.type} g-16>
        <GroupRadioButton
          value={
            !isNil(memos[6].value)
              ? {
                  value: memos[6]?.value,
                  label: MEMO_STATUS_VALUE[memos[6]?.value],
                }
              : undefined
          }
          caption={MemoType[memos[6].type]}
          options={[
            { label: "없어요", value: 3 },
            { label: "있어요", value: 4 },
          ]}
          onChange={(e) => {
            if (!isNil(e?.value)) {
              const newMemos = [...memos];

              const newMemo: Memo<keyof typeof MemoType> = {
                type: memos[6]?.type,
                value: e?.value,
                note: memos[6]?.note,
                displayOrder: memos[6].displayOrder,
              };
              newMemos[6] = newMemo;

              setMemos(newMemos);
            } else {
              const newMemos = [...memos];

              const newMemo: Memo<keyof typeof MemoType> = {
                type: memos[6].type,
                value: undefined,
                note: memos[6]?.note,
                displayOrder: memos[6].displayOrder,
              };
              newMemos[6] = newMemo;

              setMemos(newMemos);
            }
          }}
        />
        {memos[6].value == 2 && (
          <TextInput
            value={memos[6].note}
            onChange={(e) => {
              const newMemos = [...memos];

              const newMemo: Memo<keyof typeof MemoType> = {
                type: memos[6]?.type,
                value: memos[6]?.value,
                note: e.target.value,
                displayOrder: memos[6].displayOrder,
              };
              newMemos[6] = newMemo;

              setMemos(newMemos);
            }}
          />
        )}
      </VFlex>
    </VFlex>
  );
};

const ResMemoSection = ({
  memos,
  setMemos,
  files,
  setFiles,
  removeFile,
}: {
  memos: Memo<keyof typeof MemoType>[];
  setMemos: (memos: Memo<keyof typeof MemoType>[]) => void;
  files: File[];
  setFiles: any;
  removeFile: any;
}) => {
  const pushPhotosSwiperPopup = (index: number) => {
    pushPopup({
      element: PhotosSwiperPopup,
      kind: PopupKind.Swiper,
      props: {
        attachments: files,
        files: files,
        index,
      },
    });
  };

  return (
    <VFlex g-12>
      <VFlex p-24-rl>
        <TextArea
          placeholder="내용을 입력해주세요."
          value={memos[0]?.note}
          onChangeValue={(v) => {
            const newMemos = [...memos];
            newMemos[0] = { ...newMemos[0], note: v };
            if (v?.length || 0 <= 800) setMemos(newMemos);
          }}
          height={120}
          maxLength={800}
        />
      </VFlex>
      <HFlex p-24-l a-c g-8>
        <VFlex bd-t5 bdr-16 a-c j-c sized width={56} height={56}>
          <label
            htmlFor="upload"
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Image src="/icons/action/photo_camera_s6.svg" size={24} />
            <FilesUpload
              id="upload"
              multiple
              onLoad={async (f: File[]) => {
                if (!files) {
                  setFiles(f);
                } else {
                  setFiles([...files!, ...f]);
                }
              }}
            />
          </label>
        </VFlex>
        {files && (
          <HFlex
            p-10-t
            p-8-r
            g-8
            ovf-s-d
            style={{
              marginTop: "-10px",
            }}
          >
            {files.map((file, i) => {
              return (
                <VFlex
                  key={i}
                  rel
                  clickable
                  onClick={() => {
                    pushPhotosSwiperPopup(i);
                  }}
                >
                  <Image
                    width={56}
                    height={56}
                    bdr-16
                    src={URL.createObjectURL(file)}
                  />
                  <Absolute
                    top={-3}
                    right={-3}
                    bottom={"auto"}
                    left={"auto"}
                    width={16}
                    height={16}
                    clickable
                    onClick={() => {
                      removeFile(i);
                    }}
                  >
                    <Image src="/icons/delete_photo.svg" size={16} />
                  </Absolute>
                </VFlex>
              );
            })}
          </HFlex>
        )}
      </HFlex>
    </VFlex>
  );
};
