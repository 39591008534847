import { PropsWithChildren } from "react";

import VFlex from "../layouts/VFlex";
import HFlex from "../layouts/HFlex";
import Flex from "../layouts/Flex";
import Image from "../layouts/Image";
import Text from "../layouts/Text";

interface DropdownWithActionProps extends PropsWithChildren {
  title?: string;
  subTitle?: string;
  handleAction: () => void;
}

const DropdownWithAction = ({
  title,
  subTitle,
  children,
  handleAction,
}: DropdownWithActionProps) => {
  return (
    <VFlex width={240} p-8 bdr-12 sdw-6 bc-tw>
      <HFlex p-8 bd-b-t3 a-c g-8 onClick={handleAction}>
        <Image size={20} src={`/icons/edit.svg`} clickable />
        {title && <Text t-14-500-s6>{title}</Text>}
      </HFlex>
      {subTitle && (
        <VFlex t-14-s4 p-8>
          {subTitle}
        </VFlex>
      )}

      <VFlex ovf-a style={{ maxHeight: "255px" }}>
        {children}
      </VFlex>
    </VFlex>
  );
};

interface ListItemProps extends PropsWithChildren {
  handleClick: () => void;
}

const ListItem = ({ children, handleClick }: ListItemProps) => {
  return (
    <HFlex
      j-b
      a-c
      p-8
      bdr-8
      clickable
      sized
      onClick={handleClick}
      classes={["hover"]}
    >
      <Flex>{children}</Flex>
    </HFlex>
  );
};

DropdownWithAction.ListItem = ListItem;

export default DropdownWithAction;
