import Divider from "../layouts/Divider";
import HFlex from "../layouts/HFlex";
import VFlex from "../layouts/VFlex";
import Text from "../layouts/Text";
import Dropdown from "../layouts/Dropdown";
import { useState } from "react";

import "./TimePicker.scss";
import { minutesToTimeString } from "../common/format";

interface TimePickerProps {
  renderTrigger: () => React.ReactElement;
  //   isRange?: boolean;
  startTime: number;
  setStartTime: (t: number) => void;
  endTime: number;
  setEndTime: (t: number) => void;
}

const FOCUSED_TIME = ["START", "END"] as const;
const TimePicker = ({
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  renderTrigger,
}: TimePickerProps) => {
  const [focused, setFocused] = useState<(typeof FOCUSED_TIME)[number]>(
    FOCUSED_TIME[0]
  );
  return (
    <Dropdown
      trigger={renderTrigger()}
      position="left"
      renderOptions={() => (
        <VFlex classes={["time-picker"]}>
          <HFlex height={38} bdr-8>
            <HFlex
              onClick={() => {
                setFocused(FOCUSED_TIME[0]);
              }}
              clickable
              f-1
              p-12-rl
              a-c
              bd-t5={focused !== FOCUSED_TIME[0]}
              bd-g1={focused === FOCUSED_TIME[0]}
              style={{
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px",
                borderRight: "none",
                background:
                  focused === FOCUSED_TIME[0]
                    ? "rgba(39, 189, 99, 0.04)"
                    : "#F8F9F8",
              }}
            >
              <Text t-14-500-s8>{minutesToTimeString(startTime)}</Text>
            </HFlex>
            <Divider width={1} bc-g1 />
            <HFlex
              onClick={() => {
                setFocused(FOCUSED_TIME[1]);
              }}
              clickable
              f-1
              p-12-rl
              a-c
              bd-t5={focused !== FOCUSED_TIME[1]}
              bd-g1={focused === FOCUSED_TIME[1]}
              style={{
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
                borderLeft: "none",
                background:
                  focused === FOCUSED_TIME[1]
                    ? "rgba(39, 189, 99, 0.04)"
                    : "#F8F9F8",
              }}
            >
              <Text t-14-500-s8>{minutesToTimeString(endTime)}</Text>
            </HFlex>
          </HFlex>
          <HFlex p-8-t classes={["time-container"]}>
            <VFlex f-1 a-c ovf-a>
              {[12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((h, index) => {
                const currentHour = Math.floor(
                  (focused === FOCUSED_TIME[0] ? startTime! : endTime!) / 60
                );
                const currentMin =
                  (focused === FOCUSED_TIME[0] ? startTime! : endTime!) % 60;

                const selectedHour =
                  currentHour >= 12 ? currentHour - 12 : currentHour;

                return (
                  <HFlex
                    key={h}
                    classes={
                      selectedHour === index
                        ? ["time-option-item", "time-option-item--selected"]
                        : ["time-option-item"]
                    }
                    onClick={() => {
                      const newHour = currentHour > 12 ? index + 12 : index;

                      if (focused === FOCUSED_TIME[0]) {
                        setStartTime(newHour * 60 + (currentMin % 60));
                      } else {
                        setEndTime(newHour * 60 + (currentMin % 60));
                      }
                    }}
                  >
                    {h.toString().length > 1 ? "" : "0"}
                    {h}
                  </HFlex>
                );
              })}
            </VFlex>
            <Divider width={1} bc-t2 m-8-rl />
            <VFlex f-1 a-c ovf-a>
              {Array(12)
                .fill(null)
                .map((_, i) => {
                  const currentMin =
                    (focused === FOCUSED_TIME[0] ? startTime! : endTime!) % 60;

                  return (
                    <HFlex
                      key={i * 5}
                      classes={
                        currentMin === i * 5
                          ? ["time-option-item", "time-option-item--selected"]
                          : ["time-option-item"]
                      }
                      onClick={() => {
                        if (focused === FOCUSED_TIME[0]) {
                          setStartTime(startTime - currentMin + i * 5);
                        } else {
                          setEndTime(endTime - currentMin + i * 5);
                        }
                      }}
                    >
                      {(i * 5).toString().length > 1 ? "" : "0"}
                      {i * 5}
                    </HFlex>
                  );
                })}
            </VFlex>
            <Divider width={1} bc-t2 m-8-rl />
            <VFlex f-1 a-c ovf-a>
              {["오전", "오후"].map((h, i) => {
                const isAM =
                  Math.floor(
                    (focused === FOCUSED_TIME[0] ? startTime! : endTime!) / 60
                  ) < 12;

                return (
                  <HFlex
                    key={h}
                    classes={
                      (isAM && i === 0) || (!isAM && i === 1)
                        ? ["time-option-item", "time-option-item--selected"]
                        : ["time-option-item"]
                    }
                    onClick={() => {
                      const currentHour = Math.floor(
                        (focused === FOCUSED_TIME[0] ? startTime! : endTime!) /
                          60
                      );

                      if (i === 0 && currentHour >= 12) {
                        if (focused === FOCUSED_TIME[0]) {
                          setStartTime(startTime - 720);
                        } else {
                          setEndTime(endTime - 720);
                        }
                      } else if (i === 1 && currentHour < 12) {
                        if (focused === FOCUSED_TIME[0]) {
                          setStartTime(startTime + 720);
                        } else {
                          setEndTime(endTime + 720);
                        }
                      }
                    }}
                  >
                    {h}
                  </HFlex>
                );
              })}
            </VFlex>
          </HFlex>
        </VFlex>
      )}
    />
  );
};

export default TimePicker;
