import { ReactElement, useState } from "react";
import HFlex from "../layouts/HFlex";
import Image from "../layouts/Image";
import Text from "../layouts/Text";
import VFlex from "../layouts/VFlex";

function Checkbox({
  size,
  leftLabel,
  rightLabel,
  initialValue,
  onChange,
}: {
  size?: number;
  leftLabel?: string;
  rightLabel?: string;
  initialValue?: boolean;
  onChange?: (value: boolean) => void;
}): ReactElement {
  const [value, setValue] = useState<boolean>(initialValue ?? false);
  return (
    <HFlex
      a-st
      clickable
      onClick={() => {
        setValue(!value);
        onChange?.(!value);
      }}
    >
      {leftLabel && (
        <Text m-16-r t-16-600 f-1>
          {leftLabel}
        </Text>
      )}
      <VFlex width={24} height={24} c-c clickable>
        {value ? (
          <Image size={size ? size : 24} src={`/icons/checkbox_checked.png`} />
        ) : (
          <Image
            size={size ? size : 24}
            src={`/icons/checkbox_unchecked.png`}
          />
        )}
      </VFlex>
      {rightLabel && (
        <Text f-1 m-16-l t-16-500>
          {rightLabel}
        </Text>
      )}
    </HFlex>
  );
}

export default Checkbox;
