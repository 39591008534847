import { ReactElement, useEffect, useState } from "react";
import HFlex from "../layouts/HFlex";
import Text from "../layouts/Text";
import VFlex from "../layouts/VFlex";
import Divider from "../layouts/Divider";

function GroupRadioButton({
  value,
  caption,
  required,
  options,
  onChange,
}: {
  value?: { label: string; value: any } | undefined;
  caption?: string;
  required?: boolean;
  options: { label: string; value: any }[];
  onChange?: (value: { label: string; value: any } | undefined) => void;
}): ReactElement {
  const [values, setValues] = useState<
    { label: string; value: any } | undefined
  >(value);
  useEffect(() => {
    setValues(value);
  }, [value, setValues]);
  return (
    <VFlex g-8>
      {caption && (
        <HFlex g-4>
          <Text t-14-s8>{caption}</Text>
          {required && <Text t-14-700-rd1>*</Text>}
        </HFlex>
      )}
      <HFlex a-st height={38}>
        {options.map((option, i) => (
          <HFlex key={i} f-1>
            <VFlex
              style={
                i === 0
                  ? {
                      borderTopLeftRadius: 12,
                      borderBottomLeftRadius: 12,
                      borderRight: "none",
                      background:
                        values?.value === option.value
                          ? "rgba(39, 189, 99, 4%)"
                          : "",
                    }
                  : i === options.length - 1
                  ? {
                      borderTopRightRadius: 12,
                      borderBottomRightRadius: 12,
                      borderLeft: "none",
                      background:
                        values?.value === option.value
                          ? "rgba(39, 189, 99, 4%)"
                          : "",
                    }
                  : {
                      borderLeft: "none",
                      borderRight: "none",
                      background:
                        values?.value === option.value
                          ? "rgba(39, 189, 99, 4%)"
                          : "",
                    }
              }
              f-1
              clickable
              c-c
              bc-w
              c-gr5={values?.value === option.value}
              bd-gr5={values?.value === option.value}
              bd-t7={values?.value !== option.value}
              key={i}
              onClick={() => {
                setValues(option.value === value?.value ? undefined : option);
                onChange?.(option.value === value?.value ? undefined : option);
              }}
            >
              <Text
                t-14-500-gr5={values?.value === option.value}
                t-14-500-bl4={values?.value !== option.value}
              >
                {option.label}
              </Text>
            </VFlex>
            {i !== options.length - 1 && (
              <Divider
                width={1}
                style={{
                  background:
                    values?.value === option.value ||
                    (values?.value === options[i + 1]?.value &&
                      i < options.length - 1)
                      ? "#27BD63"
                      : "#D0D7D3",
                }}
              />
            )}
          </HFlex>
        ))}
      </HFlex>
    </VFlex>
  );
}

export default GroupRadioButton;
