import React, { useEffect, useMemo, useState } from "react";

import api from "../../../common/api";
import { numberWithCommas } from "../../../common/utils";
import { shortOrdinals } from "../../../common/constants";
import { Statistics } from "../../../providers/types";
import { usePartner } from "../../../providers/partner";

import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Text from "../../../layouts/Text";

import _ from "lodash";
import {
  addDays,
  addMonths,
  format,
  getMonth,
  getWeekOfMonth,
  getYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { ko } from "date-fns/locale";

export const calcStartDate = (calendarType: string, date: Date) => {
  const baseDate = new Date(date).getTime();

  return calendarType === "주별"
    ? Math.floor(startOfWeek(baseDate, { weekStartsOn: 0 }).getTime() / 1000)
    : Math.floor(baseDate / 1000);
};

export const calcEndDate = (calendarType: string, startDate: number) => {
  const start = startOfDay(new Date(startDate * 1000));

  switch (calendarType) {
    case "일별":
      return Math.floor(addDays(start, 1).getTime() / 1000);
    case "주별":
      return Math.floor(addDays(start, 7).getTime() / 1000);
    default:
      return Math.floor(
        addMonths(startOfMonth(new Date(startDate * 1000)), 1).getTime() / 1000
      );
  }
};

function SalesStatistics({
  date,
  calendarType,
  month,
  nth,
}: {
  date: Date | undefined;
  calendarType: string;
  month: number;
  nth: number;
}) {
  const { partner } = usePartner();
  const [statistics, setStatistics] = useState<Statistics>();
  const [paymentMethod, setPaymentMethod] = useState<
    Array<Statistics["paymentMethod"][0]>
  >([]);
  const [revenue, setRevenue] = useState<Array<Statistics["revenue"][0]>>([]);
  const [chargersRevenue, setChargersRevenue] = useState<
    Array<Statistics["chargersRevenue"][0]>
  >([]);
  const [petsRevenue, setPetRevenue] = useState<
    Array<Statistics["petsRevenue"][0]>
  >([]);
  const today = startOfDay(new Date());

  useEffect(() => {
    const startDate = calcStartDate(calendarType, date as Date);
    const endDate = calcEndDate(calendarType, startDate);
    const resultEndDate =
      new Date(endDate * 1000).setSeconds(
        new Date(endDate * 1000).getSeconds() - 1
      ) / 1000;

    const fetchData = async () => {
      const fetchedStatistics = await api.get<Statistics>(
        `/rest/group/${partner.id}/revenue-statistics?startDate=${startDate}&endDate=${endDate}`
      );

      if (fetchedStatistics) {
        setStatistics(fetchedStatistics);
        const { paymentMethod, revenue, chargersRevenue, petsRevenue } =
          fetchedStatistics;

        setPaymentMethod(paymentMethod);
        setRevenue(revenue);
        setChargersRevenue(chargersRevenue);
        setPetRevenue(petsRevenue);
      }
    };

    fetchData();
  }, [calendarType, date]);

  const calcMaxRevenue = (revenueArr: { amount: number }[]): number =>
    Math.max(
      ..._.compact(
        revenueArr.map((v) => {
          try {
            return v.amount;
          } catch {
            return undefined;
          }
        })
      ),
      0
    );

  const maxRevenue = useMemo(() => calcMaxRevenue(revenue), [revenue]);
  const maxChargerRevenue = useMemo(
    () => calcMaxRevenue(chargersRevenue),
    [chargersRevenue]
  );

  return (
    <VFlex
      p-16-t
      width={"100%"}
      ovf-s
      style={{
        padding: "32px 52px",
      }}
    >
      <VFlex height={"100%"} style={{ paddingTop: 72, gap: 72 }}>
        <HFlex j-b style={{ gap: 72 }}>
          <VFlex f-1 j-b>
            <Text t-32-700-s8>
              {statistics?.totalAmount
                ? numberWithCommas(statistics?.totalAmount)
                : 0}
              원
            </Text>

            <HFlex m-32-t m-28-b bdr-8 g-2>
              {paymentMethod.map((method, i) => {
                return Number(method.value) <= 0 ? null : (
                  <VFlex
                    key={i}
                    width={`${(
                      (Number(method.value) /
                        (statistics?.totalAmount
                          ? statistics?.totalAmount
                          : 0)) *
                      100
                    ).toFixed(1)}%`}
                    height={24}
                    style={{
                      background: `#${method.color.substr(2)}`,
                    }}
                  ></VFlex>
                );
              })}
            </HFlex>

            <VFlex g-24>
              {paymentMethod.map((method, i) => {
                return (
                  <HFlex j-b a-c key={i}>
                    <HFlex j-b a-c style={{ minWidth: "50%" }}>
                      <HFlex g-16>
                        <VFlex
                          style={{
                            width: 24,
                            height: 24,
                            borderRadius: "100%",
                            background: `#${method.color.substr(2)}`,
                          }}
                        ></VFlex>
                        <Text t-16-600-s7>{method.type}</Text>
                      </HFlex>
                      {method.value === "0" ? (
                        <Text t-16-s4>0.0%</Text>
                      ) : (
                        <Text t-16-s4>
                          {(
                            (Number(method.value) /
                              (statistics?.totalAmount
                                ? statistics?.totalAmount
                                : 0)) *
                            100
                          ).toFixed(1)}
                          %
                        </Text>
                      )}
                    </HFlex>
                    <Text t-16-600-s6>
                      {numberWithCommas(Number(method.value))}원
                    </Text>
                  </HFlex>
                );
              })}
            </VFlex>
          </VFlex>

          <VFlex f-1 j-b g-28>
            <VFlex g-8>
              <Text t-18-600-s8>
                {format(
                  new Date(date!),
                  `${
                    calendarType === "일별"
                      ? "M월 d일"
                      : calendarType === "주별"
                      ? `M월 ${shortOrdinals[nth - 1]}주`
                      : "M월"
                  }`,
                  {
                    locale: ko,
                  }
                )}
                의 매출
                {statistics?.totalAmount ? (
                  <>
                    {`은 ${numberWithCommas(statistics?.totalAmount)}원이에요`}
                  </>
                ) : (
                  <>이 없습니다.</>
                )}
              </Text>
            </VFlex>
            <HFlex g-24 j-c>
              {revenue.map((revenue, i) => {
                return (
                  <VFlex a-c key={i} f-1>
                    <Text
                      t-14-s4={Number(date) !== revenue.date}
                      t-14-600-gr5={(() => {
                        const baseDate = new Date(today);
                        const revenueDate = new Date(revenue.date * 1000);

                        const isSameDate =
                          baseDate.getTime() / 1000 === revenue.date;
                        const isSameYearMonth =
                          getYear(baseDate) === getYear(revenueDate) &&
                          getMonth(baseDate) === getMonth(revenueDate);
                        const isSameWeek =
                          getMonth(baseDate) === getMonth(revenueDate) &&
                          getWeekOfMonth(baseDate) ===
                            getWeekOfMonth(revenueDate);

                        return (
                          isSameDate ||
                          (calendarType === "월별" && isSameYearMonth) ||
                          (calendarType === "주별" && isSameWeek)
                        );
                      })()}
                      p-8-b
                    >
                      {parseInt(revenue.amount.toString().slice(0, -4)) > 0
                        ? revenue.amount.toString().slice(0, -4)
                        : 0}
                      만
                    </Text>
                    <VFlex rel ovf-h height={140} width={"100%"} bc-t1 bdr-8>
                      <VFlex
                        width={"100%"}
                        height={
                          maxRevenue === 0
                            ? "8px"
                            : (maxRevenue !== 0 && maxRevenue) ===
                              revenue.amount
                            ? "100%"
                            : `${(
                                (revenue.amount / maxRevenue) * 100 +
                                8
                              ).toFixed(1)}%`
                        }
                        bdr-8
                        abs
                        style={{
                          bottom: 0,
                          minHeight: 8,
                          background: (() => {
                            const revenueDate = new Date(revenue.date * 1000);
                            const baseDate = new Date(today);
                            const isSameDate =
                              baseDate.getTime() / 1000 === revenue.date;
                            const isSameMonth =
                              getMonth(baseDate) === getMonth(revenueDate);
                            const isSameWeek =
                              getWeekOfMonth(baseDate) ===
                              getWeekOfMonth(revenueDate);

                            return isSameDate ||
                              (calendarType === "월별" && isSameMonth) ||
                              (calendarType === "주별" &&
                                isSameMonth &&
                                isSameWeek)
                              ? "#27BD63"
                              : (date && date.getTime() / 1000) ===
                                  revenue.date ||
                                (calendarType === "주별" &&
                                  isSameMonth &&
                                  getWeekOfMonth(date!) ===
                                    getWeekOfMonth(revenueDate))
                              ? "#848A87"
                              : "#E4E8E6";
                          })(),
                        }}
                      ></VFlex>
                    </VFlex>
                    {new Date(today).getTime() / 1000 === revenue.date ? (
                      <Text t-14-s8 p-16-t>
                        오늘
                      </Text>
                    ) : calendarType === "일별" ? (
                      <Text t-14-s8 p-16-t>
                        {format(new Date(revenue.date * 1000), "d일", {
                          locale: ko,
                        })}
                      </Text>
                    ) : calendarType === "주별" ? (
                      <Text t-14-s8 p-16-t>
                        {format(
                          new Date(revenue.date * 1000),
                          `M월 ${
                            shortOrdinals[
                              getWeekOfMonth(new Date(revenue.date * 1000)) - 1
                            ]
                          } 주`,
                          {
                            locale: ko,
                          }
                        )}
                      </Text>
                    ) : (
                      <Text t-14-s8 p-16-t>
                        {format(new Date(revenue.date * 1000), "M월", {
                          locale: ko,
                        })}
                      </Text>
                    )}
                  </VFlex>
                );
              })}
            </HFlex>
          </VFlex>
        </HFlex>

        <HFlex j-b style={{ gap: 72, paddingBottom: 100 }}>
          <VFlex g-36 f-1 ovf-h>
            <VFlex g-8>
              <Text t-18-600-s8>담당자별 매출이에요</Text>
              <Text t-16-s6>매출이 가장 큰 순서대로 보여져요</Text>
            </VFlex>
            <HFlex g-32 ovf-s-d j-c={chargersRevenue.length <= 4}>
              {chargersRevenue.map((charger, i) => {
                return (
                  <VFlex key={i} a-c height={224}>
                    <VFlex
                      p-4-tb
                      p-8-rl
                      bdr-6
                      t-14-gr5={
                        (maxChargerRevenue !== 0 && maxChargerRevenue) ===
                        charger.amount
                      }
                      t-14-s4={
                        maxChargerRevenue !== charger.amount ||
                        maxChargerRevenue === 0
                      }
                      style={{
                        background:
                          (maxChargerRevenue !== 0 && maxChargerRevenue) ===
                          charger.amount
                            ? "rgba(39, 189, 99, 0.10)"
                            : "none",
                      }}
                    >
                      {parseInt(charger.amount.toString().slice(0, -4)) > 0
                        ? charger.amount.toString().slice(0, -4)
                        : 0}
                      만
                    </VFlex>
                    <VFlex
                      m-8-t
                      m-16-b
                      bc-t1
                      bdr-8
                      height={118}
                      style={{
                        justifyContent: "flex-end",
                      }}
                    >
                      <VFlex
                        bdr-8
                        height={
                          maxChargerRevenue === 0
                            ? "8px"
                            : (maxChargerRevenue !== 0 && maxChargerRevenue) ===
                              charger.amount
                            ? "100%"
                            : `${(
                                (charger.amount / maxChargerRevenue) *
                                100
                              ).toFixed(1)}%`
                        }
                        style={{
                          width: 64,
                          minHeight: 8,
                          background:
                            (maxChargerRevenue !== 0 && maxChargerRevenue) ===
                            charger.amount
                              ? "#27BD63"
                              : "#E4E8E6",
                        }}
                      ></VFlex>
                    </VFlex>

                    <VFlex a-c g-4>
                      <Text t-16-s8>{charger.count}건</Text>
                      <Text t-16-s6>{charger.name}</Text>
                    </VFlex>
                  </VFlex>
                );
              })}
            </HFlex>
          </VFlex>

          <VFlex g-36 f-1>
            <VFlex g-8>
              <Text t-18-600-s8>반려동물별 매출이에요</Text>
              <Text t-16-s6>매출이 가장 큰 순서대로 보여져요</Text>
            </VFlex>
            <VFlex g-28 ovf-s style={{ maxHeight: 224 }}>
              {petsRevenue ? (
                petsRevenue.map((pet, i) => {
                  return (
                    <HFlex j-b a-c key={pet.id}>
                      <HFlex
                        a-c
                        j-b
                        style={{
                          minWidth: "50%",
                        }}
                      >
                        <HFlex g-16 a-c>
                          <VFlex
                            a-c
                            j-c
                            t-14-700-gr5={i <= 2}
                            t-14-700-s4={i > 2}
                            style={{
                              width: 32,
                              height: 32,
                              borderRadius: "100%",
                              background: `${i <= 2 ? "#ECF8F3" : "#F1F3F2"}`,
                            }}
                          >
                            {i + 1}
                          </VFlex>
                          <Text t-16-600-s7>{pet.name}</Text>
                        </HFlex>
                        {/* TODO : 보호자 이름, 건수 */}
                        {/* <Text t-16-s4>건</Text> */}
                      </HFlex>
                      <Text t-16-600-s6>{numberWithCommas(pet.amount)}원</Text>
                    </HFlex>
                  );
                })
              ) : (
                <HFlex j-b a-c>
                  <HFlex
                    a-c
                    j-b
                    style={{
                      minWidth: "50%",
                    }}
                  >
                    <HFlex g-16 a-c>
                      <VFlex
                        a-c
                        j-c
                        t-14-700-gr5
                        style={{
                          width: 32,
                          height: 32,
                          borderRadius: "100%",
                          background: "#ECF8F3",
                        }}
                      >
                        1
                      </VFlex>
                      <Text t-16-600-s7>-</Text>
                    </HFlex>
                    {/* TODO : 보호자 이름, 건수 */}
                    {/* <Text t-16-s4>건</Text> */}
                  </HFlex>
                  <Text t-16-600-s6>0원</Text>
                </HFlex>
              )}
            </VFlex>
          </VFlex>
        </HFlex>
      </VFlex>
    </VFlex>
  );
}

export default SalesStatistics;
