import { ReactElement, useEffect, useMemo, useState } from "react";
import Select, { GroupBase, OptionsOrGroups } from "react-select";
import _ from "lodash";
import Checkbox from "./Checkbox";
import VFlex from "../layouts/VFlex";
import HFlex from "../layouts/HFlex";
import Text from "../layouts/Text";
import Image from "../layouts/Image";

export type DropdownItem = {
  value: string;
  label: string;
  obj?: any;
};

function StaffDropdown({
  value,
  isDisabled,
  caption,
  required,
  placeholder,
  onChange,
  onClick,
  options,
}: {
  value?: any;
  isDisabled?: boolean;
  caption?: string;
  required?: boolean;
  placeholder?: string;
  onChange?: (value: DropdownItem[]) => void;
  onClick?: () => void;
  options: OptionsOrGroups<DropdownItem, GroupBase<DropdownItem>>;
}): ReactElement {
  const flattenedOptions = useMemo(
    () =>
      _.flatten(
        options.map((option) =>
          (option as GroupBase<DropdownItem>).options
            ? (option as GroupBase<DropdownItem>).options
            : [option as DropdownItem]
        )
      ),
    [options]
  );

  const [innerValue, setInnerValue] = useState<DropdownItem[] | undefined>(
    value
      ? flattenedOptions.filter((option) => value.includes(option.value))
      : []
  );

  // when value/options is changed from outside, update innerValue
  useEffect(() => {
    setInnerValue(
      value
        ? flattenedOptions.filter((option) => value.includes(option.value))
        : []
    );
  }, [value, flattenedOptions, setInnerValue]);

  return (
    <VFlex g-8 onClick={(e) => onClick?.()}>
      {caption && (
        <HFlex g-8>
          <Text t-14-s8>{caption}</Text>
          {required && <Text t-14-700-rd1>*</Text>}
        </HFlex>
      )}
      <Select
        key={JSON.stringify(innerValue)}
        isDisabled={isDisabled}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
        isMulti={true}
        isClearable={true}
        placeholder={placeholder ?? ""}
        styles={{
          placeholder: () => ({
            position: "absolute",
            color: "#BFC6C2",
            fontSize: "16px",
            lineHeight: "24px",
          }),
          control: () => ({
            padding: "12px 12px 12px 10px",
            display: "flex",
            gap: 8,
            minHeight: 56,
            backgroundColor: "#fff",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "#EBEDEC",
            borderRadius: "16px",
            alignItems: "center",
            WebkitUserSelect: "none",
            msUserSelect: "none",
            userSelect: "none",
          }),
          indicatorSeparator: () => ({}),
          valueContainer: () => ({
            flex: 1,
            display: "flex",
            flexWrap: "wrap",
            overflow: "hidden",
          }),
          menu: () => ({
            top: 8,
            left: "auto",
            right: "auto",
            position: "absolute",
            backgroundColor: "#fff",
            width: "100%",
            maxHeight: "168px",
            overflow: "scroll",
            boxShadow:
              "0px 1px 2px 0px rgba(0, 0, 0, 0.15), 0px 4px 8px 1px rgba(0, 0, 0, 0.20)",
            borderRadius: 12,
          }),
          menuPortal: (provided) => ({ ...provided, zIndex: 100000000000 }),
          menuList: (provided) => ({
            ...provided,
            padding: 8,
            maxHeight: "168px",
          }),
          multiValue: () => {
            return {
              color: "#383B3A",
              fontWeight: 500,
              fontSize: "14px",
              lineHeight: "22px",
              background: "none",
              margin: 0,
            };
          },
          clearIndicator: () => {
            return { display: "none" };
          },
        }}
        formatOptionLabel={(data) => {
          const isLastItem =
            innerValue &&
            innerValue.length > 0 &&
            innerValue[innerValue.length - 1]?.label === data.label;

          return (
            <HFlex a-c>
              <Text t-16-s8>
                {innerValue && innerValue.length > 1 && !isLastItem
                  ? `${data.label}, `
                  : data.label}
              </Text>
            </HFlex>
          );
        }}
        components={{
          Option: (props: any) => {
            const { innerProps, innerRef } = props;
            return (
              <article
                ref={innerRef}
                {...innerProps}
                style={{
                  height: "38px",
                  color: "#383B3A",
                  display: "flex",
                  backgroundColor: props.isFocused ? "#F8F9F8" : "#fff",
                  fontSize: "14px",
                  lineHeight: "22px",
                  WebkitUserSelect: "none",
                  msUserSelect: "none",
                  userSelect: "none",
                  borderRadius: 8,
                }}
              >
                <HFlex g-4 a-c p-8-l width={"100%"}>
                  <Text clickable t-14-s8 f-1>
                    {props.data.label}
                  </Text>
                  <Checkbox initialValue={props.isSelected} size={20} />
                </HFlex>
              </article>
            );
          },
          DropdownIndicator: () => (
            <VFlex c-c>
              <Image
                size={22}
                src={`/icons/navigation/arrow_drop_down_s3.svg`}
                clickable
              />
            </VFlex>
          ),
          MultiValueRemove: ({ innerProps: { className, ...rest } }) => {
            return <div {...rest}></div>;
          },
        }}
        value={innerValue ?? []}
        onChange={(item) => {
          const items = [...item];
          setInnerValue(items);
          onChange?.(items);
        }}
        options={options}
        menuPortalTarget={document.querySelector("body")}
        isSearchable={false}
      />
    </VFlex>
  );
}

export default StaffDropdown;
