import { useCallback, useState } from "react";

import api from "../../../common/api";
import { staffColors } from "../../../common/constants";
import { formatPhoneNumber } from "../../../common/format";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";
import { Staff } from "../../../providers/types";
import { usePartner } from "../../../providers/partner";
import { useStaffs } from "../../../providers/staffs";
import { pushPopup } from "../../../providers/popups";

import ConfirmPopup from "../../../popup/ConfirmPopup";
import { PopupAlign, PopupKind } from "../../../popup/Popup";
import FileUpload from "../../../components/FileUpload";
import Dropdown from "../../../components/Dropdown";

import TextInput from "../../../layouts/TextInput";
import Text from "../../../layouts/Text";
import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Image from "../../../layouts/Image";
import Button from "../../../layouts/Button";

import _ from "lodash";
import { toast } from "react-hot-toast";

function AddStaff({
  popPopup,
  staff,
}: {
  popPopup: () => void;
  staff?: Staff;
}) {
  const {
    trackSaveStaff,
    trackSaveNewStaff,
    trackClickDeleteStaff,
    trackDeleteStaff,
  } = useAmplitudeTrack();
  const { partner } = usePartner();
  const { staffs } = useStaffs();
  const { fetchStaffs } = useStaffs();
  const [file, setFile] = useState<File | undefined>();
  const [name, setName] = useState<string | undefined>(staff?.name);
  const [jobTitle, setJobTitle] = useState<string | undefined>(
    staff?.jobTitle ?? "선생님"
  );
  const [cellNumber, setCellNumber] = useState<string | undefined>(
    staff?.workingCellNumber
  );
  const [selectedColor, setSelectedColor] = useState<number | undefined>(
    parseInt(staff?.color ?? "0")
  );
  const isComplete = useCallback(() => {
    return (
      !!name &&
      !!jobTitle &&
      /[0-9]{3}-[0-9]{4}-[0-9]{4}/.test(cellNumber || "") &&
      !_.isNil(selectedColor)
    );
  }, [name, jobTitle, cellNumber, selectedColor]);

  const isChanged = useCallback(() => {
    if (staff) {
      return (
        staff.name !== name ||
        staff.jobTitle !== jobTitle ||
        staff.workingCellNumber !== cellNumber ||
        staff.color !== selectedColor?.toString() ||
        !!file
      );
    } else {
      return isComplete();
    }
  }, [cellNumber, file, jobTitle, name, selectedColor, staff, isComplete]);

  const isStaffChanged = useCallback(() => {
    if (staff) {
      const isPhoneNumberChanged = staff.workingCellNumber !== cellNumber;
      const isOtherFieldsChanged =
        staff.name !== name ||
        staff.jobTitle !== jobTitle ||
        staff.color !== selectedColor?.toString() ||
        !!file;

      if (isPhoneNumberChanged) {
        const isPhoneNumberRegistered = staffs.some(
          (s) => s.workingCellNumber === cellNumber && s.id !== staff.id
        );
        if (isPhoneNumberRegistered) {
          return false;
        }
      }

      return isPhoneNumberChanged || isOtherFieldsChanged;
    } else {
      return isComplete();
    }
  }, [
    cellNumber,
    file,
    jobTitle,
    name,
    selectedColor,
    staff,
    isComplete,
    staffs,
  ]);

  const handleConfirm = useCallback(async () => {
    const isChanged = isStaffChanged();

    if (isChanged) {
      const formData = new FormData();
      if (file) {
        formData.append("image", file);
      }

      if (staff) {
        await api.put(
          `/rest/group/${partner.id}/staff?id=${staff.id}&workingCellNumber=${cellNumber}&jobTitle=${jobTitle}&name=${name}&color=${selectedColor}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast.success("수정되었습니다.");
        trackSaveStaff(partner.title);
      } else {
        if (
          staffs.filter((s) => s.workingCellNumber === cellNumber).length > 0
        ) {
          return toast.error(`이미 등록된 번호입니다.`);
        }
        await api.post(
          `/rest/group/${partner.id}/staff?workingCellNumber=${cellNumber}&jobTitle=${jobTitle}&name=${name}&color=${selectedColor}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast.success("직원이 등록되었습니다.");
        trackSaveNewStaff(partner.title);
      }
      fetchStaffs();
      popPopup();
    } else {
      toast.error(`이미 등록된 번호입니다.`);
    }
  }, [
    partner,
    staff,
    cellNumber,
    jobTitle,
    name,
    selectedColor,
    fetchStaffs,
    file,
    popPopup,
  ]);

  const jobTitles = [
    { value: 7, label: "원장님" },
    { value: 8, label: "실장님" },
    { value: 9, label: "선생님" },
  ];

  return (
    <VFlex f-1 a-st p-24-t m-8-rl>
      <HFlex p-24-rl>
        <VFlex f-1 />
        <Image
          size={24}
          src={`/icons/popup_close.png`}
          clickable
          onClick={() =>
            isChanged()
              ? pushPopup({
                  kind: PopupKind.Popup,
                  align: PopupAlign.TopCenter,
                  element: ConfirmPopup,
                  props: {
                    title: "변경사항을 저장하지 않고 닫을까요?",
                    onConfirm: () => {
                      popPopup();
                    },
                  },
                })
              : popPopup()
          }
        />
      </HFlex>
      <Text m-24-b p-24-rl t-20-700-s9>
        {staff ? "직원 상세" : "새로운 직원 등록"}
      </Text>
      <VFlex f-1 ovf-a g-16 p-24-rl p-24-t>
        <HFlex a-e g-16>
          <label htmlFor="staff-profile-upload" className="rel">
            <Image
              size={80}
              bdr-24
              src={
                file
                  ? URL.createObjectURL(file)
                  : staff?.attachment?.path ?? `/profile_example.png`
              }
            />
            <VFlex
              width={32}
              height={32}
              bdw-2-w
              c-c
              abs
              bdr-32
              bc-t6
              style={{ top: "auto", left: "auto", right: 0, bottom: 5 }}
            >
              <Image width={20} height={20} src={`/icons/icon_camera.png`} />
            </VFlex>
            <FileUpload
              id="staff-profile-upload"
              onLoad={async (file: File) => {
                setFile(file);
              }}
            />
          </label>
        </HFlex>
        <TextInput
          required={true}
          caption={"이름"}
          placeholder={"이름"}
          value={name}
          onChangeValue={(value) => setName(value)}
        />
        <Dropdown
          value={
            (jobTitles.find((j) => j.label === jobTitle) ?? jobTitles[2]).value
          }
          options={jobTitles}
          caption={"직책"}
          placeholder={"직책"}
          required={true}
          onChange={(value) => {
            setJobTitle(value?.label);
          }}
        />
        <TextInput
          required={true}
          caption={"휴대폰 번호"}
          placeholder={"휴대폰 번호"}
          value={cellNumber}
          inputMode="numeric"
          onKeyDown={(e) => {
            if (!(e.key === "Backspace" || /[0-9]/.test(e.key))) {
              e.preventDefault();
            }
          }}
          onChangeValue={(value) => {
            const result = value?.replace(/[^0-9]/g, "") || "";
            setCellNumber(formatPhoneNumber(cellNumber, result));
          }}
        />
        <HFlex g-8>
          <Text t-14-500-s4>지정 색깔</Text>
          <Text t-14-500-rd1>*</Text>
        </HFlex>
        <HFlex a-c>
          {_.values(staffColors).map((color, i) => (
            <VFlex
              c-c
              width={40}
              height={40}
              m-24-r
              bdr-32
              key={i}
              style={{
                backgroundColor:
                  selectedColor === i ? color.mainColor : "transparent",
              }}
              clickable
              onClick={() => setSelectedColor(i)}
            >
              <VFlex
                width={36}
                height={36}
                bdr-32
                c-c
                bdw-2-w
                style={{
                  backgroundColor: color.mainColor,
                }}
              >
                {selectedColor === i && (
                  <Image size={24} src={`/icons/checkbox_check.png`} />
                )}
              </VFlex>
            </VFlex>
          ))}
        </HFlex>
      </VFlex>
      <HFlex g-8 m-24-t p-32-rl height={86} bd-t-t3 a-c>
        <VFlex f-1 />
        {!!staff && (
          <Button
            type="warning"
            onClick={() => {
              trackClickDeleteStaff(partner.title);

              pushPopup({
                kind: PopupKind.Popup,
                align: PopupAlign.TopCenter,
                element: ConfirmPopup,
                props: {
                  title: "정말 삭제할까요?",
                  onConfirm: async () => {
                    await api.delete(
                      `/rest/group/${partner.id}/staff?id=${staff.id}`
                    );
                    fetchStaffs();
                    toast.success("직원이 삭제되었습니다.");
                    popPopup();
                    trackDeleteStaff(partner.title);
                  },
                },
              });
            }}
            caption="직원 삭제"
          />
        )}
        <Button
          type="cancel"
          caption="취소"
          onClick={() =>
            isChanged()
              ? pushPopup({
                  kind: PopupKind.Popup,
                  align: PopupAlign.TopCenter,
                  element: ConfirmPopup,
                  props: {
                    title: "변경사항을 저장하지 않고 닫을까요?",
                    onConfirm: () => {
                      popPopup();
                    },
                  },
                })
              : popPopup()
          }
          enabled={isChanged()}
        />
        <Button
          type="confirm"
          enabled={isComplete() && isChanged()}
          caption="저장"
          onClick={() => handleConfirm()}
        />
      </HFlex>
    </VFlex>
  );
}

export default AddStaff;
