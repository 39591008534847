import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import api from "../../../common/api";
import { dayOfWeek, days } from "../../../common/constants";
import LoadingView from "../../../layouts/LoadingView";
import Checkbox from "../../../components/Checkbox";
import ConfirmPopup from "../../../popup/ConfirmPopup";
import { toast } from "react-hot-toast";
import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Image from "../../../layouts/Image";
import Text from "../../../layouts/Text";
import Button from "../../../layouts/Button";
import { pushPopup } from "../../../providers/popups";
import { PopupAlign, PopupKind } from "../../../popup/Popup";
import { usePartner } from "../../../providers/partner";
import { useBusinessHours } from "../../../providers/businessHours";
import _, { isNil } from "lodash";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";
import TimePicker from "../../../components/TimePicker";
import { minutesToTimeString } from "../../../common/format";

function OpenHours({
  popPopup,
  setIsChanged,
}: {
  popPopup: () => void;
  setIsChanged: Dispatch<SetStateAction<boolean>>;
}) {
  const { trackSaveBizHours } = useAmplitudeTrack();
  const { partner } = usePartner();
  const { businessHours, fetchBusinessHours } = useBusinessHours();
  const [checkedDays, setCheckedDays] = useState<number>(127);
  const [startTimes, setStartTimes] = useState<Record<number, number>>({});
  const [endTimes, setEndTimes] = useState<Record<number, number>>({});
  const [loading, setLoading] = useState(false);
  const translateBusinessHours = useCallback(() => {
    let checkedDays = 0;
    let startTimes: Record<number, number> = {},
      endTimes: Record<number, number> = {};
    for (const businessHour of businessHours) {
      const dayIndex = dayOfWeek.indexOf(businessHour.dayOfWeek);
      checkedDays += 1 << dayIndex;
      const startTime = businessHour.start.split(":");
      const endTime = businessHour.end.split(":");
      startTimes[dayIndex] =
        parseInt(startTime[0]) * 60 + parseInt(startTime[1]);
      endTimes[dayIndex] = parseInt(endTime[0]) * 60 + parseInt(endTime[1]);
    }
    return { startTimes, endTimes, checkedDays };
  }, [businessHours]);
  useEffect(() => {
    const { startTimes, endTimes, checkedDays } = translateBusinessHours();
    setStartTimes(startTimes);
    setEndTimes(endTimes);
    setCheckedDays(checkedDays);
  }, [translateBusinessHours]);
  const isChanged = useCallback(() => {
    const {
      startTimes: _startTimes,
      endTimes: _endTimes,
      checkedDays: _checkedDays,
    } = translateBusinessHours();
    for (let i = 0; i < 7; i++) {
      if (
        (checkedDays & (1 << i)) > 0 &&
        (_.isNil(startTimes[i]) ||
          _.isNaN(startTimes[i]) ||
          _.isNil(endTimes[i]) ||
          _.isNaN(endTimes[i]))
      ) {
        return false;
      }
    }
    return (
      JSON.stringify(startTimes) !== JSON.stringify(_startTimes) ||
      JSON.stringify(endTimes) !== JSON.stringify(_endTimes) ||
      JSON.stringify(checkedDays) !== JSON.stringify(_checkedDays)
    );
  }, [startTimes, endTimes, checkedDays, translateBusinessHours]);
  useEffect(() => {
    setIsChanged(isChanged());
  }, [setIsChanged, isChanged]);
  useEffect(() => {}, [JSON.stringify(startTimes), JSON.stringify(endTimes)]);

  const onStartTimesChanged = useCallback(
    (i: number, st: number) => {
      setStartTimes((startTimes) => {
        startTimes[i] = st;

        setEndTimes((endTimes) => {
          if (st && endTimes[i] < startTimes[i]) {
            endTimes[i] = st + 30;
          }
          return { ...endTimes };
        });
        return { ...startTimes };
      });
    },
    [setStartTimes, setEndTimes]
  );
  const onEndTimesChanged = useCallback(
    (i: number, et: number) => {
      setEndTimes((endTimes) => {
        if (et) {
          endTimes[i] = et;
        } else {
          delete endTimes[i];
        }
        return { ...endTimes };
      });
    },
    [setEndTimes]
  );
  return (
    <LoadingView f-1 a-st p-24-t loading={loading}>
      <VFlex key={checkedDays} g-16 m-32-rl>
        {days.map((day, i) => (
          <HFlex key={i} a-c g-16>
            <Checkbox
              rightLabel={`${day}요일`}
              initialValue={((checkedDays >> i) & 1) === 1}
              onChange={(value) => {
                if (!value) {
                  setStartTimes((startTimes) => {
                    delete startTimes[i];
                    return { ...startTimes };
                  });
                  setEndTimes((endTimes) => {
                    delete endTimes[i];
                    return { ...endTimes };
                  });
                } else {
                  onStartTimesChanged(i, 540);
                  onEndTimesChanged(i, 1260);
                }
                setCheckedDays(
                  value
                    ? checkedDays | (1 << i)
                    : checkedDays & (127 - (1 << i))
                );
              }}
            />

            {((checkedDays >> i) & 1) === 1 ? (
              <HFlex f-1>
                <TimePicker
                  renderTrigger={() => {
                    const isActive = ((checkedDays >> i) & 1) === 1;
                    return (
                      <HFlex
                        style={{ width: "100%" }}
                        bc-t1
                        bd-t2
                        p-24-rl
                        p-16-tb
                        bdr-16
                        a-c
                        j-b
                        clickable={isActive}
                      >
                        {isActive &&
                        !isNil(startTimes[i]) &&
                        !isNil(endTimes[i]) ? (
                          <Text t-16-500-s8>
                            {minutesToTimeString(startTimes[i])} ~{" "}
                            {minutesToTimeString(endTimes[i])}
                          </Text>
                        ) : (
                          <Text t-16-500-s4>영업시간을 선택하세요</Text>
                        )}

                        <Image
                          src="/icons/navigation/arrow_drop_down_s3.svg"
                          size={20}
                        />
                      </HFlex>
                    );
                  }}
                  setStartTime={(t) => onStartTimesChanged(i, t)}
                  setEndTime={(t) => onEndTimesChanged(i, t)}
                  startTime={startTimes[i]}
                  endTime={endTimes[i]}
                />
              </HFlex>
            ) : (
              <HFlex f-1 bc-t1 bd-t2 p-24-rl p-16-tb bdr-16>
                <Text t-16-500-s4>영업시간을 선택하세요</Text>
              </HFlex>
            )}
          </HFlex>
        ))}
      </VFlex>
      {checkedDays < 127 && (
        <VFlex m-24-t m-32-rl>
          <Text t-16-gr5>
            매주{" "}
            {days
              .filter((_day, i) => (checkedDays & (1 << i)) === 0)
              .join(", ")}
            요일은 업체정보에 휴무일로 표시됩니다.
          </Text>
        </VFlex>
      )}
      <VFlex f-1 />
      <HFlex height={86} bd-t a-c g-8 m-24-t p-32-rl>
        <VFlex f-1 />
        <Button
          onClick={() => {
            isChanged()
              ? pushPopup({
                  kind: PopupKind.Popup,
                  align: PopupAlign.TopCenter,
                  element: ConfirmPopup,
                  props: {
                    title: "변경사항을 저장하지 않고 닫을까요?",
                    onConfirm: () => {
                      popPopup();
                    },
                  },
                })
              : popPopup();
          }}
          type="cancel"
          caption="취소"
        />
        <Button
          enabled={isChanged()}
          onClick={async () => {
            if (!isChanged()) {
              return;
            }
            const body = [];
            for (let i = 0; i < 7; i++) {
              if ((checkedDays & (1 << i)) === 1 << i) {
                body.push({
                  dayOfWeek: dayOfWeek[i],
                  start: `${("0" + Math.floor(startTimes[i] / 60)).slice(
                    -2
                  )}:${("0" + (startTimes[i] % 60)).slice(-2)}`,
                  end: `${("0" + Math.floor(endTimes[i] / 60)).slice(-2)}:${(
                    "0" +
                    (endTimes[i] % 60)
                  ).slice(-2)}`,
                });
              }
            }
            setLoading(true);
            await api.put(`/rest/group/${partner.id}/business-hour`, body);
            await fetchBusinessHours();
            setLoading(false);
            toast.success("성공적으로 저장되었습니다.");
            trackSaveBizHours(partner.title);
            popPopup();
          }}
          type="confirm"
          caption="저장"
        />
      </HFlex>
    </LoadingView>
  );
}

export default OpenHours;
