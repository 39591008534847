import { useState } from "react";
import { isNil } from "lodash";

import HFlex from "../../layouts/HFlex";
import VFlex from "../../layouts/VFlex";
import Text from "../../layouts/Text";
import Image from "../../layouts/Image";
import Flex from "../../layouts/Flex";
import Button from "../../layouts/Button";
import SubfixInput from "../../layouts/SubfixInput";

import { validateWeight } from "../../common/utils/customers";

const EditPetWeight = ({
  popPopup,
  value,
  fetch,
}: {
  popPopup: () => void;
  value: string | undefined;
  fetch: (v: string | undefined) => void;
}) => {
  const [weight, setWeight] = useState(value);

  return (
    <VFlex p-24>
      <HFlex j-b a-c m-24-b>
        <Text t-24-600-s8>고객 정보 수정</Text>
        <Image
          src="/icons/icon_close_s6.svg"
          size={24}
          clickable
          onClick={popPopup}
        />
      </HFlex>
      <SubfixInput
        caption="몸무게"
        placeholder="예: 7.6"
        value={weight}
        isNumber
        inputClassName="weight_input"
        onChangeValue={(e) => {
          setWeight(e);
        }}
        validate={validateWeight}
        onBlur={() => {
          if (weight?.endsWith(".")) setWeight(weight.replace(".", ""));
        }}
        renderSubfix={() => <Text t-16-500-s8>kg</Text>}
        error={!isNil(weight) && (Number(weight) > 200 || Number(weight) <= 0)}
        errorText={
          Number(weight) > 200
            ? "몸무게는 최대 200kg까지 입력할 수 있습니다."
            : "0kg 이하의 값을 입력할 수 없습니다."
        }
      />
      <Button
        m-24-t
        bdr-12
        height={56}
        enabled={
          value !== weight &&
          (isNil(weight) ||
            (!isNaN(Number(weight)) &&
              Number(weight) > 0 &&
              Number(weight) <= 200))
        }
        onClick={() => {
          fetch(weight);
          popPopup();
        }}
      >
        저장
      </Button>
    </VFlex>
  );
};

export default EditPetWeight;
