import { forwardRef, HTMLAttributes } from "react";
import HFlex from "./HFlex";
import VFlex from "./VFlex";
import Spinner from "../common/Spinner/Spinner";
import {
  BorderProps,
  ColorProps,
  FlexAlignItemsProps,
  FlexJustifyContentProps,
  FlexProps,
  GapProps,
  MiscellaneousProps,
} from "./index_generated";

type Props = {
  classes?: string[];
  loading?: boolean;
  width?: number | string;
  height?: number | string;
} & HTMLAttributes<HTMLDivElement> &
  FlexProps &
  FlexAlignItemsProps &
  FlexJustifyContentProps &
  ColorProps &
  GapProps &
  BorderProps &
  MiscellaneousProps;

const LoadingView = forwardRef<HTMLDivElement, Props>(
  ({ classes = [], children, loading, style, ...props }: Props, ref) => {
    return (
      <VFlex {...props}>
        {loading ? (
          <HFlex c-c f-1 width={"100%"} height={"100%"}>
            <Spinner />
          </HFlex>
        ) : (
          children
        )}
      </VFlex>
    );
  }
);

export default LoadingView;
