import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import api from "../common/api";
import useDebounce from "../common/useDebounce";
import { usePartner } from "./partner";
import { Pet } from "./types";

const ITEMS_PER_PAGE = 10;
interface DeletedCustomersContextProps {
  totalCount: number;
  pets: Pet[];
  searchText: string;
  setSearchText: (t: string) => void;
  page: number;
  setPage: (p: number) => void;
  fetchCurrentPage: () => void;
  restoreCustomer: (petId: number) => void;
  restoreCustomers: (petIds: number[]) => void;
  refreshDeletedCustomers: () => void;
  isLoading: boolean;
}

const DeletedCustomersContext = createContext<
  DeletedCustomersContextProps | undefined
>(undefined);

export const DeletedCustomersProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { partner } = usePartner();

  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = useState<string>("");
  const [pets, setPets] = useState<Pet[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const debouncedSearchText = useDebounce({ value: searchText, delay: 500 });

  const fetchCurrentPage = async () => {
    setIsLoading(true);
    const { data, totalCount } = await api.get(
      `/rest/v2/pet/search?groupId=${partner.id}&type=PET_DELETED&page=${
        page * ITEMS_PER_PAGE
      }&size=${ITEMS_PER_PAGE}&sort=CREATED${
        searchText ? `&search=${searchText}` : ""
      }`
    );

    setPets(data);
    setTotalCount(totalCount);
    setIsLoading(false);
  };

  const refreshDeletedCustomers = async () => {
    setPage(0);
    setSearchText("");
    fetchCurrentPage();
  };

  const restoreCustomer = async (petId: number) => {
    await api.delete(`/rest/pet/${petId}?groupId=${partner.id}&status=false`);
  };

  const restoreCustomers = async (petIds: number[]) => {
    await api.delete(
      `/rest/pets?groupId=${partner.id}&petIds=${petIds}&status=false`
    );
  };

  useEffect(() => {
    setPage(0);
  }, [searchText]);

  useEffect(() => {
    fetchCurrentPage();
  }, [page, debouncedSearchText]);

  const contextValue: DeletedCustomersContextProps = {
    totalCount,
    pets,
    searchText,
    setSearchText,
    page,
    setPage,
    fetchCurrentPage,
    restoreCustomer,
    restoreCustomers,
    refreshDeletedCustomers,
    isLoading,
  };

  return (
    <DeletedCustomersContext.Provider value={contextValue}>
      {children}
    </DeletedCustomersContext.Provider>
  );
};

export default function useDeletedCustomersContext() {
  const context = useContext(DeletedCustomersContext);

  if (!context) {
    throw new Error(
      "useDeletedCustomersContext must be used within DeletedCustomersProvider"
    );
  }

  return context;
}
