import React, { ReactElement, useEffect, useState } from "react";

import api from "../../../common/api";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";

import { usePartner } from "../../../providers/partner";
import { pushPopup } from "../../../providers/popups";
import { PopupKind } from "../../../popup/Popup";

import Divider from "../../../layouts/Divider";
import HFlex from "../../../layouts/HFlex";
import Image from "../../../layouts/Image";
import Text from "../../../layouts/Text";
import VFlex from "../../../layouts/VFlex";

import PartnerBasicInfo from "./PartnerBasicInfo";
import Policies from "./Policies";
import NotificationSettings from "./NotificationSettings";
import OpenHoursSettings from "./OpenHoursSettings";
import Holidays from "./Holidays";
import Staffs from "./Staffs";
import GroupNotifications from "./GroupNotifications";
import NoticeHistories from "./NoticeHistories";
import {
  NoticeOnboardingPopup,
  QuestionnaireOnboardingPopup,
} from "./OnboardingPopup";
import InquirySetting from "./InquirySetting";
import QuestionnaireSetting from "./QuestionnaireSetting";
import ReservationList from "./ReservationList";
import HiddenReservationList from "./HideReservationList";
import DeletedReservationList from "./DeletedReservationList";
// import Plan from "./Plan";

import "./index.scss";
import { ReservationsProvider } from "../../../providers/reservations";
import { HiddenReservationsProvider } from "../../../providers/hiddenReservations";
import { DeletedReservationsProvider } from "../../../providers/deletedReservations";

export type SettingsMenu = {
  name: string;
  link?: string;
  onClick?: () => void;
  component?: React.FC<any>;
  props?: any;
  isPopup?: boolean;
  isDrawer?: boolean;
};

export const SettingsTitle = ({
  children,
  title,
  border = true,
  setSelectedSubpage,
}: {
  children?: React.ReactNode;
  title: string;
  border?: boolean;
  setSelectedSubpage: (component?: ReactElement) => void;
}) => {
  return (
    <HFlex g-8 p-32-rl bd-b-t3={border} height={96} a-c>
      <Image
        size={24}
        clickable
        src={`/icons/icon_back.png`}
        onClick={() => setSelectedSubpage(undefined)}
      />
      <Text f-1 t-24-700-bl3>
        {title}
      </Text>
      {children}
    </HFlex>
  );
};

const SettingsItem = ({
  icon,
  title,
  menus,
  setSelectedSubpage,
}: {
  icon: string;
  title: string;
  menus: SettingsMenu[];
  setSelectedSubpage: (component: ReactElement) => void;
}) => {
  const { partner } = usePartner();
  const { trackClickSettingsMenu } = useAmplitudeTrack();

  return (
    <HFlex f-1 a-st bd-b-t3 style={{ minWidth: 225 }}>
      <VFlex p-16>
        <HFlex a-c g-16 m-16-l m-32-t>
          <Image size={24} src={`/icons/setting_menu/${icon}.png`} />
          <Text t-16-700-s9>{title}</Text>
        </HFlex>
        <VFlex m-24-t g-4 a-s>
          {menus.map((menu, i) =>
            !!menu.link || !!menu.onClick ? (
              <HFlex
                key={i}
                a-c
                bdr-8
                p-16-rl
                classes={["settings-menu"]}
                height={34}
                clickable
                onClick={() =>
                  menu.onClick
                    ? menu.onClick?.()
                    : menu.link && window.open(menu.link)
                }
              >
                <Text t-14-500-gr8>{menu.name}</Text>
              </HFlex>
            ) : (
              !!menu.component && (
                <HFlex
                  key={i}
                  a-c
                  bdr-8
                  p-16-rl
                  classes={["settings-menu"]}
                  height={34}
                  clickable
                  onClick={() => {
                    trackClickSettingsMenu(menu.name, partner.title);

                    if (menu.isPopup || menu.isDrawer) {
                      pushPopup({
                        kind: menu.isPopup ? PopupKind.Popup : PopupKind.Drawer,
                        element: menu.component!,
                        props: menu.props ?? {},
                      });
                    } else {
                      setSelectedSubpage(
                        React.createElement(menu.component!, menu.props)
                      );
                    }
                  }}
                >
                  <Text t-14-500-gr8>{menu.name}</Text>
                </HFlex>
              )
            )
          )}
        </VFlex>
        <VFlex f-1 />
      </VFlex>
      <Divider />
    </HFlex>
  );
};

const Settings = () => {
  const [selectedSubpage, setSelectedSubpage] = useState<ReactElement>();

  const { partner } = usePartner();
  const { trackLandSettings, trackClickSettingsMenu } = useAmplitudeTrack();

  useEffect(() => {
    trackLandSettings();
  }, []);

  if (selectedSubpage) {
    return selectedSubpage;
  }
  return (
    <VFlex>
      <HFlex a-c p-32-rl bd-b-t3 sized height={96}>
        <Text t-24-700>설정</Text>
      </HFlex>
      <HFlex height={295}>
        <SettingsItem
          icon="partner_settings"
          title="업체 기본 설정"
          menus={[
            {
              name: "업체 정보",
              component: PartnerBasicInfo,
              isDrawer: true,
            },
            //{ name: "내 프로필", component: <MyProfile />, isPopup: true },
            {
              name: "정책 관리",
              component: Policies,
              props: { initialTabIndex: 0 },
              isDrawer: true,
            },
            {
              name: "알림 설정",
              component: NotificationSettings,
              props: { setSelectedSubpage },
            },
          ]}
          setSelectedSubpage={setSelectedSubpage}
        />
        <Divider width={1} />
        <SettingsItem
          icon="open_hours"
          title="영업 시간 설정"
          menus={[
            {
              name: "영업 시간",
              component: OpenHoursSettings,
              props: { initialTabIndex: 0 },
              isDrawer: true,
            },
            {
              name: "휴무일",
              component: Holidays,
              props: { setSelectedSubpage },
            },
          ]}
          setSelectedSubpage={setSelectedSubpage}
        />
        <Divider width={1} />
        <SettingsItem
          icon="employees"
          title="직원 관리"
          menus={[
            {
              name: "직원 목록",
              component: Staffs,
              props: { setSelectedSubpage },
            },
          ]}
          setSelectedSubpage={setSelectedSubpage}
        />
      </HFlex>
      <HFlex height={295}>
        <SettingsItem
          icon="icon_bell"
          title="알림 관리"
          menus={[
            {
              name: "알림톡 내역",
              component: GroupNotifications,
              props: { setSelectedSubpage, initialTabIndex: 0 },
            },
            {
              name: "알림톡 관리",
              component: GroupNotifications,
              props: { setSelectedSubpage, initialTabIndex: 1 },
            },
            {
              name: "알림장 내역",
              // TODO global setting
              onClick: async () => {
                trackClickSettingsMenu("알림장 내역", partner.title);
                const isUsing = await api.get(
                  `/rest/user/onboarding?type=NOTICE`
                );

                if (isUsing) {
                  setSelectedSubpage(
                    React.createElement(NoticeHistories, {
                      setSelectedSubpage,
                      initialTabIndex: 0,
                    })
                  );
                } else {
                  pushPopup({
                    element: NoticeOnboardingPopup,
                    kind: PopupKind.Popup,
                  });
                }
              },
            },
          ]}
          setSelectedSubpage={setSelectedSubpage}
        />
        <Divider width={1} />
        <SettingsItem
          icon="reservation"
          title="예약 관리"
          menus={[
            {
              name: "예약 목록",
              component: () => (
                <ReservationsProvider>
                  <ReservationList setSelectedSubpage={setSelectedSubpage} />
                </ReservationsProvider>
              ),
            },
            {
              name: "숨긴 예약 목록",
              component: () => (
                <HiddenReservationsProvider>
                  <HiddenReservationList
                    setSelectedSubpage={setSelectedSubpage}
                  />
                </HiddenReservationsProvider>
              ),
            },
            {
              name: "삭제된 예약 목록",
              component: () => (
                <DeletedReservationsProvider>
                  <DeletedReservationList
                    setSelectedSubpage={setSelectedSubpage}
                  />
                </DeletedReservationsProvider>
              ),
            },
            {
              name: "예약 문의 설정",
              component: InquirySetting,
              props: { setSelectedSubpage, initialTabIndex: 0 },
            },
            {
              name: "안전 문진표",
              // TODO
              onClick: async () => {
                trackClickSettingsMenu("안전 문진표", partner.title);
                const isUsing = await api.get(
                  `/rest/user/onboarding?type=QUESTIONNAIRE`
                );

                if (isUsing) {
                  setSelectedSubpage(
                    React.createElement(QuestionnaireSetting, {
                      setSelectedSubpage,
                      initialTabIndex: 0,
                    })
                  );
                } else {
                  pushPopup({
                    element: QuestionnaireOnboardingPopup,
                    kind: PopupKind.Popup,
                    props: {
                      linkToQuestionnaireSetting: () =>
                        setSelectedSubpage(
                          React.createElement(QuestionnaireSetting, {
                            setSelectedSubpage,
                            initialTabIndex: 0,
                          })
                        ),
                    },
                  });
                }
              },
            },
          ]}
          setSelectedSubpage={setSelectedSubpage}
        />
        <Divider width={1} />
        <SettingsItem
          icon="customer_center"
          title="고객센터"
          menus={[
            {
              name: "고객 센터",
              onClick: () => {
                trackClickSettingsMenu("고객 센터", partner.title);
                window.open(
                  "https://pawdlypartners.channel.io/lounge",
                  "_blank"
                );
              },
            },
          ]}
          setSelectedSubpage={setSelectedSubpage}
        />
        <Divider width={1} />
      </HFlex>
      <HFlex height={295}>
        <SettingsItem
          icon="pawdly_news"
          title="소식 및 가이드"
          menus={[
            {
              name: "포들리 이용 가이드",
              onClick: () => {
                trackClickSettingsMenu("포들리 이용 가이드", partner.title);
                window.open(
                  "https://pawdly.notion.site/PawdlyPartners-1887af19093c48e1bb6f148f42bafa22",
                  "_blank"
                );
              },
            },
            {
              name: "공지사항",
              onClick: () => {
                trackClickSettingsMenu("공지사항", partner.title);
                window.open(
                  "https://pawdly.notion.site/671c1b8085e745b3b9b59f6b3bfdffcb",
                  "_blank"
                );
              },
            },
          ]}
          setSelectedSubpage={setSelectedSubpage}
        />
        <Divider width={1} />
        {/* <SettingsItem
          icon="pawdly_plan"
          title="포들리 플랜 관리"
          menus={[
            {
              name: "플랜 관리",
              // component: Plan,
              // props: { setSelectedSubpage, initialTabIndex: 0 },
            },
            // {
            //   name: '결제 관리',
            //   component: <Plan setSelectedSubpage={setSelectedSubpage} initialTabIndex={1} />,
            // },
            // { name: '유료 버전 무료로 사용해보기', link: '#' },
          ]}
          setSelectedSubpage={setSelectedSubpage}
        />
        <Divider width={1} /> */}
        {/* TODO */}
        <VFlex f-1 />
        <VFlex f-1 />
      </HFlex>
    </VFlex>
  );
};

export default Settings;
