import { useState } from "react";

import HFlex from "../layouts/HFlex";
import VFlex from "../layouts/VFlex";
import Flex from "../layouts/Flex";

import { Tag } from "../providers/types";

const TagDropdown = ({ tagList }: { tagList: Tag[] }) => {
  return (
    <VFlex bc-w bdr-12 p-8 g-8 sdw-6>
      {tagList.map((tag) => (
        <TagDropdownItem tag={tag} key={tag.id} />
      ))}
    </VFlex>
  );
};

const TagDropdownItem = ({ tag }: { tag: Tag }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <HFlex
      a-c
      t-14-500-s6
      bdr-8
      bc-w={!isHovered}
      bc-t2={isHovered}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      clickable
    >
      <Flex
        p-2-tb
        p-8-rl
        t-14-500
        sized
        bdr-8
        style={{
          color: tag.textColor,
          background: tag.backgroundColor,
        }}
      >
        {tag.title}
      </Flex>
    </HFlex>
  );
};

export default TagDropdown;
