import HFlex from "../../layouts/HFlex";
import VFlex from "../../layouts/VFlex";
import Text from "../../layouts/Text";
import Image from "../../layouts/Image";
import { useState } from "react";
import TextInput from "../../layouts/TextInput";
import Flex from "../../layouts/Flex";
import Button from "../../layouts/Button";

const EditPetName = ({
  popPopup,
  value,
  fetch,
}: {
  popPopup: () => void;
  value: string;
  fetch: (v: string | null) => void;
}) => {
  const [petName, setPetName] = useState(value);

  return (
    <VFlex p-24>
      <HFlex j-b a-c m-24-b>
        <Text t-24-600-s8>고객 정보 수정</Text>
        <Image
          src="/icons/icon_close_s6.svg"
          size={24}
          clickable
          onClick={popPopup}
        />
      </HFlex>
      <TextInput
        caption="반려동물명"
        placeholder="이름 입력"
        required
        value={petName}
        onChangeValue={(e) => setPetName(e ?? "")}
      />
      <Button
        m-24-t
        bdr-12
        height={56}
        enabled={!!petName && value !== petName}
        onClick={() => {
          fetch(petName);
          popPopup();
        }}
      >
        저장
      </Button>
    </VFlex>
  );
};

export default EditPetName;
