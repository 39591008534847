import ReactDatePicker from "react-datepicker";
import HFlex from "../layouts/HFlex";
import Image from "../layouts/Image";
import { ko } from "date-fns/locale";
import { useEffect, useState } from "react";
import VFlex from "../layouts/VFlex";
import Text from "../layouts/Text";

import "./DateTimePicker.scss";
import Flex from "../layouts/Flex";
import {
  addYears,
  format,
  isSameDay,
  setDate,
  setHours,
  setMinutes,
  setMonth,
  setYear,
} from "date-fns";
import Divider from "../layouts/Divider";
import api from "../common/api";
import { usePartner } from "../providers/partner";
import Dropdown from "../layouts/Dropdown";

const FOCUSED_DATE_TIME = ["START", "END"] as const;
const VIEW_TYPE = ["YEAR", "MONTH", "DAY", "TIME"] as const;

interface ReservationCount {
  date: number;
  reservationCount: number;
  color: { textColor: string };
}

interface DateTimePickerContainerProps extends DateTimePickerProps {
  renderTrigger: () => React.ReactElement;
}

interface DateTimePickerProps {
  showReservationCount: boolean;
  isRange?: boolean;
  startDateTime: Date;
  setStartDateTime: (dt: Date) => void;
  endDateTime?: Date;
  setEndDateTime?: (dt: Date) => void;
  minDate?: Date;
  maxDate?: Date;
}

const PICKER_START_DATE = new Date("2000-01-01");
const PICKER_END_DATE = new Date(addYears(new Date(), 5).getFullYear(), 11, 31);

const DateTimePickerContainer = ({
  renderTrigger,
  ...props
}: DateTimePickerContainerProps) => {
  return (
    <Dropdown
      position="left"
      trigger={renderTrigger()}
      renderOptions={() => <DateTimePicker {...props} />}
    />
  );
};

const DateTimePicker = ({
  showReservationCount,
  isRange,
  startDateTime,
  setStartDateTime,
  endDateTime,
  setEndDateTime,
  minDate,
  maxDate,
}: DateTimePickerProps) => {
  const [focused, setFocused] = useState<(typeof FOCUSED_DATE_TIME)[number]>(
    FOCUSED_DATE_TIME[0]
  );
  const [viewType, setViewType] = useState<(typeof VIEW_TYPE)[number]>(
    VIEW_TYPE[2]
  );
  const [currentMonthResCnt, setCurrentMonthResCnt] = useState<
    ReservationCount[]
  >([]);
  const [error, setError] = useState(false);
  const { partner } = usePartner();

  const fetchReservationCount = async (standardDate: Date) => {
    const data = await api.get<ReservationCount[]>(
      `/rest/group/${partner.id}/calendar`,
      {
        params: {
          standardDate: Math.floor(standardDate.getTime() / 1000),
        },
      }
    );

    setCurrentMonthResCnt(data);
  };

  useEffect(() => {
    setViewType(VIEW_TYPE[2]);
  }, []);

  useEffect(() => {
    fetchReservationCount(
      focused === FOCUSED_DATE_TIME[0] ? startDateTime! : endDateTime!
    );
  }, [focused]);

  useEffect(() => {
    if (
      isRange &&
      endDateTime &&
      startDateTime.getTime() >= endDateTime?.getTime()
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }, [startDateTime, endDateTime]);

  const getNewDateTime = (dateTime: Date, newDateTime: Date) => {
    if (viewType === VIEW_TYPE[0]) {
      return setYear(dateTime, newDateTime.getFullYear());
    } else if (viewType === VIEW_TYPE[1]) {
      return setMonth(dateTime, newDateTime.getMonth());
    }
    return newDateTime;
  };
  return (
    <VFlex classes={["date-time-picker"]}>
      <VFlex g-8 m-6-b>
        <HFlex height={38} bdr-8>
          <HFlex
            onClick={() => {
              setViewType(VIEW_TYPE[2]);
              setFocused(FOCUSED_DATE_TIME[0]);
            }}
            clickable
            f-1
            p-12-rl
            a-c
            bd-t5={
              focused !== FOCUSED_DATE_TIME[0] || viewType === VIEW_TYPE[3]
            }
            bd-g1={
              focused === FOCUSED_DATE_TIME[0] && viewType !== VIEW_TYPE[3]
            }
            style={{
              borderTopLeftRadius: "8px",
              borderBottomLeftRadius: "8px",
              borderRight: "none",
              background:
                focused === FOCUSED_DATE_TIME[0] && viewType !== VIEW_TYPE[3]
                  ? "rgba(39, 189, 99, 0.04)"
                  : "#F8F9F8",
            }}
          >
            <Text t-14-500-s8>{format(startDateTime, "yyyy. M. d")}</Text>
          </HFlex>
          <Divider
            width={1}
            bc-g1={focused === FOCUSED_DATE_TIME[0]}
            bc-t5={focused !== FOCUSED_DATE_TIME[0]}
          />
          <HFlex
            onClick={() => {
              setViewType(VIEW_TYPE[3]);
              setFocused(FOCUSED_DATE_TIME[0]);
            }}
            clickable
            f-1
            p-12-rl
            a-c
            bd-t5={
              focused !== FOCUSED_DATE_TIME[0] || viewType !== VIEW_TYPE[3]
            }
            bd-g1={
              focused === FOCUSED_DATE_TIME[0] && viewType === VIEW_TYPE[3]
            }
            style={{
              borderTopRightRadius: "8px",
              borderBottomRightRadius: "8px",
              borderLeft: "none",
              background:
                focused === FOCUSED_DATE_TIME[0] && viewType === VIEW_TYPE[3]
                  ? "rgba(39, 189, 99, 0.04)"
                  : "#F8F9F8",
            }}
          >
            <Text t-14-500-s8>
              {format(startDateTime, "a hh:mm", { locale: ko })}
            </Text>
          </HFlex>
        </HFlex>
        {isRange && endDateTime && (
          <HFlex height={38} bdr-8>
            <HFlex
              onClick={() => {
                setViewType(VIEW_TYPE[2]);
                setFocused(FOCUSED_DATE_TIME[1]);
              }}
              clickable
              f-1
              p-12-rl
              a-c
              bd-t5={
                focused !== FOCUSED_DATE_TIME[1] || viewType === VIEW_TYPE[3]
              }
              bd-g1={
                focused === FOCUSED_DATE_TIME[1] &&
                viewType !== VIEW_TYPE[3] &&
                !error
              }
              bd-r1={
                focused === FOCUSED_DATE_TIME[1] &&
                viewType !== VIEW_TYPE[3] &&
                error
              }
              style={{
                borderTopLeftRadius: "8px",
                borderBottomLeftRadius: "8px",
                borderRight: "none",
                background:
                  focused === FOCUSED_DATE_TIME[1] && viewType !== VIEW_TYPE[3]
                    ? error
                      ? "rgba(255, 79, 23, 0.04)"
                      : "rgba(39, 189, 99, 0.04)"
                    : "#F8F9F8",
              }}
            >
              <Text t-14-500-s8>{format(endDateTime, "yyyy. M. d")}</Text>
            </HFlex>
            <Divider
              width={1}
              bc-g1={focused === FOCUSED_DATE_TIME[1] && !error}
              bc-r1={focused === FOCUSED_DATE_TIME[1] && error}
              bc-t5={focused !== FOCUSED_DATE_TIME[1]}
            />
            <HFlex
              onClick={() => {
                setViewType(VIEW_TYPE[3]);
                setFocused(FOCUSED_DATE_TIME[1]);
              }}
              clickable
              f-1
              p-12-rl
              a-c
              bd-t5={
                focused !== FOCUSED_DATE_TIME[1] || viewType !== VIEW_TYPE[3]
              }
              bd-g1={
                focused === FOCUSED_DATE_TIME[1] &&
                viewType === VIEW_TYPE[3] &&
                !error
              }
              bd-r1={
                focused === FOCUSED_DATE_TIME[1] &&
                viewType === VIEW_TYPE[3] &&
                error
              }
              style={{
                borderTopRightRadius: "8px",
                borderBottomRightRadius: "8px",
                borderLeft: "none",
                background:
                  focused === FOCUSED_DATE_TIME[1] && viewType === VIEW_TYPE[3]
                    ? error
                      ? "rgba(255, 79, 23, 0.04)"
                      : "rgba(39, 189, 99, 0.04)"
                    : "#F8F9F8",
              }}
            >
              <Text t-14-500-s8>
                {format(endDateTime, "a hh:mm", { locale: ko })}
              </Text>
            </HFlex>
          </HFlex>
        )}
      </VFlex>

      {viewType !== VIEW_TYPE[3] && (
        <ReactDatePicker
          selected={
            focused === FOCUSED_DATE_TIME[0] ? startDateTime : endDateTime
          }
          minDate={minDate}
          maxDate={maxDate}
          open
          onChange={(date) => {
            focused === FOCUSED_DATE_TIME[0]
              ? setStartDateTime(getNewDateTime(startDateTime, date!))
              : setEndDateTime!(getNewDateTime(endDateTime!, date!));

            if (
              VIEW_TYPE.findIndex((t) => t === viewType) + 1 ===
              VIEW_TYPE.length
            ) {
            } else {
              setViewType(
                VIEW_TYPE[VIEW_TYPE.findIndex((t) => t === viewType) + 1]
              );
            }
          }}
          openToDate={
            focused === FOCUSED_DATE_TIME[0] ? startDateTime : endDateTime
          }
          yearItemNumber={80}
          onMonthChange={(date) => fetchReservationCount(date)}
          shouldCloseOnSelect={false}
          renderCustomHeader={({ decreaseMonth, increaseMonth, date }) => (
            <HFlex p-20-b bd-b-t2 m-8-b>
              <HFlex
                c-c
                m-8-r
                clickable
                onClick={() => setViewType(VIEW_TYPE[0])}
              >
                <Text t-16-600-s8 m-12-l>
                  {date.getFullYear()}년
                </Text>
                <Image src="/icons/chevron_down.png" size={24} />
              </HFlex>
              <HFlex c-c clickable onClick={() => setViewType(VIEW_TYPE[1])}>
                <Text t-16-600-s8>{date.getMonth() + 1}월</Text>
                <Image src="/icons/chevron_down.png" size={24} />
              </HFlex>
              <Flex f-1 />
              {viewType !== VIEW_TYPE[0] && viewType !== VIEW_TYPE[1] && (
                <>
                  <Flex width={48} c-c>
                    <Image
                      src="/icons/icon_chevron_left_s6.svg"
                      size={24}
                      clickable
                      onClick={() => {
                        decreaseMonth();
                      }}
                    />
                  </Flex>
                  <Flex width={48} c-c>
                    <Image
                      src="/icons/icon_chevron_right_s6.svg"
                      size={24}
                      clickable
                      onClick={() => {
                        increaseMonth();
                      }}
                    />
                  </Flex>
                </>
              )}
            </HFlex>
          )}
          renderDayContents={(dayOfMonth, date) => {
            const data = currentMonthResCnt?.find(
              (r) => date && isSameDay(date?.getTime(), r.date * 1000)
            );

            return (
              <VFlex f-1>
                <Flex
                  classes={["day-container"]}
                  style={{ color: data?.color.textColor }}
                >
                  {dayOfMonth}
                </Flex>
                {data?.reservationCount && data.reservationCount > 0 ? (
                  <Flex t-10-500-s2 style={{ lineHeight: "12px" }}>
                    {data.reservationCount}
                  </Flex>
                ) : (
                  <></>
                )}
              </VFlex>
            );
          }}
          showYearPicker={viewType === VIEW_TYPE[0]}
          showMonthYearPicker={viewType === VIEW_TYPE[1]}
          locale={ko}
        />
      )}
      {viewType === VIEW_TYPE[3] && (
        <HFlex p-8-t classes={["react-datepicker__time-container"]}>
          <VFlex f-1 a-c ovf-a>
            {[12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((h, index) => {
              const currentDateTime =
                focused === FOCUSED_DATE_TIME[0]
                  ? startDateTime!
                  : endDateTime!;
              const selectedHour =
                currentDateTime.getHours() >= 12
                  ? currentDateTime.getHours() - 12
                  : currentDateTime.getHours();

              return (
                <HFlex
                  key={h}
                  classes={
                    selectedHour === index
                      ? ["time-option-item", "time-option-item--selected"]
                      : ["time-option-item"]
                  }
                  onClick={() => {
                    const currentDateTime =
                      focused === FOCUSED_DATE_TIME[0]
                        ? startDateTime!
                        : endDateTime!;
                    const newHour =
                      currentDateTime.getHours() > 12 ? index + 12 : index;

                    if (focused === FOCUSED_DATE_TIME[0]) {
                      setStartDateTime(setHours(currentDateTime, newHour));
                    } else {
                      setEndDateTime!(setHours(currentDateTime, newHour));
                    }
                  }}
                >
                  {h.toString().length > 1 ? "" : "0"}
                  {h}
                </HFlex>
              );
            })}
          </VFlex>
          <Divider width={1} bc-t2 m-8-rl />
          <VFlex f-1 a-c ovf-a>
            {Array(12)
              .fill(null)
              .map((_, i) => {
                const currentDateTime =
                  focused === FOCUSED_DATE_TIME[0]
                    ? startDateTime!
                    : endDateTime!;

                return (
                  <HFlex
                    key={i * 5}
                    classes={
                      currentDateTime.getMinutes() === i * 5
                        ? ["time-option-item", "time-option-item--selected"]
                        : ["time-option-item"]
                    }
                    onClick={() => {
                      const currentDateTime =
                        focused === FOCUSED_DATE_TIME[0]
                          ? startDateTime!
                          : endDateTime!;

                      if (focused === FOCUSED_DATE_TIME[0]) {
                        setStartDateTime(setMinutes(currentDateTime, i * 5));
                      } else {
                        setEndDateTime!(setMinutes(currentDateTime, i * 5));
                      }
                    }}
                  >
                    {(i * 5).toString().length > 1 ? "" : "0"}
                    {i * 5}
                  </HFlex>
                );
              })}
          </VFlex>
          <Divider width={1} bc-t2 m-8-rl />
          <VFlex f-1 a-c ovf-a>
            {["오전", "오후"].map((h, i) => {
              const isAM =
                (focused === FOCUSED_DATE_TIME[0]
                  ? startDateTime!
                  : endDateTime!
                ).getHours() < 12;

              return (
                <HFlex
                  key={h}
                  classes={
                    (isAM && i === 0) || (!isAM && i === 1)
                      ? ["time-option-item", "time-option-item--selected"]
                      : ["time-option-item"]
                  }
                  onClick={() => {
                    const currentDateTime =
                      focused === FOCUSED_DATE_TIME[0]
                        ? startDateTime!
                        : endDateTime!;

                    if (i === 0 && currentDateTime.getHours() >= 12) {
                      if (focused === FOCUSED_DATE_TIME[0]) {
                        setStartDateTime(
                          setHours(
                            currentDateTime,
                            currentDateTime.getHours() - 12
                          )
                        );
                      } else {
                        setEndDateTime!(
                          setHours(
                            currentDateTime,
                            currentDateTime.getHours() - 12
                          )
                        );
                      }
                    } else if (i === 1 && currentDateTime.getHours() < 12) {
                      if (focused === FOCUSED_DATE_TIME[0]) {
                        setStartDateTime(
                          setHours(
                            currentDateTime,
                            currentDateTime.getHours() + 12
                          )
                        );
                      } else {
                        setEndDateTime!(
                          setHours(
                            currentDateTime,
                            currentDateTime.getHours() + 12
                          )
                        );
                      }
                    }
                  }}
                >
                  {h}
                </HFlex>
              );
            })}
          </VFlex>
        </HFlex>
      )}
    </VFlex>
  );
};

export default DateTimePickerContainer;
