import { Dispatch, SetStateAction, useMemo, useState } from "react";

import { reg } from "../../../common/format";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";

import { Product, Service } from "../../../providers/types";
import { useProductGroups } from "../../../providers/productGroups";
import { useTaxonomies } from "../../../providers/taxonomies";

import SearchInput from "../../../components/SearchInput";

import Image from "../../../layouts/Image";
import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Text from "../../../layouts/Text";

import _ from "lodash";
import hangul from "hangul-js";

function MenuListV2({
  groupFilter,
  selectionMode,
  selectedProducts,
  setSelectedProducts,
  searchText,
  setSearchText,
}: // viewType,
// setViewType,
{
  groupFilter?: string;
  selectionMode?: boolean;
  selectedProducts?: Service[];
  setSelectedProducts?: Dispatch<SetStateAction<Service[]>>;
  searchText: string | undefined;
  setSearchText: Dispatch<SetStateAction<string | undefined>>;
  // viewType: string;
  // setViewType: (type: string) => void;
}) {
  const { trackClickServiceMenuSearch } = useAmplitudeTrack();
  const { productGroups } = useProductGroups();
  const { taxonomies } = useTaxonomies();

  const menus = useMemo(
    () =>
      _.compact(
        _.flatten(
          taxonomies["INTANGIBLE_BEAUTY_MENU"]?.map((menuType) =>
            taxonomies[
              `INTANGIBLE_BEAUTY_SERVICE?objectId=${menuType.id}`
            ]?.map((subMenuType) => ({
              menuType: menuType.name,
              serviceType: subMenuType.name,
            }))
          )
        )
          .filter((group) => !groupFilter || group.menuType === groupFilter)
          .map(({ menuType, serviceType }) => {
            const group = productGroups.find(
              (group) =>
                group.menuType === menuType && group.serviceType === serviceType
            );
            if (group) {
              const productList = group.productList.filter(
                (product) =>
                  hangul.search(
                    `[${group.serviceType}] ${product.name}`,
                    searchText ?? ""
                  ) >= 0
              );
              if (!searchText || productList.length > 0) {
                return {
                  menuType,
                  serviceType,
                  productList,
                };
              }
            } else if (!searchText) {
              if (!selectionMode) {
                return {
                  menuType,
                  serviceType,
                  productList: [],
                };
              }
            }
            return undefined;
          })
      ),
    [searchText, groupFilter, productGroups, taxonomies, selectionMode]
  );

  return (
    <VFlex ovf-h>
      <HFlex a-c j-b g-16 p-16-t p-24-rl>
        <VFlex bdr-16 p-16-rl bd-t3 ovf-h f-1>
          <SearchInput
            height={46}
            placeholder="메뉴(상품)를 검색하세요"
            value={searchText}
            onChangeValue={(value) => {
              setSearchText(reg(value));
            }}
            onClick={() => {
              trackClickServiceMenuSearch();
            }}
          />
        </VFlex>
        {/* <Image
          size={24}
          src={`/icons/content/${
            viewType === "GRID" ? "format_list_bulleted" : "grid_view"
          }_s3.svg`}
          clickable
          onClick={() => {
            if (viewType === "LIST") {
              setViewType("GRID");
            } else {
              setViewType("LIST");
            }
          }}
        /> */}
      </HFlex>
      <VFlex f-1 p-24-rl g-16 ovf-a style={{ maxWidth: 648 }}>
        {menus.length === 0 ? (
          <HFlex height={330} bdr-16 bd-t3 c-c m-16-tb>
            <VFlex a-c g-8>
              <Image size={24} src={`/icons/icon_info_gray.png`} />
              <Text t-16-500-s4>검색 결과가 없습니다.</Text>
            </VFlex>
          </HFlex>
        ) : (
          <MenuItem
            // viewType={viewType}
            menus={menus}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
          />
        )}
      </VFlex>
    </VFlex>
  );
}

export default MenuListV2;

const MenuItem = ({
  // viewType,
  menus,
  selectedProducts,
  setSelectedProducts,
}: {
  // viewType: string;
  menus: {
    menuType: string;
    serviceType: string;
    productList: Product[];
  }[];
  selectedProducts?: Service[];
  setSelectedProducts?: Dispatch<SetStateAction<Service[]>>;
}) => {
  return (
    <VFlex>
      {menus.map((group, i) => (
        <ListBox
          key={i}
          i={i}
          menus={menus}
          group={group}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          // viewType={viewType}
        />
      ))}
    </VFlex>
  );
};

const ListBox = ({
  i,
  menus,
  group,
  selectedProducts,
  setSelectedProducts,
}: // viewType,
{
  i: number;
  group: {
    menuType: string;
    serviceType: string;
    productList: Product[];
  };
  menus: {
    menuType: string;
    serviceType: string;
    productList: Product[];
  }[];
  selectedProducts?: Service[];
  setSelectedProducts?: Dispatch<SetStateAction<Service[]>>;
  // viewType: string;
}) => {
  const [listOpened, setListOpened] = useState(false);

  return (
    <VFlex key={i} bd-b-t3={i !== menus.length - 1} g-12>
      <HFlex
        p-24-tb={listOpened}
        p-24-t={!listOpened}
        a-c
        j-b
        onClick={() => {
          setListOpened((prev) => !prev);
        }}
        clickable
      >
        <HFlex a-c g-6>
          <VFlex f-1 t-16-600-s8>
            {`[${group.menuType}] ${group.serviceType}`}
          </VFlex>
          {group.productList
            .map((p) => p.id)
            .filter((id) =>
              selectedProducts?.some((product) => product.product.id === id)
            )
            .map(
              (_, index, arr) =>
                index === arr.length - 1 && (
                  <Text t-16-600-gr5 key={index}>
                    {arr.length}
                  </Text>
                )
            )}
        </HFlex>
        <Image
          size={24}
          src={`/icons/navigation/expand_${
            listOpened ? "more" : "less"
          }_s3.svg`}
        />
      </HFlex>
      <VFlex
        p-12-b
        style={{
          display: listOpened ? "none" : "block",
        }}
      >
        {group.productList.map((product, j) => (
          <ListItem
            key={j}
            product={product}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
          />
        ))}
      </VFlex>
      {/* {viewType === "LIST" ? (
        <VFlex
          p-12-b
          style={{
            display: listOpened ? "none" : "block",
          }}
        >
          {group.productList.map((product, j) => (
            <ListItem
              key={j}
              product={product}
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
            />
          ))}
        </VFlex>
      ) : (
        <HFlex
          p-12-b
          a-s
          style={{
            display: listOpened ? "none" : "flex",
            flexWrap: "wrap",
          }}
        >
          {group.productList.map((product, j) => (
            <HFlex
              a-c
              j-b
              clickable
              key={j}
              onClick={() => {
                setSelectedProducts?.((selectedProducts) => {
                  if (product.menuType.id === 198) {
                    selectedProducts = selectedProducts.filter(
                      (product) => product.product.menuType.id !== 198
                    );
                  }
                  if (
                    selectedProducts
                      .map((product) => product.product.id)
                      .includes(product.id)
                  ) {
                    return [
                      ...selectedProducts.filter(
                        (p) => p.product.id !== product.id
                      ),
                    ];
                  } else {
                    return [
                      ...selectedProducts,
                      {
                        product,
                        quantity: 1,
                      },
                    ];
                  }
                });
              }}
            >
              <VFlex g-12 a-c p-12>
                <VFlex bc-t2 bdr-16 width={176} height={176} a-c j-c rel>
                  {product.thumbnail ? (
                    <Image
                      width={"100%"}
                      height={"100%"}
                      src={product.thumbnail.path}
                    />
                  ) : (
                    <Image width={68} src={`/icons/logo_t5.svg`} />
                  )}
                  {selectedProducts
                    ?.map((product) => product.product.id)
                    ?.includes(product.id) && (
                    <VFlex
                      width={32}
                      height={32}
                      bdr-32
                      bc-gr5
                      a-c
                      j-c
                      sdw-1
                      style={{
                        position: "absolute",
                        right: 8,
                        bottom: 8,
                      }}
                    >
                      {product.stockManaged ? (
                        <Text t-16-600-w>
                          {selectedProducts
                            ?.filter((s) => s.product.id === product.id)
                            .map((s) => s.quantity)}
                        </Text>
                      ) : (
                        <Image size={24} src={`/icons/action/check_w.svg`} />
                      )}
                    </VFlex>
                  )}
                </VFlex>
                <VFlex
                  a-c
                  g-2
                  ovf-h
                  style={{
                    maxWidth: 176,
                  }}
                >
                  <HFlex g-4 a-c>
                    <Image src={`/icons/action/package_s4.svg`} size={16} />
                    <Text t-12-s4>{"80"}개 남았어요</Text>
                  </HFlex>
                  <Text
                    t-14-s7
                    l-m
                    style={{
                      textAlign: "center",
                    }}
                  >{`${product.name}`}</Text>
                  <Text t-14-s4>
                    {product.duration >= 60
                      ? `${Math.floor(product.duration / 60)}시간 ${
                          product.duration % 60 === 0
                            ? ""
                            : (product.duration % 60) + "분"
                        }`
                      : `${product.duration % 60}분`}
                  </Text>
                  <Text t-16-600-s8>{product.price.toLocaleString()}원</Text>
                </VFlex>
              </VFlex>
            </HFlex>
          ))}
        </HFlex>
      )} */}
    </VFlex>
  );
};

const ListItem = ({
  product,
  selectedProducts,
  setSelectedProducts,
}: {
  product: Product;
  selectedProducts?: Service[];
  setSelectedProducts?: Dispatch<SetStateAction<Service[]>>;
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <HFlex
      a-c
      j-b
      p-12
      bdr-16
      hover
      height={64}
      clickable
      g-16
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        setSelectedProducts?.((selectedProducts) => {
          const isSelected = selectedProducts
            .map((p) => p.product.id)
            .includes(product.id);

          if (isSelected) {
            return selectedProducts.filter((p) => p.product.id !== product.id);
          } else {
            if (product.menuType.id === 198) {
              return [
                ...selectedProducts.filter(
                  (p) => p.product.menuType.id !== 198
                ),
                {
                  product,
                  quantity: 1,
                },
              ];
            } else {
              return [
                ...selectedProducts,
                {
                  product,
                  quantity: 1,
                },
              ];
            }
          }
        });
      }}
    >
      <HFlex g-16 a-c>
        {/* <VFlex bc-t2 bdr-12 width={40} height={40} a-c j-c sized>
          {product.thumbnail ? (
            <Image
              width={"100%"}
              height={"100%"}
              src={product.thumbnail.path}
            />
          ) : (
            <Image width={22} src={`/icons/logo_t5.svg`} />
          )}
        </VFlex> */}
        <Text t-14-s7 l-m>{`${product.name}`}</Text>
      </HFlex>

      <HFlex a-c g-8>
        <Text t-14-500-s4>
          {product.duration >= 60
            ? `${Math.floor(product.duration / 60)}시간 ${
                product.duration % 60 === 0
                  ? ""
                  : (product.duration % 60) + "분"
              }`
            : `${product.duration % 60}분`}
        </Text>

        {selectedProducts
          ?.map((product) => product.product.id)
          ?.includes(product.id) ? (
          <>
            {isHovered && (
              <Text t-14-600-s8>{product.price.toLocaleString()}원</Text>
            )}
            <VFlex width={32} height={32} bdr-32 bc-gr5 a-c j-c>
              {product.stockManaged ? (
                <Text t-16-600-w>
                  {selectedProducts
                    ?.filter((s) => s.product.id === product.id)
                    .map((s) => s.quantity)}
                </Text>
              ) : (
                <Image size={24} src={`/icons/action/check_w.svg`} />
              )}
            </VFlex>
          </>
        ) : (
          <Text t-14-600-s8>{product.price.toLocaleString()}원</Text>
        )}
      </HFlex>
    </HFlex>
  );
};
