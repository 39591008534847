import { Toaster } from "react-hot-toast";

const Toast = () => (
  <Toaster
    containerStyle={{ zIndex: 100000000002 }}
    toastOptions={{
      iconTheme: {
        primary: "#40cf8a",
        secondary: "#ffffff",
      },
      style: {
        maxWidth: "unset",
        color: "#ffffff",
        backgroundColor: "#00210D",
      },
      blank: {
        position: "top-center",
        className: "p-16-r p-24-l",
        style: { height: 56, borderRadius: 24 },
      },
      loading: {
        position: "top-center",
        className: "p-16-r p-24-l",
        style: { height: 56, borderRadius: 24 },
      },
      success: {
        position: "top-center",
        className: "p-16-r p-24-l",
        style: { height: 56, borderRadius: 24 },
      },
      error: {
        position: "top-center",
        className: "p-16-r p-24-l",
        style: {
          height: 56,
          borderRadius: 24,
          color: "#F26312",
          backgroundColor: "#FFDECC",
        },
        iconTheme: {
          primary: "#fd5a26",
          secondary: "#ffffff",
        },
      },
    }}
  />
);

export default Toast;
