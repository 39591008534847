import _ from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";
import api from "../../../common/api";
import Dropdown, { DropdownItem } from "../../../components/Dropdown";
import TextInput from "../../../layouts/TextInput";
import Button from "../../../layouts/Button";
import HFlex from "../../../layouts/HFlex";
import Image from "../../../layouts/Image";
import Text from "../../../layouts/Text";
import TextArea from "../../../layouts/TextareaV2";
import VFlex from "../../../layouts/VFlex";
import ConfirmPopup from "../../../popup/ConfirmPopup";
import { PopupAlign, PopupKind } from "../../../popup/Popup";
import { usePartner } from "../../../providers/partner";
import { pushPopup } from "../../../providers/popups";
import { useProductGroups } from "../../../providers/productGroups";
import { useTaxonomies } from "../../../providers/taxonomies";
import { Product } from "../../../providers/types";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";

function AddMenu({
  popPopup,
  editingProduct,
  selectedMenuType,
  selectedServiceType,
}: {
  popPopup: () => void;
  editingProduct?: Product;
  selectedMenuType?: string;
  selectedServiceType?: string;
}) {
  const {
    trackSaveServiceMenu,
    trackSaveNewServiceMenu,
    trackClickDeleteServiceMenu,
    trackDeleteServiceMenu,
  } = useAmplitudeTrack();
  const { partner } = usePartner();
  const { fetchProductGroups } = useProductGroups();
  const { taxonomies } = useTaxonomies();
  const [serviceName, setServiceName] = useState<string | undefined>(
    editingProduct?.name
  );
  const [price, setPrice] = useState<string | undefined>(
    editingProduct?.price?.toString()
  );
  const [description, setDescription] = useState<string | undefined>(
    editingProduct?.description
  );
  const menuOptions = useMemo(
    () =>
      taxonomies["INTANGIBLE_BEAUTY_MENU"]?.map((value) => ({
        value: value.id,
        label: value.name,
      })) ?? [],
    [taxonomies]
  );
  const [menuType, setMenuType] = useState<DropdownItem | undefined>();
  useEffect(() => {
    setMenuType(
      editingProduct?.menuType
        ? {
            value: editingProduct?.menuType.id,
            label: editingProduct?.menuType.name,
          }
        : selectedMenuType
        ? menuOptions.find((o) => o.label === selectedMenuType)
        : menuOptions[0]
    );
  }, [menuOptions, selectedMenuType, editingProduct]);
  const serviceOptions = useMemo(
    () =>
      taxonomies[`INTANGIBLE_BEAUTY_SERVICE?objectId=${menuType?.value}`]?.map(
        (value) => ({
          value: value.id,
          label: value.name,
        })
      ) ?? [],
    [menuType, taxonomies]
  );
  const [serviceType, setServiceType] = useState<DropdownItem | undefined>();
  useEffect(() => {
    setServiceType(
      editingProduct?.serviceType
        ? {
            value: editingProduct?.serviceType.id,
            label: editingProduct?.serviceType.name,
          }
        : selectedServiceType
        ? serviceOptions.find((o) => o.label === selectedServiceType)
        : serviceOptions[0]
    );
  }, [serviceOptions, selectedServiceType, editingProduct]);
  const durationOptions = useMemo(
    () => [
      { value: 0, label: "0분" },
      { value: 30, label: "30분" },
      { value: 60, label: "1시간" },
      { value: 90, label: "1시간 30분" },
      { value: 120, label: "2시간" },
      { value: 150, label: "2시간 30분" },
      { value: 180, label: "3시간" },
    ],
    []
  );
  const [duration, setDuration] = useState<number | undefined>(
    editingProduct?.duration ?? durationOptions[0].value
  );
  const isChanged = useCallback(() => {
    if (editingProduct) {
      return (
        serviceName !== editingProduct.name ||
        price !== editingProduct.price.toString() ||
        description !== editingProduct.description ||
        serviceType?.value !== editingProduct.serviceType?.id ||
        menuType?.value !== editingProduct.menuType?.id ||
        duration !== editingProduct.duration
      );
    } else {
      return (
        !!serviceName &&
        (menuType?.value === 200 || (!!serviceType && !!menuType)) &&
        _.isNumber(duration)
      );
    }
  }, [
    serviceName,
    price,
    description,
    serviceType,
    menuType,
    duration,
    editingProduct,
  ]);
  return (
    <VFlex f-1 a-st p-24-t rel ovf-h>
      <HFlex m-32-rl>
        <VFlex f-1 />
        <Image
          size={24}
          src={`/icons/popup_close.png`}
          clickable
          onClick={() =>
            isChanged()
              ? pushPopup({
                  kind: PopupKind.Popup,
                  align: PopupAlign.TopCenter,
                  element: ConfirmPopup,
                  props: {
                    title: "변경사항을 저장하지 않고 닫을까요?",
                    onConfirm: () => {
                      popPopup();
                    },
                  },
                })
              : popPopup!()
          }
        />
      </HFlex>
      <Text m-24-b p-32-rl t-20-700-s9 l-m>
        {editingProduct
          ? `[${editingProduct.serviceType.name}] ${editingProduct.name}`
          : "메뉴 등록"}
      </Text>
      <VFlex g-16 p-32-rl p-16-t ovf-a>
        <Dropdown
          value={menuType?.value}
          options={menuOptions}
          caption={"메뉴 종류"}
          required={true}
          onChange={(value) => {
            setMenuType(value);
            setServiceType(undefined);
          }}
        />
        {menuType && [198, 199].includes(menuType.value) && (
          <Dropdown
            value={serviceType?.value}
            options={serviceOptions}
            caption={"서비스 종류"}
            required={true}
            onChange={(value) => setServiceType(value)}
          />
        )}
        <TextInput
          required={true}
          caption={"서비스 명"}
          placeholder={"서비스 명"}
          value={serviceName}
          onChangeValue={(value) => setServiceName(value)}
        />
        <Dropdown
          required={true}
          value={duration}
          options={durationOptions}
          caption={"소요 시간"}
          onChange={(value) => setDuration(value?.value)}
        />
        <TextInput
          isNumber={true}
          caption={"가격"}
          placeholder={"가격"}
          value={price}
          onChangeValue={(value) => setPrice(value)}
        />
        <TextArea
          caption={"메뉴 설명"}
          value={description}
          onChangeValue={(v) => {
            if (v?.length || 0 <= 800) setDescription(v as string);
          }}
          placeholder={"메뉴 설명"}
          height={104}
          maxLength={800}
        />
      </VFlex>
      <VFlex f-1 />
      <HFlex g-8 m-24-t p-32-rl height={86} bd-t-t3 a-c>
        <VFlex f-1 />
        {!!editingProduct && (
          <Button
            type="warning"
            onClick={() => {
              trackClickDeleteServiceMenu("detail");
              pushPopup({
                kind: PopupKind.Popup,
                align: PopupAlign.TopCenter,
                element: ConfirmPopup,
                props: {
                  title: "정말 삭제할까요?",
                  confirmButtonLabel: `[${editingProduct.serviceType.name}] ${editingProduct.name} 삭제하기`,
                  confirmButtonType: "warning",
                  onConfirm: async () => {
                    await api.delete(`/rest/product/${editingProduct.id}`);
                    fetchProductGroups();
                    trackDeleteServiceMenu("detail");
                    popPopup!();
                  },
                },
              });
            }}
            caption="삭제"
          />
        )}
        <Button
          type="cancel"
          onClick={() => {
            isChanged()
              ? pushPopup({
                  kind: PopupKind.Popup,
                  align: PopupAlign.TopCenter,
                  element: ConfirmPopup,
                  props: {
                    title: "변경사항을 저장하지 않고 닫을까요?",
                    onConfirm: () => {
                      popPopup();
                    },
                  },
                })
              : popPopup!();
          }}
          caption="취소"
        />
        <Button
          type="confirm"
          enabled={isChanged()}
          onClick={async () => {
            if (!isChanged()) {
              return;
            }
            if (editingProduct) {
              await api.put(`/rest/group/${partner.id}/product/intangible`, {
                id: editingProduct.id,
                description,
                duration,
                menuTypeId: menuType!.value,
                name: serviceName,
                price: price
                  ? parseInt(price!.replaceAll(/,/g, ""))
                  : undefined,
                serviceTypeId: serviceType!.value,
              });
              trackSaveServiceMenu();
            } else {
              await api.post(`/rest/group/${partner.id}/product/intangible`, {
                description,
                duration,
                menuTypeId: menuType!.value,
                name: serviceName,
                price: price
                  ? parseInt(price!.replaceAll(/,/g, ""))
                  : undefined,
                serviceTypeId: serviceType!.value,
              });
              trackSaveNewServiceMenu();
            }
            fetchProductGroups();
            toast.success(
              editingProduct
                ? "메뉴가 수정되었습니다."
                : "메뉴가 추가되었습니다."
            );
            popPopup();
          }}
          caption="저장"
        />
      </HFlex>
    </VFlex>
  );
}

export default AddMenu;
