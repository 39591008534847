import { useMemo } from "react";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import api from "../common/api";
import { usePartner } from "./partner";
import { InquiryListType, Partner } from "./types";

const inquiriesStore = create(
  immer<{
    inquiriesCount: number;
    fetchInquiries: (
      partner: Partner
    ) => ({ page }: { page: number }) => Promise<InquiryListType[]>;
  }>((set) => ({
    inquiriesCount: 0,
    fetchInquiries:
      (partner: Partner) =>
      async ({ page }: { page: number }) => {
        const { totalCount, data } = await api.get(
          `/rest/v3/group/${
            partner!.id
          }/reservation/inquiries?platform=PARTNER_WEB&size=10&page=${
            10 * page
          }`
        );
        set((state) => ({
          ...state,
          inquiriesCount: totalCount,
        }));

        return data;
      },
  }))
);

export const useInquiries = () => {
  const { partner } = usePartner();
  const { fetchInquiries, ...rest } = inquiriesStore();
  return {
    fetchInquiries: useMemo(
      () => fetchInquiries(partner),
      [fetchInquiries, partner]
    ),
    ...rest,
  };
};
