import { MouseEvent, useEffect, useRef, useState } from "react";

import api from "../../../../common/api";
import { Note } from "../../../../providers/types";

import Divider from "../../../../layouts/Divider";
import HFlex from "../../../../layouts/HFlex";
import VFlex from "../../../../layouts/VFlex";
import Text from "../../../../layouts/Text";
import Image from "../../../../layouts/Image";
import Absolute from "../../../../layouts/Absolute";
import FilterChip from "../../../../layouts/FilterChip";
import Dropdown from "../../../../layouts/DropdownV2";

import ServiceSummary from "../../../../components/Customers/ServiceSummary";

import { format } from "date-fns";
import { ko } from "date-fns/locale";
import LoadingView from "../../../../layouts/LoadingView";

export interface ReservationHistoryItem {
  id: number;
  status: number;
  date: string;
  reservationDate: number;
  styleNotes: Note[];
  conditionNotes: Note[];
  productName: string;
  otherNotes: Note[];
  amount: number;
}

export interface ReseravtionHistoryOption {
  value: number;
  label: string;
  sortOrder: number;
}

const ServiceHistory = ({
  petId,
  groupId,
  reservationId,
}: {
  petId: number;
  groupId: number;
  reservationId?: number;
}) => {
  const [selectedYear, setSelectedYear] = useState<number>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [reservationHistory, setReservationHistory] = useState<
    ReservationHistoryItem[]
  >([]);
  const [selectOptions, setSelectOptions] = useState<
    ReseravtionHistoryOption[]
  >([]);
  const [shownYearDropdown, setShownYearDropdown] = useState(false);

  const [pageNum, setPageNum] = useState(0);
  const [hasNext, setHasNext] = useState(true);
  const [loading, setLoading] = useState(false);
  const [lastElement, setLastElement] = useState<HTMLDivElement | null>(null);

  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        setPageNum((no) => no + 1);
      }
    })
  );

  const fetchReservationHistory = async () => {
    if (loading || !hasNext) return;

    try {
      setLoading(true);
      const { data, totalCount, selectOptions } = await api.get<{
        totalCount: number;
        data: ReservationHistoryItem[];
        selectOptions: ReseravtionHistoryOption[];
      }>(
        `/rest/v3/reservation/pet/${petId}/history?groupId=${groupId}&type=RESV&size=5&page=${
          pageNum * 5
        }${selectedYear ? `&startDate=${selectedYear}` : ""}${
          reservationId ? `&reservationId=${reservationId}` : ""
        }`
      );
      setReservationHistory((prev) =>
        pageNum === 0 ? data : [...prev, ...data]
      );
      setTotalCount(totalCount);
      setSelectOptions(selectOptions);
      setHasNext(pageNum === 0 || data.length !== 0);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReservationHistory();
  }, [pageNum]);

  useEffect(() => {
    setHasNext(true);
    setReservationHistory([]);
    if (pageNum === 0) {
      fetchReservationHistory();
    } else {
      setPageNum(0);
    }
  }, [selectedYear]);

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  return (
    <>
      <HFlex height={56} p-16-tb p-24-rl a-c j-b bc-w rel>
        <FilterChip
          onClick={() => {
            setShownYearDropdown(true);
          }}
          suffixIcon="/icons/navigation/arrow_drop_down.svg"
          suffixIconSize={18}
        >
          {selectedYear
            ? format(selectedYear * 1000, "yyyy년", { locale: ko })
            : "전체"}
        </FilterChip>
        {shownYearDropdown && (
          <Absolute
            top={50}
            right={"auto"}
            bottom={"auto"}
            left={24}
            style={{ zIndex: 2 }}
          >
            <Dropdown width={180}>
              {selectOptions.map((opt, i) => {
                return (
                  <Dropdown.Item key={i}>
                    <Text
                      f-1
                      t-14-500-s8
                      onClick={() => {
                        setShownYearDropdown(false);
                        setSelectedYear(opt.value);
                      }}
                    >
                      {opt.label}
                    </Text>
                    {opt.value === selectedYear && (
                      <Image src="/icons/action/check_g1.svg" size={20} />
                    )}
                  </Dropdown.Item>
                );
              })}
            </Dropdown>
          </Absolute>
        )}
        {shownYearDropdown && (
          <Absolute
            onClick={(e: MouseEvent) => {
              setShownYearDropdown(false);
              e.stopPropagation();
            }}
            style={{ zIndex: 1 }}
          />
        )}
        <Text t-14-600-s8>총 {totalCount}건</Text>
      </HFlex>
      <Divider height={1} />
      <VFlex p-24 f-1 ovf-a rel>
        {
          <LoadingView loading={reservationHistory.length === 0 && loading} f-1>
            <VFlex g-12>
              {reservationHistory.length > 0 ? (
                <>
                  {reservationHistory.map((history, i) => (
                    <ServiceSummary
                      key={i}
                      petId={petId}
                      serviceSummary={history}
                      loadSchedule={fetchReservationHistory}
                    />
                  ))}
                  {!loading && hasNext && <VFlex ref={setLastElement} />}
                </>
              ) : (
                <Absolute p-24>
                  <VFlex height={300} bc-tw c-c bdr-16>
                    <Image src="/icons/icon_info_s3.svg" size={24} m-8-b />
                    <Text t-14-500-s4>예약 이력이 없습니다.</Text>
                  </VFlex>
                </Absolute>
              )}
            </VFlex>
          </LoadingView>
        }
      </VFlex>
    </>
  );
};

export default ServiceHistory;
