import React, { ReactElement, useEffect, useMemo, useState } from "react";

import SidebarItem from "./SidebarItem";
import NotificationPopup from "./NotificationPopup";

import VFlex from "../layouts/VFlex";
import Flex from "../layouts/Flex";
import Image from "../layouts/Image";
import Divider from "../layouts/Divider";

import { pushPopup } from "../providers/popups";
import { PopupKind } from "../popup/Popup";
import { useStaffs } from "../providers/staffs";
import { usePartner } from "../providers/partner";
import { usePreference } from "../providers/preference";
import { useAmplitudeTrack } from "../common/useAmplitudeTrack";
import { useNavigate } from "react-router-dom";
import SidebarBanner from "./SidebarBanner";

export type SidebarItemType = {
  key: string;
  icon?: string;
  name: string;
  beta?: boolean;
  component?: (...args: any[]) => ReactElement;
};

type SidebarContextInterface = {
  selectedItem: SidebarItemType;
  setSelectedItem: React.Dispatch<React.SetStateAction<SidebarItemType>>;
  onChange: ((item: SidebarItemType) => void) | undefined;
  sidebarCollapsed: boolean;
};

export const SidebarContext = React.createContext<
  SidebarContextInterface | undefined
>(undefined);

function Sidebar({
  id,
  logo,
  value,
  items,
  onChange,
}: {
  id: string;
  logo: boolean;
  collapsable?: boolean;
  value: SidebarItemType;
  items: SidebarItemType[];
  onChange?: (item: SidebarItemType) => void;
}) {
  const { trackClickLogout } = useAmplitudeTrack();
  const navigate = useNavigate();
  const { sidebarCollapsed, setSidebarCollapsed } = usePreference();
  const { logout, partner } = usePartner();
  const [selectedItem, setSelectedItem] = useState<SidebarItemType>(value);
  const context = useMemo(
    () => ({ selectedItem, setSelectedItem, onChange, sidebarCollapsed }),
    [selectedItem, setSelectedItem, onChange, sidebarCollapsed]
  );
  useEffect(() => {
    setSelectedItem(value);
  }, [value]);
  return (
    <SidebarContext.Provider value={context}>
      <VFlex anim-1 width={sidebarCollapsed ? 72 : 240} bc-t1>
        {logo && (
          <Flex
            vertical={sidebarCollapsed}
            horizontal={!sidebarCollapsed}
            height={sidebarCollapsed ? 132 : 96}
            a-c
            j-c={sidebarCollapsed}
          >
            {sidebarCollapsed ? (
              <Image
                size={24}
                src={`/icons/logo.png`}
                clickable
                onClick={() => {
                  navigate("/main/reservation");
                }}
              />
            ) : (
              <Image
                width={118}
                height={24}
                m-24-rl
                src={`/icons/logo_partners_small.png`}
                clickable
                onClick={() => {
                  navigate("/main/reservation");
                }}
              />
            )}
            <Image
              size={24}
              m-24-rl
              src={`/icons/icon_collapse.svg`}
              style={{
                marginTop: sidebarCollapsed ? 24 : 0,
                marginBottom: sidebarCollapsed ? -24 : 0,
              }}
              clickable
              onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
            />
          </Flex>
        )}
        {/* TODO */}
        {/* <VFlex m-24>
          {sidebarCollapsed ? (
            selfStaff?.attachment?.path ? (
              <Image bdr-8 size={24} src={selfStaff?.attachment?.path} />
            ) : (
              <Image bdr-8 size={24} src={'/icons/logo.png'} />
            )
          ) : (
            <HFlex
              p-8
              bdr-8
              bc-w
              a-c
              g-16
              style={{ border: "solid 1px #E7E8E8" }}
            >
              <Image src={selfStaff?.attachment?.path} size={24} bdr-8 />
              <Text t-14-700 style={{ color: "#596371" }}>
                {selfStaff?.name}
              </Text>
            </HFlex>
          )}
        </VFlex> */}
        <VFlex p-16-tb>
          <SidebarItem
            item={{ key: "notification", name: "알림", icon: "notification" }}
            onSelect={() => {
              pushPopup({
                kind: PopupKind.Drawer,
                element: NotificationPopup,
                width: 400,
              });
            }}
          />
        </VFlex>
        <Divider m-16-rl height={1} />
        <VFlex g-4 p-16-tb>
          {items.map((item, i) => (
            <SidebarItem key={i} item={item} />
          ))}
        </VFlex>
        <Divider m-16-rl height={1} />
        <VFlex p-16-t>
          <SidebarItem
            item={{ key: "logout", name: "로그아웃", icon: "logout" }}
            onSelect={() => {
              trackClickLogout(partner.title);
              logout();
            }}
          />
        </VFlex>

        {!sidebarCollapsed && <SidebarBanner />}
      </VFlex>
    </SidebarContext.Provider>
  );
}

export default Sidebar;
