import { PropsWithChildren, ReactElement, useEffect, useState } from "react";

import HFlex from "../layouts/HFlex";
import VFlex from "../layouts/VFlex";

interface ToggleProps extends PropsWithChildren {
  size?: "S" | "L";
  type?: "default" | "disabled";
  value: boolean;
  onChange?: (value: boolean) => void;
}

function ToggleV2({
  size = "S",
  type = "default",
  value,
  onChange,
}: ToggleProps): ReactElement {
  const [innerValue, setInnerValue] = useState<boolean>(value);

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  return (
    <HFlex
      f-1
      a-c
      clickable
      onClick={() => {
        if (type === "disabled") {
          return;
        }
        onChange?.(!value);
      }}
    >
      <HFlex
        a-c
        rel
        bc-g1={innerValue && type === "default"}
        bc-t3={!innerValue && type === "default"}
        bc-t8={type === "disabled"}
        bdr-16
        width={size === "S" ? 36 : 46}
        height={size === "S" ? 20 : 28}
      >
        <VFlex
          width={size === "S" ? 16 : 24}
          height={size === "S" ? 16 : 24}
          bc-w
          bdr-16={size === "S"}
          bdr-24={size === "L"}
          abs
          anim-1
          style={{
            position: "absolute",
            left: innerValue ? "18px" : "2px",
            zIndex: 1,
          }}
        />
      </HFlex>
    </HFlex>
  );
}

export default ToggleV2;
