import React from "react";
import { PropsWithChildren, useMemo, useState } from "react";

import HFlex from "../../../layouts/HFlex";
import VFlex from "../../../layouts/VFlex";
import Text from "../../../layouts/Text";
import Button from "../../../layouts/ButtonV2";
import { PopupKind } from "../../../popup/Popup";
import { pushPopup } from "../../../providers/popups";
import TabBar, { TabItemType } from "../../../components/TabBar";

import CustomerList from "./CustomerList";
import DeletedCustomerList from "./DeletedCustomerList";
import AddCustomer from "./AddCustomer";

import useCustomersContext, {
  CustomersProvider,
} from "../../../providers/customers";
import { DeletedCustomersProvider } from "../../../providers/deletedCustomers";

const CombinedProvider = ({ children }: PropsWithChildren) => {
  return (
    <CustomersProvider>
      <DeletedCustomersProvider>{children}</DeletedCustomersProvider>
    </CustomersProvider>
  );
};

function Customers() {
  const tabs = useMemo<TabItemType[]>(
    () => [
      {
        key: "1",
        name: "전체 고객",
        component: () => <CustomerList />,
      },
      {
        key: "2",
        name: "삭제된 고객",
        component: () => <DeletedCustomerList />,
      },
    ],
    []
  );
  const [selectedTab, setSelectedTab] = useState<TabItemType>(tabs[0]);
  const { fetchCurrentPage } = useCustomersContext();

  return (
    <VFlex ovf-h height={"100%"}>
      <HFlex p-24 p-16-b j-b>
        <Text t-24-600-s8>고객</Text>
        <Button
          type="filled"
          icon={"/icons/icon_add.png"}
          iconSize={18}
          onClick={() => {
            pushPopup({
              kind: PopupKind.Popup,
              element: AddCustomer,
              width: 1024,
              props: {
                fetchCurrentPage,
              },
              dimClick: "none",
            });
          }}
        >
          새로운 고객
        </Button>
      </HFlex>

      <VFlex height={50} sized bd-b-t3={true} p-12-t p-24-rl>
        <TabBar value={selectedTab} items={tabs} onChange={setSelectedTab} />
      </VFlex>

      <VFlex f-1 a-st ovf-a>
        {selectedTab && React.createElement(selectedTab.component)}
      </VFlex>
    </VFlex>
  );
}

const CustomersWithProvider = () => {
  return (
    <CombinedProvider>
      <Customers />
    </CombinedProvider>
  );
};

export default CustomersWithProvider;
