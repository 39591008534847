import { useCallback, useState } from "react";
import Dropdown from "../../../components/Dropdown";
import RadioButton from "../../../components/RadioButton";
import api from "../../../common/api";
import { repeatTypes } from "./Holidays";
import {
  dayOfWeek,
  days,
  mondayFirstDays,
  ordinals,
} from "../../../common/constants";
import _ from "lodash";
import { RepeatType } from "../../../providers/types";
import { toast } from "react-hot-toast";
import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Button from "../../../layouts/Button";
import Text from "../../../layouts/Text";
import Image from "../../../layouts/Image";
import { usePartner } from "../../../providers/partner";
import { useBusinessHours } from "../../../providers/businessHours";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";

function AddRegularHolidays({ popPopup }: { popPopup: () => void }) {
  const { trackSaveRegularHolidays } = useAmplitudeTrack();
  const { partner } = usePartner();
  const { fetchBusinessHolidays } = useBusinessHours();
  const [frequency, setFrequency] = useState<string>("매주");
  const [selectedWeekOrdinal, setSelectedWeekOrdinal] = useState<string[]>([]);
  const [selectedDays, setSelectedDays] = useState<string[]>([]);
  const [sending, setSending] = useState(false);
  const isChanged = useCallback(() => {
    if (frequency === "매주" && !_.isEmpty(selectedDays)) {
      return true;
    } else if (
      frequency === "매월" &&
      !_.isEmpty(selectedWeekOrdinal) &&
      !_.isEmpty(selectedDays)
    ) {
      return true;
    }
    return false;
  }, [selectedDays, selectedWeekOrdinal, frequency]);
  return (
    <VFlex f-1 as-st p-24-t>
      <VFlex g-16 p-32-rl f-1>
        <VFlex>
          <HFlex>
            <VFlex f-1 />
            <Image
              size={24}
              src={`/icons/popup_close.png`}
              clickable
              onClick={() => popPopup()}
            />
          </HFlex>
          <Text m-24-b t-20-700-s9>
            정기 휴무일 추가
          </Text>
        </VFlex>
        <VFlex g-8>
          <HFlex g-8>
            <Text t-14-500-s4>정기 휴무일 주기</Text>
            <Text t-14-500-rd1>*</Text>
          </HFlex>
          <HFlex a-c g-16>
            <VFlex width={160} height={56}>
              <Dropdown
                value={frequency}
                options={["매주", "매월"].map((value) => ({
                  value,
                  label: value,
                }))}
                onChange={(value) => {
                  setFrequency(value?.value);
                }}
              />
            </VFlex>
            {frequency === "매월" && (
              <VFlex height={56}>
                <RadioButton
                  options={ordinals}
                  isConnected={true}
                  isRound={true}
                  onChange={(values) => {
                    setSelectedWeekOrdinal(values as string[]);
                  }}
                />
              </VFlex>
            )}
          </HFlex>
        </VFlex>
        <RadioButton
          options={mondayFirstDays}
          isSmallOption={true}
          //multiSelect={true}
          //onChange={(values) => setSelectedDays(values as string[])}
          onChange={(values) => {
            setSelectedDays(values as string[]);
          }}
        />
      </VFlex>
      <VFlex f-1 />
      <HFlex a-c p-32-tb g-8 p-32-rl height={86} bd-t-t3>
        <VFlex f-1 />
        <Button type="cancel" onClick={() => popPopup()} caption="취소" />
        <Button
          type="confirm"
          enabled={isChanged()}
          onClick={async () => {
            if (!isChanged()) {
              return;
            }
            setSending(true);
            const repeatType =
              frequency === "매월"
                ? repeatTypes[
                    ordinals.findIndex(
                      (ordinal) => ordinal === selectedWeekOrdinal[0]
                    )
                  ]
                : RepeatType.EVERY_WEEK;
            await api.post(`/rest/group/${partner.id}/business-holiday`, [
              {
                dayOfWeek:
                  dayOfWeek[
                    days.findIndex((ordinal) => ordinal === selectedDays[0])
                  ],
                ...(repeatType ? { repeatType } : {}),
              },
            ]);
            await fetchBusinessHolidays();
            popPopup();
            toast.success("성공적으로 저장되었습니다.");
            setSending(false);
            trackSaveRegularHolidays(partner.title);
          }}
        >
          <HFlex g-8 a-c>
            <Image
              size={24}
              src={`/icons/checkbox_check.png`}
              clickable
              onClick={() => {
                popPopup();
              }}
            />
            <Text t-14-700-w>{sending ? "..." : "정기 휴무일 등록하기"}</Text>
          </HFlex>
        </Button>
      </HFlex>
    </VFlex>
  );
}

export default AddRegularHolidays;
