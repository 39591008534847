import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { ViewType } from "./types";

export const viewTypeStore = create(
  persist<ViewType>(
    (set, get) => ({
      viewType: "GRID",
      setViewType: (type: string) => set({ viewType: type }),
    }),
    {
      name: "viewType",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export const useViewType = () => {
  const { ...rest } = viewTypeStore();
  return {
    ...rest,
  };
};
