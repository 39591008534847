import { InputHTMLAttributes, useRef, useState } from "react";

import Absolute from "./Absolute";
import HFlex from "./HFlex";
import Text from "./Text";
import VFlex from "./VFlex";
import Image from "./Image";

function tryParseLocalize(str: string) {
  if (/^[0-9.,]+$/.test(str)) {
    try {
      str = str.slice(0, 20).replaceAll(",", "");
      if (str.endsWith(".")) {
        const i = str.indexOf(".");
        return parseFloat(str).toLocaleString() + (i === -1 ? "" : ".");
      }
      return parseFloat(str).toLocaleString();
    } catch {}
  }
  return str;
}

function TextInput({
  pwd,
  type,
  placeholder,
  isNumber,
  caption,
  required,
  disabled,
  error,
  value,
  noneIcon,
  bgColor,
  height,
  onChangeValue,
  ...props
}: InputHTMLAttributes<HTMLInputElement> & {
  type?: string;
  pwd?: boolean;
  placeholder?: string;
  caption?: string;
  isNumber?: boolean;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  value?: string;
  noneIcon?: boolean;
  bgColor?: string;
  height?: number | string;
  onChangeValue?: (value: string | undefined) => void;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [focused, setFocused] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <VFlex
      g-8
      unclickable={disabled}
      clickable={!disabled}
      style={{ cursor: "text" }}
    >
      {caption && (
        <HFlex g-4>
          <Text t-14-s8>{caption}</Text>
          {required && <Text t-14-700-rd1>*</Text>}
        </HFlex>
      )}
      <VFlex
        bc-w
        bd-t3={!error && !focused}
        bd-gr5={!error && focused}
        bd-err1={error}
        rel
        bdr-16
        height={height ? height : 56}
        onClick={() => {
          setFocused(true);
          inputRef.current?.focus();
        }}
      >
        <Absolute
          style={{
            background: bgColor ? bgColor : "",
          }}
        >
          <HFlex a-c p-24-rl height={"100%"}>
            <input
              type={pwd && showPassword ? "text" : pwd ? "password" : type}
              ref={inputRef}
              placeholder={placeholder}
              style={{
                width: pwd && !!value && !noneIcon ? "80%" : "95%",
                backgroundColor: "transparent",
                borderWidth: 0,
                padding: 0,
                height: 22,
                fontSize: 16,
              }}
              onKeyDown={(e) => {
                if (
                  isNumber &&
                  !(
                    e.key === "Tab" ||
                    e.key === "Backspace" ||
                    /[0-9.]/.test(e.key)
                  )
                ) {
                  e.preventDefault();
                }
              }}
              value={isNumber ? tryParseLocalize(value ?? "") : value ?? ""}
              onChange={(e) =>
                onChangeValue?.(
                  (isNumber
                    ? tryParseLocalize(e.target.value)
                    : e.target.value) || undefined
                )
              }
              {...props}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
            />
            {pwd && (
              <Image
                clickable
                m-16-r={!!value}
                src={`/icons/action/visibility_${
                  !showPassword ? "off_" : ""
                }t8.svg`}
                onClick={() => {
                  setShowPassword?.((prev) => !prev);
                }}
              />
            )}
            {!!value && !noneIcon && !disabled && (
              <Image
                size={24}
                src={`/icons/navigation/cancel_t8.svg`}
                clickable
                onClick={(e) => {
                  onChangeValue?.("");
                  e.stopPropagation();
                }}
              />
            )}
          </HFlex>
        </Absolute>
      </VFlex>
    </VFlex>
  );
}

export default TextInput;
