import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import api from "../../../../common/api";
import Spinner from "../../../../common/Spinner/Spinner";

import { pushPopup } from "../../../../providers/popups";
import { usePartner } from "../../../../providers/partner";
import {
  Attachment,
  PetInfo,
  Guardian,
  Tag,
  Reservation,
  ScheduleStatus,
  NoticeInterface,
  Note,
} from "../../../../providers/types";
import { useSchedules } from "../../../../providers/schedules";
import { useCount } from "../../../../providers/count";

import HFlex from "../../../../layouts/HFlex";
import VFlex from "../../../../layouts/VFlex";
import Text from "../../../../layouts/Text";
import Image from "../../../../layouts/Image";
import Divider from "../../../../layouts/Divider";
import Flex from "../../../../layouts/Flex";
import Absolute from "../../../../layouts/Absolute";

import ConfirmPopup from "../../../../popup/ConfirmPopup";
import { PopupKind, PopupAlign } from "../../../../popup/Popup";
import CancelSchedulePopup from "../../../../popup/ConfirmPopup/CancelSchedulePopup";

import TabBar from "../../../../components/TabBar";
import PetProfile from "../../../../components/PetProfile";
import Tooltip from "../../../../components/Tooltip";
import ReservationInfo from "./ReservationInfo";
import ReservationMemo from "./ReservationMemo";
import ServiceHistory from "./ServiceHistory";
import ServiceNote from "./ServiceNote";
import Questionnaire from "./Questionnaire";
import SendNotice from "../SendNotice";
import NotitalkHistory from "./NotitalkHistory";

import { parseISO } from "date-fns";
import toast from "react-hot-toast";
import { isNil } from "lodash";
import { useAmplitudeTrack } from "../../../../common/useAmplitudeTrack";

const DropdownMenu = ({
  isDeleted,
  isHidden,
  reservationId,
  popSchedulePopup,
  fetchCount,
  date,
  setIsShowDropdown,
  popPopup,
  setLoading,
  fetchCurrentPage,
}: {
  isDeleted?: boolean;
  isHidden?: boolean;
  reservationId: number;
  popSchedulePopup: (toastText: string) => Promise<void>;
  fetchCount: ({ date }: { date?: number | undefined }) => void;
  date: Date | undefined;
  setIsShowDropdown: (bool: boolean) => void;
  popPopup: () => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  fetchCurrentPage?: () => void;
}) => {
  const { trackRestoreRes } = useAmplitudeTrack();
  const [isFirstItemHovered, setIsFirstItemHovered] = useState(false);
  const [isSecondItemHovered, setIsSecondItemHovered] = useState(false);

  const hideReservation = async () => {
    pushPopup({
      kind: PopupKind.Popup,
      align: PopupAlign.TopCenter,
      element: ConfirmPopup,
      props: {
        title:
          "예약을 숨길까요?\n숨긴 후에도 숨긴 예약 목록에서 확인 할 수 있습니다.",
        icon: "/icons/icon_success.png",
        confirmButtonLabel: "숨기기",
        confirmButtonType: "confirm",
        onConfirm: async () => {
          setLoading(true);
          popPopup();
          await api.put(
            `/rest/reservation/${reservationId}/delete?type=HIDDEN`
          );
          popSchedulePopup("예약을 숨겼습니다.");
          await fetchCount({
            date: date!.getTime() / 1000,
          });
          if (fetchCurrentPage) fetchCurrentPage();
        },
      },
    });
  };

  const deleteReservation = async () => {
    pushPopup({
      kind: PopupKind.Popup,
      align: PopupAlign.TopCenter,
      element: ConfirmPopup,
      props: {
        title:
          "예약을 삭제 할까요?\n삭제한 후에도 삭제된 예약 목록에서 확인 할 수 있습니다.",
        confirmButtonLabel: "삭제",
        confirmButtonType: "delete",
        onConfirm: async () => {
          setLoading(true);
          popPopup();
          await api.put(
            `/rest/reservation/${reservationId}/delete?type=PNTR_DEL&status=true`
          );
          popSchedulePopup("예약을 삭제했습니다.");
          await fetchCount({
            date: date!.getTime() / 1000,
          });
          if (fetchCurrentPage) fetchCurrentPage();
        },
      },
    });
  };

  const hardDeleteReservation = async () => {
    pushPopup({
      kind: PopupKind.Popup,
      align: PopupAlign.TopCenter,
      element: ConfirmPopup,
      props: {
        title:
          "예약을 영구 삭제 할까요?\n영구 삭제시 정보를 다시 되돌릴 수 없습니다.",
        confirmButtonLabel: "영구 삭제",
        confirmButtonType: "delete",
        onConfirm: async () => {
          // TODO 영구 삭제 api
          // setLoading(true);
          // popPopup();
          // await api.put(
          //   `/rest/reservation/${reservationId}/delete?type=PNTR_DEL&status=true`
          // );
          popSchedulePopup("예약을 영구 삭제했습니다.");
          if (fetchCurrentPage) fetchCurrentPage();
        },
      },
    });
  };

  const recoveryReservation = async () => {
    pushPopup({
      kind: PopupKind.Popup,
      align: PopupAlign.TopCenter,
      element: ConfirmPopup,
      props: {
        title: "예약 목록으로 복구할까요?",
        icon: "/icons/icon_success.png",
        confirmButtonLabel: "복구하기",
        confirmButtonType: "confirm",
        onConfirm: async () => {
          setLoading(true);
          popPopup();
          await api.put(
            `/rest/reservation/${reservationId}/delete?type=${
              isDeleted ? "PNTR_DEL_RESTORE" : "HIDDEN_RESTORE"
            }&status=false`
          );
          trackRestoreRes(isDeleted ? "deleted" : "hidden");
          popSchedulePopup("예약 목록으로 복구되었습니다.");
          if (fetchCurrentPage) fetchCurrentPage();
        },
      },
    });
  };

  return (
    <Absolute style={{ inset: "24px 0px auto auto", zIndex: 2 }}>
      <VFlex width={140} p-8 g-4 bdr-12 bc-w sdw-6>
        <Text
          p-8
          t-14-500-s8
          clickable
          bdr-8
          bc-t2={isFirstItemHovered}
          onMouseEnter={() => setIsFirstItemHovered(true)}
          onMouseLeave={() => setIsFirstItemHovered(false)}
          onClick={() => {
            if (isDeleted || isHidden) {
              recoveryReservation();
            } else {
              hideReservation();
            }
            setIsShowDropdown(false);
          }}
        >
          {isDeleted || isHidden ? "예약 목록으로 복구" : "예약 숨기기"}
        </Text>
        {!isDeleted && (
          <Text
            p-8
            t-14-500-r1
            clickable
            bdr-8
            bc-t2={isSecondItemHovered}
            onMouseEnter={() => setIsSecondItemHovered(true)}
            onMouseLeave={() => setIsSecondItemHovered(false)}
            onClick={() => {
              if (isDeleted) {
                hardDeleteReservation();
              } else {
                deleteReservation();
              }
              setIsShowDropdown(false);
            }}
          >
            예약 삭제
          </Text>
        )}
      </VFlex>
    </Absolute>
  );
};

const ReservationDetail = ({
  reservationId,
  popPopup,
  fetchCurrentPage,
  editingReservation,
}: {
  reservationId: number;
  popPopup: () => void;
  fetchCurrentPage?: () => void;
  editingReservation?: Reservation;
}) => {
  const navigate = useNavigate();
  const { partner } = usePartner();
  const { fetchSchedules } = useSchedules();
  const { fetchCount } = useCount();

  const [loading, setLoading] = useState<boolean>(false);
  const [petProfileImg, setPetProfileImg] = useState<Attachment | undefined>();
  const [petInfo, setPetInfo] = useState<PetInfo | undefined>();
  const [guardianList, setGuardianList] = useState<Guardian[] | undefined>();
  const [tags, setTags] = useState<Tag[] | undefined>();
  const [date, setDate] = useState<Date | undefined>();
  const [status, setStatus] = useState<ScheduleStatus | undefined>();
  const [newPetMemo, setNewPetMemo] = useState<string | undefined>(undefined);
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const [editingSchedule, setEditingSchedule] = useState<Reservation>();
  const [isDeletedReservation, setIsDeletedReservation] =
    useState<boolean>(false);
  const [isHiddenReservation, setIsHiddenReservation] =
    useState<boolean>(false);
  const [notice, setNotice] = useState<NoticeInterface | undefined>();

  const [isHovered, setIsHovered] = useState(false);
  const [isNoticeHovered, setIsNoticeHovered] = useState(false);
  const [isHoveredArr, setIsHoveredArr] = useState(false);
  const [statusVisible, setStatusVisible] = useState(false);
  const [isNoshowHovered, setIsNoshowHovered] = useState(false);
  const [isCancelHovered, setIsCancelHovered] = useState(false);

  const savePetMemo = async () => {
    try {
      if (!petInfo) return;

      await api.put(
        `/rest/v2/pet/${petInfo.id}/single?groupId=${
          partner.id
        }&type=NOTE&value=${
          (newPetMemo && encodeURIComponent(newPetMemo)) || null
        }`
      );
      toast.success("변경사항이 저장되었습니다.");

      setPetInfo({ ...petInfo, note: newPetMemo });
    } catch (e) {}
  };

  const fetchNotice = async () => {
    if (editingSchedule && !isNil(editingSchedule.reservationNumber)) {
      const notice = await api.get(
        `/ext/reservation/notice?key=${editingSchedule.reservationNumber}`
      );

      setNotice(notice);
    }
  };

  useEffect(() => {
    fetchNotice();
  }, [editingSchedule]);

  const [notes, setNotes] = useState<Note[]>([]);
  const fetchNotes = useCallback(async () => {
    const data = await api.get(`/rest/v2/reservation/${reservationId}/note`);
    setNotes(data);
  }, [reservationId]);

  useEffect(() => {
    fetchNotes();
  }, []);

  const fetchReservation = useCallback(async () => {
    const reservation = await api.get<Reservation>(
      `/rest/reservation/${reservationId}?groupId=${partner.id}`
    );
    if (reservation) {
      setDate(parseISO(reservation.date));
      setPetInfo(reservation.pet.petInfo);
      setNewPetMemo(reservation.pet.petInfo?.note);
      setPetProfileImg(reservation.pet.petInfo.attachment);
      setTags(reservation.pet.tags);
      const guardianList = await api.get(
        `/rest/pet/${reservation.petId}/guardian-list?groupId=${partner.id}`
      );
      setGuardianList(guardianList);
      setStatus(reservation.status);
      setEditingSchedule({ ...reservation });
      setLoading(false);
      setIsDeletedReservation(reservation.partnerDeleted);
      setIsHiddenReservation(reservation.hidden);
    }
  }, [partner, reservationId]);

  const isHidable = useMemo(
    () =>
      editingSchedule?.status &&
      [
        ScheduleStatus.PARTNER_CANCEL,
        ScheduleStatus.NO_SHOW,
        ScheduleStatus.COMPLETE,
      ].includes(editingSchedule?.status),
    [editingSchedule]
  );

  const isCompleted = useMemo(
    () =>
      editingSchedule?.status &&
      [ScheduleStatus.COMPLETE].includes(editingSchedule?.status),
    [editingSchedule, status]
  );

  const isConfirm = useMemo(
    () =>
      editingSchedule?.status &&
      [ScheduleStatus.CONFIRM, ScheduleStatus.CONFIRM_VISIT].includes(
        editingSchedule?.status
      ),
    [editingSchedule, status]
  );

  const popSchedulePopup = useCallback(
    async (toastText: string) => {
      await fetchSchedules(date);
      if (reservationId) {
        await fetchReservation();
        if (fetchCurrentPage) fetchCurrentPage();
      }
      toast.success(toastText);
    },
    [fetchSchedules, reservationId, date, fetchCurrentPage]
  );

  useEffect(() => {
    return () => navigate("", { replace: true });
  }, []);

  useEffect(() => {
    if (reservationId) {
      setLoading(true);
      fetchReservation();
    }
  }, [status, isCompleted, isConfirm]);

  const tabs = [
    {
      key: "1",
      name: "예약 정보",
      component: () => (
        <ReservationInfo
          groupId={partner.id}
          reservationId={reservationId}
          setSelectedDetailTab={setSelectedDetailTab}
          tabs={tabs}
          fetchCurrentPage={() => {
            fetchReservation();
            fetchNotes();
          }}
          notes={notes}
          popSchedulePopup={popSchedulePopup}
          editingSchedule={editingSchedule}
        />
      ),
    },
    {
      key: "2",
      name: "예약 메모",
      component: () => (
        <ReservationMemo
          reservationId={reservationId}
          fetchCurrentPage={fetchNotes}
          isDeletedReservation={isDeletedReservation}
        />
      ),
    },
    {
      key: "3",
      name: "알림톡",
      component: () => <NotitalkHistory reservationId={reservationId} />,
    },
    {
      key: "4",
      name: "알림장",
      component: () => (
        <ServiceNote
          status={status!}
          petInfo={petInfo!}
          reservationId={reservationId}
          notice={notice}
          startDateTime={date!}
          popSchedulePopup={popSchedulePopup}
          fetchCurrentPage={() => {
            fetchNotice();
            fetchReservation();
          }}
          editingSchedule={editingSchedule}
        />
      ),
    },
    {
      key: "5",
      name: "안전문진표",
      component: () => (
        <Questionnaire
          petId={petInfo!.id!}
          groupId={partner.id}
          questionnaireId={editingSchedule?.questionnaireId ?? 0}
          petName={editingSchedule?.pet.petInfo.name ?? ""}
        />
      ),
    },
    {
      key: "6",
      name: "예약 목록",
      component: () => (
        <ServiceHistory
          petId={petInfo!.id!}
          groupId={partner.id}
          reservationId={reservationId}
        />
      ),
    },
  ];

  const [selectedDetailTab, setSelectedDetailTab] = useState(0);

  return (
    <VFlex
      a-st
      style={{
        height: "95vh",
        minHeight: "600px",
        maxHeight: "850px",
        maxWidth: "1024px",
        width: "95vw",
      }}
      ovf-h
      bdr-24
    >
      <HFlex p-24 a-c>
        <Text t-22-600-s8>예약 일정 상세</Text>
        <Flex f-1 />
        <HFlex g-24>
          {isHidable && (
            <VFlex rel>
              <Image
                src={"/icons/icon_more_horiz_s6.svg"}
                size={24}
                clickable
                onClick={() => setIsShowDropdown(true)}
              />

              {isShowDropdown && (
                <DropdownMenu
                  isDeleted={isDeletedReservation}
                  isHidden={isHiddenReservation}
                  reservationId={reservationId}
                  popSchedulePopup={popSchedulePopup}
                  fetchCount={fetchCount}
                  date={date}
                  setLoading={setLoading}
                  setIsShowDropdown={setIsShowDropdown}
                  popPopup={popPopup}
                  fetchCurrentPage={fetchCurrentPage}
                />
              )}
            </VFlex>
          )}
          {isShowDropdown && (
            <Absolute
              style={{ zIndex: 1 }}
              onClick={() => setIsShowDropdown(false)}
            />
          )}
          <Image
            src={"/icons/icon_close_s6.svg"}
            size={24}
            clickable
            onClick={() => {
              if (newPetMemo !== petInfo?.note) {
                pushPopup({
                  kind: PopupKind.Popup,
                  key: "confirm_discard_pet_memo",
                  element: ConfirmPopup,
                  align: PopupAlign.TopCenter,
                  props: {
                    title: `반려동물 메모가 저장되지 않았습니다.\n저장하지 않고 나갈까요?`,
                    confirmButtonLabel: "중단하기",
                    confirmButtonType: "delete",
                    onConfirm: () => {
                      popPopup();
                      if (fetchCurrentPage) fetchCurrentPage();
                    },
                  },
                });
              } else {
                popPopup();
                if (fetchCurrentPage) fetchCurrentPage();
              }
            }}
          />
        </HFlex>
      </HFlex>
      <Divider height={1} />

      {loading ? (
        <VFlex c-c f-1>
          <Spinner />
        </VFlex>
      ) : (
        petInfo &&
        guardianList && (
          <HFlex f-1 a-st ovf-h>
            <VFlex width={376} style={{ boxSizing: "border-box" }} sized>
              <VFlex ovf-a>
                <PetProfile
                  petInfo={petInfo}
                  tags={tags}
                  guardianList={guardianList}
                  petProfileImg={petProfileImg}
                  newPetMemo={newPetMemo}
                  setNewPetMemo={setNewPetMemo}
                  savePetMemo={savePetMemo}
                />
              </VFlex>
            </VFlex>
            <Divider width={1} />
            <VFlex f-1 style={{ overflowX: "hidden" }}>
              <Flex height={50} p-24-rl style={{ paddingTop: 15 }}>
                <TabBar
                  value={tabs[selectedDetailTab]}
                  items={tabs}
                  onChange={(item) =>
                    setSelectedDetailTab(
                      tabs.findIndex((t) => t.key === item.key)
                    )
                  }
                />
              </Flex>
              <Divider height={1} />
              <VFlex f-1 bc-t2 ovf-a>
                {tabs[selectedDetailTab]?.component()}
              </VFlex>
            </VFlex>
          </HFlex>
        )
      )}

      {(isCompleted || isConfirm) && (
        <VFlex
          a-e
          p-24-rl
          j-c
          height={104}
          style={{
            boxShadow:
              "0px -1px 2px 0px rgba(0, 0, 0, 0.08), 0px -2px 12px 1px rgba(0, 0, 0, 0.08)",
            zIndex: 1,
          }}
        >
          <VFlex g-16 rel>
            {isCompleted ? (
              <HFlex g-8 rel>
                {!isDeletedReservation && !notice && (
                  <VFlex
                    j-c
                    p-16-rl
                    bd-t5
                    bdr-16
                    onMouseEnter={(e) => {
                      setIsNoticeHovered(true);
                      e.stopPropagation();
                    }}
                    onMouseLeave={(e) => {
                      setIsNoticeHovered(false);
                      e.stopPropagation();
                    }}
                    onClick={async () => {
                      pushPopup({
                        kind: PopupKind.Drawer,
                        element: SendNotice,
                        props: {
                          petName: petInfo?.name,
                          reservationId,
                          loadSchedule: fetchNotice,
                        },
                      });
                    }}
                    clickable
                  >
                    {isNoticeHovered && (
                      <Tooltip
                        type="bc"
                        positionTop={-56}
                        positionLeft={-60}
                        arrowOffsetRight={16}
                        content="알림장 작성하기"
                      />
                    )}
                    <Image p-16-tb size={24} src="/icons/action/book.svg" />
                  </VFlex>
                )}

                {/* <VFlex t-18-600-w bc-s9 p-16-tb p-24-rl bdr-16 clickable>
                  결제 진행하기
                </VFlex> */}
              </HFlex>
            ) : (
              <HFlex a-c bdr-16>
                <VFlex
                  p-16-tb
                  p-24-rl
                  bc-gr5
                  t-18-600-w
                  clickable
                  op-hover
                  onMouseEnter={(e) => {
                    setIsHovered(true);
                    e.stopPropagation();
                  }}
                  onMouseLeave={(e) => {
                    setIsHovered(false);
                    e.stopPropagation();
                  }}
                  onClick={() => {
                    const startDate = new Date(
                      [editingSchedule!.date, editingSchedule!.startTime].join(
                        " "
                      )
                    );
                    const endDate = new Date(
                      [editingSchedule!.date, editingSchedule!.endTime].join(
                        " "
                      )
                    );

                    endDate.setMinutes(endDate.getMinutes() + 30);
                    if (startDate.getTime() > new Date().getTime()) {
                      pushPopup({
                        kind: PopupKind.Popup,
                        align: PopupAlign.TopCenter,
                        element: ConfirmPopup,
                        props: {
                          title:
                            "이용완료 처리는 예약시간 30분 이후부터 가능합니다.",
                          icon: "/icons/icon_good_warning.png",
                          cancelButtonDisplay: "none",
                          confirmButtonLabel: "확인",
                          confirmButtonType: "confirm",
                          onConfirm: () => {},
                        },
                      });
                    } else {
                      pushPopup({
                        kind: PopupKind.Popup,
                        align: PopupAlign.TopCenter,
                        element: ConfirmPopup,
                        props: {
                          title: `이용 완료 처리를 하면\n고객에게 서비스 후 주의사항 메세지가 보내집니다.`,
                          icon: "/icons/icon_good_warning.png",
                          confirmButtonLabel: "이용 완료 변경하기",
                          confirmButtonType: "confirm",
                          onConfirm: async () => {
                            await api.put(
                              `/rest/reservation/${reservationId}/complete?groupId=${partner.id}`
                            );
                            popSchedulePopup("이용완료 처리되었습니다.");
                          },
                        },
                      });
                    }
                  }}
                >
                  이용완료
                </VFlex>
                <span
                  style={{
                    position: "relative",
                    width: 1,
                    height: "100%",
                    background: "#27BD63",
                  }}
                >
                  <span
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: 0,
                      right: 0,
                      margin: "0 auto",
                      width: 1,
                      height: 40,
                      background: "rgba(255,255,255,0.6)",
                      transform: "translateY(-50%)",
                      zIndex: 1,
                    }}
                  ></span>
                </span>
                <VFlex
                  j-c
                  p-16-rl
                  height={58}
                  bc-gr5
                  op-hover
                  clickable
                  onClick={() => {
                    setStatusVisible((prev) => !prev);
                  }}
                  onMouseEnter={(e) => {
                    setIsHoveredArr(true);
                    e.stopPropagation();
                  }}
                  onMouseLeave={(e) => {
                    setIsHoveredArr(false);
                    e.stopPropagation();
                  }}
                >
                  <Image
                    size={24}
                    src={`/icons/navigation/${
                      statusVisible ? "arrow_drop_up" : "arrow_drop_down"
                    }_w.svg`}
                  />
                </VFlex>

                {statusVisible && (
                  <Absolute
                    style={{
                      height: 90,
                      left: "-14px",
                      top: "-95px",
                      zIndex: 2,
                    }}
                  >
                    <VFlex width={180} p-8 g-4 bdr-12 bc-w sdw-6>
                      <Text
                        p-8
                        t-14-500-s8
                        clickable
                        bdr-8
                        bc-t2={isNoshowHovered}
                        onMouseEnter={() => setIsNoshowHovered(true)}
                        onMouseLeave={() => setIsNoshowHovered(false)}
                        onClick={() => {
                          const startDate = new Date(
                            [
                              editingSchedule!.date,
                              editingSchedule!.startTime,
                            ].join(" ")
                          );

                          if (startDate.getTime() > new Date().getTime()) {
                            pushPopup({
                              kind: PopupKind.Popup,
                              align: PopupAlign.TopCenter,
                              element: ConfirmPopup,
                              props: {
                                title:
                                  "노쇼 처리는 예약시간 이후부터 가능합니다.",
                                icon: "/icons/icon_good_warning.png",
                                cancelButtonDisplay: "none",
                                confirmButtonLabel: "확인",
                                confirmButtonType: "confirm",
                                onConfirm: () => {},
                              },
                            });
                          } else {
                            pushPopup({
                              kind: PopupKind.Popup,
                              align: PopupAlign.TopCenter,
                              element: ConfirmPopup,
                              props: {
                                title: `고객이 연락 없이 오지 않았다면, 노쇼 처리가 가능합니다.\n노쇼 처리시, 고객에게 메세지가 전송됩니다.`,
                                icon: "/icons/icon_good_warning.png",
                                confirmButtonLabel:
                                  "노쇼 처리하고 메세지 보내기",
                                confirmButtonType: "confirm",
                                onConfirm: async () => {
                                  await api.put(
                                    `/rest/reservation/${reservationId}/no-show`
                                  );
                                  popSchedulePopup("노쇼 처리되었습니다.");
                                },
                              },
                            });
                          }
                        }}
                      >
                        노쇼 처리
                      </Text>
                      <Text
                        p-8
                        t-14-500-s8
                        clickable
                        bdr-8
                        bc-t2={isCancelHovered}
                        onMouseEnter={() => setIsCancelHovered(true)}
                        onMouseLeave={() => setIsCancelHovered(false)}
                        onClick={() => {
                          pushPopup({
                            kind: PopupKind.Popup,
                            align: PopupAlign.TopCenter,
                            element: CancelSchedulePopup,
                            props: {
                              title:
                                "예약을 취소하면 고객에게 취소에 대한 메세지가 보내집니다.",
                              confirmButtonLabel: "예약 취소하고 메세지 보내기",
                              confirmButtonStyle: "warning",
                              onConfirm: async (reason: any) => {
                                await api.put(
                                  `/rest/reservation/${reservationId}/cancel?accountType=2`,
                                  {
                                    cancelNote: reason,
                                    id: reservationId,
                                    requestNote: "",
                                    type: "",
                                  }
                                );
                                popSchedulePopup("예약이 취소되었습니다.");
                              },
                            },
                          });
                        }}
                      >
                        예약 취소
                      </Text>
                    </VFlex>
                  </Absolute>
                )}

                {isHovered && (
                  <Tooltip
                    type="bc"
                    positionTop={-56}
                    positionLeft={5}
                    arrowOffsetRight={40}
                    content="이용완료 처리"
                  />
                )}

                {isHoveredArr && (
                  <VFlex
                    abs
                    a-c
                    t-14-w
                    p-12
                    bdr-8
                    bc-s8
                    onMouseEnter={() => setIsHoveredArr(true)}
                    onMouseLeave={() => setIsHoveredArr(false)}
                    clickable
                    style={{
                      top: "-56px",
                      right: 0,
                      overflow: "unset",
                    }}
                  >
                    <Text>다른 예약 상태로 변경</Text>
                    <Flex
                      style={{
                        position: "absolute",
                        bottom: "-16px",
                        right: 21,
                        width: 0,
                        height: 0,
                        borderTop: "8px solid #383B3A",
                        borderRight: "8px solid transparent",
                        borderBottom: "8px solid transparent",
                        borderLeft: "8px solid transparent",
                      }}
                    />
                  </VFlex>
                )}
              </HFlex>
            )}
          </VFlex>
          {statusVisible && (
            <Absolute
              onClick={() => {
                setStatusVisible(false);
              }}
              style={{ zIndex: 1 }}
            />
          )}
        </VFlex>
      )}
    </VFlex>
  );
};

export default ReservationDetail;
