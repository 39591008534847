import { useEffect, useMemo, useState } from "react";
import api from "../../common/api";
import Dropdown from "../../components/Dropdown";
import { SignupFooter } from "./Signup";
import Checkbox from "../../components/Checkbox";
import _ from "lodash";
import FileUpload from "../../components/FileUpload";
import LoadingView from "../../layouts/LoadingView";
import { pushPopup, usePopups } from "../../providers/popups";
import Popup, { PopupKind } from "../../popup/Popup";
import VFlex from "../../layouts/VFlex";
import HFlex from "../../layouts/HFlex";
import Image from "../../layouts/Image";
import Text from "../../layouts/Text";
import Button from "../../layouts/Button";
import { usePartner } from "../../providers/partner";
import { useAmplitudeTrack } from "../../common/useAmplitudeTrack";
import useIsMobile from "../../common/useIsMobile";

function BusinessCategoryPopup({
  popPopup,
  businessCategories,
  selectedBusinessCategories,
  setSelectedBusinessCategories,
}: any) {
  return (
    <VFlex f-1 p-24 ovf-a>
      <HFlex as-st a-c>
        <VFlex f-1 />
        <Image
          size={24}
          src={`/icons/popup_close.png`}
          clickable
          onClick={() => {
            popPopup();
          }}
        />
      </HFlex>
      <HFlex a-c height={44}>
        <Text t-22-600-s9>카테고리를 선택해주세요</Text>
      </HFlex>
      <HFlex a-c height={44}>
        <Text t-16-500-s4>중복 선택이 가능합니다</Text>
      </HFlex>
      <VFlex f-1 as-st a-st ovf-a>
        {businessCategories.map((type: any, i: number) => (
          <HFlex a-c as-st height={76} sized key={i}>
            <VFlex f-1>
              <Checkbox
                leftLabel={type.name}
                initialValue={selectedBusinessCategories
                  .map((i: any) => i.id)
                  .includes(type.id)}
                onChange={(value) => {
                  if (value) {
                    setSelectedBusinessCategories((t: any) =>
                      _.uniq([...t, type])
                    );
                  } else {
                    setSelectedBusinessCategories((t: any) =>
                      t.filter((tt: any) => tt.id !== type.id)
                    );
                  }
                }}
              />
            </VFlex>
          </HFlex>
        ))}
      </VFlex>
      <Button
        m-16-t
        type="cta-lg"
        onClick={() => {
          popPopup();
        }}
        caption="저장"
      />
    </VFlex>
  );
}

function BusinessTypePopup({
  popPopup,
  businessTypes,
  selectedBusinessTypes,
  setSelectedBusinessTypes,
}: any) {
  return (
    <VFlex f-1 p-24 ovf-a>
      <HFlex a-c as-st>
        <VFlex f-1 />
        <Image
          size={24}
          src={`/icons/popup_close.png`}
          clickable
          onClick={() => {
            popPopup();
          }}
        />
      </HFlex>
      <HFlex a-c height={44}>
        <Text t-22-600-s9>업종을 선택해주세요</Text>
      </HFlex>
      <HFlex a-c height={44}>
        <Text t-16-500-s4>중복 선택이 가능합니다</Text>
      </HFlex>
      <VFlex f-1 as-st a-st ovf-a>
        {businessTypes.map((type: any, i: number) => (
          <HFlex key={i} a-c as-st height={76} sized>
            <VFlex f-1>
              <Checkbox
                leftLabel={type.name}
                initialValue={selectedBusinessTypes
                  .map((i: any) => i.id)
                  .includes(type.id)}
                onChange={(value) => {
                  if (value) {
                    setSelectedBusinessTypes((t: any) => _.uniq([...t, type]));
                  } else {
                    setSelectedBusinessTypes((t: any) =>
                      t.filter((tt: any) => tt.id !== type.id)
                    );
                  }
                }}
              />
            </VFlex>
          </HFlex>
        ))}
      </VFlex>
      <Button
        m-16-t
        type="cta-lg"
        onClick={() => {
          popPopup();
        }}
        caption="저장"
      />
    </VFlex>
  );
}

function BusinessTypeDropdown({
  businessTypes,
  businessType,
  selectedBusinessTypes,
  setSelectedBusinessTypes,
}: any) {
  return (
    <Dropdown
      isDisabled={true}
      placeholder="업종 선택"
      caption="업종"
      value={businessType?.id}
      options={businessTypes.map((i: any) => ({ value: i.id, label: i.name }))}
      onClick={() => {
        pushPopup({
          kind: PopupKind.Popup,
          element: BusinessTypePopup,
          width: 360,
          props: {
            businessTypes,
            selectedBusinessTypes,
            setSelectedBusinessTypes,
          },
        });
      }}
    />
  );
}

function BusinessCategoryDropdown({
  businessCategories,
  selectedBusinessCategories,
  setSelectedBusinessCategories,
}: any) {
  return (
    <Dropdown
      isDisabled={true}
      placeholder="카테고리 선택"
      caption="카테고리"
      value={selectedBusinessCategories.length > 0 ? 0 : undefined}
      options={[
        {
          value: 0,
          label: selectedBusinessCategories.map((c: any) => c.name).join(", "),
        },
      ]}
      onClick={() => {
        pushPopup({
          kind: PopupKind.Popup,
          element: BusinessCategoryPopup,
          props: {
            businessCategories,
            selectedBusinessCategories,
            setSelectedBusinessCategories,
          },
        });
      }}
    />
  );
}

function SignupCompanyDetails() {
  const { trackSetBizSector } = useAmplitudeTrack();
  const { popups } = usePopups();
  const { partner, fetchPartner } = usePartner();
  const isMobile = useIsMobile();
  useEffect(() => {
    if (isMobile) {
      window.location.href = "https://pawdlypartners.page.link/c6wA";
      return;
    }
    fetchPartner();
  }, [fetchPartner]);
  const [businessTypes, setBusinessTypes] = useState([]);
  const [selectedBusinessTypes, setSelectedBusinessTypes] = useState<any[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  useEffect(() => {
    setFiles(new Array(selectedBusinessTypes.length));
  }, [selectedBusinessTypes]);
  const [selectedBusinessCategories, setSelectedBusinessCategories] = useState<
    any[]
  >([]);
  const [businessCategories, setBusinessCategories] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    (async () => {
      const businessTypes = await api.get(`/rest/taxonomy/business_license`);
      setBusinessTypes(businessTypes ?? []);
    })();
  }, []);
  useEffect(() => {
    (async () => {
      if (selectedBusinessTypes) {
        const categories: any[] = [];
        for (const businessType of selectedBusinessTypes) {
          const businessCategories = await api.get(
            `/rest/taxonomy/business/categories?businessLicenseId=${businessType.id}`
          );
          categories.push(...businessCategories);
        }
        setBusinessCategories(_.uniqBy(categories, "id"));
      }
    })();
  }, [selectedBusinessTypes]);
  const ready = useMemo(
    () =>
      selectedBusinessCategories.length > 0 &&
      selectedBusinessTypes.length > 0 &&
      _.every(files.map((f: any) => !!f)),
    [selectedBusinessCategories, selectedBusinessTypes, files]
  );
  return (
    <LoadingView f-1 a-c bc-w ovf-a loading={loading}>
      <VFlex a-st g-24 p-24-rl width={428} style={{ marginTop: 150 }}>
        <VFlex a-st g-24 style={{ marginBottom: 100 }}>
          <VFlex a-c g-8>
            <HFlex a-c>
              <Text t-32-600-s9>
                <Text t-32-600-gr5>업체 정보를 </Text>입력해주세요
              </Text>
            </HFlex>
            <Text t-16-500-s3>꼭 필요한 정보만 받을게요.</Text>
            <VFlex a-st f-1 as-st m-24-trl g-16>
              {selectedBusinessTypes.length === 0 ? (
                <BusinessTypeDropdown
                  businessTypes={businessTypes}
                  selectedBusinessTypes={selectedBusinessTypes}
                  setSelectedBusinessTypes={setSelectedBusinessTypes}
                />
              ) : (
                <VFlex>
                  {selectedBusinessTypes.map((type, i) => (
                    <VFlex key={i} m-16-t>
                      <BusinessTypeDropdown
                        businessTypes={businessTypes}
                        businessType={type}
                        selectedBusinessTypes={selectedBusinessTypes}
                        setSelectedBusinessTypes={setSelectedBusinessTypes}
                      />
                      <label
                        className="fs-s m-16-t"
                        htmlFor={`profile-upload-${i}`}
                      >
                        {!!files[i] ? (
                          <HFlex c-c g-8 bdr-16 p-16-rl height={56} bd-t3>
                            <Image
                              size={20}
                              src={`/icons/checkbox_checked.png`}
                            />
                            <Text t-16-800-gr5>{files[i].name}</Text>
                          </HFlex>
                        ) : (
                          <HFlex c-c g-8 bdr-16 p-16-rl height={56} bd-t3>
                            <Image size={20} src={`/icons/icon_camera_2.png`} />
                            <Text t-16-800-gr5>영업 허가증 올리기</Text>
                          </HFlex>
                        )}
                        <FileUpload
                          id={`profile-upload-${i}`}
                          onLoad={(file: File) =>
                            setFiles((f) => {
                              f[i] = file;
                              return [...f];
                            })
                          }
                        />
                      </label>
                    </VFlex>
                  ))}
                  <VFlex m-16-t>
                    <BusinessCategoryDropdown
                      businessCategories={businessCategories}
                      selectedBusinessCategories={selectedBusinessCategories}
                      setSelectedBusinessCategories={
                        setSelectedBusinessCategories
                      }
                    />
                  </VFlex>
                </VFlex>
              )}
              <Button
                type="cta-lg"
                enabled={ready}
                onClick={async () => {
                  const formData = new FormData();
                  for (let i = 0; i < selectedBusinessTypes.length; ++i) {
                    const t = selectedBusinessTypes[i];
                    formData.append(`licenses[]`, t.id);
                    formData.append(`files[]`, files[i]);
                  }
                  for (let i = 0; i < selectedBusinessCategories.length; ++i) {
                    const c = selectedBusinessCategories[i];
                    formData.append(`categories[]`, c.id);
                  }
                  setLoading(true);
                  await api.post(
                    `/rest/group/${partner?.id}/business/categories`,
                    formData,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    }
                  );
                  window.location.href = "/main";
                  trackSetBizSector();
                }}
                caption="완료"
              />
            </VFlex>
          </VFlex>
        </VFlex>
      </VFlex>
      <VFlex f-1 />
      <SignupFooter />
      {popups.map((popup, i) => (
        <Popup key={popup.key} popup={popup} depth={i} />
      ))}
    </LoadingView>
  );
}

export default SignupCompanyDetails;
