import React, { useEffect, useMemo, useRef, useState } from "react";

import Absolute from "../layouts/Absolute";
import HFlex from "../layouts/HFlex";
import VFlex from "../layouts/VFlex";

import { usePopups } from "../providers/popups";

export enum PopupKind {
  Drawer = "drawer",
  Popup = "popup",
  Mini = "mini",
  Swiper = "swiper",
}

export enum PopupAlign {
  TopCenter = "topCenter",
  Center = "center",
}

export type NewPopup = {
  key?: string;
  element: React.FC<any>;
  dimClick?: string;
  kind: PopupKind;
  align?: PopupAlign;
  isOpened?: boolean;
  options?: {
    backdropColor?: string;
    className?: string;
    innerClassName?: string;
  };
  width?: number;
  props?: any;
  x?: number;
  y?: number;
};

function Popup({ popup, depth }: { popup: NewPopup; depth: number }) {
  const popPopupRef = useRef(usePopups((state) => state.popPopup));
  const popPopup = useMemo(() => popPopupRef.current, [popPopupRef]);
  const divRef = useRef<HTMLDivElement>(null);
  const [pos, setPos] = useState<{ x?: number; y?: number }>({
    x: undefined,
    y: undefined,
  });
  useEffect(() => {
    if (popup.x && popup.y) {
      const { width: popupWidth, height: popupHeight } =
        divRef.current?.getBoundingClientRect()!;
      setPos({
        x: Math.min(window.innerWidth - popupWidth - 32, popup.x - popupWidth),
        y: Math.min(window.innerHeight - popupHeight - 32, popup.y),
      });
    }
  }, [popup.x, popup.y]);

  if (popup.kind === PopupKind.Drawer) {
    return (
      <Absolute
        classes={["anim-1"]}
        style={{
          backgroundColor: popup.options?.backdropColor ?? "#00000080",
          opacity: popup.isOpened ? 1 : 0,
          zIndex: 20000000 + depth,
        }}
        onClick={(e) => {
          popPopup(popup.key);
          e.stopPropagation();
        }}
      >
        <Absolute
          anim-1
          bc-w
          style={{
            left: "auto",
            right: popup.isOpened ? "0%" : "-100%",
            width: popup.width ?? 600,
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <VFlex
            width={"100%"}
            height={"100%"}
            style={{
              overflowY: "scroll",
            }}
          >
            {React.createElement(popup.element, {
              key: popup.key,
              ...popup.props,
              popPopup: () => popPopup(popup.key),
            })}
          </VFlex>
        </Absolute>
      </Absolute>
    );
  } else if (popup.kind === PopupKind.Popup) {
    return (
      <Absolute
        classes={["anim-1"]}
        style={{
          backgroundColor: popup.options?.backdropColor ?? "#00000080",
          opacity: popup.isOpened ? 1 : 0,
          zIndex: 20000000 + depth,
        }}
        onClick={(e) => {
          // 보호자 정보 등록 중 dim배경 클릭을 막기 위해 추가한 코드 240111
          if (popup.dimClick === "none") return;

          popPopup(popup.key);
          e.stopPropagation();
        }}
      >
        <HFlex
          c-c={!popup.align || popup.align === PopupAlign.Center}
          j-c={popup?.align === PopupAlign.TopCenter}
          style={{ marginTop: popup?.align === PopupAlign.TopCenter ? 32 : 0 }}
          width={"100%"}
          height={"100%"}
        >
          <Absolute
            ref={divRef}
            anim-1
            bc-w
            left={"auto"}
            right={"auto"}
            top={"auto"}
            bottom={"auto"}
            bdr-24
            ovf-v
            style={{
              ...(!!popup.x && !!popup.y
                ? {
                    position: "absolute",
                    left: pos.x,
                    top: pos.y,
                    backgroundColor: "transparent",
                  }
                : { minWidth: popup.width ?? 460, minHeight: "100px" }),
              maxHeight: "95vh",
              maxWidth: "95vw",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <VFlex
              style={{
                maxHeight: "95vh",
                maxWidth: "95vw",
              }}
            >
              {React.createElement(popup.element, {
                key: popup.key,
                ...popup.props,
                popPopup: () => popPopup(popup.key),
              })}
            </VFlex>
          </Absolute>
        </HFlex>
      </Absolute>
    );
  } else if (popup.kind === PopupKind.Swiper) {
    return (
      <Absolute
        j-c
        c-c
        classes={["anim-1"]}
        style={{
          backgroundColor: popup.options?.backdropColor ?? "#00000080",
          opacity: popup.isOpened ? 1 : 0,
          zIndex: 20000000 + depth,
        }}
        onClick={(e) => {
          popPopup(popup.key);
          e.stopPropagation();
        }}
      >
        <VFlex
          style={{
            maxHeight: "95vh",
            maxWidth: "95vw",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {React.createElement(popup.element, {
            key: popup.key,
            ...popup.props,
            popPopup: () => popPopup(popup.key),
          })}
        </VFlex>
      </Absolute>
    );
  } else {
    return (
      <Absolute
        classes={["anim-1"]}
        style={{
          opacity: popup.isOpened ? 1 : 0,
          zIndex: 20000000 + depth,
        }}
        onClick={(e) => {
          popPopup(popup.key);
          e.stopPropagation();
        }}
      >
        <HFlex
          c-c={!popup.align || popup.align === PopupAlign.Center}
          j-c={popup?.align === PopupAlign.TopCenter}
          style={{ marginTop: popup?.align === PopupAlign.TopCenter ? 32 : 0 }}
          width={"100%"}
          height={"100%"}
        >
          <Absolute
            ref={divRef}
            anim-1
            left={"auto"}
            right={"auto"}
            top={"auto"}
            bottom={"auto"}
            ovf-v
            style={{
              ...(!!popup.x && !!popup.y
                ? {
                    position: "absolute",
                    left: pos.x,
                    top: pos.y,
                    backgroundColor: "transparent",
                  }
                : { minWidth: popup.width ?? 400, minHeight: "100px" }),
              maxHeight: "95vh",
              maxWidth: "95vw",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <VFlex
              style={{
                maxHeight: "95vh",
                maxWidth: "95vw",
              }}
            >
              {React.createElement(popup.element, {
                key: popup.key,
                ...popup.props,
                popPopup: () => popPopup(popup.key),
              })}
            </VFlex>
          </Absolute>
        </HFlex>
      </Absolute>
    );
  }
}

export default Popup;
