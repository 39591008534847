import Bluebird from "bluebird";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import api from "../common/api";
import { Taxonomy } from "./types";

const taxonomiesStore = create(
  immer<{
    taxonomies: Record<string, Taxonomy[]>;
    fetchTaxonomies: () => void;
  }>((set) => ({
    taxonomies: {},
    fetchTaxonomies: async () => {
      const taxonomies: Record<string, Taxonomy[]> = {};
      await Bluebird.map(
        [
          "INTANGIBLE_BEAUTY_MENU",
          "INTANGIBLE_BEAUTY_SERVICE?objectId=198",
          "INTANGIBLE_BEAUTY_SERVICE?objectId=199",
          "INTANGIBLE_BEAUTY_SERVICE?objectId=200",
        ],
        async (taxonomyType) => {
          const data = await api.get(`/rest/taxonomy/${taxonomyType}`);
          taxonomies[taxonomyType] = data;
        }
      );
      set((state) => ({
        ...state,
        taxonomies,
      }));
    },
  }))
);

export const useTaxonomies = () => {
  const { ...rest } = taxonomiesStore();
  return {
    ...rest,
  };
};
