import { useCallback, useState } from "react";
import { PopupAnchor } from "../../../App";
import api from "../../../common/api";
import Spinner from "../../../common/Spinner/Spinner";
import { BusinessHoliday, RepeatType } from "../../../providers/types";
import AddBusinessHolidays from "./AddBusinessHolidays";
import { dayConversion } from "./Holidays";
import Button from "../../../layouts/Button";
import { pushPopup } from "../../../providers/popups";
import { PopupKind } from "../../../popup/Popup";
import Image from "../../../layouts/Image";
import HFlex from "../../../layouts/HFlex";
import Text from "../../../layouts/Text";
import VFlex from "../../../layouts/VFlex";
import { ContextMenu } from "../../../popup/ContextMenu";
import { usePartner } from "../../../providers/partner";
import { useBusinessHours } from "../../../providers/businessHours";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";

function RegularHolidays() {
  const { trackAddRegularHolidays, trackDeleteRegularHolidays } =
    useAmplitudeTrack();
  const { partner } = usePartner();
  const { businessHolidays, fetchBusinessHolidays } = useBusinessHours();
  const [loading, setLoading] = useState<boolean>(false);
  const getRepeatString = useCallback((holiday: BusinessHoliday) => {
    switch (holiday.repeatType) {
      case RepeatType.ONCE:
        break;
      case RepeatType.EVERY_WEEK:
        return `매주 ${[dayConversion[holiday.dayOfWeek]].join(", ")}요일`;
      case RepeatType.FIRST_WEEK_OF_MONTH:
        return `매월 첫번째 ${[dayConversion[holiday.dayOfWeek]].join(
          ", "
        )}요일`;
      case RepeatType.SECOND_WEEK_OF_MONTH:
        return `매월 두번째 ${[dayConversion[holiday.dayOfWeek]].join(
          ", "
        )}요일`;
      case RepeatType.THIRD_WEEK_OF_MONTH:
        return `매월 세번째 ${[dayConversion[holiday.dayOfWeek]].join(
          ", "
        )}요일`;
      case RepeatType.FOURTH_WEEK_OF_MONTH:
        return `매월 네번째 ${[dayConversion[holiday.dayOfWeek]].join(
          ", "
        )}요일`;
      case RepeatType.FIFTH_WEEK_OF_MONTH:
        return `매월 다섯번째 ${[dayConversion[holiday.dayOfWeek]].join(
          ", "
        )}요일`;
      case RepeatType.SIXTH_WEEK_OF_MONTH:
        return `매월 여섯번째 ${[dayConversion[holiday.dayOfWeek]].join(
          ", "
        )}요일`;
    }
  }, []);
  const deleteRegularHoliday = useCallback(
    async (holidayId: number) => {
      await api.delete(`/rest/group/${partner.id}/business-holiday`, {
        data: [holidayId],
      });
      await fetchBusinessHolidays();
      setLoading(false);
    },
    [partner.id, fetchBusinessHolidays, setLoading]
  );
  return (
    <VFlex p-32 as-st g-16 rel>
      <VFlex bdr-16 bd-t3 style={{ minHeight: 330 }}>
        <HFlex a-c bc-t1 p-16-l p-8-r bd-b-t3 height={56}>
          <Text f-1 t-16-600-s9>
            업체 정기 휴무일
          </Text>
          <Button
            type="primary"
            onClick={() => {
              pushPopup({
                kind: PopupKind.Drawer,
                element: AddBusinessHolidays,
              });
              trackAddRegularHolidays(partner.title);
            }}
          >
            <HFlex g-8 a-c>
              <Image size={18} src={`/icons/icon_add.png`} />
              <Text t-12-800-w>정기 휴무일 추가</Text>
            </HFlex>
          </Button>
        </HFlex>
        {loading ? (
          <HFlex c-c>
            <Spinner />
          </HFlex>
        ) : businessHolidays.length === 0 ? (
          <VFlex f-1 c-c g-8>
            <Image size={20} src={`/icons/icon_info_gray.png`} />
            <Text t-16-s4>설정된 정기 휴무일이 없습니다.</Text>
          </VFlex>
        ) : (
          <VFlex f-1 p-8 ovf-a>
            {businessHolidays.map((holiday, i) => (
              <HFlex
                key={i}
                height={54}
                bd-b-t3={i !== businessHolidays.length - 1}
                a-c
                p-24-rl
                g-16
                classes={["table-row"]}
              >
                <Text t-14-700-s6>정기 휴무일 {i + 1}</Text>
                <Text t-14-500-s4>|</Text>
                <Text f-1 t-12-500-s4>
                  {getRepeatString(holiday)}
                </Text>
                <PopupAnchor
                  popup={{
                    kind: PopupKind.Popup,
                    element: ContextMenu,
                    width: 212,
                    props: {
                      menus: [
                        {
                          icon: `/icons/icon_delete.png`,
                          isErrorText: true,
                          title: "정기휴무 취소하기",
                          confirmButtonTitle: "정말 취소할까요?",
                          confirmButtonLabel: "정기휴무 취소하기",
                          confirmButtonType: "warning",
                          onConfirm: () => {
                            deleteRegularHoliday(holiday.id);
                            trackDeleteRegularHolidays(partner.title);
                          },
                        },
                      ],
                    },
                    options: {
                      innerClassName: "Popup-Cancel",
                      backdropColor: "transparent",
                    },
                    offsetX: 180,
                    offsetY: -8,
                  }}
                >
                  <Image size={24} src={`/icons/icon_more.png`} />
                </PopupAnchor>
              </HFlex>
            ))}
          </VFlex>
        )}
      </VFlex>
    </VFlex>
  );
}

export default RegularHolidays;
