import { useEffect, useMemo, useRef, useState } from "react";

import { days, dayOfWeek } from "../../../common/constants";
import { useSchedules } from "../../../providers/schedules";
import { useBusinessHours } from "../../../providers/businessHours";
import { ReservationStatus, Schedule, Staff } from "../../../providers/types";
import {
  checkIsBusinessHolidays,
  checkIsBusinessHour,
  checkIsTemporaryHolidays,
  HolidayBlock,
  ScheduleBlock,
} from "./DailyCalendar";
import { PopupAnchorActions } from "../../../App";
import Spinner from "../../../common/Spinner/Spinner";
import { CreatingScheduleBlock } from "./StaffCalendar";

import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Text from "../../../layouts/Text";
import Absolute from "../../../layouts/Absolute";

import _ from "lodash";
import { useLongPress, LongPressEvent } from "use-long-press";
import {
  addDays,
  addMinutes,
  differenceInMinutes,
  startOfWeek,
} from "date-fns";
import hangul from "hangul-js";

const WeeklyCalendar = ({
  selectedStaffs,
  searchText,
  addScheduleRef,
  personalList,
  statusList,
}: {
  selectedStaffs: Staff[] | undefined;
  searchText?: string;
  addScheduleRef: React.RefObject<PopupAnchorActions>;
  personalList?: ReservationStatus[];
  statusList?: ReservationStatus[];
}) => {
  const { schedules, calendarDate } = useSchedules();
  const { businessHours, businessHolidays, temporaryHolidays } =
    useBusinessHours();
  const monday = useMemo(() => startOfWeek(calendarDate), [calendarDate]);
  const divRef = useRef<HTMLDivElement>(null);
  const openHour = useMemo(
    () =>
      Math.max(
        ..._.compact(
          businessHours.map((businessHour) => {
            try {
              return parseInt(businessHour.start?.split(":")[0]);
            } catch {
              return undefined;
            }
          })
        ),
        0
      ),
    [businessHours]
  );
  const businessHoursByDay = useMemo(() => {
    return _.keyBy(businessHours, (businessHour) => {
      return dayOfWeek.findIndex((day) => day === businessHour.dayOfWeek);
    });
  }, [businessHours]);
  useEffect(() => {
    divRef.current?.scrollTo({ top: 38 * openHour * 2, behavior: "smooth" });
  }, [divRef, openHour]);
  const filteredSchedules = useMemo(() => {
    return schedules?.filter((schedule) => {
      return (
        startOfWeek(schedule.startDateTime).getTime() ===
          startOfWeek(calendarDate).getTime() &&
        _.some(
          [
            schedule.petName,
            schedule.nickname ?? "",
            schedule.cellNumber ?? "",
          ],
          (text) => hangul.search(text, searchText ?? "") >= 0
        ) &&
        (schedule.staffs.length === 0 ||
          _.intersection(
            selectedStaffs?.map((staff) => staff.id),
            schedule.staffs.map((staff) => staff.id)
          ).length > 0)
      );
    });
  }, [schedules, searchText, calendarDate, selectedStaffs]);
  const [startPosition, setStartPosition] = useState<{
    x: number;
    y: number;
  }>();

  const startDate = useMemo(() => {
    const startDate = new Date(calendarDate);
    startDate.setDate(startDate.getDate() - startDate.getDay());
    return startDate;
  }, [calendarDate]);

  const [creatingSchedule, setCreatingSchedule] = useState<Schedule>();
  const scheduleBoxRef = useRef<HTMLDivElement>(null);
  const bind = useLongPress(
    () => {
      const startDateTime = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + startPosition!.x,
        Math.floor(startPosition!.y / 2),
        (startPosition!.y % 2) * 30
      );
      const endDateTime = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + startPosition!.x,
        Math.floor(startPosition!.y / 2),
        (startPosition!.y % 2) * 30 + 60
      );
      setCreatingSchedule({
        reservationId: 0,
        status: 0,
        species: "",
        productList: [],
        chargerIdList: [],
        startDateTime,
        endDateTime,
        petName: "",
        staffs: [],
        note: "",
        backgroundColor: "pink",
        type: "drag",
      });
    },
    {
      filterEvents: (e: any) => {
        // filter out right click
        return e.buttons === 1;
      },
      onStart: (e: any) => {
        const { x, y, width } =
          scheduleBoxRef.current?.getBoundingClientRect()!;
        setStartPosition({
          x: Math.floor((e.clientX - x) / (width / 7)),
          y: Math.floor((e.clientY - y) / 38),
        });
      },
      onMove: (e: any) => {
        const { x, y, width } =
          scheduleBoxRef.current?.getBoundingClientRect()!;
        if (startPosition) {
          const newEndPosition = {
            x: Math.floor((e.clientX - x) / (width / 7)),
            y: Math.floor((e.clientY - y) / 38) + 1,
          };
          if (creatingSchedule) {
            let newEndDateTime = new Date(
              calendarDate.getFullYear(),
              calendarDate.getMonth(),
              creatingSchedule.startDateTime.getDate(),
              Math.floor(newEndPosition.y / 2),
              (newEndPosition.y % 2) * 30
            );
            if (creatingSchedule.endDateTime !== newEndDateTime) {
              if (
                differenceInMinutes(
                  newEndDateTime,
                  creatingSchedule.startDateTime.getTime()
                ) < 60
              ) {
                newEndDateTime = addMinutes(creatingSchedule.startDateTime, 60);
              }
              setCreatingSchedule({
                ...creatingSchedule,
                endDateTime: newEndDateTime,
              });
            }
          }
        }
      },
      onFinish: async (e: LongPressEvent) => {
        if ("buttons" in e && e.buttons > 0) {
          setStartPosition(undefined);
          setCreatingSchedule(undefined);
          return;
        }
        setStartPosition(undefined);
        if (creatingSchedule) {
          addScheduleRef.current?.pushPopup(
            [],
            creatingSchedule.startDateTime,
            creatingSchedule.endDateTime,
            creatingSchedule.type
          );
          setCreatingSchedule(undefined);
        }
      },
      onCancel: () => {
        setStartPosition(undefined);
        setCreatingSchedule(undefined);
      },
      threshold: 200,
    }
  );

  const schedulesByWeekly = useMemo(() => {
    if (!filteredSchedules) return {};

    const schedulesByDay = _(filteredSchedules)
      .groupBy((schedule) => new Date(schedule.startDateTime).getDay())
      .mapValues((schedules) =>
        schedules
          .map((schedule) => ({
            ...schedule,
            startDateTime: new Date(schedule.startDateTime),
            endDateTime: new Date(schedule.endDateTime),
          }))
          .sort((a, b) => a.startDateTime.getTime() - b.startDateTime.getTime())
      )
      .value();

    return _.mapValues(schedulesByDay, (schedules) => {
      if (!schedules.length) return [];

      const overlappingGroups = [];
      let currentGroup = [schedules[0]];

      for (let i = 1; i < schedules.length; i++) {
        const currentSchedule = schedules[i];

        let overlaps = false;
        for (const schedule of currentGroup) {
          if (
            schedule.endDateTime > currentSchedule.startDateTime &&
            schedule.startDateTime < currentSchedule.endDateTime
          ) {
            overlaps = true;
            break;
          }
        }

        if (overlaps) {
          currentGroup.push(currentSchedule);
        } else {
          currentGroup.sort((a, b) => {
            if (a.startDateTime.getTime() === b.startDateTime.getTime()) {
              return b.endDateTime.getTime() - a.endDateTime.getTime();
            }
            return a.startDateTime.getTime() - b.startDateTime.getTime();
          });

          overlappingGroups.push(currentGroup);
          currentGroup = [currentSchedule];
        }
      }

      currentGroup.sort((a, b) => {
        if (a.startDateTime.getTime() === b.startDateTime.getTime()) {
          return b.endDateTime.getTime() - a.endDateTime.getTime();
        }
        return a.startDateTime.getTime() - b.startDateTime.getTime();
      });
      overlappingGroups.push(currentGroup);

      return overlappingGroups;
    });
  }, [filteredSchedules]);

  const maxOverlapByWeekly = useMemo(() => {
    return _.mapValues(schedulesByWeekly, (dailySchedules) =>
      dailySchedules.map((schedules) => {
        if (!schedules.length) {
          return {
            maxOverlapIndex: 0,
            schedules: {},
          };
        }
        let schedulesToIterate = schedules;
        let currentEndTime;
        let overlapIndex = 0;
        let nextSchedulesToIterate = [];
        const schedulesByOverlapIndex: Record<string, Schedule[]> = {};
        while (true) {
          for (const schedule of schedulesToIterate) {
            if (currentEndTime && currentEndTime > schedule.startDateTime) {
              nextSchedulesToIterate.push(schedule);
              continue;
            }
            currentEndTime = schedule.endDateTime;
            schedulesByOverlapIndex[overlapIndex.toString()] ||= [];
            schedulesByOverlapIndex[overlapIndex.toString()].push(schedule);
          }
          if (nextSchedulesToIterate.length === 0) {
            break;
          }
          overlapIndex++;
          schedulesToIterate = [...nextSchedulesToIterate];
          nextSchedulesToIterate = [];
          currentEndTime = undefined;
        }
        return {
          maxOverlapIndex: overlapIndex + 1,
          schedules: schedulesByOverlapIndex,
        };
      })
    );
  }, [schedulesByWeekly]);

  const isToday = (i: number) => {
    const date = new Date(startDate);
    date.setDate(date.getDate() + i);
    return (
      Math.floor(date.getTime() / (24 * 60 * 60 * 1000)) ===
      Math.floor(Date.now() / (24 * 60 * 60 * 1000))
    );
  };

  return (
    <VFlex f-1>
      <HFlex>
        <VFlex width={80} bd-b-t3 c-c />
        <VFlex f-1 ovf-h>
          <HFlex>
            {days.map((day, i) => (
              <VFlex key={i} f-1 c-c height={56} ovf-h>
                <Text t-12-s4={!isToday(i)} t-12-gr5={isToday(i)} l-1>
                  {day}
                </Text>
                <Text t-18-s8={!isToday(i)} t-18-gr5={isToday(i)} l-1>
                  {new Date(
                    startDate.getTime() + 24 * 60 * 60 * 1000 * i
                  ).getDate()}
                </Text>
              </VFlex>
            ))}
          </HFlex>
          <HFlex bd-b-t3>
            {days.map((_day, i) => (
              <VFlex key={i} f-1 rel>
                {checkIsBusinessHolidays(
                  businessHolidays,
                  addDays(monday, i)
                ) && <HolidayBlock text="정기 휴무" height={18} />}
                {checkIsTemporaryHolidays(
                  temporaryHolidays,
                  addDays(monday, i)
                ) && <HolidayBlock text="임시 휴무" height={18} />}
              </VFlex>
            ))}
          </HFlex>
        </VFlex>
      </HFlex>
      <VFlex rel ovf-h>
        <HFlex ref={divRef} f-1 ovf-a rel>
          <VFlex width={80}>
            {[...Array(24).keys()]
              .map((value) => value * 60)
              .map((value, i) => (
                <VFlex key={i}>
                  <VFlex
                    c-c
                    height={38}
                    bd-br-t3
                    style={{
                      borderBottom: "1px dashed #EBEDEC",
                    }}
                  >
                    <Text t-14-500-s4>{`${value >= 720 ? "오후" : "오전"} ${
                      Math.floor(value / 60) % 12 || 12
                    }:${("0" + (value % 60)).slice(-2)}`}</Text>
                  </VFlex>
                  <VFlex c-c height={38} bd-br-t3 />
                </VFlex>
              ))}
          </VFlex>
          {schedulesByWeekly && (
            <VFlex f-1>
              <HFlex f-1 ref={scheduleBoxRef} {...bind()}>
                {days.map((__, i) => (
                  <VFlex key={i} f-1 rel>
                    <Absolute bd-l-t3={i > 0} style={{ paddingRight: 12 }}>
                      {maxOverlapByWeekly[i]?.map(
                        ({ maxOverlapIndex, schedules }, idx) => (
                          <VFlex key={idx} f-1 rel>
                            {Object.entries(schedules).flatMap(
                              (
                                [overlapIndex, scheduleList],
                                scheduleListIdx,
                                entries
                              ) =>
                                scheduleList.map((schedule, j) => {
                                  const startTime = new Date(
                                    Math.min(
                                      schedule.startDateTime.getTime(),
                                      schedule.endDateTime.getTime()
                                    )
                                  );
                                  const endTime = new Date(
                                    Math.max(
                                      schedule.startDateTime.getTime(),
                                      schedule.endDateTime.getTime()
                                    )
                                  );

                                  let width = 100 / maxOverlapIndex;

                                  for (
                                    let nextIdx = scheduleListIdx + 1;
                                    nextIdx < entries.length;
                                    nextIdx++
                                  ) {
                                    const nextScheduleList =
                                      entries[nextIdx][1];

                                    let overlaps = false;
                                    for (const nextSchedule of nextScheduleList) {
                                      const nextStartTime = new Date(
                                        Math.min(
                                          nextSchedule.startDateTime.getTime(),
                                          nextSchedule.endDateTime.getTime()
                                        )
                                      );
                                      const nextEndTime = new Date(
                                        Math.max(
                                          nextSchedule.startDateTime.getTime(),
                                          nextSchedule.endDateTime.getTime()
                                        )
                                      );

                                      if (
                                        !(
                                          endTime <= nextStartTime ||
                                          startTime >= nextEndTime
                                        )
                                      ) {
                                        overlaps = true;
                                        break;
                                      }
                                    }

                                    if (!overlaps) {
                                      width += 100 / maxOverlapIndex;
                                    } else {
                                      break;
                                    }
                                  }

                                  return (
                                    <ScheduleBlock
                                      key={j}
                                      schedule={schedule}
                                      overlapIndex={parseInt(overlapIndex)}
                                      maxOverlapIndex={maxOverlapIndex}
                                      personalList={personalList}
                                      statusList={statusList}
                                      width={width}
                                    />
                                  );
                                })
                            )}
                          </VFlex>
                        )
                      )}
                    </Absolute>
                    {isToday(i) && (
                      // 현재 시간 바
                      <VFlex
                        style={{
                          position: "absolute",
                          top: `${
                            19 *
                            Math.floor(
                              (new Date().getHours() * 60 +
                                new Date().getMinutes()) /
                                15
                            )
                          }px`,
                          left: 0,
                          width: `100%`,
                          height: 1,
                          background: "#0092E4",
                          zIndex: 1,
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: -5,
                            left: -5,
                            width: 11,
                            height: 11,
                            borderRadius: "100%",
                            background: "#0092ea",
                            zIndex: 1,
                          }}
                        ></div>
                      </VFlex>
                    )}
                    {creatingSchedule &&
                      creatingSchedule.startDateTime.getDay() === i && (
                        <Absolute bd-l-t3={i > 0} style={{ paddingRight: 4 }}>
                          <CreatingScheduleBlock schedule={creatingSchedule} />
                        </Absolute>
                      )}
                    {Array(48)
                      .fill(1)
                      .map((_, j) => (
                        <VFlex
                          key={j}
                          height={38}
                          bc-t1={checkIsBusinessHour({
                            date: addDays(startOfWeek(calendarDate), i),
                            businessHours: businessHoursByDay[i],
                            businessHolidays,
                            temporaryHolidays,
                            time: j * 30,
                          })}
                          style={{
                            borderBottom: `1px ${
                              j % 2 === 0 ? "dashed" : "solid"
                            } #EBEDEC`,
                          }}
                        />
                      ))}
                  </VFlex>
                ))}
              </HFlex>
            </VFlex>
          )}
          {!schedulesByWeekly && (
            <VFlex abs c-c style={{ inset: 0 }} unclickable>
              <Spinner />
            </VFlex>
          )}
        </HFlex>
      </VFlex>
    </VFlex>
  );
};

export default WeeklyCalendar;
