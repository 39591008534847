import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Flex from "../../../layouts/Flex";
import Text from "../../../layouts/Text";
import Image from "../../../layouts/Image";
import { pushPopup } from "../../../providers/popups";
import { PopupKind } from "../../../popup/Popup";
import EditCustomerDeletedReason from "./EditCustomerDeletedReason";
import { Pet } from "../../../providers/types";
import { format } from "date-fns";
import { ko } from "date-fns/locale";
import { useEffect, useState } from "react";
import { usePartner } from "../../../providers/partner";
import api from "../../../common/api";
import toast from "react-hot-toast";

function CustomerDeletedReason({
  popPopup,
  pet,
  fetchCurrentPage,
}: {
  popPopup: () => void;
  pet: Pet;
  fetchCurrentPage: () => void;
}) {
  const { partner } = usePartner();
  const [reason, setReason] = useState(pet.deletedReason);

  const fetchPetHistory = async () => {
    const { reason } = await api.get(
      `/rest/pet/history/${pet.id}/latest?groupId=${partner.id}&dataType=REASON&type=DELETE`
    );
    setReason(reason);
  };

  useEffect(() => {
    fetchPetHistory();
  }, []);

  const OpenEditCustomerDeletedReasonPopup = () => {
    pushPopup({
      key: "edit_customer_delected_reason",
      kind: PopupKind.Popup,
      element: EditCustomerDeletedReason,
      props: {
        deletedReason: reason,
        editDeletedReason: async (r: string) => {
          try {
            await api.put(`/rest/note?platform=PARTNER_WEB`, [
              {
                objectId: pet.id,
                content: r,
                type: "PET_DELETED",
                groupId: partner.id,
              },
            ]);

            toast.success("수정이 완료되었습니다.");
            await fetchPetHistory();
            await fetchCurrentPage();
          } catch (e) {
            toast.error("삭제 사유 변경에 실패였습니다.");
          }
        },
      },
    });
  };

  return (
    <VFlex p-24 width={530}>
      <HFlex p-24-b>
        <Text t-24-600-s8>삭제 사유</Text>
        <Flex f-1 />
        <Image
          src="/icons/edit.svg"
          size={24}
          m-24-r
          clickable
          onClick={() => OpenEditCustomerDeletedReasonPopup()}
        />
        <Image src="/icons/close.svg" size={24} clickable onClick={popPopup} />
      </HFlex>

      <Text t-14-500-s4 m-16-b>
        {format(pet.deleted! * 1000, "yyyy.MM.dd (EEE)", { locale: ko })}
      </Text>

      {reason ? (
        <Text
          t-16-500-s8
          style={{
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          }}
        >
          {reason}
        </Text>
      ) : (
        <Text t-16-500-s4>작성된 내용이 없습니다.</Text>
      )}
    </VFlex>
  );
}

export default CustomerDeletedReason;
