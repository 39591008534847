import React, { useEffect, useRef, useState } from "react";

import api from "../../../common/api";
import { scheduleStatusString } from "../../../common/constants";
import { numberWithCommas } from "../../../common/utils";
import { Histories } from "../../../providers/types";
import { usePartner } from "../../../providers/partner";

import { calcEndDate, calcStartDate } from "./SalesStatistics";
import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Text from "../../../layouts/Text";
import Image from "../../../layouts/Image";
import LoadingView from "../../../layouts/LoadingView";

import { format } from "date-fns";
import { ko } from "date-fns/locale";

function SalesList({
  date,
  calendarType,
}: {
  date: Date | undefined;
  calendarType: string;
}) {
  const { partner } = usePartner();
  const startDate = calcStartDate(calendarType, date as Date);
  const endDate = calcEndDate(calendarType, startDate);

  const [totalCount, setTotalCount] = useState<number>(0);
  const [histories, setHistories] = useState<Histories[]>([]);

  const [pageNum, setPageNum] = useState(0);
  const [hasNext, setHasNext] = useState(true);
  const [loading, setLoading] = useState(false);
  const [lastElement, setLastElement] = useState<HTMLDivElement | null>(null);

  const observer = useRef(
    new IntersectionObserver((entries) => {
      const first = entries[0];
      if (first.isIntersecting) {
        setPageNum((no) => no + 1);
      }
    })
  );

  const fetchRevenueHistoryList = async () => {
    if (loading || !hasNext) return;

    try {
      setLoading(true);
      const { count, histories } = await api.get<{
        count: number;
        histories: Histories[];
      }>(
        `/rest/group/${
          partner.id
        }/revenue-history?startDate=${startDate}&endDate=${endDate}&size=10&page=${
          pageNum * 10
        }`
      );

      setHistories((prev) =>
        pageNum === 0 ? histories : [...prev, ...histories]
      );
      setTotalCount(count);
      setHasNext(pageNum === 0 || histories.length !== 0);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRevenueHistoryList();
  }, [pageNum]);

  useEffect(() => {
    setHasNext(true);
    setHistories([]);
    if (pageNum === 0) {
      fetchRevenueHistoryList();
    } else {
      setPageNum(0);
    }
  }, [startDate, endDate, calendarType]);

  useEffect(() => {
    const currentElement = lastElement;
    const currentObserver = observer.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement]);

  return (
    <VFlex bd-l-t3 width={376}>
      <VFlex ovf-s>
        <LoadingView loading={histories.length === 0 && loading} width={376}>
          {histories.length > 0 ? (
            <VFlex>
              <VFlex bc-w g-8 p-16-tb p-24-rl bd-b-t1 width={376}>
                <Text t-18-600-s8>{totalCount}건의 서비스를 제공했어요</Text>
              </VFlex>
              <VFlex height={"100%"}>
                {histories.map((history) => (
                  <HFlex key={history.id} g-16 p-16-tb p-24-rl a-c bd-b-t1 j-b>
                    <VFlex>
                      <Text t-14-s4>
                        {`${history.name} · ${format(
                          new Date(history.date * 1000),
                          "M월 d일 (EEE) a h:mm",
                          {
                            locale: ko,
                          }
                        )}`}
                      </Text>
                      <Text m-4-t m-8-b t-16-600-s7 l-m>
                        {history.productName}
                        {history.productPrice &&
                          ` · ${numberWithCommas(history.productPrice)}원`}
                      </Text>
                      <Text t-16-gr5>
                        {scheduleStatusString[history.status]}
                      </Text>
                    </VFlex>

                    {/* TODO : 결제 작업 후 진행 */}
                    {/* {!history.registeredRevenue && (
                  <VFlex height={46} j-c p-16-rl bc-s9 bdr-16 t-14-w>
                    결제 진행
                  </VFlex>
                )} */}
                  </HFlex>
                ))}
                {!loading && hasNext && <VFlex ref={setLastElement} />}
              </VFlex>
            </VFlex>
          ) : (
            <VFlex
              a-c
              j-c
              bd-t3
              bdr-16
              g-8
              m-24
              style={{
                padding: "100px 0",
              }}
            >
              <Image size={24} src={`/icons/icon_noti.svg`} />
              <Text t-16-s2>제공한 서비스가 없습니다.</Text>
            </VFlex>
          )}
        </LoadingView>
      </VFlex>
    </VFlex>
  );
}

export default SalesList;
