import _ from "lodash";
import { nanoid } from "nanoid";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { NewPopup } from "../popup/Popup";

export const pushPopup = (popup: NewPopup) =>
  usePopups.setState((state) => state.pushPopup(popup));

export const usePopups = create(
  immer<{
    popups: NewPopup[];
    pushPopup: (popup: NewPopup) => void;
    popPopup: (key?: string) => void;
    closeAllPopup: () => void;
  }>((set) => ({
    popups: [],
    pushPopup: async (popup: NewPopup) => {
      let abort = false;
      const key = nanoid();
      popup = {
        key,
        ...popup,
        isOpened: false,
      };
      set((state) => {
        // if there is a popup with the same key, don't push it
        if (popup.key && state.popups.find((p) => p.key === popup.key)) {
          abort = true;
          return;
        }
        state.popups.push(popup);
      });
      if (abort) {
        return;
      }
      await new Promise((resolve) => setTimeout(resolve, 0));
      set((state) => {
        const openedPopup = state.popups.find((p) => p.key === popup.key);
        if (openedPopup) {
          openedPopup.isOpened = true;
        }
      });
    },
    popPopup: async (key?: string) => {
      let abort = false;
      set((state) => {
        key ||= _.last(state.popups)?.key;
        const popup = state.popups.find((p) => p.key === key);
        if (!popup) {
          abort = true;
          return;
        }
        popup.isOpened = false;
      });
      if (abort) {
        return;
      }
      await new Promise((resolve) => setTimeout(resolve, 200));
      set((state) => {
        const popupIndex = state.popups.findIndex((p) => p.key === key);
        if (popupIndex === -1) {
          return;
        }
        state.popups.splice(popupIndex, 1);
      });
    },
    closeAllPopup: async () => {
      set((state) => {
        state.popups.forEach((popup) => {
          popup.isOpened = false;
        });
      });
      await new Promise((resolve) => setTimeout(resolve, 200));
      set((state) => {
        state.popups = [];
      });
    },
  }))
);
