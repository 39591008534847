import { HTMLAttributes } from "react";

export enum SvgIconType {
  LogoText = "logo_text",
  Error = "error",
  Success = "success",
  InputCancel = "input_cancel",
}

const SvgIcon = ({
  icon,
  size,
  width,
  height,
  shadow,
  ...props
}: {
  icon: SvgIconType;
  size?: number;
  width?: number;
  height?: number;
  shadow?: boolean;
} & HTMLAttributes<HTMLImageElement>) => {
  return (
    <img
      style={{
        ...(width || size ? { width: width ?? size } : {}),
        ...(height || size ? { height: height ?? size } : {}),
        ...(shadow
          ? {
              filter: "drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4))",
            }
          : {}),
      }}
      src={require(`./${icon}.svg`)}
      alt={`icon_${icon.toString()}`}
      {...props}
    />
  );
};

export default SvgIcon;
