import { useEffect, useMemo, useState } from "react";

import api from "../../../../common/api";
import {
  QuestionHistoryDetailInterface,
  QuestionHistoryInterface,
} from "../../../../providers/types";

import HFlex from "../../../../layouts/HFlex";
import VFlex from "../../../../layouts/VFlex";
import Text from "../../../../layouts/Text";
import Image from "../../../../layouts/Image";

import { format } from "date-fns";
import { ko } from "date-fns/locale";
import _ from "lodash";

const Questionnaire = ({
  petId,
  groupId,
  questionnaireId,
  petName,
}: {
  petId: number;
  groupId: number;
  questionnaireId: number;
  petName: string;
}) => {
  const [loading, setLoading] = useState(false);
  const [questionnaire, setQuestionnaire] = useState<
    QuestionHistoryDetailInterface[]
  >([]);

  const fetchQuestionnaire = async () => {
    const data = await api.get<QuestionHistoryInterface[]>(
      `/ext/questionnaire/history?groupId=${groupId}&petId=${petId}`
    );

    try {
      setLoading(true);

      const res = data.filter((v) => v.id === questionnaireId);
      if (res.length > 0) {
        const result = await api.get(`/ext/questionnaire/${res[0].id}/result`);
        setQuestionnaire(result);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQuestionnaire();
  }, [petId]);

  const steps = useMemo(
    () =>
      _.sortBy(questionnaire, (q: any) => q.sortOrder).map((q: any) => ({
        ...q,
        questionnaireQuestionsById: Object.fromEntries(
          q.questionnaireQuestions.map((qq: any) => [qq.id, qq])
        ),
      })),
    [questionnaire]
  );

  return questionnaire.length > 0 ? (
    <VFlex f-1 m-24>
      <VFlex bc-w bdr-16>
        <VFlex g-4 p-24 bd-b-t2>
          <Text
            t-22-600-s8
            style={{ whiteSpace: "pre-line" }}
          >{`${petName}의 안전문진표`}</Text>
          <Text t-16-500-s4>
            {format(
              new Date(questionnaire[0].questionnaireResults[0].created * 1000),
              "yyyy. MM. dd (E)",
              {
                locale: ko,
              }
            )}
          </Text>
        </VFlex>

        <VFlex g-24 p-24>
          {steps
            .map((item: any) => ({
              title: item.title,
              answer: item.questionnaireResults[0].title,
              note: item.questionnaireResults[0].note,
              textArea: item.questionnaireResults[0]?.note || false,
            }))
            .map(({ title, answer, note, textArea }: any, i: number) => (
              <HFlex key={i} g-16>
                <Text
                  t-14-600-s8
                  width={268}
                  style={{
                    display: "inline-block",
                    wordBreak: "break-word",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {title}
                </Text>
                <VFlex g-4>
                  {answer && <Text t-14-600-gr5>{answer}</Text>}
                  {note && <Text t-14-s8>{note}</Text>}
                </VFlex>
              </HFlex>
            ))}
        </VFlex>
      </VFlex>
    </VFlex>
  ) : (
    <VFlex height={300} bc-tw c-c bdr-16 m-24>
      <Image src="/icons/icon_info_s3.svg" size={24} m-8-b />
      <Text t-14-s4>고객이 안전문진표를 작성하지 않았습니다.</Text>
    </VFlex>
  );
};

export default Questionnaire;
