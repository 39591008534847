import { LOCALSTORAGE_KEY } from "../../providers/types";

export function numberWithCommas(n: number) {
  return n?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// TODO throw error
export function stringToBoolean(string: string) {
  return string === "true" || string === "TRUE" || string === "True"
    ? true
    : string === "false" || string === "FALSE" || string === "False"
    ? false
    : false;
}

export function booleanToString(bool: boolean, isUpperCase?: boolean) {
  return !!isUpperCase ? (bool ? "TRUE" : "FALSE") : bool ? "true" : "false";
}

export function getLastestDateTime(dateTimeList: number[]) {
  return dateTimeList.sort((a, b) => a - b)[0];
}

export function getEpochTime(date: Date) {
  return Math.floor(date.getTime() / 1000);
}

export function getLocalStorageItem<T>(key: LOCALSTORAGE_KEY): T | undefined {
  const serializedItem = localStorage.getItem(key);
  if (serializedItem === null) {
    return undefined;
  }
  return JSON.parse(serializedItem);
}

export function setLocalStorageItem<T>(key: LOCALSTORAGE_KEY, value: T): void {
  const serializedItem = JSON.stringify(value);
  localStorage.setItem(key, serializedItem);
}

export function registMouseDownDrag(
  onDragChange: (deltaX: number, deltaY: number) => void,
  stopPropagation?: boolean
) {
  return {
    onMouseDown: (clickEvent: React.MouseEvent<Element, MouseEvent>) => {
      if (stopPropagation) clickEvent.stopPropagation();

      const mouseMoveHandler = (moveEvent: MouseEvent) => {
        const deltaX = moveEvent.screenX - clickEvent.screenX;
        const deltaY = moveEvent.screenY - clickEvent.screenY;
        onDragChange(deltaX, deltaY);
      };

      const mouseUpHandler = () => {
        document.removeEventListener("mousemove", mouseMoveHandler);
      };

      document.addEventListener("mousemove", mouseMoveHandler);
      document.addEventListener("mouseup", mouseUpHandler, { once: true });
    },
  };
}

export const inrange = (v: number, min: number, max: number) => {
  if (v < min) return min;
  if (v >= max) return max;
  return v;
};

export const getNearestNumber = (value: number, interval: number) => {
  return Math.round(value / interval) * interval;
};
