import FilterChip from "../../layouts/FilterChip";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { format, isSameDay } from "date-fns";
import DatePicker from "../DatePickerV2";

interface ReservationStatusFilterProps {
  startDateFilter: Date | undefined;
  setStartDateFilter: Dispatch<SetStateAction<Date | undefined>>;
  endDateFilter: Date | undefined;
  setEndDateFilter: Dispatch<SetStateAction<Date | undefined>>;
  removeFilter: () => void;
}

const ReservationDateFilter = ({
  startDateFilter,
  setStartDateFilter,
  endDateFilter,
  setEndDateFilter,
  removeFilter,
}: ReservationStatusFilterProps) => {
  return (
    <DatePicker
      initDate={() => {
        setStartDateFilter(undefined);
        setEndDateFilter(undefined);
      }}
      remove={removeFilter}
      trigger={
        startDateFilter && endDateFilter ? (
          <FilterChip
            size="S"
            type="selected"
            suffixIcon="/icons/active_arrow_drop_down.svg"
            suffixIconSize={18}
          >
            {`예약 날짜 : ${format(startDateFilter, "yyyy.M.d")} ${
              isSameDay(startDateFilter, endDateFilter)
                ? ""
                : format(endDateFilter, "~ yyyy.M.d")
            }`}
          </FilterChip>
        ) : (
          <FilterChip
            size="S"
            suffixIcon="/icons/navigation/arrow_drop_down.svg"
            suffixIconSize={18}
          >
            예약 날짜
          </FilterChip>
        )
      }
      startDate={startDateFilter}
      setStartDate={setStartDateFilter}
      endDate={endDateFilter}
      setEndDate={setEndDateFilter}
    />
  );
};

export default ReservationDateFilter;
