import { useMemo } from "react";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import api from "../common/api";
import { usePartner } from "./partner";
import { Count, Partner } from "./types";

const countStore = create(
  immer<{
    count: Count[];
    fetchCount: (partner: Partner) => ({ date }: { date?: number }) => void;
  }>((set) => ({
    count: [],
    fetchCount:
      (partner: Partner) =>
      async ({ date }: { date?: number }) => {
        const count = await api.get(
          `/rest/reservations/count?groupId=${partner.id}&startDate=${date}&platform=PARTNER_WEB`
        );
        set((state) => ({
          ...state,
          count: count,
        }));
      },
  }))
);

export const useCount = () => {
  const { partner } = usePartner();
  const { fetchCount, ...rest } = countStore();
  return {
    fetchCount: useMemo(() => fetchCount(partner), [fetchCount, partner]),
    ...rest,
  };
};
