import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { cloneDeep, isNil } from "lodash";
import { format } from "date-fns";
import toast from "react-hot-toast";

import Flex from "../../../../layouts/Flex";
import HFlex from "../../../../layouts/HFlex";
import VFlex from "../../../../layouts/VFlex";
import Text from "../../../../layouts/Text";
import Image from "../../../../layouts/Image";
import TabBar from "../../../../components/TabBar";
import LoadingView from "../../../../layouts/LoadingView";
import ButtonV2 from "../../../../layouts/ButtonV2";
import Tooltip from "../../../../components/Tooltip";

import PetInfoTab from "./PetInfoTab";
import GuardianInfoTab from "./GuardianInfoTab";

import useCustomerFormStore, {
  CustomerInfo,
  GuardianForm,
  PetInfoForm,
} from "../../../../providers/customerForm";
import api from "../../../../common/api";
import { usePartner } from "../../../../providers/partner";
import { NEUTRALIZED_VALUES } from "../../../../common/constants";
import {
  Guardian,
  PetInfo,
  Pet,
  Attachment,
} from "../../../../providers/types";
import { pushPopup } from "../../../../providers/popups";
import ConfirmPopup from "../../../../popup/ConfirmPopup";
import { PopupKind, PopupAlign } from "../../../../popup/Popup";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../../../common/utils";
import { validatePhoneNumber } from "../../../../common/utils/customers";
import { useAmplitudeTrack } from "../../../../common/useAmplitudeTrack";

const showedGuide = getLocalStorageItem(
  "CUSTOMER-CREATE_NEW_CUSTOMER-FILL_REQUIRED_INFO"
);

enum ValidationErrorMsg {
  "NAME" = "반려동물명을 입력해주세요.",
  "SPECIES" = "반려동물 종류를 선택해주세요.",
  "DOG_BREED" = "견종을 선택해주세요.",
  "CAT_BREED" = "묘종을 선택해주세요.",
  "GUARDIANS" = "보호자 정보를 입력해주세요.",
  "NONE" = "입력한 정보를 확인해주세요.",
}

const checkGuardiansInfoChanged = (prev: Guardian[], cur: Guardian[]) => {
  return JSON.stringify(prev) !== JSON.stringify(cur);
};
const checkPetInfoChanged = (prev: PetInfo, cur: PetInfoForm) => {
  return !!(
    cur.profileImgFile ||
    prev?.name !== cur.name ||
    prev?.species.id !== cur.breed?.id ||
    prev?.sex !== cur.sex ||
    prev?.neuteredStatus !==
      (cur.neutralized === undefined
        ? undefined
        : NEUTRALIZED_VALUES[cur.neutralized]) ||
    (cur.birthday && format(cur.birthday, "yyyyMMdd") !== prev?.birthday) ||
    (cur.age &&
      cur.months &&
      (cur.age !== prev?.age || cur.months !== prev.months)) ||
    prev?.weight !== cur.weight ||
    prev?.allergy !== cur.allergy ||
    prev?.note !== cur.note
  );
};
const showAlertToast = (message: string) => {
  return toast(message, {
    style: {
      color: "#fff",
      backgroundColor: "#383B3A",
    },
    icon: <Image src="/icons/icon_warning.svg" size={20} />,
    duration: 1500,
  });
};
const validate = (data: PetInfoForm & { guardians: GuardianForm[] }) => {
  if (isNil(data.name)) return "NAME";
  else if (isNil(data.species)) return "SPECIES";
  else if (isNil(data.breed))
    return data.species.id === 4 ? "DOG_BREED" : "CAT_BREED";
  else if (
    !data.guardians.every(
      (g) => g.cellnumber && validatePhoneNumber(g.cellnumber)
    )
  )
    return "GUARDIANS";
  return "NONE";
};

const AddCustomer = ({
  popPopup,
  fetchCurrentPage,
  petId,
  tab,
}: {
  popPopup: () => void;
  fetchCurrentPage: (petId?: number) => void;
  petId?: number;
  tab?: number;
}) => {
  const { trackSaveNewCustomer, trackSaveCustomer } = useAmplitudeTrack();
  const tabs = [
    {
      key: "1",
      name: "기본 정보",
      component: () => <PetInfoTab />,
    },
    {
      key: "2",
      name: "보호자 정보",
      component: () => <GuardianInfoTab />,
    },
  ];

  const [selectedTab, setSelectedTab] = useState(tab ?? 0);
  const [isLoading, setIsLoading] = useState(false);
  const { partner } = usePartner();
  const [petInfo, setPetInfo] = useState<PetInfo | undefined>(undefined);
  const [guardiansInfo, setGuardiansInfo] = useState<Guardian[] | undefined>(
    undefined
  );
  const [showTooltip, setShowTooltip] = useState(isNil(showedGuide));

  const { setData, initInfo, ...data } = useCustomerFormStore();

  const isChanged = useMemo(() => {
    const { guardians, ...pet } = data;

    return (
      checkGuardiansInfoChanged(guardiansInfo!, guardians as Guardian[]) ||
      checkPetInfoChanged(petInfo!, pet)
    );
  }, [
    JSON.stringify(petInfo),
    JSON.stringify(guardiansInfo),
    JSON.stringify(data),
  ]);

  const isSavable = useMemo(() => {
    return (
      !isNil(data.name) &&
      !isNil(data.species) &&
      !isNil(data.breed) &&
      (isNil(data.weight) ||
        (Number(data.weight) > 0 && Number(data.weight) <= 200)) &&
      data.guardians.length <= 10 &&
      data.guardians.every(
        (g) => g.cellnumber && validatePhoneNumber(g.cellnumber)
      )
    );
  }, [JSON.stringify(data)]);

  const createNewCustomer = async (customerInfo: CustomerInfo) => {
    setIsLoading(true);

    if (
      isNil(customerInfo.name) ||
      isNil(customerInfo.breed) ||
      isNil(customerInfo.guardians) ||
      customerInfo.guardians.find((g) => isNil(g.cellnumber))
    ) {
      setIsLoading(false);
      return;
    }

    const formData = new FormData();

    if (data.profileImgFile) formData.append("file", data.profileImgFile);
    formData.append("name", customerInfo.name);
    formData.append("species.id", customerInfo.breed.id.toString());
    formData.append("species.name", customerInfo.breed.name);
    if (!isNil(customerInfo.sex))
      formData.append("sex", customerInfo.sex.toString());
    if (!isNil(customerInfo.neutralized) && customerInfo.neutralized !== -1)
      formData.append(
        "neuteredStatus",
        NEUTRALIZED_VALUES[customerInfo.neutralized]
      );

    if (!isNil(customerInfo.birthday))
      formData.append("birthday", format(customerInfo.birthday, "yyyyMMdd"));
    if (!isNil(customerInfo.age))
      formData.append(
        "age",
        format(customerInfo.age, customerInfo.age.toString())
      );
    if (!isNil(customerInfo.months))
      formData.append(
        "months",
        format(customerInfo.months, customerInfo.months.toString())
      );
    if (!isNil(customerInfo.weight)) {
      formData.append("weight", customerInfo.weight);
    }
    if (!isNil(customerInfo.allergy))
      formData.append("allergy", customerInfo.allergy);
    if (!isNil(customerInfo.note)) formData.append("note", customerInfo.note);

    customerInfo.guardians.forEach((g, i) => {
      formData.append(`guardians[${i}].cellnumber`, g.cellnumber!);
      if (!isNil(g.id)) formData.append(`guardians[${i}].id`, g.id.toString());
      if (!isNil(g.name)) formData.append(`guardians[${i}].name`, g.name);
      if (!isNil(g.note)) formData.append(`guardians[${i}].note`, g.note);
      formData.append(
        `guardians[${i}].primaryGuardian`,
        g.primaryGuardian!.toString()
      );
    });

    try {
      const res = await api.post(
        `/rest/v2/pet?groupId=${partner.id}&platform=PARTNER_WEB`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res) {
        toast.success("고객 정보가 저장되었습니다");
        initInfo();
        if (fetchCurrentPage) await fetchCurrentPage(res);
        popPopup();
        trackSaveNewCustomer({
          profileImgFile: customerInfo.profileImgFile,
          name: customerInfo.name,
          species: customerInfo.breed.name,
          sex: customerInfo.sex,
          neutralized: customerInfo.neutralized,
          birthday: customerInfo.birthday,
          age: customerInfo.age,
          months: customerInfo.months,
          weight: customerInfo.weight,
          allergy: customerInfo.allergy,
          note: customerInfo.note,
          guardians: customerInfo.guardians,
        });
      }
    } catch (e) {
      toast.error("고객을 저장할 수 없습니다");
    }
  };

  const saveCustomer = async (customerInfo: CustomerInfo) => {
    setIsLoading(true);

    if (
      isNil(petId) ||
      isNil(customerInfo.name) ||
      isNil(customerInfo.breed) ||
      isNil(customerInfo.guardians) ||
      isNil(guardiansInfo) ||
      isNil(petInfo) ||
      customerInfo.guardians.find((g) => isNil(g.cellnumber))
    ) {
      setIsLoading(false);
      return;
    }

    const formData = new FormData();

    const { guardians, ...pet } = customerInfo;

    const guardiansInfoChanged = checkGuardiansInfoChanged(
      guardiansInfo,
      guardians as Guardian[]
    );
    const petInfoChanged = checkPetInfoChanged(petInfo, pet);

    if (petInfoChanged) {
      if (data.profileImgFile) formData.append("file", data.profileImgFile);
      formData.append("name", customerInfo.name);
      formData.append("species.id", customerInfo.breed.id.toString());
      formData.append("species.name", customerInfo.breed.name);
      if (!isNil(customerInfo.sex))
        formData.append("sex", customerInfo.sex.toString());
      if (!isNil(customerInfo.neutralized) && customerInfo.neutralized !== -1)
        formData.append(
          "neuteredStatus",
          NEUTRALIZED_VALUES[customerInfo.neutralized]
        );

      if (!isNil(customerInfo.birthday))
        formData.append("birthday", format(customerInfo.birthday, "yyyyMMdd"));
      if (!isNil(customerInfo.age))
        formData.append(
          "age",
          format(customerInfo.age, customerInfo.age.toString())
        );
      if (!isNil(customerInfo.months))
        formData.append(
          "months",
          format(customerInfo.months, customerInfo.months.toString())
        );
      if (!isNil(customerInfo.weight)) {
        formData.append("weight", customerInfo.weight);
      }
      if (!isNil(customerInfo.allergy))
        formData.append("allergy", customerInfo.allergy);
      if (!isNil(customerInfo.note)) formData.append("note", customerInfo.note);

      formData.append(`id`, petId.toString());
    }

    if (guardiansInfoChanged) {
      customerInfo.guardians.forEach((g, i) => {
        formData.append(`guardians[${i}].cellnumber`, g.cellnumber!);
        if (!isNil(g.id))
          formData.append(`guardians[${i}].id`, g.id.toString());
        if (!isNil(g.name)) formData.append(`guardians[${i}].name`, g.name);
        if (!isNil(g.note)) formData.append(`guardians[${i}].note`, g.note);
        formData.append(
          `guardians[${i}].primaryGuardian`,
          g.primaryGuardian!.toString()
        );
      });
    }

    try {
      await api.put(
        `/rest/v2/pet/${petId}?groupId=${partner.id}&platform=PARTNER_WEB`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success("고객 정보를 저장하였습니다");
      fetchCurrentPage();
      popPopup();
      trackSaveCustomer({
        profileImgFile: customerInfo.profileImgFile,
        name: customerInfo.name,
        species: customerInfo.breed.name,
        sex: customerInfo.sex,
        neutralized: customerInfo.neutralized,
        birthday: customerInfo.birthday,
        age: customerInfo.age,
        months: customerInfo.months,
        weight: customerInfo.weight,
        allergy: customerInfo.allergy,
        note: customerInfo.note,
        guardians: customerInfo.guardians,
      });
    } catch (e) {
      toast.error("고객 정보를 저장할 수 없습니다");
    }
  };

  const fetchCustomerInfo = async () => {
    setIsLoading(true);
    const { petInfo, guardians }: { petInfo: PetInfo; guardians: Guardian[] } =
      await api.get(`/rest/v2/pet/${petId}/detail?groupId=${partner.id}`);

    setPetInfo({ ...petInfo });
    setGuardiansInfo(guardians);

    initInfo({
      ...petInfo,
      guardians,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    if (petId) fetchCustomerInfo();
    else initInfo();
  }, [petId]);

  useEffect(() => {
    if (data.guardians.every((g) => !g.primaryGuardian)) {
      const newGuardians = cloneDeep(data.guardians);
      newGuardians[0].primaryGuardian = true;

      setData({
        guardians: newGuardians,
      });
    }
  }, [JSON.stringify(data.guardians)]);

  useEffect(() => {
    return () => initInfo();
  }, []);

  useEffect(() => {
    if (showTooltip && isSavable) {
      setLocalStorageItem(
        "CUSTOMER-CREATE_NEW_CUSTOMER-FILL_REQUIRED_INFO",
        "TRUE"
      );
      setShowTooltip(false);
    }
  }, [isSavable]);

  return (
    <VFlex f-1 a-st rel ovf-h style={{ minHeight: "95vh", width: "1024px" }}>
      <HFlex p-24>
        <Text t-24-600-s8>{petId ? "고객 정보 수정" : "새로운 고객 등록"}</Text>
        <Flex f-1 />
        <Image
          size={24}
          src={`/icons/icon_close_s6.svg`}
          clickable
          onClick={() => {
            if (isChanged) {
              pushPopup({
                element: ConfirmPopup,
                kind: PopupKind.Popup,
                align: PopupAlign.TopCenter,
                props: {
                  title: `변경사항을 저장하지 않고 닫을까요?`,
                  confirmButtonType: "delete",
                  confirmButtonLabel: "닫기",
                  onConfirm: popPopup,
                },
              });
            } else {
              popPopup();
            }
          }}
        />
      </HFlex>
      <HFlex height={36} p-24-rl sized bd-b-t3>
        <TabBar
          value={tabs.find((t) => tabs[selectedTab].key === t.key)!}
          items={tabs}
          onChange={(tab) =>
            setSelectedTab(tabs.findIndex((t) => tab.key === t.key))
          }
        />
      </HFlex>
      <LoadingView loading={isLoading} className="f-v f-1 ovf-h">
        <VFlex f-1 ovf-h>
          {tabs[selectedTab].component()}
        </VFlex>
        <HFlex
          sized
          p-24
          style={{
            width: "100%",
            boxShadow:
              "0px -2px 12px 1px rgba(0, 0, 0, 0.08), 0px -1px 2px 0px rgba(0, 0, 0, 0.08)",
          }}
        >
          <Flex f-1 />

          <VFlex rel>
            <ButtonV2
              size="M"
              type="filled"
              enabled={petId ? isChanged && isSavable : isSavable}
              onClick={() => {
                if (!isSavable) {
                  const errorType = validate(data);
                  showAlertToast(ValidationErrorMsg[errorType]);

                  if (errorType === "GUARDIANS") {
                    setSelectedTab(1);
                  } else {
                    setSelectedTab(0);
                  }
                } else if (petId && isChanged) {
                  saveCustomer(data);
                } else if (isNil(petId)) {
                  createNewCustomer(data);
                } else {
                  return;
                }
              }}
            >
              저장
            </ButtonV2>
            {showTooltip && (
              <Tooltip
                type="bc"
                arrowOffsetRight={32}
                positionTop={-60}
                positionLeft={-242}
                content={
                  <HFlex g-12>
                    <Text>필수 정보를 모두 입력하면 버튼이 활성화됩니다.</Text>
                    <Image
                      src="/icons/icon_close_tw.svg"
                      size={20}
                      clickable
                      onClick={() => {
                        setLocalStorageItem(
                          "CUSTOMER-CREATE_NEW_CUSTOMER-FILL_REQUIRED_INFO",
                          "TRUE"
                        );
                        setShowTooltip(false);
                      }}
                    />
                  </HFlex>
                }
              />
            )}
          </VFlex>
        </HFlex>
      </LoadingView>
    </VFlex>
  );
};

export default AddCustomer;
