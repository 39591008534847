import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import SwiperCore from "swiper";

import VFlex from "../layouts/VFlex";
import Image from "../layouts/Image";
import HFlex from "../layouts/HFlex";
import Text from "../layouts/Text";

import "swiper/css";
import "swiper/css/pagination";
import "./SidebarBanner.scss";
import api from "../common/api";
import { isNil } from "lodash";
import { useAmplitudeTrack } from "../common/useAmplitudeTrack";

SwiperCore.use([Pagination]);

interface BannerItem {
  imageName: string;
  url: string;
  backgroundUrl: string;
  sortOrder: number;
}

type GetBannerItems = BannerItem[] | false;

const getBannerItems = async () => {
  const res = await api.get<GetBannerItems>(`/rest/banner/dashboard`);

  return res;
};

const SidebarBanner = () => {
  const { trackClickSidebarBanner } = useAmplitudeTrack();
  const [items, setItems] = useState<BannerItem[] | null>(null);

  useEffect(() => {
    getBannerItems().then((res) => {
      if (!res) {
        setItems(null);
      } else {
        setItems(res);
      }
    });
  }, []);

  if (isNil(items) || items.length <= 0) return <></>;

  return (
    <VFlex width={"100%"} p-16-rl ovf-h c-c m-32-t rel clickable>
      <Swiper
        spaceBetween={0}
        style={{ width: "100%", overflow: "hidden", paddingBottom: "18px" }}
        centeredSlides
        loop
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
      >
        {items.map((item) => (
          <SwiperSlide
            style={{ display: "inline-block", width: "208px" }}
            key={item.imageName}
          >
            <VFlex
              width={208}
              bdr-16
              bd-t5
              onClick={() => {
                trackClickSidebarBanner(item.imageName, item.url);
                window.open(item.url, `_blank`);
              }}
            >
              <Image src={item.backgroundUrl} width={208} height={218} />
              <HFlex bc-w width={"100%"} p-10-tb c-c>
                <Text t-14-600-s8>상품 보러가기</Text>

                <Image src="/icons/chevron_right_s8.svg" size={20} />
              </HFlex>
            </VFlex>
          </SwiperSlide>
        ))}
      </Swiper>
    </VFlex>
  );
};

export default SidebarBanner;
