import React, { MouseEvent, useMemo, useState } from "react";

import { Service } from "../../../providers/types";
import { useViewType } from "../../../providers/viewType";
import { sumAmountOfServiceProducts } from "../../../common/utils/menus";
import { numberWithCommas } from "../../../common/utils";

import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Text from "../../../layouts/Text";
import Button from "../../../layouts/Button";
import Image from "../../../layouts/Image";
import Absolute from "../../../layouts/Absolute";
import Flex from "../../../layouts/Flex";
import Snackbar from "../../../layouts/Snackbar";

import TabBar, { TabItemType } from "../../../components/TabBar";
import MenuListV2 from "../Menus/MenuListV2";

import toast from "react-hot-toast";

function MenuSelect({
  popPopup,
  selectedProducts,
  onSubmit,
}: {
  popPopup: () => void;
  selectedProducts: Service[];
  onSubmit: (selectedProducts: Service[]) => void;
}) {
  const [innerSelectedProducts, setInnerSelectedProducts] =
    useState<Service[]>(selectedProducts);
  const [searchText, setSearchText] = useState<string>();
  const { viewType, setViewType } = useViewType();

  const tabs = useMemo<TabItemType[]>(
    () => [
      {
        key: "1",
        name: "전체",
        component: ({
          innerSelectedProducts,
          setInnerSelectedProducts,
          searchText,
          setSearchText,
        }) => (
          <MenuListV2
            selectionMode={true}
            selectedProducts={innerSelectedProducts}
            setSelectedProducts={setInnerSelectedProducts}
            searchText={searchText}
            setSearchText={setSearchText}
            // viewType={viewType}
            // setViewType={setViewType}
          />
        ),
      },
      {
        key: "2",
        name: "메인 메뉴",
        component: ({
          innerSelectedProducts,
          setInnerSelectedProducts,
          searchText,
          setSearchText,
        }) => (
          <MenuListV2
            groupFilter="메인 메뉴"
            selectionMode={true}
            selectedProducts={innerSelectedProducts}
            setSelectedProducts={setInnerSelectedProducts}
            searchText={searchText}
            setSearchText={setSearchText}
            // viewType={viewType}
            // setViewType={setViewType}
          />
        ),
      },
      {
        key: "3",
        name: "추가 메뉴",
        component: ({
          innerSelectedProducts,
          setInnerSelectedProducts,
          searchText,
          setSearchText,
        }) => (
          <MenuListV2
            groupFilter="추가 메뉴"
            selectionMode={true}
            selectedProducts={innerSelectedProducts}
            setSelectedProducts={setInnerSelectedProducts}
            searchText={searchText}
            setSearchText={setSearchText}
            // viewType={viewType}
            // setViewType={setViewType}
          />
        ),
      },
      {
        key: "4",
        name: "기타",
        component: ({
          innerSelectedProducts,
          setInnerSelectedProducts,
          searchText,
          setSearchText,
        }) => (
          <MenuListV2
            groupFilter="기타"
            selectionMode={true}
            selectedProducts={innerSelectedProducts}
            setSelectedProducts={setInnerSelectedProducts}
            searchText={searchText}
            setSearchText={setSearchText}
            // viewType={viewType}
            // setViewType={setViewType}
          />
        ),
      },
    ],
    [
      // viewType
    ]
  );
  const [selectedTab, setSelectedTab] = useState<TabItemType>(tabs[0]);
  const [removeProductVisible, setRemoveProductVisible] = useState(false);

  const increaseQuantity = (service: Service) => {
    if (
      innerSelectedProducts.find((p) => p.product.id === service.product.id)
    ) {
      setInnerSelectedProducts((products) => {
        const newSelectedProduct = [...products];
        const index = newSelectedProduct.findIndex(
          (p) => p.product.id === service.product.id
        );

        newSelectedProduct[index] = {
          product: service.product,
          quantity: newSelectedProduct[index].quantity + 1,
        };

        return newSelectedProduct;
      });
    } else {
      setInnerSelectedProducts((p) => [
        ...p,
        { product: service.product, quantity: 1 },
      ]);
    }
  };

  const decreaseQuantity = (service: Service) => {
    if (
      innerSelectedProducts.find((p) => p.product.id === service.product.id)!
        .quantity > 1
    ) {
      setInnerSelectedProducts((products) => {
        const newSelectedProduct = [...products];
        const index = newSelectedProduct.findIndex(
          (p) => p.product.id === service.product.id
        );

        newSelectedProduct[index] = {
          product: service.product,
          quantity: newSelectedProduct[index].quantity - 1,
        };

        return newSelectedProduct;
      });
    }
  };

  const deleteService = (service: Service) => {
    setInnerSelectedProducts((products) =>
      products.filter((p) => p.product.id !== service.product.id)
    );
  };

  return (
    <VFlex
      a-st
      ovf-h
      style={{
        height: "95vh",
        minHeight: "600px",
        maxHeight: "850px",
        maxWidth: "1024px",
        width: "95vw",
      }}
    >
      <HFlex a-c j-b p-24-rl sized height={86} bd-b-t2>
        <HFlex a-c g-16>
          <Image
            size={24}
            src={`/icons/navigation/arrow_back_ios.svg`}
            clickable
            onClick={() => {
              popPopup();
            }}
          />
          <Text t-24-600>메뉴(상품) 선택</Text>
        </HFlex>
        {/* <Image size={24} src={`/icons/content/edit_s6.svg`} clickable /> */}
      </HFlex>
      <HFlex
        ovf-h
        style={{
          height: 760,
        }}
      >
        <VFlex f-1 bd-r-t3>
          <VFlex p-14-t p-24-rl height={50} bd-b-t3 sized>
            <TabBar
              value={selectedTab}
              items={tabs}
              onChange={(item) => setSelectedTab(item)}
            />
          </VFlex>
          {selectedTab &&
            React.createElement(selectedTab.component, {
              innerSelectedProducts,
              setInnerSelectedProducts,
              searchText,
              setSearchText,
            })}
        </VFlex>
        <VFlex width={375} rel ovf-h>
          <HFlex p-16-tb p-24-rl>
            <Text t-16-600-s8>Total ({innerSelectedProducts.length})</Text>
          </HFlex>

          <VFlex
            ovf-a
            style={{
              paddingBottom: 24,
            }}
          >
            <VFlex f-1 p-24-rl g-12>
              {innerSelectedProducts.length > 0 ? (
                innerSelectedProducts.map((p) => {
                  return (
                    <SelectedItem
                      key={p.product.id}
                      p={p}
                      increaseQuantity={increaseQuantity}
                      decreaseQuantity={decreaseQuantity}
                      deleteService={deleteService}
                      setRemoveProductVisible={setRemoveProductVisible}
                    />
                  );
                })
              ) : (
                <VFlex
                  a-c
                  j-c
                  bd-t3
                  bdr-16
                  g-8
                  style={{
                    padding: "100px 0",
                  }}
                >
                  <Image size={24} src={`/icons/icon_noti.svg`} />
                  <Text t-16-s2>선택된 상품이 없어요</Text>
                </VFlex>
              )}
            </VFlex>
          </VFlex>

          <Flex f-1 />

          <VFlex p-24 p-40-b g-16 bd-t-t2>
            <HFlex a-c j-b>
              <Text t-16-600-s8>서비스 합계</Text>
              <Text t-20-600-gr5>
                {numberWithCommas(
                  sumAmountOfServiceProducts(innerSelectedProducts)
                )}
                원
              </Text>
            </HFlex>
            <Button
              height={56}
              enabled={innerSelectedProducts.length > 0}
              type="confirm"
              onClick={() => {
                // if (
                //   !innerSelectedProducts
                //     .map((s) => {
                //       return s.product.menuType.id;
                //     })
                //     .includes(198)
                // ) {
                //   return toast.error("메인 상품을 필수로 선택해주세요.");
                // }
                onSubmit?.(innerSelectedProducts);
                popPopup();
              }}
            >
              <Text t-16-600-w>
                {innerSelectedProducts.length > 0
                  ? `상품 ${innerSelectedProducts.length}개 저장`
                  : "저장"}
              </Text>
            </Button>
          </VFlex>
        </VFlex>

        {removeProductVisible && (
          <Absolute top={"auto"} bottom={"48"} left={"24"} right={"auto"}>
            <Snackbar
              text={"상품이 선택해제 되었습니다."}
              // action={"실행취소"}
              close
              actionOnClick={() => {}}
              onClick={() => {
                setRemoveProductVisible(false);
              }}
            />
          </Absolute>
        )}
      </HFlex>

      {removeProductVisible && (
        <Absolute
          height={"85%"}
          onClick={(e: MouseEvent) => {
            setRemoveProductVisible(false);
            e.stopPropagation();
          }}
          style={{ zIndex: 1 }}
        />
      )}
    </VFlex>
  );
}

export default MenuSelect;

const SelectedItem = ({
  p,
  increaseQuantity,
  decreaseQuantity,
  deleteService,
  setRemoveProductVisible,
}: {
  p: Service;
  increaseQuantity: (p: Service) => void;
  decreaseQuantity: (p: Service) => void;
  deleteService: (p: Service) => void;
  setRemoveProductVisible: any;
}) => {
  return (
    <VFlex p-16 g-12 bd-t5 bdr-16 key={p.product.id}>
      <HFlex j-b a-s g-16>
        <Text t-14-500-s7 l-m>
          {p.product.name}
        </Text>
        <Image
          src={`/icons/navigation/close_s3.svg`}
          size={24}
          clickable
          onClick={() => {
            deleteService(p);
            setRemoveProductVisible(true);
          }}
        />
      </HFlex>
      <HFlex
        a-c
        j-b={p.product.menuType.id !== 198}
        j-e={p.product.menuType.id === 198}
      >
        {p.product.menuType.id !== 198 && (
          <HFlex
            width={110}
            p-4-tb
            p-8-rl
            bd-t5
            style={{
              borderRadius: 99,
            }}
          >
            <Flex
              f-1
              c-c
              clickable
              onClick={() => {
                if (p.quantity === 1) {
                  deleteService(p);
                  setRemoveProductVisible(true);
                } else {
                  decreaseQuantity(p);
                }
              }}
            >
              <Image
                src={`/icons/${
                  p.quantity === 1 ? "content/delete" : "action/remove"
                }_s6.svg`}
                size={20}
              />
            </Flex>
            <Flex f-1 c-c t-16-500-s8>
              {p.quantity}
            </Flex>
            <Flex
              f-1
              c-c
              clickable={p.product.stockQuantity > p.quantity}
              onClick={() => {
                if (p.product.stockQuantity <= p.quantity) {
                  return;
                }
                increaseQuantity(p);
              }}
            >
              <Image
                src={`/icons/content/add_s6.svg`}
                size={20}
                style={{
                  opacity: p.product.stockQuantity <= p.quantity ? 0.5 : 1,
                }}
              />
            </Flex>
          </HFlex>
        )}

        <Text t-16-600-s8>
          {(p.product.price * p.quantity).toLocaleString()}원
        </Text>
      </HFlex>
      {/* TODO */}
      {/* <VFlex bd-t-t2>
      <HFlex p-12-t g-4 a-c>
        <Image src={`/icons/action/package_s4.svg`} size={16} />
        <Text t-12-s4>{"80"}개 남았어요</Text>
      </HFlex>
    </VFlex> */}
    </VFlex>
  );
};
