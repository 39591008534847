import HFlex from "../layouts/HFlex";
import Image from "../layouts/Image";
import { ButtonType } from "../layouts/index_generated";
import Text from "../layouts/Text";
import VFlex from "../layouts/VFlex";
import { pushPopup } from "../providers/popups";
import ConfirmPopup from "./ConfirmPopup";
import { PopupAlign, PopupKind } from "./Popup";

export type ContextMenuProps = {
  icon: string;
  isErrorText: boolean;
  title: string;
  confirmButtonTitle?: string;
  confirmButtonLabel?: string;
  confirmButtonType?: ButtonType;
  onConfirm?: () => void;
  onClick?: () => void;
};

export function ContextMenu({
  popPopup,
  menus,
}: {
  popPopup: () => void;
  menus: ContextMenuProps[];
}) {
  return (
    <VFlex p-16-rl bdr-16 bc-w bd-t3 sdw-1 width={212}>
      {menus.map(
        (
          {
            icon,
            isErrorText,
            title,
            confirmButtonTitle,
            confirmButtonLabel,
            confirmButtonType,
            onConfirm,
            onClick,
          },
          i
        ) => (
          <HFlex
            key={i}
            height={56}
            a-c
            g-8
            clickable
            onClick={async () => {
              popPopup();
              if (onClick) {
                onClick();
              } else if (onConfirm) {
                pushPopup({
                  kind: PopupKind.Popup,
                  align: PopupAlign.TopCenter,
                  element: ConfirmPopup,
                  props: {
                    title: confirmButtonTitle,
                    confirmButtonLabel,
                    confirmButtonType,
                    onConfirm,
                  },
                });
              }
            }}
          >
            {icon && <Image size={24} src={icon} />}
            <Text t-16-700-err2={!!isErrorText} t-16-700={!isErrorText}>
              {title}
            </Text>
          </HFlex>
        )
      )}
    </VFlex>
  );
}
