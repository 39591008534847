import React, { useEffect, useMemo, useState } from "react";
import TabBar, { TabItemType } from "../../../components/TabBar";
import HFlex from "../../../layouts/HFlex";
import Text from "../../../layouts/Text";
import VFlex from "../../../layouts/VFlex";
import MenuList from "./MenuList";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";

function Menus() {
  const { trackLandServiceMenu, trackClickServiceMenuTab } =
    useAmplitudeTrack();

  useEffect(() => {
    trackLandServiceMenu();
  }, []);

  const [searchText, setSearchText] = useState<string>();
  const tabs = useMemo<TabItemType[]>(
    () => [
      {
        key: "1",
        name: "전체",
        component: ({ searchText, setSearchText }) => (
          <MenuList searchText={searchText} setSearchText={setSearchText} />
        ),
      },
      {
        key: "2",
        name: "메인 메뉴",
        component: ({ searchText, setSearchText }) => (
          <MenuList
            groupFilter="메인 메뉴"
            searchText={searchText}
            setSearchText={setSearchText}
          />
        ),
      },
      {
        key: "3",
        name: "추가 메뉴",
        component: ({ searchText, setSearchText }) => (
          <MenuList
            groupFilter="추가 메뉴"
            searchText={searchText}
            setSearchText={setSearchText}
          />
        ),
      },
      {
        key: "4",
        name: "기타",
        component: ({ searchText, setSearchText }) => (
          <MenuList
            groupFilter="기타"
            searchText={searchText}
            setSearchText={setSearchText}
          />
        ),
      },
    ],
    []
  );
  const [selectedTab, setSelectedTab] = useState<TabItemType>(tabs[0]);
  return (
    <VFlex height={"100%"}>
      <HFlex a-c p-32-rl sized height={96}>
        <Text t-24-700 f-1>
          서비스 메뉴
        </Text>
      </HFlex>
      <VFlex p-32-rl height={42} sized bd-b-t3>
        <TabBar
          value={selectedTab}
          items={tabs}
          onChange={(item) => {
            setSelectedTab(item);
            trackClickServiceMenuTab(item.name);
          }}
        />
      </VFlex>
      {selectedTab &&
        React.createElement(selectedTab.component, {
          searchText,
          setSearchText,
        })}
    </VFlex>
  );
}

export default Menus;
