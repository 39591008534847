import { isNil } from "lodash";
import { useMemo } from "react";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import api from "../common/api";
import { usePartner } from "./partner";
import { Memo, MEMO_STATUS_VALUES, NoticeMemoType, Partner } from "./types";

const noticesStore = create(
  immer<{
    fetchPostNotice: (
      partner: Partner
    ) => ({
      reservationId,
      photos,
      memos,
    }: {
      reservationId: number;
      photos: File[];
      memos: Memo<keyof typeof NoticeMemoType>[];
    }) => void;
  }>(() => ({
    fetchPostNotice:
      (partner: Partner) =>
      async ({
        reservationId,
        photos,
        memos,
      }: {
        reservationId: number;
        photos: File[];
        memos: Memo<keyof typeof NoticeMemoType>[];
      }) => {
        const formData = new FormData();
        let memoIndex = 0;

        memos.forEach((memo) => {
          if (!isNil(memo.value)) {
            formData.append(`notes[${memoIndex}].type`, memo.type);
            formData.append(
              `notes[${memoIndex}].condition`,
              MEMO_STATUS_VALUES[memo.value]
            );

            formData.append(`notes[${memoIndex}].note`, memo.note ?? "");
            memoIndex += 1;
          } else if (memo.type === "N_B_NOTICE" && memo.note) {
            formData.append(`notes[${memoIndex}].type`, memo.type);
            formData.append(
              `notes[${memoIndex}].condition`,
              MEMO_STATUS_VALUES[1]
            );

            formData.append(`notes[${memoIndex}].note`, memo.note);
            memoIndex += 1;
          }
        });

        photos.forEach((photo, index) =>
          formData.append(`files[${index}]`, photo)
        );

        formData.append("status", "COMPLETED");

        await api.post(
          `/rest/group/${partner.id}/reservation/${reservationId}/notice`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      },
  }))
);

export const useNotices = () => {
  const { partner } = usePartner();
  const { fetchPostNotice, ...rest } = noticesStore();
  return {
    fetchPostNotice: useMemo(
      () => fetchPostNotice(partner),
      [fetchPostNotice, partner]
    ),
    ...rest,
  };
};
