import "./App.scss";
import "./table.scss";
import { Routes, Route, useNavigate } from "react-router-dom";
import Login from "./scenes/Login/Login";
import Signup from "./scenes/Signup/Signup";
import Main from "./scenes/Main/Main";
import {
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useRef } from "react";
import { initializeAxios } from "./common/api";
import "./common/firebase";
import LoginProblem from "./scenes/Login/LoginProblem";
import LoginCustomerService from "./scenes/Login/LoginCustomerService";
import LoginForgotPassword from "./scenes/Login/LoginForgotPassword";
import LoginChangePassword from "./scenes/Login/LoginChangePassword";
import LoginConfirmPassword from "./scenes/Login/LoginConfirmPassword";
import SignupPassword from "./scenes/Signup/SignupPassword";
import SignupCompany from "./scenes/Signup/SignupCompany";
import SignupCompanyDetails from "./scenes/Signup/SignupCompanyDetails";
import VFlex from "./layouts/VFlex";
import { pushPopup } from "./providers/popups";
import { NewPopup } from "./popup/Popup";
import { Staff } from "./providers/types";
import Toast from "./common/Toast";
import * as amplitude from "@amplitude/analytics-browser";
import NewReservationCalendarView from "./scenes/Main/Calendar/NewReservtaion/NewReservationCalendarView";
import useIsMobile from "./common/useIsMobile";
import Redirect from "./common/utils/RedirectAppDownload";

export type PopupAnchorActions = {
  pushPopup: (
    staffs?: Staff[] | undefined,
    startDateTime?: Date,
    endDateTime?: Date,
    type?: string
  ) => void;
};

export const PopupAnchor = forwardRef<PopupAnchorActions, any>(
  (
    {
      popup,
      children,
    }: {
      popup: NewPopup;
      children: ReactElement;
    },
    ref
  ) => {
    useImperativeHandle(
      ref,
      () => ({
        pushPopup: (staffs, startDateTime, endDateTime, type) => {
          const { bottom, right } = divRef.current?.getBoundingClientRect()!;
          pushPopup({
            ...popup,
            x: right,
            y: bottom + 8,
            props: { initialStaffs: staffs, startDateTime, endDateTime, type },
          });
        },
      }),
      [popup]
    );
    const divRef = useRef<HTMLDivElement>(null);
    return (
      <VFlex
        ref={divRef}
        c-c
        onClick={(e) => {
          const { bottom, right } = divRef.current?.getBoundingClientRect()!;
          pushPopup({ ...popup, x: right, y: bottom + 8 });
          e.stopPropagation();
        }}
      >
        {children}
      </VFlex>
    );
  }
);

function App() {
  const amplitudeApikey = process.env.REACT_APP_AMPLITUDE_API_KEY;
  amplitude.init(amplitudeApikey as string);

  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    initializeAxios(localStorage.getItem("token"));
    (async () => {
      await Promise.all([
        document.fonts.load("12px Font-400"),
        document.fonts.load("12px Font-600"),
        document.fonts.load("12px Font-800"),
      ]);
      setInitialized(true);
    })();
  }, [initialized]);
  useEffect(() => {
    if (isMobile && window.location.pathname !== "/redirect") {
      navigate("/redirect");
    }
  }, [isMobile, JSON.stringify(window.location.pathname)]);
  if (!initialized) {
    return <></>;
  }
  return (
    <VFlex width={"100vw"} height={"100vh"}>
      <Routes>
        <Route path="" element={<Login />} />
        <Route path="/problem" element={<LoginProblem />} />
        <Route path="/forgotpassword" element={<LoginForgotPassword />} />
        <Route path="/changepassword" element={<LoginChangePassword />} />
        <Route path="/confirmpassword" element={<LoginConfirmPassword />} />
        <Route path="/customerservice" element={<LoginCustomerService />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/signup/password" element={<SignupPassword />} />
        <Route path="/signup/company" element={<SignupCompany />} />
        <Route
          path="/signup/companydetails"
          element={<SignupCompanyDetails />}
        />
        <Route path="/main" element={<Main />} />
        <Route path="/main/:tab" element={<Main />} />
        <Route
          path="/main/reservation/create"
          element={<NewReservationCalendarView />}
        />
        <Route path="/redirect" element={<Redirect />} />
      </Routes>
      <Toast />
    </VFlex>
  );
}

export default App;
