import { MouseEvent } from "react";
import ColumnResize from "react-table-column-resizer";

import { Reservations } from "../providers/types";
import { scheduleStatusString } from "../common/constants";

import Image from "./Image";
import Text from "./Text";
import VFlex from "./VFlex";
import HFlex from "./HFlex";

import { format } from "date-fns";
import { ko } from "date-fns/locale";

import "./ResizeTable.scss";

function ResizeTable({
  data,
  onClick,
}: {
  data: Reservations[];
  onClick: (data: Reservations) => void;
}) {
  return (
    <table>
      <thead>
        <tr style={{ height: "46px" }}>
          {/* <th>
            <VFlex a-c j-c>
              <Image
                clickable
                onClick={(e: MouseEvent) => {
                  e.stopPropagation();
                }}
                src={"/icons/checkbox_unchecked.svg"}
                size={22}
              />
            </VFlex>
          </th>
          <ColumnResize
            id={1}
            resizeEnd={(width) => {}}
            resizeStart={() => {}}
            className="columnResizer"
            defaultWidth={31}
            minWidth={44}
            maxWidth={null}
            disabled
          /> */}
          <th>
            <Text l-1>예약 상태</Text>
          </th>
          <ColumnResize
            id={2}
            resizeEnd={(width) => {}}
            resizeStart={() => {}}
            className="columnResizer"
            defaultWidth={127}
            minWidth={44}
            maxWidth={null}
            disabled={false}
          />
          <th>
            <Text l-1>고객명</Text>
          </th>
          <ColumnResize
            id={3}
            resizeEnd={(width) => {}}
            resizeStart={() => {}}
            className="columnResizer"
            defaultWidth={127}
            minWidth={44}
            maxWidth={null}
            disabled={false}
          />
          <th>
            <Text l-1>품종</Text>
          </th>
          <ColumnResize
            id={4}
            resizeEnd={(width) => {}}
            resizeStart={() => {}}
            className="columnResizer"
            defaultWidth={127}
            minWidth={44}
            maxWidth={null}
            disabled={false}
          />
          <th>
            <Text l-1>예약자명</Text>
          </th>
          <ColumnResize
            id={5}
            resizeEnd={(width) => {}}
            resizeStart={() => {}}
            className="columnResizer"
            defaultWidth={127}
            minWidth={44}
            maxWidth={null}
            disabled={false}
          />
          <th>
            <Text l-1>휴대폰 번호</Text>
          </th>
          <ColumnResize
            id={6}
            resizeEnd={(width) => {}}
            resizeStart={() => {}}
            className="columnResizer"
            defaultWidth={127}
            minWidth={44}
            maxWidth={null}
            disabled={false}
          />
          <th>
            <Text l-1>예약 번호</Text>
          </th>
          <ColumnResize
            id={7}
            resizeEnd={(width) => {}}
            resizeStart={() => {}}
            className="columnResizer"
            defaultWidth={127}
            minWidth={44}
            maxWidth={null}
            disabled={false}
          />
          <th>
            <Text l-1>예약 일시</Text>
          </th>
          <ColumnResize
            id={8}
            resizeEnd={(width) => {}}
            resizeStart={() => {}}
            className="columnResizer"
            defaultWidth={217}
            minWidth={44}
            maxWidth={null}
            disabled={false}
          />
          <th>
            <Text l-1>서비스명</Text>
          </th>
          <ColumnResize
            id={8}
            resizeEnd={(width) => {}}
            resizeStart={() => {}}
            className="columnResizer"
            defaultWidth={217}
            minWidth={44}
            maxWidth={null}
            disabled={false}
          />
          <th>
            <Text l-1>담당자</Text>
          </th>
          <ColumnResize
            id={8}
            resizeEnd={(width) => {}}
            resizeStart={() => {}}
            className="columnResizer"
            defaultWidth={127}
            minWidth={44}
            maxWidth={null}
            disabled={false}
          />
          {data[0].histories && (
            <>
              <th>
                <Text l-1>
                  {data[0].histories[0].type === "HIDDEN"
                    ? "숨긴 처리 날짜"
                    : "예약 삭제 날짜"}
                </Text>
              </th>
              <ColumnResize
                id={8}
                resizeEnd={(width) => {}}
                resizeStart={() => {}}
                className="columnResizer"
                defaultWidth={127}
                minWidth={44}
                maxWidth={null}
                disabled={false}
              />
              <th>
                <Text l-1>
                  {data[0].histories[0].type === "HIDDEN"
                    ? "숨긴 직원"
                    : "예약 삭제 직원"}
                </Text>
              </th>
              <ColumnResize
                id={8}
                resizeEnd={(width) => {}}
                resizeStart={() => {}}
                className="columnResizer"
                defaultWidth={127}
                minWidth={44}
                maxWidth={null}
                disabled={false}
              />
            </>
          )}
        </tr>
      </thead>
      <tbody>
        {data.map((data, i) => {
          return (
            <tr
              key={i}
              onClick={() => {
                onClick(data);
              }}
              className="hover"
            >
              {/* <td>
                <VFlex a-c j-c>
                  <Image
                    clickable
                    onClick={(e: MouseEvent) => {
                      e.stopPropagation();
                    }}
                    src={"/icons/checkbox_unchecked.svg"}
                    size={22}
                  />
                </VFlex>
              </td>
              <td className="column_resizer_body none" /> */}
              <td>
                <Text>{scheduleStatusString[data.status]}</Text>
              </td>
              <td className="column_resizer_body" />
              <td>
                <HFlex a-c g-12>
                  <Image
                    sized
                    bdr-12
                    width={36}
                    height={36}
                    src={
                      data.pet.attachment
                        ? data.pet.attachment.path
                        : `/icons/default_profile.png`
                    }
                  />
                  <Text l-1>{data.pet.name}</Text>
                </HFlex>
              </td>
              <td className="column_resizer_body" />
              <td>
                <Text l-1>{data.pet.species.name}</Text>
              </td>
              <td className="column_resizer_body" />
              <td>
                {data.reserver ? (
                  <Text l-1>{data.reserver.nickname || "보호자"}</Text>
                ) : (
                  <Text>-</Text>
                )}
              </td>
              <td className="column_resizer_body" />
              <td>
                {data.reserver ? (
                  <Text l-1>{data.reserver.cellnumber || "-"}</Text>
                ) : (
                  <Text>-</Text>
                )}
              </td>
              <td className="column_resizer_body" />
              <td>
                <Text l-1>{data.reservationNumber ?? "-"}</Text>
              </td>
              <td className="column_resizer_body" />
              <td>
                <Text l-1>{data.reservationPeriod}</Text>
              </td>
              <td className="column_resizer_body" />
              <td>
                <Text l-1>{data.productName ?? "-"}</Text>
              </td>
              <td className="column_resizer_body" />
              <td>
                <Text l-1>{data.chargerName}</Text>
              </td>
              <td
                className={
                  data.histories
                    ? "column_resizer_body"
                    : "column_resizer_body none"
                }
              />
              {data.histories && (
                <>
                  <td>
                    <Text l-1>
                      {format(
                        data.histories[0].created * 1000,
                        "yyyy. M. d (E)",
                        {
                          locale: ko,
                        }
                      )}
                    </Text>
                  </td>
                  <td className="column_resizer_body" />
                  <td>
                    <Text l-1>{data.histories[0].nickname}</Text>
                  </td>
                  <td className="column_resizer_body none" />
                </>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default ResizeTable;
