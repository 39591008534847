import { useEffect, useState } from "react";

import Divider from "../../layouts/Divider";
import HFlex from "../../layouts/HFlex";
import VFlex from "../../layouts/VFlex";
import Text from "../../layouts/Text";
import Notification from "../../layouts/Notification";
import Image from "../../layouts/Image";

import { format } from "date-fns";
import api from "../../common/api";
import { usePartner } from "../../providers/partner";
import toast from "react-hot-toast";
import { numberWithCommas } from "../../common/utils";

interface IVisitingStatistics {
  completedCount: number;
  paidCount: number;
  canceledCount: number;
  noShowCount: number;
  firstVisited: number | null;
  lastVisited: number | null;
  totalSales: number;
}

const VisitingStatistics = ({
  petId,
  isDeletedCustomer,
}: {
  petId: number;
  isDeletedCustomer: boolean;
}) => {
  const { partner } = usePartner();
  const [statistics, setStatistics] = useState<IVisitingStatistics>({
    completedCount: 0,
    paidCount: 0,
    canceledCount: 0,
    noShowCount: 0,
    firstVisited: null,
    lastVisited: null,
    totalSales: 0,
  });

  const fetchStatistics = async () => {
    try {
      const statistics = await api.get(
        `/rest/pet/${petId}/visit-statistics?groupId=${partner.id}`
      );
      setStatistics(statistics);
    } catch (e) {
      toast.error("방문 통계 정보를 불러오는데 실패했습니다");
    }
  };

  useEffect(() => {
    fetchStatistics();
  }, []);

  return (
    <>
      {isDeletedCustomer && (
        <VFlex>
          <Notification type={"full"} color="err">
            <HFlex a-c p-8-rl>
              <Image src="/icons/action/info_w.svg" size={22} m-12-r />
              <HFlex g-8>
                <Notification.Title color>
                  삭제된 고객입니다.
                </Notification.Title>
              </HFlex>
            </HFlex>
          </Notification>
        </VFlex>
      )}
      <VFlex p-24>
        <VFlex bc-tw bdr-16 p-24-tb p-32-rl>
          <HFlex p-16-b a-c>
            <VFlex c-c g-4 f-1>
              <Text t-22-600-s8>{statistics.completedCount}</Text>
              <Text t-12-500-s4>이용 완료</Text>
            </VFlex>
            <Divider width={1} height={24} style={{ alignSelf: "center" }} />
            <VFlex c-c g-4 f-1>
              <Text t-22-600-s8>{statistics.paidCount}</Text>
              <Text t-12-500-s4>결제 완료</Text>
            </VFlex>
            <Divider width={1} height={24} style={{ alignSelf: "center" }} />
            <VFlex c-c g-4 f-1>
              <Text t-22-600-s8>{statistics.canceledCount}</Text>
              <Text t-12-500-s4>예약 취소</Text>
            </VFlex>
            <Divider width={1} height={24} style={{ alignSelf: "center" }} />
            <VFlex c-c g-4 f-1>
              <Text t-22-600-s8>{statistics.noShowCount}</Text>
              <Text t-12-500-s4>노쇼</Text>
            </VFlex>
          </HFlex>

          <VFlex m-16-t>
            <HFlex a-c j-b p-16-tb>
              <Text t-14-500-s8>총 매출</Text>
              <Text f-1 t-14-500-s8 style={{ textAlign: "end" }}>
                {numberWithCommas(statistics.totalSales)}원
              </Text>
            </HFlex>
            <Divider height={1} />
            <HFlex a-c j-b p-16-tb>
              <HFlex g-8>
                <Text t-14-500-s8>최근 방문 날짜</Text>
              </HFlex>
              <Text f-1 t-14-500-s8 style={{ textAlign: "end" }}>
                {statistics.lastVisited
                  ? format(
                      new Date(statistics.lastVisited * 1000),
                      "yyyy.MM.dd"
                    )
                  : "-"}
              </Text>
            </HFlex>
            <Divider height={1} />
            <HFlex a-c j-b p-16-tb>
              <HFlex g-8>
                <Text t-14-500-s8>최초 방문 날짜</Text>
              </HFlex>
              <Text f-1 t-14-500-s8 style={{ textAlign: "end" }}>
                {statistics.firstVisited
                  ? format(
                      new Date(statistics.firstVisited * 1000),
                      "yyyy.MM.dd"
                    )
                  : "-"}
              </Text>
            </HFlex>
          </VFlex>
        </VFlex>
      </VFlex>
    </>
  );
};

export default VisitingStatistics;
