import { useMemo } from "react";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import api from "../common/api";
import { usePartner } from "./partner";
import { Partner, Staff } from "./types";

const staffStore = create(
  immer<{
    staffs: Staff[];
    selfStaff?: Staff;
    fetchStaffs: (partner: Partner) => () => void;
    setSelfStaff: (staff: Staff) => void;
  }>((set) => ({
    staffs: [],
    selfStaff: undefined,
    fetchStaffs: (partner: Partner) => async () => {
      const staffs = await api.get(`/rest/group/${partner.id}/staff`);
      set((state) => ({
        ...state,
        staffs,
        selfStaff: state.selfStaff ?? staffs[0],
      }));
    },
    setSelfStaff: (selfStaff: Staff) => {
      set((state) => ({
        ...state,
        selfStaff,
      }));
    },
  }))
);

export const useStaffs = () => {
  const { partner } = usePartner();
  const { selfStaff, fetchStaffs, ...rest } = staffStore();
  return {
    selfStaff: selfStaff!,
    fetchStaffs: useMemo(() => fetchStaffs(partner), [fetchStaffs, partner]),
    ...rest,
  };
};
