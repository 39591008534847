import { useState } from "react";
import format from "date-fns/format";
import { isNil } from "lodash";

import HFlex from "../../layouts/HFlex";
import VFlex from "../../layouts/VFlex";
import Text from "../../layouts/Text";
import Image from "../../layouts/Image";
import Flex from "../../layouts/Flex";
import Button from "../../layouts/Button";
import RadioGroup from "../../layouts/RadioGroup";
import DatePicker from "../DatePicker";
import Notification from "../../layouts/Notification";
import Dropdown from "../Dropdown";

import {
  estimateBirthYearMonth,
  parseDateString,
} from "../../common/utils/customers";
import {
  BIRTH_TYPE_OPTIONS,
  PET_BIRTHDAY_MAX_DATE,
  PET_BIRTHDAY_MIN_DATE,
} from "../../common/constants";

const EditPetBirth = ({
  popPopup,
  value,
  fetch,
}: {
  popPopup: () => void;
  value: {
    birthday?: string;
    age?: number;
    months?: number;
  };
  fetch: (v: { birthday?: string; age?: number; months?: number }) => void;
}) => {
  const [birthType, setBirthType] = useState<"BIRTHDAY" | "AGE" | undefined>(
    () => {
      if (
        value.birthday?.substring(4, 6) === "00" ||
        value.birthday?.substring(6, 8) === "00"
      )
        return "AGE";
      else if (value.birthday) return "BIRTHDAY";
      else return undefined;
    }
  );
  const [birthday, setBirthDay] = useState<Date | undefined>(
    value.birthday &&
      (value.birthday?.substring(4, 6) === "00" ||
        value.birthday?.substring(6, 8) === "00")
      ? undefined
      : value.birthday
      ? parseDateString(value.birthday)
      : undefined
  );
  const [age, setAge] = useState(isNil(birthday) ? value.age : undefined);
  const [months, setMonths] = useState(
    isNil(birthday) ? value.months : undefined
  );

  return (
    <VFlex width={530} p-24>
      <HFlex j-b a-c m-24-b>
        <Text t-24-600-s8>고객 정보 수정</Text>
        <Image
          src="/icons/icon_close_s6.svg"
          size={24}
          clickable
          onClick={popPopup}
        />
      </HFlex>
      <VFlex g-16>
        <RadioGroup
          caption="나이"
          options={BIRTH_TYPE_OPTIONS.map((opt) => opt.value)}
          renderOption={(opt, handleChange) => (
            <HFlex f-1 a-c g-8>
              <Image
                src={
                  birthType === opt
                    ? "/icons/checkbox_circle_outline_checked.svg"
                    : "/icons/checkbox_circle_outline_unchecked.svg"
                }
                size={24}
                onClick={() => {
                  handleChange(opt);
                  if (opt === BIRTH_TYPE_OPTIONS[0].value) {
                    setAge(undefined);
                    setMonths(undefined);
                  } else if (opt === BIRTH_TYPE_OPTIONS[1].value)
                    setBirthDay(undefined);
                }}
                clickable
              />
              <Text t-14-500-s8 onClick={() => handleChange(opt)} clickable>
                {
                  BIRTH_TYPE_OPTIONS.find((option) => opt === option.value)
                    ?.label
                }
              </Text>
            </HFlex>
          )}
          value={birthType}
          onChange={(v) => {
            setBirthType(v);
          }}
          allowUnselect={false}
        />
        {birthType === BIRTH_TYPE_OPTIONS[0].value && (
          <DatePicker
            date={birthday}
            minDate={PET_BIRTHDAY_MIN_DATE}
            maxDate={PET_BIRTHDAY_MAX_DATE}
            setDate={(dt) => {
              setBirthDay(dt);
            }}
            trigger={
              <HFlex p-24-rl p-16-tb bdr-16 bd-t5 bc-w clickable j-b>
                {birthday ? (
                  <Text t-16-500-s8>{`${format(
                    birthday,
                    "yyyy년 MM월 dd일"
                  )}`}</Text>
                ) : (
                  <Text t-16-500-s2>생년월일 선택</Text>
                )}

                <Image
                  src="/icons/navigation/arrow_drop_down_s3.svg"
                  size={22}
                />
              </HFlex>
            }
          />
        )}
        {birthType === BIRTH_TYPE_OPTIONS[1].value && (
          <VFlex g-8>
            <HFlex g-8>
              <Flex f-1>
                <Dropdown
                  placeholder={"년"}
                  value={age}
                  options={[...Array(61).keys()].map((y) => ({
                    value: y,
                    obj: y,
                    label: `${y}년`,
                  }))}
                  onChange={(value) => {
                    setAge(value?.value);

                    if (isNil(months)) {
                      setMonths(0);
                    }
                  }}
                  isSearchable={true}
                  controlBgColor="fff"
                />
              </Flex>

              <Flex f-1>
                <Dropdown
                  placeholder={"개월"}
                  value={months}
                  options={[...Array(12).keys()].map((y) => ({
                    value: y,
                    obj: y,
                    label:
                      (isNil(age) || age === 0) && y === 0
                        ? "1개월 미만"
                        : `${y}개월`,
                  }))}
                  onChange={(value) => {
                    setMonths(value?.value);

                    if (isNil(age)) {
                      setAge(0);
                    }
                  }}
                  isSearchable={true}
                  controlBgColor="fff"
                />
              </Flex>
            </HFlex>
            <Notification type="container" color="blue">
              <HFlex g-12>
                <Image src="/icons/icon_info_s6.svg" size={22} />
                <VFlex g-4>
                  {(!isNil(age) || !isNil(months)) && (
                    <Notification.Title>
                      추정 생년월일:{" "}
                      {estimateBirthYearMonth(age ?? 0, months ?? 0)}
                    </Notification.Title>
                  )}
                  <Notification.Description>
                    추정 생년월일을 기준으로 나이가 업데이트 됩니다.
                  </Notification.Description>
                </VFlex>
              </HFlex>
            </Notification>
          </VFlex>
        )}
      </VFlex>

      <Button
        m-24-t
        bdr-12
        height={56}
        enabled={
          !!(
            birthType === "AGE" &&
            (!isNil(age) || !isNil(months)) &&
            (value.age !== age || value.months !== months)
          ) ||
          !!(
            birthType === "BIRTHDAY" &&
            birthday &&
            value.birthday !== format(birthday, "yyyyMMdd")
          )
        }
        onClick={() => {
          fetch(
            birthType === "AGE" && (!isNil(age) || !isNil(months))
              ? {
                  age,
                  months,
                }
              : birthType === "BIRTHDAY" && !isNil(birthday)
              ? { birthday: format(birthday, "yyyyMMdd") }
              : {}
          );
          popPopup();
        }}
      >
        저장
      </Button>
    </VFlex>
  );
};

export default EditPetBirth;
