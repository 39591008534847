import React, {
  useState,
  useEffect,
  useRef,
  ReactNode,
  ReactElement,
} from "react";
import ReactDOM from "react-dom";

interface DropdownProps {
  trigger: ReactNode;
  renderOptions: ({ close }: { close: () => void }) => ReactElement;
  position?: "right" | "left";
  triggerAction?: "click" | "hover";
}

const Dropdown: React.FC<DropdownProps> = ({
  trigger,
  renderOptions,
  position = "right",
  triggerAction = "click",
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const triggerRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);
  const [dropdownStyles, setDropdownStyles] = useState<React.CSSProperties>({});

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      handleResize();
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      triggerRef.current &&
      !triggerRef.current.contains(event.target as Node) &&
      optionsRef.current &&
      !optionsRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  const handleResize = () => {
    if (triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      const top = rect.bottom + window.scrollY + 8;
      const left = position === "left" ? rect.left + window.scrollX : undefined;
      const right =
        position === "right"
          ? window.innerWidth - rect.right + window.scrollX
          : undefined;

      setDropdownStyles({
        position: "absolute",
        top: `${top}px`,
        left: left !== undefined ? `${left}px` : "auto",
        right: right !== undefined ? `${right}px` : "auto",
        zIndex: 100000001,
      });
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside); // 'mousedown' 이벤트로 변경
    window.addEventListener("resize", handleResize);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // 'mousedown' 이벤트로 변경
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div
        className="dropdown"
        ref={triggerRef}
        style={{ position: "relative" }}
      >
        <div
          className="trigger"
          onClick={triggerAction === "click" ? toggleDropdown : undefined}
          onMouseEnter={triggerAction === "hover" ? toggleDropdown : undefined}
          onMouseLeave={triggerAction === "hover" ? toggleDropdown : undefined}
        >
          {trigger}
        </div>
      </div>
      {isOpen &&
        ReactDOM.createPortal(
          <div className="options" ref={optionsRef} style={dropdownStyles}>
            {renderOptions({ close: () => setIsOpen(false) })}
          </div>,
          document.body
        )}
      {isOpen &&
        triggerAction === "click" &&
        ReactDOM.createPortal(
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              cursor: "pointer",
              zIndex: 100000000,
            }}
            onClick={() => setIsOpen(false)}
          />,
          document.body
        )}
    </>
  );
};

export default Dropdown;
