import ReactPaginate from "react-paginate";
import Image from "../layouts/Image";
import "./Pagenation.scss";

function Pagination({
  page,
  pageCount,
  onPageChange,
}: {
  page: number;
  pageCount: number;
  onPageChange: (v: number) => void;
}) {
  return (
    <ReactPaginate
      forcePage={page}
      pageCount={Math.ceil(pageCount)}
      activeClassName={"item active"}
      breakClassName={"item break-me"}
      breakLabel={
        <Image src="/icons/icon_pagination_break_label.svg" size={24} />
      }
      containerClassName={"pagination"}
      disabledClassName={"disabled-page"}
      marginPagesDisplayed={2}
      nextClassName={"item next"}
      nextLabel={<Image size={24} src={`/icons/chevron_right.svg`} />}
      onPageChange={(v) => {
        onPageChange(v.selected);
      }}
      pageLinkClassName={"item pagination-page"}
      pageRangeDisplayed={2}
      previousClassName={"item previous"}
      previousLabel={<Image size={24} src={`/icons/chevron_left.svg`} />}
    />
  );
}

export default Pagination;
