import { useEffect, useState } from "react";
import { formatPhoneNumber } from "../../common/format";
import TextInput from "../../layouts/TextInput";
import api from "../../common/api";
import { useNavigate } from "react-router-dom";
import Popup, { PopupKind } from "../../popup/Popup";
import { pushPopup, usePopups } from "../../providers/popups";
import VFlex from "../../layouts/VFlex";
import HFlex from "../../layouts/HFlex";
import Image from "../../layouts/Image";
import Button from "../../layouts/Button";
import Text from "../../layouts/Text";
import { toast } from "react-hot-toast";
import { useAmplitudeTrack } from "../../common/useAmplitudeTrack";
import useIsMobile from "../../common/useIsMobile";

declare global {
  var IMP: any;
}

function AccountExistsPopup({ popPopup }: { popPopup: () => void }) {
  const navigate = useNavigate();
  return (
    <VFlex a-c p-24 m-24-b>
      <HFlex as-st a-c>
        <VFlex f-1 />
        <Image
          size={24}
          src={`/icons/popup_close.png`}
          clickable
          onClick={() => {
            popPopup();
          }}
        />
      </HFlex>
      <Text
        t-18-600-s9
        style={{
          whiteSpace: "pre-wrap",
          textAlign: "center",
        }}
      >
        해당 휴대폰 번호로 가입된{"\n"}포들리 계정이 있어요.
      </Text>
      <VFlex m-16-t m-24-b />
      <Button
        type="cta-lg"
        onClick={() => {
          navigate(`/`);
        }}
        caption="로그인 하기"
      />
    </VFlex>
  );
}

export function SignupFooter() {
  return (
    <VFlex c-c as-st sized p-24-tb bc-t2>
      <VFlex g-24 c-c>
        <Image width={118} height={24} src={`/icons/logo_partners_small.png`} />
        <VFlex c-c t-14-s4>
          <Text>상호명 : (주) 포들러스</Text>
          <Text>사업자 등록번호 : 696-86-02652</Text>
          <Text>서울특별시 성동구 왕십리로 130, 10층</Text>
          <Text>(성수동1가, KCC 프리미어 타워)</Text>
          <Text>전화번호 : 010-7343-7802</Text>
        </VFlex>
      </VFlex>
    </VFlex>
  );
}

function Signup() {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (isMobile) {
      window.location.href = "https://pawdlypartners.page.link/c6wA";
      return;
    }
    var IMP = window.IMP;
    IMP.init("imp08477488");
  }, []);
  const { trackClickIdentityAuthentication } = useAmplitudeTrack();
  const { popups } = usePopups();
  const [phone, setPhone] = useState("");
  const [ready, setReady] = useState<boolean>();
  useEffect(() => {
    if (phone?.length === 13) {
      setReady(true);
    } else {
      setReady(false);
    }
  }, [phone, setReady]);
  return (
    <VFlex f-1 a-c bc-w ovf-a>
      <VFlex a-st g-24 p-24-rl width={428} style={{ marginTop: 150 }}>
        <VFlex a-st g-24 style={{ marginBottom: 100 }}>
          <VFlex a-c g-8>
            <HFlex a-c>
              <Text t-32-600-s9>
                <Text t-32-600-gr5>무료 계정</Text>을 만들어볼까요?
              </Text>
            </HFlex>
          </VFlex>
          <HFlex a-c as-st g-8 p-16 bdr-8 bc-t4>
            <Image size={24} src={`/icons/info.png`} />
            <VFlex>
              <Text t-14-700-s4>
                업체 대표자로 가입하는 분의 본인여부를 확인하며,
              </Text>
              <Text t-14-700-s4>
                본인 인증한 휴대폰 번호는 아이디로 활용됩니다.
              </Text>
            </VFlex>
          </HFlex>
          <VFlex g-8>
            <TextInput
              placeholder={"휴대폰 번호 입력"}
              value={phone}
              inputMode="numeric"
              onKeyDown={(e) => {
                if (!(e.key === "Backspace" || /[0-9]/.test(e.key))) {
                  e.preventDefault();
                }
              }}
              onChangeValue={(value) => {
                const result = value?.replace(/[^0-9]/g, "") || "";
                setPhone((phone) => formatPhoneNumber(phone, result) ?? "");
              }}
            />
          </VFlex>
          <VFlex>
            <Button
              type="cta-lg"
              enabled={ready}
              onClick={async () => {
                if (!ready) {
                  return;
                }

                // TODO
                // navigate(`/signup/password`, {
                //   state: { name: " ", phone, impUid: "abc" },
                // });

                const phoneExist = await api.get(
                  `/rest/user/cell-number-check?cellnumber=${phone}&type=PARTNER_SIGNUP`,
                  { headers: { Authorization: "" } }
                );

                if (phoneExist) {
                  pushPopup({
                    kind: PopupKind.Popup,
                    element: AccountExistsPopup,
                  });
                  return;
                }
                window.IMP.certification(
                  {
                    merchant_uid: "merchant_" + new Date().getTime(),
                    phone,
                  },
                  async (rsp: any) => {
                    if (rsp.success) {
                      const res = await api.get(
                        `/rest/user/certifications?impUid=${rsp.imp_uid}`,
                        { headers: { Authorization: "" } }
                      );
                      navigate(`/signup/password`, {
                        state: { name: res.name, phone, impUid: rsp.imp_uid },
                      });
                    } else {
                      toast.error("본인인증에 실패하였습니다.");
                    }
                  }
                );
                trackClickIdentityAuthentication(phone);
              }}
              caption="본인인증 하기"
            />
          </VFlex>
        </VFlex>
      </VFlex>
      <VFlex f-1 />
      <SignupFooter />
      {popups.map((popup, i) => (
        <Popup key={popup.key} popup={popup} depth={i} />
      ))}
    </VFlex>
  );
}

export default Signup;
