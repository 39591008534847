import { useEffect } from "react";
import { useParams } from "react-router-dom";

import Sidebar from "../../components/Sidebar";
import useIsMobile from "../../common/useIsMobile";
import Spinner from "../../common/Spinner/Spinner";
import Popup from "../../popup/Popup";

import { usePartner } from "../../providers/partner";
import { useBusinessHours } from "../../providers/businessHours";
import { useProductGroups } from "../../providers/productGroups";
import { useTaxonomies } from "../../providers/taxonomies";
import { useSchedules } from "../../providers/schedules";
import { useNotifications } from "../../providers/notifications";
import { useSpeciesList } from "../../providers/speciesList";
import { usePopups } from "../../providers/popups";
import { useStaffs } from "../../providers/staffs";
import { usePets } from "../../providers/pets";
import { useMainTabIndex } from "../../providers/mainTabIndex";
import { useAccessLog } from "../../providers/useAccessLog";
import { useQuestionnaires } from "../../providers/questionnaires";
import { useDetails } from "../../providers/details";
import { useCount } from "../../providers/count";

import LoadingView from "../../layouts/LoadingView";
import HFlex from "../../layouts/HFlex";
import Image from "../../layouts/Image";
import Absolute from "../../layouts/Absolute";
import VFlex from "../../layouts/VFlex";
import Text from "../../layouts/Text";
import Button from "../../layouts/Button";

declare global {
  var loaded: boolean;
}

function Main() {
  const fetchAccessLog = useAccessLog();
  const { partner, fetchPartner } = usePartner();
  useEffect(() => {
    fetchPartner();
    fetchAccessLog();
  }, [fetchPartner, fetchAccessLog]);
  if (!partner || !partner.fullyLoggedIn) {
    return (
      <VFlex c-c f-1>
        <Spinner />
      </VFlex>
    );
  }
  return <MainInner1 />;
}

function MainInner1() {
  const { fetchStaffs } = useStaffs();
  const { fetchSchedules } = useSchedules();
  const {
    fetchBusinessHolidays,
    fetchTemporaryHolidays,
    fetchPublicHolidays,
    fetchBusinessHours,
  } = useBusinessHours();
  const { fetchProductGroups } = useProductGroups();
  const { fetchSpeciesList } = useSpeciesList();
  const { fetchPets } = usePets();
  const { fetchTaxonomies } = useTaxonomies();
  const { fetchQuestionnaires } = useQuestionnaires();
  const { fetchGroupNotifications, fetchGroupNotificationSettings } =
    useNotifications();
  const { fetchDetails } = useDetails();
  const { fetchCount } = useCount();
  useEffect(() => {
    fetchStaffs();
    fetchSchedules();
    fetchBusinessHolidays();
    fetchTemporaryHolidays();
    fetchPublicHolidays();
    fetchBusinessHours();
    fetchProductGroups();
    fetchSpeciesList();
    fetchGroupNotificationSettings();
    fetchTaxonomies();
    fetchPets({ page: 0, sort: "CREATED" });
    fetchQuestionnaires();
    fetchDetails();
    fetchCount({ date: Math.floor(new Date().getTime() / 1000) });
  }, [
    fetchStaffs,
    fetchSchedules,
    fetchBusinessHolidays,
    fetchTemporaryHolidays,
    fetchPublicHolidays,
    fetchBusinessHours,
    fetchProductGroups,
    fetchSpeciesList,
    fetchGroupNotifications,
    fetchGroupNotificationSettings,
    fetchTaxonomies,
    fetchPets,
    fetchQuestionnaires,
    fetchDetails,
    fetchCount,
  ]);
  return <MainInner2 />;
}

function MainInner2() {
  const { tab } = useParams();
  const { partner } = usePartner();
  const {
    selectedMenu,
    sidebarMenus,
    setSelectedMenuIndex,
    onChangeMainSideBar,
  } = useMainTabIndex();
  useEffect(() => {
    if (!tab) {
      return;
    }
    setSelectedMenuIndex(tab);
  }, [setSelectedMenuIndex, tab]);
  const isMobile = useIsMobile();
  const { popups } = usePopups();
  return isMobile ? (
    <HFlex f-1>
      <Image width={"100%"} src={`/mobile_background.png`} />
      <Absolute style={{ bottom: 41, left: 24, right: 24, top: "auto" }}>
        <VFlex
          as-st
          bc-w
          bdr-24
          ovf-h
          height={587}
          style={{ boxShadow: "0px 6px 16px rgba(0, 0, 0, 0.2)" }}
        >
          <Image
            width={380}
            src={`/mobile_popup_text.png`}
            style={{ position: "absolute" }}
          />
          <Image
            width={380}
            src={`/mobile_popup_image.png`}
            style={{ position: "absolute", right: 0 }}
          />
          <Absolute bottom={32} right={0} left={0} top={"auto"} a-c>
            <VFlex m-32-rl g-16 a-c>
              <Button
                as-st
                type={"cta-lg"}
                onClick={() =>
                  window.open("https://pawdlypartners.page.link/c6wA")
                }
                caption={"포들리 파트너스 앱 다운받기"}
              />
              <Text t-12-s2 style={{ textAlign: "center" }}>
                현재 모바일 환경에서는 앱으로만 사용 가능합니다.
              </Text>
            </VFlex>
          </Absolute>
        </VFlex>
      </Absolute>
    </HFlex>
  ) : (
    <LoadingView height={"100%"} loading={!partner}>
      <HFlex height={"100%"} a-st>
        <Sidebar
          id={"sidebar"}
          logo={true}
          value={selectedMenu}
          items={sidebarMenus}
          onChange={onChangeMainSideBar}
        />
        <VFlex f-1 as-st ovf-a>
          {selectedMenu?.component?.()}
        </VFlex>
        {popups.map((popup, i) => (
          <Popup key={popup.key} popup={popup} depth={i} />
        ))}
      </HFlex>
    </LoadingView>
  );
}

export default Main;
