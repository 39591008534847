import { useMemo } from "react";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import api from "../common/api";
import { usePartner } from "./partner";
import {
  BusinessHoliday,
  BusinessHour,
  Partner,
  PublicHoliday,
  TemporaryHoliday,
} from "./types";

const businessHoursStore = create(
  immer<{
    businessHolidays?: BusinessHoliday[];
    temporaryHolidays?: TemporaryHoliday[];
    publicHolidays?: PublicHoliday[];
    businessHours?: BusinessHour[];
    fetchBusinessHolidays: (partner: Partner) => () => void;
    fetchTemporaryHolidays: (partner: Partner) => () => void;
    fetchPublicHolidays: (partner: Partner) => () => void;
    fetchBusinessHours: (partner: Partner) => () => void;
  }>((set) => ({
    businessHolidays: [],
    temporaryHolidays: [],
    publicHolidays: [],
    businessHours: [],
    fetchBusinessHolidays: (partner: Partner) => async () => {
      const businessHolidays = await api.get(
        `/rest/group/${partner.id}/business-holiday`,
        {}
      );
      set((state) => ({
        ...state,
        businessHolidays,
      }));
    },
    fetchTemporaryHolidays: (partner: Partner) => async () => {
      const temporaryHolidays = await api.get(
        `/rest/group/${partner.id}/temporary-holiday`,
        {}
      );
      set((state) => ({
        ...state,
        temporaryHolidays: temporaryHolidays.map((holiday: any) => ({
          ...holiday,
          startDate: new Date(holiday.startDate),
          endDate: new Date(holiday.endDate),
        })),
      }));
    },
    fetchPublicHolidays: (partner: Partner) => async () => {
      const publicHolidays = await api.get(
        `/rest/group/${partner.id}/public-holiday`,
        {}
      );
      set((state) => ({
        ...state,
        publicHolidays,
      }));
    },
    fetchBusinessHours: (partner: Partner) => async () => {
      const businessHours = await api.get(
        `/rest/group/${partner.id}/business-hour`,
        {}
      );
      set((state) => ({
        ...state,
        businessHours,
      }));
    },
  }))
);

export const useBusinessHours = () => {
  const { partner } = usePartner();
  const {
    businessHolidays,
    temporaryHolidays,
    publicHolidays,
    businessHours,
    fetchBusinessHolidays,
    fetchTemporaryHolidays,
    fetchPublicHolidays,
    fetchBusinessHours,
    ...rest
  } = businessHoursStore();
  return {
    businessHolidays: businessHolidays!,
    temporaryHolidays: temporaryHolidays!,
    publicHolidays: publicHolidays!,
    businessHours: businessHours!,
    fetchBusinessHolidays: useMemo(
      () => fetchBusinessHolidays(partner),
      [fetchBusinessHolidays, partner]
    ),
    fetchTemporaryHolidays: useMemo(
      () => fetchTemporaryHolidays(partner),
      [fetchTemporaryHolidays, partner]
    ),
    fetchPublicHolidays: useMemo(
      () => fetchPublicHolidays(partner),
      [fetchPublicHolidays, partner]
    ),
    fetchBusinessHours: useMemo(
      () => fetchBusinessHours(partner),
      [fetchBusinessHours, partner]
    ),
    ...rest,
  };
};
