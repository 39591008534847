import _ from "lodash";
import { forwardRef, HTMLAttributes } from "react";
import {
  BorderProps,
  LineProps,
  MiscellaneousProps,
  propsToClasses,
  restOfProps,
} from "./index_generated";
import "./layout.scss";

type FlexProps = {
  classes?: string[];
  width?: number | string;
  height?: number | string;
  vertical?: boolean;
  horizontal?: boolean;
} & HTMLAttributes<HTMLDivElement> &
  LineProps &
  MiscellaneousProps &
  BorderProps;

const Flex = forwardRef<HTMLDivElement, FlexProps>(
  (
    {
      classes = [],
      children,
      width,
      height,
      vertical,
      horizontal,
      style,
      ...props
    }: FlexProps,
    ref
  ) => {
    return (
      <div
        ref={ref}
        className={_.compact([
          ...propsToClasses(props),
          ...(vertical ? ["f-v"] : []),
          ...(horizontal ? ["f-h"] : []),
          ...classes,
        ]).join(" ")}
        style={{
          ...style,
          ...(width
            ? _.isString(width)
              ? { width }
              : { width: `${width}px` }
            : {}),
          ...(height
            ? _.isString(height)
              ? { height }
              : { height: `${height}px` }
            : {}),
        }}
        {...restOfProps(props)}
      >
        {children}
      </div>
    );
  }
);

export default Flex;
