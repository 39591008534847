import {
  addDays,
  addMinutes,
  differenceInDays,
  differenceInMinutes,
} from "date-fns";
import React, { RefObject, SetStateAction } from "react";
import VFlex from "../../layouts/VFlex";
import Flex from "../../layouts/Flex";
import {
  getNearestNumber,
  inrange,
  registMouseDownDrag,
} from "../../common/utils";
import { CalendarType } from "../../providers/types";

const FIVE_MINUTE_PIXEL_SIZE = 38 / 6;

const getH = (startDateTime: Date, endDateTime: Date) => {
  return (
    (differenceInMinutes(endDateTime, startDateTime) / 5) *
    FIVE_MINUTE_PIXEL_SIZE
  );
};
const getY = (startDateTime: Date) => {
  return (
    ((startDateTime.getHours() * 60 + startDateTime.getMinutes()) / 5) *
    FIVE_MINUTE_PIXEL_SIZE
  );
};

export const CreatingScheduleBlock = ({
  boundaryRef,
  startDateTime,
  endDateTime,
  setStartDateTime,
  setEndDateTime,
  calendarType,
  startDate,
  setIsDragging,
}: {
  boundaryRef: RefObject<HTMLDivElement>;
  startDateTime: Date;
  endDateTime: Date;
  setStartDateTime: React.Dispatch<SetStateAction<Date>>;
  setEndDateTime: React.Dispatch<SetStateAction<Date>>;
  calendarType: CalendarType;
  startDate?: Date;
  setIsDragging?: (isDragging: boolean) => void;
}) => {
  if (!boundaryRef.current) return <></>;

  return (
    <VFlex
      abs
      style={{
        width: "100%",
        height: getH(startDateTime, endDateTime),
        minHeight: "38px",
        top: getY(startDateTime),
        left: 0,
        zIndex: 1,
      }}
    >
      <Flex
        abs
        width={8}
        height={8}
        bdr-16
        bc-tw
        clickable
        style={{
          top: -4,
          left: 24,
          border: "2px solid #27BD63",
          boxSizing: "content-box",
        }}
        {...registMouseDownDrag((_, deltaY) => {
          const MIN = 0 - getY(startDateTime);
          const MAX = getY(endDateTime) - 38;

          const newSDT = addMinutes(
            startDateTime,
            inrange(
              (getNearestNumber(deltaY, FIVE_MINUTE_PIXEL_SIZE * 6) /
                FIVE_MINUTE_PIXEL_SIZE) *
                5,
              MIN,
              MAX
            )
          );
          const newEDT = addMinutes(
            newSDT,
            (getH(newSDT, endDateTime) / FIVE_MINUTE_PIXEL_SIZE) * 5
          );

          setStartDateTime(newSDT);
          setEndDateTime(newEDT);
        }, true)}
      />
      <VFlex
        f-1
        c-c
        bdr-8
        style={{
          background: "rgba(255, 255, 255, 0.70)",
          border: "2px solid #27BD63",
          boxShadow: "0px 6px 12px 0px rgba(0, 0, 0, 0.16)",
        }}
        clickable
        {...registMouseDownDrag((deltaX, deltaY) => {
          const { width } = boundaryRef.current?.getBoundingClientRect()!;

          const newSDT = addMinutes(
            startDateTime,
            (getNearestNumber(deltaY, FIVE_MINUTE_PIXEL_SIZE * 6) /
              FIVE_MINUTE_PIXEL_SIZE) *
              5
          );
          const newEDT = addMinutes(
            newSDT,
            (getH(startDateTime, endDateTime) / FIVE_MINUTE_PIXEL_SIZE) * 5
          );

          if (calendarType === CalendarType.DAILY) {
            setStartDateTime(newSDT);
            setEndDateTime(newEDT);
          } else if (calendarType === CalendarType.WEEKLY && startDate) {
            const newX = Math.floor(deltaX / (width / 7));
            const MIN_X = differenceInDays(startDate, startDateTime);
            const MAX_X = 6 + differenceInDays(startDate, startDateTime);

            setStartDateTime(addDays(newSDT, inrange(newX, MIN_X, MAX_X)));
            setEndDateTime(addDays(newEDT, inrange(newX, MIN_X, MAX_X)));
          }
        }, true)}
      />
      <Flex
        abs
        width={8}
        height={8}
        bdr-16
        bc-tw
        clickable
        style={{
          bottom: -4,
          right: 24,
          border: "2px solid #27BD63",
          boxSizing: "content-box",
        }}
        {...registMouseDownDrag((deltaX, deltaY) => {
          setIsDragging?.(true);
          // const { x, y, width, height } =
          //   boundaryRef.current?.getBoundingClientRect()!;

          // const MIN = getY(endDateTime) - (getY(startDateTime) + 38);
          // const MAX = height;

          // console.log(
          //   (getNearestNumber(deltaY, FIVE_MINUTE_PIXEL_SIZE * 6) /
          //     FIVE_MINUTE_PIXEL_SIZE) *
          //     5,
          //   MIN,
          //   MAX
          // );

          const newEDT = addMinutes(
            endDateTime,
            // inrange(
            (getNearestNumber(deltaY, FIVE_MINUTE_PIXEL_SIZE * 6) /
              FIVE_MINUTE_PIXEL_SIZE) *
              5
            //   MIN,
            //   MAX
            // )
          );

          setEndDateTime(newEDT);
        }, true)}
      />
    </VFlex>
  );
};
