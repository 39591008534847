import { useLocation, useNavigate } from "react-router-dom";
import TextInput from "../../layouts/TextInput";
import { useEffect, useMemo, useState } from "react";
import VFlex from "../../layouts/VFlex";
import Text from "../../layouts/Text";
import HFlex from "../../layouts/HFlex";
import Image from "../../layouts/Image";
import Button from "../../layouts/Button";

function LoginChangePassword() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { phone } = (state ?? {}) as { phone: string };
  useEffect(() => {
    if (!state) {
      navigate("/problem");
    }
  }, [state, navigate]);
  const [password, setPassword] = useState<string>();
  const [passwordLengthCheck, setPasswordLengthCheck] = useState<boolean>();
  const [passwordCharacterCheck, setPasswordCharacterCheck] =
    useState<boolean>();
  useEffect(() => {
    if (password && password.length >= 8) {
      setPasswordLengthCheck(true);
    } else {
      setPasswordLengthCheck(false);
    }
    if (
      password &&
      /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/.test(password)
    ) {
      setPasswordCharacterCheck(true);
    } else {
      setPasswordCharacterCheck(false);
    }
  }, [password, setPasswordCharacterCheck, setPasswordLengthCheck]);
  const ready = useMemo(
    () => passwordLengthCheck && passwordCharacterCheck,
    [passwordLengthCheck, passwordCharacterCheck]
  );
  return (
    <VFlex f-1 a-c bc-w ovf-a>
      <VFlex a-st g-24 p-24-rl width={428} style={{ marginTop: 150 }}>
        <VFlex a-st g-24 style={{ marginBottom: 100 }}>
          <VFlex a-c g-8>
            <HFlex a-c>
              <Text t-32-600-s9>
                <Text t-32-600-gr5>새로운 비밀번호를 </Text>입력해주세요.
              </Text>
            </HFlex>
          </VFlex>
          <VFlex g-8>
            <TextInput
              pwd
              type={"password"}
              placeholder={"비밀번호 입력"}
              value={password}
              onChangeValue={(value) => setPassword(value)}
            />
          </VFlex>
          <VFlex as-st g-8 p-16 bdr-8 bc-t1 m-24-rl>
            <HFlex a-c>
              <Image
                size={24}
                src={
                  passwordLengthCheck
                    ? `/icons/signup_check_checked.png`
                    : `/icons/signup_check_unchecked.png`
                }
              />
              <Text t-14-600-s3 m-8-l>
                8글자 이상 입력
              </Text>
            </HFlex>
            <HFlex a-c>
              <Image
                size={24}
                src={
                  passwordCharacterCheck
                    ? `/icons/signup_check_checked.png`
                    : `/icons/signup_check_unchecked.png`
                }
              />
              <Text t-14-600-s3 m-8-l>
                영문+숫자+특수문자 조합으로 입력
              </Text>
            </HFlex>
          </VFlex>
          <VFlex p-24-tb>
            <Button
              type="cta-lg"
              enabled={ready}
              onClick={() => {
                if (!ready) {
                  return;
                }
                navigate("/confirmpassword", { state: { phone, password } });
              }}
              caption="비밀번호 변경하기"
            />
          </VFlex>
        </VFlex>
      </VFlex>
    </VFlex>
  );
}

export default LoginChangePassword;
