import { useMemo } from "react";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import api from "../common/api";
import { usePartner } from "./partner";
import { Partner, Tag, TagColor, Tags } from "./types";

const tagsStore = create(
  immer<{
    totalCount: number;
    colorList: TagColor[];
    fetchTagList: (partner: Partner) => () => Promise<void>;
    fetchTags: (
      partner: Partner
    ) => ({ page, search }: { page: number; search?: string }) => Promise<Tags>;
  }>((set) => ({
    totalCount: 0,
    colorList: [],
    fetchTags:
      (partner: Partner) =>
      async ({ page, search }: { page: number; search?: string }) => {
        const data = await await api.get(
          `/rest/group/${partner.id}/customer/tags?size=10&page=${page * 10}${
            search ? `&search=${search}` : ""
          }`
        );
        set((state) => ({
          ...state,
          totalCount: data.totalCount ?? 0,
        }));
        return data;
      },
    fetchTagList: (partner: Partner) => async () => {
      const { customerTags, colors } = await api.get<{
        customerTags: Tag[];
        colors: TagColor[];
      }>(
        `/rest/group/${partner.id}/customer/tags?platform=PARTNER_WEB&type=ALL`
      );

      set((state) => ({
        ...state,
        tagList: customerTags.map((tag) => ({
          ...tag,
          backgroundColor: colors.find((c) => c.textColor === tag.textColor)
            ?.bgColor,
        })),
        colorList: colors,
      }));
    },
  }))
);
export const useTags = () => {
  const { partner } = usePartner();
  const { fetchTagList, fetchTags, ...rest } = tagsStore();
  return {
    fetchTagList: useMemo(() => fetchTagList(partner), [fetchTagList, partner]),
    fetchTags: useMemo(() => fetchTags(partner), [fetchTags, partner]),
    ...rest,
  };
};
