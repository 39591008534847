import { useCallback, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import api from "../../../common/api";
import { format, isSameDay } from "date-fns";
import { toast } from "react-hot-toast";
import Text from "../../../layouts/Text";
import VFlex from "../../../layouts/VFlex";
import HFlex from "../../../layouts/HFlex";
import Image from "../../../layouts/Image";
import Button from "../../../layouts/Button";
import { usePartner } from "../../../providers/partner";
import { useBusinessHours } from "../../../providers/businessHours";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";
import DatePicker from "../../../components/DatePickerV2";

function AddTemporaryHolidays({ popPopup }: { popPopup: () => void }) {
  const { trackSaveTemporaryHolidays } = useAmplitudeTrack();
  const { partner } = usePartner();
  const { fetchTemporaryHolidays } = useBusinessHours();
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [sending, setSending] = useState(false);
  const isChanged = useCallback(() => {
    if (startDate && endDate) {
      return true;
    }
    return false;
  }, [startDate, endDate]);

  useEffect(() => {
    if (startDate && !endDate) setEndDate(startDate);
  }, [startDate, endDate]);

  return (
    <VFlex f-1 as-st p-24-t>
      <VFlex g-16 p-32-rl f-1>
        <VFlex>
          <HFlex>
            <VFlex f-1 />
            <Image
              size={24}
              src={`/icons/popup_close.png`}
              clickable
              onClick={() => popPopup()}
            />
          </HFlex>
          <Text m-24-b t-20-700-s9>
            임시 휴무일 추가
          </Text>
        </VFlex>
        <VFlex g-8>
          <HFlex g-4>
            <Text t-14-500-s8>날짜 선택</Text>
            <Text t-14-500-r1>*</Text>
          </HFlex>
          <DatePicker
            trigger={
              <HFlex p-24-rl p-16-tb bdr-16 bd-t3 bc-t1 clickable>
                {startDate && endDate ? (
                  <Text>{`${format(startDate, "yyyy년 MM월 dd일")} ~ ${format(
                    endDate,
                    "yyyy년 MM월 dd일"
                  )}`}</Text>
                ) : (
                  <Text t-16-500-s2>날짜를 선택하세요</Text>
                )}
              </HFlex>
            }
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </VFlex>
      </VFlex>
      <HFlex a-c bd-t-t3 p-32-tb g-8 p-32-rl height={86}>
        <VFlex f-1 />
        <Button type="cancel" caption="취소" onClick={() => popPopup()} />
        <Button
          type="confirm"
          caption="저장"
          enabled={isChanged()}
          onClick={async () => {
            if (!isChanged()) {
              return;
            }
            setSending(true);
            await api.post(`/rest/group/${partner.id}/temporary-holiday`, [
              {
                startDate: format(startDate!, "yyyy-MM-dd"),
                endDate: format(endDate!, "yyyy-MM-dd"),
              },
            ]);
            await fetchTemporaryHolidays();
            popPopup();
            toast.success("성공적으로 저장되었습니다.");
            setSending(false);
            trackSaveTemporaryHolidays(partner.title);
          }}
        >
          <Image
            size={24}
            src={`/icons/checkbox_check.png`}
            clickable
            onClick={() => popPopup()}
          />
          <Text t-14-700-w>{sending ? "..." : "임시 휴무일 등록하기"}</Text>
        </Button>
      </HFlex>
    </VFlex>
  );
}

export default AddTemporaryHolidays;
