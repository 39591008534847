import { useState, useEffect } from "react";
import { isNil } from "lodash";

import FilterChip from "../../layouts/FilterChip";
import HFlex from "../../layouts/HFlex";
import VFlex from "../../layouts/VFlex";
import Search from "../../layouts/Search";
import Dropdown from "../../layouts/Dropdown";
import SortFilter from "./SortFilter";
import TagFilter from "./TagFilter";
import SpeciesFilter from "./SpeciesFilter";
import ButtonV2 from "../../layouts/ButtonV2";
import Menu from "../../layouts/Menu";

import {
  Filter,
  Filters,
  Sorts,
  SpeciesFilter as SpeciesFilterType,
  TagFilter as TagFilterType,
} from "../../providers/types";
import useCustomersContext from "../../providers/customers";
import api from "../../common/api";
import { usePartner } from "../../providers/partner";

export enum FilterListCategory {
  species = "SPECIES",
  customerTag = "CUSTOMER_TAG",
}

const CustomerListFilter = () => {
  const { partner } = usePartner();
  const {
    searchText,
    setSearchText,
    setSpeciesFilterId,
    speciesFilters,
    setSpeciesFilters,
    setTagFilterId,
    tagFilters,
    setTagFilters,
    sortFilters,
    setSortFilters,
  } = useCustomersContext();

  const [filterStack, setFilterStack] = useState<string[]>([]);
  const [filtersOptions, setFiltersOptions] = useState<Filters<any>[]>([]);
  const [sortOptions, setSortOptions] = useState<Sorts[]>([]);

  const [shownFilters, setShownFilters] = useState(false);
  const [shownSort, setShownSort] = useState(false);

  const [speciesFilterOptions, setSpeciesFilterOptions] = useState<
    SpeciesFilterType[]
  >([]);
  const [tagFilterOptions, setTagFilterOptions] = useState<TagFilterType[]>([]);

  const initFilterOptions = async () => {
    const { filters, sorts } = await api.get<Filter>(
      `/rest/group/${partner.id}/filter?type=G_PET_LIST`,
      {
        params: {
          deleted: false,
        },
      }
    );

    setFiltersOptions(filters);
    setSpeciesFilterId(
      filters.find((f) => f.category === FilterListCategory.species)?.id!
    );
    setSpeciesFilterOptions(
      (filters.find((f) => f.category === FilterListCategory.species)?.data ||
        []) as SpeciesFilterType[]
    );
    setTagFilterId(
      filters.find((f) => f.category === FilterListCategory.customerTag)?.id!
    );
    setTagFilterOptions(
      (filters.find((f) => f.category === FilterListCategory.customerTag)
        ?.data || []) as TagFilterType[]
    );
    setSortOptions(sorts);
  };

  useEffect(() => {
    initFilterOptions();
    if (speciesFilters.length) {
      setShownFilters(true);
      setFilterStack((s) => [...s, FilterListCategory.species]);
    }
    if (tagFilters.length) {
      setShownFilters(true);
      setFilterStack((s) => [...s, FilterListCategory.customerTag]);
    }
    if (sortFilters.length) {
      setShownSort(true);
    }
  }, []);

  useEffect(() => {
    if (sortFilters.length <= 0) setShownSort(false);
  }, [sortFilters]);

  return (
    <VFlex
      p-16-tb
      p-24-rl
      g-16={filterStack.length > 0 || sortFilters.length > 0}
    >
      <HFlex g-16>
        <Search
          size="S"
          type="outline"
          placeholder="고객명, 보호자명, 전화번호를 입력하세요"
          value={searchText}
          onChangeValue={setSearchText}
        />
        <HFlex g-8>
          {filterStack.length === 0 ? (
            <Dropdown
              trigger={
                <ButtonV2
                  size="XS"
                  type="grey_tint"
                  onClick={() => {}}
                  icon="/icons/icon_filter.svg"
                  iconSize={18}
                >
                  필터
                </ButtonV2>
              }
              renderOptions={({ close }) => (
                <Menu width={180}>
                  {filtersOptions.map((opt) => (
                    <Menu.Item
                      handleClick={() => {
                        setFilterStack((s) => [...s, opt.category]);
                        close();

                        if (sortFilters.length) setShownSort(true);
                        setShownFilters(true);
                      }}
                    >
                      {opt.title}
                    </Menu.Item>
                  ))}
                </Menu>
              )}
              position="right"
            />
          ) : (
            <ButtonV2
              size="XS"
              type="color_outlined"
              onClick={() => {
                if (sortFilters.length) setShownSort((s) => !s);
                setShownFilters((s) => !s);
              }}
              icon="/icons/active_filter_list.svg"
              iconSize={18}
            >
              필터 {filterStack.length}
            </ButtonV2>
          )}
          {sortFilters.length === 0 ? (
            <Dropdown
              trigger={
                <ButtonV2
                  size="XS"
                  type="grey_tint"
                  onClick={() => {}}
                  icon="/icons/icon_sort.svg"
                  iconSize={18}
                >
                  정렬
                </ButtonV2>
              }
              renderOptions={({ close }) => (
                <Menu width={180}>
                  {sortOptions.map((opt) => (
                    <Menu.Item
                      handleClick={() => {
                        setShownSort(true);
                        setSortFilters((s) => [
                          ...s,
                          sortOptions.find((o) => o.category === opt.category)!,
                        ]);
                        close();

                        if (filterStack.length) setShownSort(true);
                      }}
                    >
                      {opt.title}
                    </Menu.Item>
                  ))}
                </Menu>
              )}
              position="right"
            />
          ) : (
            <ButtonV2
              size="XS"
              type="color_outlined"
              onClick={() => {
                if (filterStack.length) setShownFilters((s) => !s);
                setShownSort((s) => !s);
              }}
              icon="/icons/active_sort.svg"
              iconSize={18}
            >
              정렬 {sortFilters.length}
            </ButtonV2>
          )}
        </HFlex>
      </HFlex>
      <HFlex g-6>
        {shownSort && (
          <SortFilter
            options={sortOptions}
            sortFilters={sortFilters}
            setSortFilters={setSortFilters}
          />
        )}
        {shownFilters &&
          filterStack.map((filter) =>
            filter === FilterListCategory.species ? (
              <SpeciesFilter
                options={speciesFilterOptions}
                speciesList={speciesFilters}
                setSpeciesFilter={setSpeciesFilters}
                removeFilter={() => {
                  setSpeciesFilters([]);
                  setFilterStack((stack) =>
                    stack.filter((s) => s !== "SPECIES")
                  );
                }}
              />
            ) : (
              <TagFilter
                options={tagFilterOptions}
                tagList={tagFilters}
                setTagList={setTagFilters}
                removeFilter={() => {
                  setTagFilters([]);
                  setFilterStack((stack) =>
                    stack.filter((s) => s !== "CUSTOMER_TAG")
                  );
                }}
              />
            )
          )}
        {filterStack.length > 0 &&
          filterStack.length < 2 &&
          (shownFilters || shownSort) && (
            <Dropdown
              position="left"
              trigger={
                <FilterChip prefixIcon="/icons/add.svg" prefixIconSize={18}>
                  필터 추가
                </FilterChip>
              }
              renderOptions={() => (
                <Menu width={180}>
                  {isNil(
                    filterStack.find((f) => f === FilterListCategory.species)
                  ) && (
                    <Menu.Item
                      handleClick={() => {
                        setFilterStack((s) => [
                          ...s,
                          FilterListCategory.species,
                        ]);
                      }}
                    >
                      품종
                    </Menu.Item>
                  )}
                  {isNil(
                    filterStack.find(
                      (f) => f === FilterListCategory.customerTag
                    )
                  ) && (
                    <Menu.Item
                      handleClick={() => {
                        setFilterStack((s) => [
                          ...s,
                          FilterListCategory.customerTag,
                        ]);
                      }}
                    >
                      고객 태그
                    </Menu.Item>
                  )}
                </Menu>
              )}
            />
          )}
      </HFlex>
    </VFlex>
  );
};

export default CustomerListFilter;
