import { useCallback, useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import api from "../../../common/api";
import Spinner from "../../../common/Spinner/Spinner";
import Autocomplete from "../../../components/AddressAutocomplete";
import FileUpload from "../../../components/FileUpload";
import TextInput from "../../../layouts/TextInput";
import Button from "../../../layouts/Button";
import HFlex from "../../../layouts/HFlex";
import Image from "../../../layouts/Image";
import Text from "../../../layouts/Text";
import VFlex from "../../../layouts/VFlex";
import ConfirmPopup from "../../../popup/ConfirmPopup";
import { PopupAlign, PopupKind } from "../../../popup/Popup";
import { usePartner } from "../../../providers/partner";
import { pushPopup } from "../../../providers/popups";
import { useAmplitudeTrack } from "../../../common/useAmplitudeTrack";

function PartnerBasicInfo({ popPopup }: { popPopup: () => void }) {
  const { trackLandBizInfo, trackClickBizProfile, trackSaveBizInfo } =
    useAmplitudeTrack();

  useEffect(() => {
    trackLandBizInfo();
  }, []);

  const [file, setFile] = useState<File>();
  const { partner, fetchPartner } = usePartner();
  const [partnerName, setPartnerName] = useState<string>(partner.name);
  const [ownerName, setOwnerName] = useState<string>(partner.fullname);
  const [phoneNumber, setPhoneNumber] = useState<string>(partner.contact);
  const [address, setAddress] = useState<string>(partner.address.address);
  const [loading, setLoading] = useState<boolean>(false);
  const isChanged = useCallback(() => {
    return (
      partnerName !== partner.name ||
      phoneNumber !== partner.contact ||
      address !== partner.address.address ||
      !!file
    );
  }, [partner, partnerName, phoneNumber, address, file]);
  const updatePartnerInfo = useCallback(async () => {
    setLoading(true);
    if (file) {
      const formData = new FormData();
      formData.append("image", file);
      await api.post(`/rest/group/${partner.id}/profile/logo`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    }
    await fetchPartner();
    setLoading(false);
    toast.success("성공적으로 저장되었습니다.");
  }, [partner, file, fetchPartner]);
  return (
    <VFlex height={"100%"} a-st p-24-t m-8-rl>
      <HFlex p-24-rl>
        <VFlex f-1 />
        <Image
          size={24}
          src={`/icons/popup_close.png`}
          clickable
          onClick={() =>
            isChanged()
              ? pushPopup({
                  kind: PopupKind.Popup,
                  align: PopupAlign.TopCenter,
                  element: ConfirmPopup,
                  props: {
                    title: "변경사항을 저장하지 않고 닫을까요?",
                    onConfirm: () => {
                      popPopup();
                    },
                  },
                })
              : popPopup()
          }
        />
      </HFlex>
      <Text t-20-700-gr10 m-32-rl m-24-b>
        업체 정보
      </Text>
      <VFlex g-16 p-24-rl p-16-tb ovf-a>
        <HFlex a-e g-16>
          <label
            htmlFor="partner-profile-upload"
            style={{ width: 80, height: 80 }}
            className="rel"
            onClick={() => {
              trackClickBizProfile(partner.title);
            }}
          >
            {loading ? (
              <VFlex c-c width={"100%"} height={"100%"}>
                <Spinner />
              </VFlex>
            ) : (
              <>
                <Image
                  as-c
                  size={80}
                  bdr-32
                  src={
                    partner.store?.path
                      ? partner.store?.path
                      : file
                      ? URL.createObjectURL(file)
                      : `/icons/default_profile.png`
                  }
                />
                <VFlex
                  width={32}
                  height={32}
                  bdw-2-w
                  c-c
                  abs
                  bdr-32
                  bc-t6
                  style={{ top: "auto", left: "auto", right: 0, bottom: 0 }}
                >
                  <Image
                    width={20}
                    height={20}
                    src={`/icons/icon_camera.png`}
                  />
                </VFlex>
                <FileUpload
                  id="partner-profile-upload"
                  onLoad={(file: File) => setFile(file)}
                />
              </>
            )}
          </label>
          <Text t-12-500-t8>업체 프로필 사진을 올려주세요.</Text>
        </HFlex>
        <TextInput
          disabled={true}
          caption={"업체명"}
          placeholder={"업체명"}
          required={true}
          value={partnerName}
          onChangeValue={(value) => setPartnerName(value ?? "")}
        />
        <TextInput
          disabled={true}
          caption={"대표자명"}
          placeholder={"대표자명"}
          value={ownerName}
          onChangeValue={(value) => setOwnerName(value ?? "")}
        />
        <Autocomplete
          isDisabled={true}
          caption={"업체 주소"}
          placeholder={"업체 주소"}
          value={{ value: address, label: address }}
          onChange={(value) => setAddress(value?.value)}
        />
        <TextInput
          disabled={true}
          caption={"업체 전화번호"}
          placeholder={"업체 전화번호"}
          value={phoneNumber}
          onChangeValue={(value) => setPhoneNumber(value ?? "")}
        />
      </VFlex>
      <VFlex f-1 />
      <HFlex height={86} bd-t a-c g-8 m-24-t p-32-rl>
        <VFlex f-1 />
        <Button
          onClick={() => {
            isChanged()
              ? pushPopup({
                  kind: PopupKind.Popup,
                  align: PopupAlign.TopCenter,
                  element: ConfirmPopup,
                  props: {
                    title: "변경사항을 저장하지 않고 닫을까요?",
                    onConfirm: () => {
                      popPopup();
                    },
                  },
                })
              : popPopup();
          }}
          type="cancel"
          caption="취소"
        />
        <Button
          enabled={isChanged()}
          onClick={() => {
            isChanged() && updatePartnerInfo();
            trackSaveBizInfo(partner.title);
          }}
          type="confirm"
          caption="저장"
        />
      </HFlex>
    </VFlex>
  );
}

export default PartnerBasicInfo;
