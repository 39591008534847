import { PropsWithChildren, ReactNode } from "react";
import Flex from "../layouts/Flex";
import VFlex from "../layouts/VFlex";

interface TooltipProps extends PropsWithChildren {
  type: "tc" | "rc" | "bc" | "lc";
  positionTop: number;
  positionLeft: number;
  content: string | ReactNode;
  arrowOffsetTop?: number;
  arrowOffsetRight?: number;
  arrowOffsetBottom?: number;
  arrowOffsetLeft?: number;
}

const getArrowStyle = (
  type: TooltipProps["type"],
  arrowOffsetTop: number,
  arrowOffsetRight: number,
  arrowOffsetBottom: number,
  arrowOffsetLeft: number
): React.CSSProperties => {
  switch (type) {
    case "tc":
      return {
        top: `calc(-16px + ${arrowOffsetTop}px)`,
        width: 0,
        height: 0,
        borderTop: "8px solid transparent",
        borderRight: "8px solid transparent",
        borderBottom: "8px solid #383B3A",
        borderLeft: "8px solid transparent",
      };
    case "rc":
      return {
        top: `calc(14.5px + ${arrowOffsetTop}px)`,
        right: `calc(-16px + ${arrowOffsetRight}px)`,
        width: 0,
        height: 0,
        borderTop: "8px solid transparent",
        borderRight: "8px solid transparent",
        borderBottom: "8px solid transparent",
        borderLeft: "8px solid #383B3A",
      };
    case "bc":
      return {
        bottom: `calc(-16px + ${arrowOffsetBottom}px)`,
        right: `calc(${arrowOffsetRight}px)`,
        width: 0,
        height: 0,
        borderTop: "8px solid #383B3A",
        borderRight: "8px solid transparent",
        borderBottom: "8px solid transparent",
        borderLeft: "8px solid transparent",
      };
    case "lc":
      return {
        top: `calc(14.5px + ${arrowOffsetTop}px)`,
        left: `calc(-16px + ${arrowOffsetLeft}px)`,
        width: 0,
        height: 0,
        borderTop: "8px solid transparent",
        borderRight: "8px solid #383B3A",
        borderBottom: "8px solid transparent",
        borderLeft: "8px solid transparent",
      };
    default:
      return {};
  }
};

const Tooltip = ({
  type,
  positionTop,
  positionLeft,
  content,
  arrowOffsetTop = 0,
  arrowOffsetRight = 0,
  arrowOffsetBottom = 0,
  arrowOffsetLeft = 0,
}: TooltipProps) => {
  return (
    <VFlex
      abs
      a-c
      t-14-w
      p-12
      bdr-8
      bc-s8
      clickable
      style={{
        top: `${positionTop}px`,
        left: `${positionLeft}px`,
        overflow: "unset",
        zIndex: 99,
      }}
    >
      <VFlex>{content}</VFlex>
      {type && (
        <Flex
          abs
          style={getArrowStyle(
            type,
            arrowOffsetTop,
            arrowOffsetRight,
            arrowOffsetBottom,
            arrowOffsetLeft
          )}
        />
      )}
    </VFlex>
  );
};

export default Tooltip;
